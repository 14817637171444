import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class BuildingInspectionRequest extends React.Component {
  resource = '/building_inspection_requests'
  form_key = 'building_inspection_request'
  defaults = {}

  // Delete lines as needed
  fee = 99
  free = true
  postpaid = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  /*
  t.string :building_permit_numb
  t.string :building_address
  t.string :portion_desc
  t.date :inspection_date
  t.string :contractor_first_name
  t.string :contractor_last_name
  t.string :contractor_address
  t.string :contractor_phone
  t.date :contracted_date
  t.string :electrical_spec_numb
  t.string :owner_first_name
  t.string :owner_last_name

  t.attachment :identification_upload
  */

  fields = form => [
    {
      name: 'building_permit_numb',
      label: ' Building Permit number',
    },
    {
      name: 'building_address',
      label: 'Address of Building',
    },
    {
      name: 'portion_desc',
      label: 'Description of Portion or Portions required Inspection',
      type: 'textarea',
    },
    {
      name: 'inspection_date',
      label: 'Date ready for inspection',
      type: 'date',
    },
    '::Contractor Details',
    {
      name: 'contractor_first_name',
      label: 'First name',
    },
    {
      name: 'contractor_last_name',
      label: 'Last name',
    },
    {
      name: 'contractor_address',
      label: 'Address',
    },
    {
      name: 'contractor_phone',
      label: 'Telephone number',
      type: 'phone',
    },
    {
      name: 'contracted_date',
      label: 'Date',
      type: 'date',
    },
    {
      name: 'electrical_spec_numb',
      label: 'Electrical Specification Number',
      type: 'integer',
    },

    '::Owner Details',
    {
      name: 'owner_first_name',
      label: 'First name',
    },
    {
      name: 'owner_last_name',
      label: 'Last name',
    },

    "::Uploads",
    {
      name: 'identification_upload',
      label: 'Government issued ID',
      key: 'image.0',
      type: 'file:image',
      required: false,
    },
    {
      name: 'water_sewage_letter_upload',
      label: 'Water Sewage Letter',
      hint: 'Letters must have offical stampage',
      key: 'image.1',
      type: 'file:all',
      required: false,
    },
    {
      name: 'occupancy_process_card_upload',
      label: 'Occupancy Process Card',
      hint: 'Card must be stamped by DEHS',
      key: 'image.2',
      type: 'file:all',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
