export const GET_RESPONSE_ERROR = 'GET_RESPONSE_ERROR'
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS'
export const FETCH_GMAP_KEY = 'FETCH_GMAP_KEY'
export const GET_COUNTRY_ISLAND_DATA = 'GET_COUNTRY_ISLAND_DATA'

// USERS
export const GET_USER_DATA = 'GET_USER_DATA'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const UNCONFIRMED_LOGIN = 'UNCONFIRMED_LOGIN'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const IS_VERIFIED = 'IS_VERIFIED'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const VERIFY_DOCUMENT = 'VERIFY_DOCUMENT'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const GET_ADDRESS_INFO = 'GET_ADDRESS_INFO'
export const UPDATE_ADDRESS_INFO = 'UPDATE_ADDRESS_INFO'
export const UPDATE_WORK_INFO = 'UPDATE_WORK_INFO'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

// DASHBOARD
export const GET_COUNTS = 'GET_COUNTS'
export const GET_TOP_REQUEST = 'GET_TOP_REQUEST'
export const RECENT_HISTORY = 'RECENT_HISTORY'
export const GET_PARTICULAR_REVENUE = 'GET_PARTICULAR_REVENUE'
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS'

// TRANSACTIONS
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const GET_PARTICULAR_TRANSACTION = 'GET_PARTICULAR_TRANSACTION'
export const CREATE_TRANSATION = 'CREATE_TRANSATION'
export const UPDATE_TRANSATION = 'UPDATE_TRANSATION'
export const GET_TOTAL_SPEND ='GET_TOTAL_SPEND'
export const GET_USER_PROFILE_DATA ='GET_USER_PROFILE_DATA'
export const GET_HEADS = 'GET_HEADS'
export const GET_PARTICULAR_HEAD = 'GET_PARTICULAR_HEAD'
export const CREATE_HEAD= 'CREATE_HEAD'
export const UPDATE_HEAD = 'UPDATE_HEAD'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'

export const GET_LINE_ITEMS = 'GET_LINE_ITEMS'
export const GET_PARTICULAR_LINE_ITEM = 'GET_PARTICULAR_LINE_ITEM'
export const CREATE_LINE_ITEM= 'CREATE_LINE_ITEM'
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM'
export const GET_USER_PARENT_DATA = 'GET_USER_PARENT_DATA'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const UPDATE_PARENT_INFO = 'UPDATE_PARENT_INFO'
export const CREATE_PARENT_INFO = 'CREATE_PARENT_INFO'

export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const GET_ALL_REVENUE = 'GET_ALL_REVENUE'

// NOTES
export const GET_ALL_NOTES = 'GET_ALL_NOTES'
export const GET_PARTICULAR_NOTE = 'GET_PARTICULAR_NOTE'
export const SEND_MESSAGE_DATA = 'SEND_MESSAGE_DATA'
export const GET_PERSON_STATUS = 'GET_PERSON_STATUS'

// PASSPORTS
export const GET_RENEWAL_PASSPORT_SUCCESS = 'GET_RENEWAL_PASSPORT_SUCCESS'
export const UPDATE_RENEWAL_PASSPORT_SUCCESS = 'UPDATE_RENEWAL_PASSPORT_SUCCESS'
export const DELETE_RENEWAL_PASSPORT_SUCCESS = 'DELETE_RENEWAL_PASSPORT_SUCCESS'
export const VIEW_RENEWAL_PASSPORT_SUCCESS = 'VIEW_RENEWAL_PASSPORT_SUCCESS'
export const CREATE_RENEWAL_PASSPORT_SUCCESS = 'CREATE_RENEWAL_PASSPORT_SUCCESS'

// AGENCIES
export const GET_AGENCIES = 'GET_AGENCIES'
export const GET_AGENCY_EVENTS = 'GET_AGENCY_EVENTS'
export const GET_AGENCY_LOCATIONS = 'GET_AGENCY_LOCATIONS'
export const GET_AGENCY_ALERTS = 'GET_AGENCY_ALERTS'
export const SET_AGENCY_ALERT = 'SET_AGENCY_ALERT'
export const GET_AGENCY_SERVICES = 'GET_AGENCY_SERVICES'
export const GET_AGENCY_FAQS = 'GET_AGENCY_FAQS'
export const GET_AGENCY_FEEDBACK = 'GET_AGENCY_FEEDBACK'
export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS'
export const GET_DELIVERIES = 'GET_DELIVERIES'
export const CREATE_DELIVERY = 'CREATE_DELIVERY'
export const REMOVE_DELIVERY = 'REMOVE_DELIVERY'
export const CLOSE_DELIVERY = 'CLOSE_DELIVERY'
export const ADD_DELIVERY = 'ADD_DELIVERY'
export const RECEIVE_DELIVERY = 'RECEIVE_DELIVERY'
export const RECEIVE_PACKAGE = 'RECEIVE_PACKAGE'
export const PICKUP_DELIVERY = 'PICKUP_DELIVERY'

// SEARCH
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'

// APPOINTMENTS
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS'
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS'
export const GET_APPOINTMENT_TYPES_SUCCESS ='GET_APPOINTMENT_TYPES_SUCCESS'
export const GET_APPOINTMENT_BLACKOUTS_SUCCESS ='GET_APPOINTMENT_BLACKOUTS_SUCCESS'
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE'
export const REMOVE_APPOINTMENT_SUCCESS ='REMOVE_APPOINTMENT_SUCCESS'

// BLOCKCERTS
export const RESET_CERT_STATE = 'RESET_CERT_STATE'
export const SEND_BLOCKCERTS_INVITES_ATTEMPT = 'SEND_BLOCKCERTS_INVITES_ATTEMPT'
export const SEND_BLOCKCERTS_INVITES_SUCCESS = 'SEND_BLOCKCERTS_INVITES_SUCCESS'
export const SEND_BLOCKCERTS_INVITES_FAILURE = 'SEND_BLOCKCERTS_INVITES_FAILURE'
export const ISSUE_CERTIFICATES_ATTEMPT = 'ISSUE_CERTIFICATES_ATTEMPT'
export const ISSUE_CERTIFICATES_SUCCESS = 'ISSUE_CERTIFICATES_SUCCESS'
export const ISSUE_CERTIFICATES_FAILURE = 'ISSUE_CERTIFICATES_FAILURE'
export const REVOKE_CERTIFICATES_ATTEMPT = 'REVOKE_CERTIFICATES_ATTEMPT'
export const REVOKE_CERTIFICATES_SUCCESS = 'REVOKE_CERTIFICATES_SUCCESS'
export const REVOKE_CERTIFICATES_FAILURE = 'REVOKE_CERTIFICATES_FAILURE'
export const VIEW_CERTIFICATES_ATTEMPT = 'VIEW_CERTIFICATES_ATTEMPT'
export const VIEW_CERTIFICATES_SUCCESS = 'VIEW_CERTIFICATES_SUCCESS'
export const VIEW_CERTIFICATES_FAILURE = 'VIEW_CERTIFICATES_FAILURE'
export const FETCH_ISSUE_HISTORY_ATTEMPT = 'FETCH_ISSUE_HISTORY_ATTEMPT'
export const FETCH_ISSUE_HISTORY_SUCCESS = 'FETCH_ISSUE_HISTORY_SUCCESS'
export const FETCH_ISSUE_HISTORY_FAILURE = 'FETCH_ISSUE_HISTORY_FAILURE'
