import React, {useState, useEffect} from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { danceCodes } from './clementBethelArtsFestival/book-codes'
import { dramaCodes } from './clementBethelArtsFestival/book-codes'
import islands from '@constants/islands'
import ClementBethelbk from '@images/clement-bethel/ClementBethelbk.png'



// eslint-disable-next-line
const bethelBooklet = `
<p>For class number and other pertinent info, see festival book</p>
<div class='flex flex-row items-center' style='flex-wrap: wrap;'>
<a class='flex flex-row items-center mr-1' href="https://api.pilot-qa.gov.bs/system/public_attachments/file_uploads/000/000/007/original/national_arts_festival_new_full_book.pdf" target="_blank">
  <img class='w-32 md:w-40 lg:w-48' src='${ClementBethelbk}'/></a>' 
  </div> 
  ` 
  //Expand if you dare
  export const ClassNumberCalculator = () => {
    // Define state variables
    const [entryType, setEntryType] = useState('');
    const [division, setDivision] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [numParticipants, setNumParticipants] = useState('');
    const [classNumber, setClassNumber] = useState('');
  
    // Function to calculate class number
    const calculateClassNumber = () => {
      // Implement logic to calculate the class number based on user selections
      // Logic remains the same as before
      
      
      /*****************************************Dance********************************************************/

      if (entryType === 'Dance' && division === 'Pre-School PS' && subCategory === 'Classical Ballet') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance PS1 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance PS1 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance PS1 C');
        } 
      } else if (entryType === 'Dance' && division === 'Pre-School PS' && subCategory === 'Bahamian Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance PS2 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance PS2 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance PS2 C');
        } 
      } else if (entryType === 'Dance' && division === 'Pre-School PS' && subCategory === 'International Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance PS3 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance PS3 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance PS3 C');
        } 
      } else if (entryType === 'Dance' && division === 'Pre-School PS' && subCategory === 'Modern Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance PS4 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance PS4 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance PS4 C');
        } 
      } else if (entryType === 'Dance' && division === 'Pre-School PS' && subCategory === 'Tap Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance PS5 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance PS5 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance PS5 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Classical Ballet') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P1 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P1 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P1 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Bahamian Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P2 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P2 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P2 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'International Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P3 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P3 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P3 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Modern Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P4 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P4 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P4 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Jazz Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P5 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P5 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P5 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Popular Dances') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P6 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P6 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P6 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Dance Drama') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P7 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P7 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P7 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Liturgical Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P8 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P8 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P8 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Tap Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance P9 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance P9 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance P9 C');
        } 
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Cheerleading') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance P10');
        }
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Flagettes') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance P11');
        }
      } else if (entryType === 'Dance' && division === 'Primary PR' && subCategory === 'Best Choreographer') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance P12');
        }
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Classical Ballet') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J1 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J1 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J1 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Bahamian Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J2 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J2 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J2 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'International Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J3 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J3 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J3 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Modern Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J4 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J4 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J4 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Jazz Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J5 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J5 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J5 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Popular Dances') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J6 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J6 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J6 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Dance Drama') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J7 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J7 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J7 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Liturgical Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J8 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J8 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J8 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Tap Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance J9 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance J9 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance J9 C');
        } 
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Cheerleading') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance J10');
        }
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Flagettes') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance J11');
        }
      } else if (entryType === 'Dance' && division === 'Jr. High JH' && subCategory === 'Best Choreographer') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance J12');
        }
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Classical Ballet') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S1 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S1 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S1 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Bahamian Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S2 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S2 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S2 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'International Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S3 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S3 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S3 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Modern Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S4 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S4 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S4 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Jazz Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S5 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S5 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S5 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Popular Dances') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S6 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S6 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S6 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Dance Drama') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S7 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S7 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S7 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Liturgical Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S8 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S8 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S8 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Tap Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance S9 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance S9 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance S9 C');
        } 
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Cheerleading') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance S10');
        }
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Flagettes') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance S11');
        }
      } else if (entryType === 'Dance' && division === 'Sr. High SH' && subCategory === 'Best Choreographer') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance S12');
        }
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Classical Ballet') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C1 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C1 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C1 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Bahamian Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C2 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C2 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C2 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'International Folk Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C3 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C3 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C3 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Modern Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C4 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C4 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C4 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Jazz Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C5 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C5 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C5 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Hip Hop') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C6 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C6 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C6 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Dance Drama') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C7 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C7 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C7 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Liturgical Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C8 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C8 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C8 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Tap Dance') {
        if (numParticipants === 'Solo') {
          setClassNumber('Dance C9 A');
        } else if (numParticipants === 'Duet') {
          setClassNumber('Dance C9 B');
        } else if (numParticipants === 'Group') {
          setClassNumber('Dance C9 C');
        } 
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Cheerleading') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance C10');
        }
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Flagettes') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance C11');
        }
      } else if (entryType === 'Dance' && division === 'Community COM' && subCategory === 'Best Choreographer') {
        if (numParticipants === 'Solo' || 'Duet' || 'Group') {
          setClassNumber('Dance C12');
        }
      }
      /*****************************************Drama********************************************************/

      else if (entryType === 'Drama' && division === 'Pre-School PS' && subCategory === 'Individual Verse Speaking') {
        setClassNumber('Drama PS1'); 
    } else if (entryType === 'Drama' && division === 'Pre-School PS' && subCategory === 'Choral Verse Speaking') {
       setClassNumber('Drama PS2'); 
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Individual Verse Speaking - Grades 1-3') {
        setClassNumber('Drama P1');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Individual Verse Speaking - Grades 4-6') {
        setClassNumber('Dance P2');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Choral Verse Speaking - Grades 1-3') {
        setClassNumber('Drama P3');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Choral Verse Speaking - Grades 4-6') {
        setClassNumber('Drama P4');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Dramatized Characterization - One Person - Grades 4-6') {
        setClassNumber('Drama P5');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Dramatized Characterization - Two Persons - Grades 4-6') {
        setClassNumber('Drama P6');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Skit - Grades 1-3') {
        setClassNumber('Drama P7');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Skit - Grades 4-6') {
        setClassNumber('Drama P8');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Storytelling - Grades 1-3') {
        setClassNumber('Drama P9');
    } else if (entryType === 'Drama' && division === 'Primary PR' && subCategory === 'Storytelling - Grades 5-6') {
        setClassNumber('Drama P10');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Individual Verse Speaking') {
        setClassNumber('Drama J1');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Choral Verse Speaking') {
        setClassNumber('Drama J2');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Dramatized Characterization - One Person - Provided Piece') {
        setClassNumber('Drama J3');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Dramatized Characterization - Two Persons - Provided Piece') {
        setClassNumber('Drama J4');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Dramatized Characterization - One Person - Chosen Piece') {
        setClassNumber('Drama J5');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Dramatized Characterization - Two Persons - Chosen Piece') {
        setClassNumber('Drama J6');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Skit') {
        setClassNumber('Drama J7');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Play - One Act') {
        setClassNumber('Drama J8');
    } else if (entryType === 'Drama' && division === 'Jr. High JH' && subCategory === 'Play - Full Length') {
        setClassNumber('Drama J9');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Individual Verse Speaking') {
        setClassNumber('Drama S1');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Choral Verse Speaking') {
        setClassNumber('Drama S2');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Dramatized Characterization - One Person - Provided Piece') {
        setClassNumber('Drama S3');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Dramatized Characterization - One Person - Classic - Provided Piece') {
        setClassNumber('Drama S4');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Dramatized Characterization - Two Persons - Provided Piece') {
        setClassNumber('Drama S5');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Dramatized Characterization - Two Persons - Classic - Provided Piece') {
        setClassNumber('Drama S6');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Dramatized Characterization - Two Persons - Chosen Piece') {
        setClassNumber('Drama S7');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Skit') {
        setClassNumber('Drama S8');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Play - One Act') {
        setClassNumber('Drama S9');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Play - Full Length') {
        setClassNumber('Drama S10');
    } else if (entryType === 'Drama' && division === 'Sr. High SH' && subCategory === 'Storytelling') {
        setClassNumber('Drama S11');
    } else if (entryType === 'Drama' && division === 'Community COM' && subCategory === 'Dramatized Characterization - Two Persons - Chosen Piece') {
        setClassNumber('Drama C7 A');
    } else if (entryType === 'Drama' && division === 'Community COM' && subCategory === 'Dramatized Characterization Original Poetry Spoken Word') {
        setClassNumber('Drama C7 B');
    } else if (entryType === 'Drama' && division === 'Community COM' && subCategory === 'Skit') {
        setClassNumber('Drama C8');
    } else if (entryType === 'Drama' && division === 'Community COM' && subCategory === 'Play - One Act') {
        setClassNumber('Drama C9');
    } else if (entryType === 'Drama' && division === 'Community COM' && subCategory === 'Play - Full Length') {
        setClassNumber('Drama C10');
    } else if (entryType === 'Drama' && division === 'Community COM' && subCategory === 'Storytelling') {
        setClassNumber('Drama C11');
    } 

       /*else {
        // For all other cases, concatenate the selected values
        const newClassNumber = `${entryType}-${division}-${subCategory}-${numParticipants}`;
        setClassNumber(newClassNumber); 
      } */
    };
  /* 
    // Set initial subcategory based on entry type and division
   useEffect(() => {
      if (entryType === 'Dance' && division === 'Pre-School PS') {
        setSubCategory('Classical Ballet');
      } else if (entryType === 'Music' && division === 'Primary PR') {
        setSubCategory('Instrumental');
      } else if (entryType === 'Drama' && division === 'Jr. High JH') {
        setSubCategory('Theater Play');
      } else if (entryType === 'Arts & Crafts' && division === 'Sr. High SH') {
        setSubCategory('Drawing');
      } else {
        setSubCategory('');
      }
    }, [entryType, division]); */
  
    // Call calculateClassNumber whenever any of the selection fields change
    useEffect(() => {
      calculateClassNumber();
    }, [entryType, division, subCategory, numParticipants]);
  
    return (
      <div>
        {/* Select entry type */}
        <div style={{ paddingTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Entry Type:</label>
          <select value={entryType} onChange={(e) => setEntryType(e.target.value)}>
            <option value="">Select Entry Type</option>
            <option value="Dance">Dance</option>
            <option value="Drama">Drama</option>
            <option value="Music">Music</option>
          </select>
        </div>
  
        {/* Select division */}
        <div style={{ paddingTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Division:</label>
          <select value={division} onChange={(e) => setDivision(e.target.value)}>
            <option value="">Select Division</option>
            <option value="Pre-School PS">Pre-School PS</option>
            <option value="Primary PR">Primary PR</option>
            <option value="Jr. High JH">Jr. High JH</option>
            <option value="Sr. High SH">Sr. High SH</option>
            <option value="Community COM">Community COM</option>
            <option value="Other">Other</option>
          </select>
        </div>
  
        {/* Select subcategory based on entry type and division */}
        <div style={{ paddingTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Subcategory:</label>
          <select value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
            <option value="">Select Subcategory</option>
            {(entryType === "Dance" && division === "Pre-School PS") && (
              <>
                <option value="Classical Ballet">Classical Ballet</option>
                <option value="Bahamian Folk Dance">Bahamian Folk Dance</option>
                <option value="International Folk Dance">International Folk Dance</option>
                <option value="Modern Dance">Modern Dance</option>
                <option value="Tap Dance">Tap Dance</option>
                {/* Add other subcategories for Dance - Pre-School PS */}
              </>
            )}
            {(entryType === "Dance" && division === "Primary PR") && (
              <>
                <option value="Classical Ballet">Classical Ballet</option>
                <option value="Bahamian Folk Dance">Bahamian Folk Dance</option>
                <option value="International Folk Dance">International Folk Dance</option>
                <option value="Modern Dance">Modern Dance</option>
                <option value="Jazz Dance">Jazz Dance</option>
                <option value="Popular Dance">Popular Dance</option>
                <option value="Dance Drama">Dance Drama</option>
                <option value="Liturgical Dance">Liturgical Dance</option>
                <option value="Tap Dance">Tap Dance</option>
                <option value="Cheerleading">Cheerleading</option>
                <option value="Flagettes">Flagettes</option>
                <option value="Best Choreographer">Best Choreographer</option>
              </>
            )}
            {(entryType === "Dance" && division === "Jr. High JH") && (
              <>
                <option value="Classical Ballet">Classical Ballet</option>
                <option value="Bahamian Folk Dance">Bahamian Folk Dance</option>
                <option value="International Folk Dance">International Folk Dance</option>
                <option value="Modern Dance">Modern Dance</option>
                <option value="Jazz Dance">Jazz Dance</option>
                <option value="Popular Dance">Popular Dance</option>
                <option value="Dance Drama">Dance Drama</option>
                <option value="Liturgical Dance">Liturgical Dance</option>
                <option value="Tap Dance">Tap Dance</option>
                <option value="Cheerleading">Cheerleading</option>
                <option value="Flagettes">Flagettes</option>
                <option value="Best Choreographer">Best Choreographer</option>
              </>
            )}
            {(entryType === "Dance" && division === "Sr. High SH") && (
              <>
                <option value="Classical Ballet">Classical Ballet</option>
                <option value="Bahamian Folk Dance">Bahamian Folk Dance</option>
                <option value="International Folk Dance">International Folk Dance</option>
                <option value="Modern Dance">Modern Dance</option>
                <option value="Jazz Dance">Jazz Dance</option>
                <option value="Popular Dance">Popular Dance</option>
                <option value="Dance Drama">Dance Drama</option>
                <option value="Liturgical Dance">Liturgical Dance</option>
                <option value="Tap Dance">Tap Dance</option>
                <option value="Cheerleading">Cheerleading</option>
                <option value="Flagettes">Flagettes</option>
                <option value="Best Choreographer">Best Choreographer</option>
              </>
            )}
            {(entryType === "Dance" && division === "Community COM") && (
              <>
                <option value="Classical Ballet">Classical Ballet</option>
                <option value="Bahamian Folk Dance">Bahamian Folk Dance</option>
                <option value="International Folk Dance">International Folk Dance</option>
                <option value="Modern Dance">Modern Dance</option>
                <option value="Jazz Dance">Jazz Dance</option>
                <option value="Popular Dance">Popular Dance</option>
                <option value="Hip Hop">Hip Hop</option>
                <option value="Liturgical Dance">Liturgical Dance</option>
                <option value="Tap Dance">Tap Dance</option>
                <option value="Cheerleading">Cheerleading</option>
                <option value="Flagettes">Flagettes</option>
                <option value="Best Choreographer">Best Choreographer</option>
              </>
            )}
            {(entryType === "Drama" && division === "Pre-School PS") && (
              <>
                <option value="Individual Verse Speaking">Individual Verse Speaking</option>
                <option value="Choral Verse Speaking">Choral Verse Speaking</option>
              </>
            )}
            {(entryType === "Drama" && division === "Primary PR") && (
              <>
                <option value="Individual Verse Speaking - Grades 1-3">Individual Verse Speaking - Grades 1-3</option>
                <option value="Individual Verse Speaking - Grades 4-6">Individual Verse Speaking - Grades 4-6</option>
                <option value="Choral Verse Speaking - Grades 1-3">Choral Verse Speaking - Grades 1-3</option>
                <option value="Choral Verse Speaking - Grades 4-6">Individual Verse Speaking - Grades 4-6</option>
                <option value="Dramatized Characterization - One Person - Grades 4-6">Dramatized Characterization - One Person - Grades 4-6</option>
                <option value="Dramatized Characterization - Two Persons - Grades 4-6">Dramatized Characterization - Two Persons - Grades 4-6</option>
                <option value="Skit - Grades 1-3">Skit - Grades 1-3</option>
                <option value="Skit - Grades 4-6">Skit - Grades 4-6</option>
                <option value="Storytelling - Grades 1-3">Storytelling - Grades 1-3</option>
                <option value="Storytelling - Grades 5-6">Storytelling - Grades 5-6</option>
              </>
            )}
            {(entryType === "Drama" && division === "Jr. High JH") && (
              <>
                <option value="Individual Verse Speaking">Individual Verse Speaking</option>
                <option value="Choral Verse Speaking">Choral Verse Speaking</option>
                <option value="Dramatized Characterization - One Person - Provided Piece">Dramatized Characterization - One Person - Provided Piece</option>
                <option value="Dramatized Characterization - Two Persons - Provided Piece">Dramatized Characterization - Two Persons - Provided Piece</option>
                <option value="Dramatized Characterization - One Person - Chosen Piece">Dramatized Characterization - One Person - Chosen Piece</option>
                <option value="Dramatized Characterization - Two Persons - Chosen Piece">Dramatized Characterization - Two Persons - Chosen Piece</option>
                <option value="Skit">Skit</option>
                <option value="Play - One Act">Play - One Act</option>
                <option value="Play - Full Length">Play - Full Length</option>
              </>
            )}
            {(entryType === "Drama" && division === "Sr. High SH") && (
              <>
                <option value="Individual Verse Speaking">Individual Verse Speaking</option>
                <option value="Choral Verse Speaking">Choral Verse Speaking</option>
                <option value="Dramatized Characterization - One Person - Provided Piece">Dramatized Characterization - One Person - Provided Piece</option>
                <option value="Dramatized Characterization - One Person - Classic - Provided Piece">Dramatized Characterization - One Person - Classic - Provided Piece</option>
                <option value="Dramatized Characterization - Two Persons - Provided Piece">Dramatized Characterization - Two Persons - Provided Piece</option>
                <option value="Dramatized Characterization - Two Persons - Classic - Provided Piece">Dramatized Characterization - Two Persons - Classic - Provided Piece</option>
                <option value="Dramatized Characterization - Two Persons - Chosen Piece">Dramatized Characterization - Two Persons - Chosen Piece</option>                
                <option value="Skit">Skit</option>
                <option value="Play - One Act">Play - One Act</option>
                <option value="Play - Full Length">Play - Full Length</option>
                <option value="Storytelling">Storytelling</option>
              </>
            )}
            {(entryType === "Drama" && division === "Community COM") && (
              <>
                <option value="Dramatized Characterization - Two Persons - Chosen Piece">Dramatized Characterization - Two Persons - Chosen Piece</option>
                <option value="Dramatized Characterization Original Poetry Spoken Word">Dramatized Characterization Original Poetry Spoken Word</option>
                <option value="Skit">Skit</option>
                <option value="Play - One Act">Play - One Act</option>
                <option value="Play - Full Length">Play - Full Length</option>
                <option value="Storytelling">Storytelling</option>
              </>
            )}
            {(entryType === "Music" && division === "Pre-School PS") && (
              <>
                <option value="Unison Choral Singing Mixed">Unison Choral Singing Mixed</option>
                <option value="Solo Singing for Unchanged Boy’s Voices">Solo Singing for Unchanged Boy’s Voices</option>
                <option value="Solo Singing for Girl’s Voices">Solo Singing for Girl’s Voices</option>
                <option value="Solo Singing for Girl’s Voices - Two contrasting pieces">Solo Singing for Male Voices - Two contrasting pieces</option>
                <option value="Solo Singing for Female Voices - Two contrasting pieces ">Solo Singing for Female Voices - Two contrasting pieces</option>
                <option value="Solo Singing for Female Voices ">Solo Singing for Female Voices </option>



                {/* Add other subcategories for Music - Primary PR */}
              </>
            )}
          </select>
        </div>
  
        {/* Select number of participants */}
        <div style={{ paddingTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Number of Participants:</label>
          <select value={numParticipants} onChange={(e) => setNumParticipants(e.target.value)}>
            <option value="">Number of Participants</option>
            <option value="Solo">Solo</option>
            <option value="Duet">Duet</option>
            <option value="Group">Group</option>
            {/* Add other options for number of participants */}
          </select>
        </div>
  
        {/* Display class number */}
        <div style={{ paddingTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Class Number:</label>
          <input type="text" value={classNumber} readOnly />
        </div>
      </div>
    );
  };




export default class ClementBethelArtsFestivalEntry extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  fee  = form => {
    // Payment per entry
    // $5
    // $10
    // $20
    // Additional $5 or $10 for late fee

    // TODO: Calculate This
    // I don't know when the late fee is or how to go about
    // figuring out the classification of who the form is for
    // to charge them properly

    return 5
  }

  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  
  
 /* 
  classNumCodes = [
    //Pre school
    [ 
      'PS1 A', 'PS1 B', 'PS1 C',
      'PS2 A', 'PS2 B', 'PS2 C',
      'PS3 A', 'PS3 B', 'PS3 C',
      'PS4 A', 'PS4 B', 'PS4 C',
      'PS5 A', 'PS5 B', 'PS5 C'
    ],
    //Primary
    [
      'P1 A', 'P1 B', 'P1 C',
      'P2 A', 'P2 B', 'P2 C',
      'P3 A', 'P3 B', 'P3 C',
      'P4 A', 'P4 B', 'P4 C',
      'P5 A', 'P5 B', 'P5 C',
      'P6 A', 'P6 B', 'P6 C',
      'P7 A', 'P7 B', 'P7 C',
      'P8 A', 'PS8 B', 'P8 C',
      'P9 A', 'P9 B', 'P9 C',
      'P10', 'P11', 'P12'
      
    ],
    //Junior high
    [
      'J1 A', 'J1 B', 'J1 C',
      'J2 A', 'J2 B', 'J2 C',
      'J3 A', 'J3 B', 'J3 C',
      'J4 A', 'J4 B', 'J4 C',
      'J5 A', 'J5 B', 'J5 C',
      'J6 A', 'J6 B', 'J6 C',
      'J7 A', 'J7 B', 'J7 C',
      'J8 A', 'J8 B', 'J8 C',
      'J9 A', 'J9 B', 'J9 C',
      'J10', 'J11', 'J12'
    ],
    //Senior High
    [
      'S1 A', 'S1 B', 'S1 C',
      'S2 A', 'S2 B', 'S2 C',
      'S3 A', 'S3 B', 'S3 C',
      'S4 A', 'S4 B', 'S4 C',
      'S5 A', 'S5 B', 'S5 C',
      'S6 A', 'S6 B', 'S6 C',
      'S7 A', 'S7 B', 'S7 C',
      'S8 A', 'S8 B', 'S8 C',
      'S9 A', 'S9 B', 'S9 C',
      'S10', 'S11', 'S12'
    ],
    //Open & Community Division
    [
      'C1 A', 
      'C2 A', 'C2 B', 'C2 C',
      'C3 A', 'C3 B', 'C3 C',
      'C4 A', 'C4 B', 'C4 C',
      'C5 A', 'C5 B', 'C5 C',
      'C6 A', 'C6 B', 'C6 C',
      'C7 A', 'C7 B', 'C7 C',
      'C8 A', 'CS8 B', 'C8 C',
      'C9 A', 'C9 B', 'C9 C',
      'C10', 'C11', 'C12'
    ]
  ]

  getOutput = (form, classNumCodes) => {
    if (form.entry_list == 'Dance' &&
       form.dance_division == 'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)' &&
       form.class_title_pre == danceCodes.pre_school.categories[0].titles[0] &&
       form.entrants_num == 'Solo')
    {
        return this.classNumCodes[0][0];
    } else {
      return ' '; 
  }
}
*/


  fields = form => [

   /* {
      name: 'booklet',
      view: false,
      hint: bethelBooklet
    }, */
    {
      name: 'class_num_calc',
      label: 'Class No.',
      heading:  <div>
                    <p>For your convenience, we've made it easier to find your class number</p>
                    <ClassNumberCalculator />
                </div>
    },
    "::SPACER",
    '::Competitor Details',
    {
      name: 'i_am_competitor',
      type: 'select:bool',
      label: 'I am applying for myself to compete',
    },
    {
      name: 'gender',
      options: ['Male', 'Female'],
      hide: form.i_am_competitor != false,
    },
    {
      name: 'competitor_first_name',
      label: 'First Name',
      hide: form.i_am_competitor != false,
    },
    {
      name: 'competitor_middle_name',
      label: 'Middle Name',
      hide: form.i_am_competitor != false,
      required: false
    },
    {
      name: 'competitor_last_name',
      label: 'Last Name',
      hide: form.i_am_competitor != false,
    },
    {
      name: 'competitor_address',
      label: 'Address',
      hide: form.i_am_competitor != false,
    },
    {
      name: 'competitor_mobile_numb',
      type: 'phone',
      label: 'Mobile Phone #',
      hide: form.i_am_competitor != false,
    },
    {
      name: 'competitor_phone_numb',
      type: 'phone',
      label: 'Home Phone #',
      hide: form.i_am_competitor != false,
      required: false,
    },
    {
      name: 'competitor_email',
      type: 'email',
      label: 'Email',
      hide: form.i_am_competitor != false,
    },
    {
      name: 'employed_position',
      label: 'Position Employed As',
      options: [
        'Director of Choir',
        'Producer',
        'Choreographer'
      ]
    },
    {
      name: 'school_name',
    },
    {
      name: 'entry_list',
      label: 'Entry Type',
      options: [
        'Drama',
        'Music',
        'Dance',
        'Art & Craft',
      ]
    },
    {
      name: 'entrants_num',
      label: 'Amount of entrants',
      options: [
          'Solo',
          'Duet',
          'Group'
          ],  
    },
    {
      name: 'entrants_num_group',
      label: 'Please provide the number of females and males in the group',
      columns: [
        {
          name: 'males',
          is: 'integer'
        },
        {
          name: 'females',
          is: 'integer'
        }
      ],
      hide: form.entrants_num != 'Group'
    },
    /*{
      name: 'class_title',
      label: 'Title of Class',
    },
    {
      name: 'instruments_list',
      label: 'Instruments',
      required: false,
      hint: 'Applicable for instrument class only',
      columns: ['instrument'],
      format: v => v.instrument,
    },*/
    
    //Dance Form
    {
      heading: `<strong>Dance Form</strong>`,
      hide: form.entry_list != 'Dance'
    },
    {
      name: 'dance_deadline_date',
      label: 'Check your island deadline date for each island',
      options: [
          `Abaco (inclusive of Moore's islands, Man-o-War Cay, Hope Town, Green Turtle Cay) March 8th, 2024`,
          'Andros Feb 16th, 2024',
          'Bimini Feb 9th, 2024',
          'Berry Islands Feb 9th, 2024',
          'Cat Island Jan 26th, 2024',
          'Eleuthera (inclusive of Spanish Wells & Harbour Island) Apr 19th, 2024',
          'Exuma (Main Island) Jan 19th, 2024',
          'Grand Bahama Mar 22nd, 2024',
          'New Providence Apr 5th, 2024',
         // 'Rum Cay Feb 15th',
          //'Ragged Island Feb 15th',
          'Long Island Jan 26th, 2024',
          'Southern Islands (Inagua, Mayaguana, Acklins, Crooked Island, San Salvador) Feb 23rd, 2024',
          'Other'
  ],
   hide: form.entry_list != 'Dance'
  },
  {
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.dance_deadline_date != 'Other',
  },
  {
      name: 'school_or_ensemble',
      label:'Name of school or Ensemble',
      hide: form.entry_list != 'Dance'

  },
  //Division conditionals
  {
      name: 'dance_division',
      options: [
          'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Community COM (10 Dollars Single 20 Dollars Group Ensemble)',
          'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Other'
      ],
      hide: form.entry_list != 'Dance'

  },
  {
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.dance_division !='Other',
  },
  {
      name: 'class_title_pre',
      label: 'Class Title',
      options: danceCodes.pre_school.categories[0].titles,
      hide: form.dance_division != 'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
      name: 'class_title_primary',
      label: 'Class Title',
      options: danceCodes.primary_school.categories[0].titles,
      hide: form.dance_division != 'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
      name: 'class_title_junior',
      label: 'Class Title',
      options: danceCodes.junior_high.categories[0].titles,
      hide: form.dance_division != 'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
      name: 'class_title_senior',
      label: 'Class Title',
      options: danceCodes.senior_high.categories[0].titles,
      hide: form.dance_division != 'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
      name: 'class_title_com',
      label: 'Class Title',
      options: danceCodes.open_and_community.categories[0].titles,
      hide: form.dance_division != 'Community COM (10 Dollars Single 20 Dollars Group Ensemble)'
  },
  {
      name: 'name_of_guardian',
      label: 'Name of Director/Teacher/Parent',
      hide: form.entry_list != 'Dance' 
  }, 
  

  //Drama Form

  {
      heading: `<strong>Drama Form</strong>`,
      hide: form.entry_list != 'Drama'
  },
  {
    name: 'drama_deadline_date',
    label: 'Island Deadline Date',
    options: [
        'Abaco February 15th',
        'Acklins February 15th',
        'Andros April 1st',
        'Bimini May 1st',
        'Berry Islands May 1st',
        'Cat Island Feb 15th',
        'Crooked Island Feb 15th',
        'Eleuthera May 1st',
        'Exuma Feb 15th',
        'Grand Bahama April 1st',
        'Inagua Feb 15th',
        'New Providence March 1st',
        'Mayaguana Feb 15th',
        'San Salvador Feb 15th',
        'Rum Cay Feb 15th',
        'Ragged Island Feb 15th',
        'Long Island May 1st',
        'Other'
    ],
    hide: form.entry_list != 'Drama'
  },
  {
    name:'other',
    label: ' ',
    hint: 'Please specify',
    hide: form.drama_deadline_date !='Other',
  },
  {
    name: 'drama_division',
    options: [
        'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)',
        'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)',
        'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)',
        'Community COM (10 Dollars Single 20 Dollars Group/Ensemble)',
        'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)',
        'Other'
    ],
    hide: form.entry_list != 'Drama'
  },
  {
    name: 'drama_division_other',
    label: ' ',
    hint: 'Please specify',
    type: 'textarea',
    hide: form.drama_division != 'Other'
  },
  {
    name: 'class_title_pre',
    label: 'Class Title',
    options: dramaCodes.pre_school.categories[0].titles,
    hide: form.drama_division != 'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
    name: 'class_title_primary',
    label: 'Class Title',
    options: dramaCodes.primary_school.categories[0].titles,
    hide: form.drama_division != 'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
    name: 'class_title_junior',
    label: 'Class Title',
    options: dramaCodes.junior_high.categories[0].titles,
    hide: form.drama_division != 'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
    name: 'class_title_senior',
    label: 'Class Title',
    options: dramaCodes.senior_high.categories[0].titles,
    hide: form.drama_division != 'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)'
  },
  {
    name: 'class_title_com',
    label: 'Class Title',
    options: dramaCodes.open_and_community.categories[0].titles,
    hide: form.drama_division != 'Community COM (10 Dollars Single 20 Dollars Group/Ensemble)'
  },
  {
    name: 'works_name',
    label: 'Title of Work(s)',
    columns: ['title'],
    format: v => v.title,
    hide: form.entry_list != 'Drama'
  },
  {
    name: 'school_or_ensemble',
    label:'Name of school or Ensemble',
    hide: form.entry_list != 'Drama'
  },
  /*{
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.level !='Other',
  },*/
  {
    name: 'name_of_guardian',
    label: 'Name of Director/Teacher/Parent',
    hide: form.entry_list != 'Drama'
  },


//Film Form
{
  heading: `<strong>Film Form</strong>`,
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'nursery_rhyme',
  label: 'Which Nursery Ryhme/Ring Play is your film based on?',
  hint: 'Your film must be based on one of these options',
  options: [
      'Georgie Peorgie',
      'Jack and Jill',
      'Little Miss Muffet',
      'Little Boy Blue',
      'Mary Had a Little Lamb',
      'Pat-a-Cake',
      'Diddle Diddle Dumpling',
      'Little Jack Horner',
      'Mary Mary Quite Contrary',
      'Baa Baa Black Sheep',
      'None'
      //unable to complete if none is selected
  ],
  hide: form.entry_list != 'Art & Craft'
},
{
  name:'name_of_film',
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'name_of_organization',
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'film_category',
  label: 'Category of film',
  options: [
      'Narrative',
      'Documentary',
      'PSA',
      'Musical or Music Video',
      'Experimental'
  ],
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'film_duration',
  label: 'Duration of film',
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'island',
  options: islands,
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'level_film',
  label: 'Level',
  options: [
      'Primary PR ($5 Single $10 Group/Ensemble)',
      'Jr. High JH ($5 Single $10 Group/Ensemble)',
      'Sr. High SH ($5 Single $10 Group/Ensemble)',
      'Community COM ($10 Single $20 Group/Ensemble)',
      'Pre-School PS ($5 Single $10 Group/Ensemble)',
      'Other'
  ],
  hide: form.entry_list != 'Art & Craft'
}, 
{
  name:'other',
  label: ' ',
  hint: 'Please specify',
  hide: form.level_film !='Other',
}, 
{
  name: 'film_division',
  options: [
      'Primary PR ($5 Single)',
      'Primary PR ($10 Groupe)',
      'Jr. High JH ($5 Single)',
      'Jr. High JH ($10 Group/Ensemble)',
      'Sr. High SH ($5 Single)',
      'Sr. High SH ($10 Group/Ensemble)',
      'Community COM ($10 Single)',
      'Community COM ($20 Group/Ensemble)',
      'Pre-School PS ($5 Single $10 Group/Ensemble)',
      'Other'
  ],
  hide: form.entry_list != 'Art & Craft'
},
{
  name:'other',
  label: ' ',
  hint: 'Please specify',
  hide: form.film_division !='Other',
},
{
  name: 'recording_software',
  label: 'Recording and editing software',
  type: 'textarea',
  hide: form.entry_list != 'Art & Craft'
},
/*{
  name: 'film_sumbissions',
  key: 'image.0',
  type: 'file',
  hint: 'No larger than 1 GB',
  hide: form.entry_list != 'Art & Craft'
  //video upload'
  //Also must be able to accept links from youtube, vimeo, dropbox, google drive and one drive
},*/
{
  name: 'decription_submission',
  heading: `<p>Film entrants are to include the following info in WRITING with their Submission Package; these should also be included in the video credits at the end of the film: <br> i. Name of Commmunity Group or Production Company producing the film <br> ii. The full names of all persons who assisted and who worked on the film <br> iii. The roles of the persons who assisted; be sure to indicate those who they wish to nominate; Eg. - Writer, Director, Actor, etc. <br> iv. All actor's isNamespaceExportDeclaration, alongside the name of the character they played <br> v. Any narrator's or interviewees' names <br><br> In the case of school submissions: <br> vi. Name of school producing film <br> vii. The full names of the child/children for whom the film is being submitted</p>`,
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'written_submission_upload',
  key: 'image.1',
  type: 'file:pdf',
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'synopsis_upload',
  label: 'Provide a brief synopsis of the Film.', 
  hint: 'Accepts: pdf. Include credits',
  type: 'file:pdf',
  key: 'image.2',
  hide: form.entry_list != 'Art & Craft'
},
{
  name: 'acceptance',
  type: 'checkbox',
  use_as_acceptance: true,
  label:
   ` I declare that the film piece(s) submitted with this application represents my original work, by myself or someone on my production team; except in the instances where I have acknowledged the in writing the ideas, words, images, or material of other writers, filmmakers, musicians or songwriters. `,
   hide: form.entry_list != 'Art & Craft'
},

//Music Instrumental Form
  {
    heading: `<strong>Music Form</strong>`,
    hide: form.entry_list != 'Music',
  },
  {
    name: 'music_choice',
    label: 'What form of music will you be presenting?',
    options: ['Instrumental', 'Vocal'],
    hide: form.entry_list != 'Music',
  },
  {
    name: 'instrument_type',
    label: 'What type of instrument will you be using?',
    options: ['Keyboard','String', 'Brass','Woodwind'],
    hide: form.music_choice != 'Instrumental'
  },
  {
    name: 'instrument_used_keyboard',
    label: 'Which instrument will you be using?',
    options: ['Piano', 'Organ', 'Harpsichord', 'Accordian'],
    hide: form.instrument_type != 'Keyboard',
  },
  {
    name: 'instrument_used_string',
    label: 'Which instrument will you be using?',
    options: ['Violin', 'Viola', 'Cello', 'Double Bass', 'Guitar', 'Harp'],
    hide: form.instrument_type != 'String'
  },
  {
    name: 'instrument_used_brass',
    label: 'Which instrument will you be using?',
    options: ['B Flat Trumpet/ Cornet/ Flugel', 'French Horn', 'Trombone', 'Baritone', 'Euphonium', 'Tuba'],
    hide: form.instrument_type != 'Brass'
  },
  {
    name: 'instrument_used_woodwind',
    label: 'Which instrument will you be using?',
    options: ['Flute', 'Piccolo', 'Clarinet', 'Bass Clarinet', 'Soprano Saxophone', 'Alto Saxophone', 'Tenor saxophone', 'Oboe', 'Bassoon'],
    hide: form.instrument_type != 'Woodwind'
  },
  {
    name: 'music_level',
    options: ['Easy', 'Intermediate', 'Advanced'],
    hide: form.entry_list != 'Music'
  },
  {
    name: 'music_deadline_date',
    label: 'Island Deadline Date',
    options: [
        'Abaco February 15th',
        'Acklins February 15th',
        'Andros April 1st',
        'Bimini May 1st',
        'Berry Islands May 1st',
        'Cat Island Feb 15th',
        'Crooked Island Feb 15th',
        'Eleuthera May 1st',
        'Exuma Feb 15th',
        'Grand Bahama April 1st',
        'Inagua Feb 15th',
        'New Providence March 1st',
        'Mayaguana Feb 15th',
        'San Salvador Feb 15th',
        'Rum Cay Feb 15th',
        'Ragged Island Feb 15th',
        'Long Island May 1st',
        'Other'
],
    hide: form.entry_list != 'Music'
},
  {
    name:'other',
    label: ' ',
    hint: 'Please specify',
    hide: form.music_deadline_date !='Other',
  },
  {
    name: 'title_of_piece',
    label: 'Title of Piece(s)',
    columns: ['piece'],
    format: v => v.piece,
    hide: form.entry_list != 'Music'
  },
  {
    name: 'school_or_ensemble',
    label:'Name of school or Ensemble',
    hide: form.entry_list != 'Music'
  },
  {
    name: 'music_division',
    options: [
        'Primary PR ($5 Single)',
        'Primary PR ($10 Group)',
        'Jr. High JH ($5 Single)',
        'Jr. JH ($10 Group/Ensemble)',
        'Sr. High SH ($5 Single)',
        'Sr. High SH ($10 Group/Ensemble)',
        'Community COM ($10 Single)',
        'Community COM ($20 Group Ensemble)',
        'Pre-School PS ($5 Single $10 Group/Ensemble)',
        'Other'
    ],
    hide: form.entry_list != 'Music'
},
/*{
    name:'other',
    label: ' ',
    hint: 'Please specify',
    hide: form.music_level !='Other',
},*/

//Music Vocal Form
/*{
  name: 'Instrument',
  hide: form.music_choice != 'Vocal'
},*/
{
  name:'other',
  label: ' ',
  hint: 'Please specify',
  hide: form.vocal_deadline_date !='Other',
},
/*{
  name: 'piece_name',
  label: 'Name of Piece(s)',
  hide: form.music_choice != 'Vocal'
},*/
{
  name:'other',
  label: ' ',
  hint: 'Please specify',
  hide: form.vocal_level !='Other',
},
{
  name: 'name_of_guardian',
  label: 'Name of Director/Teacher/Parent',
  hide: form.music_choice != 'Vocal'
},

//Piano Form
  {
    name:'other',
    label: ' ',
    hint: 'Please specify',
    hide: form.piano_deadline_date !='Other',
  },
  /*{
    name: 'piece_name',
    label: 'Name of Piece(s)',
    hide: form.instrument_used_keyboard != 'Piano'
  },*/
/*{
    name:'other',
    label: ' ',
    hint: 'Please specify',
    hide: form.level !='Other',
},*/
{
  name: 'name_of_guardian',
  label: 'Name of Director/Teacher/Parent',
  hide: form.instrument_used_keyboard != 'Piano'
},

//Strings Form
{
  name: 'string_title_piece',
  label: 'Title of Piece',
  hide: form.instrument_type!= 'String'
},
{
  name:'other',
  label: ' ',
  hint: 'Please specify',
  hide: form.string_deadline_date !='Other',
},
{
  name: 'string_level',
  options: [
      'Primary PR ($5 Single $10 Group/Ensemble)',
      'Jr. High JH ($5 Single $10 Group/Ensemble)',
      'Sr. High SH ($5 Single $10 Group/Ensemble)',
      'Community COM ($10 Single $20 Group/Ensemble)',
      'Pre-School PS ($5 Single $10 Group/Ensemble)',
      'Other'
  ],
  hide: form.instrument_type!= 'String',
},
{
  name:'other',
  label: ' ',
  hint: 'Please specify',
  hide: form.string_level !='Other',
},


  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
