import * as T from '@action-types'

const initial = {
  loading : false,
  sendMsg : false,
  error   : null,
  notes   : [],
  note    : {},
}

const notes = (state = initial, { type, payload }) => {

  switch (type) {
    case T.GET_ALL_NOTES:
      return {
        ...state,
        loading: false,
        notes: payload.response.notes,
        total_count: payload.response.total_count
      }
    case T.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.response.data.error
      }
    case T.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case T.SEND_MESSAGE_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        sendMsg: true
      }
    case T.GET_PARTICULAR_NOTE:
      return {
        ...state,
        loading: true,
        error: null,
        note: payload.response.note
      }
    default:
      return {
        ...state,
        loading: false
      }
  }
}

export default notes
