import React from 'react'
import AgriImport from './_agri-import'
import fruit_veggies from '@constants/fruits-veggies'

export default function FruitVeggieImport(props) {
  const pass = {
    ...props,

    resource: '/fruit_and_veggies',
    form_key: 'fruit_and_veggie',
    _sid: 'fruit and veggie',

    form_name: 'Fruit/Veggies',
    importProps: {
      metric_list: [
        'Each Per Item',
        'Cases per item',
        'Bags per item',
        'Bins per item',
        'Pounds per item',
        'Sacks per item',
      ],
      cb: true,
      datalist: fruit_veggies,
    },
  }

  return <AgriImport {...pass} />
}
