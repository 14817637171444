import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class EconomicEmpowermentZonesI extends React.Component {
  id = "EconomicEmpowermentProperty";
  service = "economic empowerment property";
  title = "Economic Empowerment Zones Form I";
  agency = "mofrd";
  item = "";
  resource = "/economic_empowerment_properties";
  form_key = "economic_empowerment_property";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "client_type",
      label: "Name of Applicant",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "bl_number",
      label: "Business License No.",
      is: "integer",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "tax_id_number",
      label: "Tax Identification No.",
      is: "integer",
    },
    {
      name: "tax_comp_cert_issue",
      label: "Tax Compliance Certificate Issue Date",
      type: "date",
      maxDate: new Date(),
      showYearDropdown: true,
    },
    {
      name: "tax_comp_cert_expiry",
      label: "Tax Compliance Certificate Expiry Date",
      type: "date",
      showYearDropdown: true,
    },
    {
      name: "company_place",
      label: "Place of Incorporate",
    },
    {
      name: "company_date",
      label: "Date of Incorporate",
      type: "date",
      showYearDropdown: true,
    },
    {
      name: "directors",
      label: "Names of Directors, Officers and Beneficial Owners",
      type: "textarea",
      list: true,
      placeholder: "Separate names using commas (e.g., John, David, Mark, etc)",
    },
    {
      name: "address",
      label: "Street Address of Business",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "description",
      label: "Description",
      options: [
        { label: "Construction", value: "construction" },
        { label: "Reconstruction", value: "reconstruction" },
        { label: "Extension", value: "extension" },
      ],
    },
    {
      name: "reason",
      label: "Reason for Exemption",
      options: [
        { label: "Construction", value: "construction" },
        { label: "Renovation", value: "renovation" },
        { label: "Repair", value: "repair" },
      ],
    },
    {
      name: "description_of_products",
      label: "Description of the type of Products sold",
    },
    {
      name: "project_cost",
      label: "Estimated Total Project Cost",
      type: "currency",
    },
    {
      name: "permit_number",
      label: "Building Permit No.",
    },
    {
      name: "project_start_date",
      label: "Start Date to complete construction, reconstruction or extension",
      type: "date",
      showYearDropdown: true,
    },
    {
      name: "project_end_date",
      label: "End Date to complete construction, reconstruction or extension",
      type: "date",
    },
    {
      name: "incorporation_doc_upload",
      label: "Copy of Incorporation Documents",
      type: "file:image",
      showYearDropdown: true,
    },
    {
      name: "construction_plan_upload",
      label: "Copy of Construction Plan approved by Ministry of Public Works",
      type: "file:image",
    },
    {
      name: "materials_upload",
      label:
        "Copy of Materials list, prepared by a construction professional for all building material required for the project",
      type: "file:image",
    },
    {
      name: "entitlement_upload",
      label: "Proof of entitlement to develop the property",
      type: "file:image",
    },
    {
      name: "declaration",
      label:
        "I hereby declare that the above information is true and correct to the best of my knowledge, information and belief",
      type: "checkbox",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
