import { useEffect, useState } from 'react'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import Viewer from '../../modules/form-wizard/view'
import Swal from 'sweetalert2'

// import StateStore from '@helpers/state-store'
import JsEncrypt from 'jsencrypt'
import ENV from '@constants/env'
import toastr from '@modules/toastr'
import DataTable from 'react-data-table-component'
import { Menu, MenuItem } from '@szhsin/react-menu'
import * as STEPS from '../../modules/form-wizard/steps'
import * as wutils from '../../modules/form-wizard/utils'
import { extractKeys, getProperty, hasAnyKeys } from '@helpers/objects'
import axios from 'axios'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import Loader from '@shared/loader'
import verifyDigitalCert from '@modules/certs/verify'
import SteppedForm from '@modules/form-wizard/form.container'

const CASHLESS_URL = ENV.CASHLESS_URL

/* eslint-disable no-unused-vars */
const today = new Date()

const NationalSymbolPermissionRenewal = props => {
  // const [formSelected, setFormSelected] = useState(null)
  // const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  // const [clear] = useState(false)
  const [is, setIs] = useState({});
  const [step, setStep] = useState();
  const [dataNew, setDataNew] = useState([])
  const [dataRenewal, setDataRenewal] = useState([])
  // const [active, setActive] = useState({})
  const [modal, setModal] = useState([])
  const [paramsNew, setParamsNew] = useState({
    approved_only: true,
    page: 1,
    per_page: 20,
  })
  const [paramsRenewal, setParamsRenewal] = useState({
    renewal: true,
    page: 1,
    per_page: 20,
  })
  const resource = '/national_symbol_permissions';
  const isRenewal = true;
  // const form_key = 'national_symbol_permission';
  const { history } = props

  const sublink = path => [props.match.url, path].join('/')

  const generatePermissions = (roles, { code, admin: adm, dispatcher: dis }) => {
    const admin = roles.includes(adm)
    const dispatcher = roles.includes(dis || code + '_dispatcher')
    const support = roles.includes('support')

    return {
      admin,
      dispatcher,
      support,
      citizen: !admin && !dispatcher && !support,
    }
  }

  const navigate = (path, update, options) => {
    const next = path ? sublink(path) : props.location.pathname

    const state =
      options?.overwrite || update === null
        ? {}
        : { ...(props.location.state || {}) }

    typeof update == 'object' && Object.assign(state, update)
    history[options?.replace ? 'replace' : 'push'](next, state)

    return props.location.state
  }

  const fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(CASHLESS_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        console.error(err)
      }
    },

    rsa: async () => {
      try {
        const { data } = await axios.get(CASHLESS_URL + '/fetch_rsa_key')
        return {
          pubkey: data.rsa_key.public_key,
          secret: data.rsa_key.rsa_token,
        }
      } catch (err) {
        console.error(err)
      }
    },

    invoice: async id => {
      try {
        const Authorization = await fetch.token()
        const { data } = await axios.get(
          CASHLESS_URL + '/invoices/by_invoice_number/' + id,
          {
            headers: { Authorization },
          }
        )

        return data
      } catch (err) {
        console.error(err)
      }
    },

    profile: async id => {
      try {
        const { data } = await $app.axios.get(
          '/profiles' + (id ? '/' + id : '')
        )
        return data
      } catch (err) {
        console.error(err)
        return null
      }
    },

    company: async (bl_numb = '', nib = '') => {
      try {
        const { data } = await $app.axios.post('/fetch_company_verification', {
          use_tin: false,
          bl_numb,
          nib
        })
        return data
      } catch (err) {
        console.error(err)
        return null
      }
    },

    FPOCompany: async (bl_numb = '') => {
      try {
        const { data } = await $app.axios.post('/fetch_company_verification', {
          use_tin: true,
          bl_numb,
          nib: '###',
        })
        return data
      } catch (err) {
        console.error(err)
        return null
      }
    },
  }

  const serializeFields = (form, fields, isLinkedRecord = false) => {
    const perm = is;

    // prettier-ignore
    return fields.map(f => {
      const { name, view, save, hide, type, is, admin, columns, fields } = f

      if (typeof f == 'string') {
        if (f == '::SPACER') {
          return { heading: '<span>&nbsp;</span>' }
        } else if (f.startsWith('::')) {
          return f.length == 2 ? null : { heading: f.slice(2) }
        } else if (f == 'auth_dcode') {
          return {
            label: 'AUTH DCODE',
            value: form.auth_dcode,
          }
        }

        return {
          name: f,
          label: f?.initialCaps(),
          value:
            typeof form[f] == 'boolean'
              ? form[f]
                ? 'Yes'
                : 'No'
              : form[f] || 'N/A',
        }
      }

      if (f.view?.element) {
        return {
          name: f.name,
          label: view?.label || f.label || name?.initialCaps(),
          value: 'CUSTOM'
        }
      }

      // Skip hide check for columns.
      // Just show if there is data
      if ((columns || fields) && form[name]?.length) {
        const use = columns ?? fields
        const val = form[name]

        if (name == 'shareholders' && typeof val[0] == 'string') {
          return {
            name,
            label: 'Shareholders',
            value: val.map(str => JSON.parse(str).name).join(', '),
            long: true,
          }
        }

        return {
          name,
          label: view?.label || f.label || name?.initialCaps(),
          value: view?.format?.(val) || val,
          long: true,
          table: !!columns,
          array: !!fields,

          fields: use
            .filter(col => !col.hide && col.view !== false)
            .map(col => {
              for (let [prop, val] of Object.entries(col)) {
                if (typeof val == 'function') delete col[prop]
              }

              return col
            }),
        }
      }

      if (false
      || (type == 'linked' && isLinkedRecord)
      || (admin && !perm.admin)
      || (save === false && !view)
      || view === false
      || view?.hide
      || (hide && view == undefined)
      || (type == 'checkbox' && view?.hide !== false)
      ) return null

      if (f.view?.heading) {
        return { heading: f.view.heading }
      }

      if (f.heading) return f

      const label = view?.label || f.label || name?.initialCaps()
      let value = form[name]

      // type 'linked' fields will see if the `name` maps to an object
      // and create viewLinkedApplication() link if it finds it
      // otherwise, it will be skipped
      if (type == 'linked') {
        switch (typeof getProperty(form, name)) {
          case 'string':
            try {
              // Test string; could be stringified object
              JSON.parse(getProperty(form, name))
              return { name, label, long: f.long, value: 'LINKED' }
            } catch (err) {
              return null
            }

          case 'object':
            return { name, label, long: f.long, value: 'LINKED' }

          default:
        }
      }

      if (typeof view?.value == 'function') {
        value = view.value(value)
      } else if (/^file:?/.test(type)) {
        value = wutils.getFieldLink(form, f)
        return { name, label, value }
      } else if (f.options && !f.multi) {
        value = f.options.find(opt => (opt?.value ?? opt) == value)
        if (value?.value) value = value.label
        return { name, label, value }
      }

      try {
        value = false
          || (typeof value == 'boolean' && (value ? 'Yes' : 'No'))
          || (!value && 'N/A')
          || (f.profile && form.user[f.name])
          || (f.use_profile && form.user[f.use_profile])
          || (f.columns && typeof value == 'object' && JSON.parse(value))
          || (f.fields && typeof value == 'object' && JSON.parse(value))
          || (f.type == 'link' && wutils.getUploadPath(value))
          || (/(alphanumeric|integer)/.test(is) && value)
          || (is == 'currency' && '$' + Number(value).toFixed(2))
          || (type == 'select:bool' && (value ? 'Yes' : 'No'))
          || ((/(number|#)$/i.test(label) || Number.isNaN(+value)) && value)
          || (typeof value == 'string' && value?.initialCaps())
          || (+value).toFixed(2)
        } catch (err) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Failed to serialize "${name}". Value was...`)
            console.log(value)
          }
        }
        
        return { name, label, value, long: type == 'textarea' }
    }).filter(f => f)
  }

  const determineActions = (record = {}, viewing = false) => {
    // const { state, props, email, proxy, notes, delivery, navigate } = this
    const { mode } = props?.location?.state

    const actions = []
    const action = (text, icon, fn) => ({
      icon: /^fa(r|s)\s/.test(icon) ? icon : 'fas fa-' + icon,
      text,
      fn,
    })

    const isPostpaid = (p => {
      if (p === true) return true
      if (typeof p == 'function' && p(record)) return true
      return false
    })(props.postpaid)


    if (props.pausable && mode == 'citizen') {
      actions.push(
        action(
          'Resume Application',
          'chevron-right',
          this.resumeApplication(record)
        )
      )
    }

    !viewing && actions.push(
      action('View Application', 'far fa-file', this.viewSubmission(record))
    )

    if (mode == 'citizen') {
      if (
        !(
          props.free ||
          record.payment_status ||
          record.application_status ||
          (isPostpaid && !record.payment_amount) ||
          props.pay_on_approval
        ) || (props.pay_on_approval && record.application_decision == 'approved' && !record.payment_status)
      ) {

        actions.push(
          action(
            props.has_appointment && !record.__appointment?.id
              ? 'Set Appointment & Pay'
              : 'Pay Now',
            'dollar-sign',
            this.goToPayment()(record, { skip_proxy: true })
          )
        )}
    }

    if (mode == 'admin') {
      const { location, hide_buttons } = props
      const pendingSubs = record.services?.some(r => !r.application_status)
      const person = location.state?.user || record.user
      if (!person) return

      const shouldShow = prop => {
        const value = hide_buttons?.[prop]

        switch (typeof value) {
          case 'function':
            return !value(record)
          case 'boolean':
            return !value
          default:
            return true
        }
      }

      if (
        props.has_appointment &&
        record.payment_status &&
        !record.__appointment
      )
        actions.push(
          action('Set Appointment', 'calendar-plus', () => {
            this.props.history.push('/appointments', {
              redirect: this.props.match.path + '/applications',
              createModal: true,
              userInfo: {
                user_id: record?.user?.id,
                user_first_name: record?.user?.first_name,
                user_last_name: record?.user?.last_name,
                user_email: record?.user?.email,
              },
              schedulable_type: this.props.service.type,
              recordId: record?.id,
            })
          })
        )

      if (
        props.has_appointment &&
        record.payment_status &&
        record.__appointment
      )
        actions.push(
          action('View Appointment', 'calendar-alt', () => {
            this.props.history.push('/appointments', {
              redirect: this.props.match.path + '/applications',
              appointment: {
                user_id: record?.user.id,
                schedulable_id: record?.id,
                schedulable_type: this.props.service.type,
              },
              initialDate: record?.__appointment.slot,
            })
          })
        )

      if ((typeof props.postpaid == 'function' ? props.postpaid(record) : props.postpaid)
        && !record.payment_status && shouldShow('fee')
      ) {
        actions.push(
          action('Set Amount', 'dollar-sign', this.setApplicationFee(record))
        )
      }
    }

    do {
      if (record.application_decision != 'approved') break

      let cert
      const certLinkRx = /\/pdf_uploads\//

      if (Array.isArray(record.image)) {
        cert = record.image.find(p => certLinkRx.test(p))
      } else if (typeof record.image == 'string') {
        cert = certLinkRx.test(record.image) ? record.image : null
      }
      
      if (!cert || cert.includes('original/missing.png')) break

      const openCert = () => {
        const url = wutils.getUploadPath(cert, true)
        window.open(url, '_blank')
      }

      const verifyCert = async () => {
        this.setState({ loading: true })
        const { form_num } = record
        const service = props.service.type
        verifyDigitalCert(service, form_num, {
          onFetch: () => this.setState({ loading: false }),
        })
      }

      actions.push(
        action('View Certificate', 'award', openCert),
        action('Verify Certificate', 'shield-alt', verifyCert)
      )
    } while (false)

    if (hasAnyKeys(props, 'custom_action', 'custom_actions')) {
      const fn = mode == 'citizen' ? act => act.user : act => !act.user
      const customs = (props.custom_actions || [props.custom_action])
        .filter(fn)
        .filter(act => {
          if (act.table) return false
          if (wutils.failsRoleRequirements(act.roles)) return false
          if (act.test && !act.test(record)) return false
          return true
        })
        .map(act => ({ ...act, fn: () => act.fn(record) }))

      actions.push(...customs)
    }

    return actions
  }

  const viewSubmission = (record, replace = false, options = {}) => async () => {
    setLoading(true)

    // const { props, is, navigate, serializeFields } = this
    const { hooks } = props
    const {
      auth_dcode,
      form_num,
      user,
      user_id,
      payment_invoice,
      serviceables,
      services,
    } = record

    const arrFields = []
    let business = null

    // Handle linked fields
    if (options?.linked) {
      if (options.fields) {
        arrFields.push(...options.fields(record, true))
      } else {
        arrFields.push(...fields(record, true))
        if (hidden_fields) arrFields.push(...hidden_fields(record, true))
      }

      // Can't reliably serialize fields array so need to delete it
      // It will still be available as a prop on the sub/linked view component
      delete options.fields

      setLoading(false)

      navigate('applications/subview/', {
        view: {
          ...props.location.state?.view,
          fields: serializeFields(record, arrFields, true),
        },
        record,
        options,
      })

      return
    }

    record.user = record.user?.id == $app.current_user.id
      ? $app.current_user
      : record.user || props.location.state?.user || $app.user

    if (!record.requested_proxy) {
      const userid = user?.user_id || user?.id || user_id
      const profile = await fetch.profile(!is.citizen && userid)
      record.user = { ...record.user, ...profile }
      $app.setApplicant(record.user)
    }

    if (auth_dcode && is.admin) arrFields.push('auth_dcode')
    arrFields.push(...fields(record, true))
    if (hidden_fields) arrFields.push(...hidden_fields(record, true))
    

    // prettier-ignore
    const payment_amount = record.payment_amount
      || (typeof fee == 'function' ? await fee(record) : fee)

    if (hooks?.view) {
      const added = await hooks.view(record)
      typeof added == 'object' && Object.assign(record, added)
    }

    const blNum = record[props.bl_field ?? 'bl_number']
    const bNib = record[props.bnib_field ?? 'business_nib']
    if (blNum && props.service.for_companies) {
      const { company_data } = await fetch.company(blNum, bNib)
      business = company_data
    }

    const view = {
      ...extractKeys(
        record,
        'id',
        'form_num',
        'created_at',
        'pickup_location',
        'pickup_sub_location',
        'application_decision',
        'payment_invoice',
        'payment_amount',
        'payment_status',
        'user'
      ),
      fields: serializeFields(record, arrFields),
      payment_amount,
    }

    if (payment_invoice) {
      const invoice = await fetch.invoice(payment_invoice)
      Object.assign(view, { invoice })
    }

    if (serviceables) {
      const dependents = services.map((service, i) => ({
        service_type: serviceables[i].match(/'service_type'=>'(\w+)',/)[1],
        application_decision: service.application_decision,
      }))

      Object.assign(view, { dependents })
    }

    setLoading(false)

    navigate('applications/view/' + form_num, {
      view,
      business,
      record,
      options,
    }, { replace })
  }


  const setSuccessMessage = async form => {
    const { service } = props
    let msg

    if (form.pickup_location) {
      try {
        const { data } = await $app.axios.get('/agency_services', {
          params: {
            'service[name]': service.name,
            ...extractKeys(
              form,
              'pickup_location:service[island]',
              'pickup_sub_location:service[street_address]'
            ),
          },
        })

        msg = data.service?.toast_msg
      } catch (err) {
        console.error(
          'Unable to fetch location specific service success message'
        )
        console.error(err)
      } finally {
        navigate(null, {
          success_message: msg || service.messaging.submitted,
        })
      }
    } else {
      navigate(null, {
        success_message: service.messaging.submitted,
      })
    }
  }

  const post = (action, other) => async data => {
    const hookname = 'post:' + action
    // const { props, navigate, goToPayment, updateView } = this
    const {
      hooks,
      location,
    } = props

    try {
      if (hooks?.[hookname]) {
        const keepGoing = await hooks[hookname](data, other)

        if (keepGoing === false) {
          setLoading(false)
          return
        }
      }
    } catch (err) {
      console.error(err)
      return
    }

    switch (action) {
      case 'update:payment':
        toastr.success('Success', 'Payment Successful')
        setLoading(false)
        setStep(STEPS.FINISH)

        if (dataRenewal?.length) {
          Object.assign(
            dataRenewal.find(r => r.form_num == data.form_num) || {},
            dataRenewal,
            { payment_status: true,
              application_decision: 'approved' }
          )
        }

        return navigate(
          'form/success',
          {
            paid: +data.payment_amount > 0,
            success: true,
            success_message: location.state.success_message,
          },
          { replace: true, overwrite: true }
        )
      default:
    }
  }

  const CRUD = action => async (form, other) => {
    setLoading(true)

    // const { state, props, axios } = this
    const { form_key } = props
    const postAction = post(action, other)
    let body = !form_key ? form : { [form_key]: form }

    try {
      switch (action) {
        case 'update:payment':
          if (!props.skip_update_payment) {
            if (props.u_key) body = { [props.u_key]: form }

            const { status, data } = await $app.axios.put(
              resource + '/update_payment',
              body,
              {
                validateStatus: status => status <= 400,
              }
            )

            if (status >= 400) {
              setLoading(false)

              return Swal.fire({
                icon: 'warning',
                title: 'Payment Failed',
                // prettier-ignore
                html: data.cashless?.message
                  || data.cashless?.error
                  || data.message
                  || data.error
                  || 'Payment failed',
                confirmButtonText: 'Try Again',
              })
            }
          }

          return postAction(form)

        default:
      }
    } catch (err) {
      console.error(err)
      toastr.error(
        'Error',
        {
          'update:payment': 'Unable to process payment',
        }[action]
      )

      setLoading(false)
    }
  }

  const submitPayment = async form => {
    setLoading(true)
    // const { fetch, CRUD } = this
    const { form_num } = props.location.state.payment

    try {
      const rsa = await fetch.rsa()
      const ccKeys = ['number', 'name', 'cvv', 'exp', 'amount']

      const encrypt = new JsEncrypt()
      encrypt.setPublicKey(rsa.pubkey)

      const body = {
        form_num,
        rsa_token: rsa.secret,
        amount: form['card-amount'],
      }

      for (let k of ccKeys) {
        const v = encrypt.encrypt(JSON.stringify(form['card-' + k]))
        body['card_' + k] = v
      }

      CRUD('update:payment')(body)
    } catch (err) {
      toastr.error(
        'Error',
        'There was a problem processing your payment',
        'SERVICE_ERROR'
      )

      return setLoading(false)
    }
  }

  const goToPayment = (replace = false) => (record, options) => async cb => {
    setLoading(true);

    // const { state, props, navigate, CRUD, setSuccessMessage } = this
    const { location, fee } = props
    const { form_num } = record

    if (!record.payment_status) {
      if (!location.state?.success_message) {
        await setSuccessMessage(record)
      }

      // prettier-ignore
      const payment_amount = Number(
        parseFloat(record.payment_amount ?? 0)
        || (typeof fee == 'function' && await fee(record))
        || fee
      )

      if (!payment_amount) {
        return CRUD('update:payment')({
          form_num,
          payment_amount: 0,
        })
      }

      let step, to

      if (
        !props.skip_location &&
        !props.disable_proxy &&
        !options?.skip_proxy &&
        !record.proxy &&
        step != STEPS.PROXY_PICKUP
      ) {
        step = STEPS.PROXY_PICKUP
        to = 'form/proxy'
      } else {
        step = STEPS.PAYMENT
        to = 'form/pay'
      }

      const nextState = {
        record,
        appointment_pick: false,
        payment: { form_num, payment_amount },
      }

      if (options?.additionalState) {
        Object.assign(nextState, options.additionalState)
      }

      navigate(to, nextState, { replace })
      setStep(step);
      setLoading(false);
    }

    typeof cb == 'function' && cb()
  }

  const viewRecord = record => {
    viewSubmission(record)();
  }

  const renewRecord = record => {
    const renewPost = async () => {
      setLoading(true)

      return await $app.axios.post(resource + '/create_renewal', { national_symbol_permission: { form_num: record.form_num } })
    }
    const fetchRecords = async () => {
      setLoading(true)

      return await $app.axios.get(resource + `?page=${paramsRenewal.page}&per_page=${paramsRenewal.per_page}&renewal=true`, { paramsRenewal })
    }

    renewPost()
    .then(res => {
      const { data } = res;
      setLoading(false)
      if(data.status == 200) {
        toastr.success('Successful','Renewal record Successfully created, payment required');
        fetchRecords()
        .then(res => {
          const { data } = res;
          setDataRenewal(data.records)
          setLoading(false)
        })
        .catch(err => console.log(err))
      } else {
        toastr.error('Error', data.message)
      }
    })
    .catch(err => {
      console.error(err)
      toastr.error('Error', 'Could not renew form')
    })
  }

  let fee = form => {
    if (Array.isArray(form.symbols_used)) {
      return 22 * form.symbols_used.length
    } else {
      return 22 * form.symbols_used.split(',')?.length
    }
  }

  const fields = (form, viewing = false) => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      save: true,
      hide: true
    },
    {
      heading: '1. I, the above named applicant, hereby apply for the permission of the Minister to -',
      view: false,
    },
    {
      name: 'acceptance_1',
      type: 'checkbox',
      view: true,
      required: false,
      label: viewing ? 'Produced/Distributed By Applicant?' : `
        (1) apply to the goods/articles/things which are described in paragraph 2
        below and which have been made/produced/distributed/sole by me, the design
        illustrated in paragraph 3 below;
      `
    },
    {
      name: 'acceptance_2',
      type: 'checkbox',
      view: true,
      required: false,
      label: viewing ? 'Importing Goods?' : `
        (2) import into The Bahamas the goods/articles/things which are described
        in paragraph 2 below and to which there has been applied the design
        illustrated in paragraph 3 below;
      `
    },
    {
      name: 'acceptance_3',
      type: 'checkbox',
      view: true,
      required: false,
      label: viewing ? 'Using For Business?' : `
        (3) use or permit to be used in the manner specified in paragraph 4
        below and in connection with a business/trade/calling/profession, the
        design illustrated in paragraph 3 below.
      `
    },
    {
      name: 'symbols_used',
      label: 'Symbol(s) Requesting Permission For',
      multi: true,
      options: [
        { value: 'National Flag', label: 'National Flag' },
        { value: 'Coat of Arms', label: 'National Coat of Arms' },
       // { value: 'part of the design of the National Flag', label: 'Partial Use of the National Flag' },
        { value: 'part of the design of the Coat of Arms', label: 'Partial Use of the Coat of Arms' },
        { value: 'flag colours', label: 'Official Colours of the National Flag' },
      ]
    },
    {
      hide: !form.symbols_used?.includes?.('flag colours'),
      heading: `
        <div>
        NOTE: The colour code required for the National Flag is as follows (Pantone Standard):
        <div class='flex flex-wrap space-x-4 mt-1'>
          <span style='padding:0.5rem;background:#00778b;color:white;border-radius:0.5rem'>
            Aquamarine : PMS 3145
          </span>
          <span style='padding:0.5rem;background:#fcc917;color:#000;border-radius:0.5rem'>
            Gold : PMS 123
          </span>
          <span style='padding:0.5rem;background:#000;color:white;border-radius:0.5rem'>
            Black : PMS Standard
          </span>
        </div>
        </div>
      `,
    },
    {
      name: 'detailed_desc',
      type: 'textarea',
      label: `
        2. Details of goods/articles/things to which the application relates:
      `
    },
    {
      name: 'illustration_description',
      type: 'textarea',
      label: `
        3. Illustration of the National Flag/Coat of Arms/part of the design
        of the National Flag/part of the design of the Coat of Arms/other
        flag/devices, to which the application relates:
      `,
    },
    {
      name: 'proposed_use',
      type: 'textarea',
      label: `
        4. Manner in which it is proposed to use the design illustrated in
        paragraph 3 above in connection with a business/trade/calling/profession:
      `,
    },
    {
      name: 'permission_date',
      type: 'date',
      label: '5. Date on which permission is required',
      minDate: today,
    },

    '::Uploads',
    {
      key: 'image.1',
      name: 'illustration_upload',
      type: 'file:image',
      label: 'Illustration Using Symbol',
    },
    {
      key: 'image.2',
      name: 'illustration_extra_upload',
      type: 'file:image',
      label: '2nd Illustration Using Symbol',
      required: false,
    }
  ]

  const hidden_fields = () => [
    {
      key: 'image.0',
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Letter of Approval',
    }
  ]

  const colors = [
    {
      style: { backgroundColor: '#FFBF0044' },
      when: row => {
        const newR = new Date(row.license_expiry_date);
        const today = new Date();
        return newR <= today
      }
    },
  ]

  const columnsNew = [
    {
      name: 'View',
      selector: row => (
        <button style={{backgroundColor: 'transparent', border: 'none', color: '#369'}} onClick={() => viewRecord(row)}>View</button>
      ),
      // button: true,
      sortable: false,
    },
    {
      name: 'Renewal',
      selector: row => row.renewal ? 'Yes' : 'Non-renewal',
      sortable: false,
    },
    {
      name: 'Submitted Date',
      selector: row => row.created_at.substring(0, row.created_at.indexOf('T')),
      sortable: true,
    },
    {
      name: 'Expiry Date',
      selector: row => row.license_expiry_date,
      sortable: true,
    },
    {
      name: 'Status',
      style: {textTransform: 'uppercase'},
      selector: row => row.application_decision,
      sortable: true,
    },
    // {
    //   name: 'Payment',
    //   style: {textTransform: 'uppercase'},
    //   selector: row => row.payment_status ? 'Paid' : 'Unpaid',
    //   sortable: true,
    // },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={() => viewRecord(row)}>
            View
          </MenuItem>
          <MenuItem onClick={() => renewRecord(row)}>
            Renew
          </MenuItem>
        </Menu>
      ),
    }
  ]

  const columnsRenewal = [
    {
      name: 'View',
      selector: row => (
        <button style={{backgroundColor: 'transparent', border: 'none', color: '#369'}} onClick={() => viewRecord(row)}>View</button>
      ),
      // button: true,
      sortable: false,
    },
    {
      name: 'Renewal',
      selector: row => row.renewal ? 'Yes' : 'Non-renewal',
      sortable: false,
    },
    {
      name: 'Submitted Date',
      selector: row => row.created_at.substring(0, row.created_at.indexOf('T')),
      sortable: true,
    },
    {
      name: 'Expiry Date',
      selector: row => row.license_expiry_date,
      sortable: true,
    },
    {
      name: 'Status',
      style: {textTransform: 'uppercase'},
      selector: row => row.application_decision,
      sortable: true,
    },
    {
      name: 'Payment',
      style: {textTransform: 'uppercase'},
      selector: row => row.payment_status ? 'Paid' : 'Unpaid',
      sortable: true,
    },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={() => viewRecord(row)}>
            View
          </MenuItem>
          {row.application_decision == 'void' &&
            <MenuItem onClick={() => goToPayment(true)(row, { skip_proxy: true })()}>
              $ Pay Now
            </MenuItem>
          }

        </Menu>
      ),
    }
  ]

  useEffect(() => {
    setIs(generatePermissions($app.roles, props.service.agency));
  }, [])

  useEffect(() => {
    (async function () {
      if (props.service.under_maintenance) {
        await Swal.fire(
          'Service Unavailable',
          `
            Sorry, but this service is currently under maintenance.
            Please try again later or reach out to our helpdesk toll free at
            ${ENV.HELPDESK.HTML.PHONE} or email ${ENV.HELPDESK.HTML.EMAIL}.
          `,
          'warning'
        )

        props.history.goBack()
      }
    })()
  }, [])


  // Fetch Non Renewals
  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true)

      return await $app.axios.get(resource + `?page=${paramsNew.page}&per_page=${paramsNew.per_page}&approved_only=true`, { paramsNew })
    }

    fetchRecords()
    .then(res => {
      const { data } = res;
      setDataNew(data.records)
      // setParams(data.params)
      setLoading(false)
    })
    .catch(err => console.log(err))
  }, [paramsNew])

  // Fetch Renewals
  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true)

      return await $app.axios.get(resource + `?page=${paramsRenewal.page}&per_page=${paramsRenewal.per_page}&renewal=true`, { paramsRenewal })
    }

    fetchRecords()
    .then(res => {
      const { data } = res;
      setDataRenewal(data.records)
      // setParams(data.params)
      setLoading(false)
    })
    .catch(err => console.log(err))
  }, [paramsRenewal])

  const MultiStepForm = () => {
    const { state } = props.location
    const pass = {
      state,
      step: step,
      tab: {
        text: 'Application',
        icon: 'file-alt',
      },

      setLoading: set => this.setState({ loading: set }),
      goBack: props.history.goBack,
      blockSubmission: block => {
        this.setState({
          block,
          loading: false,
          modal: 'submit:block',
        })
      },
      navigate,
      CRUD,
      submitPayment,
      ...extractKeys(
        // this,
        'any',
        'submitPayment',
        'navigate',
        'appointment',
        'proxy',
        'CRUD'
      ),
      ...extractKeys(
        props,
        'tab',
        'fields',
        'stacked',
        'defaults',
        'pausable',
        'free',
        'form_key',
        'postpaid',
        'pay_on_approval',
        'long_name',
        'service',
        'picker',
        'validate',
        'skip_location',
        'multiple_uploads',
        'generate_form_data',
        'has_appointment',
        'custom_acceptance',
        'foremost'
      ),
      fields,
    }

    return <SteppedForm {...pass} />
  }
  const renderView = router => {
    const vprops = {
      title: props.service.title,
      viewLinkedApplication: (title, record, fields) => viewSubmission(record, false, {
        linked: true,
        title,
        fields
      })(),
      setLoading: loading => setLoading(loading ),
      ...router,
      ...extractKeys(
        // this,
        'is',
        'fee',
        'delivery',
        'setModal',
        'determineActions'
      ),
      ...extractKeys(
        'props',
        'postpaid',
        'fields',
        'free',
        'show_spouse',
        'service'
      ),
      is,
      fee,
      setModal,
      fields,
      determineActions,
      props,
    }

    return <Viewer {...vprops} />
  }

  const shouldShowLoader =
  props.loading ||
  props.fetching ||
  props.sending ||
  (loading && loading != 'applications')

  // render = () => <Wizard {...SERVICE_PROPS(this)} stacked />
  return (
    <div>
    <Loader loading={shouldShowLoader} />
    <Route path={sublink('applications/view')} render={renderView} />
    {step == 5 && <Route path={sublink('form')} component={MultiStepForm} />}
    {step != 5 &&
      <div className="content">
        <div className="page-inner">
          <div className="row">
            <div className="col-md-3">
              <div className="card card-post card-round text-center">
                <div className="card-img-top text-center rounded"><img src={`${ENV.WEB_SERVICE_URL}/system/agencies/images/000/000/018/original/nat_symbol.png?1688395836`} alt="Agency Thumbnail" className="card-img-top"/></div>
                <div className="card-body text-left">
                  <div style={{marginBottom: "1rem"}}>
                    <h3 className="card-title text-capitalize fw-bold">Renewal - Permission to Use the National Flag or Coat of Arms</h3>
                    <p className="card-text">Ministry of National Security</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-header flex items-center" data-hide="true">
                  <div className="flex space-x-4 items-center ml-auto">
                    <span className="flex-shrink-0"> </span>
                    <select className="form-control">
                      <option value="Default">Default</option>
                    </select>
                  </div>
                </div>
                <div className='table-responsive form-records-table'>
                  <h3 style={{fontWeight: 'bold', textAlign: 'center', paddingTop: '12px'}}>New</h3>
                  {dataNew && <DataTable
                    data={dataNew}
                    columns={columnsNew}
                    // onSelectedRowsChange={s => setSelected(s.selectedRows)}
                    // clearSelectedRows={clear}
                    conditionalRowStyles={colors}
                    noHeader
                    // selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={paramsNew.per_page}
                    paginationTotalRows={paramsNew.no_of_records}
                    onChangeRowsPerPage={per_page => setParamsNew({ per_page })}
                    onChangePage={page => setParamsNew({ page })}
                  />}
                </div>
                <div className='table-responsive form-records-table'>
                  <h3 style={{fontWeight: 'bold', textAlign: 'center', borderTop: '1px solid lightgrey', paddingTop: '12px'}}>Renewals</h3>
                  {dataRenewal && <DataTable
                    data={dataRenewal}
                    columns={columnsRenewal}
                    // onSelectedRowsChange={s => setSelected(s.selectedRows)}
                    // clearSelectedRows={clear}
                    conditionalRowStyles={colors}
                    noHeader
                    // selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={paramsRenewal.per_page}
                    paginationTotalRows={paramsRenewal.no_of_records}
                    onChangeRowsPerPage={per_page => setParamsRenewal({ per_page })}
                    onChangePage={page => setParamsRenewal({ page })}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    <div id="alert-banner-container">
      <div id="alert-banner">
        <span></span>
        <button>
          <svg className="svg-inline--fa fa-times-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
          </svg>
          {/* <!-- <i className="fas fa-times-circle"></i> --> */}
        </button>
      </div>
    </div>
    </div>
  )
}

export default NationalSymbolPermissionRenewal;
