import React from 'react'
import Swal from 'sweetalert2'


import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import { fullName } from '@modules/form-wizard/utils'
import passportPhotoExample from '@helpers/passport-photo-example'

export default class CrownLandLease extends React.Component {
  resource = '/crown_lands'
  form_key = 'crown_land'
  enable_batch_updates = ['minister']
  renewal  = /-renewal/.test(window.location.pathname)

  free = true
  skip_location = true
  custom_actions = [
    // Clerical Staff
    forwardToSupervisor.call(this),
    // Supervisor
    recommendToDPS.call(this),
    // Deputy Permanent Secretary
    recommendToPS.call(this),
    // Under Secreatary / Permanent Secretary
    recommendToMinister.call(this),
    // Minister
  ]

  hide_buttons = {
    status: !$app.hasRole('minister'),
    deny: !$app.hasRole('minister'),
  }

  defaults = {
    renewal: this.renewal,
  }

  validate = form => {
    return {
      farming_type: form.farming_type.join(', '),
      source_of_funding: [
        form.funding_private && 'Private',
        form.funding_bank && 'Bank',
        form.funding_other && 'Other',
      ].filter(bool => bool)
    }
  }


  fields = form => [
    ...BUSINESS_FIELDS(form),

    '::Personal Contact Information',
    {
      name: 'renewal',
      save: true,
      hide: true
    },
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'phone_number',
      is: 'phone',
    },

    '::Land Details',
    {
      name: 'land_island',
      label: 'Island',
      options: [
        'New Providence',
        'Grand Bahama',
        'Abaco',
        'North Andros',
      ]
    },
    // {
    //   name: 'land_street_address',
    //   label: 'Street Address'
    // },
    {
      name: 'amount_land_requested',
      type: 'number',
      label: 'Size of Land Requesting',
      is: 'integer',
      min: 0,
      hint: "(Acres)",
    },
    {
      name: 'farming_type',
      label: 'Type of Farming',
      multi: true,
      options: [
        'General Farming',
        'Vegetable / Crop Farming',
        'Nursery',
        'Aquaponics',
        'Hydroponics',
        'Ornamental',
        'Livestock',
        'Apiary',
        'Other',
      ]
    },
    {
      name: 'farming_type_other',
      hide: !form.farming_type?.includes('Other'),
      label: 'Please Specify',
      save: false,
    },

    {
      heading: 'Previous Lease',
      view: form.previous_land_leased
    },
    {
      name: 'previous_land_leased',
      type: 'select:bool',
      long: true,
      view: false,
      label: `
        Have you been granted a lease before for agricultural purposes
        from the Ministry of Agriculture?
      `,
    },
    {
      name: 'prev_land_island',
      label: 'Island',
      options: [
        'New Providence',
        'Grand Bahama',
        'Abaco',
        'Andros'
      ],
      hide: form.previous_land_leased !== true
    },
    {
      name: 'prev_land_address',
      label: 'File Ref. #',
      hide: form.previous_land_leased !== true,
      required: false,
      hint: `
        Your file number will be in the header on the top right
        of your approval letter.
      `
    },

    '::Source(s) of Funding',
    {
      name: 'funding_private',
      label: 'Private',
      type: 'checkbox',
      required: false,
    },
    {
      name: 'funding_bank',
      label: 'Bank',
      type: 'checkbox',
      required: false,
    },
    {
      name: 'funding_other',
      label: 'Other',
      type: 'checkbox',
      required: false,
    },
    {
      name: 'business_plan_upload',
      key: 'image.4',
      type: 'file:pdf',
      hide: form.amount_land_requested < 4,
      label: 'Business Plan',
      hint: '(PDF) Your full business plan',
    },
    {
      name: 'executive_summary_upload',
      key: 'image.3',
      type: 'file:pdf',
      hide: form.amount_land_requested >= 4,
      label: 'Executive Summary',
      hint: '(PDF) A minimum 1 page summary of your business proposal',
    },
    {
      name: 'funding_source_upload',
      key: 'image.2',
      label: 'Proof of Funding',
      type: 'file:pdf',
      hint: `
        (PDF) Proof of funding from a domestic, private, or international bank
        or a private individual.
      `
    },

    {
      name: 'passport_upload',
      key: 'image.5',
      type: 'file:image',
      label: 'Passport Page',
      hint: passportPhotoExample,
    },

    {
      heading: '<span>&nbsp;</span>',
      view: false,
    },
    {
      name: 'development_within_year',
      label: 'If approved, I am prepared to begin development within one (1) year',
      type: 'checkbox'
    },
    {
      name: 'declare',
      type: 'checkbox',
      label: `
        By clicking this box, I confirm that I understand my obligations under
        the respective laws and regulations applicable to the farm land lease
        application and agree to observe all applicable laws, regulations,
        policies and guidelines.
      `,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

function forwardToSupervisor () {
  return {
    text: 'Forward To Supervisor Of Land Unit',
    icon: 'check',
    test: r => r.application_decision == 'pending',
    roles: ['officer'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props
      
      const { isConfirmed, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend Application',
        text: `
          After having done your due diligence, would you recommend
          this application to the Supervisor of the Land Unit?
        `,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: isConfirmed ? 'approved' : 'denied',
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

function recommendToDPS () {
  return {
    text: 'Recommend To Dep. Permanent Secretary',
    icon: 'user-tie',
    roles: ['senior_officer'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend To Deputy Permanent Secretary',
        html: `
          What is your recommendation to the Dep. Permanent Secretary
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

function recommendToPS () {
  return {
    text: 'Recommend To Under/Permanent Secretary',
    icon: 'user-tie',
    roles: ['deputy_permanent_secretary'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend To Permanent Secretary',
        html: `
          What is your recommendation to the Permanent Secretary
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

function recommendToMinister () {
  return {
    text: 'Recommend To Minister',
    icon: 'user-tie',
    roles: [['permanent_secretary', 'under_secretary']],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend To Minister',
        html: `
          What is your recommendation to the Minister of National Security
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}