export const assert = (condition, onFail) => {
  if (condition) return true

  if (typeof onFail == 'function') {
    onFail()
    return false
  } else return onFail
}

export const assertAll = (...pairs) => {
  for (let p of pairs) {
    const ok = assert(...p)
    if (ok === false || typeof ok == 'string') {
      return ok
    }
  }

  return true
}

export default assert
