const breeds = [
  'Domestic Shorthair',
  'Domestic Mediumhair',
  'Domestic Longhair',
  'Abyssinian',
  'American Bobtail',
  'American Curl',
  'American Shorthair',
  'American Wirehair ',
  'Balinese-Javanese',
  'Birman',
  'Bombay',
  'British Shorthair',
  'Burmese',
  'Chartreux',
  'Cornish Rex',
  'Devon Rex',
  'Egyptian Mau',
  'European Burmese',
  'Exotic Shorthair',
  'Havana Brown',
  'Himalayan',
  'Japanese Bobtail',
  'Korat',
  'Maine Coon',
  'Manx',
  'Munchkin',
  'Norwegian Forest',
  'Ocicat',
  'Oriental',
  'Persian',
  'Peterbald',
  'Ragamuffin',
  'Ragdoll',
  'Russian Blue',
  'Scottish Fold',
  'Siamese',
  'Selkirk Rex',
  'Siberian',
  'Singapura',
  'Somali',
  'Sphynx',
  'Tonkinese',
  'Toyger',
  'Turkish Angora',
  'Turkish Van',
]

export default breeds
