import React from 'react'
import { connect } from 'react-redux'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

import { getLists } from '@actions/users'

class LayerProduction extends React.Component {
  componentDidMount() {
    this.props.getLists()
  }

  title = 'Emergency Food Plan: Layer Production'
  resource = '/layer_productions'
  form_key = 'layer_production'
  free = false
  postpaid = false
  defaults = {}
  
  skip_location = false
  hide_buttons = false
  custom_action = false
  custom_acceptance = false

  fee = 99

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'registered_farmer',
      label: 'Are you a registered farmer?',
      type: 'select:bool',
    },
    {
      name: 'become_registered_farmer',
      label: 'Are you willing to become a registered farmer?',
      type: 'select:bool',
      hide: form.registered_farmer,
    },
    {
      name: 'business_license',
      label: 'Do you have a business licence?',
      type: 'select:bool',
    },
    {
      name: 'layer_production',
      label: 'Are you currently in layer production?',
      type: 'select:bool',
    },
    {
      name: 'animal_mortality_records',
      label: 'Do you currently maintain records of animal mortality?',
      type: 'select:bool',
    },
    {
      name: 'layer_production_records',
      label: 'Do you currently maintain records of your layer production?',
      type: 'select:bool',
    },
    {
      name: 'day_old_chicks',
      label: 'Can you accomodate day old chicks?',
      type: 'select:bool',
    },
    {
      name: 'week_old_chicks',
      label: 'Can you accomodate five week old chicks?',
      type: 'select:bool',
    },
    {
      name: 'farm_land_type',
      label: 'What type of land is your farm located in?',
      options: ['Residential', 'Commercial', 'Agricultural', 'Unknown'],
    },
    {
      name: 'farm_location',
      label: 'Location of Farm',
      options: this.props.islands,
    },
    ':: Please attach any supporting documents to this application',
    {
      name: 'farmer_registration_card_upload',
      label: "Farmer's Registration Card",
      type: 'file:pdf',
      required: false,
      key: f => f.image[0],
    },
    {
      name: 'business_license_upload',
      label: "Farmer's Business Licence",
      type: 'file:pdf',
      required: false,
      key: f => f.image[1],
    },
    {
      name: 'cert_upload',
      label: 'Certificate in Related Agricultural Program',
      type: 'file:pdf',
      required: false,
      key: f => f.image[2],
    },
    {
      name: 'diploma_upload',
      label: 'Copy of High School Diploma',
      type: 'file:pdf',
      required: false,
      key: f => f.image[3],
    },
    {
      name: 'identification_upload',
      label: 'Identification',
      type: 'file:image',
      required: false,
      key: f => f.image[4],
    },
    {
      name: 'housing_layer_upload',
      label: 'Photo of the Layer Housing',
      type: 'file:image',
      required: false,
      key: f => f.image[5],
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default connect(
  ({ users }) => ({
    islands: users.lists?.islands || [],
  }),
  { getLists }
)(LayerProduction)
