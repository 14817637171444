import { combineReducers } from 'redux'
import { routerReducer as router } from 'react-router-redux'

import dashboard from './dashboard'
import users from './users'
import agencies from './agencies'

import appointments from './appointments'
import payments from './payments'
import notes from './notes'
import blockcerts from './blockcerts'
import session from './session'
import gmap from './gmap'

export default combineReducers({
  // 3rd Party Reducers
  router,

  // Universal/Site Reducers
  dashboard,
  users,
  agencies,
  session,

  // Service Form Reducers
  appointments,
  payments,
  notes,
  gmap,
  blockcerts,
})
