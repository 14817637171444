import * as actionTypes from '@action-types'

const initialState = {
  authenticated: false,
  loading: false,
  data: [],
  license: {},
  payment: {},
  success_message: undefined,
  success: false,
  error: undefined 
}

const passport = (state=initialState,action) => {

  switch(action.type){   
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return {...state, loading: false, success: true,success_message: "Successfully payment", payment: action.payload.response}
    case actionTypes.GET_RESPONSE_ERROR:
      return{...state, loading: false, error: action.payload.response.data.error} 
    // case actionTypes.GET_REQUEST_SUCCESS: 
    //   return {...state,loading: true, error: undefined};

    case actionTypes.GET_PARTICULAR_REVENUE:
      return {...state, loading: false, line_item: action.payload.response, license: undefined}
    case actionTypes.GET_ALL_REVENUE:
      return {...state,loading: false, line_items: action.payload.response,license: undefined}
    case actionTypes.PAYMENT_ERROR:
      return {...state,loading: false, license: {}}
    default: 
      return {...state, loading: false} 
  }  
}

export default passport
