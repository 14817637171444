
export const unionOfEmployers = {
    nassau: [
        'Bahamas Musicians Entertainers Union',
        'Airline, Airport & Allied Workers Union',
        'Bahamas Union of Teachers',
        'Bahs Communications and Public Officers',
        'Bahamas Communications and Public Managers Union',
        'Bahs Commercial Stores, Supermarkets, Warehouse, Workers Union ',
        'Bahamas Financial Services Management Workers Union',
        'The Bahamas Unified Bus Drivers Union',
        'United Flight Attendants of The Bahamas',
        'United Artists Bahamas',
        'Abaco Taxi Drivers & Allied Workers Union',
        'Bahamas National Ailliance Trade Union Congress',
        'The Bahamas Industrial General and Services Union',

    ],

    grandBah: [
        'The Bahamas Industrial General and Services Union',
     ],
}


export const unionOfEmployees = {
    nassau: [
        'Bahamas Hotel Catering & Allied Workers Union',
        'Bahamas Brewery Distillers Union',
        'Bahamas Public Services Union',
        'Bahamas Doctors Union',
        'Bahamas Electrical Utility Managerial Union',
        'Bahs Communications and Public Management Union (Formally known as BTMU)',
        'Bahamas Utility Service & Allied Workers Union',
        'Bahs Electrical Workers Union',
        'Bahs Taxi Cab Union',
        'Water & Sewerage Management Union',
        'Bahamas Public Managers Union',
        'Bahamas Air Traffic Controllers Union',
        'Union of Tertiary Educators',
        'Union of Public Officers',
        'Bahamas Nurses Union',
        'Union of Central Bankers',
        'The Bahamas Financial Services Union',
        'Bahamas Hotel Maintenance and Allied Workers Union',
        'General Workers Union',
        'The Bahamas Educators Managerial Union',
        'Utilities & Allied Workers Management Union',
        'Bahamas Customs and Immigration Allied Workers Union',
        'Bahamas Border Protection Officers Union',
        'The Bahamas Allied Health Care Workers Unions',
        'Bahamas Union of Auxiliary Professionals In Education',
        'Commonwealth Union of Hotel Services',
        'Commonwealth Electrical Workers Union',
        'Grand Bahamas Public Service Drivers and Allied Workers Union',
        'Grand Bahama Port Authority W.U.',
        'Bahamas Industrial Engineers Managerial and Supervisory Union',
        'Grand Bahama Dock and Allied Workers Union',
        'The Bahamas Longshore, Warehouse and Harbor Workers Union',
        'Bahamas Educators Couselors & AWU',
        'Bahs Industrial Manufactures & AWU',
    ],

    grandBah: [
        'Commonwealth Union of Hotel Services',
        'Commonwealth Electrical Workers Union',
        'G.B Public Service Drivers and Allied Workers Union',
        'G.B. Port Authority W.U.',
        'Bahamas Industrial Engineers Managerial and Supervisory Union',
        'Grand Bahama Dock and Allied Workers Union',
        'The Bahamas Longshore, Warehouse and Harbor Workers Union',
        'Bahamas Educators Couselors & AWU',
    ],

    inagua: [
        'Bahs Industrial Manufactures & AWU',
    ],
}

export const asscOfEmployers = {
    nassau: [
        'Bahamas Hotel & Restaurant Employers Association',
        'Bahamas Contractors Association',
        'The Arawak Cay, Fish, Vegetable & Vendors Association',
        'The Junkanoo Beach Association',
        'The Consultant Physicians Staff Association',
        "Potter’s Cay Fish, Fruit And Vegetable Vendor Association",
        'Cabbage Beach Business Owners Association',
        'The Bahama Docks & Allied Venues Association',
        'Straw Business Persons Society',
    ]
}

export const asscOfEmployees = {
    nassau: [
        'Bahamas Airline Pilots Association',
        'Bahs Motor Dealers Association',
        'Bahamas Casino Employees Association',
        'Bahamas Hotel Managerial Assosciation',
    ]
}



/*
const umbrellaUnions = {
    nassau: [
        {
            label: 'Bahamas Chamber of Commerce/Bahamas Employer’s Confederation',
            value: '1'
        },
        {
            label: 'Trade Union Congress',
            value: '2'
        },
        {
            label: 'National Congress of Trade Unions',
            value: '3'
        },
    ]
} */



/*
const cancelledUnions = {
    nassau: [
        {
            label: 'Bahamas Beverage Water Distribution Union',
            value: '1'
        },
        {
            label: 'Bahamas Casino Employees Association',
            value: '2'
        },
        {
            label: 'Bahamas Cement, Marine, Maintenance Building Construction & Allied WorkerUnion',
            value: '3'
        },
        {
            label: 'Bahamaland Construction & Allied Workers Union',
            value: '4'
        },
        {
            label: 'Bahamas Construction & Civil Engineering Trade Union',
            value: '5'
        },
        {
            label: 'Bahamas Gaming and Allied Workers Union',
            value: '6'
        },
        {
            label: 'Bahamas Maritime Port & Allied Workers Union',
            value: '7'
        },
        {
            label: 'Bahamas Omnibus Owners Associaiton',
            value: '8'
        },
        {
            label: 'Bahamas Petroleum Retailers Association',
            value: '9'
        },
        {
            label: 'Commonwealth Cement Construction Workers Union',
            value: '10'
        },
        {
            label: 'Eastside Stevedores Union ',
            value: '11'
        },
    
        {
            label: 'Public Service Drivers Union',
            value: '12'
        },
        {
            label: 'United Brotherhood Longshore Union ',
            value: '13'
        },
        {
            label: 'Worldwide Guild of Variety Artist',
            value: '14'
        },
    ],

    grandBah: [
        {
            label: 'Bahamas Marine Dock Construction & Refinery Workers Union',
            value: '1'
        },
        {
            label: 'The Bahamas Security Allied Workers Union',
            value: '2'
        },
        {
            label: 'Commonwealth Transport, Distributive & Ailled Works Union  ',
            value: '3'
        },
        {
            label: 'Commonwealth Wholesale Retail & Allied Workers Union',
            value: '4'
        },
        {
            label: 'Freeport Containers Port Workers Union',
            value: '5'
        },
        {
            label: 'Freeport Flight Services Airline & Allied Workers Union',
            value: '6'
        },
        {
            label: 'Freeport Hotel Employers Restaurants Association',
            value: '7'
        },
        {
            label: 'Grand Bahama Bus Drivers Union',
            value: '8'
        },
        {
            label: 'Grand Bahama Chemical & Allied Workers Union',
            value: '9'
        },
        {
            label: 'Grand Bahama Construction Refinery Maintenance & Allied Workers Union',
            value: '10'
        },
        {
            label: 'Grand Bahama Entertainers Association',
            value: '11'
        },
        {
            label: 'Grand Bahama Hotel Catering & Allied Workers Union',
            value: '12'
        },
        {
            label: 'Grand Bahama Musicians & Entertainers Union',
            value: '13'
        },
        {
            label: 'Grand Bahama Public Service Drivers Union',
            value: '14'
        },
        {
            label: 'Grand Bahama Taxi Union',
            value: '15'
        },
        {
            label: 'Grand Bahama Telephone & Communications Union',
            value: '16'
        },
        {
            label: 'Optometric Association of The Bahamas',
            value: '17'
        },

    ],
} */





