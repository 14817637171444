import React from 'react'
import moment from 'moment'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class BarricadeRental extends React.Component {
  resource = '/barricade_rentals'
  form_key = 'barricade_rental'
  defaults = {}

  // Delete lines as needed
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = form => {
     var a = moment(form.return_date);
     

     return {
          event_length_days: a.diff(form.date_required,'days') + 1,
     }
  }

  foremost = null


  fee = (form) => {
    //We carry barricades for a wide variety of purposes all at a very affordable rate of $2.00 per day per barricade and a security deposit of $150.00.

    
    return 2 * (form.barricades_requested_numb * form.event_length_days) + 150;

  }

  fields = form => [
    {
        name: 'organization',
        
        
    },
    {
      name: 'events_list',
      label:'Purpose of the Barricades',
      columns: ['event','location'],
      
    },
    {
      name: 'barricades_requested_numb',
      label: 'Number Requested',
      is: 'integer',
    },
    {
      name: 'event_length_days',
      label:'Number of Days',
      is: 'integer',
      hide:true,
      view:true,
      save:true,
    },
    {
      name:'date_required',
      type:'date',
    },
    {
      name: 'return_date',
            type: 'date',
    }
  ]



  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

