import React from 'react'
import Modal from 'react-responsive-modal'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys, withoutKeys } from '@helpers/objects'

import {
  ExemptionsForm,
  calculateExemptionToatal,
  ApprovalForm,
} from './_exemptions-form'
// import redirectToCustoms from './_redirect'

export default class HomeExemption extends React.Component {
  state = {
    record: null,
    loading: false,
    picker: null,
  }

  id = 'AffordableHomeExemption'
  service = 'affordable home exemption'
  title = 'Affordable Home Exemption'
  agency = 'mofrd'
  resource = '/affordable_home_exemptions'
  form_key = 'affordable_home_exemption'
  free = true

  // before_apply = redirectToCustoms
  skip_location = true
  hide_buttons = {
    status: true,
  }

  validate = form => {
    if (!form.exemption_category.size) {
      return 'No exemption categories were selected'
    }

    const exemptions = [
      'building_supplies_exemption_list',
      'furniture_exemption_list',
      'appliances_exemption_list',
    ]

    const transformed = exemptions.reduce((obj, prop) => {
      const ex = form[prop]
        ?.filter(row => row.item)
        ?.map(row => ({
          ...withoutKeys({ ...row }, 'tariff'),
          value: Number(row.value).toFixed(2),
          tariff_id: row.tariff?.id,
          tariff_code: row.tariff?.value,
        }))

      if (ex) obj[prop] = ex
      return obj
    }, {})

    return {
      ...transformed,
      total_value_of_exemption: exemptions
        .reduce((sum, prop) => sum + calculateExemptionToatal(form[prop]), 0)
        .toFixed(2),
    }
  }

  openPicker = (data, target) => {
    const options = data.map(d => ({
      label: `<span class='text-muted'>${d.value}</span><br/>${d.label}`,
      value: extractKeys(d, 'id', 'value'),
    }))

    this.setState({
      picker: {
        options,
        unskippable: true,
        heading: 'Please Select The Correct Tariff Code',
        target: target.replaceAll('::', '.').replace('item', 'tariff'),
        onSelect: () => this.setState({ picker: null }),
      },
    })
  }

  custom_action = {
    text: 'Approve Exemptions',
    icon: 'check-square',
    fn: record => this.setState({ record }),
  }

  close = () => this.setState({ record: null })

  update = status => {
    switch (status) {
      case 'SAVING':
        return this.setState({ record: null, loading: true })
      case 'DONE':
      case 'FAILED':
        return this.setState({ loading: false })
      default:
    }
  }

  foremost = {
    name: 'approved',
    label: 'Have you been approved for a Government Serviced Lot?',
    type: 'select:bool',
    test: v =>
      v ||
      'Sorry, but this service is only available for citizens that have been approved for a service lot.',
  }

  fields = (form, viewMode) => [
    {
      name: 'serviced_lot_type',
      label: 'Approved Lot District',
      options: [
        'Prospect Ridge, New Providence',
        'Carmichael Village, New Providence',
        'Spring City, Abaco',
        'Central Pines, Abaco',
      ],
    },
    {
      name: 'exemption_category',
      label: 'Exemption Types',
      type: 'checklist',
      options: ['Building Supplies', 'Furniture', 'Appliances'],
    },
    '::Supporting Documents',
    {
      name: 'doh_approval_letter_upload',
      label: 'Housing Approval Letter',
      type: 'file:pdf',
      key: f => f.image[1],
    },
    {
      name: 'invoice_upload',
      label: 'Invoice',
      type: 'file:pdf',
      key: f => f.image[2],
    },
    {
      name: 'site_plan_upload',
      label: 'Min. Of Public Works Stamped Floor Plan',
      type: 'file:pdf',
      key: f => f.image[3],
      hide: !form.exemption_category?.has?.('Building Supplies'),
      required: false,
    },
    {
      name: 'occupancy_letter_upload',
      label: 'Occupancy Certificate',
      type: 'file:pdf',
      key: f => f.image[5],
      hide: !form.exemption_category?.has?.('Furniture'),
    },
    '::Exemption Details',
    {
      name: 'building_supplies_exemption_list',
      label: 'Building Supplies',
      hide: !form.exemption_category?.has?.('Building Supplies'),
      ...ExemptionsForm(
        form.building_supplies_exemption_list,
        this.openPicker,
        viewMode
      ),
    },
    {
      name: 'furniture_exemption_list',
      label: 'Furniture',
      hide: !form.exemption_category?.has?.('Furniture'),
      ...ExemptionsForm(
        form.furniture_exemption_list,
        this.openPicker,
        viewMode
      ),
    },
    {
      name: 'appliances_exemption_list',
      label: 'Appliances',
      hide: !form.exemption_category?.has?.('Appliances'),
      ...ExemptionsForm(
        form.appliances_exemption_list,
        this.openPicker,
        viewMode
      ),
    },
    '::SPACER',
    {
      name: 'penalty',
      type: 'checkbox',
      label: `
        Providing false or misleading information
        &mdash; or using exemptions for purposes other than applied for
        &mdash; will lead to prosecution and related fines and criminal penalties.
      `,
    },
  ]

  hidden_fields = () => [
    {
      name: 'total_value_of_exemption',
      view: {
        label: 'Total Exemption Value',
        value: v => '$' + Number(v).toLocaleString(),
      },
    },
    {
      name: 'pdf_upload',
      type: 'file:image',
      label: 'Exemption Certificate',
      key: f => f.image[6],
    },
  ]

  render = () => {
    const { state, props, close, fields, resource, form_key, update } = this
    const { record } = state

    const fprops = {
      update,
      resource,
      close,
      form_key,
      location: props.location,
      history: props.history,
    }

    if (record)
      Object.assign(fprops, {
        record,
        fields: fields(record),
      })

    return (
      <React.Fragment>
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal open={!!record} onClose={close} center>
          <ApprovalForm {...fprops} />
        </Modal>
      </React.Fragment>
    )
  }
}
