/* These are import statements in JavaScript that import various modules and components needed for the
TradeUnionRegistration class to function properly. */
import React, { Fragment } from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import Loader from '@shared/loader'
// import Form from '@modules/form-wizard/form'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
// import OpenMapLeaf from '@modules/open-street-map-field'

/* This code exports a React component called `TradeUnionRegistration` as the default export. The
component extends the `React.Component` class and defines various properties and methods that are
used to render a form wizard for registering a trade union. */




export default class CharcoalKiln extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  // async componentDidMount() {
  //   Object.assign(this, extractKeys($app, 'current_user:user'))
  // }

  resource = '/charcoal_kiln_forest_burning_permits'
  form_key = 'charcoal_kiln_forest_burning_permit'
  isRenewal = /renewal/.test(this.props.location.pathname)

  // postpaid = true
  pay_on_approval = true
  skip_location = true

  hide_buttons = {
    status: false,
    deny: false,
    fee: false,
  }

  state = {
    loading: false,
  }

  defaults = {
    renewal: this.isRenewal,
    // transfer: this.isTransfer
    first_permit: this.isRenewal ? !this.isRenewal : null,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
        // transfer: this.isTransfer,
      },
    },
  }


 /* `defaults` is an object that contains default values for the fields in the form. These values will
 be used to pre-populate the form when it is first loaded. The default values include the type of
 union, whether a new name will be used, the name of the employer, the name of the union, the street
 address of the union, and information about the union officers, including their titles, names,
 email addresses, NIB numbers, addresses, and occupations. */
  // defaults = {
  //   "union_type": "Union of Employees",
  //   "new_name": true,
  //   "employer_name": "Woodworking",
  //   "union_name": "Totally New Union #242",
  //   "union_street_address": "TEST STREET",
  //   "union_officers": [
  //     {
  //         "title": "President",
  //         "email": "test@email.com",
  //         "first_name": "John",
  //         "middle_name": "J",
  //         "last_name": "Doe",
  //         "nib": "12345678",
  //         "address": "TEST ADDRESS",
  //         "occupation": "Business Management & Administration"
  //     },
  //     {
  //         "title": "Treasurer",
  //         "email": "test@email.com",
  //         "first_name": "Jane",
  //         "middle_name": "",
  //         "last_name": "Doe",
  //         "nib": "754732624",
  //         "address": "Test Address",
  //         "occupation": "Arts, Audio/Video Technology & Communications"
  //     },
  //     {
  //         "title": "General Secretariat",
  //         "email": "test@email.com",
  //         "first_name": "Jack",
  //         "middle_name": "T",
  //         "last_name": "Doe",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     },
  //     {
  //         "title": "Trustee",
  //         "email": "test@email.com",
  //         "first_name": "Johnathon",
  //         "middle_name": "D",
  //         "last_name": "Munnings",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     }
  //   ],
  // }

  validate = form => {

  }

  canned = {
    'TEST CANNED': `
      This is a test canne message; it can be whatever you like.
    `
  }

  foremost = null

  // closeModal = () => this.setState({ record: null })

  // t.boolean :renewal, default: false

  // # DIR Verification Info

  // t.string :forest_name
  // t.string :forest_acreage
  // t.string :forest_island
  // t.string :forest_city
  // t.string :forest_street_address
  // t.decimal :longitude, precision: 10, scale: 6
  // t.decimal :latitude, precision: 10, scale: 6
  // t.string :forest_settlement
  // t.boolean :first_permit, default: false
  // t.string :last_app_numb
  // t.date :last_app_date
  // t.boolean :app_successful, default: false
  // t.string :last_permit_numb
  // t.date :last_permit_date
  // t.string :wood_types_list, array: true, default: []
  // t.boolean :occupiers_notified, default: false

  // t.attachment :management_plan_upload
  // t.attachment :burning_plan_upload

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      label: 'Renewal?',
      type: 'select:bool',
      hide: true,
      view: true,
      save: true,
    },
    '::Forest Estate Details',
    {
      name:'forest_name',
      label:'Name of forest estate',
    },
    // {
    //   name:'forest_acreage',
    //   label:'approximate acreage of forest estate',
    // },
    {
      name:'forest_island',
      label:'Island',
      options: islands,
    },
    // {
    //   name:'forest_city',
    //   label:'',
    // },
    {
      name:'forest_street_address',
      label:'Address',
    },
    {
      name:'forest_settlement',
      label:'Nearest Settlement',
    },

    '::Specific Information',
    {
      name:'first_permit',
      type: 'select:bool',
      label:'Is this your first application for a Burning Permit for a charcoal kiln?',
      disabled: this.isRenewal,
      save: true,
    },
    {
      name:'last_app_numb',
      label:'Application No.',
      // type: 'integer',
      hide: form.first_permit,
    },
    {
      name:'last_app_date',
      label:'Date of application',
      type:'date',
      hide: form.first_permit,
    },
    {
      name:'app_successful',
      type: 'select:bool',
      label: 'Was the application successful?',
      hide: form.first_permit,
    },
    {
      name:'last_permit_numb',
      label:'Permit No.',
      // type: 'integer',
      hide: !form.app_successful,
    },
    {
      name:'last_permit_date',
      label:'Date of issue',
      type:'date',
      hide: !form.app_successful,
    },
    {
      name: 'wood_types_list',
      label: 'Please indicate the type (s) (species) and the source of fuel wood to be used in the proposed activity',
      // hint: 'Applicable for instrument class only',
      columns: ['info'],
      format: v => v.info,
    },
    {
      name:'occupiers_notified',
      label: 'State whether the owners or occupiers of adjoining property have been notified of the proposal Charcoal kiln activity',
      type: 'select:bool',
    },

    '::Uploads',
    {
      name:'burning_plan_upload',
      key: 'image.1',
      // label:'',
      type: 'file:all',
    },
    {
      name:'management_plan_upload',
      key: 'image.2',
      // label:'',
      type: 'file:all',
      hint: 'in compliance with requirements of Regulation 17 (3) (b)',
    },
  ]

  render = () => {
    const { loading } = this.state
    // console.log($app.current_user.id)

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
      </Fragment>
    )
  }
}
