import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class HydroponicsProgram extends React.Component {
  resource = "/hydroponics";
  form_key = "hydroponic";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};
  long_name = "Emergency Food Plan: Hydroponics Program";

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "registered_farmer",
      label: "Are you a registered farmer?",
      type: "select:bool",
    },
    {
      name: "will_register_farmer",
      label: "Are you willing to be a registered farmer?",
      type: "select:bool",
      hide: form.registered_farmer,
    },
    {
      name: "academic_background",
      label: "Do you have an academic background in Agriculture?",
      type: "select:bool",
    },
    {
      name: "education_level",
      label: "Level of Education",
      hide: !form.farmer_background,
    },
    {
      name: "programme_studied",
      label: "Name of Agricultural Degree, Course or Program",
      hide: !form.farmer_background,
    },
    {
      name: "farming_experience",
      label: "Do you have any experience in agricultural areas?",
      type: "select:bool",
    },
    {
      name: "experiences",
      label: "Type of Experience(s)",
      columns: [{ name: "experience", label: "Experience" }],
      hide: !form.agricultural_experience,
    },
    {
      name: "num_of_years_experience",
      label: "Number of years Experience",
      is: "integer",
      hide: !form.agricultural_experience,
    },
    {
      name: "farming_license",
      label: "Do you have a farming business licence?",
      type: "select:bool",
    },
    {
      name: "land_access",
      label:
        "Do you have access to land (20 x 40) to set up the hydroponic system?",
      type: "select:bool",
    },
    {
      name: "water_access",
      label:
        "Do you have access to a water source close to where the hydroponic system will be placed?",
      type: "select:bool",
    },
    {
      name: "internet_access",
      label: "Do you have access to the internet for online training?",
      type: "select:bool",
    },
    {
      name: "purchase_system",
      label: "Are you willing to purchase the hydroponic system?",
      type: "select:bool",
    },
    "::Supporting Documents",
    {
      name: "diploma_upload",
      label: "Copy of High School Diploma",
      type: "file:image",
      key: (f) => f.image[1],
    },
    {
      name: "farmer_card_upload",
      label: "Farmer's registration card",
      type: "file:image",
      hide: !form.registered_farmer,
      key: (f) => f.image[3],
    },
    {
      name: "farmer_licence_upload",
      label: "Copy of Business License",
      type: "file:image",
      hide: !form.farming_license,
      key: (f) => f.image[0],
    },
    {
      name: "cert_upload",
      label: "Agriculture Related Certificate/Degree",
      type: "file:image",
      hide: !form.academic_background,
      key: (f) => f.image[2],
    },
    {
      name: "land_photo_upload",
      label: "Photo of land space (20 X 40) for hydroponic system",
      type: "file:image",
      hide: !form.land_access,
      key: (f) => f.image[5],
    },
    {
      name: "water_source__photo_upload",
      label: "Photo of water source close to land space for hydroponic system",
      type: "file:image",
      hide: !form.water_access,
      key: (f) => f.image[4],
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
