import React, { useEffect } from 'react'
import { Redirect } from 'react-router'

import { isSubset } from '@helpers/arrays'
import { history } from '@/App'

const AuthGuard = (ComposedComponent, requiredRoles) => {
  if (
    requiredRoles &&
    (!window.$app || !$app?.token || !isSubset($app.roles, requiredRoles))
  )
    return () => <Redirect to='/' />

  const AuthCheck = props => {
    useEffect(() => {
      const check = async () => {
        if (!$app.token) return history.push('/login')

        try {
          const { data } = await $app.axios.get('/users/is_verified')
          !data.is_verified && history.push('/otp')
        } catch (err) {
          console.error(err)
          return null
        }
      }

      check()
    }, [])

    return <ComposedComponent {...props} />
  }

  return AuthCheck
}

export default AuthGuard
