import React from 'react'

import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'

import SelectField from '@shared/form-fields/select'
import Loader from '@shared/loader'
import toastr from '@modules/toastr'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import Swal from 'sweetalert2'
import ENV from '@constants/env'
import { getCurrentStage } from './_marine-utility'

export default class FlatsFishingRenewal extends React.Component {
  state = {
    training_verified: false,
    record: null,
    loading: false,
  }

  long_name = 'Application for Flats Fishing Guide Licence - Renewal'
  resource = '/flats_fishing_licenses'
  form_key = 'flats_fishing_license'
  free = false
  postpaid = false
  defaults = {
    renewal: true,
  }
  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: true,
      },
    },
  }

  skip_location = true
  hide_buttons = {
    status: true,
    deny: true,
  }
  custom_acceptance = false

  // fee = 112

  validate = null

  foremost = null

  columns = props => [
    {
      name: 'Current Stage',
      selector: 'application_decision',
      width: '300px',
      cell: r => <span>{getCurrentStage(r) || 'N/A'}</span>,
      searchable: r => getCurrentStage(r),
    },
  ]
  // filter_records = r => r.renewal

  service_type = 'FlatsFishingLicense'

  custom_actions = [
    {
      text: 'Submit to Director',
      icon: 'id-badge',
      roles: ['officer'],
      test: r => r.payment_status && r.application_decision == 'pending',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to submit?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and the accompanying attachments, and are ready to submit it to a Director/Approved Signatories. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.submitToDirector(record)
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['director'],
      test: r => r.payment_status && (r.application_decision == 'processing' || r.application_decision == 'officer viewed'),
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.approve(record)
          }
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]

  submitToDirector = async record => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'officer viewed'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval by the Director/Approved Signatories.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - Flats Fishing Permit- Renewal',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Congratulations! Your application for a new Flats Fishing Permit - Renewal has been approved.
            <br/>
            A downloadable PDF document will be sent to your email shortly.
            <br/>
            Thank you for using the MyGateway Portal.
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
      try {
        const { data } = await $app.axios.get('/services/all_paid_forms', {
          params: {
            service_type: this.service_type || props.service.type,
          },
        })
        const updated_record = data.paid_forms.filter(
          d => d.id === record?.id
        )[0]
        await $app.axios.post('/emails', {
          email: record.user.email,
          subject: `MyGateqway Portal Update - Flats Fishing Licence Renewal`,
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
           Your application for a Flats Fishing Licence Renewal has been approved. As promised, below is a link to your downloadable PDF Permit.
            <br/><br />
            <p><b>Links</b></p>
            <ul>
            <li><a href='${`${ENV.WEB_SERVICE_URL}${updated_record?.image[0]}`}' target='_blank'>Flats Fishing Licence Renewal</a></li>
            </ul>
            <br/><br/>
            Thank you for using MyGateway Portal.
          `,
        })
      } catch (error) {
        toastr.error('Error', 'Unable to send permit email')
        console.error(error)
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
          license_numb: record.license_numb || 0,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - Flats Fishing Licence Renewal',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Thank you for your interest in applying for a new flats fishing licence renewal. 
            Unfortunately, your application was denied due to outstanding documents required. 
            Please review the requirements and resubmit. 
            Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services. 
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  fields = form => [
    {
      name: 'renewal',
      hide: true,
      save: true,
    },
    {
      name: 'license_numb',
      label: 'Permit Number #',
      is: 'integer',
    },
    {
      name: 'cert_upload',
      label: 'Fly Fishing Assosciation Certificate',
      type: 'file:pdf',
      key: f => f.image[1],
    },
    {
      name: 'date_of_cert',
      label: 'Issue Date of Certification',
      type: 'date',
    },
    {
      name: 'previous_cert_upload',
      label: 'Previous Fly Fishing Assosciation Certificate',
      type: 'file:pdf',
      key: f => f.image[2],
    },
    {
      name: 'declare',
      label:
        'I DECLARE THAT ANY PERMIT USED AS A RESULT OF THIS APPLICATION WILL BE USED ONLY IN ACCORDANCE WITH THE FISHERIES RESOURCES (JURISDICTION AND CONSERVATION) (FLATS FISHING) REGULATIONS, 2017 AND THE REGULATIONS MADE THEREUNDER.',
      type: 'checkbox',
    },
    // {
    //   name: 'training_verified',
    //   label:
    //     'Has training of applicant been verified by Ministry of Tourism in accordance with regulation 5(2)(b)?',
    //   hide: true,
    //   view: true,
    // },
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Flats Fishing License',
      key: f => f.image[0],
    },
  ]

  options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  closeModal = () => this.setState({ record: null })

  on = {
    verify: v => this.setState({ training_verified: v.length && v[0].value }),

    submit: async () => {
      this.setState({ loading: true })

      const { state, props, resource, form_key } = this
      const { history, location } = props
      const { record, training_verified } = state
      const { axios } = $app

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
            training_verified,
          },
        })

        if (location.state?.view) {
          const view = { ...location.state.view }
          view.application_decision = 'approved'
          view.fields[2].value = training_verified

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success('Success', 'Application has been approved and verified')
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to approve and verify application')
        return
      }

      try {
        await axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal Update - Flats Fishing License',
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
            Your Flats Fishing application has been approved and training has been verified.
            <br/><br />
            Thank you for using the MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false, record: null })
    },
  }

  render() {
    const { state, on, closeModal, options } = this
    const { training_verified } = state

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 720 }}
          onClick={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Set Details</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='training_verified'>
                  Has training of applicant been verified by Ministry of Tourism
                  in accordance with regulation 5(2)(b)?
                  <span className='required-label'>*</span>
                </label>
                <SelectField
                  onChange={on.verify}
                  name='service'
                  icon={null}
                  options={options}
                  values={[
                    options.find(o => o.value == training_verified) || {},
                  ]}
                  value={training_verified}
                  validators={['required']}
                  errorMessages={['Option must be selected']}
                  className='form-control w-100'
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-round mr-2'
                aria-label='Close'
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    )
  }
}
