import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

import SamplePassportImage from '@images/sample-passport-data-page.png'
import BadPassportImage from '@images/sample-passport-data-page-bad.jpg'

import {
  markAsVetted,
  recommendToPS,
  recommendToMinister,
  appealApplication,
  SECURITY_COLUMNS
} from './_security-utils'

export default class SecurityLicenceRenewal extends React.Component {
  resource = '/inquiry_and_security_licenses'
  form_key = 'inquiry_and_security_license'
  fee = 99
  free = false
  postpaid = false

  isSecurity = /security-/.test(this.props.location.pathname)

  hide_buttons = {
    status: true,
    deny: !$app.hasRole('minister'),
  }
  
  skip_location = true
  columns = SECURITY_COLUMNS
  custom_acceptance = true
  custom_actions = [
    markAsVetted.call(this),
    recommendToPS.call(this),
    recommendToMinister.call(this),
    appealApplication.call(this)
  ]

  defaults = {
    client_type: 'individual',
    renewal: true,
    temporary: false,
    agent_type: this.isSecurity ? 'security guard' : 'inquiry agent',
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        temporary: false,
        renewal: true,
        appeal: false,
        agent_type: this.defaults.agent_type
      },
    },
  }

  fields = form => [
    {
      name: 'client_type',
      save: true,
      hide: true,
    },
    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    {
      name: 'temporary',
      save: true,
      hide: true,
    },
    {
      name: 'agent_type',
      save: true,
      hide: true,
    },
    {
      name: 'po_box',
      save: true,
      hide: true,
    },
    {
      name: 'license_numb',
      label: 'Licence Number',
      // is: 'integer',
    },
    '::Personal Contact Information',
    {
      name: 'street_address',
      label: 'Street Address',
    },
    {
      name: 'po_box',
      label: 'P.O. Box',
      required: false,
      use_profile: 'po_box',
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      is: 'phone',
      use_profile: 'additional_phone_number',
    },

    '::Uploads',
    {
      name: 'registrar_cert_upload',
      label: 'Certificate of Registrar of Companies / Certificate of Incorparation / Approval of Trade Name',
      key: 'image.2',
      hide: form.client_type !== 'business',
      type: 'file:all',
      // required: false,
    },
    {
      name: 'photo_upload',
      label: 'Passport sized Photo',
      type: 'file:image',
      key: 'image.5',
      hide: form.client_type === 'business',
    },
    {
      name: 'identification_upload',
      label: 'Government Issued Photo ID',
      type: 'file:image',
      // hint: 'Passport, Driver\'s Licence, or Voter\'s Card.',
      key: 'image.3',
      hide: form.client_type === 'business',
      hint: `
      The <strong>information page</strong> of your passport,
      or the front side of your Driver's Licence or Voter's Card.<br/>
      <strong>Selfies will not be accepted.</strong>
      <div class='flex flex-row items-center'>
        <div class='flex flex-row items-center mr-6'>
          <img class='w-32 md:w-40 lg:w-48 mr-2' src='${SamplePassportImage}'/>
          <strong class='text-green-500 font-extrabold text-xl'>&checkmark;</strong>
        </div>
        <div class='flex flex-row items-center mr-6'>
          <img class='w-32 mx-4' src='${BadPassportImage}'/>
        </div>
      </div>
      `
    },
    {
      name: 'police_cert_upload',
      label: 'Valid Police Character Certificate',
      type: 'file:image',
      key: 'image.11',
      hint: 'Not more than six (6) months old',
      hide: form.client_type === 'business',
    },
    {
      heading: `
        <span class='text-sm text-muted'>
          Please upload your previous licence booklet;
          you may use the three upload fields below if you cannot save it
          all on one image or PDF
        </span>
      `,
      view: false,
    },
    {
      name: 'license_booklet_upload',
      key: 'image.8',
      label: 'Valid Licence Booklet',
      type: 'file:pdf',
    },
    {
      name: 'license_booklet_2_upload',
      key: 'image.9',
      label: 'Additional Booklet Upload',
      type: 'file:pdf',
      required: false,
    },
    {
      name: 'license_booklet_3_upload',
      key: 'image.10',
      label: '2nd Additional Booklet Upload',
      type: 'file:pdf',
      required: false,
    },
    '::SPACER',
    {
      name: 'final_declartion',
      long: true,
      label: `
        By clicking this box, I confirm that I understand my obligations under
        the respective laws and regulations applicable to the application for 
        security guards and/or inquiry agents licence and agree to observe
        all applicable laws, regulations, policies and guidelines.
      `,
      type: 'checkbox',
    },
    {
      name: 'acceptance',
      type: 'checkbox',
      use_as_acceptance: true,
      label: `
        By clicking here you agree that the information provided is accurate
        and lawful without false pretence
      `
    }
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: this.isSecurity
        ? 'Security Guard Certificate'
        : 'Inquiry Agent Certificate',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
