import React from 'react'

import Loader from '@shared/loader'
import ENV from '@constants/env'
import DTULogo from '@images/govt-logo.png'
import CoatOfArms from '@images/bs-coat-of-arms.png'
import Backdrop from '@images/backdrops/c.jpg'
import MainLogo from '@images/logo/default.png'

export default function LandingPage(props) {
  const footer = props.hideFooter ? null : (
    <div className='text-center'>
      For more information, please visit <br />
      <a href={ENV.PARENT_SITE_URL}>{ENV.PARENT_SITE_NAME}</a>
      <br />
      <br />
      <small>
        Powered By:
        <img style={{ width: 96 }} src={DTULogo} alt='DTU Logo' />
      </small>
    </div>
  )

  return (
    <React.Fragment>
      <Loader loading={props.loading} />
      <div
        className='wrapper wrapper-login wrapper-login-full p-0 bg-left bg-cover bg-no-repeat'
        style={{ backgroundImage: `url(${Backdrop})` }}
      >
        <div
          className='login-aside w-50 d-flex'
          style={{ position: 'relative' }}
        >
          <img src={MainLogo} alt='My Gateway Logo' />
        </div>
        <div className='login-aside w-50 d-flex'>
          <div className='container container-login container-transparent animated fadeIn'>
            <div className='card rounded p-4 mt-4'>
              <img
                src={CoatOfArms}
                alt="The Bahamas' Coat Of Arms"
                className='block mx-auto'
                style={{ width: '8.75rem' }}
              />
              {props.children}
              {footer}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
