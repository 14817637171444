import React from 'react'
// import moment from 'moment'
// import Swal from 'sweetalert2'

// import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
// import Loader from '@shared/loader'
import SERVICE_PROPS from '@helpers/extract-service-props'
// import STRINGS from '@constants/strings'

export default class InternationalDriversLicense extends React.Component {
  resource = '/international_drivers_licenses'
  form_key = 'international_drivers_license'
  c_key = 'international_drivers_license'

  fee = 50
  skip_location = true

  fields = () => [
    {
      name: 'vehicle_category',
      label: 'I wish to apply for an international Permit to drive a Motor Vehicle in: ',
      type: 'select',
      options: [
        { label: 'A Motor Vehicle where maximum weight fully laden exceeds 3,500 kilog', value: 'Vehicle Max-weight 3,500' },
        { label: 'A Motor Vehicle  where minimum weight fully laden exceeds 3,500 kilog', value: 'Vehicle Min-weight 3,500' },
        { label: 'A Motor Cycle with or without Side-car', value: 'Motorcycle' },
      ],
    },
    {
      name: 'local_license_numb',
      label: 'Local License #',
      required: true,
    },

    '::Uploads',
    {
      name: 'photo_upload',
      label: 'Passport sized Photo',
      type: 'file:image',
      key: 'image.0',
      required: false,
    },
    // {
    //   name: 'photo_2_upload',
    //   label: 'Passport sized Photo',
    //   type: 'file:image',
    //   key: 'image.1',
    // },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
