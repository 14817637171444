import React from 'react'
import { connect } from 'react-redux'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { ValidatorForm } from 'react-form-validator-core'

import MCSidePanel from './sidebar'
import { sendMessage } from '@actions/notes'
import { getAgencies } from '@actions/agencies'

import InputField from '@shared/form-fields/input'
import SelectField from '@shared/form-fields/select'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const blankState = ({ note }) => ({
  editor: EditorState.createEmpty(),
  form: {
    email: '',
    type: '',
    subject: '',
    ...(note || {}),
  },
})

const hide = ['doh', 'po']

class ComposeMail extends React.Component {
  fromInvoice = this.props.location.state?.note
  state = blankState(this.props.location.state || {})

  componentDidMount() {
    !this.props.agencies.length && this.props.getAgencies()
  }

  componentWillReceiveProps({ sendMsg }) {
    sendMsg && this.on.reset()
  }

  on = {
    change: ({ target }) => {
      this.setState(state => {
        state.form[target.name] = target.value
        return state
      })
    },

    select: name => val =>
      val.length &&
      this.setState(state => {
        state.form[name] = val[0].value
        return state
      }),

    editor: editor => this.setState({ editor }),

    reset: () =>
      this.setState(() => blankState(this.props.location.state || {})),

    upload: () => {},

    submit: event => {
      event.preventDefault()

      const data = {
        ...this.state.form,
        message: draftToHtml(
          convertToRaw(this.state.editor.getCurrentContent())
        ),
      }

      this.props.sendMessage(data, () => {
        this.on.reset()
        this.props.history.push('/message-center/inbox')
      })
    },
  }

  render() {
    const { state, props, on } = this

    let fields

    if (this.fromInvoice) {
      fields = (
        <div className='form-group row'>
          <label htmlFor='to' className='col-form-label col-md-2 text-right'>
            To
          </label>
          <div className='col-md-10'>
            <InputField
              onChange={on.change}
              name='email'
              value={state.form.email}
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Invalid email']}
              className='form-control'
              disabled
            />
          </div>
        </div>
      )
    } else {
      const options = props.agencies
        .filter(a => hide.indexOf(a.code.toLowerCase()) == -1)
        .map(a => ({
          label: `${a.name.capitalize()} (${a.support_email})`,
          value: a.support_email,
        }))

      fields = (
        <React.Fragment>
          <div className='form-group row'>
            <label htmlFor='to' className='col-form-label col-md-2 text-right'>
              Agency
            </label>
            <div className='col-md-10'>
              <SelectField
                onChange={on.select('email')}
                options={options}
                name='email'
                value={state.form.email}
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Invalid email']}
                className='form-control'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='subject'
              className='col-form-label col-md-2 text-right'
            >
              Subject
            </label>
            <div className='col-md-10'>
              <InputField
                onChange={on.change}
                name='subject'
                id='subject'
                value={state.form.subject}
                validators={['required']}
                errorMessages={['This field is required']}
                className='form-control'
              />
            </div>
          </div>
        </React.Fragment>
      )
    }

    return (
      <div className='email-content email-content-detail content content-full'>
        <div className='page-inner-fill'>
          <div className='page-with-aside mail-wrapper bg-white'>
            <MCSidePanel {...props} />
            <div className='email-content-main page-content mail-content'>
              <div className='email-head d-lg-flex d-block'>
                <h3>
                  <i className='flaticon-pen mr-1'></i>
                  Compose new message
                </h3>
              </div>
              <ValidatorForm method='post' onSubmit={on.submit}>
                <div className='email-compose-fields'>
                  {fields}

                  <div className='form-group row'>
                    <label
                      htmlFor='subject'
                      className='col-form-label col-md-2 text-right'
                    >
                      Message
                    </label>
                    <div className='col-md-10'>
                      <div style={{ border: '1px solid #ccc' }}>
                        <Editor
                          editor={state.editor}
                          toolbarClassName='toolbarClassName'
                          wrapperClassName='editorwrapper'
                          editorClassName='editorsample'
                          onEditorStateChange={on.editor}
                          toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: {
                              urlEnabled: false,
                              uploadCallback: on.upload,
                              alt: { present: false, mandatory: false },
                            },
                          }}
                          height='600'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='email-editor'>
                  <div id='editor'></div>
                  <div className='email-action'>
                    <button type='submit' className='btn custom-btn'>
                      Send
                    </button>
                    <a className='btn btn-danger' onClick={on.reset}>
                      Reset
                    </a>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ notes, agencies }) => ({
    sendMsg: notes.sendMsg,
    agencies: agencies.agencies,
  }),
  { sendMessage, getAgencies }
)(ComposeMail)
