import React from "react";
import Modal from "react-responsive-modal";
import { ValidatorForm } from "react-form-validator-core";

import SelectField from "@shared/form-fields/select";
import Range from "@helpers/range";
import { TimePeriod } from "./TimePeriod";

export const SlotModal = (props) => {
  const {
    services,
    locations,
    on,
    slotModal,
    onClose,
    handleSlotSubmit,
    service,
    location,
    interval,
    slotPeriod,
    slot,
  } = props;

  const periods = Range(1, 6)
    .map((n) => n * 10)
    .map((n) => ({ value: n, label: String(n) }));
  const slots = Range(1, 10).map((n) => ({ value: n, label: String(n) }));

  return (
    <React.Fragment>
      <Modal
        open={slotModal}
        styles={{ modal: { width: "850px" } }}
        onClose={onClose}
        center
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Change amount of slots</h5>
          </div>
          <ValidatorForm id="time-slot" onSubmit={handleSlotSubmit}>
            <div className="modal-body">
              <Field name="service" label="Service">
                <SelectField
                  onChange={on.service}
                  name="service"
                  key={service}
                  icon={null}
                  options={services}
                  values={[services.find(s => s.value == service) || {}]}
                  value={service}
                  validators={["required"]}
                  errorMessages={["Service must be selected"]}
                  className="form-control w-100"
                />
              </Field>
              <Field name="location" label="Location">
                <SelectField
                  onChange={on.location}
                  key={location}
                  name="location"
                  icon={null}
                  options={locations}
                  values={[locations.find(l => l.value == location) || {}]}
                  value={location}
                  validators={["required"]}
                  errorMessages={["Location must be selected"]}
                  className="form-control w-100"
                />
              </Field>
              <Field name="slotsPerTime" label="Slots Per Time">
                <TimePeriod slots={slots} timePeriod={slotPeriod} on={slot} />
              </Field>
              <Field name="form_period" label="Interval">
                <SelectField
                  onChange={on.select("interval")}
                  name="form_period"
                  icon={null}
                  options={periods}
                  values={[interval]}
                  value={interval}
                  validators={["required"]}
                  errorMessages={["Time period must be selected"]}
                  className="form-control w-100"
                />
              </Field>
            </div>
            <div className="modal-footer">
              <input
                className="btn custom-btn btn-round"
                type="submit"
                value="Submit"
              />
            </div>
          </ValidatorForm>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const Field = ({ name, label, children }) => {
  return (
    <div className="form-group form-show-validation row">
      <label htmlFor={name} className="col-md-2 col-sm-4 mt-sm-2 text-right">
        {label}
        <span className="required-label">*</span>
      </label>
      <div className="col-md-10">{children}</div>
    </div>
  );
};
