import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import ENV from '@constants/env'
import ISLANDS from '@constants/islands'
import NATIONALITIES from '@constants/nationalities'
import generateRange from '@helpers/range'
//import { required } from 'react-form-validator-core/lib/ValidationRules'
//import { update } from 'immutable'

export default class DisabilityRegistration extends React.Component {
  resource = '/disability_registrations'
  form_key = 'disability_registration'
  title = 'National Commission for Persons with Disabilities'
  defaults = {}
  free = true

  skip_location = true
  hide_buttons = false

  custom_actions = []

  validate = form => {
    const update = {
      top_two_concerns: form.top_two_concerns.slice(0, 2),
    }
    return update
  }

  foremost = null

  fields = form => [
    {
      name: 'i_am_disabled',
      type: 'select:bool',
      label: 'Are you completing this form for yourself?',
      view: {
        label: 'Applicant is Disabled Person?',
      },
    },
    {
      name: 'relationship_to_disabled',
      label: 'What is your relationship to the disabled person?',
      datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
      hide: form.i_am_disabled,
    },
    {
      heading: `Disabled Person's Details`,
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_first_name',
      label: 'First Name',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_middle_name',
      label: 'Middle Name',
      hide: form.i_am_disabled,
      required: false,
    },
    {
      name: 'disabled_last_name',
      label: 'Last Name',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_sex',
      label: 'Sex',
      options: ['Male', 'Female'],
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_date_of_birth',
      type: 'date',
      label: 'Date of Birth',
      hide: form.i_am_disabled,
      showYearDropdown: true,
    },
    {
      name: 'disabled_nib',
      label: 'NIB#',
      is: 'integer',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_nationality',
      label: 'Nationality',
      options: NATIONALITIES,
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_place_of_birth',
      label: 'Place of Birth',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_marital_status',
      label: 'Marital Status',
      hide: form.i_am_disabled,
      options: ENV.MARITAL_STATUSES,
    },
    {
      name: 'disabled_address',
      label: 'Street Address',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_po_box',
      label: 'P. O. Box',
      hide: form.i_am_disabled,
      required: false,
    },
    {
      name: 'disabled_island',
      label: 'Island',
      hide: form.i_am_disabled,
      options: ISLANDS,
    },
    {
      name: 'disabled_settlement',
      label: 'Settlement',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_home_phone',
      label: 'Home Phone',
      type: 'phone',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_mobile_phone',
      label: 'Mobile Phone',
      type: 'phone',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_work_phone',
      label: 'Work Phone',
      type: 'phone',
      hide: form.i_am_disabled,
    },
    {
      name: 'disabled_email',
      label: 'Email Address',
      hide: form.i_am_disabled,
    },

    '::SPACER',
    {
      name: 'emergency_contacts',
      columns: [
        'first_name',
        'last_name',
        {
          name: 'relationship',
          datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
        },
        {
          name: 'phone_numb',
          label: 'Contact #',
          is: 'phone',
        },
      ],
    },
    {
      name: 'constant_medical_assistance',
      type: 'select:bool',
      label: 'Do you require constant medical attention?',
    },
    {
      name: 'medical_assistance_details',
      hide: !form.constant_medical_assistance,
      type: 'textarea',
      label: 'Please give details',
    },
    {
      name: 'service_animal',
      type: 'select:bool',
      label: 'Do you have a service animal?',
    },
    {
      name: 'service_animal_details',
      hide: !form.service_animal,
      type: 'textarea',
      label: 'Please give details',
    },
    {
      name: 'disablities_diagnosed',
      label: 'Type of Disability',
      hint: 'Please select all that apply',
      multi: true,
      options: [
        { label: 'Sight (Totally/Legally Blind)', value: 'Sight' },
        {
          label: 'Hearing (Partially/Totally Deaf, Use of Hearing Aids)',
          value: 'Hearing',
        },
        'Speech/Communication',
        'Learning/Intellectual/Development Disorder',
        'Autism',
        {
          label: 'Mobility/Moving (Absent/Impared Limb)',
          value: 'Mobility/Moving (Limb)',
        },
        {
          label: 'Mobility/Moving (Paralysis)',
          value: 'Mobility/Moving (Paralysis)',
        },
        {
          label: 'Chronic Pain Disorder (Medically Diagnosed)',
          value: 'Chronic Pain',
        },
        'Mental Disorders',
        { label: 'Other', value: 'Other' },
      ],
    },
    {
      name: 'disability_details',
      label: 'Please give further details',
      type: 'textarea',
    },

    '::Education Information',
    {
      name: 'schools_attended',
      label: 'I attended...',
      multi: true,
      required: false,
      hint: 'Select all that apply (Skip if not applicable)',
      options: [
        'Preschool',
        'Primary School',
        'Home School',
        'Special School',
        'Alternative School',
      ],
    },
    {
      name: 'school_attending',
      label: 'I currently attend...',
      multi: true,
      hint: 'Select all that apply (Skip if not applicable)',
      required: false,
      options: [
        'Preschool',
        'Primary School',
        'Home School',
        'Special School',
        'Alternative School',
      ],
    },
    {
      name: 'school_name',
      hide: !form.school_attending?.length,
      label: 'Name of School',
    },
    '::Current Status',
    {
      name: 'completed_school',
      type: 'checkbox',
      label: 'I have completed/finished school but have not graduated',
      required: false,
    },
    {
      name: 'graduated_school',
      type: 'checkbox',
      label: 'I have graduated from school',
      required: false,
    },
    {
      name: 'never_attended_school',
      type: 'checkbox',
      label: 'I have never attended school',
      required: false,
    },
    {
      name: 'highest_grade_completed',
      label: 'Highest Grade Completed',
      hide: form.never_attended_school,
      options: generateRange(1, 12).map(n => String(n)),
    },
    {
      name: 'tertiary_education_completed',
      label: 'Tertiary Education Completed',
      hide: form.never_attended_school,
      options: [
        'None',
        'Trade/Vocational School',
        'College',
        'University',
        'Other',
      ],
    },
    {
      name: 'tertiary_education_completed_other',
      label: 'Please Specify',
      hide: form.tertiary_education_completed != 'Other',
    },

    '::Employment Information',
    {
      name: 'currently_employed',
      type: 'select:bool',
      label: 'Are you employed?',
    },
    {
      name: 'disabled_occupation',
      hide: !form.currently_employed,
      label: 'Current Occupation',
    },
    {
      name: 'seeking_employed',
      type: 'select:bool',
      label: 'Are you seeking employment?',
      hide: form.currently_employed,
    },
    {
      name: 'job_preferences',
      label: 'What are your job preferences?',
      hide: form.currently_employed || !form.seeking_employed,
    },
    {
      name: 'special_skills',
      label: 'Skills, Training & Certifications',
      hide: form.currently_employed || !form.seeking_employed,
      columns: ['skill'],
      format: v => v.skill,
    },

    '::Concerns',
    {
      name: 'top_two_concerns',
      label: `What are your top two concerns as a person with a disability?`,
      multi: true,
      options: [
        'Transportation',
        'Communication/IT',
        'Housing',
        'Health Care',
        'Insurance',
        'Accessibility',
        'Employment Opportunities',
        'Educational Opportunities',
        'Recreational/leisure/social opportunities',
        'Other',
      ],
    },
    {
      name: 'top_two_concerns_others',
      label: 'Please specify',
      hide: !form.top_two_concerns?.includes?.('Other'),
      columns: ['Please specify'],
      format: v => v.skill,
    },
    {
      name: 'concerns_details',
      type: 'textarea',
      label: 'Please provide additional comments regarding your concerns',
      required: false,
    },
    {
      name: 'identification_upload',
      key: 'image.0',
      type: 'file:image',
      label: 'Government Issued ID',
    },
    {
      name: 'doctor_letter_upload',
      required: false,
      key: 'image.1',
      type: 'file:pdf',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
