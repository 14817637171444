export default class Digger {
  constructor (obj) {
    this.original = obj
    this.data = this.original
  }

  dig (path = '', value, ) {
    const props = (typeof path == 'string' ? path.split('.') : path).reverse()
    // console.log(`Searching for ${path} to assign the value "${value}"`)
    return this.down(this.original, props, value)
  }

  down (obj, props = [], value) {
    if (obj === undefined || !props.length) return obj
    if (obj !== undefined && props.length == 1 && value) {
      const prop = props.pop()
      obj[prop] = value
      return this.original
    }
  
    return this.down(obj[props.pop()], props, value)
  }
}
