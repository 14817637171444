import React from "react";

import Wizard from "@modules/form-wizard";
import countries from "@constants/countries";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class ReturningResidentsExemption extends React.Component {
  id = "ReturningResidentsExemption";
  service = "returning residents exemption";
  title = "Returning Residents Exemption";
  agency = "mofrd";
  item = "20";
  resource = "/returning_residents_exemptions";
  form_key = "returning_residents_exemption";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  resdident_types = ["Resident", "Employment", "Student"].map((l) => ({
    label: l,
    value: l.toLowerCase(),
  }));

  fields = (form) => [
    "::Address Outside The Bahamas",
    "street_address",
    "city",
    "zip_postal",
    {
      name: "apartment_no",
      required: false,
    },
    {
      name: "country",
      options: countries.filter((country) => country != "Bahamas"),
    },
    '::SPACER',
    {
      name: "residency_type",
      options: this.resdident_types,
    },
    {
      name: "residency_name",
      label: "Residency Status",
    },

    {
      name: "residency_years",
      label: "Years Abroad",
      is: "float",
    },
    {
      name: "proof_upload",
      label: "Proof",
      type: "file:image",
    },
    {
      name: "additional_proof_upload",
      label: "Additional Proof",
      type: "file:image",
    },
    "::Exemptions",
    {
      name: "vehicle_exemption",
      type: "checkbox",
      label: "Vehicle Exemption?",
    },
    {
      name: "bill_of_sale_upload",
      label: "Bill Of Sale",
      type: "file:image",
      hide: !form.vehicle_exemption,
    },
    {
      name: "title_upload",
      label: "Title",
      type: "file:image",
      hide: !form.vehicle_exemption,
    },
    {
      name: "registration_upload",
      label: "Registration",
      type: "file:image",
      hide: !form.vehicle_exemption,
    },
    {
      name: "insurance_certificate_upload",
      label: "Insurance Certificate",
      type: "file:image",
      hide: !form.vehicle_exemption,
    },
    {
      name: "finance_agreement_upload",
      label: "Finance_Agreement",
      type: "file:image",
      hide: !form.vehicle_exemption,
    },
    {
      name: "household_exemption",
      type: "checkbox",
      label: "Household Exemption?",
    },
    {
      name: "list_upload",
      label: "Household Items List",
      type: "file:image",
      hide: !form.household_exemption,
      tooltip: "The items on the list should not exceed $10,000 in value",
    },
    '::SPACER',
    {
      name: "declaration",
      type: "checkbox",
      label: `
        I understand that any person who makes or causes to be made any declaration, certificate application
        orother document or written statement under this Act that is false or incorrect in
        any material particular commits an offense and shall be liable to a summary conviction
        to imprisonment of a maximum term of three years, or to a maximum fine of ten thousand
        dollars, or both such fine and imprisonment
      `,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
