import React from 'react'
import AgriImport from './_agri-import'

export default class PoultryImport extends React.Component {
  render = () => {
    const { props } = this

    const aprops = {
      resource: '/poultry_import_licenses',
      form_key: 'poultry_import_license',
      _sid: 'poultry import license',
      service: props.service,
      form_name: 'Poultry',
      importProps: {
        metric_list: [
          'Each Per Item',
          'Cases per item',
          'Bags per item',
          'Bins per item',
          'Pounds per item',
          'Sacks per item',
        ],
        hide_scientific: true,
      },
      ...props,
    }

    return (
      <>
        <AgriImport {...aprops} />
      </>
    )
  }
}
