import React from 'react'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import passportPhotoExample from '@helpers/passport-photo-example'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'
import ENV from '@constants/env'
import { fullName } from '@modules/form-wizard/utils'

const payUnits = {
  Hourly: 'Hour',
  Weekly: 'Week',
  Annual: 'Annum.',
}

const RECOMMENDATION_COLUMNS = () => [
  {
    name: 'PES Recommended?',
    omit: !$app.hasAnyRole(
      'officer',
      'dol_pes',
      'director',
    ),
    sortable: true,
    selector: row => {
      switch (row.pes_recommendation) {
        case 'approved':
          return 'YES'
        case 'denied':
          return 'NO'
        case 'pending':
          return 'PENDING'
        default:
          return 'Not Deferred'
      }
    }
  },
  {
    name: 'OSH Recommended?',
    omit: !$app.hasAnyRole(
      'officer',
      'dol_osa',
      'director',
    ),
    sortable: true,
    selector: row => {
      switch (row.osh_recommendation) {
        case 'approved':
          return 'YES'
        case 'denied':
          return 'NO'
        case 'pending':
          return 'PENDING'
        default:
          return 'Not Deferred'
      }
    }
  },
]

export default class VacancyNotice extends React.Component {
  resource = '/vacancy_notices'
  form_key = 'vacancy_notice'
  columns = RECOMMENDATION_COLUMNS

  isRenewal = /renewal/.test(this.props.location.pathname)

  fee = 50
  skip_location = true

  // foremost = {
  //   type: 'select:bool',
  //   test: v =>
  //     !v || {
  //       icon: 'warning',
  //       heading: 'Please Renew Instead',
  //       message: 'Please use the Vacancy Notice Renewal form instead.',
  //     },
  //   long: true,
  //   label: 'Has your labour certificate expired?',
  // }

  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
      },
    },
  }

  hide_buttons = {
    deny: $app.hasAnyRole(['director', 'dol_osa', 'dol_pes']),
    status: r => !$app.hasRole('director')
      || r.application_decision != 'officer viewed',
  }

  custom_actions = [
    {
      text: 'Submit to Director',
      icon: 'user-tie',
      roles: ['officer'],
      fn: submit.bind(this),
    },
    // Submit to Director with additional comment box
    {
      text: 'Submit to Director',
      icon: 'user-tie',
      roles: [['dol_osa', 'dol_pes']],
      fn: submit_comment.bind(this),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['director'],
      test: r => r.application_decision === 'officer viewed' || r.application_decision === 'processing',
      fn: approve.bind(this),
    },
    {
      text: 'Defer Application',
      icon: 'file-import',
      roles: ['director'],
      test: r => r.application_decision === 'officer viewed' || r.application_decision === 'processing',
      fn: defer.bind(this),
    },
    // {
    //   text: 'Send Note to Director',
    //   icon: 'user-tie',
    //   roles: [['officer', 'dol_osa', 'dol_pes']],
    //   fn: note,
    // },
    {
      text: 'Upload OSH Report',
      icon: 'file-alt',
      roles: [['dol_osa']],
      fn: upload.bind(this),
    },
  ]

  validate = form => ({
    po_box: form.po_box ?? '#####',
  })

  fields = form => [
    {
      name: 'renewal',
      hide: true,
      save: true,
    },
    {
      heading: 'Business Details',
      hide: form.client_type !== 'business',
    },
    {
      name: 'company_name',
      label: 'Company Registered Name',
      hide: form.client_type !== 'business',
      disabled: true,
    },
    {
      name: 'bl_number',
      label: 'Business Licence Number',
      is: 'integer',
      hide: form.client_type !== 'business',
      required: false,
      disabled: true,
    },
    {
      name: 'tin_number',
      label: 'TIN#',
      is: 'integer',
      disabled: true,
      hide: form.client_type !== 'business',
    },
    {
      name: 'company_email',
      label: 'Company email address',
      view: false,
      hide: form.client_type !== 'business',
      disabled: true,
    },
    {
      name: 'street_address',
      hide: form.client_type !== 'business',
    },
    {
      name: 'po_box',
      label: 'P.O. Box',
      hide: form.client_type !== 'business',
      save: true,
    },
    {
      name: 'phone_number',
      label: 'Telephone Number',
      type: 'phone',
      hide: form.client_type !== 'business',
    },
    {
      name: 'business_type',
      label: 'Type of Business',
      view: false,
      hide: form.client_type !== 'business',
    },
    {
      name: 'business_nib',
      label: 'Business NIB#',
      hide: form.client_type !== 'business',
      // disabled: true,
    },
    {
      name: 'ownership',
      options: [
        'Government',
        'Private',
        'Local',
        'Foreign',
        'Incorporated',
        'Non Profit',
      ],
      view: false,
      hide: form.client_type !== 'business',
    },
    {
      name: 'number_of_employees',
      label: 'Current Number of Employees',
      is: 'integer',
    },
    {
      name: 'number_of_bahamians',
      is: 'integer',
      callback: v => {
        if (form.number_of_employees) {
          return { number_of_non_bahamians: form.number_of_employees - v }
        }
      },
    },
    {
      name: 'number_of_non_bahamians',
      label: 'Number of Non - Bahamians',
      is: 'integer',
    },
    ':: Position Details',
    {
      name: 'expected_hours_of_work',
      label: 'Hours of Work',
      options: [
        { value: '20 or less', label: '20 hours or less (per week)' },
        { value: '21 - 34', label: '21 - 34 hours (per week)' },
        { value: '35 or more', label: '35+ hours (per week)' },
      ],
    },
    {
      name: 'work_duration',
      label: 'Duration',
      options: ['Part-Time', 'Full-Time', 'Permanent', 'Temporary'],
    },
    {
      name: 'pay_unit',
      options: [
        { value: 'Hourly', label: 'Hourly Wage' },
        { value: 'Weekly', label: 'Weekly Wage' },
        { value: 'Annual', label: 'Annual Wage' },
      ],
    },
    {
      name: 'salary',
      label: 'Salary/Wages Per ' + (payUnits[form.pay_unit] ?? 'Period'),
      is: 'curency',
      view: {
        value: amt => `$${(+amt).toFixed(2)} Per ${payUnits[form.pay_unit]}`,
      },
    },
    'job_title',
    {
      name: 'job_desc',
      type: 'textarea',
      label: 'Job Description',
    },
    {
      name: 'duty_details',
      label: 'Details of Duties',
      type: 'textarea',
    },
    {
      key: 'image.4',
      name: 'details_of_duty_upload',
      label: 'Upload of Details of Duties',
      type: 'file:all',
      required: false,
    },
    '::Employee Details (On behalf of whom the application is made)',
    {
      name: 'employee_first',
      label: 'First Name of Employee',
    },
    {
      name: 'employee_middle',
      label: 'Middle Name of Employee',
      required: false,
    },
    {
      name: 'employee_last',
      label: 'Last Name of Employee',
    },
    {
      name: 'employee_age',
      label: 'Age of Employee',
      is: 'integer',
    },
    {
      name: 'employee_gender',
      label: 'Gender',
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
    },
    {
      name: 'pickup_location',
      save: true,
      label: 'Island',
      options: [
        'New Providence',
        'Andros',
        'Abaco',
        'Grand Bahama',
        'Exuma',
        // 'Bimini'
      ],
    },
    {
      name: 'nationality',
      options: nationalities,
    },
    {
      key: 'image.10',
      name: 'passport_upload',
      label: 'Passport Photo',
      type: 'file:image',
      hint: passportPhotoExample,
    },
    {
      name: 'country_of_origin',
      options: countries,
    },
    {
      name: 'education',
      options: ['Primary', 'Secondary', 'High School', 'College'],
    },
    {
      name: 'academic_qualifications',
      label: 'List of Academic Qualifications',
      columns: [{ name: 'qualifications', label: 'Qualifications' }],
    },
    {
      name: 'professional_experience',
      label: 'List of Professional Experiences',
      columns: [{ name: 'experience', label: 'Experience' }],
    },
    '::SPACER',
    {
      name: 'bahamians_training',
      columns: [
        { name: 'first_name', label: 'First name' },
        { name: 'middle_initial', label: 'Middle Initial', width: 32 },
        { name: 'last_name', label: 'Last Name' },
        { name: 'date', label: 'Training Start Date', type: 'date' },
      ],
    },
  
    '::SPACER',
    {
      name: 'job_advertisement_upload',
      label: 'Job Advertisement',
      type: 'file:all',
      key: 'image.5',
      hint: `
        Please upload evidence of the 3 day newspaper advertisement of the
        vacant position. If the advertisement was posted electronically,
        kindly provide a PDF or screenshot of the ads showing
        the relevant URL/link web address where posted.
      `,
    },
    {
      key: 'image.6',
      name: 'job_advertisement_2_upload',
      type: 'file:all',
      label: 'Additional Job Ad',
      required: false,
    },
    {
      key: 'image.7',
      name: 'job_advertisement_3_upload',
      type: 'file:all',
      label: '2nd Additional Job Ad',
      required: false,
    },
    {
      name: 'job_ad_receipt_upload',
      label: 'Receipt for Job Ad',
      type: 'file:all',
      key: 'image.8',
    },
    {
      key: 'image.9',
      type: 'file:pdf',
      save: true,
      name: 'labour_cert_upload',
      label: 'Last Work Permit',
      hide: !this.isRenewal
    },
    {
      name: 'declaration',
      type: 'checkbox',
      label: `
        By clicking this box, I confirm that I understand my obligations under
        the respective laws and regulations applicable to the labour
        certificate to observe all applicable laws, regulations, policies and
        guidelines.
      `,
    },
  ]
  
  hidden_fields = () => [
    {
      key: 'image.11',
      name: 'osh_report',
      type: 'file:pdf',
      admin: true,
    },
    {
      name: 'incorporation_cert_upload',
      label: 'Job Advertisement(v1)',
      type: 'file:all',
      key: 'image.1',
      hint: `
        Please upload evidence of the 3 day newspaper advertisement of the
        vacant position. If the advertisement was posted electronically,
        kindly provide a PDF or screenshot of the ads showing
        the relevant URL/link web address where posted.
      `,
    },
    {
      name: 'waiver_upload',
      label: 'Receipt for Job Ad(v1)',
      type: 'file:all',
      key: 'image.2',
    },
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Labour Certificate',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

async function approve (record) {
  const { isConfirmed } = await Swal.fire({
    title: 'Approve Application',
    text: `
    Are you sure that you would like to approve this application?
    By clicking "Confirm", you are acknowleding that you have reviewed this
    application and are satisfied with its contents. Would you like to approve?
    `,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Confirm',
  })

  if (!isConfirmed) return
  this.setState({ loading: true })

  const { props, resource, form_key } = this
  const { history, location } = props
  const { axios } = $app

  try {
    await axios.put(resource + '/update_application', {
      [form_key]: {
        form_num: record.form_num,
        application_decision: 'approved',
      },
    })

    if (location.state?.view) {
      const view = { ...location.state.view }
      view.application_decision = 'approved'

      history.replace(location.pathname, { ...location.state, view })
    }

    toastr.success('Success', 'Application successfully approved')
  } catch (error) {
    console.error(error)
    toastr.error('Error', 'Unable to approve application')
    return
  }

  try {
    await axios.post('/emails', {
      email: record.user.email,
      subject: 'MyGateway Portal Update - Vacancy Notice',
      message: `
        Dear ${record.user.first_name.capitalize()},
        <br/>
        Congratulations! Your application for a Vacancy Notice certificate has been approved.
        <br/>
        <br/>
        Thank you for using the MyGateway Portal.
      `,
    })

    toastr.success('Success', 'Confirmation email successfully sent')

    try {
      const { data } = await $app.axios.get('/services/all_paid_forms', {
        params: { service_type: this.service_type || props.service.type },
      })

      const updated_record = data.paid_forms.filter(d => d.id === record?.id)[0]

      await $app.axios.post('/emails', {
        email: record.user.email,
        subject: `MyGateqway Portal Update -  Vacancy Notice`,
        message: `
          Good day ${record.user.first_name.capitalize()},
          <br/>
          Your application for a Vacancy Notice has been approved. As promised, below is a link to your downloadable PDF Permit.
          <br/><br />
          <p><b>Links</b></p>
          <ul>
          <li><a href='${`${ENV.WEB_SERVICE_URL}${updated_record?.image[0]}`}' target='_blank'>Vacancy Notice</a></li>
          </ul>
          <br/><br/>
          Thank you for using MyGateway Portal.
        `,
      })
    } catch (error) {
      toastr.error('Error', 'Unable to send permit email')
      console.error(error)
    }
  } catch (err) {
    console.error(err)
    toastr.error('Error', 'Unable to send email')
  }

  this.setState({ loading: false })
}

async function defer (record) {
  const { value: role } = await Swal.fire({
    title: 'Select Agency',
    input: 'select',
    inputOptions: {
      OSH: 'OSH',
      PES: 'PES',
    },
    showCancelButton: true,
  })

  if (!role) return

  const { value: message } = await Swal.fire({
    input: 'textarea',
    inputLabel: `Message to ${role}`,
    inputPlaceholder: 'Type your message here...',
    inputAttributes: {
      'aria-label': 'Type your message here',
    },
    showCancelButton: true,
    confirmButtonText: 'Send',
  })

  if (!message) return

  try {
    const { data } = await $app.axios.post('/services/notes/add_note', {
      note: {
        note_type: 'note',
        internal_view_only: true,
        notable_type: 'VacancyNotice',
        notable_id: record.id,
        text: `Deferred to ${role}: ${message}`,
      },
    })

    await $app.axios.put(this.resource + '/defer_to', {
      [this.form_key]: {
        form_num: record.form_num,
        deferred_role: role,
      },
    })

    record.notes.push(data.note)

    Swal.fire(
      'Success!',
      `Application deferred! Message sent to ${role}`,
      'success'
    )
  } catch (error) {
    console.error(error)
    Swal.fire(
      'Oops...',
      'There has been an error with processing this request',
      'error'
    )
  }
}

async function upload (record) {
  const { value: upload } = await Swal.fire({
    icon: 'question',
    title: 'Upload OSH Report',
    input: 'file',
    inputLabel: 'Select A File To Upload',
    inputAttributes: { accept: 'application/pdf' },
    showCancelButton: true,
    confirmButtonText: 'Upload',
  })

  if (!upload) return
  this.setState({ loading: true })

  const form = new FormData()
  form.append('vacancy_notice[form_num]', record.form_num)
  form.append('vacancy_notice[osh_report_upload]', upload)

  try {
    await $app.axios.put(this.resource + '/update_upload', form)
    toastr.success('Success', 'Report successfully uploaded')
  } catch (error) {
    console.error(error)
    toastr.error('Error', 'Unable to upload OSH report')
  }

  this.setState({ loading: false })
}

async function submit (record) {
  // const { props, resource, form_key } = this
  // const { history, location } = props
  // const { axios } = $app

  // try {
  //   await axios.put(resource + '/update_application', {
  //     [form_key]: {
  //       form_num: record.form_num,
  //       application_decision: 'processing',
  //     },
  //   })

  //   if (location?.state?.view) {
  //     const view = { ...location.state.view }
  //     view.application_decision = 'processing'

  //     history.replace(location.pathname, {
  //       ...location.state,
  //       view,
  //     })
  //   }

  //   Swal.fire(
  //     'Success!',
  //     'The application has been submitted to the Director for final approval',
  //     'success'
  //   )
  // } catch (error) {
  //   console.error(error)
  //   Swal.fire(
  //     'Oops...',
  //     'There has been an error with processing your application',
  //     'error'
  //   )
  // }
  const { props, resource, form_key } = this
  const { history, location } = props

  const { value, isDismissed } = await Swal.fire({
    icon: 'question',
    title: 'Recommend To Director',
    html: `
      What is your recommendation to the Director
      regarding the applicant,
      <strong>${fullName(record.user, 'initial')}</strong>?
    `,
    input: 'select',
    inputOptions: {
      'approved': "I recommend this applicant",
      'denied': "I do not recommend this applicant",
    },
    showCancelButton: true,
    confirmButtonText: 'Send',
  })

  if (isDismissed) return

  try {
    await $app.axios.put(resource + '/update_application', {
      [form_key]: {
        form_num: record.form_num,
        application_decision: value,
      },
    })

    if (location?.state?.view) {
      const view = { ...location.state.view }

      history.replace(location.pathname, {
        ...location.state,
        view,
      })
    }

    toastr.success('Success', 'Application successfully updated')
  } catch (error) {
    console.error(error)
    Swal.fire(
      'Oops...',
      'There has been an error with processing your application',
      'error'
    )
  }
}

async function submit_comment (record) {
  const { props, resource, form_key } = this
  const { history, location } = props

  const { value, isDismissed } = await Swal.fire({
    icon: 'question',
    title: 'Recommend To Director',
    html: `
      What is your recommendation to the Director
      regarding the applicant,
      <strong>${fullName(record.user, 'initial')}</strong>?
    `,
    input: 'select',
    inputOptions: {
      'approved': "I recommend this applicant",
      'denied': "I do not recommend this applicant",
    },
    showCancelButton: true,
    confirmButtonText: 'Send',
  })

  if (isDismissed) return

  const { value: comment } = await Swal.fire({
    input: 'textarea',
    inputLabel: `Message to Director`,
    inputPlaceholder: 'Type your message here...',
    inputAttributes: {
      'aria-label': 'Type your message here',
    },
    showCancelButton: true,
    confirmButtonText: 'Send',
  })

  try {
    await $app.axios.put(resource + '/update_application', {
      [form_key]: {
        form_num: record.form_num,
        application_decision: value,
        comment: comment,
      },
    })

    if (location?.state?.view) {
      const view = { ...location.state.view }

      history.replace(location.pathname, {
        ...location.state,
        view,
      })
    }

    toastr.success('Success', 'Application successfully updated')
  } catch (error) {
    console.error(error)
    Swal.fire(
      'Oops...',
      'There has been an error with processing your application',
      'error'
    )
  }
}

// async function note (record) {
//   const { value: message } = await Swal.fire({
//     input: 'textarea',
//     inputLabel: `Message to Director`,
//     inputPlaceholder: 'Type your message here...',
//     inputAttributes: {
//       'aria-label': 'Type your message here',
//     },
//     showCancelButton: true,
//     confirmButtonText: 'Send',
//   })

//   if (message) {
//     try {
//       const { data } = await $app.axios.post('/services/notes/add_note', {
//         note: {
//           note_type: 'note',
//           internal_view_only: true,
//           notable_type: 'VacancyNotice',
//           notable_id: record.id,
//           text: message,
//         },
//       })
//       record.notes.push(data.note)
//       console.log(data)
//       Swal.fire('Success!', `Success! Message sent to Director`, 'success')
//     } catch (error) {
//       console.error(error)
//       Swal.fire(
//         'Oops...',
//         'There has been an error with processing this request',
//         'error'
//       )
//     }
//   }
// }