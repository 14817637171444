import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
export default class BuildingPermitWalls extends React.Component {
  resource = '/building_regulations_acts'
  form_key = 'building_regulations_act'
  defaults = {
    construction_type_list: 'walls',
  }
  
  // Delete lines as needed
  // fee = 99
  free = true
  postpaid = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'construction_type_list',
      save: true,
      hide: true,
    },
    {
      name: 'area',
    },
    {
      name: 'drawings_numb',
      label: 'Number of drawings',
      type: 'integer',
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'i_am_delegate',
      label: 'I am a delegate',
      type: 'select:bool',
    },
    {
      name: 'delegate_profession',
      label: 'Profession',
    },
    '::Applicant information',
    {
      name: 'applicant_first_name',
      label: 'First Name',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_last_name',
      label: 'Last Name',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_street_address',
      label: 'Address',
      type: 'textarea',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_island',
      label: 'Island',
      options: islands,
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_phone',
      label: 'Phone Number',
      type: 'phone',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_email',
      label: 'Email',
      type: 'email',
      hide: !form.i_am_delegate,
    },
    {
      name: 'building_contractor',
    },

    '::Interest in Land',
    {
      name: 'interest_in_land',
      label: 'What is the intended use of this land?',
      type: 'textarea',
    },
    {
      name: 'development_consent',
      label:
        'Has consent been given to begin development on this land/building?',
      type: 'select:bool',
    },
    {
      name: 'land_address',
      label: 'Address of Land',
      type: 'textarea',
      hint: 'Be very decriptive',
    },
    {
      name: 'land_lot_number',
      label: 'Lot number',
    },
    {
      name: 'land_subdivision',
      label: 'Subdivision',
    },
    {
      name: 'land_longitude',
      label: 'Longitude',
    },
    {
      name: 'land_latitude',
      label: 'Latitude',
    },

    '::Proposed Development Details',
    {
      name: 'b_c',
      label: 'B.C.',
      type: 'select:bool',
      required: false,
    },
    {
      name: 'w_c',
      label: 'W.C.',
      type: 'select:bool',
      required: false,
    },
    {
      name: 'proposed_development_details',
      label: 'How do you intend to use the land/building?',
      type: 'textarea',
      hint: 'If you intend to use it for more than one purpose, explain',
    },
    {
      name: 'current_land_use_desc',
      label: 'What is the land/building currently being used for?',
      type: 'textarea',
    },
    '::Materials For Exterior Finish of the Buildings',
    {
      name: 'walls_materials_list',
      label: 'List all materials needed for the walls.',
      columns: [' '],
    },
    {
      name: 'roofing_materials_list',
      label: 'List all materials needed for the roof.',
      columns: [' '],
    },
    {
      name: 'total_area',
      label: 'What is the total area?',
      type: 'integer',
      hint: 'In square feet',
    },
    {
      name: 'estimated_cost',
      label: 'What is the estimated cost',
      is: 'currency',
    },
    '::Architect Details',
    {
      name: 'architect_first_name',
      label: 'First Name',
    },
    {
      name: 'architect_last_name',
      label: 'Last Name',
    },
    {
      name: 'architect_street_address',
      label: 'Address',
    },
    {
      name: 'architect_island',
      label: 'Island',
      options: islands,
    },
    {
      name: 'architect_phone',
      label: 'Phone Number',
      type: 'phone',
    },
    '::Surveyor Details',
    {
      name: 'surveyor_first_name',
      label: 'First Name',
    },
    {
      name: 'surveyor_last_name',
      label: 'Last Name',
    },
    {
      name: 'surveyor_address',
      label: 'Address',
    },
    {
      name: 'surveyor_phone',
      label: 'Phone Number',
      type: 'phone',
    },
    {
      name: 'surveyor_island',
      label: 'Island',
      options: islands,
    },
    '::Required Documents',
    {
      name: 'location_plan_upload',
      label: 'Location plan',
      key: 'image.1',
      type: 'file:all',
    },
    {
      name: 'survey_upload',
      label: 'Survey',
      key: 'image.2',
      type: 'file:all',
    },
    {
      name: 'site_plans_upload',
      label: 'Site plan',
      key: 'image.3',
      type: 'file:all',
    },
    {
      name: 'architect_drawings_upload',
      label: 'Architect drawings',
      key: 'image.4',
      type: 'file:all',
    },
    {
      name: 'structural_drawings_upload',
      label: 'Structural drawings',
      key: 'image.5',
      type: 'file:all',
    },
    {
      name: 'mech_elec_drawings_upload',
      label: 'Mechanical & Electrical drawings',
      key: 'image.6',
      type: 'file:all',
    },
    {
      name: 'approval_letter_upload',
      label: 'Approval letter',
      key: 'image.7',
      type: 'file:all',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
