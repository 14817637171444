import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class ContractorInspection extends React.Component {
  resource = '/urban_renewal_contractor_inspections'
  form_key = 'urban_renewal_contractor_inspection'
  free     = true

  skip_location = true
  business_only = true
  hide_buttons  = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'inspection_date',
      type: 'date',
      maxDate: new Date(),
      view: {
        value: v => new Date(v).toISOString().slice(0, 10)
      }
    },
    {
      name: 'scope_of_work',
      label: 'Description of portion(s) of contract work requiring inspection',
      columns: ['item'],
      format: v => v.item,
    },

    '::Home Owner Details',
    {
      name: 'homeowner_first_name',
      label: 'First Name',
    },
    {
      name: 'homeowner_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'homeowner_last_name',
      label: 'Last Name',
    },
    {
      name: 'homeowner_address',
      label: 'Street Address',
    },
    {
      name: 'homeowner_po_box',
      label: 'P. O. Box',
      required: false
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
