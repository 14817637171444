import React from 'react'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
// imports logger from 'redux-logger'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'

import Main from '@router'
import ErrorBoundary from '@wrappers/error-boundary'
import reducer from '@reducers'
import Session from '@modules/session'

import 'bootstrap/dist/js/bootstrap.min.js'

const ONE_HOUR = 3600000

export const history = createBrowserHistory()
const historyMiddleware = routerMiddleware(history)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(
      historyMiddleware,
      thunk
      // logger
    )
  )
)

const App = () => (
  <Provider store={store}>
    <ErrorBoundary>
      <Main history={history} />
    </ErrorBoundary>
    <Session timeout={$app?.hasRole?.('pilot') ? ONE_HOUR : ONE_HOUR * 8} />
    <div id='__TOP__'></div>
  </Provider>
)

export default App
