import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'
import DataTable from 'react-data-table-component'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import Swal from 'sweetalert2'

import SelectField from '@shared/form-fields/select'
import InputField from '@shared/form-fields/input'
import OTPPreferences from '@modules/otp-preferences'

import { getAllCitizens, verifyDocument } from '@actions/users'
import { extractKeys } from '@helpers/objects'
import { fullName } from '@modules/form-wizard/utils'
import findSelected from '@helpers/find-selected'

const confirmUserAccount = async person => {
  const { isDismissed } = await Swal.fire({
    icon: 'question',
    title: 'Confirm User Email',
    html: `
      Are you sure you want to confirm the email address
      <strong>${person.email}</strong>, belonging to 
      <strong>${fullName(person, 'initial')}</strong>?
    `,
    confirmButtonText: 'Confirm Email',
    showCancelButton: true,
    showLoaderOnConfirm: true,

    preConfirm: async () => {
      try {
        await $app.axios.put(`/profiles/${person.id}/support/confirm`)
        return true
      } catch (err) {
        console.error(err)

        const msg = `
          There was an error processing this request.
          The account was most likely already verified
        `

        Swal.showValidationMessage(msg)
        return false
      }
    },
  })

  if (isDismissed) return

  Swal.fire(
    'Account Confirmed',
    'The account has been successully confirmed',
    'success'
  )
}

const Layout = ({ children }) => (
  <div className='content'>
    <div className='page-header' />
    <div className='page-inner'>{children}</div>
  </div>
)

const FindCitizen = ({ history, ...props }) => {
  const [active, setActive] = useState(null)
  const [results, setResults] = useState(null)
  const [modal, setModal] = useState(null)

  useEffect(() => {
    if (active) return

    Swal.fire({
      icon: 'question',
      input: 'number',
      title: 'Find User',
      inputLabel: `Please enter the user's NIB number`,
      inputAttributes: {
        name: 'nib',
        inputMode: 'numeric',
      },
      showCancelButton: true,
      confirmButtonText: 'Search',
      showLoaderOnConfirm: true,
      preConfirm: async NIB => {
        try {
          const { data } = await $app.axios.get('/profiles/show_all', {
            params: {
              page: 1,
              per_page: 9999,
              NIB,
            },
          })

          switch (data.all_profiles.length) {
            case 0:
              return null
            case 1:
              return data.all_profiles.pop()
            default:
              return data.all_profiles
          }
        } catch (err) {
          console.error(err)

          const msg = 'An error occurred while searching for that NIB'
          Swal.showValidationMessage(msg)
          return false
        }
      },
    }).then(res => {
      if (res.isDismissed) {
        history.goBack()
        return
      }

      if (!res.value) {
        Swal.fire(
          'No User Found',
          'No user was found with NIB number entered',
          'error'
        ).then(() => history.goBack())
      } else if (res.value.user_id) {
        setActive(res.value)
      } else {
        setResults(res.value)
      }
    })
  }, [active?.user_id, results?.length])

  if (active?.user_id) {
    return (
      <Layout>
        <div className='card'>
          <header className='card-header flex justify-between items-center'>
            <h3 className='m-0'>{fullName(active, 'initial')}</h3>
            <button
              className='btn flex items-center'
              onClick={() => setActive(null)}
            >
              <i className='fas fa-chevron-left mr-2' />
              Back
            </button>
          </header>
          <article className='card-body'>
            <div className='flex flex-col md:flex-row md:justify-between'>
              <div className='w-full lg:w-1/2 2xl:w-1/3'>
                <h5 className='mb-4'>Documents</h5>
                <VerificationStatuses {...active} />
              </div>
              <div className='flex flex-col w-full 2xl:w-80 space-y-2'>
                <h5 className='pb-2'>Actions</h5>
                <a href='#' onClick={() => setModal('verify')} data-action>
                  Verify A Document
                </a>
                <OTPPreferences id={active.user_id}>
                  <a href='#' data-action>
                    Change OTP Preferences
                  </a>
                </OTPPreferences>
                <a
                  href='#'
                  onClick={() => confirmUserAccount(active)}
                  data-action
                >
                  Confirm Email
                </a>
              </div>
            </div>
          </article>
          <Modal
            open={modal == 'verify'}
            classNames={{ modal: 'w-128' }}
            center
          >
            <VerifyDocForm
              key={modal}
              citizen={active}
              close={() => setModal(null)}
              onSubmit={props.verifyDocument}
            />
          </Modal>
        </div>
      </Layout>
    )
  }

  if (!active && !results?.length) return null

  const columns = [
    {
      name: 'Name',
      selector: 'first_name',
      format: r => `${r.first_name} ${r.last_name}`.capitalize(),
    },
    {
      name: 'D.O.B',
      selector: 'date_of_birth',
      format: r => moment(r).format('YYYY-MM-DD'),
    },
    {
      name: 'Actions',
      selector: 'id',
      cell: record => {
        return (
          <React.Fragment>
            <a
              href='#'
              onClick={() => {
                setActive(record)
                setModal('view')
              }}
            >
              View
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href='#' onClick={() => setActive(record)}>
              Profile
            </a>
          </React.Fragment>
        )
      },
    },
  ]

  return (
    <Layout>
      <div className='card'>
        <header className='card-header flex justify-between items-center'>
          <h3 className='m-0'>{fullName(active, 'initial')}</h3>
          <button
            className='btn flex items-center'
            onClick={() => setResults(null)}
          >
            <i className='fas fa-chevron-left mr-2' />
            Search
          </button>
        </header>
        <div className='card-body'>
          <div className='table-responsive form-records-table'>
            <DataTable columns={columns} data={results} striped pagination />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const VerificationStatuses = ({ verifications }) => {
  if (!verifications?.length) return null
  const reduced = verifications.reduce((obj, v) => ({ ...obj, ...v }), {})

  const list = [
    ['NIB', 'nib'],
    ["Driver's Licence", 'drivers license'],
    ['Passport', 'passport'],
    ["Voter's Card", 'voters card'],
  ].map(([label, id]) => {
    const { system_verified, document_verified } = reduced[id] ?? {}

    return (
      <Fragment key={id}>
        <dt className='text-left w-1/2'>{label}</dt>
        <dd className='xl:text-right xl:w-1/2 font-normal'>
          {(document_verified && 'verified') ||
            (system_verified && 'Pending Document Verification') ||
            'Not verified'}
        </dd>
      </Fragment>
    )
  })

  return <dl className='flex flex-wrap'>{list}</dl>
}

const VerifyDocForm = ({ citizen, onSubmit, close }) => {
  const [doctype, setDoctype] = useState('')
  const [acceptance, setAcceptance] = useState(false)
  const [docNumber, setDocNumber] = useState('')

  const doctypes = [
    { label: 'National Insurance Number', value: 'nib' },
    { label: 'Passport Number', value: 'passport' },
    { label: "Driver's Licence", value: 'drivers license' },
    { label: "Voter's Card", value: 'voters card' },
  ]

  const submit = ev => {
    onSubmit(
      citizen,
      {
        verification_type: doctype,
        verification_number: docNumber,
        acceptance,
      },
      close
    )
  }

  const onDoctype = val => {
    const v = val?.[0]?.value

    setDoctype(v)
    setAcceptance(false)

    switch (v) {
      case 'nib':
        setDocNumber(citizen.NIB ?? '')
        break
      case 'passport':
        setDocNumber(citizen.passport_number ?? '')
        break
      case 'drivers license':
        setDocNumber(citizen.drivers_license_number ?? '')
        break
      case 'voters card':
        setDocNumber(citizen.voters_card_number ?? '')
        break
      default:
    }
  }

  return (
    <Fragment>
      <div className='modal-header'>
        <h5 className='modal-title' id='verify-doc-modal-title'>
          Verify Document
        </h5>
      </div>
      <ValidatorForm onSubmit={submit}>
        <div className='modal-body'>
          <div className='form-group form-show-validation'>
            <label htmlFor='name'>
              Document Type<span className='required-label'>*</span>
            </label>
            <SelectField
              name='verification_type'
              value={doctype}
              values={findSelected(doctypes, doctype)}
              options={doctypes}
              onChange={onDoctype}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
            />
          </div>
          <div className='form-group form-show-validation'>
            <label htmlFor='name'>
              Document Number<span className='required-label'>*</span>
            </label>
            <InputField
              key={doctype}
              name='verification_number'
              icon={null}
              value={docNumber}
              onChange={ev => setDocNumber(ev.target.value)}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
              disabled={doctype == 'nib'}
            />
          </div>
          <div className='form-group form-show-validation row'>
            <div className='col-sm-12 mb-2 text-justify'>
              I confirm that I am authorized to access this portal for the
              purpose of facilitating the request of the citizen and/or matters
              related thereto. I understand that I am obligated to adhere to the
              provisions of the Data Protection Act and understand that I may be
              liable if I choose to do otherwise.
            </div>
            <div className='col-sm-12 acceptance-text'>
              <input
                type='checkbox'
                checked={acceptance}
                onChange={ev => setAcceptance(ev.target.checked)}
                required
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label htmlFor='acceptance'>
                I agree to the above statement.
                <span className='required-label'>*</span>
              </label>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            className='btn btn-round mr-2'
            aria-label='Close'
            onClick={close}
          >
            Cancel
          </button>
          <input
            className='btn custom-btn btn-round'
            type='submit'
            value='Confirm'
          />
        </div>
      </ValidatorForm>
    </Fragment>
  )
}

export default connect(
  state => extractKeys(state.users, 'citizens', 'loading'),
  { getAllCitizens, verifyDocument }
)(FindCitizen)
