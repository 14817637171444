import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import passportPhotoExample from '@helpers/passport-photo-example'

export default class YouthSenateForm extends React.Component {
  resource = '/youth_senates'
  form_key = 'youth_senate'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    "::Youth Applicant Information",
    {
      name: 'applicant_first_name',
      label: 'First name',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_middle_name',
      label: 'Middle name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'applicant_last_name',
      label: 'Last name',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_date_of_birth',
      label: 'Date of birth',
      type: 'date',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_age',
      label: 'Age',
      type: 'integer',
      hide: form.i_am_applicant,
      callback: () => {
        if (form.applicant_date_of_birth) {
          const today = new Date();
          const dob = new Date(form.applicant_date_of_birth)
          const monthCheck = today.getMonth() >= dob.getMonth()
          const dayCheck = today.getDate() >= dob.getDate()
          const minusYear = monthCheck && dayCheck ? 0 : 1
          return { applicant_age: (today.getFullYear() - dob.getFullYear()) - minusYear }
        }
      },
    },
    {
      name: 'applicant_gender',
      label: 'Gender',
      options: ['male','female'],
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_island',
      label: 'Island you currently reside',
      options: islands,
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_nationality',
      label: 'Nationality',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_national_insurance_number',
      label: 'NIB Number',
      type: 'integer',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_address',
      label: 'Home Address',
    },
    {
      name: 'applicant_subdivision',
      label: 'Subdivision',
    },
    {
      name: 'applicant_home_phone_number',
      label: 'Home phone #',
      type: 'phone',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_work_phone_number',
      label: 'Work phone #',
      type: 'phone',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'applicant_cell_phone_number',
      label: 'Cell phone #',
      type: 'phone',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'applicant_email_address',
      label: 'Email address',
      type: 'email',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'employed',
      label: 'Are you currently employed?',
      type: 'select:bool'
    },
    {
      name: 'place_of_employment',
      label: 'Where are you employed?',
      hide: !form.currently_employed
    },
    {
      name: 'employed_position',
      label: 'What is your position at work?',
      hide: !form.currently_employed
    },
    {
      name: 'covid_vaccine_status',
      label: 'COVID-19 Vaccination Status',
      options: ['Fully vaccinated', 'Partially vaccinated (one shot)','Not vaccinated']
    },
    {
      name: 't_shirt_size',
      options:[
          'small',
          'medium',
          'large',
          'XL',
          'XXL',
          'XXXL',
          'other'
      ]
    },
    {
      name: 'other_shirt_size',
      label: 'Please state your shirt size',
      hide: form.t_shirt_size !='other'
    },
    {
      name: 'youth_activities_list',
      label: 'List all youth activities/organizations in which you are involved',
      columns: ['activity'],
      format: v => v.activity,
      hint: 'Indicate leadership positions'
    },
    {
      name: 'participation',
      label: 'Have you participated in a similar event in the past?',
      type: 'select:bool',
      hint: 'For example: speech competitions, debate competitions, etc.'
    },
    {
      name: 'participation_details',
      label: 'When and where?',
      type: 'textarea',
      hide: !form.participation
    },
    "::Applicant Medical & Emergency Information",
    {
        name: 'emergency_contact_list',
        fields: [
            {
                name: 'first_name',
                label: 'First name of parent/guardian'
            },
            {
                name: 'last_name',
                label: 'Last name of parent/guardian'
            },
            {
                name: 'phone_contact',
                label: 'Parent/Guardian phone contact',
                type: 'phone'
            }
        ]
    },
    {
        name: 'food_allergies',
        label: 'Do you have any food allergies?',
        type: 'select:bool'
    },
    {
        name: 'allergies_details',
        label: 'Please list them',
        type: 'textarea',
        hide: !form.food_allergies
    },
    {
        name: 'medical_conditions',
        label: 'Do you have any medical conditions?',
        type: 'select:bool'
    },
    {
        name: 'medical_details_list',
        label: 'Please list them',
        columns: ['condition'],
        format: v => v.condition,
        hide: !form.medical_conditions
    },
    "::Applicant Academic information",
    {
        name: 'student',
        label: 'Are you currently a student?',
        type: 'select:bool'
    },
    {
        name: 'name_of_college',
        label: 'What is the name of the college you attend?',
        hide: !form.student_status
    },
    {
        name: 'graduate',
        label: 'Are you a graduate?',
        type: 'select:bool'
    },
    {
        name: 'graduate_degree',
        label: 'State your degree',
        hide: !form.graduate_status
    },
    {
        name: 'academic_qualifications_list',
        label: 'List any academic certifications and qualifications you may have',
        columns: ['qualification'],
        format: v => v.qualification,
    },
    {
        name: 'interesting_facts_list',
        label: 'Share five (5) interesting facts about yourself',
        columns: ['fact'],
        format: v => v.fact,
    },
    
    "::Uploads",
    {
        name: 'photo_upload',
        label: 'Professional Photo',
        type: 'file:image',
        key: 'image.0'
    },
    {
        name: 'government_id_upload',
        label: 'Government ID',
        type: 'file:image',
        key: 'image.1',
        hint: passportPhotoExample
    },
    {
        name: 'portfolio_upload',
        label: 'Portfolio (.zip file)',
        type: 'file',
        key: 'image.1',
        allow: 'application/zip',
        hint: 'Must contain 1-2 page biography',
        maxSize: 10
    },
    // {
    //     name: 'first_character_reference_upload',
    //     type: 'file:all',
    //     key: 'image.3'
    // },
    // {
    //     name: 'second_character_reference_upload',
    //     type: 'file:all',
    //     key: 'image.4'
    // },
    // {
    //     name: 'essay_upload',
    //     type: 'file:all',
    //     hint: 'Must be 1-2 pages long',
    //     key: 'image.5'
    // },
    // {
    //     name: 'nib_upload',
    //     label: 'NIB Card',
    //     type: 'file:all',
    //     key: 'image.6'
    // },
    // {
    //     name: 'passport_upload',
    //     label: 'Passport',
    //     type: 'file"all',
    //     key: 'image.7',
    //     hint: passportPhotoExample
    // },

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
