import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class CarMaintenance extends React.Component {
  resource = '/car_maintenances'
  form_key = 'car_maintenance'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'application_purpose',
        label: 'Purpose of Application',
        options: ['Issue', 'Variation']
    },
    "::Maintenance Organization Details",
    {
        name: 'org_name',
        label: 'Name of Maintenance Organisation'
    },
    {
        name: 'trading_name',
        required: false
    },
    {
        name: 'mailing_address'
    },
    {
        name: 'site_adress',
        label: 'Address of any additional sites to be approved'
    },
    {
        name: 'fax_number'
    },
    {
        name: 'naa_approve',
        label: 'Current NAA Approvals issued by:',
        rowLabel: 'Approval',
        fields: [{name: 'naa', label: 'NAA'}, 'Approval Certificate Number']
    },
    "::Accountable Manager Details",
    {
        name: 'fname',
        label: 'First Name'
    },
    {
        name: 'mname',
        label: 'Middle Name',
        required: false
    },
    {
        name: 'lname',
        label: 'Last Name'
    },
    {
        name: 'email',
        type: 'email'
    },
    "::Quality Manager Details",
    {
        name: 'fname_second',
        label: 'First Name'
    },
    {
        name: 'mname_second',
        label: 'Middle Name',
        required: false
    },
    {
        name: 'lname_second',
        label: 'Last Name'
    },
    {
        name: 'email',
        type: 'email'
    },
    {
        name: 'positions',
        label: ' Names And Positions Of Key Management And Senior Staff Appropriate To The Approval Ref Car 145.105, Appendix To Car 145.25 And Associated Amcs',
        rowLabel: 'Staff',
        fields: [
            {
                name: 'fname_third',
                label: 'First Name'
            },
            {
                name: 'mname_third',
                label: 'Middle Name',
                required: false
            },
            {
                name: 'lname_third',
                label: 'Last Name'
            },
            {
                name: 'position'
            }
        ]
    },
    {
        name: 'scope',
        label: 'Scope of work applied for',
        type: 'textarea'
    },
    "::Supporting Documents",
    {
        name: 'checklist_one_upload',
        label: 'Checklist for the Submission of an Initial CAR 145 Approval Based on an Existing NAA Approval',
        key: 'image.0',
        type: 'file:pdf'
    },
    {
        name: 'checklist_two_upload',
        label: 'Checklist for the Submission of an Initial Stand-Alone CAR 145 Approval',
        key: 'image.1',
        type: 'file:pdf'
    },
    {
        name: 'checklist_three_upload',
        label: 'Checklist for the Submission of a Variation to a CAR 145 Approval Based on an Existing NAA Approval',
        key: 'image.2',
        type: 'file:pdf'
    },
    {
        name: 'checklist_four_upload',
        label: 'Checklist for the Submission of a Variation to a Stand-Alone CAR 145 Approval',
        key: 'image.3',
        type: 'file:pdf'
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
