import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class DateTimeField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () =>
    this.state.isValid ? null : (
      <div style={this.errorStyles}>{this.getErrorMessage()}</div>
    )

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      withRequiredValidator,
      icon,
      ...rest
    } = this.props

    if (!rest.className) rest.className = 'form-control'
    if (!this.state.isValid) rest.className += ' input_error'

    return (
      <React.Fragment>
        <div className='input-group'>
          <DatePicker {...rest} />
          {icon === null ? null : (
            <div className='input-group-append'>
              <span className='input-group-text'>
                <i className='fas fa-calendar'></i>
              </span>
            </div>
          )}
        </div>
        {this.errorText()}
      </React.Fragment>
    )
  }
}

export default DateTimeField
