// prettier-ignore-start
export const join = (arr1, arr2) => [...arr1, ...arr2]
export const stringifyValues = arr => arr.map(item => JSON.stringify(item))

export const isSubset = (superset, subset) =>
  subset.every(item => superset.includes(item))

export const intersection = (l1, l2) => {
  const found = []
  const [big, small] = [l1, l2].sort((a, b) => a.length > b.length)

  for (let item of big) {
    small.includes(item) && found.push(item)
  }

  return found
}

export const hasAny = (superset, targets) =>
  Array.isArray(superset) && targets?.some?.(item => superset.includes(item))

export const splitEvery = (arr, size) => {
  if (!size || size >= arr.length) return [arr]

  const list = arr.slice()
  const final = []

  while (list.length) {
    final.push(list.splice(0, size))
  }

  return final
}

export const splitInto = (arr, numGroups) => {
  const size = Math.ceil(arr.length / Math.floor(numGroups))
  return splitEvery(arr, size)
}

export const groupBy = (arr, key) =>
  arr.reduce((obj, item) => {
    const prop = item[key] || '__ungrouped__'
    if (!obj.hasOwnProperty(prop)) obj[prop] = []

    obj[prop].push(item)
    return obj
  }, {})

// prettier-ignore-end
