const initialCaps = str =>
  str
    ?.trim()
    .split(/[ _-]/)
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')

const normalizeField = f => {
  if (typeof f == 'string') {
    if (f == '::SPACER') {
      return { heading: '<span>&nbsp;</span>' }
    } else if (f.startsWith('::')) {
      return { heading: f.slice(2) }
    }

    return { name: f, label: initialCaps(f) }
  }

  if (f.heading == 'SPACER') {
    f.heading = '<span>&nbsp;</span>'
  }

  return {
    ...f,
    label: f.label || initialCaps(f.name),
  }
}

export default normalizeField
