import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelMusicVocal extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ":: Entrant Info",
    {
        name: 'gender',
        options: ['Male', 'Female', 'Other'],
    },
    {
      name: 'entree_name_first',
      label: 'First Name',
    },
    {
      name: 'entree_name_last',
      label: 'Last Name',
    },
    {
      name:'email',
      type: 'email',
    },
    "::Additional Info",
    {
    name: 'class_number',
    hint: 'This can be found in the national arts festival booklet'
    },
    {
      name: 'Instrument',
    },
    {
      name: 'island_deadline_date',
      label: 'Island Deadline Date',
      options: [
          'Abaco February 15th',
          'Acklins February 15th',
          'Andros April 1st',
          'Bimini May 1st',
          'Berry Islands May 1st',
          'Cat Island Feb 15th',
          'Crooked Island Feb 15th',
          'Eleuthera May 1st',
          'Exuma Feb 15th',
          'Grand Bahama April 1st',
          'Inagua Feb 15th',
          'New Providence March 1st',
          'Mayaguana Feb 15th',
          'San Salvador Feb 15th',
          'Rum Cay Feb 15th',
          'Ragged Island Feb 15th',
          'Long Island May 1st',
          'Other'
  ],
  
  },
    {
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.island_deadline_date !='Other',
    },
    {
      name: 'piece_name',
      label: 'Name of Piece(s)',
    },
    {
      name: 'school_or_ensemble',
      label:'Name of school or Ensemble',
    },
    {
      name: 'number_of_male_and_female',
      label: 'If the entry is an Ensemble please provide the number of females and males',
      type: 'textarea',
    },
    {
      name:'phone_number',
      type: 'phone',
    },
    {
      name: 'level',
      options: [
          'Primary PR ($5 Single)',
          'Primary PR ($10 Group)',
          'Jr. High JH ($5 Single)',
          'Jr. JH ($10 Group/Ensemble)',
          'Sr. High SH ($5 Single)',
          'Sr. High SH ($10 Group/Ensemble)',
          'Community COM ($10 Single)',
          'Community COM ($20 Group Ensemble)',
          'Pre-School PS ($5 Single $10 Group/Ensemble)',
          'Other'
      ],
  },
  {
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.level !='Other',
  },
  {
    name: 'name_of_guardian',
    label: 'Name of Director/Teacher/Parent',
}

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
