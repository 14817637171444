const ENV = process.env || {}
const { origin } = window.location

const columns = (...headings) => headings.map(title => ({ title }))
const options = (...values) => values.map(value => ({ value, label: value }))

const env = {
  PRODUCTION: origin == 'https://services.mygateway.gov.bs',
  UB_DOMAIN: 'cash',
  API_V: 'api/v1',

  PARENT_SITE_URL: 'https://mygateway.gov.bs',
  PARENT_SITE_NAME: 'My Gateway',

  PILOT_URL: ENV.REACT_APP_PILOT_URL || window.location.origin,
  WEB_SERVICE_URL: ENV.REACT_APP_API_HOST,
  OTP_SERVICE_URL: ENV.REACT_APP_OTP_HOST,
  VIN_SERVICE_URL: ENV.REACT_APP_VIN_HOST,
  TARIFF_CODES_URL:
    ENV.REACT_APP_TARIFFS_HOST ||
    'https://api.importtaxes.revenue.gov.bs/api/v1/hs_codes',
  CASHLESS_URL:
    // 'https://api.cashless-qa.revenue.gov.bs/api/v1',
    ENV.REACT_APP_CASHLESS_URL || 'https://api.cashless-qa.revenue.gov.bsapi/v1',
  BLOCKCERTS_SERVER: ENV.REACT_APP_BLOCKCERTS_SERVER,
  SIDENAV_MODE: ENV.REACT_APP_NAV_ENV || 'pilot-prod',

  PASSPORTS: columns('Passport Number', 'Status', 'Submission Date', 'Action'),

  MARITAL_STATUSES: options('Single', 'Married', 'Divorced', 'Widowed'),
  RELIGIONS: options(
    'None',
    'Christian',
    'Rastafarian',
    "Jehovah's Witness",
    'Black Hebrew Israelite',
    'Jewish',
    'Islamic',
    'Hindu',
    'Buddhist',
    'Agnostic',
    'Atheist',
    'Other'
  ),

  HELPDESK: {
    TEXT: {
      EMAIL: 'mygateway@bahamas.gov.bs',
      PHONE: '(242) 300-5717',
    },
    LINK: {
      EMAIL: 'mailto:mygateway@bahamas.gov.bs',
      PHONE: 'tel:2423005717',
    },
  },
}

env.HELPDESK.HTML = {
  EMAIL: `<a href='${env.HELPDESK.LINK.EMAIL}'>${env.HELPDESK.TEXT.EMAIL}</a>`,
  PHONE: `<a href='${env.HELPDESK.LINK.PHONE}'>${env.HELPDESK.TEXT.PHONE}</a>`,
}

env.TRANSACTION_REQUEST = `
  The GoBh will facilitate an on-line payment for your convenience.
  You understand that by proceeding you have authorized the GoBh to process this on-line payment.
  You are responsible for all fees associated with this transaction.
  You represent that: a: you are 18 years or older, b: that you are using your actual identity,
  c: information you provide is accurate and complete, d you are legally authorized to make payments
  and your use of this Service will not violate any existing agreements with your financial institution.
  GoBh will not be responsible for any failure to process payment and shall not be responsible or held
  liable for any of your losses if GoBh is unable to complete the payment.
  <br /><br />
  BY CLICKING FORWARD TO MAKE A PAYMENT, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS STATEMENT.
`

env.SERVICE_REQUEST = `
  This form and the information provided will be used to apply for the prescribed government service. We recommend that you visit&nbsp;
  <a href=${env.PARENT_SITE_URL}>${env.PARENT_SITE_NAME}</a> before making this application to understand
  how to make the application, and when your documents will be ready for pick up. The Government of The Bahamas,
  through its agencies, is committed to processing all requests in a timely manner. Errors or omissions in the information
  provided may lead to a delay in process. Make sure you review the information that you will provide and ensure that it is
  accurate and complete. We reserve the right to request additional information.
`

export default env
