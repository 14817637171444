import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

export default class DeathRegistrationForm extends React.Component {
  resource = '/death_registrations'
  form_key = 'death'

  // The fee for this form
  fee = 10

  // The fields that will make up the form
  fields = () => [
    'first_name',
    {
      name: 'middle_name',
      required: false,
    },
    'last_name',
    'died_at',
    {
      type    : 'select',
      name    : 'island',
      options : islands
    },
    {
      type    : 'date',
      name    : 'date_of_death',
      maxDate : new Date(),
    },
    'father_first_name',
    {
      name: 'father_middle_name',
      required: false,
    },
    'father_last_name',
    'mother_first_name',
    {
      name: 'mother_middle_name',
      required: false,
    },
    'mother_last_name',
    {
      name: 'mother_maiden_name',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}