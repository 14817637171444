import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'

export default class DriverPermit extends React.Component {
  id = 'DrivingPermit'
  service = 'driving permit'
  title = "New Driver's Permit"
  agency = 'rtd'
  item = '396'
  admin = 'road_traffic'
  resource = '/driving_permits'
  form_key = 'driving_permit'

  fee = 15

  foremost = {
    name: 'has_wpr_case',
    label:
      'Have you registered on the RTD website or onsite at any other Road Traffic Locations?',
    type: 'select:bool',
    long: true,
    test: v =>
      v || {
        heading: 'Please Register With RTD First',
        message:
          "Please register <a href='https://tms.roadtraffic.gov.bs/RegisterUser'>here</a> and return to this application.",
      },
  }

  fields = () => [
    {
      name: 'wpr_case_number',
      label: 'WPR Case Number',
      long: true,
    },
    {
      name: 'passport_number',
      long: true,
    },
    {
      key: 'image',
      name: 'document',
      type: 'file',
      hint: passportPhotoExample,
      label: 'Passport Photo',
      accept: 'image/*',
      long: true,
    },
    {
      name: 'type_of_license',
      label: 'Vehicle Class',
      type: 'select',
      options: [
        { label: 'Class A (5,000 lbs. or less)', value: 'Class A' },
        { label: 'Class B (5001 lbs. - 15,000 lbs.)', value: 'Class B' },
        { label: 'Class C (15,000 lbs. or greater)', value: 'Class C' },
      ],
      long: true,
    },
    {
      name: 'passed_test',
      show: false,
      type: 'file',
      long: true,
      accept: '.pdf',
      hint: 'A PDF of your written exam results',
    },
    {
      name: 'disqualified',
      type: 'select:bool',
      label:
        'Are you disqualified by any court for holding or obtaining a licence?',
      long: true,
      view: {
        label: 'Disqualified Licence?',
      },
    },
    {
      name: 'epilepsy',
      type: 'select:bool',
      label:
        'Do you suffer from epilepsy, or from sudden attacks of disabling giddiness or fainting?',
      long: true,
      view: {
        label: 'Epilepsy?',
      },
    },
    {
      name: 'distance',
      type: 'select:bool',
      label:
        'Can you read at a distance of 25 yards in good daylight (with glasses if worn) a car number plate containing six letters and figures?',
      long: true,
      view: {
        label: 'Can read at 25 yards?',
      },
    },
    {
      name: 'movement',
      type: 'select:bool',
      label:
        'Are you without either hand or foot or are you suffering from any defect in movement, control, or muscular power, of either arm or leg?',
      long: true,
      view: {
        label: 'Disabled?',
      },
    },
    {
      name: 'disease',
      type: 'select:bool',
      label:
        'Are you suffering from any other disease, mental or physical, or disability which would be likely to cause the driving by you of a motor vehicle to be a source of danger to the public?',
      long: true,
      view: {
        label: 'Other Illnesses?',
      },
    },
    {
      name: 'offense',
      type: 'select:bool',
      label:
        'Do you understand that it is an offense to use a motor vehicle on a road unless covered by Insurance against third party risks?',
      long: true,
      view: {
        label: 'Understands that no insurance is an offense?',
      },
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
