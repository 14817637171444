import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class AnalysisProducts extends React.Component {
  resource = "/analysis_products";
  form_key = "analysis_product";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "container_condition_good",
      label: "Physical Condition of Container",
      options: ["Acceptable", "Unacceptable"],
    },
    {
      name: "sample_loss",
      label: "Loss of Sample(improper sealing)",
      type: "select:bool",
    },
    {
      name: "sample_contaminated",
      label: "Contamination of Sample(inadequate separation)",
      type: "select:bool",
    },
    {
      name: "sample_hold",
      label: "Hold Sample(insufficient sample)",
      type: "select:bool",
    },
    {
      name: "sample_accepted",
      label: "Sample Accepted",
      type: "select:bool",
    },
    "::Client Information",
    {
      name: "customer_req_numb",
      label: "Customer Request Form Ref #",
      required: false,
      hint: "If applicable",
    },
    {
      name: "sample_log_numb",
      label: "Sample Log-in #",
    },
    {
      name: "fax",
      type: "phone",
    },
    "::Product",
    {
      name: "product_type",
      label: "Type",
    },
    "packaging",
    {
      name: "sample_amount_received",
      label: "# of Samples Received",
    },
    {
      name: "product_desc",
      label: "Description of Sample/s and Sample I.D (Client)",
      columns: [{ name: "sample", label: "Sample" }],
    },
    "::Sample Type",
    {
      name: "regulatory",
      label: "Regulatory Sample",
      type: "checkbox",
      required: false,
    },
    {
      name: "independent",
      label: "Independent Sample",
      type: "checkbox",
      required: false,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
