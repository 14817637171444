import Input from './input'
import Select from './select'
import NativeSelect from './select.native'
import Date from './date'
import DateTime from './datetime'
import Phone from './phone'
import Textarea from './textarea'
import File from './file'
import Image from './image'
import Checkbox from './checkbox'

const Fields = {
  Input,
  Select,
  Checkbox,
  NativeSelect,
  Date,
  DateTime,
  Phone,
  Textarea,
  File,
  Image,
}

export default Fields
