import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Loader from '@shared/loader'
import MCSidePanel from './sidebar'
import { getAllNotes, getSearchNotes } from '@actions/notes'

class Inbox extends React.Component {
  state = {
    page: 1,
  }

  searchbar = React.createRef()

  componentDidMount() {
    // this.props.getAllNotes()
  }

  belongsName = name => {
    switch (name) {
      case 'DriverLicenseReplacement':
      case 'DriversLearnersPermitRenewal':
      case 'DriversLicense':
      case 'DrivingPermit':
      case 'DrivingTestApplicationPractical':
      case 'FranchiseLicense':
      case 'IndividualLicense':
      case 'LicenceRenewal':
      case 'NewDriverLicense':
      case 'OmibusFranchiseApplication':
      case 'PublicServiceDriversLicence':
      case 'SelfDriveFranchiseApplication':
      case 'TaxiCabGrantApplication':
      case 'TourCarFranchiseApplication':
        return 'Road Traffic'
      case 'BirthCertificate':
      case 'BirthRegistration':
      case 'DeathCertificate':
      case 'DeathRegistration':
      case 'DeedsAndDocument':
      case 'MarriageCertificate':
        return 'Registrar General'
      case 'RentBox':
      case 'MoneyOrder':
        return 'Post Office'
      case 'Passport':
        return 'Passport Office'
      case 'PoliceCertificate':
        return 'Royal Bahamas Police Force'
      case 'DisablityForm':
        return 'Social Services'
      default:
        return null
    }
  }

  on = {
    search: ev => {
      ev.preventDefault()
      this.props.getSearchNotes(this.searchbar.current.value, 1)
    },

    page: type => () => {
      const page = this.state.page + (type == 'prev' ? -1 : 1)
      this.props.getSearchNotes(this.searchbar.current.value, page)
      this.setState({ page })
    },
  }

  lastPage = () => {
    const { total_count } = this.props
    const result = Math.ceil(total_count / 10)
    return result == total_count ? 1 : result
  }

  viewMessage = note => () =>
    this.props.history.push({
      pathname: `${this.props.match.url}/${note.id}`,
      state: { note },
    })

  PaginationButtons = () => {
    const { on, lastPage } = this
    const { page } = this.state

    const back =
      page == 1 ? null : (
        <button
          type='button'
          className='btn btn-border-secondary'
          onClick={on.page('prev')}
        >
          <i className='fa fa-angle-left'></i>
        </button>
      )

    const next =
      page == lastPage() ? null : (
        <button
          type='button'
          className='btn btn-border-secondary'
          onClick={on.page('next')}
        >
          <i className='fa fa-angle-right'></i>
        </button>
      )

    return (
      <React.Fragment>
        {back}
        {next}
      </React.Fragment>
    )
  }

  render() {
    const {
      state,
      props,
      on,
      searchbar,
      lastPage,
      belongsName,
      viewMessage,
      PaginationButtons,
    } = this
    const { notes, total_count, loading } = props

    if (loading) return <Loader {...props} />

    const messages = notes.map((item, i) => (
      <div
        className={`email-list-item ${item == 0 ? 'read' : 'unread'}`}
        key={i}
      >
        <div className='email-list-actions'>
          <div className='d-flex'>
            <label className='custom-control custom-checkbox'>
              <input type='checkbox' className='custom-control-input' />
              <span className='custom-control-label'></span>
            </label>
          </div>
        </div>
        <a onClick={viewMessage(item)}>
          <div className='email-list-detail'>
            <span className='date float-right'>
              {moment(item.created_at).format('DD MMM')}
            </span>
            <span className='from'>
              {item.notable_type && item.notable_type.spaceBeforeCap()}
            </span>
            <p className='msg'>{belongsName(item.notable_type)}</p>
          </div>
        </a>
      </div>
    ))

    return (
      <div className='email-content email-content-main content content-full'>
        <div className='page-inner-fill'>
          <div className='page-with-aside mail-wrapper bg-white'>
            <MCSidePanel {...props} />
            <div className='page-content mail-content'>
              <div className='inbox-head d-lg-flex d-block'>
                <h3>Inbox</h3>
                <form onSubmit={on.search} className='ml-auto'>
                  <div className='input-group'>
                    <input
                      type='text'
                      ref={searchbar}
                      placeholder='Search Email'
                      className='form-control'
                    />
                    <div className='input-group-append'>
                      <button type='submit' className='input-group-text'>
                        <i className='fa fa-search search-icon'></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='inbox-body'>
                <div className='mail-option'>
                  {/* <div className="email-filters-left">
                    <div className="chk-all">
                      <div className="btn-group">
                        <div className="form-check">
                          <label className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" />
                            <span className="custom-control-label"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className='email-filters-right ml-auto'>
                    <span className='email-pagination-indicator'>
                      {state.page} - {lastPage()} of {total_count}
                    </span>
                    <div className='btn-group ml-3'>
                      <PaginationButtons />
                    </div>
                  </div>
                </div>

                <div className='email-list'>{messages}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ notes }) => ({
    notes: notes.notes,
    loading: notes.loading,
    total_count: notes.total_count,
  }),
  { getAllNotes, getSearchNotes }
)(Inbox)
