import React from 'react'
// import axios from 'axios'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class PublicServiceDriversLicenseTourCar extends React.Component {
  constructor (props) {
    super(props)
    this.user = this.getUser()
  }

  getUser = () => {
    const user = window.sessionStorage.getItem('user')
    return user && JSON.parse(user)
  }

  // id       = 'PublicServiceDriversLicence'
  // service  = 'public service drivers licence'
  // title    = 'Public Service Licence'
  // agency   = 'rtd'
  // item     = '396'
  // admin    = 'road_traffic'
  resource = '/public_service_drivers_licenses'
  form_key = 'public_service_drivers_license'
  skip_location = true

  // fee = 15

  defaults = {
    // client_type: 'individual',
    omnibus: false,
    taxicab: false,
    livery_car: false,
    tour_car: true,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        omnibus: false,
        taxicab: false,
        livery_car: false,
        tour_car: true,
      },
    },
  }

  validate = async (form) => {
    return true
  }

  fields = () => [
    {
      name: 'omnibus',
      save: true,
      hide: true,
    },
    {
      name: 'taxicab',
      save: true,
      hide: true,
    },
    {
      name: 'livery_car',
      save: true,
      hide: true,
    },
    {
      name: 'tour_car',
      save: true,
      hide: true,
    },
    // {
    //   name: 'years_in_bahamas',
    //   label: "IF NOT A BAHAMIIAN, HOW LONG HAVE YOU BEEN IN THE BAHAMAS",
    //   required: false,
    //   is: 'integer',
    // },
    '::Personal Details',
    {
      name: 'present_occupation',
      label: "Present Occupation",
      required: false,
      is: 'text',
    },
    {
      name: 'present_employer',
      label: "Present Employer",
      required: false,
      is: 'text',
    },
    {
      name: 'present_work_address',
      label: "Present Work Address",
      required: false,
      is: 'text',
    },
    {
      name: 'present_work_phone',
      label: "Present Work Phone #",
      required: false,
      is: 'phone',
    },

    {
      name: 'previous_occupation',
      label: "Previous Occupation",
      required: false,
      is: 'text',
    },
    {
      name: 'previous_employer',
      label: "Previous Employer",
      required: false,
      is: 'text',
    },
    {
      name: 'previous_work_address',
      label: "Previous Work Address",
      required: false,
      is: 'text',
    },
    {
      name: 'previous_work_phone',
      label: "Previous Work Phone #",
      required: false,
      is: 'phone',
    },

    '::Licence Details',
    {
      name: 'license_number',
      label: "Driver's Licence #",
      use_profile: 'drivers_license_number',
    },
    {
      type: 'date',
      name: 'expirary_date',
      label: 'Expiration Date',
      required: false,
      showYearDropdown: true,
    },

    '::Uploads',
    {
      name: 'photo_upload',
      label: 'Passport sized Photo',
      type: 'file:image',
      key: 'image.0',
      required: false,
    },
    {
      name: 'reference_upload',
      label: 'Character Reference',
      type: 'file:all',
      key: 'image.1',
      required: false,
    },
    {
      name: 'reference_2_upload',
      label: 'Character Reference',
      type: 'file:all',
      key: 'image.2',
      required: false,
    },
    {
      name: 'reference_3_upload',
      label: 'Character Reference',
      type: 'file:all',
      key: 'image.3',
      required: false,
    },
    {
      name: 'traffic_record_upload',
      label: 'Police Traffic Character Reference',
      type: 'file:image',
      key: 'image.4',
      required: false,
    },
    {
      name: 'police_record_upload',
      label: 'Valid Police Character Certificate',
      type: 'file:all',
      key: 'image.5',
      hint: 'Not more than six (6) months old',
      required: false,
    },
    {
      name: 'eye_exam_upload',
      label: 'Eye Examination Certificate',
      type: 'file:all',
      key: 'image.6',
      required: false,
    },
    {
      name: 'bahama_host_upload',
      label: 'Bahama Host Certificate',
      type: 'file:all',
      key: 'image.7',
      required: false,
    },

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
