import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class MoneyOrder extends React.Component {
  title    = 'Money Order'
  agency   = 'po'
  item     = '344'
  admin    = 'post_office'
  resource = '/money_orders'
  form_key = 'money_order'

  fee = ({ amount }) => (Number(amount) * 1.065).toFixed(2)

  fields = form => [
    'first_name',
    {
      name: 'middle_name',
      required: false,
    },
    'last_name',
    {
      name: 'amount',
      is: 'currency',
      validators: ['required', 'isFloat'],
      errorMessages: ['Required', 'Must be a number'],
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
