import React from 'react'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import { getUploadPath } from './utils'
import { FaPaperclip } from 'react-icons/fa'

export default function ApplicationNotes({ data, onClose }) {
  const content = data?.length ? (
    data.map(Node)
  ) : (
    <strong className='block text-center'>There are no notes.</strong>
  )

  return (
    <Modal
      open={!!data}
      onClose={onClose}
      center
      styles={{ modal: { width: '800px', maxWidth: '100%' } }}
    >
      <div className='modal-header'>
        <h5 className='modal-title'>Notes</h5>
      </div>
      <div className='modal-body overflow-y-auto' style={{ maxHeight: 480 }}>
        {content}
      </div>
      <div className='modal-footer'>
        <button className='btn btn-primary' onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  )
}

function Node({ id, image, text: __html, created_at, user }) {
  // prettier-ignore
  const head = /Application (approve|denied|submit|set|defer|recomend)/i.test(__html)
    ? 'Status Update'
    : 'General'

  return (
    <React.Fragment key={id}>
      <article className='flex flex-row items-center'>
        <div className='flex flex-initial flex-shrink-0 justify-center items-center rounded-full w-12 h-12 bg-primary'>
          <span className='text-white text-lg'>
            {user?.first_name?.charAt(0).toUpperCase() || 'A'}
          </span>
        </div>
        <div className='ml-4 pt-1 flex-grow-0 w-full'>
          <div className='flex justify-between mb-1'>
            <h6 className='text-uppercase font-bold'>
              {`${user?.first_name} ${user?.last_name}`}
              <span className='text-muted pl-5'>{head}</span>
            </h6>
            <span className='italic text-gray-600'>
              {moment(created_at).format('Do MMM @ h:mm A')}
            </span>
          </div>
          <div
            className='text-gray-700'
            dangerouslySetInnerHTML={{
              __html: __html.trim().replace(/\n+/g, '<br/>'),
            }}
          />
          {/^\/system\/notes/.test(image?.[0])
            ? (
              <a
                target='_blank'
                rel="noreferrer"
                href={getUploadPath(image[0], true)}
                className='inline-flex mt-2 space-x-2'
              >
                <FaPaperclip size={16} />
                <span>View Attachment</span>
              </a>
            ) : null}
        </div>
      </article>
      <div className='border-t border-l-0 border-r-0 border-b-0 border-gray-300 border-dashed my-4'></div>
    </React.Fragment>
  )
}
