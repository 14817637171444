import React, { useState, useEffect } from 'react'

import Loader from '@shared/loader'
import verifyDigitalCert from '@modules/certs/verify'

export default function DigiCert({ history, match }) {
  const [loading, setLoading] = useState(true)
  const { service, form_num } = match.params

  useEffect(() => {
    verifyDigitalCert(service, form_num, {
      onFetch: () => setLoading(false),
      onClose: () => history.push('/'),
    })
  }, [form_num, service]) // eslint-disable-line

  return loading ? (
    <Loader loading={true} message='Fetching Application...' />
  ) : null
}
