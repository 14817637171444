import React, { Fragment, useState } from 'react'
import Modal from 'react-responsive-modal'
import { ValidatorForm } from 'react-form-validator-core'

import toastr from '@modules/toastr'
import SelectField from '@shared/form-fields/select'
import InputField from '@shared/form-fields/input'
import findSelected from '@helpers/find-selected'
import { getPickupRequirements } from '../data'
import { fullName } from '@modules/form-wizard/utils'

export default function CitizenActions ({ open, onClose: close, item, proxy }) {
  const [verifying, setVerifying] = useState(false)
  const { collectable } = item ?? {}
  const user = (proxy && item.proxy) || item.user

  const onClose = () => {
    setVerifying(false)
    close()
  }

  const markAsCollected = async () => {
    if (!collectable) return
    const { service_type, id: service_id } = item
    const status = proxy ? 'collected by proxy' : 'collected'

    try {
      await $app.axios.patch('/deliveries/deliverable_status_update', {
        service_type,
        service_id,
        status,
      })

      toastr.success('Success', 'Item picked up')
      Object.assign(item, { delivery_status: status })
    } catch (err) {
      console.error(err)
      toastr.error('Error', `Unable to mark item as picked up`)
      return false
    }

    onClose()
  }

  const verifyUserIDs = async (citizen, verification) => {
    try {
      const { data } = await $app.axios.post('/verifications', {
        id: citizen.user_id,
        verify: {
          document_verified: true,
          ...verification,
        },
      })
  
      if (data) {
        toastr.success('Success', 'Successfully verified document')
      }
    } catch (err) {
      toastr.error('Error', `Failed to verify user's documents`)
    }

    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: 'w-full sm:w-128' }}
      center
    >
      <div className='modal-header'>
        <h5 className='modal-title'>{fullName(user)}</h5>
      </div>
      {verifying
        ? (
          <DocumentVerifier
            citizen={user}
            onSubmit={verifyUserIDs}
            toPickup={() => setVerifying(false)}
          />
        )
        : (
          <PickupConfirmation
            item={item}
            user={user}
            toVerifiy={() => setVerifying(true)}
            onSubmit={markAsCollected}
            onClose={close}
          />
        )
      }
    </Modal>
  )
}

const PickupConfirmation = ({ item, user, onSubmit, toVerifiy, onClose }) => {
  const { service_name, collectable } = item ?? {}

  let buttonText = ''

  if (collectable) {
    buttonText = `Confirm ${service_name} Pickup`
  } else if (item.delivery_status == 'collected') {
    buttonText = `Already Picked Up`
  } else {
    buttonText = `Awaiting delivery of ${service_name}`
  }

  return (
    <Fragment>
      <div className='modal-body'>
        <div className='row'>
          <div className='col-sm-6 mb-6'>
            <strong className='d-block'>NIB</strong>
            <span>{user?.NIB}</span>
          </div>
          <div className='col-sm-6 mb-6'>
            <strong className='d-block'>Passport</strong>
            <span>{user?.passport_number || 'N/A'}</span>
          </div>
          <div className='col-sm-6'>
            <strong className='d-block'>Driver's Licence</strong>
            <span>{user?.drivers_license_number || 'N/A'}</span>
          </div>
          <div className='col-sm-6'>
            <strong className='d-block'>Voter's Card</strong>
            <span>{user?.voters_card_number || 'N/A'}</span>
          </div>
        </div>
        <div className='my-10'>
          <h5 className='m-0'>Required For Pickup:</h5>
          <hr className='mt-2 mb-3 mx-0' />
          <strong className="text-muted">
            {getPickupRequirements(item?.service_type, item)}
          </strong>
        </div>
        <button
          className='block mx-auto btn custom-btn w-full md:w-3/4 mb-6'
          onClick={() => onSubmit()}
          disabled={!collectable}
        >
          {buttonText}
        </button>
      </div>
      <div className='modal-footer'>
        <span
          className='text-blue-800 cursor-pointer mr-auto'
          onClick={toVerifiy}
        >
          Verify Document(s)
        </span>
        <button className='btn btn-round' onClick={onClose}>
          Close
        </button>
      </div>
    </Fragment>
  )
}

const DocumentVerifier = ({ citizen, onSubmit, toPickup }) => {
  const [doctype, setDoctype] = useState('')
  const [acceptance, setAcceptance] = useState(false)
  const [docNumber, setDocNumber] = useState('')

  const doctypes = [
    { label: 'National Insurance Number', value: 'nib' },
    { label: 'Passport Number', value: 'passport' },
    { label: "Driver's Licence", value: 'drivers license' },
    { label: "Voter's Card", value: 'voters card' },
  ]

  const onDoctype = val => {
    const v = val?.[0]?.value

    setDoctype(v)
    setAcceptance(false)

    switch (v) {
      case 'nib':
        setDocNumber(citizen.NIB ?? '')
        break
      case 'passport':
        setDocNumber(citizen.passport_number ?? '')
        break
      case 'drivers license':
        setDocNumber(citizen.drivers_license_number ?? '')
        break
      case 'voters card':
        setDocNumber(citizen.voters_card_number ?? '')
        break
      default:
    }
  }

  return (
    <ValidatorForm onSubmit={() => onSubmit(doctype, docNumber)}>
      <div className='modal-body'>
        <h6 className='text-lg mx-2'>
          Verify Document
        </h6>
        <div className='form-group form-show-validation'>
          <label htmlFor='name'>
            Document Type<span className='required-label'>*</span>
          </label>
          <SelectField
            name='verification_type'
            value={doctype}
            values={findSelected(doctypes, doctype)}
            options={doctypes}
            onChange={onDoctype}
            className='form-control'
            validators={['required']}
            errorMessages={['Required']}
          />
        </div>
        <div className='form-group form-show-validation'>
          <label htmlFor='name'>
            Document Number<span className='required-label'>*</span>
          </label>
          <InputField
            key={doctype}
            name='verification_number'
            icon={null}
            value={docNumber}
            onChange={ev => setDocNumber(ev.target.value)}
            className='form-control'
            validators={['required']}
            errorMessages={['Required']}
            disabled={doctype == 'nib'}
          />
        </div>
        <div className='form-group form-show-validation row'>
          <div className='col-sm-12 mb-2 text-justify'>
            I confirm that I am authorized to access this portal for the
            purpose of facilitating the request of the citizen and/or matters
            related thereto. I understand that I am obligated to adhere to the
            provisions of the Data Protection Act and understand that I may be
            liable if I choose to do otherwise.
          </div>
          <div className='col-sm-12 acceptance-text'>
            <input
              type='checkbox'
              checked={acceptance}
              onChange={ev => setAcceptance(ev.target.checked)}
              required
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label htmlFor='acceptance'>
              I agree to the above statement.
              <span className='required-label'>*</span>
            </label>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button
          type='button'
          className='btn btn-round mr-2'
          onClick={toPickup}
        >
          Back
        </button>
        <input
          type='submit'
          value='Confirm'
          className='btn custom-btn btn-round'
          disabled={!acceptance}
        />
      </div>
    </ValidatorForm>
  )
}
