import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import memoize from 'memoize-one'

import { getAppointments, updateAppointment } from '@actions/appointments'
import { extractKeys } from '@helpers/objects'
import toastr from '@modules/toastr'

import '@styles/appt-list-cards.css'

function AppointmentsList({
  service_type,
  location,
  onSelect,
  filter,
  multiple,
  ...props
}) {
  const [loading, setLoading] = useState(true)
  const [appointments, setAppointments] = useState([])
  const [selected, setActive] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!props.appointments) {
      props.getAppointments(true, service_type, location)
    } else if (loading && !appointments.length) {
      const fetchAll = async () => {
        let appointments = apptList(
          props.appointments,
          props.show_inactive,
          filter
        )

        if (props.metadata) {
          const [endpoint, data] = props.metadata

          appointments = await Promise.all(
            appointments.map(async appt => {
              try {
                const { data: application } = await $app.axios.get(
                  endpoint(appt.schedulable_id)
                )

                return {
                  ...appt,
                  application,
                  metadata: data.reduce((obj, key) => {
                    const [prop, alias] =
                      typeof key == 'string'
                        ? key.split(':')
                        : key.slice().reverse()

                    let val =
                      typeof prop == 'function'
                        ? prop(application)
                        : application[prop]

                    if (val === null) val = 'N/A'
                    if (typeof val == 'boolean') val = val ? 'Yes' : 'No'

                    obj[alias || prop.replaceAll('_', ' ').capitalize()] = val
                    return obj
                  }, {}),
                }
              } catch (err) {
                return {
                  ...appt,
                  __failed: true,
                }
              }
            })
          )

          appointments = appointments.filter(a => !a.__failed)

          if (filter) {
            appointments = appointments.filter(filter)
          }
        }

        setAppointments(appointments)
        setLoading(false)
      }

      fetchAll()
    }
  })

  const find = memoize((appointments, term) => {
    if (!term || !appointments?.length) return appointments
    const rx = new RegExp(term, 'i')

    return appointments.filter(appt => {
      const arr = [appt.username, appt.slot, appt.isoDate]

      if (appt.metadata) {
        arr.push(
          ...Object.values(appt.metadata).filter(v =>
            /number|string/.test(typeof v)
          )
        )
      }

      return arr.some(str => rx.test(str))
    })
  })

  const callback =
    (...appointments) =>
    async (confirmed = true) => {
      for (let { reference_code, username } of appointments) {
        try {
          await $app.axios.patch('/schedules/update_status', {
            reference_code,
            status: confirmed ? 'arrived' : 'no show',
          })
        } catch (err) {
          console.error('Error')
          toastr.error(
            'Error',
            `Unable to set appointment of ${username} to 'Arrived'`
          )
        }
      }
    }

  const onClick = id => () => {
    if (!multiple) {
      setActive([id])

      const appt = appointments.find(a => a.id == id)
      typeof onSelect == 'function' && onSelect(appt, callback(appt))
    } else {
      const _s = new Set(selected)
      _s.has(id) ? _s.delete(id) : _s.add(id)
      setActive([..._s])

      const appts = [..._s].map(id => appointments.find(a => a.id == id))
      typeof onSelect == 'function' && onSelect(appts, callback(...appts))
    }
  }

  const list = find(appointments, search).map(appt => {
    const args = [appt, selected.includes(appt.id)]

    return (
      <div key={appt.id} onClick={onClick(appt.id)}>
        {props.children?.(...args) || defaultCard(...args)}
      </div>
    )
  })

  if (loading) {
    return (
      <div className='text-center'>
        <p>Fetching Appointments...</p>
      </div>
    )
  } else if (!appointments.length) {
    return (
      <div className='text-center'>
        <p>{props.noAppointmentsText || 'No Appointments Found.'}</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <section className='appointments-list'>
        <div className='appointments-list-header'>
          <div>
            <input
              placeholder={props.searchText || 'Search by Name/Time'}
              className='form-control'
              value={search}
              onChange={ev => setSearch(ev.target.value)}
            />
          </div>
          {props.action ? (
            <button
              className='btn custom-btn'
              onClick={props.action.fn}
              disabled={!selected.length}
            >
              {props.action.text || 'Confirm Selection'}
            </button>
          ) : null}
          {props.prompt ? (
            <div className='flex-grow mt-4 text-center text-muted'>
              {props.prompt}
            </div>
          ) : null}
        </div>
        {list}
      </section>
    </React.Fragment>
  )
}

function apptList(list, show_inactive, filter) {
  let appointments = list

  if (!appointments?.length) return []

  if (!show_inactive) {
    appointments = appointments.filter(appt => appt.active)
  }

  if (filter) {
    appointments = appointments.filter(filter)
  }

  return appointments
    .sort((a, b) => Date(a.slot) > Date(b.slot))
    .map(appt => ({
      ...appt,
      username:
        `${appt.user_first_name} ${appt.user_middle_name} ${appt.user_last_name}`.capitalize(),
      isoDate: appt.slot.slice(0, 10),
      isoTime: appt.slot.slice(11),
      when: moment(appt.slot).format('Do MMMM, YYYY @ h:mm A'),
    }))
}

function defaultCard(appt, selected) {
  const metadata = appt.metadata
    ? Object.entries(appt.metadata).map(([key, val]) => (
        <div key={key}>
          <strong>{key}</strong>
          <span>{val}</span>
        </div>
      ))
    : null

  return (
    <div
      className={
        'card card-rounded appointment-card' +
        (selected ? ' appointment-card-active' : '')
      }
    >
      <div className='card-header'>
        <span>{appt.username}</span>
      </div>
      <div className='card-body'>
        <div>
          <strong>Time</strong>
          <span>{appt.when}</span>
        </div>
        {metadata}
      </div>
    </div>
  )
}

export default connect(
  state => extractKeys(state.appointments, 'appointments'),
  { getAppointments, updateAppointment }
)(AppointmentsList)
