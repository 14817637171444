import * as TYPES from '@action-types'
import toastr from '@modules/toastr'
import mapServiceName from '@constants/maps.services'

const getRequest = {
  type: TYPES.GET_REQUEST_SUCCESS,
  payload: {},
}

export const getAllNotes = () => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get('/notes')

    dispatch({
      type: TYPES.GET_ALL_NOTES,
      payload: { response: data },
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const getSearchNotes = (query, page) => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get('/notes', {
      params: { query, page },
    })

    dispatch({
      type: TYPES.GET_ALL_NOTES,
      payload: { response: data },
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const getNote = id => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get('/notes/' + id)

    dispatch({
      type: TYPES.GET_PARTICULAR_NOTE,
      payload: { response: data },
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const sendMessage = (note, cb) => async dispatch => {
  dispatch(getRequest)

  try {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const subject =
      note.subject ?? 'MyGateway Portal Update - ' + mapServiceName(note.type)

    if (note.type) {
      await $app.axios.post('/services/notes/add_note', {
        note: {
          note_type: 'note',
          internal_view_only: false,
          notable_id: note.form,
          notable_type: note.type,
          text: note.message,
          user: note.user,
        },
      })

      await $app.axios.post('/emails', {
        email: note.email,
        subject,
        message: `
          Good day ${note.name.capitalize()},
          <br />
          <p>${note.message}</p>
        `,
      })
    } else {
      const { email, subject, message } = note

      await $app.axios.post('/emails', {
        email,
        subject: 'Pilot Service Center - ' + subject,
        message: `From <strong>${user.email}</strong>,<br/>` + message,
      })
    }

    dispatch({
      type: TYPES.SEND_MESSAGE_DATA,
    })

    toastr.success('Success', 'Your message has been successfully sent.')
    typeof cb == 'function' && cb()
  } catch (err) {
    dispatch(createError(err))
  }
}

export function createError(error) {
  if (error.response) {
    const { data, status } = error.response

    toastr.error('Error', data.error)

    if (status == 401) {
      sessionStorage.clear()
      window.location.href = '/login'
      return
    }
  }

  return dispatch => {
    setTimeout(() => {
      dispatch({
        type: TYPES.CLEAR_ERROR,
      })
    }, 2000)

    dispatch({
      type: TYPES.GET_RESPONSE_ERROR,
      payload: error,
    })
  }
}
