import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";

export default class BackyardFarm extends React.Component {
  resource = "/backyard_farms";
  form_key = "backyard_farm";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    "::Farm Location",
    {
      name: "farm_street",
      label: "Street Address",
    },
    {
      name: "farm_lot_numb",
      label: "Lot Number",
    },
    {
      name: "farm_island",
      label: "Island",
      options: islands,
    },
    {
      heading: "GPS Coordinates",
    },
    {
      heading: `<span class='text-lg'>To obtain GPS coordinates in Google Maps, find desired location on map, hold desired location until marker appears, coordinates should appear in the search bar.</span>`,
    },
    {
      name: "latitude",
      is: "float",
    },
    {
      name: "longitude",
      is: "float",
    },
    "::SPACER",
    {
      name: "land_ownership",
    },
    {
      name: "farm_type",
      label: "Type of Farming",
    },
    {
      name: "farm_size",
      label: "Size of the Farm/Backyard",
      hint: "In acres",
    },
    {
      name: "livestock_prev_year",
      label: "Livestock in Previous Year",
      columns: [
        { name: "species", label: "Species" },
        { name: "male_slaughtered", label: "Males slaughtered last year" },
        { name: "female_slaughtered", label: "Females slaughtered last year" },
        {
          name: "weight_slaughtered",
          label: "Weight of Animals Slaughtered (pounds)",
        },
      ],
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
