import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class AnalysisRequest extends React.Component {
  resource = "/analysis_requests";
  form_key = "analysis_request";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "sample_log_numb",
      label: "Sample Log #",
    },
    {
      name: "customer_req_numb",
      label: "Customer Request Form # (If applicable)",
      required: false,
    },
    {
      name: "regulatory",
      label: "Regulatory Sample",
      type: "checkbox",
      required: false,
    },
    {
      name: "independent",
      label: "Independent",
      type: "checkbox",
      required: false,
    },
    {
      name: "inventory_numb",
      label: "Inv #",
      hide: !form.independent,
      required: false,
    },
    "::Microbiology Analysis",
    {
      heading: `<span class='text-lg'>Accredited Tests</span>`,
    },
    {
      name: "t_a_b",
      label: "Total Aerobic Bacteria (TAB)",
      type: "checkbox",
      required: false,
    },
    {
      name: "t_c",
      label: "Total Coliforms (TC)",
      type: "checkbox",
      required: false,
    },
    {
      name: "f_c",
      label: "Faecal Coliform (FC)",
      type: "checkbox",
      required: false,
    },
    {
      name: "e_c",
      label: "E.Coli (EC)",
      type: "checkbox",
      required: false,
    },
    {
      name: "sal",
      label: "Salmonella sp. (Sal)",
      type: "checkbox",
      required: false,
    },
    {
      name: "staph",
      label: "Staphyloccus sp.(Staph)",
      type: "checkbox",
      required: false,
    },
    {
      name: "list",
      label: "Listeria sp. (List)",
      type: "checkbox",
      required: false,
    },
    {
      name: "yeast_mold",
      label: "Yeast & Mold",
      type: "checkbox",
      required: false,
    },
    {
      name: "t_c_membrane",
      label: "Total Coliform (TC)(Membrane Filtration)",
      type: "checkbox",
      required: false,
    },
    {
      name: "f_c_membrane",
      label: "Faecal Coliform (FC)(Membrane Filtration)",
      type: "checkbox",
      required: false,
    },
    {
      name: "e_c_membrane",
      label: "E.coli (EC)(Membrane Filtration)",
      type: "checkbox",
      required: false,
    },
    {
      name: "f_s_membrane",
      label: "Faecal Streptococcus/Enterococcus (FS)(Membrane Filtration)",
      type: "checkbox",
      required: false,
    },
    {
      name: "routine_food_test",
      label: "Routine Testing for Food (TAB, TC, FC, EC, Sal, Staph)",
      type: "checkbox",
      required: false,
    },
    {
      name: "routine_water_test",
      label: "Routine Testing for Food (TC, FC, EC, FS)",
      type: "checkbox",
      required: false,
    },
    {
      name: "other_bio",
      label: "Other",
      required: false,
    },
    "::Chemistry Analysis",
    {
      heading: `<span class='text-lg'>Accredited Tests</span>`,
    },
    {
      name: "moisture",
      label: "Moisture",
      type: "checkbox",
      required: false,
    },
    {
      name: "ph",
      label: "pH",
      type: "checkbox",
      required: false,
    },
    {
      name: "ash",
      label: "Ash",
      type: "checkbox",
      required: false,
    },
    {
      name: "salt",
      label: "Salt",
      type: "checkbox",
      required: false,
    },
    {
      name: "t_v_b_n",
      label: "Total Volatile Nitrogen Bases (TVB-N)",
      type: "checkbox",
      required: false,
    },
    {
      name: "water_activity",
      label: "Water Activity",
      type: "checkbox",
      required: false,
    },
    {
      name: "fat",
      label: "Fat",
      type: "checkbox",
      required: false,
    },
    {
      name: "routine_seafood_test",
      label: "Routine Testing for Seafood (TVB-N)",
      type: "checkbox",
      required: false,
    },
    {
      heading: `<span class='text-lg'>Non-Accredited Tests</span>`,
    },
    {
      name: "sulphites",
      label: "Sulphites",
      type: "checkbox",
      required: false,
    },
    {
      name: "phosphorus",
      label: "Phosphorus",
      type: "checkbox",
      required: false,
    },
    {
      name: "t_m_a_n",
      label: "Trimethylamaine Nitrogen (TMA-N)",
      type: "checkbox",
      required: false,
    },
    {
      name: "protein_crude",
      label: "Protein (Crude)/Nitrogen",
      type: "checkbox",
      required: false,
    },
    {
      name: "sodium",
      label: "Sodium",
      type: "checkbox",
      required: false,
    },
    {
      name: "potassium",
      label: "Potassium",
      type: "checkbox",
      required: false,
    },
    {
      name: "soluble_solids",
      label: "Soluble Solids",
      type: "checkbox",
      required: false,
    },
    {
      name: "fat_crude",
      label: "Fat(Crude)",
      type: "checkbox",
      required: false,
    },
    {
      name: "reducing_sugars",
      label: "Total Reducing Sugars",
      type: "checkbox",
      required: false,
    },
    {
      name: "sucrose",
      label: "Sucrose",
      type: "checkbox",
      required: false,
    },
    {
      name: "tiratable_acidity",
      label: "Titratable (Total) Acidity",
      type: "checkbox",
      required: false,
    },
    {
      name: "hardness",
      label: "Hardness",
      type: "checkbox",
      required: false,
    },
    {
      name: "alcohol",
      label: "Alcohol",
      type: "checkbox",
      required: false,
    },
    {
      name: "other_chem",
      label: "Other",
      required: false,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
