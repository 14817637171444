import React, { Fragment } from 'react'
import Swal from 'sweetalert2'
import Modal from 'react-responsive-modal'
// import { MdAutorenew } from 'react-icons/md'

import Loader from '@shared/loader'
import toastr from '@modules/toastr'
import Form from '@modules/validator-form'

import SERVICE_PROPS from '@helpers/extract-service-props'
import Wizard from '@modules/form-wizard'
import breeds from '@constants/dog-breeds'
import countries from '@constants/countries'
import islands from '@constants/islands'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import moment from 'moment'
// import form from '@modules/form-wizard/form'

const getRemainingBreeds = (arr = [], predominant = '') => {
  const index = arr.findIndex(breed => predominant == breed)
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export default class DogLicensing extends React.Component {
  state = {
    license_num: '',
    renewal_date: '',
    record: null,
    loading: false,
  }

  // componentDidMount() {
  //   Swal.fire(
  //     'Applications Closed',
  //     `
  //       Sorry, but the acceptance of this application form has been temporarily suspended.
  //     `,
  //     'info'
  //   )
  // }

  today = new Date()

  title = 'Application For Dog Registration & Licencing'
  resource = '/dog_licenses'
  form_key = 'dog_license'

  skip_location = true
  hide_buttons = {
    status: true,
    // deny: true,
  }

  fee = 10.00

  // fee = record => record.neutered == true ? 0.00 : 10.00
  free = record => record.neutered

  // fee = (form) => {
  //   const isNeutered = form.neutered;
  //   if(isNeutered) {
  //     return 0.00
  //   } else {
  //     return 11.00
  //   }
  // }

  custom_actions = [
    {
      user: true,
      text: 'Remove Dog',
      icon: 'times',
      fn: removeDog.bind(this)
    },
    {
      user: true,
      text: 'Transfer Dog',
      icon: 'exchange-alt',
      test: r => r.application_decision == 'approved'
        && !r.transfer,
      fn: record => this.setState({ record })
    },
    {
      user: true,
      text: 'Cancel Transfer',
      icon: 'times',
      test: r => r.application_decision == 'approved'
        && r.transfer && r.transferred_to_id,
      fn: async record => {
        const { form_num, dog_name } = record
        const { isDismissed } = await Swal.fire({
          icon: 'info',
          title: 'Cancel Transfer',
          text: 'Are you sure that you want to cancel this transfer?',
          showCancelButton: true,
          cancelButtonText: 'Nevermind',
          confirmButtonText: 'Cancel Transfer'
        })

        if (isDismissed) return

        try {
          await $app.axios.put('/dog_licenses/cancel_transfer', {
            dog_license: { form_num }
          })

          await Swal.fire(
            'Transfer Cancelled',
            `Transfer request for ${dog_name} has been cancelled.`,
            'success'
          )
        } catch (err) {
          console.error(err)

          await Swal.fire(
            'Error',
            'We failed to cancel your transfer request',
            'error'
          )
        }
      }
    },
    {
      text: 'Approve & Set Tag Number',
      icon: 'tag',
      fn: setDogLicenceNumber.bind(this),
    },
    // {
    //   user: true,
    //   table: true,
    //   text: 'Renew Dog',
    //   icon: <MdAutorenew size={24} />,
    //   fn: async (records) => {
    //     console.log(records)
    //   }
    // },
    {
      user: true,
      table: true,
      text: 'Transfer Dog',
      icon: 'exchange-alt',
      fn: dogs => runActionOnSelectedDog.call(
        this,
        dogs,
        dog => dog.application_decision == 'approved',
        record => this.setState({ record })
      )
    },
    {
      user: true,
      table: true,
      text: 'Remove Dog',
      icon: 'times',
      fn: dogs => runActionOnSelectedDog.call(this, dogs, null, removeDog)
    }
  ]

  validate = form => {
    return {
      breed: form.mixed_breed
        ? form.breed_multi.join(', ')
        : form.breed_single,
      dog_place_of_birth: form.dog_island_birth
        ? `${form.dog_island_birth}, Bahamas`
        : form.dog_place_of_birth
    }
  }

  columns = () => [
    {
      name: 'Dog Name',
      selector: row => row.dog_name,
    },
    {
      name: 'Tag',
      selector: row => row.license_numb || 'N/A'
    },
    {
      name: 'Expires At',
      cell: row => row.application_decision == 'approved'
        ? moment(row.license_expiry_date).format('Do MMM, YYYY')
        : 'N/A'
    }
  ]

  closeModal = () => this.setState({ record: null })

  initiateTransfer = async values => {
    await $app.axios.put('/dog_licenses/transfer_license', {
      [this.form_key]: {
        ...values,
        form_num: this.state.record.form_num
      }
    })

    this.setState({ record: null })

    await Swal.fire(
      'Licence Transfer Started',
      'Your transfer licence has been sent to the requested recipient',
      'success'
    )
  }

  fields = (form, viewing) => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'license_numb',
      label: 'License Number',
      hide: !form.renewal,
      view: true,
    },
    {
      name: 'renewal_date',
      type: 'date',
      hide: !form.renewal,
      view: true,
    },
    {
      name: 'island',
      label: 'Island of Residence',
      options: [
        { value: 'new providence', label: 'New Providence' },
        { value: 'grand bahama', label: 'Grand Bahama' },
      ]
    },
    {
      heading: '',
      hide: true,
    },
    {
      heading: viewing ? '<span>&nbsp;</span>' : `
        <strong>NOTE:</strong>
        <span>
          This form should be completed once
          the dog is 3 months of age or older.
        </span>
      `,
    },
    {
      name: 'dog_name',
      label: `Dog's Name`
    },
    {
      name: 'dog_sex',
      label: 'Sex',
      options: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
      ],
    },
    {
      name: 'dog_age',
      label: `Dog's Age (In Years)`,
      is: 'integer',
    },
    {
      name: 'dog_date_of_birth',
      label: 'Birth Date',
      type: 'date',
      showYearDropdown: true,
      required: false,
    },
    {
      name: 'mixed_breed',
      type: 'select:bool',
      label: 'Is your dog a mixed breed?',
      view: false
    },
    {
      name: 'predominant_breed',
      hide: !form.mixed_breed,
      options: breeds,
    },
    {
      name: 'breed',
      hide: true,
      save: true,
      view: {
        label: form.mixed_breed ? 'Additional Breeds' : 'Breed',
        value: v => Array.isArray(v) ? v.join(', ') : v
      }
    },
    {
      name: 'breed_single',
      label: 'Breed of Dog',
      save: false,
      options: breeds,
      hide: form.mixed_breed,
      view: false,
    },
    {
      name: 'breed_multi',
      label: 'Other Breed(s)',
      options: getRemainingBreeds(breeds, form.predominant_breed),
      hide: !form.mixed_breed,
      required: false,
      multi: true,
      view: false,
      save: false,
    },
    {
      name: 'microchip_numb_or_brand',
      label: 'Microchip #',
      hint: 'Leave blank if dog is not chipped',
      required: false,
    },
    {
      name: 'neutered',
      label: 'Is this dog neutered/spayed?',
      type: 'select:bool',
      view: {
        label: 'Neutered?'
      }
    },
    {
      name: 'neutered_region',
      label: form.dog_sex === 'female' ? 'Where was this dog spayed?' : 'Where was this dog neutered?',
      hide: !form.neutered,
      options: [
        { value: 'local', label: 'Local'},
        { value: 'international', label: 'International'},
      ]
    },
    {
      name: 'vet_first_name',
      label: "Veterinarian's First Name",
      hide: form.neutered ? form.neutered_region == 'local' : true,
      required: false,
    },
    {
      name: 'vet_last_name',
      label: "Veterinarian's Surname",
      hide: form.neutered ? form.neutered_region == 'local' : true,
      required: false,
    },
    {
      name: 'vet_company_name',
      label: "Office/Clinic/Hospital Name",
      hide: form.neutered ? form.neutered_region == 'local' : true,
      required: false,
    },
    {
      name: 'vet_company_address',
      label: "Office/Clinic/Hospital Address",
      hide: form.neutered ? form.neutered_region == 'local' : true,
      required: false,
    },
    {
      name: 'vet_email',
      label: "Vetenarian's Email",
      hide: form.neutered ? form.neutered_region == 'local' : true,
      required: false,
    },
    {
      name: 'vet_phone_numb',
      label: "Vetenarian's Phone #",
      hide: form.neutered ? form.neutered_region == 'local' : true,
      required: false,
    },
    {
      name: 'dog_color',
      label: 'Color',
      multi: true,
      hint: 'Select the colors which apply',
      options: [
        'Tan',
        'Black',
        'Blonde',
        'Brindle',
        'Brown',
        'Fawn',
        'Golden',
        'Silver',
        'Grey',
        'Tri-Color',
        'White',
        'Red',
        'Spotted',
        'Blue Merle',
        'Other',
      ],
    },
    {
      name: 'dog_color_other',
      save: false,
      label: 'Please specify',
      hide: !form.dog_color?.includes?.('Other')
    },
    {
      name: 'dog_place_of_birth',
      label: 'Country of Birth',
      options: countries
    },
    {
      name: 'dog_island_birth',
      label: 'Island of Birth',
      hide: form.dog_place_of_birth != 'Bahamas',
      options: islands
    },
    {
      name: 'countries_visited_last_6_months',
      label: 'What countries has this dog visited in the last 6 months?',
      multi: true,
      options: countries,
      required: false,
      view: {
        label: 'Countries visited in last 6 months'
      }
    },
    {
      name: 'dog_desc_of_use',
      label: `Dog's Purpose`,
      multi: true,
      options: [
        'Pet/Companion',
        'Breeding Animal',
        'National Security Canine',
        'Private Security Canine',
        'Show Dog',
        'Service Dog',
        'Other',
      ]
    },
    {
      name: 'dog_desc_of_use_other',
      save: false,
      label: 'Please specify',
      hide: !form.dog_desc_of_use?.has?.('Other'),
      view: false,
    },
    {
      name: 'rabies_expiration_date',
      label: 'Rabies Expiration Date',
      type: 'date',
      showYearDropdown: true,
      minDate: this.today,
      required: false,
    },
    {
      name: 'dhlpp_expiration_date',
      label: 'DHLPP Expiration Date',
      type: 'date',
      showYearDropdown: true,
      minDate: this.today,
      required: false,
    },
    {
      key: 'image.1',
      name: 'vaccination_upload',
      label: 'Vaccination Card',
      type: 'file:image',
      required: false,
    },
    {
      key: 'image.2',
      name: 'neutered_upload',
      label: 'Neutered/Spayed Proof',
      type: 'file:image',
      hide: !form.neutered,
      hint: `Please upload spayed or neutered certificate following the template provided by the Department of Agriculture
         (link to website page to obtain certificate template). Certificate must be signed and stamped by the administering veterinarian.`,
      // required: false,
    },
    {
      name: 'declare',
      type: 'checkbox',
      label: `
        I declare that the information entered is true and correct
        to the best of my knowledge.
      `,
    },
    {
      name: 'no_refund_acceptance',
      type: 'checkbox',
      label: `
        By submitting this form, I acknowledge and agree that all fees or payments made are non-refundable.
        Once the payment is processed, no refunds will be provided, regardless of the application result.
      `,
    },
  ]

  hidden_fields = form => [
    {
      key: 'image.0',
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Dog Licence '
    }
  ]

  render = () => {
    const { record, loading } = this.state

    const transferFields = () => [
      {
        name: 'nib',
        label: 'NIB #',
        is: 'integer',
      },
      {
        name: 'first_name',
        label: 'First Name',
      },
      {
        name: 'last_name',
        label: 'Last Name'
      }
    ]

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!record}
          onClose={this.closeModal}
          classNames={{ modal: 'w-full max-w-xl' }}
          center
        >
          <header className='modal-header'>
            <h5 className='mb-0'>Transfer Dog Licence</h5>
          </header>
          <p className='my-2'>
            Enter the details for whom you want to transfer this licence to.&nbsp;
            <strong>
              Please note that the recipient must have a MyGateway account
            </strong>
          </p>
          <Form
            key={record?.id}
            fields={transferFields}
            save={this.initiateTransfer}
            hideButton={false}
          />
        </Modal>
      </Fragment>
    )
  }
}


export async function setDogLicenceNumber (record) {
  const { value: license_numb, isDismissed } = await Swal.fire({
    title: 'Set Tag Number',
    input: 'text',
    inputLabel: 'Tag Number',
    inputPlaceholder: 'Tag Number',
    text: `Please set this dog's tag number`,
    showCancelButton: true,
    confirmButtonText: 'Set Tag #',
  })

  if (isDismissed) return
  this.setState({ loading: true })

  const { props, resource, form_key } = this
  const { history, location } = props

  try {
    await $app.axios.put(resource + '/update_application', {
      [form_key]: {
        form_num: record.form_num,
        application_decision: 'approved',
        tag_desc: 'approved',
        license_numb,
      },
    })

    if (location.state?.view) {
      const view = { ...location.state.view }
      view.application_decision = 'approved'
      view.fields[6].value = license_numb

      history.replace(location.pathname, { ...location.state, view })
    }

    toastr.success('Success', 'Application has been approved and verified')
  } catch (error) {
    console.error(error)
    toastr.error('Error', 'Unable to approve and verify application')
    return
  }

  try {
    await $app.axios.post('/emails', {
      email: record.user.email,
      subject: 'MyGateway Portal Update - Annual Dog License',
      message: `
        Good day ${record.user.first_name.capitalize()},
        <br/>
        Your Annual Dog License has been approved. Here are your details: 
        <ul>
        <li><b>License Number:</b> ${license_numb}</li>
        </ul>
        <br/><br />
        Thank you for using the MyGateway Portal.
      `,
    })

    toastr.success('Success', 'Email successfully sent')
  } catch (err) {
    console.error(err)
    toastr.error('Error', 'Unable to send email')
  }

  this.setState({ loading: false, record: null })

}

async function runActionOnSelectedDog (records, filter, callback) {
  const dogs = records.reduce((obj, dog, i) => {
    if (!filter || filter(dog)) {
      obj[i] = [
        dog.dog_name,
        dog.predominant_breed || dog.breed
      ].join(', ')
    }

    return obj
  }, {})

  if (!Object.keys(dogs)?.length) {
    await Swal.fire(
      'Oops',
      'The action you are trying to do cannot currently be done on/with any dog.',
      'warning'
    )

    return null
  }

  const { value: index, isDismissed } = await Swal.fire({
    input: 'select',
    title: 'Please Select A Dog',
    inputOptions: dogs,
  })

  if (isDismissed) return null
  callback.call(this, records[index])
}

async function removeDog (record) {
  const { form_num, dog_name } = record
  const { isDismissed } = await Swal.fire({
    icon: 'warning',
    title: 'Void Licence',
    html: 'Are you sure that you want to void your licence belonging to '
      + '<strong>' + dog_name + '</strong>?',
    showCancelButton: true,
    cancelButtonText: 'Nevermind',
    confirmButtonText: 'Void Licence'
  })

  if (isDismissed) return
  const { resource, form_key } = this

  try {
    await $app.axios.put(resource + '/void_application', {
      [form_key]: { form_num }
    })

    await Swal.fire(
      'Licence Voided',
      'The licence for our dog has been successfully voided',
      'success'
    )
  } catch (err) {
    console.error(err)

    await Swal.fire(
      'Error',
      'An error occurred while attempting to void your dog\'s licence.',
      'error'
    )
  }
}

// async function renewDog (record) {
  
// }
