import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import convertToBase64 from '@helpers/file-to-base64'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import SamplePassportImage from '@images/sample-passport-data-page.png'
import BadPassportImage from '@images/sample-passport-data-page-bad.jpg'

import {
  markAsVetted,
  recommendToPS,
  recommendToMinister,
  appealApplication,
  SECURITY_COLUMNS,
} from './_security-utils'

const _18Years = moment().subtract(18, 'years').toDate()

export default class BusinessSecurityLicence extends React.Component {
  isRenewal = /renewal/.test(this.props.location.pathname)
  isSecurity = /security-/.test(this.props.location.pathname)

  resource = '/inquiry_and_security_licenses'
  form_key = 'inquiry_and_security_license'
  skip_location = true
  // business_only = true
  columns = SECURITY_COLUMNS

  defaults = {
    temporary: true,
    agent_type: this.isSecurity ? 'security firm' : 'inquiry firm',
  }

  hide_buttons = {
    status: true,
    deny: !$app.hasRole('minister'),
  }

  custom_acceptance = true
  custom_actions = [
    markAsVetted.call(this),
    recommendToPS.call(this),
    recommendToMinister.call(this),
    appealApplication.call(this)
  ]

  fee = record => record.client_type == 'individual'
    ? 500
    : record.employee_details.length * 10

  validate = async values => {
    const update = {
      employee_details: []
    }

    if (values.client_type == 'business') {
      for (let row of values.employee_details) {
        const {
          identification_upload,
          photo_upload,
          police_cert_upload,
          ...item
        } = row
  
        item.photo_upload = await convertToBase64(photo_upload)
        item.identification_upload = await convertToBase64(identification_upload)
        item.police_cert_upload = await convertToBase64(police_cert_upload)
        update.employee_details.push(item)
      }
    } else {
      if (values.shareholders[0]) {
        update.shareholders = []

        for (let row of values.shareholders) {
          const {
            identification_upload,
            photo_upload,
            police_cert_upload,
            ...item
          } = row

          if(item.name){
            if(photo_upload){item.photo_upload = await convertToBase64(photo_upload);}
            if(identification_upload){item.identification_upload = await convertToBase64(identification_upload);}
            if(police_cert_upload){item.police_cert_upload = await convertToBase64(police_cert_upload);}
            update.shareholders.push(item);
          }
        }
      }
      if (values.owners[0]) {
        update.owners = []

        for (let row of values.owners) {
          const {
            identification_upload,
            photo_upload,
            police_cert_upload,
            ...item
          } = row

          if(item.name){
            if(photo_upload){item.photo_upload = await convertToBase64(photo_upload);}
            if(identification_upload){item.identification_upload = await convertToBase64(identification_upload);}
            if(police_cert_upload){item.police_cert_upload = await convertToBase64(police_cert_upload);}
            update.owners.push(item);
          }
        }
      }
    }

    Object.assign(update, {
      renewal: this.isRenewal,
      po_box: values.po_box || '####',
    })

    return update
  }

  updateView = update => {
    const { history, location } = this.props
    const inview = location.state?.view

    inview &&
      history.replace(location.pathname, {
        ...location.state,
        view: { ...inview, ...update },
      })
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        temporary: false,
        renewal: this.isRenewal,
        appeal: false,
        agent_type: this.defaults.agent_type,
        per_page: 50
      },
    },
  }

  fields = form => {
    const list = [
      {
        name: 'temporary',
        save: true,
        hide: true,
      },
      {
        name: 'agent_type',
        save: true,
        hide: true,
      },
      {
        name: 'po_box',
        save: true,
        hide: true,
      },
    ]

    if (form.client_type == 'business') {
      list.push(...BUSINESS_FIELDS(form))
    } else {
      list.push(
        {
          name: 'bl_number',
          label: 'Business Licence #',
          view: false,
        },
        {
          name: 'business_nib',
          label: 'Business NIB',
          view: false,
        },
        '::SPACER',
      )
    }
    
    list.push(
      {
        name: 'company_name',
        label: 'Company Name',
      },
      {
        name: 'incorporation_cert_upload',
        key: 'image.1',
        type: 'file:all',
        hide: this.isRenewal,
        label: 'Approved Trading Name (Upload)',
        hint: `
            Document from DIR showing status of approval
            for the given name above.
          `,
      },
      {
        name: 'street_address',
        label: 'Street Address',
      },
      {
        name: 'po_box',
        label: 'P.O. Box',
        required: false,
      },
      {
        name: 'phone_number',
        label: 'Phone Number',
        type: 'phone',
      },
  
      '::SPACER',
      // {
      //   name: 'employers',
      //   label: 'Other Owner(s)',
      //   required: false,
      //   columns: [
      //     {
      //       name: 'first_name',
      //       required: false,
      //     },
      //     {
      //       name: 'last_name',
      //       required: false,
      //     },
      //     // {
      //     //   name: 'photo_upload',
      //     //   label: 'Government Issued Photo ID',
      //     //   type: 'file:all',
      //     //   required: false,
      //     // }
      //   ]
      // },
      {
        name: 'owners',
        label: 'Other Owners',
        hide: form.client_type == 'business',
        required: false,
        fields: [
          {
            name: 'name',
            required: false,
          },
          {
            name: 'photo_upload',
            label: 'Passport Photo',
            type: 'file:image',
            required: false,
          },
          {
            name: 'identification_upload',
            label: 'Government Issued Photo ID',
            type: 'file:all',
            required: false,
          },
          {
            name: 'police_cert_upload',
            label: 'Valid Police Character Certificate',
            type: 'file:all',
            required: false,
          }
        ],
      },
      
      '::SPACER',
      {
        name: 'shareholders',
        label: 'Shareholders/Directors',
        hide: form.client_type == 'business',
        required: false,
        fields: [
          {
            name: 'name',
            required: false,
          },
          {
            name: 'photo_upload',
            label: 'Passport Photo',
            type: 'file:image',
            required: false,
          },
          {
            name: 'identification_upload',
            label: 'Government Issued Photo ID',
            type: 'file:all',
            required: false,
          },
          {
            name: 'police_cert_upload',
            label: 'Valid Police Character Certificate',
            type: 'file:all',
            required: false,
          }
        ],
      },
  
      '::SPACER',
      {
        name: 'employee_details',
        label: 'Employee Details',
        hide: form.client_type == 'individual',
        rowLabel: 'Employee',
        fields: [
          { name: 'first_name', label: 'First Name' },
          { name: 'middle_name', label: 'Middle Name', required: false },
          { name: 'last_name', label: 'Last Name' },
          {
            name: 'date_of_birth',
            type: 'date',
            maxDate: _18Years,
            showYearDropdown: true,
          },
          {
            name: 'sex',
            options: [
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' }
            ]
          },
          { name: 'nib', label: 'NIB#' },
          // { name: 'email', label: 'Email Address' },
          {
            name: 'photo_upload',
            label: 'Passport Photo',
            type: 'file:image',
          },
          {
            name: 'identification_upload',
            label: 'Government Issued Photo ID',
            type: 'file:all',
          },
          {
            name: 'police_cert_upload',
            label: 'Valid Police Character Certificate',
            type: 'file:all',
          }
        ],
      },
  
      '::Uploads',
      {
        name: 'badge_design_upload',
        label: 'Design of Badge',
        type: 'file:image',
        key: 'image.6',
        hide: this.isRenewal,
      },
      {
        name: 'uniform_design_upload',
        label: 'Design of Uniform',
        type: 'file:image',
        key: 'image.7',
        hint: `Male and Female (.jpg/.png)`,
        hide: this.isRenewal,
      },
      {
        name: 'photo_upload',
        hide: form.client_type == 'business',
        label: 'Passport sized Photo',
        type: 'file:image',
        key: 'image.5',
      },
      {
        name: 'identification_upload',
        label: 'Government Issued Photo ID',
        type: 'file:image',
        // hint: 'Passport, Driver\'s Licence, or Voter\'s Card.',
        key: 'image.3',
        hint: `
          The <strong>information page</strong> of your passport,
          or the front side of your Driver's Licence or Voter's Card.<br/>
          <strong>Selfies will not be accepted.</strong>
          <div class='flex flex-row items-center'>
            <div class='flex flex-row items-center mr-6'>
              <img class='w-32 md:w-40 lg:w-48 mr-2' src='${SamplePassportImage}'/>
              <strong class='text-green-500 font-extrabold text-xl'>&checkmark;</strong>
            </div>
            <div class='flex flex-row items-center mr-6'>
              <img class='w-32 mx-4' src='${BadPassportImage}'/>
            </div>
          </div>
        `
      },
      {
        name: 'police_cert_upload',
        label: 'Valid Police Character Certificate',
        type: 'file:image',
        key: 'image.11',
        hint: 'Not more than six (6) months old',
      },
  
      '::SPACER',
      {
        name: 'final_declartion',
        type: 'checkbox',
        long: true,
        label: `
          By clicking this box, I confirm that I understand my obligations under
          the respective laws and regulations applicable to the application for 
          security guards and/or inquiry agents licence and agree to observe
          all applicable laws, regulations, policies and guidelines.
        `,
      },
      {
        name: 'acceptance',
        type: 'checkbox',
        use_as_acceptance: true,
        label: `
          By clicking here you agree that the information provided is accurate
          and lawful without false pretence
        `
      }
    )

    return list
  }

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: this.isSecurity
        ? 'Security Guard Firm Certificate'
        : 'Inquiry Agent Firm Certificate',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
