import moment from 'moment'

const dateFormat = 'Do MMM, YYYY'
const date = d => moment(d).format(dateFormat)

// export const getBusinessDetails = record => {}

export const getCertMap = (type, f) => {
  switch (type) {
    case 'PlantsImportLicense':
    case 'PoultryImportLicense':
    case 'DairyImportLicense':
    case 'MeatImportLicense':
    case 'BananaImport':
    case 'FruitAndVeggie':
    case 'EggsImport':
    case 'OrchidImportLicense':
      return [
        ['Permit Details'],
        ['Issue Date', date(f.license_approval_date)],
        ['Exp. Date', date(f.license_expiry_date)],
        ['Port of Entry', f.entry_port],
        ['Country of Origin', f.country_origin],
      ]
    case 'PoliceCertificate':
      return [
        ['Licence Number', f.license_numb],
        ['SPACE'],
        ['Applicant Details'],
        ['Date Of Birth', date(f.user.date_of_birth)],
        [
          'Place Of Birth',
          f.birth_place == 'Bahamas'
            ? `${f.birth_island}, Bahamas`
            : f.birth_place,
        ],
        ['Passport Number', f.passport_number],
        ['Issing Country', f.passport_issue_country],
        // ['National Insurance Number', f.user.NIB],
        // [`Driver's Licence Number`, f.user.drivers_license_number],
      ]
    default:
      return [['SPACE']]
  }
}
