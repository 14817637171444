import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import { ConsignmentDetails } from "./_item-imports";
import countries from "@constants/countries";
import islands from "@constants/islands";

export default class AnimalByProducts extends React.Component {
  id = "AnimalByproductPermit";
  service = "animal byproduct permit";
  title = "Application for Permit to import Animal By-products";
  agency = "moa";
  resource = "/animal_byproduct_permits";
  form_key = "animal_byproduct_permit";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 11.2;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "permit_type",
      label: "Type of Permit Request",
      options: ["Import", "Export", "Re-export"],
    },
    {
      name: "date_of",
      label: `Date of ${form.permit_type || "Transport"}`,
      type: "date",
    },
    {
      name: "recepient_names",
      label: "Name(s) of Company/Persons Receiving Shipment",
      columns: [{ name: "Name", label: "Names" }],
    },
    {
      name: "recepient_address",
      label: "Physical Address",
    },
    {
      name: "consignment_details",
      label: "Consignment Details in table",
      columns: ConsignmentDetails,
    },
    "::Purpose for Export/Import",
    {
      name: "pet",
      label: "Pet",
      type: "checkbox",
    },
    {
      name: "research",
      label: "Research",
      type: "checkbox",
    },
    {
      name: "medical",
      label: "Medical",
      type: "checkbox",
      hide: form.permit_type !== "Export" && form.permit_type !== "Re-Export",
    },
    {
      name: "other",
      label: "Other?",
      type: "checkbox",
    },
    {
      name: "purpose_other",
      label: "Other",
      hide: !form.other,
    },
    "::SPACER",
    {
      name: "country_of_origin",
      label: "Country of Origin",
      options: countries,
      hide:
        form.permit_type !== "Re-Export" && form.permit_type !== "In-transit",
    },
    {
      name: "prev_cites_permit_numbs",
      label: "Previous CITES permit no.",
      hide: form.permit_type !== "Export" && form.permit_type !== "Re-Export",
      columns: [{ name: "permit_numb", label: "Permit No." }],
    },
    {
      name: "country_destination",
      label: "Country of Destination",
      options: countries,
      hide: form.permit_type !== "Export" && form.permit_type !== "Re-Export",
    },
    {
      name: "destination_entry_port",
      label: "Port of entry name in Destination Country",
      hide: form.permit_type !== "Export" && form.permit_type !== "Re-Export",
    },
    {
      name: "island_destinations",
      label: "Island destination(s) of animal by-products in the Bahamas",
      options: islands,
      hint: "Select ALL that apply",
      multi: true,
      hide: form.permit_type !== "Import",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
