import React from 'react'
import { commonBanks } from '@constants/banking-institutions'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import generateRange from '@helpers/range'
import Swal from 'sweetalert2'
import { BUSINESS_FIELDS, fullName} from '@modules/form-wizard/utils'
import { sportsFederations, sportsList } from '@constants/mysc-constants'

export default class SportsGrant extends React.Component {
  resource = '/sports_grants'
  form_key = 'sports_grant'
  defaults = {}

  // Delete lines as needed
//   fee           = 99
  free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons = {
    status: true,
    deny: !$app.hasAnyRole(['senior_officer']),
  }
  customTitle = () => {
    if ($app.hasRole('officer')) return 'Recommend To Assistant Director';
    if ($app.hasRole('deputy_director')) return 'Approve Application';
    // if ($app.hasRole('department_officer')) return 'Recommend To ADOC';
    // if ($app.hasAnyRole(['deputy_director'])) return 'Recommend To Director';
    // if ($app.hasAnyRole(['director'])) return 'Approve Application';
    // if ($app.hasAnyRole(['under_secretary', 'permanent_secretary'])) return 'Recommend To Minister';
    // if ($app.hasRole('minister')) return 'Approve Application';
  }

  custom_actions = [
    {
      text: this.customTitle(),
      icon: 'user-tie',
      roles: [['officer', 'deputy_director']],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: async record => {
        const isFinalApprover = $app.hasAnyRole(['deputy_director']);
        const { value, isDismissed } = await Swal.fire({
          icon: 'question',
          title: this.customTitle(),
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': isFinalApprover ? "I approve this applicant" : "I recommend this applicant",
            'denied': isFinalApprover ? "I will deny this applicant" : "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (isDismissed) return

        await this.submitToNewLevel(value, record)
      },
    },
  ]

  submitToNewLevel = async (value, record) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    const newAppDecision = () => {
      if ($app.hasRole('officer')) return 'officer viewed';
      if ($app.hasRole('deputy_director')) return value;
    }

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = newAppDecision()

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application successfully submitted.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application',
        'error'
      )
      return
    }
  }

/*  validate = form => {
    const update = {
      project_discipline_list: form.project_discipline_list.slice(0, 3),
    }
    return update
  } */

  foremost = null

  columns = props => [
    {
      name: 'Federation',
      selector: row => row.federation_name?.capitalize() || 'N/A',
      sortable: true,
      searchable: r => r.federation_name,
    },
  ]

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
        name: 'project_category',
        options: [
          { label: 'Individual', value: 'individual' },
          { label: 'Organization', value: 'organization' },
          { label: 'Homecoming/Regatta', value: 'home coming/regatta' },
          { label: 'Federation/Association', value: 'federation' },
          { label: 'Government Entity', value: 'government entity' },
          { label: 'Auspice', value: 'auspice' }
        ]
    },
    '::Contact Person',
    {
      name: 'contact_person',
      type: 'checkbox',
      label: 'I, the applicant, would be the main contact person for the project',
      required: false,
      view: {
        label: 'Applicant is Primary Contact?'
      }
    },
    {
      name: 'contact_first_name',
      label: `First Name`,
      hide: form.contact_person === true
    },
    {
      name: 'contact_middle_name',
      label: `Middle Name`,
      hide: form.contact_person === true,
      required: false,
    },
    {
      name: 'contact_last_name',
      label: `Last Name`,
      hide: form.contact_person === true
    },
    {
      name: 'position'
    },
    {
      name: 'new_organization',
      label: 'Are you a new organization?',
      type: 'select:bool'
    },
    // Ternary Statement
    (form.new_organization ? {
      name: 'federation_name',
      label: 'Name of organization',
    } : {
      name: 'federation_name',
      label: 'Name of organization',
      options: sportsFederations,
    }),
    {
        name: 'sports_list',
        label: 'Name of discipline',
        // multi: true, 
        options: sportsList,
    },

    "::Grant Details",
    {
      name: 'grant_type', 
      label: 'I am applying for',
      options: ['annual grant', 'special grant', 'assistance']
    },
    {
      name: 'received_grant',
      label: 'Have you received a grant as yet for the year?',
      type: 'select:bool'
    },
    {
      name: 'received_grant_list',
      label: 'Past/Current Grants Received',
      hide: form.received_grant != true,
      columns: [
          {
              name: 'amount',
              width: 128,
              is: 'currency'
          },
          {
              name: 'year',
              width: 128,
              showYearDropdown: true,
              options: generateRange(1970, new Date().getFullYear())
                .reverse().map(n => String(n))
          },
          {
              name: 'reason',
          }
      ]
    },
    {
      name: 'submitted_financials',
      label: 'Have you submitted a financial report?',
      type: 'select:bool',
      hide: form.received_grant != true,
      callback: bool => {
        if (bool) return
    
        Swal.fire({
            title: 'Please Note',
            icon: 'info',
            html: `
                In order to be considered for a Sports Grant, you will also be
                required to submit a <em>Financial Report</em>. Once approved, you only have to submit a financial report once per year. Applicants without a financial report will be rejected.
            `,
        })
      }
    },
    {
      name: 'submitted_financials_date',
      label: 'When was the financial report submitted?',
      type: 'date',
      showYearDropdown: true,
      hide: form.submitted_financials != true
    },
    {
      name: 'request_date',
      type: 'date',
      showYearDropdown: true
    },
    {
      name: 'amount_requested',
      type: 'currency'
    },
    {
      name: 'disbursement_dates_list',
      label: 'Requested disbursement dates:',
      columns: [{ name: 'date', type: 'date' }],
      format: v => v.date,
    },
    {
      name: 'request_details',
      label: 'Request Reason',
      options: [
        { label: 'Training Expenses', value: 'training expenses' },
        { label: 'Travel Costs', value: 'travel costs' },
        { label: 'Assistance With Purchasing New Equipment And Gear', value: 'assistance with purchasing new equipment and gear' },
        { label: 'Registration And Competition Fees', value: 'registration and competition fees' },
        { label: 'Medical Expenses', value: 'medical expenses' },
        { label: 'Educational/Scholarships Financial Aid', value: 'educational/scholarships financial aid' },
        { label: 'Community Programs', value: 'community programs' },
        { label: 'Event Hosting', value: 'event hosting' },
        { label: 'Annual Grant', value: 'annual grant' },
        { label: 'Special Grant', value: 'special grant' },
        { label: 'Medical Assistance', value: 'medical assistance' }
      ]
    },
    {
      name: 'request_justification',
      label: 'Request Justification Details',
      // hint: 'Expand on why you need the'
      required: false,
    },
    {
      name: 'date_registered',
      label: 'Date registered as Non-Profit Organization(NPO)',
      type: 'date',
      showYearDropdown: true
    },

    "::Banking Information",
    {
      name: 'bank_name',
      label: '',
      options: commonBanks
    },
    {
      name: 'bank_number',
      label: 'Bank account #',
      type: 'integer'
    },
    {
      name: 'bank_account_name',
      label: 'Name on the bank account'
    },
    {
      name: 'bank_routing_number',
      label: '',
      type: 'integer'
    },
    {
      name: 'branch_information',
      label: '',
      type: 'textarea'
    },
    "::Uploads",
    {
      name: 'vendor_form_upload',
      label: 'Vendor Form',
      key: 'image.0',
      type: 'file:all',
      required: !form.new_organization
    },
    {
      name: 'budget_upload',
      label: 'Budget',
      key: 'image.1',
      type: 'file:all',
    },
    {
      name: 'request_letter_upload',
      label: 'Letter of Request',
      type: 'file:pdf',
      key: 'image.2'
    },
    {
      name: 'financial_statements_upload',
      label: 'Financial Report',
      type: 'file:all',
      key: 'image.3',
      hide: form.submitted_financials != false
    },
    {
      name: 'annual_report_upload',
      label: 'Annual Report',
      type: 'file:pdf',
      key: 'image.4'
    },
    /*{
      name: 'annual_grant_upload',
      label: 'Annual Grant',
      type: 'file:pdf',
      key: 'image.8'
    },
    {
      name: 'special_grant_upload',
      label: 'Special Grant',
      type: 'file:pdf',
      key: 'image.9'
    }, */
    {
      name: 'bank_info_upload',
      label: 'Bank Account Information and Proof of Recent Activity',
      key: 'image.5',
      type: 'file:all',
      hint: 'Card with Bank name, branch, account number and transit code or Bank statement'
    },
    {
      name: 'medical_insurance_upload',
      label: 'Proof of medical insurance (for travel)',
      key: 'image.6',
      type: 'file:all',
      //required: false
      hide: !form.request_details == 'travel'
    },
    {
      name: 'travel_proof_upload',
      label: 'Proof of travel',
      key: 'image.7',
      type: 'file:all',
      hint: 'upcoming and past 3',
      hide: !form.request_details == 'travel'

    },
    // {
    //   name: 'financial_vertification_upload',
    //   label: 'Athlete Financial Verification',
    //   key: 'image.8',
    //   type: 'file:all'
    // }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
