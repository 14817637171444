import React from 'react'
import Mask from 'react-input-mask'

import Input from './input'

export default function PhoneField (props) {
  return (
    <Mask mask='(999) 999-9999' value={props.value} onChange={props.onChange}>
      {cp => <Input {...props} />}
    </Mask>
  )
}
