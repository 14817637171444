import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'

import InputField from '@shared/form-fields/input'
import Loader from '@shared/loader'
import toastr from '@modules/toastr'

import Wizard from '@modules/form-wizard'
import range from '@helpers/range'
import SERVICE_PROPS from '@/helpers/extract-service-props'
import islands from '@constants/islands'

// const freeDocs = [
//   "Conveyance by Assent",
//   "Deed of Assent",
//   "Vesting Deed",
//   "Last Will and Testament",
// ];

const doctypes = [
  'Affidavit of Bachelorhood or Spinstership',
  'Affidavit of Beneficial Ownership',
  'Affidavit of Citizenship',
  'Affidavit of Guardianship',
  'Affidavit of Loss',
  'Affidavit of Repossession',
  'Affidavit',
  'Agreement for Sale',
  'Agreement',
  'Amendment to Condominium',
  'Assignment of Rent',
  'Bill of Sale',
  'Certificate of Tile',
  'Chattel Mortgage',
  'Conveyance by Assent',
  'Conveyance',
  'Debenture',
  'Declaration of Condominium',
  'Deed of Assent',
  'Deed of Release',
  'Deed Poll',
  'Further Charge',
  'Grant of Right of Way',
  'Guarantee',
  'Interval Ownership Deed',
  'Last Will and Testament',
  'Lien',
  'Mortgage',
  'Notice of Charge',
  'Pledge of Shares',
  'Power of Attorney',
  'Promissory Note',
  'Quit Claim Deed',
  'Release of Dower',
  'Satisfaction of Mortgage',
  'Supplemental of Mortgage',
  'Transfer of Mortgage',
  'Up Stamp of Mortgage',
  'Variation of Mortgage',
  'Vesting Deed',
  'Deeds of Acknowledgement',
  'Deeds of Exchange',
  'Deeds of Partition',
  'Deeds of Rectification',
].map(value => ({
  value,
  label: value + ' ($4/pg.)',
}))

const years = range(1900, new Date().getFullYear()).map(v => ({
  value: v + '-01-01',
  label: v,
}))

const doesNotContain =
  doctype =>
  (...indexes) =>
    indexes.map(i => doctypes[i].value).indexOf(doctype) == -1

export default class DeedsAndDocuments extends React.Component {
  state = {
    record: null,
    loading: false,
    book_number: '',
  }

  long_name = 'Deeds and Documents<br />(Certified Copies)'
  resource = '/deeds_and_documents'
  form_key = 'deeds_and_document'
  postpaid = true
  postpay_key = 'fee'

  // fee = ({ pages, back_only }) => {
  //   const base = 4;
  //   const base_limit = 10;
  //   const after_base = 2.5;

  //   if (back_only) return base;
  //   if (pages <= base_limit) return base * pages;

  //   return base * base_limit + after_base * (pages - base_limit);
  // };

  validate = form => {
    const obj = {}
    obj.pages =
      form.back_only || !form.end_page
        ? 1
        : Math.abs(form.end_page - form.start_page) + 1

    return obj
  }

  hide_buttons = {
    status: true,
    deny: true,
  }

  custom_action = {
    text: 'Set Book Number',
    icon: 'book',
    test: r => r.payment_status,
    fn: record => this.setState({ record, book_number: '' }),
  }

  fields = form => {
    const showIfNot = doesNotContain(form.instrument)

    return [
      {
        name: 'book_number',
        hide: true,
        view: true,
      },
      {
        name: 'instrument',
        label: 'Document/Instrument',
        type: 'select',
        options: doctypes,
        // tooltip: 'Instruments not labelled "Free" are $4 per page for the first 10 pages, and $2.50 for every page after',
      },
      {
        name: 'purpose',
        label: 'Application Purpose',
        options: ['Marriage', 'Birth', 'Death'],
      },
      {
        name: 'purpose_date',
        label: `Date of ${form.purpose}`,
        type: 'date',
        showYearDropdown: true,
        hide: !form.purpose,
      },
      {
        name: 'document_year',
        type: 'select',
        options: years,
        label: 'Est. Document Year',
        view: {
          value: v => v?.slice(0, 4) || 'N/A',
        },
      },
      // {
      //   name    : 'lodge_year',
      //   type    : 'select',
      //   options : years,
      //   is      : 'integer',
      //   label   : 'Est. Lodge Year',
      //   view    : {
      //     value: v => v.slice(0, 4)
      //   }
      // },
      {
        name: 'back_only',
        type: 'select:bool',
        view: false,
        label: 'Backing Sheet Only?',
      },
      {
        name: 'start_page',
        is: 'integer',
        hide: form.back_only,
      },
      {
        name: 'end_page',
        is: 'integer',
        hide: form.back_only,
        tooltip: 'Omit if you wish to only print a single page',
        required: false,
      },
      {
        name: 'pages',
        hide: true,
        view: {
          value: v => (form.back_only ? 'Backing Sheet Only' : v + ' page(s)'),
        },
      },
      {
        name: 'island',
        type: 'select',
        options: islands,
        hide: true,
      },
      {
        name: 'district',
        required: false,
        hide: showIfNot(15),
      },
      {
        name: 'subdivision',
        required: false,
        hide: showIfNot(15),
      },
      {
        name: 'block',
        required: false,
        hide: showIfNot(15),
      },
      {
        name: 'lot',
        required: false,
        hide: showIfNot(15),
      },
      {
        name: 'unit_number',
        label: 'Unit #',
        required: false,
        hide: showIfNot(15),
      },
      '::FROM',
      {
        name: 'from_party_first_name',
        label: 'First Name',
      },
      {
        name: 'from_party_middle_name',
        label: 'Middle Name',
      },
      {
        name: 'from_party_last_name',
        label: 'last Name',
      },
      '::TO',
      {
        name: 'to_party_first_name',
        label: 'First Name',
      },
      {
        name: 'to_party_middle_name',
        label: 'Middle Name',
      },
      {
        name: 'to_party_last_name',
        label: 'Last Name',
      },
    ]
  }

  closeModal = () => this.setState({ record: null })

  on = {
    input: name => ev =>
      this.setState({ [name]: ev.target.value.replace(/[^0-9a-z ]/gi, '') }),

    submit: async () => {
      this.setState({ loading: true })

      const { state, props, resource, form_key } = this
      const { history, location } = props
      const { record, book_number } = state
      const { axios } = $app

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
            application_status: true,
            book_number,
          },
        })

        if (location.state.view) {
          const view = { ...location.state?.view }
          view.application_decision = 'approved'
          view.fields[0].value = book_number

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success('Success', 'Book Number successfully set')
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to set Book Number for this application')
        return
      }

      try {
        await axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal Update - Deeds and Documents',
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
            Your Deeds and Documents application has been approved.
            Your book number has been set to <strong>${book_number}</strong>.
            <br/><br />
            Thank you for using the MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false, record: null })
    },
  }

  render() {
    const { state, on, closeModal } = this

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 480 }}
          onClick={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Enter Book Number</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='book_number'>
                  Book Number
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='book_number'
                  icon='hashtag'
                  value={state.book_number}
                  onChange={on.input('book_number')}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  required
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-round mr-2'
                aria-label='Close'
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    )
  }
}
