import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";

export default class ForeignFishing extends React.Component {
  id = "ForeignSportfishing";
  service = "foreign sportfishing";
  title = "Permit to Engage in Foreign Fishing for Sporting Purposes";
  agency = "moa";
  resource = "/foreign_sportfishings";
  form_key = "foreign_sportfishing";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "vessel_name",
      label: "Name of Vessel",
    },
    {
      name: "vessel_desc",
      label: "Descripton of Vessel",
      type: "textarea",
    },
    {
      name: "vessel_reg_numb",
      label: "Registration Number of Vessel",
      is: "integer",
    },
    {
      name: "registration_port",
      label: "Port of Registration",
      options: islands,
    },
    {
      name: "persons_on_board",
      label: "Number of Persons on board vessel",
      is: "integer",
    },
    {
      name: "fishing_gear_type",
      label: "Type of fishing gear to be used in sportsfishing",
    },
    {
      name: "fishing_gear_desc",
      label: "Description of fishing gear to be used in sportsfishing",
      type: "textarea",
    },
    {
      name: "duration_of_stay",
      hint: "In Days",
      label: "Duration of stay in the Bahamas",
      is: "integer",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
