import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelArt extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'name_of_work',
    },
    {
        name: 'island_deadline',
        label: 'Island deadline date',
        options: [
            'Abaco February 15th',
            'Acklins February 15th',
            'Andros April 1st',
            'Bimini May 1st',
            'Berry Islands May 1st',
            'Cat Island May 1st',
            'Crooked Island May 1st',
            'Eleuthera May 1st',
            'Exuma Feb 15th',
            'Grand Bahama April 1st',
            'Inagua Feb 15th',
            'New Providence March 1st',
            'Mayaguana Feb 15th',
            'San Salvador Feb 15th',
            'Rum Cay Feb 15th',
            'Ragged Island Feb 15th',
            'Long Island May 1st',
            'Other',

        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.island_deadline !='Other',
    },
    {
        name: 'division',
        options:[
            'Primary PR ($5 Single)',
            'Primary PR ($10 Group)',
            'Jr High JH ($ Single)',
            'Jr High JH ($10 Group/Ensemble)',
            'Sr. High SH ($5 Single)',
            'Sr. High SH ($10 Group/Ensemble)',
            'Community COM ($10 Single)',
            'Community COM ($20 Group/Ensemble)',
            'Pre-School PS ($5 Singles $10 Group/Ensemble)',
            'Other',
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.level !='Other',
    },
    {
        name: 'work_medium',
        label: 'Medium of Work',
        type: 'textarea',
        hint: 'Please explain in detail',
    },
    {
        name: 'school_or_ensemble',
        label:'Name of school or Ensemble',
    },
    {
        name: 'name_of_guardian',
        label: 'Name of Director/Teacher/Parent', 
    },
    {
        name: 'size_of_art',
        hint: 'inches/feet'
    },
    {
        name: 'work_description',
        type: 'textarea',
        hint: 'Maximum 250 words'
    },

    "::Uploads",
    {
        name: 'pictures_of_works',
        type: 'file:all',
        key: 'image.0'
    },
    {
        name: 'desc_of_work',
        heading: `<p>Give a description of your artwork including an introdruction, entrant name, grade, school, age and personal description of artwork</p>`
    },
    {
        name: 'description_of_artwork',
        key: 'image.1',
        type: 'file',
        hint: 'Maximum 2 minites'
        //video upload
    },
    "::SPACER",
    {
        name: 'acceptance',
        type: 'checkbox',
        use_as_acceptance: true,
        label: `
          I declare that the artwork submitted with this application represents my original work, except where I have acknowledged the ideas, words, images, or material of authors/creators.
        `
      }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
