import { Redirect, Route, Switch } from 'react-router'
import { Link, NavLink } from 'react-router-dom'

import Logout from '@pages/common/logout'
import Backdrop from '@images/backdrops/a.jpg'

import CollectionsProvider from './contexts'
import ApplicationFinder from './find-applicant'
import BatchCollection from './collect-batch'
import BatchesList from './list'
import './styles.css'

const CollectionsCentre = ({ history }) => {
  return (
    <section
      className='ccc-screen relative flex flex-col min-h-screen bg-gray-100'
      style={{ backgroundImage: `url(${Backdrop})` }}
    >
      <header className='container bg-gray-100'>
        <h1>Collections Centre</h1>

        <nav>
          <NavLink to='/collections' exact>
            <i className='fas fa-list' />
            <span>Items</span>
          </NavLink>
          <NavLink to='/collections/collect'>
            <i className='fas fa-hand-holding-medical' />
            <span>Collect</span>
          </NavLink>
          <NavLink to='/collections/find-applicant'>
            <i className='fas fa-search' />
            <span>Search</span>
          </NavLink>
          <Link to='/logout' tooltip='Sign Out'>
            <i className='fas fa-sign-out-alt' />
          </Link>
        </nav>
      </header>
      <CollectionsProvider>
        <Switch>
          <Route path='/logout' component={Logout} />
          <Route path='/collections/batches/:agency?' component={BatchesList} />
          <Route path='/collections/find-applicant' component={ApplicationFinder} />
          <Route path='/collections/collect' component={BatchCollection} />
          <Redirect to='/collections/batches' />
        </Switch>
      </CollectionsProvider>
    </section>
  )
}

export default CollectionsCentre
