import React from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import { fullName } from '@modules/form-wizard/utils'
import { useCollections } from '../contexts'

const SearchResults = ({ service, user, forms }) => {
  const { openUser } = useCollections()
  if (!user) return null

  const columns = [
    {
      name: 'View',
      width: '80px',
      cell: row => (
        <span
          data-action
          onClick={() => openUser(row)}
        >View</span>
      )
    },
    {
      name: 'Proxy',
      selector: 'proxy',
      sortable: true,
      cell: row => row.proxy ? (
        <span
          data-action
          onClick={() => openUser(row, true)}
        >{fullName(row.proxy, 'initial')}</span>
      ) : 'N/A'
    },
    {
      name: 'Service',
      selector: row => row.service_name,
      sortable: true,
      omit: service != 'all',
    },
    {
      name: 'Submission Date',
      sortable: true,
      selector: row => moment(row.created_at).format('Do MMM, YYYY'),
    },
    {
      name: 'Application Status',
      selector: row => {
        if (row.payment_status === (false || null) && row.application_decision === 'processing' && parseInt(row.payment_amount) >= 0) {
          return 'Awaiting Payment'
        } else {
          return row.application_decision.initialCaps() ?? 'Pending'
        }
      },
    },
    {
      name: 'Delivery Status',
      sortable: true,
      selector: row => row.delivery_status?.initialCaps() ?? 'N/A',
    },
    {
      name: 'Pickup Location',
      selector: row =>
        row.pickup_location
          ? `${row.pickup_sub_location}, ${row.pickup_location}`
          : 'N/A',
    },
    {
      name: 'Invoice #',
      selector: row => row.payment_invoice ?? 'N/A',
    },
    {
      name: 'Payment Status',
      sortable: true,
      selector: row => (row.payment_status ? 'Paid' : 'Unpaid'),
    },
  ]

  return (
    <article className='card p-4 md:p-5 table-responsive form-records-table'>
      <header className='flex flex-col'>
        <h4>{fullName(user)}</h4>
      </header>
      {forms?.length ? (
        <DataTable
          keyField='form_num'
          data={forms}
          columns={columns}
          noHeader
          pagination
          striped
        />
      ) : (
        <div className='my-6 text-center'>(No Records Found)</div>
      )}
    </article>
  )
}

export default SearchResults
