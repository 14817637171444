import React from 'react'
import Swal from 'sweetalert2'

import ENV from '@constants/env'
import toastr from '@modules/toastr'
import { extractKeys } from '@helpers/objects'
import * as STEPS from '../../modules/form-wizard/steps'

// import axios from 'axios'


export default class NationalSymbolPermissionRenewal extends React.Component {
  constructor(props) {
    super(props)

    const { axios, current_user: user, roles } = $app

    this.token = null
    this.any = {}
    this.resource = '/national_symbol_permissions'
    this.form_key = 'national_symbol_permission'
    this.isRenewal = true
    this.axios = axios
    this.user = user
    this.is = this.generate.permissions(roles, props.service.agency)
    this.state = this.generate.state(this.is)
  }


  generate = {
    permissions: (roles, { code, admin: adm, dispatcher: dis }) => {
      const admin = roles.includes(adm)
      const dispatcher = roles.includes(dis || code + '_dispatcher')
      const support = roles.includes('support')

      return {
        admin,
        dispatcher,
        support,
        citizen: !admin && !dispatcher && !support,
      }
    },
    state: () => ({
      tkey: 0, // Increment to force a re-render of the records table
      mode: 'citizen',
      modal: false,
      block: null,
      loading: false,
      records: [],
      banner: '',
      notes: null,
      query: null,
      query_only_paid: true,

      delivery: null,
      eligible: true,
      inProgress: true,

      metadata: { page: 1, per_page: 10 },
    }),
  }

  CRUD = action => async (form, other) => {
    this.setState(state => {
      if (action == 'read' && (!form?.refresh || form.updating)) {
        state.loading = 'applications'
      } else if (!form?.refresh) {
        state.loading = true
      }

      return state
    })

    const { state, props, axios, resource } = this
    const { hooks  } = props
    console.log(this);
    const post = this.post(action, other)

    try {
      if (hooks?.[action]) {
        const keepGoing = await hooks[action](form, post, other)

        if (keepGoing === false) {
          this.setState({ loading: false })
          return
        } else if (action == 'read' && Array.isArray(keepGoing.records)) {
          return post(keepGoing.records)
        } else if (typeof keepGoing == 'object') {
          if (form instanceof FormData) {
            for (let [k, v] of keepGoing) {
              form.append(k, v)
            }
          } else {
            Object.assign(form, keepGoing)
          }
        }
      }
    } catch (err) {
      console.error(err)
      return
    }

    try {
      switch (action) {
        case 'read':
          const rParams = extractKeys(state.metadata, 'page', 'per_page')

          if (form?.params) {
            Object.assign(rParams, form.params)
          } else if (props.read_params?.default) {
            const { values, default: d } = props.read_params
            Object.assign(rParams, values[d])
          }

          const read = await axios.get(resource, { params: rParams })

          this.setState({ metadata: read.data.records_params })
          let arr = props.r_key ? read.data[props.r_key] : read.data

          if (arr.records_by_proxy) {
            if (this.is.citizen) {
              const proxies = await this.proxy.v2.getApproved()
              this.setState({ proxies })
            }

            arr = [...arr.records_by_proxy, ...arr.records]
          }

          form.refresh && toastr.success('Applications Refreshed')
          return post(arr)

        default:
      }
    } catch (err) {
      console.error(err)
      toastr.error(
        'Error',
        {
          'read': 'Unable to fetch forms',
          'read:one': 'Unable to fetch form details',
        }[action]
      )

      this.setState({ loading: false })
    }
  }

  post = (action, other) => async data => {
    const hookname = 'post:' + action
    const { props } = this
    const {
      hooks,
    } = props

    try {
      if (hooks?.[hookname]) {
        const keepGoing = await hooks[hookname](data, other)

        if (keepGoing === false) {
          this.setState({ loading: false })
          return
        }
      }
    } catch (err) {
      console.error(err)
      return
    }

    switch (action) {
      case 'read':
        if (typeof other == 'function') other(data)
        return this.setState({
          loading: false,
          records: typeof props.filter_records == 'function'
            ? data.filter(props.filter_records)
            : data
        })
      default:
    }
  }

  proxy = {
    v1: {
      set: async (useProxy, email, show_toast = true) => {
        const { props, navigate, goToPayment } = this
        const { record, modify_proxy } = props.location.state

        if (!useProxy) {
          goToPayment(true)(record)()
          return
        }

        const { isConfirmed } = await Swal.fire({
          title: 'Confirm',
          html: `Are you sure you want to set the user with the email address <strong>${email}</strong> as your proxy for this application?`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })

        if (!isConfirmed) return
        this.setState({ loading: true })

        try {
          const { data } = await $app.axios.put('/services/set_proxy', {
            service_type: props.service.type,
            form_num: record.form_num,
            email,
          })

          if (/Sorry, no user was found/.test(data.message)) {
            toastr.warning('No User Found', data.message)
            this.setState({ loading: false })
            return
          }

          if (data.message == 'Proxy added successfully.') {
            show_toast &&
              toastr.success(
                'Success',
                'Proxy successfully added to application'
              )
            if (modify_proxy) {
              navigate('applications', null)
              this.setState({ step: STEPS.FORM })
            } else goToPayment(true)(record)()

            this.CRUD('read')({ reset: true })
          }
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Failed to set proxy user')
          this.setState({ loading: false })
        }
      },

      remove: async (record, show_toast = true) => {
        const { isConfirmed } = await Swal.fire({
          title: 'Confirm',
          text: 'Are you sure you want to remove your proxy?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Remove',
        })

        if (!isConfirmed) return
        this.setState({ loading: true })

        try {
          await $app.axios.put('/services/remove_proxy', {
            service_type: this.props.service.type,
            form_num: record.form_num,
          })

          show_toast &&
            toastr.success(
              'Success',
              'Proxy successfully removed from application'
            )
          this.CRUD('read')({ reset: true })
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Failed to remove proxy user')
          this.setState({ loading: false })
        }
      },

      modify: record => {
        this.navigate('form/proxy', {
          record,
          modify_proxy: true,
        })

        this.setState({ step: STEPS.PROXY_PICKUP })
      },
    },

    v2: {
      getApproved: async () => {
        try {
          const url = '/services/approved_proxies/' + this.props.service.type
          const { data } = await $app.axios.get(url)
          return data
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to fetch approved proxy requests')
        }
      },

      request: async ({ nib, ...child }) => {
        const { navigate } = this

        if (!nib) {
          navigate(null, {
            proxy_select: false,
            proxy: child,
          })

          this.setState({
            step: this.any?.dependencies ? STEPS.DEPENDENCIES : STEPS.FORM,
          })

          $app.setApplicant(child)
          return
        }

        try {
          this.setState({ loading: true })

          const { data } = await $app.axios.post('/services/proxy_request', {
            nib,
            service_type: this.props.service.type,
          })

          if (data.message) {
            const [heading, type] = /no user/.test(data.message)
              ? ['No User Found', 'warning']
              : ['Proxy Request Sent', 'success']

            toastr[type](heading, data.message)

            if (type == 'success') {
              this.setState({ step: STEPS.FORM })
              navigate('applications', null)
            }
          }
        } catch (err) {
          const { message } = err.response?.data || {}
          console.error(err)
          toastr.error(
            'Error',
            message || 'There was an error sending the proxy request'
          )
        } finally {
          this.setState({ loading: false })
        }
      },

      choose: async proxy => {
        try {
          const proxy_type = proxy
            ? proxy.confirmation_token
              ? 'request'
              : 'child'
            : 'none'

          await $app.axios.patch('/services/choose_proxy/', {
            id: proxy.id,
            proxy_type,
            service_type: this.props.service.type,
          })

          return true
        } catch (err) {
          console.error(err)
          return false
        }
      },
    },
  }

  validate = async form => {
    if (!form.acceptance_1 && !form.acceptance_2 && !form.acceptance_3) {
      await Swal.fire(
        'Response Missing',
        'Please select at least one of the checkboxes below Question 1.',
        'warning'
      )
      
      setTimeout(() => {
        document.querySelector('.wizard-body > .row').scrollIntoView()
      }, 100)

      return false
    }

    return true
  }

  async componentDidMount() {
    const { props } = this

    if (props.service.under_maintenance) {
      await Swal.fire(
        'Service Unavailable',
        `
          Sorry, but this service is currently under maintenance.
          Please try again later or reach out to our helpdesk toll free at
          ${ENV.HELPDESK.HTML.PHONE} or email ${ENV.HELPDESK.HTML.EMAIL}.
        `,
        'warning'
      )

      props.history.goBack()
    }

    this.CRUD('read')({ reset: true, refresh: true })
  }


  // render = () => <Wizard {...SERVICE_PROPS(this)} stacked />
  render = () => (
    <div>
    <div className="content">
      <div>{console.log(this.state.records)}</div>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-3">
            <div className="card card-post card-round text-center">
              <div className="card-img-top text-center rounded"><img src="https://api.pilot-qa.gov.bs/system/agencies/images/000/000/018/original/nat_symbol.png?1688395836" alt="Agency Thumbnail" className="card-img-top"/></div>
              <div className="card-body text-left">
                <div style={{marginBottom: "1rem"}}>
                  <h3 className="card-title text-capitalize fw-bold">Permission to Use the National Flag or Coat of Arms</h3>
                  <p className="card-text">Ministry of National Security</p>
                </div>
                <button className="btn custom-btn btn-rounded">
                  <svg className="svg-inline--fa fa-plus fa-w-14 mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                    <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                  Apply Now
                  {/* <!-- <i className="fas fa-plus mr-2"></i> --> */}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-header flex items-center" data-hide="true">
                <div className="flex space-x-4 items-center ml-auto">
                  <span className="flex-shrink-0"> </span>
                  <select className="form-control">
                    <option value="Default">Default</option>
                  </select>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive form-records-table">
                  <div className="sc-dxgOiQ gAuPhq">
                    <div className="sc-jKJlTe ktMXMg">
                      <div className="sc-bdVaJa ePDqSK rdt_Table" role="table">
                        <div className="sc-bwzfXH kjhGqe rdt_TableHead" role="rowgroup">
                          <div className="sc-htpNat hPRLgG rdt_TableHeadRow" role="row">
                            <div className="sc-bxivhb sc-ifAKCX sc-gqjmRU cPICgJ rdt_TableCol">
                              <div id="column-undefined" role="columnheader" tabIndex="0" className="sc-VigVT gBHjne rdt_TableCol_Sortable">
                                <div>View</div>
                              </div>
                            </div>
                            <div className="sc-bxivhb sc-ifAKCX sc-gqjmRU fnkVc rdt_TableCol">
                              <div id="column-created_at" role="columnheader" tabIndex="0" className="sc-VigVT hXmvvi rdt_TableCol_Sortable">
                                <div>Date</div>
                                <span className="sc-gZMcBi iAeWvj">▲</span>
                              </div>
                            </div>
                            <div className="sc-bxivhb sc-ifAKCX sc-gqjmRU iHWyql rdt_TableCol">
                              <div id="column-application_decision" role="columnheader" tabIndex="0" className="sc-VigVT jKgQlH rdt_TableCol_Sortable">
                                <div>Status</div>
                                <span className="sc-gZMcBi kBUJSp">▲</span>
                              </div>
                            </div>
                            <div className="sc-bxivhb sc-ifAKCX sc-gqjmRU iHWyql rdt_TableCol">
                              <div id="column-payment_status" role="columnheader" tabIndex="0" className="sc-VigVT jKgQlH rdt_TableCol_Sortable">
                                <div>Payment</div>
                                <span className="sc-gZMcBi kBUJSp">▲</span>
                              </div>
                            </div>
                            <div className="sc-bxivhb sc-ifAKCX sc-gqjmRU dUFrci rdt_TableCol">
                              <div id="column-undefined" role="columnheader" tabIndex="0" className="sc-VigVT gBHjne rdt_TableCol_Sortable">
                                <div>Action</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div offset="250px" className="sc-kpOJdX bIxPIp rdt_TableBody" role="rowgroup">
                          <div id="row-9" role="row" className="sc-iwsKbI fnmLPs rdt_TableRow">
                            <div id="cell-KEByZV7Yv-9" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-9" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-05-08</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-9" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">APPROVED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-9" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Paid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-9" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-17" role="row" className="sc-iwsKbI hBLkzW rdt_TableRow">
                            <div id="cell-KEByZV7Yv-17" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-17" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-06-20</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-17" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">APPROVED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-17" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Paid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-17" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-19" role="row" className="sc-iwsKbI fnmLPs rdt_TableRow">
                            <div id="cell-KEByZV7Yv-19" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-19" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-06-20</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-19" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">DENIED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-19" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Unpaid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-19" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-20" role="row" className="sc-iwsKbI hBLkzW rdt_TableRow">
                            <div id="cell-KEByZV7Yv-20" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-20" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-06-20</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-20" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">APPROVED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-20" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Paid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-20" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-21" role="row" className="sc-iwsKbI fnmLPs rdt_TableRow">
                            <div id="cell-KEByZV7Yv-21" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-21" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-06-29</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-21" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">APPROVED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-21" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Paid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-21" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-22" role="row" className="sc-iwsKbI hBLkzW rdt_TableRow">
                            <div id="cell-KEByZV7Yv-22" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-22" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-07-03</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-22" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">APPROVED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-22" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Unpaid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-22" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-23" role="row" className="sc-iwsKbI fnmLPs rdt_TableRow">
                            <div id="cell-KEByZV7Yv-23" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-23" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-07-03</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-23" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">APPROVED</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-23" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Unpaid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-23" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-24" role="row" className="sc-iwsKbI hBLkzW rdt_TableRow">
                            <div id="cell-KEByZV7Yv-24" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-24" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-07-03</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-24" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">PENDING</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-24" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Unpaid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-24" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-26" role="row" className="sc-iwsKbI fnmLPs rdt_TableRow">
                            <div id="cell-KEByZV7Yv-26" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-26" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-07-06</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-26" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">PENDING</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-26" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Unpaid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-26" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                          <div id="row-27" role="row" className="sc-iwsKbI hBLkzW rdt_TableRow">
                            <div id="cell-KEByZV7Yv-27" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje lXcXS rdt_TableCell"><span className="truncate" data-action="true">View</span></div>
                            <div id="cell-1AeRZsDz7h-27" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje jvNgqB rdt_TableCell">
                              <div data-tag="allowRowEvents">2023-07-06</div>
                            </div>
                            <div id="cell-JZ8CODA0hc-27" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">PENDING</div>
                            </div>
                            <div id="cell-ZXjJfBY4oO-27" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje knktnm rdt_TableCell">
                              <div data-tag="allowRowEvents">Unpaid</div>
                            </div>
                            <div id="cell-jwPA1h4C1S-27" role="gridcell" data-tag="allowRowEvents" className="sc-bxivhb sc-ifAKCX sc-EHOje kEbetb rdt_TableCell"><span data-action="true">Actions</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <nav className="sc-iAyFgw epTcix rdt_Pagination">
                      <span className="sc-cvbbAY sc-brqgnP djAYXR">Rows per page:</span>
                      <div className="sc-kkGfuU gOlFUC">
                        <select aria-label="Rows per page:" className="sc-kEYyzF btusjc">
                          <option defaultValue="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M7 10l5 5 5-5z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </div>
                      <span className="sc-cvbbAY sc-jWBwVP hgiyph">1-10 of 12</span>
                      <div className="sc-eHgmQL jfYpih">
                        <button id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="" className="sc-hSdWYo dpxibY">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                            <path fill="none" d="M24 24H0V0h24v24z"></path>
                          </svg>
                        </button>
                        <button id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-hSdWYo dpxibY">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                          </svg>
                        </button>
                        <button id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="false" className="sc-hSdWYo dpxibY">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                          </svg>
                        </button>
                        <button id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="false" className="sc-hSdWYo dpxibY">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                          </svg>
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="alert-banner-container">
      <div id="alert-banner">
        <span></span>
        <button>
          <svg className="svg-inline--fa fa-times-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
          </svg>
          {/* <!-- <i className="fas fa-times-circle"></i> --> */}
        </button>
      </div>
    </div>
    </div>
  )
}
