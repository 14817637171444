import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";

export default class AnglerLicenceFishing extends React.Component {
  id = "PersonalAnglerLicense";
  service = "personal angler license";
  title = "Application for Personal Angler to engage in Flats Fishing - SportsFishing";
  agency = "moa";
  resource = "/personal_angler_licenses";
  form_key = "personal_angler_license";
  free = false;
  postpaid = false;
  defaults = {
    research: false,
    sportsfishing: true,
  };
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "sportsfishing",
      label: "Purpose for Application",
      hide: true,
      view: {
        value: (v) => (v ? "Research" : "SportsFishing"),
      },
    },
    {
      name: "fishing_date_start",
      label: "Proposed Start Date of Fishing",
      type: "date",
      max_date: new Date(),
    },
    {
      name: "fishing_date_end",
      label: "Proposed End Date of Fishing",
      type: "date",
    },
    {
      name: "proposed_fishing_areas",
      label: "Proposed Fishing areas",
      options: islands,
      multi: true,
      hint: "Select ALL of the options that apply",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
