import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class NewDriverLicence extends React.Component {
  long_name = "New Driver's Licence<br />(Non Blue Licence Holders)"
  resource  = '/new_driver_licenses'
  form_key  = 'new_driver_license'

  fee = ({ length }) => ({
    3: 60,
    1: 20,
  })[length]

  fields = () => [
    {
      name: 'passport_number',
      long: true,
    }, {
      name: 'length',
      long: true,
      label: 'Licence Period',
      options: [
        { label: '1 Year', value: 1 },
        { label: '3 Years', value: 3 },
      ],
    }, {
      name: 'type_of_license',
      long: true,
      label: 'Licence Type',
      options: [
        { value: 'A', label: 'A - Tractor / Other Heavy Motor Vehicle' },
        { value: 'B', label: 'B - Commercial Vehicle' },
        { value: 'C', label: 'C - Private Motor Vehicle' },
        { value: 'D', label: 'D - Motorcycle with/without sidecar' },
      ],
    }, {
      name: 'drivers_license_held',
      type: 'select:bool',
      long: true,
    }, {
      name: 'disqualified',
      type: 'select:bool',
      label: 'Are you disqualified by any court for holding or obtaining a licence?',
      long: true,
      view: {
        label: 'Disqualified Licence?',
      }
    }, {
      name: 'epilepsy',
      type: 'select:bool',
      label: 'Do you suffer from epilepsy, or from sudden attacks of disabling giddiness or fainting?',
      long: true,
      view: {
        label: 'Epilepsy?',
      }
    }, {
      name: 'distance',
      type: 'select:bool',
      label: 'Can you read at a distance of 25 yards in good daylight (with glasses if worn) a car number plate containing six letters and figures?',
      long: true,
      view: {
        label: 'Can read at 25 yards?',
      }
    }, {
      name: 'movement',
      type: 'select:bool',
      label: 'Are you without either hand or foot or are you suffering from any defect in movement, control, or muscular power, of either arm or leg?',
      long: true,
      view: {
        label: 'Disabled?',
      }
    }, {
      name: 'disease',
      type: 'select:bool',
      label: 'Are you suffering from any other disease, mental or physical or disability which would be likely to cause the driving by you of a motor vehicle to be a source of danger to the public?',
      long: true,
      view: {
        label: 'Other Illnesses?',
      }
    }, {
      name: 'offense',
      type: 'select:bool',
      label: 'Do you understand that it is an offense to use a motor vehicle on a road unless covered by Insurance against third party risks?',
      long: true,
      view: {
        label: 'Understands that no insurance is an offense?',
      }
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}