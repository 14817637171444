import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'

export default class ValidationAuthorisation extends React.Component {
  resource = '/validation_authorisations'
  form_key = 'validation_authorisation'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    {
      name: 'f_name',
      label: 'First Name',
      hide: form.i_am_applicant != false
    },
    {
      name: 'm_name',
      label: 'Middle Name',
      hide: form.i_am_applicant != false
    },
    {
      name: 'l_name',
      label: 'Last Name',
      hide: form.i_am_applicant != false
    },
    {
      name: 'address',
      label: 'Permanent Address',
      hint: 'Street or PO Box Number',
      hide: form.i_am_applicant != false
    },
    {
      name: 'telephone',
      type: 'phone',
      hide: form.i_am_applicant != false
    },
    {
      name: 'city'
    },
    {
      name: 'state',
      label: 'State/Providence'
    },
    {
      name: 'mail_code'
    },
    {
      name: 'country',
      options: countries
    },
    {
      name: 'birth_date',
      label: 'Date of Birth',
      type: 'date',
      showYearDropdown: true
    },
    {
      name: 'birth_place',
      label: 'Birth place',
      options: countries
    },
    {
      name: 'nationality',
      options: nationalities
    },
    {
      name: 'speak_english',
      label: 'Do you speak and understand English?',
      type: 'select:bool'
    },
    {
      name: 'height',
      hint: 'inches'
    },
    {
      name: 'weight',
      hint: 'lbs'
    },
    {
      name: 'hair'
    },
    {
      name: 'eyes'
    },
    {
      name: 'sex',
      options: ['male', 'female']
    },
    {
      name: 'email',
      label: 'E-mail address',
      type: 'email'
    },
    "::E-Foreign Medical Information",
    {
      name: 'cert_class',
      label: 'Class of certificate'
    },
    {
      name: 'state_issue',
      label: 'State of Issue'
    },
    {
      name: 'date_issued',
      type: 'date',
      showYearDropdown: true
    },
    {
      name: 'rating_held',
      label: 'Rating(s) Held',
      columns: ['rating']
    },
    {
      name: 'flight_hours',
      label: 'Total Flight Hours',
      type: 'integer'
    },
    {
      name: 'pic_hours',
      label: 'Total Pic Hours',
      type: 'integer'
    },
    {
      name: 'XC_hours',
      label: 'Total X-C Hours',
      type: 'integer'
    },
    {
      name: 'night_hours',
      label: 'Total Night Hours',
      type: 'integer'
    },
    {
      name: 'instrument_pic'
    },
    {
      name: 'hrs_type',
      label: 'Total HRS Type'
    },
    "::CAA-B License Information",
    {
      name: 'license_number'
    },
    {
      name: 'date_issued_two',
      label: 'Date Issued',
      type: 'date',
      showYearDropdown: true
    },
    {
      name: 'rating_held_two',
      label: 'Rating(s) Held'
    },
    "::Foreign Medical Information",
    {
      name: 'class_cert',
      label: 'Class of Certificate'
    },
    {
      name: 'state',
      label: 'State of Issue'
    },
    {
      name: 'issue_date',
      label: 'Date of Issue',
      date: 'type',
      showYearDropdown: true
    },
    {
      name: 'medical_examiner'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
