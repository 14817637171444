import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import countries from "@constants/countries";
import islands from "@/constants/islands";
import { AnimalRequestDetails } from "./_item-imports";

export default class FreshwaterFish extends React.Component {
  id = "FreshwaterFishImportPermit";
  service = "freshwater fish import permit";
  title = "Application for Permit to import Freshwater Fish";
  agency = "moa";
  resource = "/freshwater_fish_import_permits";
  form_key = "freshwater_fish_import_permit";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "bl_number",
      label: "Registered Business Licence Number",
      is: "integer",
    },
    {
      name: "company_name",
      label: "Company Name",
    },
    "::Consignee",
    {
      name: "consignee_first_name",
      label: "First Name",
    },
    {
      name: "consignee_middle_name",
      label: "Middle Name",
    },
    {
      name: "consignee_last_name",
      label: "Last Name",
    },
    "::SPACER",
    {
      name: "supplier_name",
      label: "Name of Supplier",
    },
    "::Supplier Contact",
    {
      name: "supplier_contact_first_name",
      label: "First Name",
    },
    {
      name: "supplier_contact_middle_name",
      label: "Middle Name",
    },
    {
      name: "supplier_contact_last_name",
      label: "Last Name",
    },
    "::Supplier Address",
    {
      name: "supplier_address",
      label: "Address",
    },
    {
      name: "supplier_address_zip",
      label: "Zip Code",
    },
    {
      name: "supplier_address_state",
      label: "State",
    },
    {
      name: "supplier_address_country",
      label: "Country",
      options: countries,
    },
    {
      name: "supplier_address_city",
      label: "City",
    },
    "::Reason for Import Request",
    {
      name: "pet",
      label: "Pet",
      type: "checkbox",
      required: false,
    },
    {
      name: "education",
      label: "Education",
      type: "checkbox",
      required: false,
    },
    {
      name: "commercial",
      label: "Commercial",
      type: "checkbox",
      required: false,
    },
    {
      name: "other_purpose",
      label: "Other",
      type: "checkbox",
      required: false,
    },
    {
      name: "purpose_other",
      label: "Other Purpose",
      hide: !form.other_purpose,
    },
    "::SPACER",
    {
      name: "shipper_name",
      label: "Carrier/Shipper Name",
    },
    {
      name: "date_of_arrival",
      label: "Date of Arrival in the Bahamas",
      type: "date",
      maxDate: new Date(),
    },
    {
      name: "port_of_entry",
      label: "Port of Entry",
      options: ["Dock", "Airport"],
    },
    "::Port of Entry",
    "name",
    {
      name: "island",
      options: islands,
    },
    {
      name: "animal_request_details",
      columns: AnimalRequestDetails,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
