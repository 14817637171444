import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'

export default class AircraftRegistration extends React.Component {
  resource = '/aircraft_registrations'
  form_key = 'aircraft_registration'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Details of Aircraft",
    {
        name: 'reg_mark',
        label:'Registration Mark',
        columns: ['previous', 'allocated']
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Manufacturer of Aircraft'
    },
    {
        name: 'mtom',
        label: 'MTOM(kg)' 
    },
    "::Details of Aircraft Owner",
    {
        heading: <p>. If the aircraft is to be registered under a company, state:</p>
    },
    {
        name: 'comapny_name',
        label: 'Name of Comapny',
        required: false
    },
    {
        name: 'reg_address',
        label: 'Registered Address'
    },
    {
        name: 'country',
        label: 'Country of Incorporation',
        options: countries
    },
    {
        name: 'contact_name'
    },
    {
        name: 'tele_num',
        label: 'Telephone No.',
        type: 'phone'
    },
    {
        name: 'reg_num',
        label: 'Registration No.'
    },
    {
        name: 'email',
        type: 'email'
    },
    {
        name: 'fax_num',
        label: 'Fax No.'
    },
    {
        heading: <p> If the aircraft is to be registered under an individual, state:</p>
    },
    {
        name: 'individual_name',
        label: 'Name of Individual'
    },
    {
        name: 'perm_address',
        label: 'Permenant Address'
    },
    {
        name: 'nationality',
        options: nationalities
    },
    {
        name: 'contact_name'
    },
    {
        name: 'email_two',
        type: 'email'
    },
    {
        name: 'fax_add',
        label: 'Fax No.'
    },
    {
        heading: <p>Correspondence address if different to 2a or 2b above, state:</p>
    },
    {
        name: 'cor_address',
        label: 'Correspondence address'
    },
    {
        name: 'tele_num',
        label: 'Telephone No.',
        type: 'phone'
    },
    {
        name: 'reg_num',
        label: 'Registration No.'
    },
    {
        name: 'email',
        type: 'email'
    },
    {
        name: 'fax_num',
        label: 'Fax No.'
    },
    "::Details of the Aircraft Operator",
    {
        name: 'name_air',
        label: 'Name',
      },
      {
        name: 'op_address',
        label: 'Address',
      },
      {
        name: 'op_contact',
        label: 'Contact Name'
      },
      {
        name: 'op_telephone',
        type: 'phone',
      },
      {
        name: 'op_email',
        type: 'email',
      },
      {
        name: 'op_fax',
        label: 'Fax No.'
      },
      "::Details of Operation",
      {
        name:'op_type',
        label: 'Type of Operation',
        options: [
            'CAR OPS 1',
            'CAR OPS 2A',
            'CAR OPS 2H',
            'CAR OPS 3',
            'CAR OPS 4',
            'ICAO 83bis Agreement'

        ]
      },
      {
        name: 'storage_pro',
        label: 'Is the aircraft under a Storage Programme',
        type: 'select:bool'
      },
      {
        name: 'air_base',
        label: 'Aircraft home base'
      }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
