import Swal from 'sweetalert2'

import StateStore from '@helpers/state-store'
import { fullName } from '@modules/form-wizard/utils'

import Loader from '@shared/loader'
import mapServiceName from '@constants/maps.services'
import SearchResults from './components/search-results'

import { AVAILABLE_SERVICES } from './data'
const allServiceTypes = Object.entries(AVAILABLE_SERVICES)
  .reduce((list, [, items]) => [...list, ...Object.keys(items)], [])

const Field = ({ label, children }) => (
  <label className='flex items-center mb-0'>
    <span className='font-semibold w-20 pr-2'>{label}</span>
    {children}
  </label>
)

const ApplicationFinder = () => {
  const [state, setState] = StateStore({
    nib: '',
    service: 'all',
    loading: false,
    results: {},
  })

  const search = async service => {
    const { status, data } = await $app.axios.get('/services/search/' + state.nib, {
      validateStatus: status => status <= 400,
      params: { service },
    })

    if (status == 400) return { status }
    const { user, forms } = data
    const service_name = mapServiceName(service)
    const username = fullName(user, 'initial')

    return {
      user,
      forms: forms.map(f => Object.assign(f, {
        username,
        user,
        service_name,
        service_type: service,
        collectable: f.delivery_status == 'ready for pickup'
      }))
    }
  }

  const searchAll = async () => {
    const forms = []
    const user = {}

    for (let s of allServiceTypes) {
      const { status, user: u, forms: f } = await search(s)
      
      if (status) return { status }
      !u.id && Object.assign(user, u)
      
      if (!f?.length) continue
      forms.push(...f)
    }

    return { user, forms }
  }

  const getResults = async ev => {
    setState({ loading: true, results: {} })
    ev?.preventDefault()

    try {
      const { status, forms, user, errors } = state.service == 'all'
        ? await searchAll()
        : await search(state.service)

      if (status == 400) {
        return Swal.fire(
          'An Error Occurred',
          errors?.toString(),
          'error'
        )
      }

      if (!forms?.length) {
        return Swal.fire(
          'No Applications Found',
          `
            There were no applications for the selected service found for
            <strong>${fullName(user)}</strong>
          `,
          'warning'
        )
      }

      setState({
        results: { forms, user }
      })
    } catch (error) {
      setState({ error })
    } finally {
      setState({ loading: false })
    }
  }

  return (
    <section className='container'>
      <Loader loading={state.loading} message='Searching ...' />
      <h2>Find User Application</h2>
      <form
        onSubmit={getResults}
        className='inline-flex flex-col lg:flex-row items-center lg:items-stretch w-full lg:w-auto space-x-8 mb-4 bg-white p-3 rounded-lg shadow'
      >
        <Field label='NIB #'>
          <input
            className='form-control'
            value={state.nib}
            onChange={ev => setState({ nib: ev.target.value })}
            />
        </Field>
        <Field label='Service'>
          <select
            className='form-control'
            value={state.service}
            onChange={ev => setState({ service: ev.target.value })}
          >
            <option value='all'>All Services</option>
            {Object.entries(AVAILABLE_SERVICES).map(([group, links]) => (
              <optgroup label={group} key={group}>
                {Object.entries(links).map(([key, label]) => (
                  <option key={key} value={key}>{label}</option>
                ))}
              </optgroup>
            ))}
          </select>
        </Field>
        <button
          type='submit'
          className='border-none rounded-lg bg-primary hover:bg-blue-500 text-white font-semibold px-4'
        >
          Search
        </button>
      </form>
      <SearchResults service={state.service} {...state.results} />
    </section>
  )
}

export default ApplicationFinder
