import React from 'react'
import moment from 'moment'

import Currency from '@wrappers/currency'
import RefreshTable from '@modules/polling-table'
import { extractKeys } from '@helpers/objects'
import { Link } from 'react-router-dom'

export default class ApprovedInspections extends React.Component {
  state = {
    modal: false,
    record: {},
  }

  today = moment()

  title = `Approved Inspections (${this.today.format('Do MMMM, YYYY')})`
  interval = 30 * 1000
  endpoint = '/individuals'
  filter = form =>
    form.verdict &&
    form.application_decision != 'approved' &&
    this.today.isSame(form.updated_at, 'day')

  columns = [
    {
      name: 'Name',
      selector: 'user.name',
      format: r => `${r.user.last_name}, ${r.user.first_name}`.capitalize(),
    },
    {
      name: 'Plate #',
      selector: 'plate_number',
      width: '160px',
    },
    {
      name: 'Time',
      selector: 'updated_at',
      sortable: true,
      width: '160px',
      format: ({ updated_at }) => moment(updated_at).format('h:mm:ss A'),
      sortFunction: (a, b) =>
        new Date(a.updated_at).getTime() > new Date(b.updated_at).getTime(),
    },
    {
      name: 'Vehicle',
      selector: 'vehicle_model',
      format: r =>
        ['year', 'make', 'model'].map(p => r[p + '_of_vehicle']).join(' '),
    },
    {
      name: 'AUTH CODE',
      selector: 'auth_dcode',
      width: '160px',
    },
    {
      name: 'Amount',
      selector: 'payment_amount',
      width: '180px',
      format: r => <Currency value={r.payment_amount} />,
    },
    {
      name: 'Inspector',
      selector: 'inspector',
      format: r =>
        r.inspector
          ? `${r.inspector.first_name} ${r.inspector.last_name}`.capitalize()
          : 'N/A',
    },
  ]

  render = () => {
    const tprops = extractKeys(
      this,
      'title',
      'interval',
      'columns',
      'filter',
      'endpoint'
    )

    return (
      <React.Fragment>
        <div style={{ margin: '1rem 2rem 0', textAlign: 'right' }}>
          <Link to='/logout'>
            <i
              className='fas fa-sign-out-alt'
              style={{ transform: 'scale(1.35)' }}
            ></i>
          </Link>
        </div>
        <RefreshTable {...tprops} />
      </React.Fragment>
    )
  }
}
