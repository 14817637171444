import React from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'
import QR from 'react-qr-reader'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'
import { Map, Marker } from 'google-maps-react'

import styles from './courier.styles'
import TextField from '@shared/form-fields/textarea'
import SelectField from '@shared/form-fields/select'
import Loader from '@shared/loader'
import withGoogleMaps from '@wrappers/gmaps'
import { extractKeys } from '@helpers/objects'
import toastr from '@modules/toastr'
import { getDirections, getPosition } from '@modules/geo'

import { getAgencyLocations } from '@actions/agencies'

import '@styles/delivery-scanner.css'

const STEPS = {
  READY: 'READY',
  FORM: 'FORM',
  FINISH: 'FINISH',
  ROUTE: 'ROUTE',
  ERROR: 'ERROR',
}

const STATUSES = ['In Transit', 'Running Late']

class Courier extends React.Component {
  state = {
    delivery: null,
    loading: false,
    step: STEPS.READY,
    error: null,
    position: null,
    location: [25.04616, -77.35152],
    status: '',
    note: '',
    nonce: 1,
    code: '',
  }

  async componentDidMount() {
    this.map = React.createRef()

    try {
      const position = await getPosition()

      this.setState(
        position ? { position } : { error: 'Geolocation API Unavailable' }
      )
    } catch (err) {
      console.error(err)
    }

    const { qr_code } = this.props.match.params
    qr_code && this.on.scan(qr_code)
  }

  on = {
    scan: async code => {
      if (!code) return
      const { on, getDelivery } = this

      if (!/^[0-9a-z_-]+$/i.test(code?.trim())) {
        return on.error('Invalid QR code')
      }

      this.setState({ loading: true })
      const delivery = await getDelivery(code)

      if (typeof delivery == 'string') {
        this.on.error(delivery)
        return
      }

      this.setState({
        code,
        delivery,
        loading: false,
        step: STEPS.FORM,
        nonce: this.state.nonce + 1,
      })
    },

    collect: async () => {
      this.setState({ loading: true, step: STEPS.ROUTE })

      const { state, props, on, map } = this
      const [latitude, longitude] = state.location
      const coords = await getPosition()
      let travel_time = 40

      try {
        travel_time = await getDirections(
          map.current,
          props.google.maps,
          coords,
          { latitude, longitude }
        )
      } catch (err) {
        toastr.error(
          'Google Maps Unavailable',
          'Unable to get directions and ETA'
        )

        this.setState({ step: STEPS.FORM })
      }

      try {
        await $app.axios.patch(`/deliveries/${state.code}/collected`, {
          travel_time,
          location: `${coords.latitude},${coords.longitude}`,
        })

        await Swal.fire(
          'Batch Collected',
          'Batch successfully collected',
          'success'
        )
      } catch (err) {
        on.error('Unable to colect package', err)
      }

      this.setState({ travel_time, loading: false })
    },

    update: async () => {
      this.setState({ loading: true })

      const { state, on } = this
      const { code, status, note } = state

      try {
        const coords = await getPosition()

        await $app.axios.post(`/deliveries/${code}/status_update`, {
          delivery_status: {
            delivery_status: status,
            delivery_location: `${coords.latitude},${coords.longitude}`,
            delivery_note: note,
          },
        })

        await Swal.fire(
          'Batch Updated',
          'Successfully updated status',
          'success'
        )

        this.setState({ loading: false })
      } catch (err) {
        on.error('Unable to update delivery status', err)
      }
    },

    input: name => ev => this.setState({ [name]: ev.target.value }),

    select: name => v => v.length && this.setState({ [name]: v[0].value }),

    reset: () =>
      this.setState({
        step: STEPS.READY,
        code: null,
        delivery: null,
        travel_time: null,
      }),

    error: (message = '', err) => {
      Swal.fire('Error', message, 'error').then(this.on.reset)
      err && console.error(err)
      this.setState({ loading: false })
    },
  }

  goto = step => () => this.setState({ step })

  getDelivery = async code => {
    try {
      const { data } = await $app.axios.get('/deliveries/' + code)

      if (typeof data.delivery == 'string') {
        return data.delivery
      }

      if (!data.delivery.submitted) {
        return 'The delivery found is not yet closed.'
      }

      return {
        ...data.delivery,
        latest_status: data.statuses.pop(),
      }
    } catch (err) {
      console.error(err)
      return 'Unable to fetch delivery details'
    }
  }

  flattenLocations = locations => {
    if (!locations?.length) return null

    const list = locations
      ?.reduce((list, curr) => [...list, ...curr.locations], [])
      .filter(l => l.island == this.user.island)

    const __default = list.findIndex(l => /collections/i.test(l.street_address))
    __default > -1 && list.unshift(...list.splice(__default, 1))

    return list
  }

  generateForm = ({ name, latest_status, collected }) => {
    const { state, on } = this
    let form, submit

    if (collected) {
      const statuses = STATUSES.map(s => ({ value: s, label: s }))
      submit = on.update
      form = (
        <React.Fragment>
          <div className='form-group'>
            <label htmlFor='status'>Please select a status</label>
            <SelectField
              className='form-control text-left'
              options={statuses}
              onChange={on.select('status')}
              value={state.status}
              validators={['required']}
              errorMessages={['Please select a status']}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='status'>Additional Note (Optional)</label>
            <TextField
              key={state.nonce}
              className='form-control'
              value={state.note}
              onChange={on.input('note')}
              maxLength='255'
            />
          </div>
          <br />
          <input
            className='btn custom-btn w-100'
            type='submit'
            value='Update Status'
          />
        </React.Fragment>
      )
    } else {
      submit = on.collect
      form = (
        <React.Fragment>
          <div className='form-group'>
            <label htmlFor='status'>Additional Note (Optional)</label>
            <TextField
              key={state.nonce}
              className='form-control'
              value={state.note}
              onChange={on.input('note')}
              maxLength='255'
            />
          </div>
          <br />
          <input
            className='btn custom-btn w-100'
            type='submit'
            value='Collect Package'
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <article className='my-0 mx-auto text-center'>
          <h5>{name}</h5>
          <strong className='text-capitalize'>
            {latest_status.delivery_status} @{' '}
            {moment(latest_status.updated_at).format('Do MMM. hh:mm A')}
          </strong>
        </article>
        <br />
        <ValidatorForm onSubmit={submit}>{form}</ValidatorForm>
      </React.Fragment>
    )
  }

  render() {
    const { state, props, map, on, goto, generateForm } = this
    const { delivery, position, travel_time, step, loading } = state

    let body = null

    const currentPos = {
      lat: +position?.latitude,
      lng: +position?.longitude,
    }

    const mconfig = {
      google: props.google,
      ref: map,
      zoom: 15,
      initialCenter: currentPos,
    }

    switch (step) {
      case STEPS.READY:
        body = (
          <div className='form-group'>
            <label className='mb-5 text-center w-100'>
              Please scan the QR code for the deliverable batch of documents
            </label>
            <QR
              style={styles.fullWidth}
              delay={1000}
              onError={on.error}
              onScan={on.scan}
            />
            <br />
            <button className='btn btn-danger w-100' onClick={goto(STEPS.FORM)}>
              Back
            </button>
          </div>
        )
        break

      case STEPS.FORM:
        body = (
          <React.Fragment>
            {generateForm(delivery)}
            <div id='map-container' className='pt-8'>
              <Map {...mconfig}>
                <Marker
                  key='current_position'
                  name='Current Position'
                  position={currentPos}
                />
              </Map>
            </div>
          </React.Fragment>
        )
        break

      case STEPS.ROUTE:
        body = (
          <React.Fragment>
            <div className='my-0 mx-auto pb-8 text-center'>
              <h4>Package Collected</h4>
              <strong>Estimated Travel Time: {travel_time} Minutes</strong>
              <br />
              <strong>
                Estimated Arrival Time:{' '}
                {moment().add(travel_time, 'minutes').format('h:mm A')}
              </strong>
            </div>
            <div id='map-container'>
              <Map {...mconfig} />
            </div>
            <div className='text-center mb-8'>
              <button
                className='btn custom-btn w-75'
                onClick={goto(STEPS.READY)}
              >
                OK
              </button>
            </div>
          </React.Fragment>
        )
        break

      default:
    }

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <section
          id='delivery-code-scanner'
          style={styles.container(step == STEPS.ROUTE)}
        >
          <Link to='/logout' className='logout-button' style={styles.logout}>
            <i className='fas fa fa-sign-out-alt' aria-hidden='true'></i>
          </Link>
          {body}
        </section>
      </React.Fragment>
    )
  }
}

export default withGoogleMaps(
  connect(state => extractKeys(state.agencies, 'locations'), {
    getAgencyLocations,
  })(Courier)
)
