import React, { useState, useEffect } from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'

import Swal from '@sweetalert'
import Fields from '@shared/form-fields'
import mapServiceName from '@constants/maps.services'
import STRINGS from '@constants/strings'

const bool = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const findSelected = (options, target) => [
  options.find(o => o.value === target) || {},
]

const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation row'>
    <label
      htmlFor={name}
      className='col-lg-6 col-md-6 col-sm-6 text-right large-label'
      style={{ whiteSpace: 'normal'}}
    >
      {label}
      {required !== false ? <span className='required-label'>*</span> : null}
    </label>
    <div>
      {children}
    </div>
  </div>
)

export default function ProxyForm ({ form, editing, service, submit, goBack }) {
  const [email, setEmail] = useState('')
  const [use, setUse] = useState(editing)
  const [key, setKey] = useState(0)
  const [info, setInfo] = useState(false)

  const enforceAcceptance = async val => {
    if (val && use) return
    if (val === undefined) return
    
    if (val) {
      const res = await Swal.fire({
        icon: 'info',
        title: 'Disclaimer',
        html: (
          <div>
            Before confirming your choice, please carefully read and agree to the rules for designating a proxy.&nbsp;
            <span className='text-blue-600 cursor-pointer' onClick={openTerms}>Click here</span>
          </div>
        ),
        confirmButtonText: 'I Accept',
        showCancelButton: true,
      })

      if (res.isConfirmed) {
        setUse(true)
        setKey(-1)
      } else if (editing) {
        goBack()
      } else {
        setKey(key + 1)
      }
    } else setUse(val)
  }

  useEffect(() => {
    if (editing && key != -1) enforceAcceptance(true)
  }, [editing, key]) // eslint-disable-line

  const openTerms = () => {
    Swal.close()
    setInfo(true)
  }

  const returnToFirst = () => {
    setInfo(false)
    enforceAcceptance(true)
  }

  const input = use ? (
    <Field name='email' label="Please enter your proxy's email address">
      <Fields.Input
        name='email'
        value={email}
        onChange={ev => setEmail(ev.target.value)}
        className='form-control'
        validators={['required']}
        errorMessages={["Please answer 'Yes' or 'No'"]}
      />
    </Field>
  ) : null

  const select = editing ? null : (
    <Field
      name='use'
      label={`Do you want to allow somebody else to pick up your ${mapServiceName(service)}?`}
    >
      <Fields.Select
        key={key}
        name='use'
        values={findSelected(bool, use)}
        value={use}
        options={bool}
        onChange={v => enforceAcceptance(v[0]?.value)}
        className='form-control'
        validators={['required']}
        errorMessages={['Please select "Yes" or "No"']}
        required
      />
    </Field>
  )

  return (
    <React.Fragment>
      <ValidatorForm id={form} onSubmit={() => submit(use, email)} className='form-wizard__form'>
        {select}
        {input}
      </ValidatorForm>
      <Modal open={info} onClose={returnToFirst} center>
        <article style={{ width: 960, maxWidth: '100%' }}>
          <div className="modal-header">
            <h5 className="modal-title">Proxy Details</h5>
          </div>
          <div className='modal-body overflow-y-auto' style={{ maxHeight: '60vh' }}>
            <strong className='block mb-3'>PROXY RULES</strong>
            <p>{STRINGS.INFO.PROXY_RULES}</p>
          </div>
          <div className='modal-footer'>
            <button className='btn custom-btn' onClick={returnToFirst}>Okay</button>
          </div>
        </article>
      </Modal>
    </React.Fragment>
  )
}
