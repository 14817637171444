import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class PlumbingPermit extends React.Component {
  resource = '/plumbing_permits'
  form_key = 'plumbing_permit'
  defaults = {}

  // Delete lines as needed
  has_appointment = true
  // fee           = 99
  free          = false
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    
    "::Property Owner Details",
    {
      name: 'property_owner_first_name',
      label: 'First Name',
    },
    {
      name: 'property_owner_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'property_owner_last_name',
      label: 'Last Name',
    },
    {
      name: 'property_address',
      label: 'Property Address',
    },
    {
      name: 'building_permit_numb',
      label: 'Building permit No.',
      type: 'integer'
    },

    "::SPACER",
    "::The Number of proposed fixtures as follows",

    {
      name: 'bathtubs',
      label: 'Bathtubs with or without shower #',
      type: 'integer',
    },
    {
      name: 'showers',
      label: 'Showers #',
      type: 'integer',
    },
    {
      name: 'water_closets',
      label: 'Water Closets #',
      type: 'integer',
    },
    {
      name: 'lavatories',
      label: 'Lavatories #',
      type: 'integer',
    },
    {
      name: 'urinals',
      label: 'Urinals #',
      type: 'integer',
    },
    {
      name: 'bidets',
      label: 'Bidets #',
      type: 'integer',
    },
    {
      name: 'slop_kitchens',
      label: 'Slop Kitchens #',
      type: 'integer',
    },
    {
      name: 'kitchen_sinks',
      label: 'Kitchen Sinks #',
      type: 'integer',
    },
    {
      name: 'floor_drains',
      label: 'Floor Drains #',
      type: 'integer',
    },
    {
      name: 'washing_machines',
      label: 'Washing Machines #',
      type: 'integer',
    },
    {
      name: 'laundry_trays',
      label: 'Laundry Trays #',
      type: 'integer',
    },
    {
      name: 'miscellaneous',
      label: 'Miscellaneous #',
      type: 'integer',
    },

    "::Connection To:",
    {
      name: 'septic_tank_connected',
      label: 'Is the Septic Tank Connected?',
      type: 'select:bool',
    },
    {
      name: 'public_sewer_connected',
      label: 'Is there a public sewer connection?',
      type: 'select:bool',
    },
    {
      name: 'sewer_package_system_connected',
      label: 'Is there a Sewer Package System or Private Sewer connection?',
      type: 'select:bool',
    },
    {
      name: 'permit_number',
      // type: 'integer',
    },
    {
      name: 'plumber_number',
      // type: 'integer',
    },
    {
      name: 'new_connection',
      type: 'select:bool',
    },
    {
      name: 'additional_work',
      type: 'textarea',
    },
    {
      name: 'removal_of_fixtures',
      type: 'textarea',
    },
    {
      name: 'alterations',
      type: 'textarea',
    },

    // "::Uploads",
    // {
    //   name: 'sanitation_certification',
    //   type: 'file:all',
    //   required: false,
    // },

    "::SPACER",
    {
      name: 'declare',
      type: 'checkbox',
      use_as_acceptance: true,
      label: `
      I/we agree to employ a LICENSED MASTER/JOURNEYMAN PLUMBER, as applicable, and submit a certificate from him/her to the effect that the rules and regulations governing the use of sanitation, have been or will be complied with before the fixtures are put to use for which they are intended.
      `
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
