import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import InputField from '@shared/form-fields/input'
import DateField from '@shared/form-fields/date'
import Loader from '@shared/loader'
import toastr from '@modules/toastr'

import { getCurrentStage } from './_marine-utility'
import { hull_types } from './duty-free'
import passportPhotoExample from '@helpers/passport-photo-example'
import SERVICE_PROPS from '@helpers/extract-service-props'
import ENV from '@constants/env'

export default class CommercialFishing extends React.Component {
  state = {
    permit_numb: '',
    permit_expiry_date: '',
    record: null,
    loading: false,
    backspace: false,
  }

  long_name = 'Commercial Fishing'
  resource = '/commercial_fishings'
  form_key = 'commercial_fishing'
  free = false
  postpaid = false
  defaults = {}
  
  skip_location = true
  hide_buttons = {
    status: true,
    deny: true,
  }
  custom_acceptance = false

  // fee = 11.2
  fee = (form) => {
    const vesselLength = parseInt(form.overall_length);
    if(vesselLength <= 39) {
      return 110.00
    }
    else if(vesselLength > 39 && vesselLength < 79) {
      return 220.00
    } else {
      return 275.00
    }
  }

  validate = null

  foremost = {
    type: 'select:bool',
    label: `
      Are you a commercial fisherman with a port licence?
    `,
    test: v =>
      v || 'Sorry, but this application is only for commercial fishermen',
  }

  columns = props => [
    {
      name: 'Current Stage',
      selector: 'application_decision',
      width: '300px',
      cell: r => <span>{getCurrentStage(r) || 'N/A'}</span>,
      searchable: r => getCurrentStage(r),
    },
  ]
  // filter_records = r => !r.renewal

  // custom_action = {
  //   text: 'Set Details',
  //   icon: 'mouse-pointer',
  //   test: r => r.payment_status,
  //   fn: record => this.setState({ record }),
  // }

  custom_actions = [
    {
      text: 'Submit to Director',
      icon: 'id-badge',
      roles: ['officer'],
      test: r => r.payment_status && r.application_decision == 'pending',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to submit?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and the accompanying attachments, and are ready to submit it to a Director/Approved Signatories. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            const { value: license_numb } = await Swal.fire({
              title: "Please enter the Client's Permit Number",
              input: 'text',
              inputLabel: 'Permit Number',
              inputPlaceholder: 'Permit Number',
            })
            if (license_numb) {
              await this.submitToDirector(record, license_numb)
            }
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['director'],
      test: r => r.payment_status && (r.application_decision == 'processing' || r.application_decision == 'officer viewed'),
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.approve(record)
          }
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]

  submitToDirector = async (record, license_numb) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          license_numb,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'officer viewed'
        view.license_numb = license_numb

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval by the Director/Approved Signatories.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          license_numb: record?.license_numb,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - Commercial Fishing Permit',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Congratulations! Your application for a new Commercial Fishing Permit has been approved.
            <br/>
            Your permit number is as follows: ${record?.license_numb}
            <br/>
            Thank you for using the MyGateway Portal.
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
      try {
        const { data } = await $app.axios.get('/services/all_paid_forms', {
          params: { service_type: props.service.type },
        })
        const updated_record = data.paid_forms.filter(
          d => d.id === record?.id
        )[0]
        await $app.axios.post('/emails', {
          email: record.user.email,
          subject: `MyGateqway Portal Update -  New Commercial Fishing Permit`,
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
           Your application for a Commercial Fishing Permit has been approved. As promised, below is a link to your downloadable PDF Permit.
            <br/><br />
            <p><b>Links</b></p>
            <ul>
            <li><a href='${`${ENV.WEB_SERVICE_URL}${updated_record?.image[0]}`}' target='_blank'>Commercial Fishing Permit</a></li>
            </ul>
            <br/><br/>
            Thank you for using MyGateway Portal.
          `,
        })
      } catch (error) {
        toastr.error('Error', 'Unable to send permit email')
        console.error(error)
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
          license_numb: record.license_numb || 0,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - New Commercial Fishing Permit',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Thank you for your interest in applying for a new commercial fishing permit. 
            Unfortunately, your application was denied due to outstanding documents required. 
            Please review the requirements and resubmit. 
            Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services. 
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  fields = form => [
    {
      name: 'client_type',
      label: 'Are you a company or an individual?',
      options: [
        { label: 'Individual', value: 'individual' },
        { label: 'Business', value: 'business' },
      ],
    },
    {
      name: 'company_name',
      label: "Company's registered name",
      hide: form.client_type != 'business',
      disabled: form.client_type == 'business',
    },
    {
      name: 'company_email',
      label: "Company's registered email address",
      hide: form.client_type != 'business',
      disabled: form.client_type == 'business',
      required: false,
    },
    {
      name: 'captain',
      type: 'select:bool',
      label: 'Are you the captain?',
    },
    {
      heading: "Captain's Details",
    },
    {
      name: 'captain_first_name',
      label: 'First Name',
      hide: form.captain,
      view: !form.captain,
    },
    {
      name: 'captain_last_name',
      label: 'Last Name',
      hide: form.captain,
      view: !form.captain,
    },
    // {
    //   name: 'captains_nationality',
    //   label: 'Nationality',
    //   options: nationalities,
    // },
    {
      name: 'captain_address',
      label: 'Street Address',
      hide: form.captain,
      view: !form.captain,
    },
    {
      name: 'captain_passport_upload',
      label: 'Passport Photo',
      type: 'file:image',
      hide: form.captain,
      view: !form.captain,
      key: 'image.0',
      hint: passportPhotoExample,
    },
    {
      name: 'captain_license_numb',
      label: 'Captain License Number',
      required: false,
    },
    ':: Vessel Details',
    {
      name: 'vessel_name',
      label: 'Name of Fishing Vessel',
    },
    {
      name: 'port_registration_number',
      label: 'Port Registration Number of Vessel',
      callback: v => {
        let backspace = this.state?.backspace
        let val = v.toUpperCase()

        if (v.length === 2 && !backspace) {
          val += '-'
          this.setState((prevState, props) => ({
            backspace: !prevState.backspace,
          }))
        }

        if (v.length < 2 && backspace)
          this.setState((prevState, props) => ({
            backspace: !prevState.backspace,
          }))

        if (v.length > 3) {
          console.log(v)
          if (!/^[0-9]+$/.test(v.substring(3)))
            return { port_registration_number: val.substring(0, 3) }
        }

        return { port_registration_number: val }
      },
      hint: 'Registration Number is alphanumeric, e.g. BS-3434354',
    },
    {
      name: 'overall_length',
      label: 'Length overall',
      is: 'integer',
      hint: 'In Feet',
    },
    {
      name: 'num_of_persons',
      label: 'Number of Persons aboard Vessel',
      is: 'integer',
    },

    {
      name: 'hull_material',
      options: hull_types,
    },
    {
      name: 'vessel_engine',
      label: 'Engine Manufacturer',
      options: [
        'American Skier',
        'Bayliner',
        'Boston Whaler',
        'Brunswick Boat Group',
        'Carver Yachts',
        'Chris-Craft Boats',
        'Front Street Shipyard',
        'MasterCraft',
        'Ski NautiqueSki Nautique',
        'Starcraft Marine',
        'Other',
      ],
      callback: v => {
        if (v != 'Other') {
          return { other_purpose: '' }
        }
      },
    },
    {
      name: 'other_vessel',
      label: 'Other Engine Manufacturer',
      hide:
        form.vessel_engine != 'Other' &&
        form.vessel_engine !== form.other_vessel,
      view: false,
    },
    {
      name: 'engine_horse_power',
      label: 'Total Engine Horse Power',
      is: 'integer',
    },
    '::SPACER',
    {
      name: 'proposed_fishing',
      label: 'Types of Proposed Fishing',
      options: [
        'Free Diving',
        'Compressor Diving',
        'Fish Potting',
        'Stone Crab Trapping',
        'Netting',
        'Handlining',
      ],
      multi: true,
      hint: `<span style="background-color: #FFFF00">Select <strong>ALL</strong> of the options that apply</span>`,
    },
    {
      name: 'port_registration_upload',
      label: 'Port Registration Card',
      type: 'file:all',
      key: 'image.3',
      hint: `<span style="background-color: #FFFF00">WARNING: This card should be a reflection of your commercial status as a fisherman. If you are a leisure fisherman, your application will be <strong>denied</strong></span>`,
    },
    {
      name: 'boat_image_upload',
      label: 'Picture of Boat',
      type: 'file:image',
      key: 'image.2',
    },

    {
      name: 'declare',
      label:
        'I DECLARE THAT ANY PERMIT USED AS A RESULT OF THIS APPLICATION WILL BE USED ONLY IN ACCORDANCE WITH THE FISHERIES (CUSTOMS MANAGEMENT) ACT, 2011 AND THE REGULATIONS MADE THEREUNDER.',
      type: 'checkbox',
      callback: v => {
        const other = {}

        if (form.other_vessel) {
          other.vessel_engine = form.other_vessel
        }
        return other
      },
    },

    {
      name: 'license_numb',
      label: 'Permit Number',
      hide: true,
      view: true,
    },
    {
      name: 'license_expiry_date',
      label: 'Permit Expiration Date',
      hide: true,
      view: true,
    },
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Commercial Fishing Permit',
      key: 'image.0',
    },
  ]

  closeModal = () => this.setState({ record: null })

  on = {
    input: name => ev =>
      this.setState({ [name]: ev.target.value.replace(/[^0-9a-z ]/gi, '') }),

    date: val => this.setState({ permit_expiry_date: val }),

    submit: async () => {
      this.setState({ loading: true })

      const { state, props, resource, form_key } = this
      const { history, location } = props
      const { record, permit_numb, permit_expiry_date } = state
      const { axios } = $app

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
            permit_numb,
            permit_expiry_date,
          },
        })

        if (location.state?.view) {
          const view = { ...location.state.view }
          view.application_decision = 'approved'
          view.fields[13].value = permit_numb
          view.fields[14].value = permit_expiry_date

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success(
          'Success',
          'Permit Number and Permit Expiration Date successfully set'
        )
      } catch (error) {
        console.error(error)
        toastr.error(
          'Error',
          'Unable to set Permit Number and Permit Expiration Date'
        )
        return
      }

      try {
        await axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal Update - Commercial Fishing',
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
            Your Commercial Fishing application has been approved.
            Your permit number is <strong>${permit_numb}</strong> and your permit expiration date is <strong>${permit_expiry_date}</strong> .
            <br/><br />
            Thank you for using the MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false, record: null })
    },
  }

  render() {
    const { state, on, closeModal } = this

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 480 }}
          onClick={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Set Details</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='permit_numb'>
                  Permit Number
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='permit_numb'
                  value={state.permit_numb}
                  onChange={on.input('permit_numb')}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  required
                />
              </div>
              <div className='form-group form-show-validation'>
                <label htmlFor='permit_expiry_date'>
                  Permit Expiration Date
                  <span className='required-label'>*</span>
                </label>
                <DateField
                  type='date'
                  className='form-control'
                  name={'permit_expiry_date'}
                  onChange={on.date}
                  selected={state.permit_expiry_date}
                  value={state.permit_expiry_date}
                  maxDate={new Date()}
                  validators={['required']}
                  errorMessages={['Required']}
                  dateFormat='do MMMM, yyyy'
                  showYearDropdown
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-round mr-2'
                aria-label='Close'
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    )
  }
}
