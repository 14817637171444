import * as T from '@action-types'

const initial = {
  authenticated: false,
  loading: false,
  error: null,
  data: [],
  transactions: {},
}

const reducer = (state = initial, { type, payload }) => {
  switch (type) {
    case T.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case T.GET_USER_TRANSACTIONS:
      return {
        ...state,
        loading: false,
        transactions: payload.response.purchase_history,
      }
    case T.RECENT_HISTORY:
      return {
        ...state,
        loading: false,
        histories: payload.response.histories,
      }
    case T.GET_TOP_REQUEST:
      return {
        ...state,
        loading: false,
        top_requests: payload.response.data,
      }
    case T.GET_COUNTS:
      return {
        ...state,
        loading: false,
        get_counts: payload.response,
      }
    case T.GET_TOTAL_SPEND:
      return {
        ...state,
        loading: false,
        total_spend: payload.response.total_7_day_spent,
        last_7_spend: payload.response.last_7_day_spent,
      }
    case T.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      }
    default:
      return {
        ...state,
        loading: false,
      }
  }
}

export default reducer
