import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { confirmAlert } from 'react-confirm-alert'
import toastr from '@modules/toastr'

export default class FingerPrinting extends React.Component {
  title = 'Application for Fingerprinting'
  resource = '/finger_printings'
  form_key = 'finger_printing'

  free = false
  postpaid = false
  defaults = {}
    has_appointment = false

  skip_location = false
  hide_buttons = {
    status: true,
    email: true,
    deny: true,
  }
  custom_action = {
    text: 'Mark as Completed',
    icon: 'check-circle',
    test: r => r.payment_status,
    fn: record =>
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className='custom-ui'>
            <h3>Mark as Completed</h3>
            <p style={{ color: '#333' }}>
              Are you sure you want to mark this application as completed?
              Ensure that all relevant processes are completed before clicking
              'Yes'.
            </p>
            <button
              className='btn btn-primary'
              onClick={this.completeApplication(record, onClose)}
            >
              Yes
            </button>
            <button className='btn btn-danger' onClick={onClose}>
              No
            </button>
          </div>
        ),
      }),
  }
  custom_acceptance = false
  disable_proxy = true

  fee = form => {
    const { is_citizen } = $app
    return is_citizen ? 20 : 100
  }

  validate = null

  foremost = null

  completeApplication = (record, onClose) => async ev => {
    const { resource, form_key } = this

    try {
      this.setState({ loading: true })

      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      toastr.success('Success', 'Application has been marked as completed')

      await $app.axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateqway Portal Update - Fingerprinting Application',
        message: `
          Good day ${record.user.first_name.capitalize()},
          <br/>
          Your Fingerprinting service has been completed. Please provide us with your feedback on your experience using MyGateway. 
          https://www.surveymonkey.com/r/Preview/?sm=sEmnqd14nxLzKGxhyYuuDJAZUeco2ApGWHVju7BgliT1T5W3BqSbPtivQ8gMTf2a
          <br/><br />
          Thank you for using MyGateway Portal.
        `,
      })

      toastr.success('Success', 'Email has been sent to user')
    } catch (err) {
      toastr.error('Error', 'Unable to mark application as completed')
      console.error(err)
    } finally {
      this.setState({ loading: false })
      onClose()
    }
  }

  fields = form => [
    {
      name: 'fingerprint_reason',
      label: 'Fingerprinting Reason',
      options: ['Criminal Records', 'Employment Purposes', 'FBI'],
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
