import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

export default class ImageField extends ValidatorComponent {
  state = {
    preview: null
  }

  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () => this.state.isValid ? null : (
    <div style={this.errorStyles}>
      {this.getErrorMessage()}
    </div>
  )

  grabUpload = file => {
    const reader = new FileReader()
    reader.onload = () => this.setState({ preview: reader.result })
    reader.readAsDataURL(file)
  }

  render () {
    const { state, props, grabUpload } = this

    const {
      errorMessages, validators, requiredError, validatorListener,
      onChange, value, accept, name, label, icon, width, height, alt, ...rest
    } = props

    const image = state.preview ? (
      <img
        className="img-upload-preview"
        width={width || 150}
        height={height || 150}
        src={state.preview}
        alt={alt || 'Form Field Upload'}
      />
    ) : null

    const change = ev => {
      onChange(ev)
      grabUpload(ev.target.files[0])
    }

    return (
      <div className="input-file input-file-image">
        {image}
        <input
          {...rest}
          id={name}
          type="file"
          name={name}
          accept={accept || '*'}
          onChange={change}
          className="form-control form-control-file"
        />
        <label htmlFor={name} className="label-input-file btn custom-btn btn-round">
          <span className="btn-label">
            <i className={'fas fa-' + (icon || 'image')}></i>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;{label || 'Upload An Image'}
        </label>
        {this.errorText()}
      </div>
    )
  }
}
