import React from 'react'

import SiteSearch from '@modules/site-search'
import MainLogo from '@images/logo/header.png'
import TextlessLogo from '@images/logo/white.png'

const styles = {
  phone: {
    textAlign: 'left',
  },
  logo: {
    margin: '0 1.25rem',
    padding: '0.5rem 0 0',
    height: '80%',
    width: 'auto',
  },
}

export default function Header(props) {
  const { toggled, togglePanel, openTopBar } = props

  const img = toggled ? null : (
    <picture style={{ height: '100%' }}>
      <source srcSet={MainLogo} media='(min-width: 800px)' />
      <img style={styles.logo} src={TextlessLogo} alt='My Gateway Logo' />
    </picture>
  )

  return (
    <header className='main-header'>
      <div className='logo-header' data-background-color='blue2'>
        {img}

        <button
          onClick={togglePanel}
          className='navbar-toggler sidenav-toggler ml-auto'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'>
            <i className='fas fa-bars'></i>
          </span>
        </button>

        <button onClick={openTopBar} className='topbar-toggler more'>
          <i className='fas fa-ellipsis-v'></i>
        </button>

        <div className='nav-toggle'>
          <button
            onClick={togglePanel}
            className={
              'btn btn-toggle toggle-sidebar ' + (toggled ? 'toggled' : '')
            }
          >
            <i className={'fas fa-' + (toggled ? 'ellipsis-v' : 'bars')}></i>
          </button>
        </div>
      </div>

      <nav
        className='navbar navbar-header navbar-expand-lg'
        data-background-color='blue2'
      >
        <div className='container-fluid'>
          <SiteSearch />

          <div className='navbar-nav topbar-nav ml-md-auto align-items-center social-icons'>
            <li className='nav-item dropdown hidden-caret submenu'>
              <a
                className='nav-link dropdown-toggle'
                href='#'
                id='help-numbers'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <i className='fas fa-phone'></i>
              </a>
              <ul
                className='dropdown-menu notif-box animated fadeIn'
                aria-labelledby='help-numbers'
              >
                <li className='dropdown-title'>Call Center Phone Numbers</li>
                <li className='dropdown-title' style={styles.phone}>
                  <a href='tel:2426044357'>(242) 604-4357 (HELP)</a>
                </li>
                <li className='dropdown-title' style={styles.phone}>
                  <a href='tel:2428254321'>(242) 825-4321</a>
                </li>
              </ul>
            </li>

            <li className='nav-item'>
              <a
                href='https://www.facebook.com/Digital-Transformation-Unit-106568484621561'
                className='nav-link'
              >
                <i className='fab fa-facebook'></i>
              </a>
            </li>
          </div>
        </div>
      </nav>
    </header>
  )
}
