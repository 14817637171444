import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import DataTable from 'react-data-table-component'

import { getUserTransactions } from '@actions/dashboard'

import { extractKeys } from '@helpers/objects'
import Loader from '@shared/loader'

import mapServiceName from '@constants/maps.services'

class Transactions extends React.Component {
  state = {}
  table = React.createRef()

  componentDidMount() {
    const records = this.flatten(this.props.transactions)

    records && records.length
      ? this.setState({ records })
      : this.props.getUserTransactions()
  }

  componentWillReceiveProps({ transactions }) {
    if (!this.state.records && transactions.purchases) {
      const records = this.flatten(transactions)
      this.setState({ records })
    }
  }

  headings = ['Service Name', 'Application ID', 'Amount', 'Date']

  columns = [
    {
      name: 'Service name',
      selector: 'service',
      sortable: true,
      format: r => mapServiceName(r.service),
    },
    {
      name: 'Application ID',
      selector: 'service_id',
    },
    {
      name: 'Amount',
      selector: 'amount_paid',
      cell: r => <span data-currency>{(+r.amount_paid).toFixed(2)}</span>,
      sortable: true,
      sortFunction: (a, b) => Number(a.amount_paid) < Number(b.amount_paid),
    },
    {
      name: 'Date',
      selector: 'created_at',
      format: r => moment(r.created_at).format('YYYY-MM-DD @ hh:mm A'),
      sortable: true,
      sortFunction: (a, b) =>
        new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
          ? 1
          : -1,
    },
  ]

  flatten = ({ purchases }) =>
    purchases && purchases.length
      ? purchases.reduce(
          (transactions, { service, records }) => [
            ...transactions,
            ...records.map(r => ({ ...r, service })),
          ],
          []
        )
      : []

  render() {
    const { state, props, columns } = this
    const { loading } = props
    const { records: data } = state

    if (loading) return <Loader loading={true} />

    const tableProps = {
      columns,
      data,

      defaultSortField: 'created_at',
      defaultSortAsc: false,
    }

    return (
      <div className='content'>
        <div className='page-header'></div>
        <div className='page-inner'>
          <div className='card'>
            <div className='card-header'>
              <h4 style={{ margin: 0 }}>Transaction History</h4>
            </div>
            <div className='card-body'>
              <div className='table-responsive form-records-table'>
                <DataTable {...tableProps} striped pagination />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => extractKeys(state.dashboard, 'loading', 'transactions'),
  { getUserTransactions }
)(Transactions)
