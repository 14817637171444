import React from 'react'
import { connect } from 'react-redux'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { getLists } from '@actions/users'
import islands from '@constants/islands'

class CaptiveMarine extends React.Component {
  componentDidMount() {
    !this.props.islands.length && this.props.getLists()
  }

  title = 'Application to Operate a Captive Marine Mammal Facility'
  resource = '/captive_marine_mammal_licenses'
  form_key = 'captive_marine_mammal_license'
  free = false
  postpaid = false
  defaults = {}
  
  skip_location = false
  hide_buttons = false
  custom_action = false
  custom_acceptance = false

  fee = 99

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'facility_location',
      label: 'Location of Facility',
      options: islands,
    },
    {
      name: 'facility_desc',
      label: 'Description of Facility',
      type: 'checklist',
      options: [
        'Dolphin Enclosures',
        'Food Preparation Facilities and Equipment',
        'Food Storage Facilities',
        'Staff Facilities',
        'Type and quantity of water available for food preparation and wash down',
      ],
    },
    {
      name: 'dolphin_enclosure_desc',
      label: 'Dolphin Enclousures',
      type: 'textarea',
      hide: !form.facility_desc?.has?.('Dolphin Enclosures'),
    },
    {
      name: 'food_prep_and_equipment_desc',
      label: 'Food Preparation Facilities and Equipment',
      type: 'textarea',
      hide: !form.facility_desc?.has?.(
        'Food Preparation Facilities and Equipment'
      ),
    },
    {
      name: 'food_storage_facilities_desc',
      type: 'textarea',
      hide: !form.facility_desc?.has?.('Food Storage Facilities'),
    },
    {
      name: 'staff_facilities_desc',
      label: 'Staff Facilities',
      type: 'textarea',
      hide: !form.facility_desc?.has?.('Staff Facilities'),
    },
    {
      name: 'type_and_water_quantity',
      label:
        'Type and quantity of water available for food preparation and wash down',
      type: 'textarea',
      hide: !form.facility_desc?.has?.(
        'Type and quantity of water available for food preparation and wash down'
      ),
    },
    '::SPACER',
    {
      name: 'dolphin_details',
      label: 'Details on captive dolphins or proposed acquisition of the same',
      type: 'textarea',
    },
    {
      name: 'managerial_staff_desc',
      label: 'Proposed professional/managerial staff for the Facility',
      type: 'textarea',
    },
    {
      name: 'programmes_list',
      label: 'Description of Proposed Programmes',
      type: 'checklist',
      options: [
        'Education Programmes',
        'Veterinary Programmes',
        'Quarantine Programme',
        'Exhibits and/or public/dolphin programmes',
        'Scientific Research Programmes',
        'Marine mammal training programmes',
        'Marine Mammal Propogation Programme',
        'Pest Control Programmes',
        'Environmental quality control programmes',
      ],
    },
    {
      name: 'education_programmes_desc',
      label: 'Education Programmes',
      type: 'textarea',
      hide: !form.programmes_list?.has?.('Education Programmes'),
    },
    {
      name: 'medical_programmes_desc',
      label: 'Veterinary/Medical Programmes',
      type: 'textarea',
      hide: !form.programmes_list?.has?.('Veterinary Programmes'),
    },
    {
      name: 'quarantine_programme_desc',
      label: 'Quarantine Programmes',
      type: 'textarea',
      hide: !form.programmes_list?.has?.('Quarantine Programme'),
    },
    {
      name: 'exhibits_desc',
      type: 'textarea',
      label: 'Exhibits and/or public/dolphin programmes',
      hide: !form.programmes_list?.has?.(
        'Exhibits and/or public/dolphin programmes'
      ),
    },
    {
      name: 'research_desc',
      label: 'Scientific Research Programmes',
      type: 'textarea',
      hide: !form.programmes_list?.has?.('Scientific Research Programmes'),
    },
    {
      name: 'training_desc',
      label: 'Marine mammal training programmes',
      type: 'textarea',
      hide: !form.programmes_list?.has?.('Marine mammal training programmes'),
    },
    {
      name: 'propogation_desc',
      type: 'textarea',
      label: 'Marine Mammal Propogation Programme',
      hide: !form.programmes_list?.has?.('Marine Mammal Propogation Programme'),
    },
    {
      name: 'pest_control_desc',
      type: 'textarea',
      label: 'Pest Control Programmes',
      hide: !form.programmes_list?.has?.('Pest Control Programmes'),
    },
    {
      name: 'environment_quality_desc',
      label: 'Environmental quality control programmes',
      type: 'textarea',
      hide: !form.programmes_list?.has?.(
        'Environmental quality control programmes'
      ),
    },
    {
      name: 'floor_plan_upload',
      type: 'file:image',
      label:
        'A plan of the Facility on a scale of not less than one eight of an inch to the foot should be submitted showing the intended use of each part, room or division and marine mammal enclosure.',
      key: f => f.image,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default connect(
  ({ users }) => ({
    lists: users.lists,
    countries: users.lists?.countries || [],
    islands: users.lists?.islands || [],
  }),
  { getLists }
)(CaptiveMarine)
