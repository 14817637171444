import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'

export default class JobSkill extends React.Component {
  resource = '/job_skills'
  form_key = 'job_skill'
  defaults = {}

  // Delete lines as needed
  // fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  childProofLabel = (form) => {
    if (form.choose_upload == 'Birth Certificate') {
      return 'Registered birth certificate of minor'
    }
    else if (form.choose_upload == 'Adoption Certificate') {
      return 'Certified Adoption Certificate'
    }
    else if (form.choose_upload == 'Certified Court Order') {
      return 'Certified Court Order for proof of legal Guardianship'
    }
    else if (form.choose_upload == 'Letter from Social Services') {
      return 'Valid letter from Department of Social Services, where the minor is under state care'
    } else {
      return 'Child Identification Documents'
    }
  }

  nationalIDOptions = (form) => {
    const modifyList = () => {
      let index = 0;
      switch (form.id_upload_type_one || form.id_upload_type_two) {
        case 'Birth Certificate':
          index = options.findIndex(e => e == 'Birth Certificate')
          options = options.toSpliced(index, 1)
          break;
        case 'Passport':
          index = options.findIndex(e => e == 'Passport')
          options = options.toSpliced(index, 1)
          break;
        case 'Voters Card':
          index = options.findIndex(e => e == 'Voters Card')
          options = options.toSpliced(index, 1)
          break;
        case 'NIB Card':
          index = options.findIndex(e => e == 'NIB Card')
          options = options.toSpliced(index, 1)
          break;
        default:
         return options
      }
    }
    let options = ['Birth Certificate', 'Passport', 'Voters Card', 'NIB Card'];
    if (form.i_am_applicant) {
      options = ['Birth Certificate', 'Passport', 'Voters Card'];
      modifyList();
    } else {
      modifyList()
    }

    return options
  }

  fields = form => [
    '::Applicant Details',
    {
      name: 'i_am_applicant',
      type: 'select:bool',
      label: 'I am applying for myself',
    },
    {
      name: 'app_first_name',
      label: 'First Name',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_middle_name',
      label: 'Middle Name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'app_last_name',
      label: 'Last Name',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_date_of_birth',
      label: 'Date of Birth',
      type: 'date',
      showYearDropdown: true,
      hide: form.i_am_applicant,
    },
    {
      name: 'app_age',
      label: 'Age',
      type: 'integer',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_sex',
      label: 'Sex',
      options: ['male', 'female'],
      hide: form.i_am_applicant,
    },
    {
      name: 'app_nib_number',
      label: 'National Insurance number',
      type: 'integer',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_telephone_numb',
      label: 'Telephone Number',
      type: 'phone',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_email_address',
      label: 'Email Address',
      type: 'email',
      hide: form.i_am_applicant,
    },
    {
      name: 'emergency_contact_list',
      label: 'Emergency Contact',
      rowLabel: 'Emergency Contact',
      required: false,
      fields: [
        {
          name: 'first_name',
          // label: 'First name of parent/guardian'
        },
        {
          name: 'last_name',
          // label: 'Last name of parent/guardian'
        },
        {
          name: 'relationship_to_youth',
          label: 'Relation to camper',
          options: ['parent', 'guardian', 'relative', 'friend', 'other'],
        },
        {
          name: 'contact_number',
          // label: 'Parent/Guardian phone contact',
          type: 'phone'
        },
        {
          name: 'alt_phone_contact',
          label: 'Alternate phone contact',
          type: 'phone',
          required: false,
        }
      ]
    },

    "::SPACER",
    "::Education Information",
    {
      name: 'school_list',
      label: 'Tell us the last High School you attended',
      columns: [
       {
        name: 'school_name',
       },
       {
        name: 'school_address',
       },
       {
        name: 'from_year',
        type: 'integer',
       } ,
       {
        name: 'to_year',
        type: 'integer'
      },
      ]
    },
    {
      name: 'tertiary_school_list',
      label: 'Tell us the last Tertiary School you attended',
      columns: [
       {
        name: 'school_name',
       },
       {
        name: 'school_address',
       },
       {
        name: 'from_year',
        type: 'integer',
       } ,
       {
        name: 'to_year',
        type: 'integer'
      },
      ]
    },
    {
      name: 'accomplishments_list',
      label: 'Awards and Certificates',
      hint: 'List any awards, achievements or certificates',
      columns: ['accomplishment'],
      format: v => v.accomplishment,
    },
    "::References",
    {
      name:'references_list',
      label:'Give the name of persons, who are not immediate relatives, to provide a reference on your behalf',
      rowLabel: 'Reference',
      min: 3,
      // hide: form.which_program != 'corporate placement',
      fields: [
        'first_name',
        'last_name',
        {
          name: 'relationship_to_applicant',
          label: 'Relation to applicant',
          options: ['friend', 'employer', 'other'],
        },
        {
          name: 'occupation'
        },
        {
          name: 'contact_information',
          type: 'phone'
        },
        {
          name: 'alternate_contact_information',
          type: 'phone',
          required: false,
        },
      ],
    },
    {
      name: 'social_media_list',
      label: 'Social Media Handle Links',
      required: false,
      columns: [
        {
          name: 'platform',
          datalist: [
            'Facebook',
            'Twitter',
            'Instagram',
            'TikTok',
            'YouTube',
            'LinkedIn',
            'Pinterest'
          ]
        },
        {
          name: 'tag',
        },
        {
          name: 'tag_link',
          label: 'Tag in Active URL link',
          hint: 'Please include tag within an active URL link'
        },
      ],
    },
    {
      name: 'informed_by',
      label: 'How did you hear about The Bahamas Apprenticeship Program?',
      hint: 'Select all that apply',
      multi: true,
      options: ['Radio', 'Newspaper','Friends','Social Media', 'School', 'Television', 'Extra-curricular program', 'Other'],
    },
    {
      name: 'acceptance_reason',
      label: 'Why should you be accepted into this program?',
      type: 'textarea',
      max: 200,
      maxLength: 200,
    },

    "::Uploads",
    {
      name: 'choose_upload',
      label: "Please upload one of the following documents to verify you are the applicant's parent or guardian",
      options: ['Birth Certificate', 'Adoption Certificate', 'Certified Court Order', 'Letter from Social Services'],
      hide: form.i_am_applicant || form.app_age > 18,
    },
    {
      name: 'child_proof_upload',
      label: this.childProofLabel(form),
      hint: `If the document is not uploaded, you must visit the Division of 
        <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.0',
      type: 'file:all',
      hide: form.i_am_applicant || form.app_age > 18,
      required: false,
    },
    {
      name: 'guardian_proof_upload',
      label: 'Identification documents of parent or legal guardian',
      hint: `If the document is not uploaded, you must visit the Division of 
      <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.1',
      type: 'file:all',
      hide: form.i_am_applicant || form.app_age > 18 || form.choose_upload != 'Birth Certificate',
      required: false,
    },
    {
      name: 'photo_upload',
      lable: 'Applicant Personal Photo',
      type: 'file:image',
      key: 'image.2',
      required: true,
    },
    '::Upload two (2) National IDs minimum',
    {
      name: 'id_upload_type_one',
      label: "Please upload one of the following National ID documents",
      hint: 'Do not upload two of the same ID types',
      options: this.nationalIDOptions(form),
    },
    {
      name: 'id_upload_type_two',
      label: "Please upload one of the following National ID documents",
      hint: 'Do not upload two of the same ID types',
      options: this.nationalIDOptions(form),
    },
    {
      name: 'national_id_checker',
      label: 'You cannot use two of the same National ID documents!',
      hint: 'You cannot use two of the same National ID documents!',
      hide: form.id_upload_type_one != form.id_upload_type_two,
      disabled: true,
      save: false,
      view: false,
    },
    {
      name: 'passport_upload',
      // label: 'Passport',
      type: 'file:all',
      key: 'image.3',
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
      hide: (form.id_upload_type_one != 'Passport' && form.id_upload_type_two != 'Passport'),
    },
    {
      name: 'voter_card_upload',
      lable: `Voter's Card/Affidavit`,
      type: 'file:all',
      key: 'image.4',
      hide: (form.id_upload_type_one != 'Voters Card' && form.id_upload_type_two != 'Voters Card'),
    },
    {
      name: 'birth_certificate_upload',
      type: 'file:all',
      key: 'image.5',
      hide: (form.id_upload_type_one != 'Birth Certificate' && form.id_upload_type_two != 'Birth Certificate'),
    },
    // {
    //   name: 'certifcate_of_identity_upload',
    //   type: 'file:all',
    //   key: 'image.3',
    //   required: false,
    // },
    {
      name: 'nib_card_upload',
      label:' NIB card',
      type: 'file:all',
      key: 'image.6',
      hide: (form.id_upload_type_one != 'NIB Card' && form.id_upload_type_two != 'NIB Card'),
    },
    '::Other Uploads',
    {
      name: 'police_record_upload',
      label: 'Valid Police Record',
      type: 'file:all',
      key: 'image.7',
      required: true,
    },
    {
      name: 'transcripts_upload',
      label: 'Primary, Junior or Senior high transcripts',
      type: 'file:all',
      key: 'image.8',
      required: true,
    },
    {
      name: 'resume_upload',
      label: 'Recent Resume',
      type: 'file:all',
      key: 'image.9',
      required: true,
    },

    "::For Non-bahamian citizens",
    {
      name: 'belonger_permit_upload',
      label: `Belonger's permit from the department of immigration`,
      type: 'file:all',
      key: 'image.10',
      required: false,
    },
    {
      name: 'permanent_residency_permit_upload',
      type: 'file:all',
      key: 'image.11',
      required: false,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
