const sportsFederations = [
  'Bahamas Karate Association',
  'Bahamas Martial Arts Federation',
  'Bahamas Taekwondo Federation',
  'Bahamas Amateur Wrestling Federation',
  'Special Olympic Bahamas',
  'Bahamas Associaton for the Physically Disabled',
  'Bahamas National Council rof Disability',
  'E-Sports Federation',
  'Bahamas Judo Federation',
  'Bahamas Softball Federation',
  'Abaco Softball Association',
  'Andros Softball Association',
  'Bimini Softball Association',
  'Central Andros Softball Association',
  'Eleuthera Softball Association',
  'Exuma Softball Association',
  'Grand Bahama Softball Association',
  'Long Island Softball Association',
  'New Providence Softball Association',
  'Bahamas Volleyball Federation',
  'Abaco Volleyball Association',
  'Grand Bahama Volleyball Association',
  'Bahamas Bodybuilding & Fitness Federation',
  'Bahamas Cricket Federation',
  'Bahamas Cycling Federation',
  'Bahamas Dart Federation',
  'Bahamas Golf Federation',
  'Bahamas Triathalon Association',
  'Equestrian Bahamas Association',
  'Gymnastics Federation of The Bahamas',
  'Bahamas Boxing Federation',
  'Bahamas Boxing Commission',
  'Inagua Baseball Association',
  'Grand Bahama Baseball Association',
  'New Providence Amateur Baseball League',
  'Bahamas Basketball Federation',
  'Abaco Amateur Basketball Association',
  'Bimini Basketball Association',
  'Eleuthera Basketball Association',
  'Grand Bahama Basketball Association',
  'New Providence Basketball Association',
  "New Providence Women's Basketball Association",
  'San Salvador Basketball Association',
  'Bahamas Lawn Tennis Association',
  'Abaco Tennis Association',
  'Bahamas Football Association',
  'Grand Bahama Football Association',
  'Bahamas Coaches Association',
  'Bahamas Baseball Association',
  'Bimini Baseball Association',
  'Bahamas Association of Certified Officials',
  'Bahamas Association of Athletic Associations',
  'Freeport Aquatics Club',
  'Bahamas Aquatics Federation',
  'Bahamas Motor Sports Association',
  'Bahamas Chess Federation',
  'Bahamas Federation of American Football',
  'Bahamas Fencing Federation',
  'Bahamas Archery Federation',
  'Bahamas Rugby Union',
  'Bahamas Rowing Federation',
  'Bahamas Pickleball Federation',
  'Bahamas Powerlifting Federation',
  'Bahamas Weightlifting Federation',
  'Bahamas Sailing Associations',
  'Bahamas Triathlon Federation',
  'Bahamas Table Tennis Federation',
  'Bahamas Anti Doping Commission',
  'Bahamas Bowling Federation',
  'Bahamas Dragon Boat Racing Federation',
]

const sportsList = [
  'Adaptive Sports',
  'Aquatics',
  'Archery',
  'Athletics (Track And Field)',
  'Badminton',
  'Baseball',
  'Basketball',
  'Bowling',
  'Bodybuilding And Fitness',
  'Boxing',
  'Chess',
  'Cricket',
  'Cycling',
  'Darts',
  'Dragon Boat Racing',
  'Dominoes',
  'Eightball (Pool)',
  'Equestrian',
  'E-Sports',
  'Football (American/Flag)',
  'Fencing',
  'Gymnastics',
  'Golf',
  'Handball',
  'Judo',
  'Karate' ,
  'Martial Arts',
  'Motor Sports',
  'Netball',
  'Lacrosse',
  'Powerlifting',
  'Pickleball',
  'Rowing',
  'Rugby',
  'Regatta',
  'Racquet Ball',
  'Sailing',
  'Swimming',
  'Softball',
  'Squash',
  'Table Tennis',
  'Taekwondo',
  'Tennis',
  'Volleyball',
  'Weightlifting',
  'Wrestling',
  'Yoga',
]

export { sportsFederations, sportsList };
