import SimpleCrypto from 'simple-crypto-js'
import cookie from 'react-cookies'

import * as TYPES from '@action-types'
import { history } from '@/App'

import toastr from '@modules/toastr'
import AppGlobals from '@modules/setup-globals'
import STRINGS from '@constants/strings'

const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_SECRET_KEY)

const getRequest = {
  type: TYPES.GET_REQUEST_SUCCESS,
  payload: {},
}

const Resource = async (dispatch, path, body, _method, _justData = true) => {
  dispatch(getRequest)

  const method = _method || (body ? 'post' : 'get')

  try {
    const res = await (method == 'get'
      ? $app.axios.get(path, { params: body })
      : $app.axios[method.toLowerCase()](path, body))

    return _justData ? res.data : res
  } catch (err) {
    dispatch(createError(err))
    return null
  }
}

const User = (dispatch, path, body, _method) =>
  Resource(dispatch, 'users/' + path, body, _method)

export const getUser = () => async dispatch => {
  const response = await User(dispatch, 'get_user')

  response &&
    dispatch({
      type: TYPES.GET_PERSON_STATUS,
      payload: {
        status: response.in_person_status,
      },
    })
}

export const loginUser = (user, history) => async dispatch => {
  dispatch(getRequest)

  if (user.remember_me) {
    const password = simpleCrypto.encrypt(user.password)
    cookie.save('rememberPilotObj', JSON.stringify({ ...user, password }), {
      path: '/',
      secure: true,
    })
  } else cookie.remove('rememberPilotObj', { path: '/' })

  try {
    const { data } = await $app.axios.post(
      '/users/signin',
      { user },
      {
        validateStatus: s => (s >= 200 && s < 300) || s == 400,
      }
    )

    if (data?.error == 'You must confirm your email!') {
      dispatch({ type: TYPES.UNCONFIRMED_LOGIN })
      return
    }

    if (data.error) {
      const error =
        data.error == 'Invalid login credentials'
          ? STRINGS.ERRORS.INVALID_LOGIN_CREDENTIALS
          : data.error

      toastr.error('Error', error)
      dispatch({ type: 'NOOP' })
      return
    }

    if (data.id) {
      toastr.success('Success', 'Successfully Logged In')
      window.sessionStorage.setItem('user', JSON.stringify(data))
      global.$app = new AppGlobals(sessionStorage.user)

      dispatch({
        type: TYPES.VERIFY_OTP_SUCCESS,
        payload: { response: data },
      })

      return
    }

    toastr.success('Success', 'Please check your email for the access token.')
    window.__OTP_TOKEN = data.token
    history.push('/otp', data)

    dispatch({ type: TYPES.LOGIN_SUCCESS })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const verifyOTP = (otp, cb) => async dispatch => {
  const response = await Resource(dispatch, 'otp_verify', {
    otp,
    token: window.history.state.state.token,
  })

  if (response?.token) {
    toastr.success('Success', 'Successfully Logged In')
    window.sessionStorage.setItem('user', JSON.stringify(response))
    global.$app = new AppGlobals(sessionStorage.user)

    dispatch({
      type: TYPES.VERIFY_OTP_SUCCESS,
      payload: { response },
    })

    history.push('/dashboard')
  } else {
    dispatch({ type: 'NOOP' })
    cb?.(false)
    return false
  }
}

// prettier-ignore
export const logoutUser = (expired = false, callback) => async dispatch => {
  const logout = await User(dispatch, 'signout')

  if (logout) {
    sessionStorage.clear()
    global.$app = new AppGlobals(sessionStorage.user)
    window.location.href = '/login' + (expired ? '?session=expired' : '')

    typeof callback == 'function' && setTimeout(callback, 200)
  }
}

export const isVerified = () => async dispatch => {
  const response = await User(dispatch, 'is_verified')

  response &&
    dispatch({
      type: TYPES.IS_VERIFIED,
      payload: { response },
    })
}

export const signupUser = (user, token, cb) => async dispatch => {
  window.localStorage.setItem('__OTP.PREFS.CHOSEN__', '1')

  const response = await User(dispatch, 'signup', {
    nib: { token },
    user,
  })

  if (response && response.message) {
    toastr.success('Success', 'Successfully Registered')
    typeof cb == 'function' && cb(response.message)
  }

  dispatch({
    type: TYPES.SIGNUP_SUCCESS,
  })
}

export const forgetPassword = user => async dispatch => {
  const response = await Resource(dispatch, 'passwords', { user })

  if (response) {
    toastr.success('Success', response.message)
    history.push('/login')

    dispatch({
      type: TYPES.FORGET_PASSWORD_SUCCESS,
      payload: { response },
    })
  }
}

export const resetPassword = user => async dispatch => {
  const response = await Resource(dispatch, 'passwords/update_by_token', {
    user,
  })

  if (response) {
    toastr.success('Success', 'Password has been updated')
    history.push('/login')

    dispatch({
      type: TYPES.RESET_PASSWORD_SUCCESS,
      payload: { response },
    })
  }
}

export const getUserProfile = id => async dispatch => {
  const response = await Resource(dispatch, 'profiles/' + (id ? id : ''))

  response &&
    dispatch({
      type: TYPES.GET_USER_PROFILE_DATA,
      payload: { response },
    })
}

export const updateProfile = (id, profile) => async dispatch => {
  const response = await Resource(
    dispatch,
    'profiles/' + id,
    { profile },
    'patch'
  )

  if (response) {
    toastr.success('Success', 'Successfully updated Profile')

    dispatch({
      type: TYPES.UPDATE_USER_PROFILE,
      payload: { response },
    })
  }
}

export const getAllCitizens =
  (page = 1, per_page = 20, NIB, cb) =>
  async dispatch => {
    const response = await Resource(
      dispatch,
      'profiles/show_all',
      { page, per_page, NIB },
      'get'
    )

    response &&
      dispatch({
        type: TYPES.GET_ALL_USERS,
        payload: { response },
      })

    typeof cb == 'function' && cb(!!response.all_profiles.length)
  }

export const getAddressInfo = () => async dispatch => {
  const response = await Resource(dispatch, 'addresses')

  response &&
    dispatch({
      type: TYPES.GET_ADDRESS_INFO,
      payload: { response },
    })
}

export const updateAddressInfo = address => async dispatch => {
  const response = await Resource(dispatch, 'addresses/update_home_address', {
    address,
  })

  if (response) {
    toastr.success('Success', 'Successfully updated address')

    dispatch({
      type: TYPES.UPDATE_ADDRESS_INFO,
      payload: { response },
    })
  }
}

export const updateOccupationInfo = address => async dispatch => {
  const response = await Resource(dispatch, 'addresses/update_work_address', {
    address,
  })

  if (response) {
    toastr.success('Success', 'Occupation details updated')

    dispatch({
      type: TYPES.UPDATE_WORK_INFO,
      payload: { response },
    })
  }
}

export const updateEducationInfo = (id, profile) => async dispatch => {
  const response = await Resource(
    dispatch,
    'profiles/' + id,
    { profile },
    'patch'
  )

  if (response) {
    toastr.success('Success', 'Successfully updated Education Details')

    dispatch({
      type: TYPES.UPDATE_USER_PROFILE,
      payload: { response },
    })
  }
}

export const getUserParentInfo = id => async dispatch => {
  const response = await Resource(dispatch, 'parents/' + id)

  response &&
    dispatch({
      type: TYPES.GET_USER_PARENT_DATA,
      payload: { response },
    })
}

export const createParentInfo = parent => async dispatch => {
  const response = await Resource(dispatch, 'parents', { parent })

  if (response) {
    toastr.success('Success', `Successfully updated parents' information`)

    dispatch({
      type: TYPES.CREATE_PARENT_INFO,
      payload: { response },
    })
  }
}

export const updateParentInfo = (id, parent) => async dispatch => {
  const response = await Resource(
    dispatch,
    'parents/' + id,
    { parent },
    'patch'
  )

  if (response) {
    toastr.success('Success', `Successfully updated parents' information`)

    dispatch({
      type: TYPES.UPDATE_PARENT_INFO,
      payload: { response },
    })
  }
}

export const verifyDocument = (citizen, data, cb) => async dispatch => {
  const response = await Resource(dispatch, 'verifications', {
    id: citizen.user_id,
    verify: {
      ...data,
      document_verified: true,
    },
  })

  if (response) {
    toastr.success('Success', 'Successfully verified document')

    dispatch({
      type: TYPES.VERIFY_DOCUMENT,
      payload: { response },
    })
  }

  typeof cb == 'function' && cb(!!response)
}

export const getLists = () => async dispatch => {
  const countries = await User(dispatch, 'country_list')
  const islands = await User(dispatch, 'island_list')

  countries &&
    islands &&
    dispatch({
      type: TYPES.GET_COUNTRY_ISLAND_DATA,
      payload: {
        response: {
          countries,
          islands: islands.map(initialCaps),
        },
      },
    })
}

export function createError(error) {
  if (error.response) {
    const { data, status } = error.response

    toastr.error('Error', data.error)

    if (status == 401) {
      sessionStorage.clear()
      window.location.href = '/login'
      return
    }
  }

  return dispatch => {
    setTimeout(() => {
      dispatch({
        type: TYPES.CLEAR_ERROR,
      })
    }, 2000)

    dispatch({
      type: TYPES.GET_RESPONSE_ERROR,
      payload: error,
    })
  }
}

function initialCaps(str) {
  return str
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(' ')
}
