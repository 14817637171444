import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import AuthGuard from '@wrappers/auth-guard'
import Loader from '@shared/loader'

import Dashboard from '@pages/common/dashboard'
import AgencyDetails from '@pages/common/agency'
import NotFound from '@pages/common/not-found'
import Login from '@pages/common/login'
import OTPScreen from '@pages/common/otp-screen'
import Logout from '@pages/common/logout'

import NIBSearch from '@pages/officer/nib-search'
import Stats from '@pages/officer/stats'
import Citizens from '@pages/officer/citizens'
import {
  SuggestionsTable,
  ServiceSuggestions,
} from '@pages/officer/service-request'

import FAQ from '@pages/citizen/faqs'
import MapPage from '@pages/citizen/map'
import Transactions from '@pages/citizen/transactions'

import LiveDigitalCerts from '@modules/certs/live'
import DigitalCert from '@pages/common/digicert'

import AppointmentsCalendar from '@modules/appointments'
import CreateAppointment from '@modules/appointments/create'
import AppointmentDashboard from '@modules/appointments/AppointmentDashboard'

import Inbox from '@modules/message-center/inbox'
import SentMail from '@modules/message-center/sent'
import Detail from '@modules/message-center/detail'
import ComposeMail from '@modules/message-center/compose'

import BlockcertsTable from '@modules/blockcerts/index'
import BlockcertsForm from '@modules/blockcerts/issue'

import PendingRegistries from '@agencies/mysc/culture/pending-approvals'

import generateServiceRoutes from './service-routes'
import { RoleManagement } from '@modules/officer-management/role-management'

const SignUp = lazy(() => import('@pages/signup/adult'))
const EmailVerified = lazy(() => import('@pages/common/email-verified'))
const Profile = lazy(() => import('@pages/common/profile'))
const CodePrinter = lazy(() => import('@pages/print/code-printer'))
const ForgetPassword = lazy(() => import('@pages/common/forgot-password'))
const ResetPassword = lazy(() => import('@pages/common/reset-password'))
const RegisterChild = lazy(() => import('@pages/signup/child'))
const TermsOfUse = lazy(() => import('@pages/legal/terms-of-use'))
const PrivacyPolicy = lazy(() => import('@pages/legal/privacy-policy'))
const ProxyConfirmation = lazy(() => import('@pages/citizen/confirm-proxy'))
const CourierScanner = lazy(() => import('@modules/delivery/courier'))

const indexRoute = user => ({
  path: '/',
  exact: true,
  authenticated: !!user,
  render: () => <Redirect to={user ? '/dashboard' : '/login'} />,
})

class Routes extends React.Component {
  shouldComponentUpdate = next => {
    return this.props.location.pathname != next.location.pathname
  }

  render() {
    const { component, noAuthGuard } = this.props

    if (component) {
      const page = noAuthGuard ? component : AuthGuard(component)

      return (
        <Switch>
          <Route path='/otp' exact component={OTPScreen} />
          <Route path='/logout' exact component={Logout} />
          <Route path='/' component={page} />
          <Route component={NotFound} />
        </Switch>
      )
    }

    return (
      <Suspense fallback={<Loader loading={true} />}>
        <Switch>
          <Route {...indexRoute($app.current_user)} />

          <Route path='/404' component={NotFound} />
          <Route path='/print/qr/:code' component={CodePrinter} />

          <Route
            path='/confirm-proxy'
            render={rp => <ProxyConfirmation {...rp} application />}
          />

          <Route
            path='/confirm-proxy-signup'
            render={rp => <ProxyConfirmation {...rp} child />}
          />

          <Route
            path='/cert-check/:service/:form_num'
            component={DigitalCert}
          />

          <Route path='/certs' component={LiveDigitalCerts} />

          <Route
            path='/deliveries/scanner/:qr_code?'
            component={AuthGuard(CourierScanner, ['courier'])}
          />

          <Route path='/signup' exact component={SignUp} />

          {$app.current_user?.gender == 'female' ? (
            <Route path='/signup/child' exact component={RegisterChild} />
          ) : null}

          <Route path='/login' exact component={Login} />
          <Route path='/logout' exact component={Logout} />
          <Route path='/otp' exact component={OTPScreen} />
          <Route path='/confirm-email' component={EmailVerified} />
          <Route path='/dashboard' component={AuthGuard(Dashboard)} />
          <Route path='/agency/:code' component={AuthGuard(AgencyDetails)} />
          <Route path='/citizens/:id?' component={AuthGuard(Citizens)} />
          <Route path='/profile' exact component={AuthGuard(Profile)} />
          <Route
            path='/transactions'
            exact
            component={AuthGuard(Transactions)}
          />
          <Route path='/stats' exact component={AuthGuard(Stats)} />
          <Route path='/forget-password' exact component={ForgetPassword} />
          <Route
            path='/reset-password/:token'
            exact
            component={ResetPassword}
          />
          <Route path='/nib-search' component={AuthGuard(NIBSearch)} />

          <Route
            path='/appointments'
            exact
            component={AuthGuard(AppointmentsCalendar)}
          />
          <Route
            path='/appointments/add'
            exact
            component={AuthGuard(CreateAppointment)}
          />
          <Route
            path='/appointments/edit/:id'
            exact
            component={AuthGuard(CreateAppointment)}
          />
          <Route
            path='/appointments/dashboard'
            exact
            component={AuthGuard(AppointmentDashboard)}
          />

          <Route
            path='/message-center/inbox'
            exact
            component={AuthGuard(Inbox)}
          />
          <Route
            path='/message-center/inbox/:id'
            exact
            component={AuthGuard(Detail)}
          />
          <Route
            path='/message-center/compose-mail'
            exact
            component={AuthGuard(ComposeMail)}
          />
          <Route
            path='/message-center/sent-mail'
            exact
            component={AuthGuard(SentMail)}
          />

          <Route path='/certs' exact component={AuthGuard(BlockcertsTable)} />
          <Route
            path='/certs/issue/:type'
            exact
            component={AuthGuard(BlockcertsForm)}
          />

          <Route path='/faqs' exact component={AuthGuard(FAQ)} />
          <Route path='/map' exact component={AuthGuard(MapPage)} />

          <Route
            path='/service-suggestions/form'
            component={ServiceSuggestions}
          />
          <Route
            path='/service-suggestions/list'
            component={SuggestionsTable}
          />

          <Route
            path='/mysc/culture/cultural-registry/registry'
            component={AuthGuard(PendingRegistries, ['registry'])}
          />

          <Route
            path='/officer-management'
            component={AuthGuard(RoleManagement, ['role_admin'])}
          />

          {generateServiceRoutes()}

          <Route path='/legal/terms-of-use' component={TermsOfUse} />
          <Route path='/legal/privacy-policy' component={PrivacyPolicy} />
          <Redirect to='/404' />
        </Switch>
      </Suspense>
    )
  }
}

export default withRouter(Routes)
