import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import Swal from 'sweetalert2'

export default class TarpDistribution extends React.Component {
  resource = '/urban_renewal_tarp_distributions'
  form_key = 'urban_renewal_tarp_distribution'
  free     = true

  componentDidMount () {
    this.before_apply()
  }

  blockApplication = () => {
    Swal.fire({
      title: 'Unable To Apply',
      icon: 'warning',
      html: `
        In order to apply for this service, you must create at least one
        Minor Roof Repair application.
        `,
        showCancelButton: true,
        cancelButtonText: 'Close',
        confirmButtonText: 'Go To Minor Roof Repairs',
        confirmButtonColor: '#252092',
      }).then(res => {
      const { path } = $app.getService('urban renewal home owner')
      res.isConfirmed && this.props.history.push(path)
    })
  
    return null
  }

  before_apply = async () => {
    if ('homes' in this) {
      if (this.homes.length) return
      return this.blockApplication()
    }

    const { data } = await $app.axios.get('/urban_renewal_home_owners')

    this.homes = data.records.map(r => ({
      value: r.id,
      label: `Application ${r.form_num}` +
        `(${r.application_decision.capitalize()} ${moment(r.updated_at).format('Do MMM, YYYY')})`,
    }))

    if (!this.homes?.length) return this.blockApplication()
  }

  skip_location = true
  hide_buttons  = false
  custom_actions = []
  validate = null
  foremost = null

  fields = form => [
    {
      name: 'home_owner_record_id',
      label: 'For Which Roof Repair Application is this for?',
      options: this.homes
    },
    {
      name: 'tarp_size',
      label: 'Size of Tarp',
      hint: 'In feet',
      datalist: [
        '13 x 20',
      ],
      view: {
        value: v => v + ' ft.',
      }
    },

    '::Home Owner Details',
    {
      name: 'homeowner_first_name',
      label: 'First Name',
    },
    {
      name: 'homeowner_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'homeowner_last_name',
      label: 'Last Name',
    },
    {
      name: 'homeowner_nib',
      label: 'NIB',
      is: 'integer'
    },
    {
      name: 'homeowner_address',
      label: 'Street Address',
    },
    {
      name: 'homeowner_po_box',
      label: 'P. O. Box',
      required: false
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
