import React, { useState } from 'react'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { ValidatorForm } from 'react-form-validator-core'

import InputField from '@shared/form-fields/input'
import '@styles/email-editor.css'

const EmailComposer = props => {
  const [subject, setSubject] = useState(props.defaultSubject)
  const [editor, setEditorState] = useState(EditorState.createEmpty())

  const on = {
    upload: () => {},
    reset: () => {
      setEditorState(EditorState.createEmpty())
      setSubject(props.defaultSubject)
    },
    submit: () => {
      const message = draftToHtml(convertToRaw(editor.getCurrentContent()))
      props.submit(message, subject)
    },
  }

  const inDropdown = { inDropdown: true }
  const toolbarProps = {
    inline: inDropdown,
    list: inDropdown,
    textAlign: inDropdown,
    link: inDropdown,
    history: inDropdown,
    image: {
      urlEnabled: false,
      uploadCallback: on.upload,
      alt: { present: false, mandatory: false },
    },
  }

  return (
    <div className='w-full'>
      <div className='modal-header'>
        <h5 className='modal-title' id='change-stat-modal-title'>
          Email User
        </h5>
      </div>
      <div className='modal-content'>
        <ValidatorForm id='custom-email-form' onSubmit={on.submit}>
          <div className='form-group'>
            <label htmlFor='subject'>Subject</label>
            <InputField
              onChange={ev => setSubject(ev.target.value)}
              name='subject'
              value={subject}
              validators={['required']}
              errorMessages={['This field is required']}
              className='form-control'
            />
          </div>
          <div className='form-group'>
            <label>Message</label>
            <Editor
              editorState={editor}
              toolbarClassName='wysiwyg-editor__toolbar'
              wrapperClassName='wysiwyg-editor__wrapper'
              editorClassName='wysiwyg-editor__editor'
              onEditorStateChange={setEditorState}
              toolbar={toolbarProps}
            />
          </div>
        </ValidatorForm>
      </div>
      <div className='modal-footer'>
        <div className='flex justify-end'>
          <button className='btn' onClick={on.reset}>
            Reset
          </button>
          <button
            className='btn custom-btn'
            type='submit'
            form='custom-email-form'
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailComposer
