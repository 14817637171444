import React from 'react'
import Modal from 'react-responsive-modal'
import { ValidatorForm } from 'react-form-validator-core'

import { extractKeys } from '@helpers/objects'
import range from '@helpers/range'
import InputField from '@shared/form-fields/input'
import Loader from '@shared/loader'
import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@/helpers/extract-service-props'

class DeedSearch extends React.Component {
  constructor(props) {
    super(props)
    this.times = range(1, 12).map(n => ({ value: n, label: n + (n - 1 ? ' Hours' : ' Hour') }))
  }

  state = {
    record: null,
    loading: false,

    code: '',
    receipt_number: '',
  }

  title    = 'Deed Search'
  agency   = 'rgd'
  item     = '471'
  admin    = 'registrar_general'
  resource = '/deed_searches'
  form_key = 'deed_search'

  skip_location = true

  custom_action = {
    text: 'Set Credentials',
    icon: 'hashtag',
    fn: record => this.setState({ record }),
  }

  hide_buttons = {
    status: true,
  }

  fee = ({ hour }) => hour * 10

  fields = () => [{
    name: 'hour',
    label: 'Access Time ($10/hr.)',
    options: this.times,
    view: {
      label: 'Access Time',
      value: v => v + ' Hour(s)',
    }
  }]

  hidden_fields = () => [
    {
      name: 'receipt_number',
      is: 'alphanumeric',
    },
    {
      name: 'code',
      label: 'Access Code',
      is: 'alphanumeric',
    },
  ]

  closeModal = () => this.setState({ record: null })

  on = {
    input: name => ev => this.setState({ [name]: ev.target.value }),

    submit: async ev => {
      this.setState({ loading: true })

      const { axios } = $app
      const { state, resource, form_key } = this
      const { record, code, receipt_number } = state

      const message = `
        Good day ${record.user.first_name.capitalize()},
        <br />
        Please see below your Receipt Number & Access Code to fulfill your Deed Search access request.
        <br /><br />
        Receipt Number : <b>${receipt_number}</b><br />
        Access Code : <b>${code}</b><br />
        </br>
        Please click <a href='https://rgd.bahamas.gov.bs/netdata/db2www.pgm/c_pdd.ndm/login'>here</a> to login with the provided credentials
        <br/><br />
        Thank you for using the MyGateway Portal.
      `

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
            application_status: true,
            ...extractKeys(state, 'code', 'receipt_number')
          }
        })

        try {
          const { data } = await axios.post('emails', {
            email: record.user.email,
            subject: 'MyGateway Portal: Deed Search Credentials',
            message,
          })

          data.message && !data.error
            ? toastr.success( 'Success', 'Email sent to citizen')
            : toastr.error('Error', data.message || data.error)
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to send email to citizen')
        }
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to set Receipt# and Access Code')
        return
      }

      this.setState({ loading: false, record: null })
    }
  }

  render () {
    const { state, on, closeModal } = this

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal open={state.record} style={{ width: 480 }} onClick={closeModal} center>
          <div className='modal-header'>
            <h5 className='modal-title'>Set Amount</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='receipt_number'>
                  Receipt Number
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='receipt_number'
                  icon='hashtag'
                  value={state.receipt_number}
                  onChange={on.input('receipt_number')}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                />
              </div>
              <div className='form-group form-show-validation'>
                <label htmlFor='code'>
                  Access Code
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='code'
                  icon='hashtag'
                  value={state.code}
                  onChange={on.input('code')}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button className='btn btn-round mr-2' aria-label='Close' onClick={closeModal}>Cancel</button>
              <input className='btn custom-btn btn-round' type='submit' value='Submit' />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
   )
  }
}

export default DeedSearch
