import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

import passportPhotoExample from '@helpers/passport-photo-example'

export default class DrivingTestApplicationPractical extends React.Component {
  resource = '/driving_test_application_practicals'
  form_key = 'driving_test_application_practical'

  skip_location = true
  // fee = 15

  fields = (form) => [
    // {
    //   name: 'permit_numb',
    //   label: 'Permit #',
    //   is: 'integer',
    // },
    {
      name: 'passport',
      label: 'Passport #',
      is: 'integer',
    },
    {
      name: 'days_list',
      multi: true,
      options: [
        { label: 'Monday (a.m. - p.m.)', value: 'Monday' },
        { label: 'Tuesday (a.m. - p.m.)', value: 'Tuesday' },
        { label: 'Wednesday (a.m. - p.m.)', value: 'Wednesday' },
        { label: 'Thursday (a.m. - p.m.)', value: 'Thursday' },
        { label: 'Friday (a.m. - p.m.)', value: 'Friday' },
      ]
    },
    {
      name: 'vehicle_type',
      label: 'Type of vehicle to be used for test',
      type: 'select',
      options: [
        { label: 'Class A (5,000 lbs. or less)', value: 'Class A' },
        { label: 'Class B (5001 lbs. - 15,000 lbs.)', value: 'Class B' },
        { label: 'Class C (15,000 lbs. or greater)', value: 'Class C' },
      ],
    },
    {
      name: 'registration_mark',
      label: 'Registration Mark of vehicle if known',
      required: false,
      is: 'text',
    },
    {
      type: 'select:bool',
      name: 'physical_disablities',
      label: 'Do you suffer any physical disablities?'
    },
    {
      name: 'physical_disabilities_list',
      label: 'Physical disabilities from which you suffer as stated in your application for a licence,',
      hide: !form.physical_disablities,
      columns: ['physical_disabilities'],
      format: v => v.physical_disabilities
    },
    {
      type: 'select:bool',
      name: 'tested_before',
      label: 'Were you tested before?'
    },
    {
      type: 'date',
      name: 'last_test',
      hide: !form.tested_before,
      label: 'State date of last test on a vehicle in the same group',
      showYearDropdown: true,
      required: false,
    },

    '::Uploads',
    {
      name: 'passport_upload',
      label: 'Passport upload',
      type: 'file:image',
      key: 'image.0',
      required: false,
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
