import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import countries from "@constants/countries";
import islands from "@constants/islands";
import animals from "@constants/animals";
import { AnimalRequestDetails } from "./_item-imports";

export default class DomesticAnimals extends React.Component {
  id = "DomesticAnimalImportPermit";
  service = "domestic animal import permit";
  title = "Application for Permit to import Domestic Animals";
  agency = "moa";
  resource = "/domestic_animal_import_permits";
  form_key = "domestic_animal_import_permit";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "animal_type",
      label: "Type of Animal",
      options: animals,
    },
    {
      name: "number_of_animals",
      label: "Number of Animals",
      is: "integer",
    },
    {
      name: "animal_breed",
      label: "Breed",
    },
    ":: Age",
    {
      name: "animal_age_years",
      label: "Years",
    },
    {
      name: "animal_age_months",
      label: "Months",
    },
    "::SPACER",
    {
      name: "animal_sex",
      options: ["Male", "Female"],
    },
    {
      name: "neutered",
      label: "Neutered?",
      type: "select:bool",
    },
    {
      name: "homeagain",
      label: "HomeAgain?",
      type: "select:bool",
    },
    {
      name: "avid",
      label: "Avid?",
      type: "select:bool",
    },
    {
      name: "destron",
      label: "Destron?",
      type: "select:bool",
    },
    {
      name: "trovan",
      label: "Trovan?",
      type: "select:bool",
    },
    {
      name: "other_option",
      label: "Other?",
      type: "select:bool",
    },
    {
      name: "other",
      label: "Other",
      hide: !form.other_option,
    },
    {
      name: "microchip_numb",
      label: "Microchip Number",
      is: "integer",
    },
    {
      name: "initial_country_of_export",
      label: "Initial Country of Export",
      options: countries,
    },
    "::Port of Entry",
    {
      name: "initial_entry_port",
      label: "Initial Port of Entry into the Bahamas",
      options: islands,
    },
    "::SPACER",
    {
      name: "country_of_birth",
      label: "Country of birth of animals",
      options: countries,
    },
    {
      name: "date_of_arrival",
      label: "Anticipated Date of Arrival",
      type: "date",
      maxDate: new Date(),
    },
    "::Purpose of Importing Animals",
    {
      name: "pet",
      label: "Pet",
      type: "checkbox",
      required: false,
    },
    {
      name: "vacation",
      label: "Vacation",
      type: "checkbox",
      required: false,
    },
    {
      name: "service_animal",
      label: "Service Animal",
      type: "checkbox",
      required: false,
    },
    {
      name: "resale",
      label: "Re-sale",
      type: "checkbox",
      required: false,
    },
    {
      name: "other_purpose",
      label: "Other",
      type: "checkbox",
      required: false,
    },
    {
      name: "purpose_other",
      label: "Other Purpose",
      hide: !form.other_purpose,
    },
    {
      name: "entry_port",
      label: "Expected Island and Port of Entry name",
      options: islands,
    },
    {
      name: "visiting_islands",
      label: "Expected Islands visiting while in the Bahamas",
      multi: true,
      hint: "Select ALL of the options that apply",
      options: islands,
    },
    {
      name: "duration_of_stay",
      label: "Expected duration of stay in the Bahamas",
      hint: "Days",
      is: "integer",
    },
    {
      name: "animal_request_details",
      columns: AnimalRequestDetails,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
