import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class TheatreLicenceTransfer extends React.Component {
  resource = '/'
  form_key = ''
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  hooks = {
    // 'read': async () => {
    //   const url = '/operate_theatre_licenses/show_transferee_requests'
    //   const { data } = await $app.axios.get(url)
    // }
  }

  fields = form => []

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
