import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
//import nationalities from '@constants/nationalities'

export default class DeregistrationCertificate extends React.Component {
  resource = '/deregistration_certificates'
  form_key = 'deregistration_certificate'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    /*{
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
        
    }, */
    {
      name: 'nationality',
      label: 'Nationality or common mark and registration mark'
      //options: nationalities,
      //hide: form.i_am_applicant != false
    },
    /*{
      name: 'common_mark',
      label:'common mark and registraion mark',
      type: 'textarea'
    },*/
    {
        name: 'man_designation',
        label: `Manufacturer and manufacturer's designation of aircraft`
    },
    {
      name: 'serial_num',
      label: 'Aircraft serial no.'
    },
    {
      name: 'issued_to',
    },
    {
        name: 'reg_basis',
        label: 'Basis of registration',
        options: ['ownership of aircraft', 'operator of aircraft', 'other']
    },
    {
        name: 'explain_other',
        label: 'Explain',
        type: 'textarea',
        hide: form.registration_basis != 'other'
    },
    {
      name: 'owner_address',
       label: 'Address of owner certificate holder',
       hint: 'at the time of deregistration'
       //hide: form.i_am_applicant != false
    },
    {
        name: 'dereg_reason',
        label: 'Reason(s) for deregistration, if known',
        columns: [
          {
            name:'reason',
            type: 'textarea'
          }
        ],
        required: false
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
