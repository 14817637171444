import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class NativeSelectField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () =>
    this.state.isValid ? null : (
      <div style={this.errorStyles}>{this.getErrorMessage()}</div>
    )

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      options,
      required,
      ...rest
    } = this.props

    if (!rest.className) rest.className = 'form-control'
    if (!this.state.isValid) rest.className += ' input_error'

    const list = options.map((opt, i) => (
      <option key={i} value={opt.value}>
        {opt.label}
      </option>
    ))

    return (
      <React.Fragment>
        <div className='input-group'>
          <select {...rest}>
            <option value=''></option>
            {list}
          </select>
        </div>
        {this.errorText()}
      </React.Fragment>
    )
  }
}

export default NativeSelectField
