import React from 'react'
import countries from '@constants/countries'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'
import moment from 'moment'
import nationalities from '@constants/nationalities'
import convertToBase64 from '@helpers/file-to-base64'

const today = moment()
const yearsAgo = (minAge) => today.clone().subtract(minAge, 'years').toDate()

// form.division_selected != 'sports' ? [] : []
const youthFields = (formState, form) => [
  {
    heading: "Youth Information",
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'relation_to_youth',
    label: 'Relation to Youth',
    options: ['parent', 'guardian', 'relative'],
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_first_name',
    label: 'First Name',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_middle_name',
    label: 'Middle Name',
    hide: true,
    view: !form.youth_campers_list?.length,
    required: false,
  },
  {
    name: 'youth_last_name',
    label: 'Last name',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_date_of_birth',
    label: 'Date of birth',
    type: 'date',
    showYearDropdown: true,
    minDate: yearsAgo(14),
    maxDate: yearsAgo(5),
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_age',
    label: 'Age',
    type: 'integer',
    hide: true,
    view: !form.youth_campers_list?.length,
    callback: () => {
      if (form.youth_date_of_birth) {
        const today = new Date();
        const dob = new Date(form.youth_date_of_birth)
        const monthCheck = today.getMonth() >= dob.getMonth()
        const dayCheck = today.getDate() >= dob.getDate()
        const minusYear = monthCheck && dayCheck ? 0 : 1
        const result = (today.getFullYear() - dob.getFullYear()) - minusYear
        if (result < 5 || result > 14) {
          Swal.fire(
            `Summer Camp is only intended for children between the ages of 5 and 14 years of age`,
            '',
            'error',
          )
          return { youth_age: '' }
        }
        return { youth_age: result }
      }
    },
  },
  {
    name: 'youth_gender',
    label: 'Gender',
    options: ['male','female'],
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_place_of_birth',
    label: 'Place of birth',
    options: countries,
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_nationality',
    label: 'Nationality',
    options: nationalities,
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_school_name',
    label: 'Name of School',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_grade_level',
    label: 'Grade Level',
    options: ['K5', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'youth_address',
    label: "Address",
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'subdivision',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  // {
  //   name: 'po_box',
  //   label: 'P.O. Box',
  // },
  {
    name: 'youth_phone',
    label: 'Phone number',
    type: 'phone',
    hide: true,
    view: !form.youth_campers_list?.length,
    required: false,
  },
  // {
  //   name: 'personal_cell_number',
  //   label: 'Cell/parent work number',
  //   type: 'phone',
  // },
  {
    name: 'youth_email_address',
    type: 'email',
    hide: true,
    view: !form.youth_campers_list?.length,
    // required: false,
  },
  {
    name: 'has_attended',
    label: 'Has the child attended a Ministry of Youth, Sports & Culture camp before?',
    type: 'select:bool',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'attended_year',
    label: 'What year did he/she attend?',
    type: 'integer',
    hide: !form.has_attended,
  },
  {
    name: 'preferred_camp',
    label: formState.campLabel(form),
    options: formState.campOptions(form),
    hide: true,
    view: !form.youth_campers_list?.length,
    hint: 'Please indicate your top choice',
  },

  {
    heading: "Medical Information",
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'health_condition',
    label: 'Does this child have any physical or health conditions that may affect his/her ability to participate fully in camp activities?',
    type: 'select:bool',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'health_condition_details',
    label:' Please specify precautions or special arrangements that would need to be taken under consideration',
    type: 'textarea',
    hide: !form.health_condition,
  },
  {
    name: 'allergies',
    label: 'Does this child have any known allergies',
    type: 'select:bool',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'allergies_details',
    label: 'Type(s) of Allergies',
    type: 'textarea',
    hide: !form.allergies,
  },
  {
    name: 'medication',
    label: 'Is the child currently taking any medications?',
    type: 'select:bool',
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'medication_details',
    label: 'Name(s) of medication(s)',
    type: 'textarea',
    hide: !form.medication,
  },
  {
    name: 'doc_first_name',
    label: `Physician's first name`,
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'doc_last_name',
    label: `Physician's last name`,
    hide: true,
    view: !form.youth_campers_list?.length,
  },
  {
    name: 'doc_phone',
    label: `Physician's phone number`,
    type: 'phone',
    hide: true,
    view: !form.youth_campers_list?.length,
  },

  // "::Civil Involvement",
  // {
  //   name: 'membership',
  //   label: 'Does the applicant hold membership in a youth organization or after school programme?',
  //   type: 'select:bool',
  // },
  // {
  //   name: 'position',
  //   label: 'Does he/she hold a position in the organiation?',
  //   type: 'select:bool',
  //   hide: !form.membership, 
  // },
  // {
  //   name: 'position_details',
  //   label: 'Specify position held',
  //   hide: !form.position, 
  // },
]

export default class SummerCampBase extends React.Component {
  resource = '/summer_camps'
  form_key = 'summer_camp'

  isYouth = /youth/.test(this.props.location.pathname)
  isCulture = /culture/.test(this.props.location.pathname)
  isSports = /sports/.test(this.props.location.pathname)

  // Shutdown applications
  componentDidMount() {
    (this.isYouth || this.isCulture || this.isSports) && Swal.fire(
      'Applications Closed',
      `
        Sorry, but this application form has reached its deadline.
      `,
      'info'
    )
  }


  before_apply = () => {
    if (this.isYouth || this.isCulture || this.isSports)
      return `
        Sorry, but this application form has reached its deadline.
      `
  }

  decideDivision = () => {
    if (this.isSports) {
      return 'sports'
    } else if (this.isCulture) {
      return 'culture'
    } else {
      return 'youth'
    }
  }

  defaults = {
    division_selected: this.decideDivision(),
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        division_selected: this.defaults.division_selected
      },
    },
  }

  // Delete lines as needed
  // fee           = 99
  free          = true
  // postpaid      = true
  skip_location = true
  hide_buttons  = {
    status: true,
    deny: true,
  }

  custom_actions = [
    {
      text: 'Verify Documents',
      icon: 'id-badge',
      roles: ['officer'],
      test: r => r.application_decision != 'verified',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to verify record documents?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and verified the authenticity of the accompanying attachments. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.verifyRecord(record)
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['officer'],
      test: r => r.application_decision == 'verified',
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.approve(record)
          }
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      // test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]

  verifyRecord = async (record) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'verified',
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'verified'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    this.setState({ loading: false })
  }

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    this.setState({ loading: false })
  }

  validate = async values => {
    const update = {}

    if(values.youth_campers_list[0]) {
      update.youth_campers_list = []

      for (let row of values.youth_campers_list) {
        const {
          photo_upload,
          child_proof_upload,
          ...item
        } = row

        if (photo_upload) {
          item.photo_upload = await convertToBase64(photo_upload)
          item.child_proof_upload = await convertToBase64(child_proof_upload)
          update.youth_campers_list.push(item)
        }
      }
    }

    return update
  }

  foremost = null

  childProofLabel = (form) => {
    if (form.choose_upload == 'Birth Certificate') {
      return 'Registered birth certificate of minor'
    }
    else if (form.choose_upload == 'Adoption Certificate') {
      return 'Certified Adoption Certificate'
    }
    else if (form.choose_upload == 'Certified Court Order') {
      return 'Certified Court Order for proof of legal Guardianship'
    }
    else if (form.choose_upload == 'Letter from Social Services') {
      return 'Valid letter from Department of Social Services, where the minor is under state care'
    } else {
      return 'Child Identification Documents'
    }
  }

  campLabel = form => {
    if (form.division_selected == 'youth') {
      return 'Select camp of your choice'
    } else if (form.division_selected == 'sports') {
      return 'Program Options'
    } else {
      return 'Program Options'
    }
  }

  campOptions = form => {
    let options = []

    if (form.division_selected == 'youth') {
      options = [
        { 
          value: 'Camp iMatter',
          label: 'Camp iMatter',
        },
        { 
          value: 'CampLit',
          label: 'CampLit',
        },
      ] 
    } else if (form.division_selected == 'sports') {
      options = [
        'Basketball',
        'Volleyball',
        'Baseball',
        'Soccer',
        'Softball',
        'Boxing',
        'Archery',
        'Tennis',
        { value: 'Flag Football', label: 'Flag Football'},
        // { value: 'Beep Baseball', label: 'Beep Baseball'},
        { value: 'Swimming', label: 'Swimming(located at south beach swimming pools)'},
      ]
    } else {
      options = [
        { value: 'Drumming', label: 'Drumming (located at Albury Sayles Primary School)'},
        { value: 'Band', label: 'Band - *Must have own instrument (located at Albury Sayles Primary School)'},
        { value: 'Choir', label: 'Choir (located at Albury Sayles Primary School)'},
        { value: 'Art & Craft', label: 'Art & Craft (located at RM Bailey Senior High School)'},
        { value: 'Theatre (YE)', label: 'Theatre (located at Yellow Elder Primary School)'},
        { value: 'Junkanoo Craft', label: 'Junkanoo Craft (located at Yellow Elder Primary School)'},
        { value: 'Film', label: 'Film - age 8+ (located at LW Young Junior High School)'},
        { value: 'Boys Culture Camp', label: 'Boys Culture Camp - *Boys only (located at Stephen Dillet Primary School)'},
        { value: 'Religious Culture Camp', label: 'Religious Culture Camp *Girls and Boys (located at Stephen Dillet Primary School)'},
        { value: 'Dance', label: 'Dance (located at Centreville Primary School)'},
        { value: 'Theatre (CF)', label: 'Theatre (located at Carlton Francis Primary School)'},
        { value: 'Pageantry', label: 'Pageantry (located at Fox Hill Community Centre)'}
      ]
    }

    return options
  }

  columns = props => [
    {
      name: 'Camp',
      selector: row => row.preferred_camp?.capitalize() || row.youth_campers_list.map(x => x.preferred_camp?.capitalize()).join(', ') || 'N/A',
      sortable: true,
      searchable: r => r.preferred_camp,
    },
    {
      name: 'Camper Name(s)',
      selector: row => {
        const youthFirst = row.youth_first_name?.capitalize();
        const youthLast = row.youth_last_name?.capitalize();
        const camperNames = row.youth_campers_list?.map(x => {
          const youthFirst = x.first_name?.capitalize();
          const youthLast = x.last_name?.capitalize();
          return `${youthFirst} ${youthLast}`
        })?.join(', ');

        if (youthFirst) {
          return `${youthFirst} ${youthLast}`
        } else if(camperNames) {
          return camperNames
        } else { return 'N/A' }
      },
      sortable: true,
      searchable: r => r?.youth_first_name,
    },
  ]

  fields = form => [
    {
      name: 'division_selected',
      save: true,
      hide: true,
    },
    {
      heading: `
      <p>
        Please note, the information requested below is in reference to the youth being registered for Summer Camp.
      </p>`,
      view: false,
    },
  
    // Allow for multiple campers per list
    "::Youth Information",
    {
      name:'youth_campers_list',
      label:'Information about the summer campers',
      rowLabel: 'Summer Camper',
      min: 1,
      // hide: form.division_selected != 'sports',
      fields: [
        'first_name',
        {
          name: 'middle_name',
          required: false,
        },
        'last_name',
        {
          name: 'relation_to_youth',
          label: 'Relation to this Camper',
          options: ['parent', 'guardian', 'relative'],
        },
        {
          name: 'youth_date_of_birth',
          label: 'Date of birth',
          type: 'date',
          showYearDropdown: true,
          minDate: yearsAgo(14),
          maxDate: yearsAgo(5),
        },
        {
          name: 'youth_age',
          label: 'Age',
          type: 'integer',
          // callback: (v, s, index) => {
          //   if (form.youth_campers_list[index].youth_date_of_birth) {
          //     if (v < 5 || v > 14) {
          //       Swal.fire(
          //         `Summer Camp is only intended for applicants between the ages of 5 and 14 years of age`,
          //         '',
          //         'error',
          //       )
          //     }
          //   } else {
          //     Swal.fire(
          //       `Must set Date of birth field first`,
          //       '',
          //       'error',
          //     )
          //   }
          // },
          onBlur: (v) => {
            if (v < 5 || v > 14) {
              Swal.fire(
                `Summer Camp is only intended for applicants between the ages of 5 and 14 years of age`,
                '',
                'error',
              )
            }
          },
        },
        {
          name: 'youth_gender',
          label: 'Gender',
          options: ['male','female'],
        },
        {
          name: 'youth_place_of_birth',
          label: 'Place of birth',
          options: countries,
        },
        {
          name: 'youth_nationality',
          label: 'Nationality',
          options: nationalities,
        },
        {
          name: 'youth_school_name',
          label: 'Name of School',
        },
        {
          name: 'youth_grade_level',
          label: 'Grade Level',
          options: ['K5', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
        },
        {
          name: 'youth_address',
          label: "Address",
        },
        {
          name: 'subdivision',
        },
        {
          name: 'youth_phone',
          label: 'Phone number',
          type: 'phone',
          required: false,
        },
        {
          name: 'youth_email_address',
          type: 'email',
          // required: false,
        },
        {
          name: 'has_attended',
          label: 'Has the child attended a Ministry of Youth, Sports & Culture camp before?',
          type: 'select:bool',
        },
        {
          name: 'attended_year',
          label: 'What year did he/she attend?',
          type: 'integer',
          hide: (index) => !form.youth_campers_list[index].has_attended,
          required: false,
        },
        {
          name: 'preferred_camp',
          label: this.campLabel(form),
          options: this.campOptions(form),
          hint: 'Please indicate your top choice',
          callback: async (v, s, index) => {
            if(form.division_selected == 'culture') {
              const youthCampersList = form.youth_campers_list
              let campLocation = ''
              if (v == 'Drumming') campLocation = 'Albury Sayles Primary School';
              if (v == 'Band') campLocation = 'Albury Sayles Primary School';
              if (v == 'Choir') campLocation = 'Albury Sayles Primary School';
              if (v == 'Art & Craft') campLocation = 'RM Bailey Senior High School';
              if (v == 'Theatre (YE)') campLocation = 'Yellow Elder Primary School';
              if (v == 'Junkanoo Craft') campLocation = 'Yellow Elder Primary School';
              if (v == 'Film') campLocation = 'LW Young Junior High School';
              if (v == 'Boys Culture Camp') campLocation = 'Stephen Dillet Primary School';
              if (v == 'Religious Culture Camp') campLocation = 'Stephen Dillet Primary School';
              if (v == 'Dance') campLocation = 'Centreville Primary School';
              if (v == 'Theatre (CF)') campLocation = 'Carlton Francis Primary School';
              if (v == 'Pageantry') campLocation = 'Fox Hill Community Centre';

              youthCampersList[index].camp_location = campLocation
              return {youth_campers_list: youthCampersList}
            }
          }
        },
        {
          name: 'camp_location',
          hide: form.division_selected != 'culture',
          view: true,
          save: true,
          disabled: true
        },
        {
          heading: `
          <h5 style="margin-left: 2rem;">
          Medical Information
          </h5>`,
          view: false,
          save: false,
        },
        {
          name: 'health_condition',
          label: 'Does this child have any physical or health conditions that may affect his/her ability to participate fully in camp activities?',
          type: 'select:bool',
        },
        {
          name: 'health_condition_details',
          label:' Please specify precautions or special arrangements that would need to be taken under consideration',
          type: 'textarea',
          hide: (index) => !form.youth_campers_list[index].health_condition,
          // hint: 'Type N/A if not applicable'
        },
        {
          name: 'allergies',
          label: 'Does this child have any known allergies?',
          type: 'select:bool',
        },
        {
          name: 'allergies_details',
          label: 'Type(s) of Allergies',
          type: 'textarea',
          hide: (index) => !form.youth_campers_list[index].allergies,
          // hint: 'Type N/A if not applicable'
        },
        {
          name: 'medication',
          label: 'Is the child currently taking any medications?',
          type: 'select:bool',
        },
        {
          name: 'medication_details',
          label: 'Name(s) of medication(s)',
          type: 'textarea',
          hide: (index) => !form.youth_campers_list[index].medication
          // hint: 'Type N/A if not applicable'
        },
        {
          name: 'doc_first_name',
          label: `Physician's first name`,
        },
        {
          name: 'doc_last_name',
          label: `Physician's last name`,
        },
        {
          name: 'doc_phone',
          label: `Physician's phone number`,
          type: 'phone',
        },
        {
          heading: `
          <h5 style="margin-left: 2rem;">
          Uploads
          </h5>`,
          view: false,
        },
        {
          name: 'photo_upload',
          label: 'Camper photo(Recent headshot)',
          key: 'image.0',
          type: 'file:image'
        },
        {
          name: 'choose_upload',
          label: "Please upload one of the following documents to verify you are the applicant's parent or guardian",
          options: ['Birth Certificate', 'Adoption Certificate', 'Certified Court Order', 'Letter from Social Services'],
        },
        {
          name: 'child_proof_upload',
          // label: this.childProofLabel(form),
          label: 'Child Identification Documents',
          hint: `If the document is not uploaded, you must visit the Division of 
          <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
          key: 'image.1',
          type: 'file:all',
          required: false,
        },
      ],
    },
    ...youthFields(this, form),

    "::Parent Contacts",
    {
      name: 'parent_contact_list',
      rowLabel: 'Parent/Guardian',
      fields: [
        {
          name: 'first_name',
          // label: 'First name of parent/guardian'
        },
        {
          name: 'last_name',
          // label: 'Last name of parent/guardian'
        },
        {
          name: 'relationship_to_youth',
          label: 'Relation to camper',
          options: ['parent', 'guardian'],
        },
        {
          name: 'phone_contact',
          // label: 'Parent/Guardian phone contact',
          type: 'phone'
        },
        {
          name: 'alt_phone_contact',
          label: 'Alternate phone contact',
          type: 'phone',
          required: false,
        },
        {
          name: 'email',
          label: 'Email Address',
          type: 'email',
          // required: false,
        }
      ]
    },

    "::Emergency Contacts",
    {
      name: 'emergency_contact_list',
      rowLabel: 'Emergency Contact',
      fields: [
        {
          name: 'first_name',
          // label: 'First name of parent/guardian'
        },
        {
          name: 'last_name',
          // label: 'Last name of parent/guardian'
        },
        {
          name: 'relationship_to_youth',
          label: 'Relation to camper',
          options: ['parent', 'guardian', 'relative'],
        },
        {
          name: 'phone_contact',
          // label: 'Parent/Guardian phone contact',
          type: 'phone'
        },
        {
          name: 'alt_phone_contact',
          label: 'Alternate phone contact',
          type: 'phone',
          required: false,
        }
      ]
    },

    "::SPACER",
    "::Uploads",
    {
      name: 'photo_upload',
      label: 'Camper photo(Recent headshot)',
      key: 'image.0',
      type: 'file:image',
      hide: true,
      view: !form.youth_campers_list?.length,
    },
    {
      name: 'choose_upload',
      label: "Please upload one of the following documents to verify you are the applicant's parent or guardian",
      options: ['Birth Certificate', 'Adoption Certificate', 'Certified Court Order', 'Letter from Social Services'],
      hide: true,
      view: !form.youth_campers_list?.length,
    },
    {
      name: 'child_proof_upload',
      label: this.childProofLabel(form),
      hint: `If the document is not uploaded, you must visit the Division of 
      <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} Officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.1',
      type: 'file:all',
      required: false,
      hide: true,
      view: !form.youth_campers_list?.length,
    },
    {
      name: 'guardian_proof_upload',
      label: 'Identification documents of parent or legal guardian',
      hint: `If the document is not uploaded, you must visit the Division of 
      <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.2',
      type: 'file:all',
      // hide: form.choose_upload != 'Birth Certificate',
      required: false,
    },
    {
      name: 'disclaimer',
      label: `I hereby give full approval for the applicant(s) to participate in all activities of the Ministry of Youth, Sports and 
        Culture's Summer Programme and in so doing exempt The Bahamas Government, the Ministry of Youth, Sports, 
        and Culture and all its agents or assigns from any action or future claims`,
      type: 'checkbox',
    },
    {
      name: 'declare',
      label: `I confirm that the information submitted is true and that any 
        application submitted on behalf of a minor has been completed 
        by their parent/guardian`,
      type: 'checkbox',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
