import React, { useEffect, useState } from 'react'
import Toggle from 'react-switch'

import { fullName } from '@modules/form-wizard/utils'
import { useInspection } from '.'
import { FaSyncAlt } from 'react-icons/fa'

const FarmSelection = () => {
  const ctx = useInspection()
  const [farms, setFarms] = useState(null)
  const [reset, setReset] = useState(0)
  const [renewals, setRenewals] = useState(false)

  useEffect(() => {

    ctx.fetchApplications(renewals)
      .then(list => setFarms(list))
  }, [reset, renewals])

  const select = farm => () => {
    console.log(farm)
    ctx.farm = farm
    ctx.step++
  }

  const cards = farms?.length ? farms.map(f => {
    return (
      <article key={f.id} className='farm-selection' onClick={select(f)}>
        <h5 className='' children={fullName(f.user)} />
        <h6 children={f.farm_street_address} />
      </article>
    )
  }) : (
    <div className='flex justify-center items-center h-full'>
      <p>No Pending Inspections Found.</p>
    </div>
  )

  return (
    <React.Fragment>
      <h4 className='text-center mb-8'>Select An Application</h4>

      <nav
        className='flex justify-between mx-auto mb-4 pb-2'
        style={{ borderBottom: '2px solid #454545' }}
      >
        <div className='flex items-center space-x-4'>
          <span>New</span>
          <Toggle
            checked={renewals}
            offColor='#369'
            onColor='#070'
            onChange={bool => setRenewals(bool)}
          />
          <span>Renewal</span>
        </div>
        <button
          className='flex items-center btn hover:bg-gray-300'
          onClick={() => setReset(Date.now())}
        >
          <FaSyncAlt size={16} />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Refresh</span>
        </button>
      </nav>
      <div className='flex flex-col'>
        {cards}
      </div>
    </React.Fragment>
  )
}

export default FarmSelection
