import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import JsEncrypt from 'jsencrypt'
import Modal from 'react-responsive-modal'
import memoize from 'memoize-one'
import { Printd } from 'printd'

import Swal from '@sweetalert'
import Alert from '@modules/alert'
import toastr from '@modules/toastr'
import { getPosition } from '@modules/geo'
import AuthGuard from '@/wrappers/auth-guard'
import Loader from '@shared/loader'
import { extractKeys, getProperty, hasAnyKeys } from '@helpers/objects'
import { assertAll } from '@helpers/assert'

import Viewer from './view'
import LinkedViewer from './view.sub'
import Notes from './notes'
import SelectScreen from './select'
import GeneralTable from './table.general'
import DispatchTable from './table.dispatch'
import PaymentsTable from './table.payments'
import TableContainer from './table.container'
import SteppedForm from './form.container'
import * as STEPS from './steps'
import * as wutils from './utils'

import CannedEmailModal from './modals/canned-email'
import CustomEmailModal from './modals/custom-email'

import { sendMessage } from '@actions/notes'
import { getUserProfile } from '@actions/users'
import * as AgencyActions from '@actions/agencies'

import ENV from '@constants/env'
import mapUnavailabilityMessage from '@constants/maps.down'
import AgencyNames from '@constants/maps.agencies'
import mapServiceName from '@constants/maps.services'

import '@styles/banner.css'
import verifyDigitalCert from '@modules/certs/verify'
import convertToBase64 from '@helpers/file-to-base64'

const CASHLESS_URL = ENV.CASHLESS_URL

class Wizard extends React.Component {
  constructor(props) {
    super(props)

    const { axios, current_user: user, roles } = $app

    this.token = null
    this.noteForm = React.createRef()
    this.any = {}
    this.axios = axios
    this.user = user
    this.is = this.generate.permissions(roles, props.service.agency)
    this.state = this.generate.state(this.is)

    !props.deliveries?.length && props.getDeliveries()
    !props.locations?.length && props.getAgencyLocations()
    !props.profile && props.getUserProfile()

    this.setCannedResponses(props.service, props.canned)
  }

  async componentDidMount() {
    const { state, props, is } = this
    const { view } = props.location.state || {}

    if (props.service.under_maintenance) {
      await Swal.fire(
        'Service Unavailable',
        `
          Sorry, but this service is currently under maintenance.
          Please try again later or reach out to our helpdesk toll free at
          ${ENV.HELPDESK.HTML.PHONE} or email ${ENV.HELPDESK.HTML.EMAIL}.
        `,
        'warning'
      )

      props.history.goBack()
    }

    if (props.match.isExact && view) {
      this.viewSubmission(view, true)()
    } else {
      state.agency?.id && this.setCannedResponses(state.agency)
      is.dispatcher && this.find.delivery(props.deliveries)
      this.CRUD('read')({ reset: true }, this.checkForPendingApplication)
    }

    if (props.refresh_every && (is.admin || is.dispatcher)) {
      this.__timer = setInterval(() => {
        this.state.mode != 'paid' &&
          this.CRUD('read')({ reset: true, refresh: true })
      }, props.refresh_every)
    }
  }

  componentDidUpdate() {
    const { state, props, find } = this
    const { delivery } = state
    const { deliveries } = props

    if (!delivery && deliveries?.length) {
      find.delivery(deliveries)
    }
  }

  componentWillUnmount() {
    if (this.__timer) clearInterval(this.__timer)
  }

  generate = {
    permissions: (roles, { code, admin: adm, dispatcher: dis }) => {
      const admin = roles.includes(adm)
      const dispatcher = roles.includes(dis || code + '_dispatcher')
      const support = roles.includes('support')

      return {
        admin,
        dispatcher,
        support,
        citizen: !admin && !dispatcher && !support,
      }
    },

    state: ({ admin, dispatcher }) => ({
      tkey: 0, // Increment to force a re-render of the records table
      mode: (admin && 'admin') || (dispatcher && 'dispatcher') || 'citizen',
      modal: false,
      block: null,
      loading: false,
      records: [],
      banner: '',
      notes: null,
      query: null,
      query_only_paid: true,

      delivery: null,
      eligible: true,
      inProgress: true,

      metadata: { page: 1, per_page: 10 },
    }),
  }

  with = {
    authToken: token => ({
      headers: {
        Authorization: token || this.token,
      },
    }),
  }

  find = {
    dependents: async services => {
      if (!services?.length) return null

      const forms = {}

      for (let i = services.length; i--;) {
        const key = services[i]
        const { data } = await this.axios.get('/services/dependent/' + key)
        forms[key] = extractKeys(
          data,
          'id',
          'application_decision',
          'updated_at'
        )
      }

      return forms
    },

    delivery: async deliveries => {
      const { props, user } = this
      const hit = deliveries.find(
        d =>
          !d.delivered &&
          d.officer.id == user.id &&
          d.services.includes(props.service.type)
      )

      if (hit) {
        const { data } = await this.axios.get('/deliveries/' + hit.qr_code)
        this.delivery.__cache[hit.id] = null

        this.setState(state => {
          state.delivery = { ...hit, statuses: data.statuses }
          return state
        })
      }
    },
  }

  fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(CASHLESS_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        console.error(err)
      }
    },

    rsa: async () => {
      try {
        const { data } = await axios.get(CASHLESS_URL + '/fetch_rsa_key')
        return {
          pubkey: data.rsa_key.public_key,
          secret: data.rsa_key.rsa_token,
        }
      } catch (err) {
        console.error(err)
      }
    },

    invoice: async id => {
      try {
        const Authorization = await this.fetch.token()
        const { data } = await axios.get(
          CASHLESS_URL + '/invoices/by_invoice_number/' + id,
          {
            headers: { Authorization },
          }
        )

        return data
      } catch (err) {
        console.error(err)
      }
    },

    profile: async id => {
      try {
        const { data } = await this.axios.get(
          '/profiles' + (id ? '/' + id : '')
        )
        return data
      } catch (err) {
        console.error(err)
        return null
      }
    },

    company: async (bl_numb = '', nib = '') => {
      try {
        const { data } = await $app.axios.post('/fetch_company_verification', {
          use_tin: false,
          bl_numb,
          nib
        })
        return data
      } catch (err) {
        console.error(err)
        return null
      }
    },

    FPOCompany: async (bl_numb = '') => {
      try {
        const { data } = await $app.axios.post('/fetch_company_verification', {
          use_tin: true,
          bl_numb,
          nib: '###',
        })
        return data
      } catch (err) {
        console.error(err)
        return null
      }
    },
  }

  email = {
    canned: (name, email, user, form) => () => {
      this.note = {
        email,
        name,
        user,
        form,
        type: this.props.service.type,
      }

      this.setState({
        modal: 'email:create',
        message: '',
      })
    },

    custom: () => this.setState({ modal: 'email:custom' }),

    send: async (message = '', subject = '') => {
      const { note } = this

      this.props.sendMessage({
        ...note,
        message,
        subject,
      })

      this.setModal(null)()
    },
  }

  notes = {
    create: (record, params) => async () => {
      let data

      try {
        if (params) {
          data = await $app.axios.post('/services/notes/add_note', {
            note: {
              note_type: 'status',
              internal_view_only: true,
              notable_type: this.props.service_type || this.props.service.type,
              notable_id: record.id,
              ...params,
            },
          })
        } else {
          const { isDismissed } = await Swal.fire({
            title: 'Add Note',
            inputAttributes: {
              autocorrect: 'on',
            },
            showCancelButton: true,
            html: `
              <div class='flex flex-col items-stretch' style='margin:0 -1.6em'>
                <textarea
                  id='note__text'
                  autocorrect="on"
                  class="swal2-textarea"
                  placeholder="Please enter a message..."
                ></textarea>
                <input
                  id='note__file'
                  type="file"
                  autocorrect="on"
                  class="swal2-file"
                >
              </div>
            `,
            preConfirm: async () => {
              const { value: text } = document.getElementById('note__text')
              const { files } = document.getElementById('note__file')
              const { service, service_type } = this.props

              if (!text) {
                Swal.showValidationMessage('Please enter a message')
                return null
              }

              const payload = {
                note_type: 'note',
                internal_view_only: true,
                notable_type: service_type || service.type,
                notable_id: record.id,
                text,
              }

              try {
                if (files?.length)
                  payload.file_upload = await convertToBase64(files[0])
              } catch (err) {
                console.error(err)
                const msg = 'An error occurred while processing the file'
                Swal.showValidationMessage(msg)
                return null
              }

              data = await $app.axios.post('/services/notes/add_note', {
                note: payload,
              })

              return true
            }
          })

          if (isDismissed) return
        }

        record.notes.push(data.data.note)
        toastr.success('Success', 'Note successfully created')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to create new note')
      }
    },

    remove: id => async () => {
      try {
        await $app.axios.put(`/services/notes/${id}/soft_remove`)
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to remove note')
      }
    },

    view: record => () => this.setState({ notes: record.notes }),
    close: () => this.setState({ notes: null }),
  }

  delivery = {
    __cache: {},
    details: () => this.state.delivery || {},
    canEdit: () => this.state.delivery && !this.state.delivery.submitted,
    closed: () => this.state.delivery?.submitted,
    collected: () => this.state.delivery?.collected,
    collectable: () =>
      this.state.delivery?.submitted && !this.state.delivery?.collected,

    empty: () =>
      this.state.delivery?.service_forms?.reduce(
        (a, s) => [...a, ...(s.forms || [])],
        []
      ).length,

    getStatus: ({ delivery_id: id }) => {
      if (!id) return 'N/A'
      if (this.delivery.__cache[id]) return this.delivery.__cache[id]

      const target =
        this.state.delivery?.id == id
          ? this.state.delivery
          : this.props.deliveries.find(d => d.id == id)

      if (target) {
        let m = target.delivered
          ? 'DELIVERED ' + moment(target.delivery_date).format('YYYY-MM-DD')
          : 'READY FOR TRANSPORT'

        if (target.statuses?.length)
          m = target.statuses[target.statuses.length - 1]?.delivery_status

        this.delivery.__cache[id] = m
        return m
      }

      return null
    },

    send: async () => {
      const { qr_code } = this.state.delivery ?? {}

      try {
        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Send Batch To CCC',
          text: `
            Are you sure you want to mark the current batch as off
            to the Centralized Collections Centre?
          `,
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })

        if (isConfirmed) {
          const pos = await getPosition(false)

          await $app.axios.patch(`/deliveries/${qr_code}/collected`, {
            travel_time: 20,
            location: pos
              ? `${pos.latitude},${pos.longitude}`
              : '25.0461644,-77.3515153', // hardcoded coords to CCC
          })

          Swal.fire(
            'Batch Collected',
            'Batch successfully collected',
            'success'
          )
        }
      } catch (err) {
        console.error(err)

        Swal.fire('Error', 'Failed to mark the batch as in-transit.', 'error')
      }
    },

    add: records =>
      this.props.addDelivery(
        this.state.delivery.id,
        this.props.service.type,
        records.map(r => r.id),
        this.delivery.alterRecords(this.state.delivery.id, records)
      ),

    remove: records =>
      this.props.removeDelivery(
        this.state.delivery.id,
        this.props.service.type,
        records.map(r => r.id),
        this.delivery.alterRecords(null, records)
      ),

    create: () => {
      const { createDelivery, service } = this.props
      const timestamp = moment().format('YYYY-MM-DD - #HH:mm:ss')

      createDelivery({
        name: `${AgencyNames(service.type, 'full')} - ${timestamp}`,
        services: service.agency.service_types,
      })
    },

    submit: () =>
      this.props.closeDelivery(this.state.delivery.id, success => {
        success &&
          this.setState(state => {
            const u = Object.assign({}, state.delivery, { submitted: true })
            state.delivery = u
            return state
          })
      }),

    alterRecords: (delivery_id, altered) => () =>
      this.setState(state => {
        for (let { id } of altered) {
          const index = state.records.findIndex(r => r.id == id)

          if (index != -1) {
            state.records[index].delivery_id = delivery_id
          }
        }

        return state
      }),
  }

  appointment = {
    set: async (schedulable_id, appointment, retry = false) => {
      try {
        await $app.axios.post('/schedules', {
          ...appointment,
          schedulable_id,
          date: moment(appointment.date).format(),
        })

        toastr.success(
          'Success',
          'Appointment successfully set; you should receive a confirmation email shortly'
        )
      } catch (err) {
        if (retry) {
          let message = 'Unable to set appointment'
          toastr.error('Error', message)
          throw Error(err)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Appointment Failed',
            text: 'Although your payment has been processed, there has been a problem with scheduling your appointment. Please ensure you select a valid date and time for your appointment in order to be processed appropriately.',
          })
          throw Error(err)
        }
      }
    },

    setParams: appointment => {
      const { props, navigate } = this
      const { location, free } = props

      const [step, to] = free
        ? [STEPS.FINISH, 'form/success']
        : [STEPS.PAYMENT, 'form/pay']

      navigate(to, {
        appointment,
        appointment_pick: false,
      })

      free && this.appointment.set(location.state.record.id, appointment)
      this.setState({ step })
    },

    retry: async appt => {
      const { props, navigate, appointment } = this
      const { record, success_message } = props.location.state

      try {
        await appointment.set(record.id, appt, true)
        this.setState({ step: STEPS.FINISH })

        return navigate(
          'form/success',
          {
            success_message,
            success: true,
            paid: +record.payment_amount > 0,
            appointment_retry: true,
          },
          { overwrite: true }
        )
      } catch (err) {}
    },
  }

  proxy = {
    v1: {
      set: async (useProxy, email, show_toast = true) => {
        const { props, navigate, goToPayment } = this
        const { record, modify_proxy } = props.location.state

        if (!useProxy) {
          goToPayment(true)(record)()
          return
        }

        const { isConfirmed } = await Swal.fire({
          title: 'Confirm',
          html: `Are you sure you want to set the user with the email address <strong>${email}</strong> as your proxy for this application?`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })

        if (!isConfirmed) return
        this.setState({ loading: true })

        try {
          const { data } = await $app.axios.put('/services/set_proxy', {
            service_type: props.service.type,
            form_num: record.form_num,
            email,
          })

          if (/Sorry, no user was found/.test(data.message)) {
            toastr.warning('No User Found', data.message)
            this.setState({ loading: false })
            return
          }

          if (data.message == 'Proxy added successfully.') {
            show_toast &&
              toastr.success(
                'Success',
                'Proxy successfully added to application'
              )
            if (modify_proxy) {
              navigate('applications', null)
              this.setState({ step: STEPS.FORM })
            } else goToPayment(true)(record)()

            this.CRUD('read')({ reset: true })
          }
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Failed to set proxy user')
          this.setState({ loading: false })
        }
      },

      remove: async (record, show_toast = true) => {
        const { isConfirmed } = await Swal.fire({
          title: 'Confirm',
          text: 'Are you sure you want to remove your proxy?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Remove',
        })

        if (!isConfirmed) return
        this.setState({ loading: true })

        try {
          await $app.axios.put('/services/remove_proxy', {
            service_type: this.props.service.type,
            form_num: record.form_num,
          })

          show_toast &&
            toastr.success(
              'Success',
              'Proxy successfully removed from application'
            )
          this.CRUD('read')({ reset: true })
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Failed to remove proxy user')
          this.setState({ loading: false })
        }
      },

      modify: record => {
        this.navigate('form/proxy', {
          record,
          modify_proxy: true,
        })

        this.setState({ step: STEPS.PROXY_PICKUP })
      },
    },

    v2: {
      getApproved: async () => {
        try {
          const url = '/services/approved_proxies/' + this.props.service.type
          const { data } = await $app.axios.get(url)
          return data
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to fetch approved proxy requests')
        }
      },

      request: async ({ nib, ...child }) => {
        const { navigate } = this

        if (!nib) {
          navigate(null, {
            proxy_select: false,
            proxy: child,
          })

          this.setState({
            step: this.any?.dependencies ? STEPS.DEPENDENCIES : STEPS.FORM,
          })

          $app.setApplicant(child)
          return
        }

        try {
          this.setState({ loading: true })

          const { data } = await $app.axios.post('/services/proxy_request', {
            nib,
            service_type: this.props.service.type,
          })

          if (data.message) {
            const [heading, type] = /no user/.test(data.message)
              ? ['No User Found', 'warning']
              : ['Proxy Request Sent', 'success']

            toastr[type](heading, data.message)

            if (type == 'success') {
              this.setState({ step: STEPS.FORM })
              navigate('applications', null)
            }
          }
        } catch (err) {
          const { message } = err.response?.data || {}
          console.error(err)
          toastr.error(
            'Error',
            message || 'There was an error sending the proxy request'
          )
        } finally {
          this.setState({ loading: false })
        }
      },

      choose: async proxy => {
        try {
          const proxy_type = proxy
            ? proxy.confirmation_token
              ? 'request'
              : 'child'
            : 'none'

          await $app.axios.patch('/services/choose_proxy/', {
            id: proxy.id,
            proxy_type,
            service_type: this.props.service.type,
          })

          return true
        } catch (err) {
          console.error(err)
          return false
        }
      },
    },
  }

  searchRecords = async _params => {
    this.setState({ loading: true })

    const params = {
      ..._params,
      service: this.props.service.type ?? this.props.service_type,
      paid_only: !this.props.postpaid,
    }

    try {
      const { data: records } = await $app.axios.get(
        '/services/find_by_query',
        { params }
      )

      if (!records?.length) {
        this.setState({ loading: false })
        Swal.fire('Oops', 'No results found; please try another query', 'error')

        return
      }

      if (records.length == 1) {
        return this.viewSubmission(records[0])()
      }

      this.setState({
        mode: 'admin',
        query: { records },
        loading: false,
      })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })

      Swal.fire(
        'Error',
        'Failed to run a search; please try again later',
        'error'
      )
    }
  }

  // prettier-ignore
  clearSearch = () => this.setState(
    state => {
      state.inProgress = false
      state.query = null
      state.metadata.page = 1
      return state
    },
    () => this.CRUD('read')({ updating: true })
  )

  // prettier-ignore
  setTableParam = prop => value => this.setState(state => {
    state.metadata[prop] = value
    return state
  }, () => this.CRUD('read')({ updating: true }))

  // prettier-ignore
  resetTablePage = () => this.setState(
    state => {
      state.metadata.page = 1
    },
    () => this.CRUD('read')({ updating: true })
  )

  filterRecords = memoize((records, mode, delivery) => {
    if (!records?.length) return []
    if (mode == 'citizen' || this.props.show_approved) return records
    if (mode == 'payments') return records.filter(r => r.payment_status)

    if (mode == 'admin')
      return records.filter(r =>
        !/(approved|denied)/i.test(r.application_decision)
      )

    return records.filter(r =>
      /approve/i.test(r.application_decision)
      && /Collections Cent(re|er)/i.test(r.pickup_sub_location)
      && (!delivery || (r.delivery_id ? r.delivery_id == delivery : true))
    )
  })

  // prettier-ignore
  switchOfficerRole = mode => () => this.setState({ mode }, this.resetTablePage)

  setCannedResponses = (service, canned) => {
    const arr = []

    if (Array.isArray(canned)) {
      arr.push(...canned)
    } else if (typeof canned == 'object') {
      const add = Object.entries(canned).map(([label, value]) => ({
        label,
        value,
      }))

      arr.push(...add)
    }

    if (!this.props.overrides?.canned_messages) {
      const defaults = Object.entries({
        'Request More Information': `
          Thank you for your request. Your application requires additional
          information in order to proceed. Please contact the ${service.agency.name}
          for further details at ${service.agency.support_email}
        `.replace(/\s{2,}|\n/, ' '),

        'Notify Of Approval': `
          Thank you for your request. Your application has been successfully
          processed. Please visit the ${service.agency.name} to collect your
          ${service.title} at the MyGateway Express Pick Up Line.
        `.replace(/\s{2,}|\n/, ' '),

        'Notify Of Denial': `
          Thank you for your request for ${service.title}. Your application
          requires additional information and is unable to be processed at this
          time. For further assistance, please contact ${service.agency.name} at
          ${service.agency.support_email}
        `.replace(/\s{2,}|\n/, ' '),
      }).map(([label, value]) => ({ label, value }))

      arr.push(...defaults)
    }

    // prettier-ignore
    this.quickReplies = arr.map(item => item?.label
      ? item
      : { label: item, value: item }
    )
  }

  determineActions = (record = {}, viewing = false) => {
    const { state, props, email, proxy, notes, delivery, navigate } = this
    const { mode } = state

    const actions = []
    const action = (text, icon, fn) => ({
      icon: /^fa(r|s)\s/.test(icon) ? icon : 'fas fa-' + icon,
      text,
      fn,
    })
    const d = new Printd();
    const printElem = () => {
      const cssText = `
        *, ::after, ::before {
          box-sizing: border-box;
        }
        .h3, h3 {
          font-size: 1.75rem;
        }
        .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
          margin-bottom: .5rem;
          font-weight: 500;
          line-height: 1.2;
        }
        body {
          font-family: 'Poppins', 'NEXA', 'Gill Sans MT', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #575962 !important;
          color: #212529;
          text-align: left;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          margin-right: -15px;
          margin-left: -15px;
        }

        .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
          position: relative;
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
        }

        .col-md-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .col-md-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }

        .col-lg-4 {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }

        .col-md-4 {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }

        .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
        }
        .card, .card-light {
        box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1) !important;
        border: none !important;
        }
        .card, .card-light {
        border-radius: 5px;
        background-color: #fff;
        margin-bottom: 30px;
        box-shadow: 2px 6px 15px 0 rgba(69,65,78,.1);
        border: 0;
        }
        @media not print {
        .card-invoice.card-invoice .card-header {
          padding-bottom: 0;
        }
        }
        .card .card-header:first-child, .card-light .card-header:first-child {
        border-radius: 0;
        }
        .card-header:first-child {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
        }
        .card-invoice .card-header {
        padding: 50px 0 20px;
          padding-bottom: 20px;
        border: 0 !important;
        width: 75%;
        margin: auto;
        }
        .card .card-header, .card-light .card-header {
        padding: 1rem 1.25rem;
        background-color: transparent;
        border-bottom: 1px solid #ebecec !important;
        }
        .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
        }
        .card-invoice .invoice-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        }
        .card-invoice .invoice-header .invoice-logo {
        width: 150px;
        display: flex;
        align-items: center;
        }
        .card-invoice .invoice-header .invoice-logo img {
        width: 100%;
        }
        .card-invoice .sub {
          font-size: 1rem;
          margin-bottom: 8px;
          font-weight: 600;
        }
        .card-invoice .info-invoice p {
          font-size: 0.9375rem;
        }
        p {
          margin-top: 0;
          margin-bottom: 1rem;
        }
        p {
          font-size: 1rem;
          line-height: 1.82;
          margin-bottom: 1rem;
          word-break: break-word;
        }
        img {
        vertical-align: middle;
        border-style: none;
        }
        .card-invoice .invoice-header .invoice-title {
        font-size: 1.8125rem;
        font-weight: 400;
        }

        .text-right {
          text-align: right;
        }

        .card-invoice .transfer-total {
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .card-invoice .card-body {
        padding: 0;
        border: 0 !important;
        width: 75%;
        margin: auto;
        }
        .card .card-body, .card-light .card-body {
        padding: 1.25rem;
        }
        .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        }
        .card-footer:last-child {
        border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
        }
        .card-invoice .card-footer {
        padding: 5px 0 50px;
        border: 0 !important;
        width: 75%;
        margin: auto;
        }
        .card .card-footer, .card-light .card-footer {
        background-color: transparent;
        line-height: 30px;
        border-top: 1px solid #ebecec !important;
        font-size: 0.9375rem;
        }
        .card .separator-solid, .card-light .separator-solid {
          border-top: 1px solid #ebecec;
          margin: 15px 0;
        }
        .mb-3 {
          margin-bottom: 0.75rem;
        }
        .mb-md-0, .my-md-0 {
          margin-bottom: 0 !important;
        }
        .mb-3, .my-3 {
          margin-bottom: 1rem !important;
        }
        .col-md-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .col-sm-7 {
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
        }

        .col-md-7 {
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
        }
        .col-sm-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .align-items-center {
          align-items: center !important;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          margin-right: -15px;
          margin-left: -15px;
        }
        .btn {
          font-weight: 400;
          color: #212529;
          text-align: center;
          font-size: 1rem;
          line-height: 1.5;
        }

        .custom-btn {
          display: none !important;
          color: #fff !important;
        }
        .page-title {
          font-size: 1.5625rem;
          font-weight: 600;
          color: #444;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .text-primary {
          --text-opacity: 1;
          color: #00AEEF;
          color: rgba(0, 174, 239, var(--text-opacity));
        }
        .text-lg {
          font-size: 1.125rem;
        }
        .font-semibold {
          font-weight: 600;
        }
        .text-primary {
          color: #007bff !important;
        }
        .text-primary, .text-primary a {
          color: #00AEEF !important;
        }
        .page-pretitle {
          letter-spacing: .08em;
          text-transform: uppercase;
          color: #95aac9;
        }
        `
      d.print(document.getElementById('AppFormFull'), [cssText]);
    }

    const isPostpaid = (p => {
      if (p === true) return true
      if (typeof p == 'function' && p(record)) return true
      return false
    })(props.postpaid)

    if (mode == 'dispatcher') {
      if (props.skip_location || !delivery.canEdit()) return null

      const args = record.delivery_id
        ? [
          'Remove From Delivery',
          'folder-minus',
          () => delivery.remove([record]),
        ]
        : ['Add To Delivery', 'folder-plus', () => delivery.add([record])]

      return [action(...args)]
    }

    if (props.pausable && mode == 'citizen') {
      actions.push(
        action(
          'Resume Application',
          'chevron-right',
          this.resumeApplication(record)
        )
      )
    }

    viewing && actions.push(
      action('Print Application', 'far fa-file', printElem)
    )

    !viewing && actions.push(
      action('View Application', 'far fa-file', this.viewSubmission(record))
    )

    record.notes?.length &&
      actions.push(
        action('View Notes', 'far fa-sticky-note', notes.view(record))
      )
    // console.log(props)
    if (props.pausable && props.resource == '/bahama_host_companies') {
      actions.push(action('Resume Application', 'chevron-right', this.resumeApplication(record)))
    }

    // if (
    //   // prettier-ignore
    //   $app.hasRole('support')
    //   && !record.payment_status
    //   && props.fee_adjustment
    // ) {
    //   const fn = async record => {
    //     const { value } = await Swal.fire({
    //       title: 'Adjust Application Fee',
    //       text: 'Please set the adjusted fee.',
    //       showCancelButton: true,
    //       confirmButtonText: 'Update',
    //       ...props.fee_adjustment(record.payment_amount),
    //     })
    //   }

    //   actions.push('Adjust Fee', 'comment-dollar', fn)
    // }

    if (mode == 'citizen') {
      if (
        !(
          props.free ||
          record.payment_status ||
          record.application_status ||
          (isPostpaid && !record.payment_amount) ||
          props.pay_on_approval
        ) || (props.pay_on_approval && (record.application_decision == 'approved' || record.application_decision == 'awaiting payment') && !record.payment_status)
      ) {
        // console.log(props.pay_on_approval);
        // console.log(props.service);
        // console.log(record);
        actions.push(
          action(
            props.has_appointment && !record.__appointment?.id
              ? 'Set Appointment & Pay'
              : 'Pay Now',
            'dollar-sign',
            this.goToPayment()(record, { skip_proxy: true })
          )
        )}

      if (
        props.has_appointment &&
        record.payment_status &&
        !record.__appointment
      )
        actions.push(
          action('Book Appointment', 'calendar-plus', () => {
            this.setState({ step: STEPS.APPOINTMENT })

            return navigate(
              'form/appointment',
              {
                success_message: props.service.messaging?.submitted,
                appointment_retry: true,
                record: {
                  ...record,
                  payment_status: true,
                },
              },
              { replace: true }
            )
          })
        )
      if (
        props.has_appointment &&
        props.free &&
        !record.__appointment
      )
        actions.push(
          action('Book Appointment', 'calendar-plus', () => {
            this.setState({ step: STEPS.APPOINTMENT })

            return navigate(
              'form/appointment',
              {
                success_message: props.service.messaging?.submitted,
                appointment_retry: true,
                record: {
                  ...record,
                  payment_status: true,
                },
              },
              { replace: true }
            )
          })
        )

      if (
        !props.skip_location &&
        !props.disable_proxy &&
        !/collected/.test(record.delivery_status)
      ) {
        const args = record.proxy
          ? ['Remove Proxy', 'user-slash', () => proxy.v1.remove(record)]
          : ['Add Proxy', 'user-plus', () => proxy.v1.modify(record)]

        actions.push(action(...args))
      }
    }

    if (mode == 'admin') {
      const { location, hide_buttons } = props
      const pendingSubs = record.services?.some(r => !r.application_status)
      const person = location.state?.user || record.user
      if (!person) return

      const shouldShow = prop => {
        const value = hide_buttons?.[prop]

        switch (typeof value) {
          case 'function':
            return !value(record)
          case 'boolean':
            return !value
          default:
            return true
        }
      }

      actions.push(
        action('Create Note', 'far fa-plus-square', notes.create(record))
      )

      if (
        props.has_appointment &&
        record.payment_status &&
        !record.__appointment
      )
        actions.push(
          action('Set Appointment', 'calendar-plus', () => {
            this.props.history.push('/appointments', {
              redirect: this.props.match.path + '/applications',
              createModal: true,
              userInfo: {
                user_id: record?.user?.id,
                user_first_name: record?.user?.first_name,
                user_last_name: record?.user?.last_name,
                user_email: record?.user?.email,
              },
              schedulable_type: this.props.service.type,
              recordId: record?.id,
            })
          })
        )

      if (
        props.has_appointment &&
        record.payment_status &&
        record.__appointment
      )
        actions.push(
          action('View Appointment', 'calendar-alt', () => {
            this.props.history.push('/appointments', {
              redirect: this.props.match.path + '/applications',
              appointment: {
                user_id: record?.user.id,
                schedulable_id: record?.id,
                schedulable_type: this.props.service.type,
              },
              initialDate: record?.__appointment.slot,
            })
          })
        )

      if ((typeof props.postpaid == 'function' ? props.postpaid(record) : props.postpaid)
        && !record.payment_status && shouldShow('fee')
      ) {
        actions.push(
          action('Set Amount', 'dollar-sign', this.setApplicationFee(record))
        )
      }

      if (!record.delivery_id) {
        if (
          shouldShow('status') &&
          (props.free || record.payment_status) &&
          !pendingSubs
        ) {
          actions.push(
            action('Change Status', 'check', () => {
              this.user_email = person.email
              this.changeApplicationStatus(record)
            })
          )
        }

        if (shouldShow('email'))
          actions.push(
            action(
              'Email',
              'envelope',
              email.canned(
                person.first_name,
                person.email,
                person.id,
                record.id
              )
            )
          )
      }

      if (
        (!shouldShow('status') && shouldShow('deny')) ||
        (isPostpaid && !record.payment_status)
      ) {
        actions.push(action('Deny', 'ban', this.denyApplication(record)))
      }
    }

    do {
      if (record.application_decision != 'approved') break

      let cert
      const certLinkRx = /\/pdf_uploads\//

      if (Array.isArray(record.image)) {
        cert = record.image.find(p => certLinkRx.test(p))
      } else if (typeof record.image == 'string') {
        cert = certLinkRx.test(record.image) ? record.image : null
      }
      
      if (!cert || cert.includes('original/missing.png')) break

      const openCert = () => {
        const url = wutils.getUploadPath(cert, true)
        window.open(url, '_blank')
      }

      const verifyCert = async () => {
        this.setState({ loading: true })
        const { form_num } = record
        const service = props.service.type
        verifyDigitalCert(service, form_num, {
          onFetch: () => this.setState({ loading: false }),
        })
      }

      actions.push(
        action('View Certificate', 'award', openCert),
        action('Verify Certificate', 'shield-alt', verifyCert)
      )
    } while (false)

    if (hasAnyKeys(props, 'custom_action', 'custom_actions')) {
      const fn = mode == 'citizen' ? act => act.user : act => !act.user
      const customs = (props.custom_actions || [props.custom_action])
        .filter(fn)
        .filter(act => {
          if (act.table) return false
          if (wutils.failsRoleRequirements(act.roles)) return false
          if (act.test && !act.test(record)) return false
          return true
        })
        .map(act => ({ ...act, fn: () => act.fn(record) }))

      actions.push(...customs)
    }

    return actions
  }

  determineTableActions = () => {
    const { mode, records } = this.state
    const { custom_actions } = this.props

    const isIncorrectUserType = mode == 'citizen'
      ? act => !act.user
      : act => act.user

    return custom_actions.reduce((list, act) => {
      if (!act.table) return list
      if (isIncorrectUserType(act)) return list
      if (wutils.failsRoleRequirements(act.roles)) return list
      if (act.test && !act.test(records)) return list

      list.push({ ...act, fn: () => act.fn(records) })
      return list
    }, [])
  }

  sublink = path => [this.props.match.url, path].join('/')

  navigate = (path, update, options) => {
    const next = path ? this.sublink(path) : this.props.location.pathname

    const state =
      options?.overwrite || update === null
        ? {}
        : { ...(this.props.location.state || {}) }

    typeof update == 'object' && Object.assign(state, update)
    this.props.history[options?.replace ? 'replace' : 'push'](next, state)

    return this.props.location.state
  }

  setModal = name => () => this.setState({ modal: name, input: '' })

  getAgencyImage = () => {
    if (!this.state.agency) return undefined
    return ENV.WEB_SERVICE_URL + this.state.agency.image
  }

  checkForPendingApplication = data => {
    if (this.props.disable_pending_check) return null

    this.any.pending = data.some(d =>
      /(pending|processing)/i.test(d.application_decision)
    )
  }

  openNewForm = async (ev, record) => {
    const { state, props } = this
    const proxy = !!ev?.confirmation_token && ev
    ev?.preventDefault?.()

    let business = proxy ? { client_type: 'individual' } : {}
    let proxy_select = false
    // prettier-ignore
    let step = this.any?.dependencies || props.service?.dependencies
      ? STEPS.DEPENDENCIES
      : STEPS.FORM

    // Always set/reset $app.applicant
    $app.setApplicant(proxy?.user || proxy)

    if (!proxy && this.any?.pending && !record) {
      const res = await Swal.fire({
        icon: 'warning',
        title: 'Resubmit Application',
        text: 'You have an application pending. Are you sure you would like to do this?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      })

      if (!res.isConfirmed) return
    }

    if (!proxy) {
      const block = await props.before_apply?.(state.records)

      if (block === null) {
        return null
      } else if (typeof block == 'string') {
        return this.setState({
          eligible: false,
          modal: 'submit:block',
          block: {
            heading: 'Unable To Apply',
            message: block,
          },
        })
      } else if (typeof block == 'object') {
        return this.setState({
          eligible: false,
          modal: 'submit:block',
          block,
        })
      }
    }

    if (!proxy && props.service.for_companies) {
      const res = props.business_only && props.disable_fpo
        ? { isConfirmed: true }
        : await Swal.fire({
          icon: 'question',
          // title: '',
          text: 'Are you applying for yourself, or a business?',
          showDenyButton: !props.business_only,
          showCancelButton: !props.disable_fpo,
          denyButtonText: 'Individual',
          denyButtonColor: '#6e7d88',
          confirmButtonText: 'Business',
          cancelButtonText: 'Port Auth. Business',
          cancelButtonColor: '#54AF10',
        })

      // User selects Individual; just go to form
      if (res.isDenied) {
        business = { client_type: 'individual' }
      }

      // User selects "Freeport Business" Use Port Authority specific flow
      else if (res.dismiss == Swal.DismissReason.cancel) {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Company Details',
          input: 'text',
          showCancelButton: true,
          text: `Please enter your company's TIN Number`,
          showLoaderOnConfirm: true,
          preConfirm: async value => {
            if (!value) {
              Swal.showValidationMessage('Please enter your TIN.')
              return false
            }

            const data = await this.fetch.FPOCompany(value)
            const fetchError = assertAll(
              [data, "There was an error fetching your company's details"],
              [data?.status, data?.msg]
            )

            if (typeof fetchError == 'string') {
              Swal.showValidationMessage(fetchError)
              return false
            }

            if (data.company_data['is_expired']) {
              Swal.fire({
                title: "Licence Expired",
                text: `Licence has expired and exceeded the grace period (Exp Date: ${data.company_data['expiry_date']})`,
                icon: "error"
              });
            }

            business = {
              client_type: 'business',
              ...extractKeys(
                data.company_data,
                'company_name',
                // 'company_type',
                'organisation_type:company_type',
                'trading_names:trading_name',
                'licence_number:' + (props.bl_field || 'bl_number'),
                // 'freeport_grand_bahama_licence:' + (props.bl_field || 'bl_number'),
                'tin:tin_number',
                'nib_number:business_nib',
                'company_address',
                'email:company_email'
              ),
            }

            return true
          },
        })

        if (isDismissed) return
      }

      // User selects business; prompt for Business Licence & NIB
      else if (res.isConfirmed) {
        // const moaImportPath = props.location.pathname.match(/moa\/(.*?)-import/)
        // if (moaImportPath) {
        //   business = {
        //     client_type: 'business',
        //   }
        // } else {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Company Details',
          // customClass: 'swal--wide',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          text: `Please enter both your company's Business Licence & NIB numbers`,
          html: `
            <div id='__dir-check'>
              <div>
                <label for='swal-bl-input'>Business Licence</label>
                <input id="swal-bl-input" class="swal2-input"/>
              </div>
              <div>
                <label for='swal-nib-input'>Business NIB</label>
                <input id="swal-nib-input" class="swal2-input"/>
              </div>
            </div>
          `,
          preConfirm: async () => {
            const bl = document.getElementById('swal-bl-input').value
            const nib = document.getElementById('swal-nib-input').value

            const inputError = assertAll(
              [bl, 'Please enter a Business Licence #'],
              [nib, 'Please enter an NIB #']
            )

            if (typeof inputError == 'string') {
              Swal.showValidationMessage(inputError)
              return false
            }

            const data = await this.fetch.company(bl, nib)
            const fetchError = assertAll(
              [data, `There was an error fetching your company's details`],
              [data?.status, data?.msg],
            )

            if (typeof fetchError == 'string') {

              Swal.showValidationMessage(fetchError)
              return false
            }

            if (data.company_data['is_expired']) {
              Swal.fire({
                title: "Licence Expired",
                text: `Licence has expired and exceeded the grace period (Exp Date: ${data.company_data['expiry_date']})`,
                icon: "error"
              });
            }

            business = {
              client_type: 'business',
              business_nib: nib,
              ...extractKeys(
                data.company_data,
                'company_name',
                'organisation_type:company_type',
                'trading_name_1:trading_name',
                'licence_number:' + (props.bl_field || 'bl_number'),
                'tin:tin_number',
                'company_address',
                'email:company_email'
              ),
            }

            return true
          },
        })
        if (isDismissed) return
      } else return
    }

    const dependencies = await this.find.dependents(props.service.dependencies)

    this.navigate(
      'form',
      {
        dependencies,
        proxy_select,
        proxy,
        business,
        children: state.proxies?.children,
        record,
      },
      { overwrite: true }
    )

    this.setState({ step })
  }

  // prettier-ignore
  resumeApplication = record => async ev => this.openNewForm(ev, record)

  // prettier-ignore
  goToPayment = (replace = false) => (record, options) => async cb => {
    this.setState({ loading: true })

    const { state, props, navigate, CRUD, setSuccessMessage } = this
    const { location, fee, has_appointment } = props
    const { form_num } = record

    if (!record.payment_status) {
      if (!location.state?.success_message) {
        await setSuccessMessage(record)
      }

      // prettier-ignore
      const payment_amount = Number(
        parseFloat(record.payment_amount ?? 0)
        || (typeof fee == 'function' && await fee(record))
        || fee
      )

      if (!payment_amount) {
        return CRUD('update:payment')({
          form_num,
          payment_amount: 0,
        })
      }

      let step, to

      if (
        !props.skip_location &&
        !props.disable_proxy &&
        !options?.skip_proxy &&
        !record.proxy &&
        state.step != STEPS.PROXY_PICKUP
      ) {
        step = STEPS.PROXY_PICKUP
        to = 'form/proxy'
      } else if (has_appointment && !record.__appointment?.id) {
        step = STEPS.APPOINTMENT
        to = 'form/appointment'
      } else {
        step = STEPS.PAYMENT
        to = 'form/pay'
      }

      const nextState = {
        record,
        appointment_pick: has_appointment,
        payment: { form_num, payment_amount },
      }

      if (options?.additionalState) {
        Object.assign(nextState, options.additionalState)
      }

      navigate(to, nextState, { replace })
      this.setState({ step, loading: false })
    }

    typeof cb == 'function' && cb()
  }

  // prettier-ignore
  setApplicationFee = record => async () => {
    const { pass_fee, postpay_key } = this.props
    let fee

    if (typeof pass_fee == 'function') {
      fee = await pass_fee(record)
    } else {
      const { value } = await Swal.fire({
        input: 'text',
        icon: 'info',
        text: 'Please set the application fee',
        inputPlaceHolder: 'Application Fee',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        inputValidator: val => {
          if (Number(val).toFixed(2) == 'NaN') {
            return 'Please enter a valid amount.'
          }
        }
      })

      fee = Number(value).toFixed(2)

      if (value) {
        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Confirm Fee',
          text: `
            Are you sure you want to set the fee for
            ${wutils.fullName(record.user, false)} to $${fee}?
          `,
          showCancelButton: true,
          confirmButtonText: 'Submit',
        })

        if (isConfirmed) {
          this.navigate(null, { record })
          this.CRUD('set:fee')({
            [postpay_key || 'payment_amount']: Number(fee),
            form_num: record.form_num,
          })
        }
      }
    }
  }

  setSuccessMessage = async form => {
    const { navigate } = this
    const { service } = this.props
    let msg

    if (form.pickup_location) {
      try {
        const { data } = await $app.axios.get('/agency_services', {
          params: {
            'service[name]': service.name,
            ...extractKeys(
              form,
              'pickup_location:service[island]',
              'pickup_sub_location:service[street_address]'
            ),
          },
        })

        msg = data.service?.toast_msg
      } catch (err) {
        console.error(
          'Unable to fetch location specific service success message'
        )
        console.error(err)
      } finally {
        navigate(null, {
          success_message: msg || service.messaging.submitted,
        })
      }
    } else {
      navigate(null, {
        success_message: service.messaging.submitted,
      })
    }
  }

  submitPayment = async form => {
    this.setState({ loading: true })
    const { fetch, CRUD } = this
    const { form_num } = this.props.location.state.payment

    try {
      const rsa = await fetch.rsa()
      const ccKeys = ['number', 'name', 'cvv', 'exp', 'amount']

      const encrypt = new JsEncrypt()
      encrypt.setPublicKey(rsa.pubkey)

      const body = {
        form_num,
        rsa_token: rsa.secret,
        amount: form['card-amount'],
      }

      for (let k of ccKeys) {
        const v = encrypt.encrypt(JSON.stringify(form['card-' + k]))
        body['card_' + k] = v
      }

      CRUD('update:payment')(body)
    } catch (err) {
      toastr.error(
        'Error',
        'There was a problem processing your payment',
        'SERVICE_ERROR'
      )

      return this.setState({ loading: false })
    }
  }

  serializeFields = (form, fields, isLinkedRecord = false) => {
    const { is: perm } = this

    // prettier-ignore
    return fields.map(f => {
      const { name, view, save, hide, type, is, admin, columns, fields } = f

      if (typeof f == 'string') {
        if (f == '::SPACER') {
          return { heading: '<span>&nbsp;</span>' }
        } else if (f.startsWith('::')) {
          return f.length == 2 ? null : { heading: f.slice(2) }
        } else if (f == 'auth_dcode') {
          return {
            label: 'AUTH DCODE',
            value: form.auth_dcode,
          }
        }

        return {
          name: f,
          label: f?.initialCaps(),
          value:
            typeof form[f] == 'boolean'
              ? form[f]
                ? 'Yes'
                : 'No'
              : form[f] || 'N/A',
        }
      }

      if (f.view?.element) {
        return {
          name: f.name,
          label: view?.label || f.label || name?.initialCaps(),
          value: 'CUSTOM'
        }
      }

      // Skip hide check for columns.
      // Just show if there is data
      if ((columns || fields) && form[name]?.length) {
        const use = columns ?? fields
        const val = form[name]

        if (name == 'shareholders' && typeof val[0] == 'string') {
          return {
            name,
            label: 'Shareholders',
            value: val.map(str => JSON.parse(str).name).join(', '),
            long: true,
          }
        }

        return {
          name,
          label: view?.label || f.label || name?.initialCaps(),
          value: view?.format?.(val) || val,
          long: true,
          table: !!columns,
          array: !!fields,

          fields: use
            .filter(col => !col.hide && col.view !== false)
            .map(col => {
              for (let [prop, val] of Object.entries(col)) {
                if (typeof val == 'function') delete col[prop]
              }

              return col
            }),
        }
      }

      if (false
      || (type == 'linked' && isLinkedRecord)
      || (admin && !perm.admin)
      || (save === false && !view)
      || view === false
      || view?.hide
      || (hide && view == undefined)
      || (type == 'checkbox' && view?.hide !== false)
      ) return null

      if (f.view?.heading) {
        return { heading: f.view.heading }
      }

      if (f.heading) return f

      const label = view?.label || f.label || name?.initialCaps()
      let value = form[name]

      // type 'linked' fields will see if the `name` maps to an object
      // and create viewLinkedApplication() link if it finds it
      // otherwise, it will be skipped
      if (type == 'linked') {
        switch (typeof getProperty(form, name)) {
          case 'string':
            try {
              // Test string; could be stringified object
              JSON.parse(getProperty(form, name))
              return { name, label, long: f.long, value: 'LINKED' }
            } catch (err) {
              return null
            }

          case 'object':
            return { name, label, long: f.long, value: 'LINKED' }

          default:
        }
      }

      if (typeof view?.value == 'function') {
        value = view.value(value)
      } else if (/^file:?/.test(type)) {
        value = wutils.getFieldLink(form, f)
        return { name, label, value }
      } else if (f.options && !f.multi) {
        value = f.options.find(opt => (opt?.value ?? opt) == value)
        if (value?.value) value = value.label
        return { name, label, value }
      }

      try {
        value = false
          || (typeof value == 'boolean' && (value ? 'Yes' : 'No'))
          || (!value && 'N/A')
          || (f.profile && form.user[f.name])
          || (f.use_profile && form.user[f.use_profile])
          || (f.columns && typeof value == 'object' && JSON.parse(value))
          || (f.fields && typeof value == 'object' && JSON.parse(value))
          || (f.type == 'link' && wutils.getUploadPath(value))
          || (/(alphanumeric|integer)/.test(is) && value)
          || (is == 'currency' && '$' + Number(value).toFixed(2))
          || (type == 'select:bool' && (value ? 'Yes' : 'No'))
          || ((/(number|#)$/i.test(label) || Number.isNaN(+value)) && value)
          || (typeof value == 'string' && value?.initialCaps())
          || (+value).toFixed(2)
        } catch (err) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Failed to serialize "${name}". Value was...`)
            console.log(value)
          }
        }
        
        return { name, label, value, long: type == 'textarea' }
    }).filter(f => f)
  }

  // prettier-ignore
  viewSubmission = (record, replace = false, options = {}) => async () => {
    this.setState({ loading: true })

    const { props, is, navigate, serializeFields } = this
    const { hidden_fields, hooks, fee } = props
    const {
      auth_dcode,
      form_num,
      user,
      user_id,
      payment_invoice,
      serviceables,
      services,
    } = record

    const fields = []
    let business = null

    // Handle linked fields
    if (options?.linked) {
      if (options.fields) {
        fields.push(...options.fields(record, true))
      } else {
        fields.push(...props.fields(record, true))
        if (hidden_fields) fields.push(...hidden_fields(record, true))
      }

      // Can't reliably serialize fields array so need to delete it
      // It will still be available as a prop on the sub/linked view component
      delete options.fields

      this.setState({ loading: false })

      navigate('applications/subview/', {
        view: {
          ...props.location.state?.view,
          fields: serializeFields(record, fields, true),
        },
        record,
        options,
      })

      return
    }

    record.user = record.user?.id == $app.current_user.id
      ? $app.current_user
      : record.user || props.location.state?.user || this.user

    if (!record.requested_proxy) {
      const userid = user?.user_id ?? user?.id ?? user_id
      const profile = await this.fetch.profile(!this.is.citizen && userid)
      record.user = { ...record.user, ...profile }
      $app.setApplicant(record.user)
    }

    if (auth_dcode && is.admin) fields.push('auth_dcode')
    fields.push(...props.fields(record, true))
    if (hidden_fields) fields.push(...hidden_fields(record, true))
    

    // prettier-ignore
    const payment_amount = record.payment_amount
      || (typeof fee == 'function' ? await fee(record) : fee)

    if (hooks?.view) {
      const added = await hooks.view(record)
      typeof added == 'object' && Object.assign(record, added)
    }

    const blNum = record[props.bl_field ?? 'bl_number']
    const bNib = record[props.bnib_field ?? 'business_nib']
    if (blNum && props.service.for_companies) {
      // Ensure that url pathname does not match moa import services
      // const moaImportPath = props.location.pathname.match(/moa\/(.*?)-import/)
      // if (!moaImportPath) {
        // Include BL fetch API in here
      // }
      const { company_data } = await this.fetch.company(blNum, bNib)
      business = company_data

      if (company_data['is_expired']) {
        Swal.fire({
          title: "Licence Expired",
          text: `Licence has expired and exceeded the grace period (Exp Date: ${company_data['expiry_date']})`,
          icon: "error"
        });
      }
    }

    const view = {
      ...extractKeys(
        record,
        'id',
        'form_num',
        'created_at',
        'pickup_location',
        'pickup_sub_location',
        'application_decision',
        'payment_invoice',
        'payment_amount',
        'payment_status',
        'user'
      ),
      fields: serializeFields(record, fields),
      payment_amount,
    }

    if (payment_invoice) {
      const invoice = await this.fetch.invoice(payment_invoice)
      Object.assign(view, { invoice })
    }

    if (serviceables) {
      const dependents = services.map((service, i) => ({
        service_type: serviceables[i].match(/'service_type'=>'(\w+)',/)[1],
        application_decision: service.application_decision,
      }))

      Object.assign(view, { dependents })
    }

    this.setState({ loading: false })

    navigate('applications/view/' + form_num, {
      view,
      business,
      record,
      options,
    }, { replace })
  }

  renderView = router => {
    const vprops = {
      title: this.props.service.title,
      viewLinkedApplication: (title, record, fields) => this.viewSubmission(record, false, {
        linked: true,
        title,
        fields
      })(),
      setLoading: loading => this.setState({ loading }),
      ...router,
      ...extractKeys(
        this,
        'is',
        'fee',
        'delivery',
        'setModal',
        'determineActions'
      ),
      ...extractKeys(
        this.props,
        'postpaid',
        'fields',
        'free',
        'show_spouse',
        'service'
      ),
    }

    return <Viewer {...vprops} />
  }

  renderSubView = router => {
    const vprops = {
      title: this.props.service.title,
      setLoading: loading => this.setState({ loading }),
      ...router,
      ...extractKeys(this, 'is', 'setModal'),
      ...extractKeys(this.props, 'service', 'fields', 'postpaid', 'free'),
    }

    return <LinkedViewer {...vprops} />
  }

  updateView = update => {
    const { history, location } = this.props
    const inview = location.state?.view

    // prettier-ignore
    inview && history.replace(location.pathname, {
      ...location.state,
      view: { ...inview, ...update },
    })
  }

  changeApplicationStatus = async (record, decision = '') => {
    const data = {}
    let val = decision || null
    let batch = false

    if (!val) {
      const inputOptions = {
        processing: 'Processing',
        approved: 'Approve',
        denied: 'Deny',
      }

      if (this.delivery.canEdit() && wutils.isDeliverable(record, true)) {
        inputOptions.push = 'Approve & Add to Batch'
      }

      const res = await Swal.fire({
        title: 'Change Status',
        text: 'Please select the application status',
        input: 'select',
        showCancelButton: true,
        confirmButtonText: 'Update',
        inputOptions,
      })

      if (res.isDismissed) return
      val = res.value

      if (val == 'push') {
        val = 'approved'
        batch = true
      }
    }

    const { metadata } = this.props
    Object.assign(data, metadata?.[val] || {})

    const updated = await this.CRUD('update:status')(
      {
        form_num: record.form_num,
        application_decision: val,
        ...data,
      },
      record
    )

    if (batch && updated) {
      await this.delivery.add([record])
    }
  }

  denyApplication = record => async () => {
    this.user_email = record.user.email

    const { isConfirmed } = await Swal.fire({
      icon: 'error',
      title: 'Confirm Denial',
      text: 'Are you sure you want to deny this application?',
      confirmButtonText: 'Deny',
      showCancelButton: true,
    })

    isConfirmed && this.changeApplicationStatus(record, 'denied')
  }

  sendStatusUpdate = async (record, decision) => {
    const { props, axios, user_email } = this
    if (/processing/i.test(decision)) return

    const email = user_email || props.location.state?.view.invoice.email

    let pickupMessage = ''
    if (record.pickup_location && /approved?/i.test(decision)) {
      const location = props.locations
        .reduce((arr, l) => [...arr, ...l.locations], [])
        .find(
          l =>
            l.island &&
            record.pickup_location &&
            l.street_address &&
            record.pickup_sub_location
        )

      if (location) {
        const days = location.fulfillment_period || 2
        pickupMessage = `
          <br/>
          Your item will be ready for collection in ${days} days
        `
      }
    }

    try {
      const message = `
        ${props.service.messaging[decision.toLowerCase()] ||
        'There has been a change in your application status.'
        }
        ${pickupMessage}
        <br/><br/><br/>Please help us improve this product and your experience by completing our survey: https://www.surveymonkey.com/r/WMYC276
      `.trim()

      const { data } = await axios.post('/emails', {
        email,
        message,
        subject: 'MyGateway Portal Update',
      })

      if (data.message && !data.error) {
        toastr.success(
          'Email Sent',
          'Application update email successfully sent'
        )
      } else {
        toastr.error('Error', `${data?.message}(${data?.error})`)
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send application update email')
    }
  }

  CRUD = action => async (form, other) => {
    this.setState(state => {
      if (action == 'read' && (!form?.refresh || form.updating)) {
        state.loading = 'applications'
      } else if (!form?.refresh) {
        state.loading = true
      }

      return state
    })

    const { state, props, axios } = this
    const { hooks, resource, form_key, c_fail, fields } = props
    const post = this.post(action, other)
    const fieldArray = fields(form)

    try {
      if (hooks?.[action]) {
        const keepGoing = await hooks[action](form, post, other)

        if (keepGoing === false) {
          this.setState({ loading: false })
          return
        } else if (action == 'read' && Array.isArray(keepGoing.records)) {
          return post(keepGoing.records)
        } else if (typeof keepGoing == 'object') {
          if (form instanceof FormData) {
            for (let [k, v] of keepGoing) {
              form.append(k, v)
            }
          } else {
            Object.assign(form, keepGoing)
          }
        }
      }
    } catch (err) {
      console.error(err)
      return
    }

    const subObjects = fieldArray
      .filter(field => field.sub_obj)
      .map(
        field =>
          field.sub_obj && {
            [field.sub_obj]: {
              [field.name.replace(`${field.sub_obj}_`, '')]: form[field.name],
            },
          }
      )

    fieldArray.forEach(field => {
      if (field.sub_obj) {
        delete form[field.name]
      }
    })

    const hasUploads = form instanceof FormData
    let body = hasUploads || !form_key ? form : { [form_key]: form }

    if (subObjects.length > 0 && form?.acceptance) {
      subObjects.forEach(obj => {
        const newKey = Object.keys(obj)[0]
        const value = Object.values(obj)[0]
        if (!body[newKey]) {
          body[newKey] = {}
        }
        body[newKey][Object.keys(value)[0]] = Object.values(value)[0]
      })
    }

    try {
      switch (action) {
        case 'create':
        case 'create:complete':
          try {
            // prettier-ignore
            console.log('SAVE DRAMA', props)
            console.log(form)
            const method = (props.pausable && form.form_num) ? 'put' : 'post'
            let create = null
            if (method == 'put') {
              create = await axios[method](`${resource}/update_form`, body, {
                validateStatus: s => s < 401 || s == 500,
              })
            } else {
              create = await axios[method](resource, body, {
                validateStatus: s => s < 401 || s == 500,
              })
            }

            if (create.data.external_errors) {
              this.setState({ loading: false })
              Swal.fire(...mapUnavailabilityMessage(props.service.agency.code))
              return
            }

            if (create.status == 500) {
              toastr.error(
                'Error',
                'There was an error processing your request',
                'SERVICE_ERROR'
              )
              this.setState({ loading: false })
              return
            }

            if (create.status == 400) {
              const { message, error } = create.data
              if (message || error)
                toastr.error(
                  'Error',
                  `${message}(${error})`,
                  // message || (Array.isArray(error) ? error[0] : error),
                  'SERVICE_ERROR'
                )
              this.setState({ loading: false })
              return
            }

            this.setState({ banner: '' })
            const application =
              props.c_key || form_key
                ? create.data[props.c_key || form_key]
                : create.data

            post(application)
          } catch (err) {
            const msg =
              c_fail || hasUploads
                ? `
                  The file you have attempted to upload is not currently supported.
                  Please ensure that your upload(s) are in the recommended format format and try again.
                `
                : `
                  Please follow up with the agency from which you are requesting this service to verfiy
                  the information that you have provided is correct according to their records.
                `

            console.error(err)
            toastr.error('Error', msg, 'SERVICE_ERROR')
            this.setState({ loading: false })
          }

          break

        case 'read':
          const rParams = extractKeys(state.metadata, 'page', 'per_page')

          if (form?.params) {
            Object.assign(rParams, form.params)
          } else if (props.read_params?.default) {
            const { values, default: d } = props.read_params
            Object.assign(rParams, values[d])
          }

          const read = await axios.get(resource, { params: rParams })

          this.setState({ metadata: read.data.records_params })
          let arr = props.r_key ? read.data[props.r_key] : read.data

          if (arr.records_by_proxy) {
            if (this.is.citizen) {
              const proxies = await this.proxy.v2.getApproved()
              this.setState({ proxies })
            }

            arr = [...arr.records_by_proxy, ...arr.records]
          }

          if (props.has_appointment) {
            let list = state.appts

            if (!Array.isArray(list)) {
              try {
                const { data } = await axios.get('/schedules')
                const appts = data.schedules.filter(
                  appt => appt.schedulable_type == props.service.type
                )

                this.setState({ appts })
                list = appts
              } catch (err) {
                console.error(err)
                toastr.error('Error', 'Unable to fetch appointment data')
              }
            }

            arr = arr.map(({ id, ...application }) => ({
              id,
              ...application,
              __appointment: list.find(appt => appt.schedulable_id == id),
            }))
          }

          form.refresh && toastr.success('Applications Refreshed')
          return post(arr)

        case 'set:fee':
          await axios.put(resource + '/update_payment_amount', body)
          const amount = form[props.postpay_key || 'payment_amount']
          return post(amount)

        case 'update:payment':
          if (!props.skip_update_payment) {
            if (props.u_key) body = { [props.u_key]: form }

            const { status, data } = await axios.put(
              resource + '/update_payment',
              body,
              {
                validateStatus: status => status <= 400,
              }
            )

            if (status >= 400) {
              this.setState({ loading: false })

              return Swal.fire({
                icon: 'warning',
                title: 'Payment Failed',
                // prettier-ignore
                html: data.cashless?.message
                  || data.cashless?.error
                  || data.message
                  || data.error
                  || 'Payment failed',
                confirmButtonText: 'Try Again',
              })
            }
          }

          return post(form)

        case 'update:status':
          if (props.u_key) body = { [props.u_key]: form }
          await axios.put(resource + '/update_application', body)

          return post(form)

        default:
      }
    } catch (err) {
      console.error(err)
      toastr.error(
        'Error',
        {
          'read': 'Unable to fetch forms',
          'read:one': 'Unable to fetch form details',
          'set:fee': 'Unable to set application fee',
          'update:payment': 'Unable to process payment',
          'update:status': 'Unable to update form status',
        }[action]
      )

      this.setState({ loading: false })
    }
  }

  post = (action, other) => async data => {
    const hookname = 'post:' + action
    const { props, navigate, goToPayment, updateView } = this
    const {
      free,
      hooks,
      location,
      pausable,
      postpaid,
      pay_on_approval,
      has_appointment,
      custom_postpaid_message,
    } = props

    try {
      if (hooks?.[hookname]) {
        const keepGoing = await hooks[hookname](data, other)

        if (keepGoing === false) {
          this.setState({ loading: false })
          return
        }
      }
    } catch (err) {
      console.error(err)
      return
    }

    switch (action) {
      case 'create':
      case 'create:complete':
        this.CRUD('read')({ reset: true })

        if (pausable && action == 'create') {
          return navigate('applications')
        }

        if ((free
          || (typeof postpaid == 'function' ? postpaid(data) : postpaid)
          || data?.exempt_payment
          || pay_on_approval)
          && !has_appointment
        ) {
          await this.setSuccessMessage(data)

          this.setState({ step: STEPS.FINISH, loading: false })
          navigate('form/success', {
            ...extractKeys(this.props.location.state, 'success_message'),
            postpaid,
            success: true,
            custom_postpaid_message,
          })

          break
        }

        if (has_appointment && free) {
          const { record, appointment } = location.state

          try {
            await this.appointment.set(record.id, appointment)
          } catch (err) {
            this.setState({ step: STEPS.APPOINTMENT })

            return navigate(
              'form/appointment',
              {
                appointment_retry: true,
                record: {
                  ...record,
                  ...data,
                  payment_status: true,
                },
              },
              { replace: true }
            )
          }
        }

        return goToPayment(true)(data)()

      case 'read':
        if (typeof other == 'function') other(data)
        return this.setState({
          loading: false,
          records: typeof props.filter_records == 'function'
            ? data.filter(props.filter_records)
            : data
        })

      case 'update:payment':
        toastr.success('Success', 'Payment Successful')

        this.setState(state => {
          state.loading = false
          state.step = STEPS.FINISH

          if (state.records?.length) {
            Object.assign(
              state.records.find(r => r.form_num == data.form_num) || {},
              data,
              { payment_status: true }
            )
          }

          return state
        })

        if (has_appointment) {
          const { record, appointment } = location.state

          try {
            await this.appointment.set(record.id, appointment)
          } catch (err) {
            this.setState({ step: STEPS.APPOINTMENT })

            return navigate(
              'form/appointment',
              {
                appointment_retry: true,
                record: {
                  ...record,
                  ...data,
                  payment_status: true,
                },
              },
              { replace: true }
            )
          }
        }

        return navigate(
          'form/success',
          {
            paid: +data.payment_amount > 0,
            success: true,
            success_message: location.state.success_message,
          },
          { replace: true, overwrite: true }
        )

      case 'update:status':
        const { application_decision, form_num } = data
        let updated = null

        this.setState(state => {
          if (state.query?.records) {
            const index = state.query.records.findIndex(
              r => r.form_num == form_num
            )

            updated = Object.assign(state.query.records[index], {
              application_decision,
            })
          } else {
            const index = state.records?.findIndex(r => r.form_num == form_num)

            updated = Object.assign(state.records[index], {
              application_decision,
            })
          }

          return {
            ...state,
            modal: null,
            status: null,
            loading: false,
          }
        })

        this.updateView({ application_decision })
        this.sendStatusUpdate(updated, application_decision)
        toastr.success('Success', 'Updated application status')
        return true

      case 'set:fee':
        toastr.success('Success', 'Fee successfully set')
        data && updateView({ payment_amount: data })
        this.setState({ loading: false })

        break
      default:
    }
  }

  TableContainer = ({ children }) => {
    const { read_params } = this.props

    const tprops = {
      read_params,
      searching: !!this.state.query,
      current_param: this.state.current_param,

      ...extractKeys(
        this,
        'is',
        'state',
        'delivery',
        'props:form',
        'openNewForm',
        'clearSearch',
        'searchRecords',
        'getAgencyImage',
        'switchOfficerRole',
        'determineTableActions',
      ),

      // prettier-ignore
      toggleinProgress: () => this.setState(state => {
        state.inProgress = !state.inProgress
        return state
      }),

      setParams: ev => {
        const option = ev.target.value
        // prettier-ignore
        this.setState({ current_param: option }, () => {
          this.CRUD('read')({ params: read_params.values[option] })
        })
      },
    }

    return <TableContainer {...tprops} children={children} />
  }

  RecordsTable = () => {
    const { state, props, filterRecords, TableContainer } = this
    const { mode, tkey } = state

    if (mode == 'paid') {
      const tprops = {
        loading: state.loading,
        ...extractKeys(this, 'viewSubmission:view'),
        ...extractKeys(
          props,
          'service',
          'free',
          'skip_location',
          'legal_disclaimer',
          'service_type'
        ),
        setLoading: loading => this.setState({ loading }),
      }

      return (
        <TableContainer>
          <PaymentsTable {...tprops} />
        </TableContainer>
      )
    }

    let component
    let records = state.inProgress && state.query
      ? state.query.records.filter(r => !/(approved|denied)/.test(r.application_decision))
      : state.query?.records
        ?? filterRecords(state.records, mode, state.delivery?.id)

    const tprops = {
      metadata: state.metadata,
      loading: state.loading,
      inProgress: state.inProgress,
      usePagination: state.metadata?.no_of_records && !state.query,
      pay: this.goToPayment(),
      setLoading: (loading = true) => this.setState({ loading }),

      ...extractKeys(
        this,
        'viewSubmission:view',
        'determineActions',
        'setTableParam',
        'proxy',
        'delivery'
      ),
      ...extractKeys(
        props,
        'postpaid',
        'service',
        'locations',
        'free',
        'search',
        'columns',
        'color_codes',
        'has_appointment',
        'skip_location',
        'legal_disclaimer',
        'overrides',
        'hide_columns',
        'table_row_id',
        'service_type',
        'enable_batch_updates'
      ),
      mode,
      records,
      citizen: mode == 'citizen',
      admin: mode == 'admin',

      key: tkey,
    }

    tprops.searchable = [
      ...wutils.getSearchableFieldNames(props.columns?.({}), 'selector'),
      ...wutils.getSearchableFieldNames(props.fields({}), 'name'),
    ]

    if (props.custom_table) {
      component = <props.custom_table {...tprops} />
    } else if (mode == 'dispatcher') {
      component = <DispatchTable {...tprops} mode='dispatcher' />
    } else {
      component = <GeneralTable {...tprops} />
    }

    return <TableContainer>{component}</TableContainer>
  }

  SingleCitizen = () => {
    const { props, is, sublink } = this
    const { forms, user, service } = props.location.state

    if (!user) return <Redirect to={sublink('applications')} />

    let mode = 'citizen'
    if (is.admin) mode = 'admin'
    if (is.dispatcher) mode = 'dispatcher'

    const tprops = {
      ...extractKeys(this, 'viewSubmission:view', 'determineActions'),

      mode,
      citizen: user,
      dispatcher: false,
      admin: true,
      records: forms,
    }

    return (
      <div className='page-inner form-records-table'>
        <div className='card'>
          <div className='card-header'>
            <h4 className='text-capitalize'>
              {user.first_name} {user.last_name}
            </h4>
            <h5>Service: {service.spaceBeforeCap()}</h5>
          </div>
          <div className='card-body'>
            <GeneralTable {...tprops} />
          </div>
        </div>
      </div>
    )
  }

  MultiStepForm = () => {
    const { state } = this.props.location
    const pass = {
      state,
      step: this.state.step,
      tab: {
        text: 'Application',
        icon: 'file-alt',
      },

      setLoading: set => this.setState({ loading: set }),
      goBack: this.props.history.goBack,
      blockSubmission: block => {
        this.setState({
          block,
          loading: false,
          modal: 'submit:block',
        })
      },

      ...extractKeys(
        this,
        'any',
        'submitPayment',
        'navigate',
        'appointment',
        'proxy',
        'CRUD'
      ),
      ...extractKeys(
        this.props,
        'tab',
        'fields',
        'stacked',
        'defaults',
        'pausable',
        'free',
        'form_key',
        'postpaid',
        'pay_on_approval',
        'long_name',
        'service',
        'picker',
        'validate',
        'skip_location',
        'legal_disclaimer',
        'multiple_uploads',
        'generate_form_data',
        'has_appointment',
        'custom_acceptance',
        'foremost'
      ),
    }

    return <SteppedForm {...pass} />
  }

  render() {
    const {
      state,
      props,
      is,
      notes,
      sublink,
      setModal,
      openNewForm,
      RecordsTable,
      SingleCitizen,
      MultiStepForm,
    } = this

    if (props.match.isExact && (
      (is.citizen && props.before_apply === false)
      || !is.citizen
    )) return <Redirect to={sublink('applications')} />

    let modal = null
    let __ep = null
    let __mw = null
    let mprops = rest => ({
      close: setModal(false),
      ...rest,
    })

    switch (state.modal) {
      case 'email:create':
        __ep = extractKeys(this.email, 'send:submit', 'custom')
        modal = (
          <CannedEmailModal {...mprops(__ep)} replies={this.quickReplies} />
        )
        break
      case 'email:custom':
        __mw = '100%'
        __ep = extractKeys(this.email, 'send:submit', 'name')
        modal = (
          <CustomEmailModal
            {...mprops(__ep)}
            defaultSubject={
              'MyGateway Portal Update - ' + mapServiceName(props.service.type)
            }
          />
        )
        break
      default:
    }

    const shouldShowLoader =
      props.loading ||
      props.fetching ||
      props.sending ||
      (state.loading && state.loading != 'applications')

    return (
      <React.Fragment>
        <Loader loading={shouldShowLoader} />
        <Route path={sublink('applications/view')} render={this.renderView} />
        <Route path={sublink('applications/subview')} render={this.renderSubView} />
        <Switch>
          <Route path={sublink('applications')} component={RecordsTable} />
          <Route path={sublink('single-citizen')} component={SingleCitizen} />
          <Route path={sublink('form')} component={MultiStepForm} />
          <Route
            path='/'
            render={rprops => (
              <SelectScreen
                {...{ sublink, openNewForm }}
                agency={props.service.agency}
                title={props.long_name || props.service.title}
              />
            )}
          />
        </Switch>

        <Notes
          data={state.notes}
          onClose={notes.close}
        />

        <Modal
          open={modal}
          style={{ width: __mw ?? 480 }}
          onClose={setModal(false)}
          center
        >
          <div className='modal-content' style={{ width: __mw ?? 480 }}>
            {modal}
          </div>
        </Modal>
        <Alert
          {...state.block}
          open={state.modal == 'submit:block'}
          type='critical'
          onClose={setModal(false)}
        />
        <Alert
          {...state.down}
          open={state.down}
          type='critical'
          onClose={() => this.setState({ down: false })}
        />
        <div
          id='alert-banner-container'
          className={state.banner ? 'visible' : undefined}
        >
          <div
            id='alert-banner'
            className={state.banner ? 'visible error' : undefined}
          >
            <span>{state.banner}</span>
            <button onClick={ev => this.setState({ banner: '' })}>
              <i className='fas fa-times-circle'></i>
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(
  state => ({
    ...extractKeys(
      state.agencies,
      'loading:fetching',
      'deliveries',
      'locations'
    ),
    sending: state.notes.loading,
    profile: state.users.userProfile,
  }),
  {
    sendMessage,
    getUserProfile,
    ...extractKeys(
      AgencyActions,
      'getAgencyLocations',
      'getDeliveries',
      'createDelivery',
      'closeDelivery',
      'addDelivery',
      'removeDelivery'
    ),
  }
)(AuthGuard(Wizard))
