const findSelected = (options, target) => [
  options?.find?.(o => o.value === target) || {},
]

export const findMultiSelected = (options, targets) => {
  const matches = targets?.map?.(t => options.find(o => o.value == t))
  return matches?.length ? matches : []
}

export default findSelected
