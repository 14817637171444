import React from 'react'
import AgriImport from './_agri-import'

export default class EggsImport extends React.Component {
  render = () => {
    const { props } = this

    const aprops = {
      resource: '/eggs_imports',
      form_key: 'eggs_import',
      _sid: 'eggs import',
      service: props.service,
      form_name: 'Eggs',
      importProps: {
        metric_list: [
          'Each Per Item',
          'Cases per item',
          'Bags per item',
          'Bins per item',
          'Pounds per item',
          'Sacks per item',
        ],
        hide_scientific: true,
      },
      ...props,
    }

    return (
      <>
        <AgriImport {...aprops} />
      </>
    )
  }
}
