import React from "react";
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
// import { fullName } from "@modules/form-wizard/utils";
import { getUploadPath } from "@modules/form-wizard/utils";


const FormView = ({ methods, passedProps }) => {
  const { service, formSelected, } = passedProps;
  const { setFormSelected, message, setActive, writeNote, deactivate } = methods;

  const populateUploads = (certsOnly = false) => (
    formSelected.image.map((data, index) => {
      const dataSplit = data.split('/');
      const isMissing = dataSplit[1] != 'system';
      let uploadName = isMissing ? dataSplit[1] : dataSplit[3];
      uploadName = uploadName.replaceAll('_', ' ');
      uploadName = uploadName.slice(0, -1)

      if(certsOnly && (uploadName != 'pdf upload' || uploadName != 'pdf 2 upload')){return null}
      if(!certsOnly && (uploadName == 'pdf upload' || uploadName == 'pdf 2 upload')){return null}
      if(uploadName == 'pdf upload'){ uploadName = 'view certificate'; }
      if(uploadName == 'pdf 2 upload'){ uploadName = 'view 2nd certificate'; }

      if(isMissing){ return null; }

      return (
        <div key={index} className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub" style={{textTransform: 'capitalize'}}>{uploadName}</h5>
          <p>
            {isMissing ? <span>N/A</span> :
            <a href={getUploadPath(data, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>}
          </p>
        </div>
      );
    }));

  const populateSocialMedia = () => formSelected.social_media.map((data, index) => (
    <tr key={index}>
      <td>{data.platform}</td>
      <td>{data.tag}</td>
    </tr>
  ));

  const populateAffiliates = () => formSelected.affiliates.map((data, index) => (
    <tr key={index}>
      <td>{data}</td>
    </tr>
  ));

  const populateEducationHistory = () => formSelected.education_history.map((data, index) => (
    <tr key={index}>
      <td>{data.school_name}</td>
      <td>{data.school_type}</td>
      <td>{data.area_of_focus}</td>
      <td>{data.level_of_study}</td>
      <td>{data.start_date.slice(0, data.start_date.indexOf("T"))}</td>
      <td>{data.completion_date.slice(0, data.completion_date.indexOf("T"))}</td>
    </tr>
  ));

  const populateSpecialSkills = () => formSelected.special_skills.map((data, index) => (
    <tr key={index}>
      <td>{data}</td>
    </tr>
  ));

  const populateHighlights = () => formSelected.highlights.map((data, index) => (
    <tr key={index}>
      <td>{data}</td>
    </tr>
  ));

  const populateDevelopmentAssists = () => formSelected.development_assistance.map((data, index) => (
    <tr key={index}>
      <td>{data}</td>
    </tr>
  ));

  const populateLowSeason = () => formSelected.low_season_dates.map((data, index) => (
    <tr key={index}>
      <td>{data}</td>
    </tr>
  ));

  const populateHighSeason = () => formSelected.high_season_dates.map((data, index) => (
    <tr key={index}>
      <td>{data}</td>
    </tr>
  ));

  const registryBodyRender = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Number of Members in Group/Organizations</h5>
          <p>{formSelected.number_of_members}</p>
        </div>
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Artist Name</h5>
          <p>{formSelected.artist_name}</p>
        </div>
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Website</h5>
          <p>{formSelected.website}</p>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Social Media Links</h5>
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Platform</th>
                  <th>Tag</th>
                </tr>
              </thead>
              <tbody>
                {populateSocialMedia()}
              </tbody>
            </table>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub text-muted">Work History</h5>
        </div>
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Years Of Engagement</h5>
          <p>{formSelected.years_of_engagement}</p>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Local &amp; International Affiliates</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Affiliate</th>
                </tr>
              </thead>
              <tbody>
                {populateAffiliates()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Work Level</h5>
          <p>{formSelected.work_level}</p>
        </div>
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Areas Of Focus</h5>
          <p style={{textTransform: 'capitalize'}}>{formSelected.areas_of_focus.join(', ')}</p>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Target Demographic</h5>
          <p>{formSelected.target_demographic}</p>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub text-muted">Education / Training</h5>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Education History</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>School Name</th>
                  <th>School Type</th>
                  <th>Area of Focus</th>
                  <th>Level of Study</th>
                  <th>Start Date</th>
                  <th>Completion Date</th>
                </tr>
              </thead>
              <tbody>
                {populateEducationHistory()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub text-muted">Skills and Qualifications</h5>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Special Skills, Abilities, &amp; Honours</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Skill</th>
                </tr>
              </thead>
              <tbody>
                {populateSpecialSkills()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub text-muted">Highlights</h5>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Main Activities over the last 5 years</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Activity</th>
                </tr>
              </thead>
              <tbody>
                {populateHighlights()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Three (3) things that can assist your development</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Item</th>
                </tr>
              </thead>
              <tbody>
                {populateDevelopmentAssists()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub text-muted">Financial Indicators</h5>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">High Season Dates</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {populateHighSeason()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Low Season Dates</h5>
          <div className="form-wizard-form-table table-responsive">
            <table className="table table-striped table-striped-bg-black">
              <thead>
                <tr className="header">
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {populateLowSeason()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub">Annual Income Range</h5>
          <p>${formSelected.annual_income_range}</p>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub">Additional Support Funding</h5>
          <p>{formSelected.support_funding || 'N/A'}</p>
        </div>
        <div className="col-md-12 info-invoice">
          <h5 className="sub text-muted">Uploads</h5>
        </div>
        {populateUploads()}
      </div>
);
  };

  return (
    <div>
      {/* Service Record View Page */}
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-9">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="page-pretitle text-primary font-semibold text-lg">
                {formSelected.payment_status ? 'PAID' : 'UNPAID'}&nbsp; | {formSelected.application_decision}
              </h6>
              <h4 className="page-title">{service.serviceType(formSelected)}</h4>
            </div>
            <div className="col-auto">
              <button className="flex items-center btn custom-btn btn-round ml-auto" onClick={() => setFormSelected(null)}>
              <span>Back</span>
              </button>
            </div>
            <div className="col-auto">
              <button className="flex items-center btn btn-round ml-auto">
                <Menu
                menuButton={
                <div className='btn custom-btn'>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                  </svg>
                  <span data-action>Actions</span>
                </div>
                }
                transition
                portal
                arrow
                >
                <MenuItem onClick={message(formSelected)}>
                  Message
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setActive(formSelected)}>
                  View Notes
                </MenuItem>
                <MenuItem onClick={() => writeNote(formSelected)}>
                  Create Note
                </MenuItem>
                <MenuItem onClick={() => deactivate(formSelected)}>
                  Archive/Deactivate
                </MenuItem>
                </Menu>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          </div>
          <div className="page-divider">
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-invoice">
                <div className="card-header">
                  <div className="invoice-header">
                    <div className="invoice-logo">
                      <img src="/static/media/bs-coat-of-arms.bb54f8a8.png" alt="Bahamas Coat of Arms"/>
                    </div>
                    <h3 className="invoice-title lg:text-right">Application</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="separator-solid"></div>
                  <div className="row">
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application ID</h5>
                      <p>{formSelected.form_num}</p>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application Date</h5>
                      <p>{formSelected.created_at.slice(0, formSelected.created_at.indexOf("T"))}</p>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application Status</h5>
                      <p style={{textTransform: 'uppercase'}}>{formSelected.application_decision ? 'pending' : 'emailed'}</p>
                    </div>
                  </div>
                  <div className="separator-solid"></div>
                  <div className="row">
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Applicant Name</h5>
                      <div style={{textTransform: 'capitalize'}}>{`${formSelected.user.first_name} ${formSelected.user.middle_name} ${formSelected.user.last_name}`}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">NIB #</h5>
                      <div>{formSelected.user.NIB}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">D.O.B</h5>
                      <div>{formSelected.user.date_of_birth}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Email</h5>
                      <div>{formSelected.user.email}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Mobile Number</h5>
                      <div>{formSelected.user?.additional_phone_numb || formSelected.phone_number}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Address</h5>
                      <div>
                        <address>{formSelected.street_address}<br/>{formSelected.po_box}<br/>{formSelected.user?.home_address?.island}, Bahamas</address>
                      </div>
                    </div>
                  </div>
                  {service?.key == 'CULTURAL_REGISTRY' && registryBodyRender()}
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-sm-7 col-md-5 mb-3 mb-md-0 transfer-to"></div>
                    <div className="col-sm-5 col-md-7 transfer-total">
                      <h5 className="sub">Total Amount</h5>
                      <div className="price">${formSelected.payment_amount || 0.00}</div>
                      <span>Taxes Included</span>
                    </div>
                  </div>
                  <div className="separator-solid"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormView;
