import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import convertToBase64 from '@helpers/file-to-base64'
import { fullName } from '@modules/form-wizard/utils'

export default class FreshStartProgramme extends React.Component {
  resource = '/teach_youth_summer_programmes'
  form_key = 'teach_youth_summer_programme'
  defaults = {}

  // Delete lines as needed
  free = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = async ({ references, ...form }) => {
    if (references?.length < 3) {
      return 'You must enter at least three references.'
    }

    const payload = {
      ...form,
      references: [],
      additional_images: [],
    }

    for (let ref of references) {
      const { upload, ...rest } = ref

      payload.references.push(rest)
      payload.additional_images.push({
        title: `Reference Letter: ${fullName(rest, false)}`,
        desc: 'Supplemental Reference Letter',
        url: await convertToBase64(upload),
      })
    }
  }

  fields = form => [
    /*
      t.boolean :i_am_guardian, default: false
      t.boolean :i_am_youth, default: false
      t.string :youth_first_name
      t.string :youth_middle_name
      t.string :youth_last_name
      t.string :youth_nib
      t.string :youth_nationality
      t.string :youth_date_of_birth
      t.string :youth_sex
      t.string :youth_island
      t.string :youth_subdivision
      t.string :youth_address
      t.string :youth_house_numb
      t.string :youth_po_box
      t.string :relationship_to_youth
      t.string :emergency_contacts, array: true, default: []
    */

    '::Job Placement Interest',
    {
      name: 'similar_programme_experience',
      type: 'select:bool',
      label: `
        Have You Had Past Experience With The Ministry Of 
        Youth, Sports And Culture's Summer Programme
      `,
    },
    {
      name: 'similar_programme_details_list',
      hide: !form.similar_programme_experience,
      label: 'Past Experiences',
      fields: [
        'camp',
        {
          name: 'duties',
          type: 'textarea',
        },
        {
          name: 'date',
          type: 'date',
          showYearDropdown: true
        }
      ]
    },
    {
      name: 'area_of_interest',
      label: 'Which Area Do You Want To Be Considered For?',
      options: [
        { value: 'youth', label: 'Youth' },
        { value: 'sports', label: 'Sports' },
        { value: 'culture', label: 'Culture' },
      ]
    },
    {
      name: 'youth_interest_area',
      hide: !form.area_of_interest,
      label: 'Sub Area of Interest',
      options: [
        { value: 'business establishment', label: 'Business Establishment' },
        { value: 'camps', label: 'Camps' },
      ]
    },
    {
      name: 'supervisory_experience',
      type: 'select:bool',
      label: 'Have you supervised young people before?',
    },
    {
      name: 'supervisory_details',
      label: 'Please provide details',
      hide: !form.supervisory_experience
    },

    '::Education',
    {
      name: 'attending_school',
      type: 'select:bool',
      label: 'Are you currently attending school?',
    },
    {
      name: 'school_name',
      label: `School's Name`,
      hide: !form.attending_school,
    },
    {
      name: 'school_address',
      label: `School's Address`,
      hide: !form.attending_school,
    },
    {
      name: 'grade_level_completed',
      label: 'Highest Level of Education',
      options: [
        'Grade 10',
        'Grade 11',
        'Grade 12',
        'College',
        'University',
        'Vocational School',
        'Technical School',
      ]
    },
    {
      name: 'academic_certificates',
      label: 'Certificates/Diplomas/Degrees Obtained',
      columns: ['item'],
    },

    '::Medical History',
    {
      name: 'medical_issues',
      label: `
        Do you have any physical or health disabilities that may affect your
        ability to perform in the position sought?
      `
    },
    {
      name: 'medical_details',
      hide: !form.medical_issues,
      label: 'Please Provide Details'
    },

    {
      heading: (
        <div className='my-2'>
          Please note that you are required to upload at least&nbsp;
          <strong>three (3)</strong> references
        </div>
      ),
      view: false
    },
    {
      name: 'references',
      fields: [
        'first_name',
        'last_name',
        'occupation',
        {
          name: 'phone_numb',
          type: 'phone',
          label: 'Phone',
        },
        {
          name: 'upload',
          type: 'file:pdf',
          label: 'PDF Upload',
        }
      ]
    },

    '::Uploads',
    {
      key: 'image.0',
      name: 'police_cert_upload',
      type: 'file:image',
      label: 'Current Police Record',
    },
    // {
    //   key: 'image.1',
    //   name: 'nib_upload',
    //   type: 'file:image',
    //   label: 'NIB Card',
    // },
    {
      key: 'image.2',
      name: 'identification_upload',
      type: 'file:image',
      label: 'Government Issued ID',
    },
    {
      key: 'image.3',
      name: 'photo_upload',
      type: 'file:image',
      label: 'Passport Photo',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
