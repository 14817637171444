import React from 'react'

import { useInspection } from '.'
import Form from '@modules/form-wizard/form'
import FarmRegistration, { commonLivestock, waterSources } from '../farm-registration'
import islands from '@constants/islands'

const FORM_ID = '__inspection-form__'
const today = new Date()

const extractValues = (fields, form) => 
  fields(form, false, true).reduce((obj, f) => {
    if (typeof f == 'string') {
      if (/^::/.test(f)) return obj

      obj[f] = form[f]
      return obj
    }

    if (f.heading || f.hide || f.save === false) return obj

    obj[f.name] = form[f.name]
    return obj
  }, {})

const defaults = {
  "farm_island": "New Providence",
  "farm_location": "",
  "farm_longitude": "",
  "farm_latitude": "",
  "structures_on_farm": false,
  "structure_details": [
      {
          "structure_type": "",
          "material": "",
          "size": "",
          "purpose": ""
      }
  ],
  "live_on_farm": false,
  "field_condition": "",
  "sanitary_facilities": false,
  "clean_water": false,
  "clean_water_source": "",
  "environmental_hazards": false,
  // "hurricane_preparedness_plan": false,
  "farm_records_paper": false,
  "farm_records_electronic": false,
  "soil_tests": false,
  "water_tests": false,
  "type_tests_conducted": [
      {
          "type_of_test": "",
          "date_conducted": ""
      }
  ],
  "proper_chemical_storage": false,
  "chemical_storage_location": "",
  "chemicals_stored_in_original_containers": false,
  "chemical_container_disposal_method": "",
  "protective_equipment_used": false,
  "trained_on_pesticide_use": false,
  "raw_manure_fertilizer_used": false,
  "manure_expected_usage_timeline": false,
  "crops_near_livestock": false,
  "crops_affected_by_livestock_waste": false,
  "trace_disease_in_crops": false,
  "medium_disease_in_crops": false,
  "heavy_disease_in_crops": false,
  "crop_disease_details": [
      {
          "crop_affected": "",
          "location": "",
          "pest": ""
      }
  ],
  "designated_area_for_packaging": false,
  "locations_for_packagings": "",
  "vehicle_used_for_crop_trans": false,
  "refrigerated_transport": false,
  "feed_procured_from": "",
  "animal_water_source": "",
  "swill_or_food_scraps_used": false,
  "animals_well_fed": false,
  "feed_contain_medication": false,
  "feed_contain_antibiotics": false,
  "feed_properly_stored": false,
  "animals_vaccinated": "",
  "vacinated_by": [
      {
          "first_name": "",
          "middle_name": "",
          "last_name": ""
      }
  ],
  "disease_in_animals": false,
  "proper_animal_housing": "",
  "animal_waste_disposal_method": "",
  "how_are_animals_slaughtered": "",
  "designated_place_for_slaughter": false,
  "slaughter_transport_method": "",
  "medication_before_slaughter": false,
}

export const inspectionFields = (form, viewing = false) => [
  '::Confirm Farm Location',
  {
    name: 'farm_street_address',
    label: 'Street Address',
    required: false,
  },
  {
    name: 'farm_settlement',
    label: 'Settlement/Subdivision',
  },
  {
    name: 'farm_island',
    label: 'Island',
    options: islands,
  },
  {
    name: 'farm_longitude',
    label: 'Longitude',
    is: 'float',
  },
  {
    name: 'farm_latitude',
    label: 'Latitude',
    is: 'float',
  },

  '::Structures',
  {
    name: 'structures_on_farm',
    type: 'select:bool',
    label: 'Are There Any Structures On The Grounds?',
  },
  {
    name: 'structure_details',
    label: '',
    hide: !form.structures_on_farm,
    rowLabel: 'Structure',
    fields: ['structure_type', 'material', 'size', 'purpose'],
  },

  '::Farm Conditions',
  {
    name: 'live_on_farm',
    type: 'select:bool',
    label: 'Does the applicant reside on the farm?',
  },
  {
    name: 'field_condition',
    label: 'Condition of Field',
  },
  {
    name: 'sanitary_facilities',
    type: 'checkbox',
    label: 'This location has proper sanitary locations',
    required: false,
  },
  {
    name: 'clean_water',
    type: 'checkbox',
    label: 'This location has access to clean water',
    required: false,
  },
  {
    name: 'clean_water_source',
    hide: !form.clean_water,
    label: 'Source of Clean Water',
  },
  {
    name: 'environmental_hazards',
    type: 'checkbox',
    label: 'There are environmental hazards to be considered',
    required: false,
  },
  // {
  //   name: 'hurricane_preparedness_plan',
  //   type: 'checkbox',
  //   label: 'This farm has a hurricane preparedness plan in place',
  //   required: false,
  // },
  {
    name: 'farm_records_paper',
    type: 'checkbox',
    label: `The farm's paper records are available`,
    required: false,
  },
  {
    name: 'farm_records_electronic',
    hide: !form.farm_records_paper,
    type: 'checkbox',
    label: `The farm's records are saved electronically`,
    required: false,
  },

  '::Health Tests',
  {
    name: 'soil_tests',
    type: 'checkbox',
    label: 'Soil tests have been conducted at this location',
    required: false,
  },
  {
    name: 'water_tests',
    type: 'checkbox',
    label: 'Water tests have been conducted at this location',
    required: false,
  },
  {
    name: 'type_tests_conducted',
    hide: !form.soil_tests && !form.water_tests,
    label: 'Tests Conducted',
    fields: [
      {
        name: 'type_of_test',
      },
      {
        name: 'date_conducted',
        type: 'date',
        maxDate: today
      },
    ],
  },

  '::Chemical Handling',
  {
    name: 'proper_chemical_storage',
    type: 'checkbox',
    label: 'Harmful chemicals are being propely stored on this farm',
    required: form?.chemicals_stored_in_original_containers ? false : true,
  },
  {
    name: 'chemical_storage_location',
    hide: !form.proper_chemical_storage,
  },
  {
    name: 'chemicals_stored_in_original_containers',
    type: 'checkbox',
    label: 'Harmful chemicals are stored in their original containers',
    required: false,
  },
  {
    name: 'chemical_container_disposal_method',
    type: 'textarea',
    label: 'Disposal Method',
  },
  {
    name: 'protective_equipment_used',
    type: 'select:bool',
    label: 'Protective Equipment Used',
  },
  {
    name: 'trained_on_pesticide_use',
    type: 'select:bool',
    label: 'Have the workers been trained on proper pesticide use?',
  },
  {
    name: 'raw_manure_fertilizer_used',
    type: 'select:bool',
    label: 'Is raw manure being used as fertilizer?',
  },
  {
    name: 'manure_expected_usage_timeline',
    type: 'select:bool',
    hide: !form.raw_manure_fertilizer_used,
    label: 'Is it being used within 120 days (4 months) of expected harvest?',
  },

  '::Crop Health',
  {
    name: 'crops_near_livestock',
    type: 'select:bool',
    label: 'Are the crops near any livestock?',
  },
  {
    name: 'crops_affected_by_livestock_waste',
    type: 'select:bool',
    label: 'Are the crops affected by livestock waste?',
  },
  {
    name: 'trace_disease_in_crops',
    type: 'select:bool',
    label: 'Are there any trace diseases in the crops?',
  },
  {
    name: 'medium_disease_in_crops',
    type: 'select:bool',
    label: 'Are there any mild diseases in the crops?',
  },
  {
    name: 'heavy_disease_in_crops',
    type: 'select:bool',
    label: 'Are there any severe diseases in the crops?',
  },
  {
    name: 'crop_disease_details',
    // prettier-ignore
    hide: !form.trace_disease_in_crops
      && !form.medium_disease_in_crops
      && !form.heavy_disease_in_crops,
    rowLabel: 'Crop',
    addLabel: 'Add Crop',
    fields: [
      'crop_affected',
      'location',
      'pest',
    ],
  },
  {
    name: 'designated_area_for_packaging',
    type: 'select:bool',
    label: 'Are there designated area(s) for packaging/post harvest activities?',
  },
  {
    name: 'locations_for_packagings',
    type: 'textarea',
    label: 'Packaging Locations',
    hide: !form.designated_area_for_packaging,
    format: value => value.split('\n').map(str => str.trim()),
  },
  {
    name: 'vehicle_used_for_crop_trans',
    type: 'select:bool',
    label: 'Is the vehicle used for transport of crops sanitized?',
  },
  {
    name: 'refrigerated_transport',
    type: 'select:bool',
    label: 'Is refrigerated transport used to move produce?',
  },

  '::Livestock Information',
  {
    name: "feed_procured_from",
    label: 'Where does the farm procure feed?',
  },
  {
    name: "animal_water_source",
    label: 'What is the water source for the animals?',
    datalist: waterSources
  },
  {
    name: "swill_or_food_scraps_used",
    type: 'select:bool',
    label: 'Is swill or food scraps being used to feed the animals?',
  },
  {
    name: "animals_well_fed",
    type: 'select:bool',
    label: 'Do the animals appear well fed?',
  },
  {
    name: "feed_contain_medication",
    type: 'select:bool',
    label: 'Does the feed contain medication?',
  },
  {
    name: "feed_contain_antibiotics",
    type: 'select:bool',
    label: 'Does the feed contain antibiotics?',
  },
  {
    name: "feed_properly_stored",
    type: 'select:bool',
    label: 'Is the feed properly stored? (Secured from pests, weather, etc.)',
  },

  '::Animal health & Welfare',
  {
    name: "animals_vaccinated",
    label: 'Have the animals received vaccinations?',
    options: ['Yes', 'No', 'Unsure']
  },
  {
    name: "vacinated_by",
    hide: form.animals_vaccinated != 'Yes',
    label: 'Vaccination Administer(s)',
    columns: [
      'first_name',
      { name: 'middle_name', required: false },
      'last_name'
    ],
  },
  {
    name: "disease_in_animals",
    type: 'select:bool',
    label: 'Are there obvious signs of disease or injury?',
  },
  {
    name: "proper_animal_housing",
    label: 'Do the animals have proper housing?',
    options: [
      'Yes',
      'No (Poor Housing)',
      'No (No Housing)'
    ]
  },
  {
    name: "animal_waste_disposal_method",
    label: "How Is Animals' Waste Disposed",
    long: viewing,
  },

  {
    name: "how_are_animals_slaughtered",
    label: 'How Are Animals Slaughtered?',
    long: viewing,
  },
  {
    name: "designated_place_for_slaughter",
    label: 'Is There A Designated Area To Slaughter Animals?',
    type: 'select:bool',
    long: viewing,
  },
  {
    name: "slaughter_transport_method",
    label: 'How Is Livestock Transported To & From Slaughter?',
    type: 'textarea',
  },
  {
    name: "medication_before_slaughter",
    label: 'Were The Animals Receiving Medication Immediately Before Slaughter?',
    type: 'select:bool',
    long: viewing,
  },
  {
    name: "medication_before_slaughter_explained",
    label: 'Medication(s) Given',
    hide: !form.medication_before_slaughter,
    type: 'select:bool',
    fields: [
      {
        name: 'species',
        datalist: commonLivestock,
      },
      {
        name: 'date_received',
        type: 'date'
      },
      {
        name: 'medication_received'
      }
    ]
  },

  {
    name: "additional_comments",
    type: 'textarea',
    label: 'Additional Comments',
    hint: 'If you do not recommend the farm, please include what needs to be corrected.',
    required: false
  },
  {
    name: "farm_recommended",
    type: 'select:bool',
    label: 'Would you recommend this farm for approval',
  },

  //   "declare": true/false
]

const checkForFormErrors = () => {
  setTimeout(() => {
    // eslint-disable-next-line
    document.querySelector('.form-wizard__form .input_error')?.focus()
  }, 100)
}

const InspectionForm = (props) => {
  const ctx = useInspection()
  const component = new FarmRegistration(props)
  const copiedValues = ctx.farm.inspector_form_copy

  const fullForm = form => {
    return [
      {
        heading: (
          <h5 className='text-center'>
            <strong>STEP 1:</strong> Review Farm Registration Form
            <br />
            <small>
              Please confirm that the applicant's<br/>
              information entered below is accurate
            </small>
          </h5>
        )
      },
      ...component.fields(form, false, true),

      {
        heading: (
          <h5 className='text-center'>
            <strong>STEP 2:</strong> Farm Observation Form
            <br />
            <small>Please enter the information below</small>
          </h5>
        )
      },
      ...inspectionFields(form)
    ]
  }

  return (
    <React.Fragment>
      <button className='btn' style={{border: '1px solid #326AAB'}} onClick={() => ctx.step--}>
        Go Back
      </button>
      <h4 className='text-center mb-4'>Inspection Form</h4>

      <Form
        name={FORM_ID}
        fields={fullForm}
        defaults={{
          ...copiedValues,
          ...defaults,
          // farming_type: copiedValues.farming_type.split(', '),
          // manufactured_products: copiedValues.manufactured_products.map(e => ({ product: e })),
          family_workers: !!(
            copiedValues.numb_male_family_workers
            || copiedValues.numb_female_family_workers
          )
        }}
        save={(form) => {
          console.log(form)
          const inspection = extractValues(inspectionFields, form)
          const copy = extractValues(component.fields, form)

          ctx.submitInspectionForm({
            farm_inspection: {
              ...inspection,
              form_num: ctx.farm.form_num,
            },
            farm_registration: copy,
          })
        }}
        validate={() => true}
        skip_location
        generate_form_data={false}
        free
      />
      <footer className='flex justify-between mt-3 mb-12'>
        <button className='btn' onClick={() => ctx.step--}>
          Go Back
        </button>
        <button
          type='submit'
          form={FORM_ID}
          className='btn btn-primary'
          onClick={checkForFormErrors}
        >
          Submit
        </button>
      </footer>
    </React.Fragment>
  )
}

export default InspectionForm
