export default [
  "Agriculture, Food & Natural Resources",
  "Arts, Audio/Video Technology & Communications",
  "Education & training",
  "Government & Public Administration",
  "Hospitality & Tourism",
  "Information technology",
  "Manufacturing",
  "Science, technology, Engineering & Mathematics",
  "Architecture & Construction",
  "Business Management & Administration",
  "Finance",
  "Health Science",
  "Human Services",
  "Law, Public Safety, Corrections & Security",
  "Marketing, Sales & Service",
  "Transportation, Distribution & Logistics",
  "Other",
  "Unemployed"
].map(c => ({ value: c, label: c }))
