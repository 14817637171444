import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import generateRange from '@helpers/range'
import { generateSegmentedFormData } from '@helpers/form-data'

const years = generateRange(1900, new Date().getFullYear())
  .reverse()
  .map(y => ({ label: y, value: y }))

export default class HomeOwners extends React.Component {
  resource = '/urban_renewal_home_owners'
  form_key = 'urban_renewal_home_owner'
  free = true

  skip_location = true
  hide_buttons = false

  custom_actions = []

  validate = form => {
    if (form.tarp_form) {
      const {
        tarp_size,
        homeowner_first_name,
        homeowner_middle_name,
        homeowner_last_name,
        homeowner_nib,
        homeowner_address,
        homeowner_po_box,
        proxy,
        ...home
      } = form

      const payload = generateSegmentedFormData(
        ['urban_renewal_home_owner', {
          acceptance: true,
          declare: true,
          ...home
        }],
        ['urban_renewal_tarp_distribution', {
          acceptance: true,
          declare: true,
          tarp_size,
          homeowner_first_name,
          homeowner_middle_name,
          homeowner_last_name,
          homeowner_nib,
          homeowner_address,
          homeowner_po_box
        }]
      )

      return payload
    }

    return true
  }

  foremost = null

  fields = form => [
    {
      name: 'year_home_built',
      label: 'Year Home Established',
      options: years,
    },
    {
      name: 'building_style',
      options: [
        'Single Family',
        'Multi Family',
      ]
    },
    {
      name: 'structure_type',
      options: [
        'Concrete Block',
        'Wood',
        'Stucco'
      ]
    },
    {
      name: 'indoor_plumbing',
      label: 'This home has indoor plumbing',
      type: 'checkbox',
      required: false,
    },
    {
      name: 'septic_tank',
      label: 'This home has a septic tank',
      type: 'checkbox',
      required: false,
    },
    {
      name: 'requested_roof_repairs',
      type: 'textarea',
    },
    {
      name: 'number_of_adults',
      is: 'integer',
    },
    {
      name: 'number_of_children',
      is: 'integer',
    },
    {
      name: 'number_of_disabled',
      is: 'integer',
    },
    {
      name: 'employed_in_household',
      label: 'Household Employed Information',
      columns: [
        'name',
        {
          name: 'employer',
          label: 'Place of Employment'
        },
        {
          name: 'phone_number',
          label: 'Phone #'
        },
      ]
    },

    '::Tarp Distribution',
    {
      name: 'tarp_form',
      type: 'select:bool',
      label: 'Would you like to also apply for a tarp rental?',
    },
    {
      name: 'tarp_size',
      label: 'Size of Tarp',
      hint: 'In feet',
      hide: !form.tarp_form,
      datalist: [
        '13 x 20',
      ],
      view: {
        value: v => v + ' ft.',
      }
    },

    {
      name: 'homeowner_first_name',
      label: 'First Name',
      hide: !form.tarp_form,
    },
    {
      name: 'homeowner_middle_name',
      label: 'Middle Name',
      required: false,
      hide: !form.tarp_form,
    },
    {
      name: 'homeowner_last_name',
      label: 'Last Name',
      hide: !form.tarp_form,
    },
    {
      name: 'homeowner_nib',
      label: 'NIB',
      is: 'integer',
      hide: !form.tarp_form,
    },
    {
      name: 'homeowner_address',
      label: 'Street Address',
      hide: !form.tarp_form,
    },
    {
      name: 'homeowner_po_box',
      label: 'P. O. Box',
      required: false,
      hide: !form.tarp_form,
    },

    '::Uploads',
    // {
    //   key: 'image.1',
    //   name: 'photo_upload',
    //   label: 'Passport Photo',
    //   type: 'file:image',
    // },
    // {
    //   key: 'image.0',
    //   name: 'home_ownership_upload',
    //   label: 'Proof of Ownership',
    //   type: 'file:image',
    //   hint: 'Please provide a deed, will, or alternative proof',
    // },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
