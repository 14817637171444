import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import daysOfWeek from '@constants/weekdys'

export default class __SERVICE_NAME__ extends React.Component {
  resource = '/youth_organizations'
  form_key = 'youth_organization'
  defaults = {
    shareholders_list: [
      {
        position: 'President',
        first_name: '',
        last_name: '',
        email: ''
      },
      {
        position: 'Vice President',
        first_name: '',
        last_name: '',
        email: ''
      },
      {
        position: 'Secretary',
        first_name: '',
        last_name: '',
        email: ''
      },
      {
        position: 'Treasurer',
        first_name: '',
        last_name: '',
        email: ''
      },
    ]
  }

  // Delete lines as needed
  free          = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => {
    const list = [
      {
        name: 'organization_name',
        label: 'Name of Organization',
      },
      {
        name: 'organization_type',
        label: 'Type of Organization',
      },
      {
        name: 'organization_start_date',
        type: 'date',
        label: 'Start Date',
        showYearDropdown: true,
      },
      {
        name:'org_position',
        label: 'President/Head of Organization',
      },
      {
        name: 'head_contact',
        label: 'President/Head of Organization contact',
        type: 'phone',
      },
      {
        name: 'head_email',
        label: 'President/Head of Organization Email',
        type: 'email',
      },
      {
        name: 'vice_deputy',
        label: 'Vice President/Deputy Head of Organization',
      },
      {
        name: 'vice_contact',
        label: 'Vice President/Deputy Contact',
        type: 'phone',
      },
      {
        name: 'vice_email',
        label: 'Vice President/Deputy email',
        type: 'email',
      },
      {
        name: 'office_location(s)',
      },
      {
        name: 'postal_address',
      },
      {
        name: 'office_telephone',
        type: 'phone'
      },
      {
        name: 'web_address',
        required: false,
      },
      {
        name: 'youth_coordinators',
        label: 'Youth Program Coordinator(s)'
      },
      {
        name: 'contact_person_title',
      },
      {
        name: 'organization_type',
        optione: [
          'Religious', 
          'Private', 
          'Government',
          'Community',
          'Non-profit Organization/ Non-government Organization'
        ],
      },
      {
        name: 'affiliation',
        label: 'Is this organization affiliated with any other organizations?',
        options: ['Yes', 'No'],
      },
      {
        name: 'is_affiliated',
        label: 'Please specify',
        hide: form.affiliation !="Yes",
      },
      {
        name: 'fees_associated',
        label: 'Are there any fees associated with this service that you offer?',
        options: ['Yes', 'No'],
      },
      {
        name: 'are_fees',
        label: 'Please specify',
        type: 'textarea',
        hide: form.fees_associated !="Yes",
      },
      {
        name: 'progam_purpose',
        label: 'What is the general purpose of this youth program?',
      },
      {
        name:'target_population',
        label: 'What is your target population?',
        options: [
          'Under 12 years (primary)',
          '16-25 years', 
          'Females only', 
          'Males only', 
          'Males and Females',
          '12-18 years (high school)',
          'All ages',
          'At-Risk/Marginalized youth'
        ]
      },
      {
        name: 'island',
        options: islands,
      },
      {
        name: 'po_box',
        label: 'P. O. Box',
        required: false
      },
      /*'::Contact Details',
      {
        name: 'i_am_contact',
        type: 'checkbox',
        required: false,
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      }, */
    ]
    /*
    if (!form.i_am_contact) {
      list.push(
        '::Contact Details',
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
        {
          name: 'contact_phone_numb',
          label: 'Primary Phone #',
          type: 'phone'
        },
      )
    } */

    list.push(
      {
        name: 'meeting_frequency',
        label: 'Meeting Days',
        multi: true,
        options: daysOfWeek,
        format: val => val.join(', ')
      },
      {
        name: 'meetings_located',
        label: 'Meeting Location',
      },
      {
        name: 'shareholders',
        label: 'Officers',
        columns: [
          'first_name',
          'last_name',
          'position'
        ]
      },
      {
        name: 'number_of_members',
        is: 'integer'
      },
      {
        name: 'member_age_range',
        label: 'Age Range of Members',
        options: [
          { value: 'under 12', label: 'Under 12' },
          '13 - 19',
          '20 - 25',
          { value: 'over 25', label: 'Over 25' },
        ],
      },
      {
        name: 'members_sex',
        label: 'Gender Ratio',
        options: [
          { value: 'male', label: 'All Male' },
          { value: 'female', label: 'All Female' },
          { value: 'mixed', label: 'Mixed' },
        ]
      },
      {
        name: 'group_activies',
        columns: ['activity'],
        format: v => v.activity,
      },
      {
        name: 'volunteer',
        label: 'Are you registered with volunteer Bahamas?',
        type: 'select:bool',
      },
      {
        name: 'recieve_volunteers',
        label: 'Would you like to recieve volunteer(s) from Volunteer Bahamas?',
        type: 'select:bool',
      },
      {
        name: 'skill_requirements',
        label: ' Volunteer skill requirements',
        type: 'textarea',
      },
      {
        name: 'training_completed',
        label: 'Have any of your members completed a youth worker training program?',
        type: 'select:bool',
      },
      '::Uploads',
      {
        name: 'organization_profile_upload',
        type: 'file:pdf',
        label: `Organiztion's Aims & Objectives`,
      },
      {
        name: 'proposal_upload',
        type: 'file:pdf',
        label: 'Business Proposal',
      },
      {
        name: 'trained_youth_workers',
        label: 'List trained youth workers and note the qualification',
        columns: [
          'Youth worker name',
          'Qualification',
          'Year of Qualification',
        ], 
      },

        //mimic sharholders section in annual grant
        "::Executive team for the organization (Provide emails)",
        {
              name: 'shareholders_list',
              label: 'Executive Team',
              min: 4,
              fields: [
                  {
                    name: 'position',
                    disabled: true
                  },
                  {
                    name: 'email',
                    is: 'email'
                  },
        
          ]
        },
    )

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
