import React from 'react'

import { connect } from 'react-redux'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { getLists } from '@actions/users'

class FamilyIslandDevExemption extends React.Component {
  componentDidMount() {
    !this.props.islands.length && this.props.getLists()
  }

  title = 'Family Island Development'
  resource = '/family_island_dev_exemptions'
  form_key = 'family_island_dev_exemption'
  free = true
  postpaid = false
  defaults = {}
  
  skip_location = true
  hide_buttons = false
  custom_action = false
  custom_acceptance = false

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'description',
      label: 'Description of Building',
      options: [
        'New Building',
        'Rehabilitation, remodeling, or extension of new or existing building',
      ],
    },
    {
      name: 'machinery',
      label: 'Machinery',
      options: [
        'Clearing of land for farming',
        'Clearing of land for construction',
      ],
    },
    {
      name: 'start_date',
      label: 'Date of Commencement',
      type: 'date',
      showYearDropdown: true,
    },
    {
      name: 'island',
      label: 'Name of Island',
      options: this.props.islands,
    },
    { name: 'settlement', label: 'Settlement' },
    { name: 'location', label: 'Project Location' },
    {
      name: 'materials_upload',
      type: 'file:image',
      label: 'Listing of building materials',
    },
    {
      name: 'penalty',
      type: 'checkbox',
      label:
        'Any person who knowingly makes a false declaration for the purpose of obtaining a benefit pursuant to the provisions of this Act is guilty of an offence and shall be liable on summary conviction, to a fine of five thousand dollars.',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default connect(
  ({ users }) => ({
    islands: users.lists?.islands || [],
  }),
  { getLists }
)(FamilyIslandDevExemption)
