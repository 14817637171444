import React from 'react'
import moment from 'moment'
import Modal from 'react-responsive-modal'
import { ValidatorForm } from 'react-form-validator-core'
import Swal from 'sweetalert2'

import Loader from '@shared/loader'
import SelectField from '@shared/form-fields/select'
import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys, listKeys } from '@helpers/objects'
import normalizeField from '@helpers/normalize-form-field'

import degrees from '@constants/higher-education-programs'
import countries from '@constants/countries'
import islands from '@constants/islands'
import ENV from '@constants/env'

import {
  ministries,
  overseas_locations,
  approval_reasons,
  approval_strings,
  advanced_poll_date,
  // APPLICATION_IDS,
  constituencies,
} from '@constants/prd'

export default class AdvancePollerApplication extends React.Component {
  constructor(props) {
    super(props)
    Object.assign(this, extractKeys($app.applicant, 'age', 'full_name'))
  }
  state = {
    type: 'j',
    service_type: 'OverseasVisitorPrd',
    form_key: 'overseas_visitor_prd',
    resource: '/overseas_visitor_prds',

    record: null,
    reason: '',
    decision: '',
    loading: false,
  }

  id = 'OverseasVisitorPrd'
  // service = 'advanced poller apply prd'
  // title = 'Advanced Poll Application'
  // agency = 'prd'
  free = true

  table_row_id = 'form_num'
  disable_pending_check = true
  show_approved = true
  skip_location = true
  custom_acceptance = true

  defaults = {
    type: 'j',
    reason: this.age > 64 ? 'A' : '',
    advanced_poll_date,
    __senior_citizen: 'I am 65 years of age or older',
  }

  hide_buttons = {
    status: true,
    deny: true,
    email: true,
  }

  custom_actions = [
    {
      text: 'Change Status',
      icon: 'check',
      test: r => r.application_decision == 'pending',
      fn: record => this.setState({ modal: true, record }),
    },
    {
      user: true,
      text: 'Withdraw',
      icon: 'arrow-left',
      test: r => !r.withdrawn,
      fn: record =>
        Swal.fire({
          title: 'Confirm Withdrawal',
          text: 'Are you sure you want to withdraw your application?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Withdraw',
        }).then(async result => {
          if (result.isConfirmed) {
            try {
              switch (record.type) {
                case 'K':
                  await $app.axios.put('/special_voter_prds/update_withdraw', {
                    form_num: record.form_num,
                  })
                  break
                case 'J':
                  await $app.axios.put(
                    '/overseas_visitor_prds/update_withdraw',
                    {
                      form_num: record.form_num,
                    }
                  )
                  break
                default:
              }

              toastr.success(
                'Success',
                'Successfully withdrawn from advanced poll'
              )
            } catch (error) {
              toastr.error('Error', 'Unable to withdraw')
              return
            }

            if (record.web_pass_url) {
              const uuid = record.verify_url.match(/\?uuid=(.+)/).pop()

              try {
                await $app.axios.post(
                  ENV.BLOCKCERTS_SERVER + '/api/v1/revoke',
                  {
                    company: 'prd',
                    revoked: [
                      {
                        uuid,
                        reason: 'Applicant withdrew from application',
                      },
                    ],
                  }
                )
              } catch (err) {
                toastr.error('Error', 'Unable to revoke digital certificate')
                return
              }
            }
          }
        }),
    },
    {
      text: 'Mark As Voted',
      icon: 'stamp',
      test: r =>
        r.application_decision == 'approved' && !r.withdrawn && !r.voted,
      fn: record =>
        Swal.fire({
          title: 'Confirm',
          text: `
          Are you sure you want to mark ${[
            listKeys(record.user, 'first_name', 'middle_name', 'last_name'),
          ]
            .join(' ')
            .capitalize()}
          as having voted?
        `,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        }).then(result => {
          if (result.isConfirmed) {
            switch (record.type) {
              case 'K':
                return $app.axios.put('/special_voter_prds/update_voted', {
                  form_num: record.form_num,
                })
              case 'J':
                return $app.axios.put('/overseas_visitor_prds/update_voted', {
                  form_num: record.form_num,
                })
              default:
            }
          }
        }),
    },
  ]

  hooks = {
    'read': async (noop, done) => {
      const arr = []

      if ($app.roles.includes('prd')) {
        const type = 'J'

        try {
          const approved = await $app.axios.get('/overseas_visitor_prds', {
            params: {
              per_page: '100',
            },
          })

          arr.push(
            ...approved.data.map(item => ({ ...item, type }))
          )
        } catch (err) {
          console.error(err)
          toastr.error(
            'Error',
            'Unable to fetch approved applications',
            'SERVICE_ERROR'
          )
        }
      } else {
        try {
          const o = await $app.axios.get('/overseas_visitor_prds')
          const k = await $app.axios.get('/special_voter_prds')
          const a = await $app.axios.get('/advanced_poller_apply_prds')

          arr.push(
            ...o.data.map(item => ({ ...item, type: 'J' })),
            ...k.data.map(item => ({ ...item, type: 'K' })),
            ...a.data.map(item => ({ ...item, type: 'K' }))
          )
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to fetch forms', 'SERVICE_ERROR')
        }
      }

      done(arr)
      return false
    },

    'post:create': async data =>
      new Promise((resolve, reject) => {
        if (this.age > 64 && this.state.type == 'k') {
          this.setState(
            {
              record: {
                ...data,
                user: $app.applicant,
              },
              decision: 'approved',
            },
            () => {
              this.on.submit(null, true)
              resolve(null)
            }
          )
        } else resolve(null)
      }),
  }

  // before_apply = () => ({
  //   heading: 'Applications Are Closed',
  //   message: `
  //     Sorry, but we are no longer accepting advanced poll applications.
  //   `,
  // })

  columns = props => [
    {
      name: 'Form Type',
      selector: 'type',
      sortable: true,
      format: r => (r.type == 'J' ? 'J (Overseas)' : 'K (Special)'),
    },
    {
      name: 'Reason',
      selector: 'application_reason',
      sortable: true,
      format: r => (r.type == 'J' ? 'O' : r.voter_type_code),
    },
    {
      name: 'Withdrawn?',
      omit: props.mode != 'citizen',
      selector: 'withdrawn',
      sortable: true,
      format: r => (r.withdrawn ? 'YES' : 'NO'),
    },
    {
      name: 'Voted?',
      omit: props.mode == 'citizen',
      selector: 'voted',
      sortable: true,
      format: r => (r.voted ? 'YES' : 'NO'),
    },
  ]

  validate = form => {
    const list = [
      ...constituencies['New Providence'],
      ...constituencies['Grand Bahama'],
      ...constituencies['Family Islands'].slice(1),
    ]

    const obj = {
      senior_citizen: this.age > 64,
      constituency_code: list.indexOf(form.constituency) + 1,
    }

    if (this.state.type == 'j') {
      Object.assign(obj, {
        voter_type_code: 'O',
        senior_citizen: this.age > 64,
        occupation: form.occupation || 'Student',
        place_of_work: form.place_of_work || form.school_name,
        programme_studied: form.programme_studied || 'N/A',
        // voters_number: $app.applicant.voters_card_number,
      })
    }

    return obj
  }

  // determineForm = ({ q1, q2 }) => {
  //   if (q1) {
  //     this.setState({
  //       type: 'j',
  //       form_key: 'overseas_visitor_prd',
  //       resource: '/overseas_visitor_prds',
  //     })

  //     return { reason: 'O' }
  //   } else if (q2) {
  //     this.setState({
  //       type: 'k',
  //       form_key: 'special_voter_prd',
  //       resource: '/special_voter_prds',
  //     })

  //     return
  //   }
  // }

  forms = {
    select: form =>
      [
        {
          view: false,
          heading: `
          <span class='text-base'>
            Please answer the following.
          </span>
        `,
        },
        {
          name: 'q1',
          save: false,
          long: true,
          type: 'select:bool',
          label: `I am applying to enroll as an overseas voter`,
          callback: v => this.determineForm({ ...form, q1: v }),
        },
        {
          name: 'q2',
          save: false,
          long: true,
          type: 'select:bool',
          test: v =>
            v || 'Sorry, but you are ineligible to submit this application',
          hide: form.q1 === undefined,
          label: `Are you a special voter?`,
          callback: v => this.determineForm({ ...form, q2: v }),
        },
      ].map(f => ({ ...f, hide: f.hide || this.state.type })),

    j: (form, viewMode) => {
      const isStudent = ['A'].includes(form.occupation_status)
      const isOther = ['B', 'C', 'D'].includes(form.occupation_status)

      return [
        '::Overseas Voters S. 49A (Form J)',
        {
          heading: `<div class='text-lg'>
            Persons eligible to vote as Overseas Voters S. 49A (Form J) are:
            <ul>
              <li>
                A student or the spouse accompanying such student, pursuing a bona fide program of study outside The Bahamas;
              </li>
              <li>
                A member of staff of any embassy, high commission or other foreign mission of The Bahamas who is posted overseas, or the spouse or any immediate member of the family living within the household of that member of staff;
              </li>
              <li>
                A member of staff of the Ministry of Tourism, The Bahamas Maritime Authority, or other agency of the Government of The Bahamas, or the spouse or any immediate member of the family living within the household of that member of staff; or
              </li>
              <li>
                A public officer or a member of staff of any delegation who is on official duty overseas, or the spouse or any immediate member of the family living within the household of that member of staff.
              </li>
            </ul>
          </div>`,
          view: false,
        },
        {
          heading: `<span class='text-lg'>A) I, <strong>${this?.full_name?.capitalize()}</strong> hereby apply for the enrollment as an overseas voter</span>`,
          view: false,
        },
        {
          name: 'advanced_poll_date',
          save: false,
          disabled: true,
          required: false,
        },
        {
          name: 'occupation_status',
          type: 'checklist',
          view: false,
          label: `Select One`,
          single: true,
          options: [
            {
              value: 'A',
              label: `A student, or the spouse accompanying such student, pursuing a bona fide programme of study outside of The Bahamas`,
            },
            {
              value: 'B',
              label: `
                A member of staff of any embassy, high commission or other foreign mission of The Bahamas who is posted overseas,
                or the spouse or any immediate member of the family living within the household of that member of staff
              `,
            },
            {
              value: 'C',
              label: `
                A public officer or a member of staff of the Ministry of Tourism or any delegation of The Bahamas who is on official
                duty overseas or the spouse of such person or any immediate member of the family living within the household of that member of staff;
                or a member of staff of The Bahamas Maritime Authority or any other agency of the Government of The Bahamas or the spouse of such
                person or any immediate member of the family living within the household of that member of staff.
              `,
            },
            {
              value: 'D',
              label: `
                A member of staff of The Bahamas Maritime Authority or any other agency of the Government of The Bahamas or the spouse of such person
                or any immediate member of the family living within the household of that member of staff.
              `,
            },
          ],
        },
        {
          heading: viewMode
            ? 'Address Outside Of Country'
            : `<span class='text-lg'>B) My Current Address Is</span>`,
        },
        {
          name: 'foreign_city',
          label: 'City',
        },
        {
          name: 'foreign_state',
          label: 'State/Province',
        },
        {
          name: 'foreign_country',
          label: 'Country',
          options: countries.slice(1),
        },
        {
          name: 'email_address',
          save: false,
          label: 'My Email Address Is',
          use_profile: 'email',
          view: false,
          disabled: true,
        },
        {
          name: 'phone_number',
          type: 'text',
          save: false,
          label: 'My telephone Number Is',
          use_profile: 'additional_phone_number',
          view: false,
          disabled: true,
        },
        {
          heading: viewMode
            ? "Voter's Constituency"
            : `<span class='text-lg'>C) I Am Currently A Registered Voter In The Constituency Of</span>`,
        },
        {
          name: 'island',
          // options: islands,
          options: ['Grand Bahama', 'Bimini']
        },
        {
          name: 'constituency',
          disabled: !form.island,
          options:
            constituencies[form.island] ||
            constituencies['Family Islands'].filter(n =>
              n.includes(form.island)
            ),
          view: {
            label: 'Constituency',
            value: v => v.toString().capitalize() || 'N/A'
          }
        },
        {
          name: 'voters_number',
          type: 'integer',
          use_profile: 'voters_card_number',
          view: false
        },
        {
          heading: viewMode
            ? 'Address In Country'
            : `<div class='text-base pl-8'>And My Address In The Constituency Is</div>`,
        },
        {
          name: 'home_street_number',
          label: 'House/Apt. #',
          required: false,
        },
        {
          name: 'home_street',
          label: 'Street',
        },
        {
          name: 'home_subdivision',
          label: 'Area / Subdivision / Settlement',
        },
        {
          heading: viewMode
            ? `Employment Information`
            : `<span class='text-lg'>D) My Occupation Is</span>`,
          hide: !isOther,
        },
        {
          name: 'occupation',
          hide: !isOther,
          label: 'Occupation',
          required: false,
          save: true,
        },
        {
          heading: `<span class='text-lg'>E) I am currently a member of staff of</span>`,
          view: false,
          hide: !isOther,
        },
        {
          name: 'place_of_work',
          label: 'Ministry',
          options: ministries,
          hide: !isOther,
          save: true,
        },
        {
          heading: viewMode
            ? 'School Information'
            : `<span class='text-lg'>F) I Am Currently Registered As A Student At</span>`,
          hide: !isStudent,
        },
        {
          name: 'school_name',
          label: 'Name of School',
          hide: !isStudent,
        },
        {
          name: 'school_city',
          label: 'City',
          hide: !isStudent,
        },
        {
          name: 'school_state',
          label: 'State/Province',
          hide: !isStudent,
        },
        {
          name: 'school_country',
          options: countries.slice(1),
          hide: !isStudent,
          hint: 'Start typing to search...',
        },
        {
          name: 'programme_studied',
          save: true,
          label: 'Programme of Study',
          options: degrees,
          hide: !isStudent,
          hint: 'Start typing to search...',
        },
        {
          key: f => f.image[0],
          name: 'document_upload',
          type: 'file',
          hide: !form.occupation_status,
          label: isStudent
            ? 'Proof of Study'
            : 'Documentation To Support Application',
          hint: isStudent
            ? `
            <ul class='list '>
              <li>Student Visa</li>
              <li>Unofficial Transcript</li>
              <li>Letter from College/University</li>
              <li>Acceptance Letter from College/University</li>
            </ul>
          `
            : `
            <ul class='list '>
              <li>Proof of Designation</li>
              <li>Proof of Overseas Deployment</li>
            </ul>
          `,
        },
        '::SPACER',
        {
          name: 'overseas_location_code',
          options: overseas_locations,
          required: false,
          label: 'Where would you like to vote?',
          view: {
            label: 'Voting Location',
            value: v =>
              overseas_locations.find(p => p.value == v)?.label || 'N/A',
          },
        },
      ]
    },

    k: (form, viewMode) => [
      '::Special Voters S. 49C (Form K)',
      {
        heading: `<div class='text-lg'>
          Persons eligible to vote as Special Voters S. 49C (Form K) are:
          <ul>
            <li>
              Nominated Candidates and their spouses;
            </li>
            <li>
              Election day workers, election officials or employees of the Parliamentary Registration Department;
            </li>
            <li>
              Any registered voter entitled to vote at an election in the Constituency and who is a person with disabilities or 65 years or older on the day appointed for the taking of the poll;
            </li>
            <li>
              Doctor-certified individuals with illness, infirmity, pregnancy, or recent childbirth, (documentary proof required) and unable to vote on the day appointed for the taking of the Poll; or
            </li>
            <li>
              Any person (not being a person who qualifies to enroll as an overseas voter under section 49A) who expects to be overseas on the day appointed for the taking of the poll, such as a confirmed traveler already scheduled to be out of the country (documentary proof required)
            </li>
          </ul>
        </div>`,
        view: false,
      },
      {
        heading: `<span class='text-lg'>A) I, <strong>${this?.full_name?.capitalize()}</strong> hereby apply for a special voter certificate to vote on the day of the Advanced Poll</span>`,
        view: false,
      },
      {
        name: 'advanced_poll_date',
        save: false,
        disabled: true,
        required: false,
      },
      {
        heading: viewMode
          ? 'Address'
          : `<span class='text-lg'>B) My Address Is</span>`,
      },
      {
        name: 'constituency_address',
        label: 'Street Address',
      },
      {
        name: 'island',
        options: islands,
      },
      {
        name: 'email_address',
        save: false,
        label: 'My Email Address Is',
        use_profile: 'email',
        view: false,
        disabled: true,
      },
      {
        name: 'phone_number',
        type: 'text',
        save: false,
        label: 'My telephone Number Is',
        use_profile: 'additional_phone_number',
        view: false,
        disabled: true,
      },
      {
        heading: viewMode
          ? "Voter's Constituency"
          : `<span class='text-lg'>C) I Am Currently A Registered Voter In The Constituency Of</span>`,
      },
      {
        name: 'constituency',
        hide: !form.island,
        options:
          constituencies[form.island] ||
          constituencies['Family Islands'].filter(n => n.includes(form.island)),
        view: {
          label: 'Constituency',
          value: v => v.toString().capitalize() || 'N/A'
        }
      },
      {
        name: 'voters_number',
        type: 'integer',
        use_profile: 'voters_card_number',
        view: false,
      },
      {
        heading: 'D) Reason For Applying For A Special Voter Certificate',
        hide: viewMode,
      },
      {
        name: 'voter_type_code',
        label: 'Reason for Application',
        options: approval_reasons,
        hide: this.age > 64,
        view: {
          label: 'Reason',
          // value: v => approval_reasons.find(p => p.value == v)?.label || 'N/A'
        },
      },
      {
        name: '__senior_citizen',
        label: 'Reason for Application',
        hide: this.age < 64,
        save: false,
        view: false,
        disabled: true,
      },
      {
        key: f => f.image[0],
        name: 'document_upload',
        type: 'file',
        label: 'Supporting Document',
        hide: this.age > 64,
      },
    ],
  }

  fields = (form, viewMode) => {
    const { state, forms } = this
    const { type } = state

    if (viewMode)
      return [
        ...forms[form.type.toLowerCase()](form, true),
        {
          name: 'pdf_upload',
          label: 'Form L Certificate',
          type: 'file:pdf',
          key: f => f.image[1],
          hide: form.withdrawn || form.application_decision != 'approved',
        },
        {
          name: 'web_pass_url',
          label: 'Web Pass',
          type: 'link',
          hide: form.withdrawn || !form.web_pass_url,
        },
        {
          name: 'verify_url',
          label: 'Verification Link',
          type: 'link',
          hide: form.withdrawn || !form.verify_url,
        },
      ]

    const j = forms
      .j(form)
      .map(normalizeField)
      .map(f => ({ ...f, hide: f.hide || type !== 'j' }))

    const k = forms
      .k(form)
      .map(normalizeField)
      .map(f => ({ ...f, hide: f.hide || type !== 'k' }))

    return [
      ...forms.select(form),
      ...k,
      ...j,
      {
        use_as_acceptance: true,
        hide: !type,
        name: 'acceptance',
        type: 'checkbox',
        label: `
          ${
            type == 'j' ? 'G)' : 'E)'
          } I declare that the information provided in this form is true
          and correct to the best of my knowledge, information and belief.
        `,
      },
    ]
  }

  decisions = ['Approved', 'Denied'].map(l => ({
    value: l.toLowerCase(),
    label: l,
  }))

  on = {
    cancel: () => this.setState({ record: null }),
    reason: v => this.setState({ reason: v?.[0]?.value }),
    decision: v => this.setState({ decision: v?.[0]?.value }),

    submit: async (ev, senior_auto = false) => {
      this.setState({ loading: true })

      const { state, props } = this
      const { history, location } = props
      const { record, reason, decision } = state
      const approved = decision == 'approved'
      const age = moment().diff(record.user.date_of_birth, 'years')

      const [resource, form_key] =
        record.type == 'J'
          ? ['/overseas_visitor_prds', 'overseas_visitor_prd']
          : ['/special_voter_prds', 'special_voter_prd']

      if (!senior_auto) {
        try {
          await $app.axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: decision,
              application_status: true,
              reason,
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = decision
            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success(
            'Success',
            `Applicant successfully ${approved ? 'enrolled' : 'denied'}`
          )
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to enroll applicant')
          return
        }
      }

      const fullName = listKeys(
        record.user,
        'first_name',
        'middle_name',
        'last_name'
      )
        .join(' ')
        .capitalize()

      if (approved) {
        let endpoint, reason

        if (record.type == 'J') {
          endpoint = 'overseas_visitor_prds'
          reason =
            record.occupation_status == 'a'
              ? 'Overseas Student'
              : 'Overseas Worker'
        }

        if (record.type == 'K' || senior_auto) {
          endpoint = 'special_voter_prds'
          reason =
            age > 64
              ? '65 Years Or Older'
              : approval_strings[record.voter_type_code]
        }

        try {
          await $app.axios.post(ENV.BLOCKCERTS_SERVER + '/api/v1/enqueue', {
            type: 'voter-certificate',
            company: 'prd',
            recipients: [
              {
                reason,
                endpoint,
                name: fullName,
                email: record.user.email,
                form_num: record.form_num,
                voters_number: record.user.voters_card_number,
              },
            ],
          })
        } catch (error) {
          console.error(error)
          toastr.error(
            'Error',
            "Failed to enqueue applicant's Digital Web Pass"
          )
        }
      }

      const emailMsg = approved
        ? `
        Good day ${record.user.first_name.capitalize()},
        <br/>
        ${fullName} is hereby certified as a special voter by virtue of
        <strong>Code ${
          record.type == 'J' ? 'O' : record.voter_type_code
        }</strong>
        and is permitted to vote as a special voter in the Advanced Poll on ${advanced_poll_date}.
      `
        : `
        Dear ${record.user.first_name.capitalize()},
        <br/>
        Please be advised that your application submitted on ${moment(
          record.created
        ).format('Do MMMM, YYYY')}
        to participate in the Advanced Poll was considered; however, it was not approved.
      `

      try {
        await $app.axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal Update - Special Voter Enrollment',
          message: `
            ${emailMsg}
            <br/><br />
            Thank you for using MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false, record: null, modal: null, reason: '' })
    },
  }

  render() {
    const { state, on, decisions } = this
    const { modal, decision, loading } = state

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS({ ...this, ...state })} />
        <Modal open={modal} onClose={on.cancel} center>
          <article className='w-96'>
            <div className='modal-header'>
              <h5 className='modal-title'>Approve/Deny Applicant</h5>
            </div>
            <ValidatorForm onSubmit={on.submit}>
              <div className='modal-body'>
                <div className='form-group form-show-validation'>
                  <label htmlFor='box_number'>
                    Decision
                    <span className='required-label'>*</span>
                  </label>
                  <SelectField
                    name='decision'
                    value={decision}
                    options={decisions}
                    onChange={on.decision}
                    className='form-control'
                    validators={['required']}
                    errorMessages={['Required']}
                    required
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  className='btn btn-round mr-2'
                  aria-label='Close'
                  onClick={on.cancel}
                >
                  Cancel
                </button>
                <input
                  className='btn custom-btn btn-round'
                  type='submit'
                  value='Submit'
                />
              </div>
            </ValidatorForm>
          </article>
        </Modal>
      </React.Fragment>
    )
  }
}
