import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class MaintenanceProgramme extends React.Component {
  resource = '/maintenance_programmes'
  form_key = 'maintenance_programme'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    /*{
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'First Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name of Operator',
        hide: form.i_am_applicant != false
    }, */
    {
        name: 'registration_mark',
        hint: 'C6'
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Manufacturer of Aircraft'
    },
    {
        name: 'op_name',
        label: 'Name of Operator'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
