import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";

export default class TractorClearing extends React.Component {
  resource = "/tractor_clearings";
  form_key = "tractor_clearing";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};
  long_name = "Application for Tractor Clearing of Agricultural Farm Land";

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "business_name",
      label: "Name of Business",
    },
    {
      name: "farm_street",
      label: "Farm Address",
    },
    {
      name: "farm_island",
      label: "Farm Island",
      options: islands,
    },
    {
      name: "registered_farmer",
      label: "Are you a registered farmer?",
      type: "select:bool",
    },
    {
      name: "last_reg_cert_numb",
      label: "Farmer's Registration Certificate Number",
      is: "integer",
      hide: !form.registered_farmer,
    },
    {
      name: "last_reg_cert_date",
      label: "Date of Last Farmer's Registration Certificate",
      type: "date",
      hide: !form.registered_farmer,
    },
    {
      name: "farm_size",
      label: "Size of Farm",
      is: "integer",
      hint: "In acres",
    },
    {
      name: "clearing_size",
      label: "Size of Farm to be Tractor Cleared",
      is: "integer",
      hint: "In acres",
    },
    {
      name: "cost_to_clear_land",
      label: "Approx. cost to clear farm land",
      is: "currency",
    },
    {
      name: "tractor_vendor_name",
      label: "Name of Tractor Vendor to Conduct Tractor Service",
    },
    {
      name: "tractor_vendor_cost",
      label: "Amount paid to Tractor Vendor",
      is: "currency",
    },
    {
      name: "cost_to_clear_land_upload",
      label: "Approx. cost to clear farm land (Quote)",
      type: "file:image",
      key: (f) => f.image[1],
    },
    {
      name: "tractor_vendor_invoice_upload",
      label: "Tractor Service Invoice",
      type: "file:image",
      key: (f) => f.image[2],
    },
    {
      name: "tractor_vendor_receipt_upload",
      label: "Tractor Service Receipt",
      type: "file:image",
      key: (f) => f.image[3],
    },
    {
      name: "last_reg_cert_upload",
      label: "Copy of Farmer's Registration Certificate",
      type: "file:image",
      hide: !form.registered_farmer,
      key: (f) => f.image[0],
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
