import React, { Fragment, isValidElement } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { Menu, MenuItem } from '@szhsin/react-menu'
import moment from 'moment'

import { fullName, getUploadPath } from './utils'
import verifyDigitalCert from '@modules/certs/verify'
import mapServiceName from '@constants/maps.services'
import CoatOfArms from '@images/bs-coat-of-arms.png'
import { extractKeys, getProperty } from '@helpers/objects'

const SubmissionView = props => {
  const { view, business, record, options } = props.location.state
  const { history, location, free, postpaid, show_spouse } = props
  if (!view) return null

  // console.log('view', props)

  const {
    user,
    invoice,
    dependents,
    payment_invoice,
    payment_status,
    payment_amount,
    application_decision,
  } = view

  const rawFields = props.fields(record)

  const getAddress = addresses => {
    const add = addresses && addresses.find(a => a.address_type == 'home')

    if (add) {
      return (
        <address>
          {add.street}
          <br />
          {add.p_o_box}
          {add.p_o_box ? <br /> : null}
          {add.island}, Bahamas
        </address>
      )
    }

    return <p>N/A</p>
  }

  const generateActions = () => {
    const actions = props.determineActions(record, true)
    if (!actions?.length) return null

    return (
      <Menu
        menuStyle={{ maxWidth: '280px' }}
        menuButton={(
          <button className='flex items-center btn custom-btn btn-round ml-auto'>
            <FaChevronDown size={18} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>Actions</span>
          </button>
        )}
        transition
        arrow
        portal
      >
        {actions.map((action, i) => (
          <MenuItem
            key={i}
            onClick={action.fn}
            className='flex items-center space-x-4 text-gray-700'
          >
            {
              isValidElement(action.icon)
                ? action.icon
                : <i className={'fas fa-' + (action.icon || 'circle')} />
            }
            <span>{action.text}</span>
          </MenuItem>
        ))}
      </Menu>
    )
  }

  // prettier-ignore
  const linkedServices = list => list.map(item => (
    <div
      key={item.service_type}
      className='col-md-6 col-lg-4 info-invoice'
      data-decision={item.application_decision.toLowerCase()}
    >
      <h5 className='sub'>{mapServiceName(item.service_type)}</h5>
      <strong>{item.application_decision}</strong>
    </div>
  ))

  const generateTable = field => {
    const { name, fields, value } = field
    const columns = fields.map(c => c.name || c)
    // console.log('columns', columns, value);

    const headings = fields.map(c => (
      <th key={c.name || c}>
        {(c.label || c.name || c).replaceAll('_', ' ').capitalize()}
      </th>
    ))

    const widths = fields.map(c => (
      <col key={c.name || c} span='1' width={c.width || 'auto'} />
    ))

    const rows = value.map((_row, i) => {
      // console.log('_row', _row, value);
      const isJSONObj = typeof _row == 'string' && _row.charAt(0) == '{'

      let row = isJSONObj ? JSON.parse(_row) : _row
      const isString = typeof row == 'string' && true

      // console.log('row', row)
      // console.log('isObj', isJSONObj)
      // console.log('isString', isString)
        
      const fields = columns.map((col, j) => {
        const rc = isString ? row : row[col]
        // console.log('rc', rc)

        const val = col == 'status' ? rc?.capitalize() || 'Pending' : rc

        return <td key={j}>{isString ? row?.capitalize() : val}</td>
      })

      return <tr key={i}>{fields}</tr>
    })

    return (
      <div className='form-wizard-form-table table-responsive' key={name}>
        <table className='table table-striped table-striped-bg-black'>
          <colgroup>{widths}</colgroup>
          <thead>
            <tr className='header'>{headings}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }

  const generateArray = field => {
    const { name, fields, rowLabel, value } = field
    // console.log(field)

    const list = value.map((row, i) => {
      const values = fields.map((f, j) => {
        const classes =
          f?.type == 'textarea'
            ? 'infor-invoice col-md-12'
            : 'infor-invoice col-md-6 col-lg-4'

        return (
          <div key={j} className={classes}>
            <h5 className='sub'>{f?.label ?? (f?.name ?? f).initialCaps()}</h5>
            <p>{row[f?.name ?? f] + ''}</p>
          </div>
        )
      })

      return (
        <div data-row key={i} className='row py-2'>
          <h5 className='sub text-muted col-sm-12 mb-3'>
            {(rowLabel ?? 'Item') + '#' + (i + 1)}
          </h5>
          {values}
        </div>
      )
    })

    return (
      <div className='form-wizard-form-array' key={name}>
        {list}
      </div>
    )
  }

  // prettier-ignore
  const submissionValues = fields => fields.map((field, i) => {
    const { name, label, value, heading, long } = field
    
    if (value == 'CUSTOM') {
      const el = rawFields.find(f => f.name == name)
        ?.view?.element(record[name], record, extractKeys(
          props,
          'viewLinkedApplication'
        ))

      if (el === null || el === undefined) return <Fragment key={i} />

      return (
        <div
          key={i}
          className='col-md-12 info-invoice'
          children={el}
        />
      )
    }

    if (value == 'LINKED') {
      const target = rawFields.find(f => f.name == name)

      // If for some reason target is not found; just return empty fragment
      if (!target) return <Fragment key={i} />

      return (
        <div key={i} className='col-md-6 col-lg-4 info-invoice'>
          <h5 className='sub'>{label}</h5>
          <p>
            <span
              children='(Click To View)'
              className='text-primary hover:underline cursor-pointer'
              onClick={() => props.viewLinkedApplication(
                target.applicationLabel ?? label,
                getProperty(record, name),
                target.fields
              )}
            />
          </p>
        </div>
      )
    }

    if (heading) {
      return (
        <div key={i} className='col-md-12 info-invoice'>
          <h5
            className='sub text-muted'
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h5>
        </div>
      )
    }

    let table = false
    let val = value

    if (String(value).startsWith('href::')) {
      const [, link] = value.split('::')
      val = /(original|medium)\/missing\.png$/.test(link) ? (
        'N/A'
      ) : (
        <a href={link} target='_blank' rel='noopener noreferrer'>
          (Click To View)
        </a>
      )
    }

    if (Array.isArray(value)) {
      if (field.table) {
        table = true
        val = generateTable(field)
      } else if (field.array) {
        table = true
        val = generateArray(field)
      } else val = value.join(', ')
    }

    const classes = long ? 'col-md-12' : 'col-md-6 col-lg-4'

    return (
      <div key={i} className={classes + ' info-invoice'}>
        <h5 className='sub'>{label}</h5>
        {table ? val : <p>{val}</p>}
      </div>
    )
  })

  const hasDigitalCert =
    application_decision == 'approved' &&
    view.fields.find(f => f.name == 'pdf_upload')

  const runCertVerifier = () => {
    props.setLoading(true)
    verifyDigitalCert(props.service.type, view.form_num, {
      onFetch: () => props.setLoading(false),
    })
  }

  const cost = payment_amount ? (
    <React.Fragment>
      <div className='price'>${Number(payment_amount).toFixed(2)}</div>
      <span>Taxes Included</span>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className='price'>N/A</div>
      <span>{postpaid ? 'Awaiting Fee' : 'No Application Fee'}</span>
    </React.Fragment>
  )

  const header = (
    <div className='row align-items-center'>
      <div className='col'>
        <h6 className='page-pretitle text-primary font-semibold text-lg'>
          {free ? 'FREE' : payment_status ? 'PAID' : 'UNPAID'}&nbsp;
          {application_decision
            ? ' | ' + application_decision
            : (payment_status && ' | PENDING') || null}
        </h6>
        <h4
          className='page-title'
          dangerouslySetInnerHTML={{ __html: props?.service?.agency?.name }}
        ></h4>
        <h3
          className='page-title'
          style={{ FontSize: '1.34rem' }}
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></h3>
      </div>
      <div className='col-auto' children={generateActions()} />
    </div>
  )

  const formatApplicationStatus = () => {
    if (payment_status === (false || null) && application_decision === 'processing' && parseInt(payment_amount) >= 0) {
      return 'AWAITING PAYMENT'
    } else {
      return application_decision.toUpperCase() ?? 'PENDING'
    }
  }

  const details = {
    invoice: invoice ? (
      <React.Fragment>
        <div className='col-md-4 info-invoice'>
          <h5 className='sub'>Invoice ID</h5>
          <p>{payment_invoice || 'N/A'}</p>
        </div>
        <div className='col-md-4 info-invoice'>
          <h5 className='sub'>Invoice Date</h5>
          <p>{moment(invoice.updated_at).format('Do MMM, YYYY')}</p>
        </div>
        <div className='col-md-4 info-invoice'>
          <h5 className='sub'>Invoice Status</h5>
          <p>{payment_status ? 'PAID' : 'UNPAID'}</p>
        </div>
      </React.Fragment>
    ) : null,
    business: business ? (
      <React.Fragment>
        <div className='separator-solid mt-0'></div>
        <div className='invoice-detail'>
          <div className='invoice-top'>
            <h3 className='title'>
              <strong>Business Details</strong>
            </h3>
          </div>
        </div>
        <div className='row'>
          {[
            ['Registered Name', business?.company_name?.initialCaps()],
            ['Trading As', business.trading_name_1 || '--'],
            ['Business Licence #', business.licence_number],
            ['TIN #', business.tin],
            ['Email', business.email],
            ['Address', business?.company_address?.initialCaps()],
          ].map(([label, val], i) => (
            <div className='col-md-4 info-invoice' key={i}>
              <h5 className='sub'>{label}</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: val?.replaceAll?.('\n', '<br />'),
                }}
              />
            </div>
          ))}
        </div>
      </React.Fragment>
    ) : null,
    supplements: dependents ? (
      <div className='row'>
        <div className='col-md-12'>
          <div className='invoice-detail'>
            <div className='invoice-top'>
              <h3 className='title'>
                <strong>Supplemental Forms</strong>
              </h3>
            </div>
          </div>
          <div className='separator-solid mb-2'></div>
          <div className='row'>{linkedServices(dependents)}</div>
          <div className='separator-solid'></div>
        </div>
      </div>
    ) : null,
    form: (
      <React.Fragment>
        <div className='col-md-4 info-invoice'>
          <h5 className='sub'>Application ID</h5>
          <p>{view.form_num}</p>
        </div>
        <div className='col-md-4 info-invoice'>
          <h5 className='sub'>Application Date</h5>
          <p>{moment(view.created_at).format('Do MMM, YYYY')}</p>
        </div>
        <div className='col-md-4 info-invoice'>
          <h5 className='sub'>Application Status</h5>
          <p style={{ textTransform: 'uppercase' }}>
            {formatApplicationStatus()}
            {/* {application_decision || 'PENDING'} */}
          </p>
        </div>
      </React.Fragment>
    ),
    user: location.state.options?.hide_user ? null : (
      <React.Fragment>
        { 
          [
          ['Applicant Name', fullName(user)],
          ['Proxy Collected By', fullName(record.proxy)],
          // ['Requester NIB', record.proxy?.NIB]
          [
            'Citizenship',
            props.is.admin && user.country_of_citizenship?.capitalize(),
          ],
          ...(props?.service?.type == 'TradeDispute' ? 
            [['Email', user.email.replace(/^\d+\+/, '')],
            ['Mobile Number', user.additional_phone_number],
            ['Address', getAddress(user.addresses)]]
           :
            [['NIB #', user.NIB],
            ['Passport #', user.passport_number],
            ["Driver's Licence", user.drivers_license_number],
            ["Voter's Card", user.voters_card_number],
            ['D.O.B', moment(user.date_of_birth).format('Do MMM, YYYY')],
            ['Email', user.email.replace(/^\d+\+/, '')],
            ['Mobile Number', user.additional_phone_number],
            ['Address', getAddress(user.addresses)]])
          ,
        ].map(([label, val], i) =>
          val ? (
            <div className='col-md-4 info-invoice' key={i}>
              <h5 className='sub'>{label}</h5>
              <div>{val}</div>
            </div>
          ) : null
        )}
        {props.skip_location === true || !view.pickup_location ? null : (
          <div className='col-md-4 info-invoice'>
            <h5 className='sub'>Pickup Location</h5>
            <address>
              {(view.pickup_location || '')?.capitalize()}
              <br />
              {(view.pickup_sub_location || '')?.capitalize()}
            </address>
          </div>
        )}
      </React.Fragment>
    ),
    // prettier-ignore
    spouse: show_spouse && user.spouse ? (
      <React.Fragment>
        <div className='separator-solid'></div>
        <div className='row'>
          <div className='col-md-4 info-invoice'>
            <h5 className='sub'>Spouse Name</h5>
            <p>
              {`${user.spouse?.first_name} ${
                user.spouse?.middle_name || ''
              } ${user.spouse?.last_name}`.capitalize()}
            </p>
          </div>
          {[
            ['Spouse NIB #', user.spouse?.NIB],
            [
              'Spouse D.O.B',
              moment(user.spouse?.date_of_birth).format('Do MMM, YYYY'),
            ],
            ["Spouse's Mobile Number", user.spouse?.work_number],
            ["Spouse's Address", user.spouse?.address],
            ["Spouse's Employer", user.spouse?.employer],
            ["Spouse's Employer Address", user.spouse?.employer_address],
            ["Spouse's Employer Number", user.spouse?.employer_number],
          ].map(([label, val], i) =>
            val ? (
              <div className='col-md-4 info-invoice' key={i}>
                <h5 className='sub'>{label}</h5>
                <div>{val}</div>
              </div>
            ) : null
          )}
        </div>
      </React.Fragment>
    ) : null,
    payment: invoice ? (
      <React.Fragment>
        <div className='separator-solid mt-0'></div>
        <div className='invoice-detail'>
          <div className='invoice-top'>
            <h3 className='title'>
              <strong>Order Summary</strong>
            </h3>
          </div>
          <div className='invoice-item'>
            <div className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <td>
                      <strong>Item</strong>
                    </td>
                    <td className='text-center'>
                      <strong>Price</strong>
                    </td>
                    <td className='text-center'>
                      <strong>Quantity</strong>
                    </td>
                    <td className='text-right'>
                      <strong>Totals</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{invoice.description}</td>
                    <td className='text-right'>{cost}</td>
                    <td className='text-center'>1</td>
                    <td className='text-right'>{cost}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : null,
  }

  const summary = (
    <div className='card-body'>
      <div className='separator-solid'></div>
      <div className='row'>
        {details.invoice}
        {details.form}
      </div>
      <div className='separator-solid'></div>
      <div className='row'>{details.user}</div>
      {details.business}
      {details.spouse}
      {details.supplements}
      <div className='row'>
        <div className='col-md-12'>
          <div className='separator-solid mt-0'></div>
          <div className='invoice-detail'>
            <div className='invoice-top'>
              <h3 className='title'>
                <strong>Details</strong>
              </h3>
            </div>
          </div>
          <div className='row'>
            {submissionValues(view.fields)}
            {hasDigitalCert ? (
              <div className='col-md-6 col-lg-4 info-invoice'>
                <h5 className='sub'>Verify Certificate</h5>
                <p
                  className='text-primary cursor-pointer'
                  onClick={runCertVerifier}
                >
                  (Click To View)
                </p>
              </div>
            ) : null}
          </div>
          {!!record.additional_images?.length && (
            <Fragment>
              <h5 className='sub text-muted'>Uploads</h5>
              <div className='flex flex-col flex-wrap md:flex-row'>
                {record.additional_images.map(img => (
                  <div
                    className='flex justify-between w-full md:w-1/2 md:px-4 mb-2 space-x-2'
                    key={img.url}
                  >
                    <span>{img.title.initialCaps()}</span>
                    <a
                      href={getUploadPath(img.url).slice(6)}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      View
                    </a>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          {details.payment}
          <div className='separator-solid mb-3' />
        </div>
      </div>
    </div>
  )

  return (
    <div
      className='application-details page-inner'
      style={{ marginTop: '60px' }}
    >
      <div className='row justify-content-center'>
        <div id='AppFormFull' className='col-12 col-lg-10 col-xl-9'>
          {header}
          <div className='page-divider'></div>
          <div id='AppForm' className='row'>
            <div className='col-md-12'>
              <div className='card card-invoice'>
                <div className='card-header'>
                  <div className='invoice-header'>
                    <div className='invoice-logo'>
                      <img src={CoatOfArms} alt='Bahamas Coat of Arms' />
                    </div>
                    <h3
                      className='invoice-title lg:text-right'
                      children={options?.title ?? 'Application'}
                    />
                  </div>
                </div>
                {summary}
                <div className='card-footer'>
                  <div className='row'>
                    <div className='col-sm-7 col-md-5 mb-3 mb-md-0 transfer-to'></div>
                    <div className='col-sm-5 col-md-7 transfer-total'>
                      <h5 className='sub'>Total Amount</h5>
                      {cost}
                    </div>
                  </div>
                  <div className='separator-solid'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className='col-12 col-lg-10 col-xl-9 text-right'>
          <button className='btn btn-next custom-btn' onClick={history.goBack}>
            Back
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubmissionView
