import React from 'react'
import Swal from 'sweetalert2'
import * as wutils from '@modules/form-wizard/utils'
import { unionOfEmployers } from './TradeUnions/trade-unions'
import { unionOfEmployees } from './TradeUnions/trade-unions'
import { asscOfEmployers } from './TradeUnions/trade-unions'
import { asscOfEmployees } from './TradeUnions/trade-unions'
import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import SERVICE_PROPS from '@helpers/extract-service-props'


// Section (5) subsection

export default class TradeDocumentRequest extends React.Component {
  resource = '/trade_document_requests'
  form_key = 'trade_document_request'
  
  skip_location = true
  // fee = 1
  free = true
  postpaid = false
  pay_on_approval = true
  defaults = {}

  hide_buttons = {
    status: true,
    deny: false,
  }

  custom_actions = [
    {
      text: 'Approve For Payment',
      icon: 'check',
      roles: ['dol'],
      fn: async record => {
        let fee

        const { value } = await Swal.fire({
          icon: 'info',
          input: 'text',
          text: 'Please set the number of pages to be printed',
          title: 'Pages Printed',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          inputValidator: val => {
            if (Number(val) == 'NaN') {
              return 'Please enter a valid amount.'
            }
          }
        })
    
        fee = Number(value);
    
        if (value) {
          const { isConfirmed } = await Swal.fire({
            icon: 'question',
            title: 'Confirm Page Amount',
            html: `
              Are you sure you want to print <b>${fee}</b> pages for
              ${wutils.fullName(record.user, false)}?
            `,
            showCancelButton: true,
            confirmButtonText: 'Submit',
          })
    
          if (isConfirmed) {
            const { props, resource, form_key } = this
            const { history, location } = props
    
            try {
              await $app.axios.put(resource + '/update_payment_amount', {
                [form_key]: {
                  form_num: record.form_num,
                  payment_amount: Number(fee).toFixed(2),
                },
              })
    
              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.payment_amount = Number(fee).toFixed(2)
    
                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }
    
              toastr.success('Success', 'Application fee successfully set')
            } catch (err) {
              console.error(err)
              Swal.fire(
                'Oops...',
                'An error occurred while approving the application',
                'error'
              )
            }
          }
        }
      }
    },
  ]

  validate = null

  foremost = null

  setUnionOptions = (form) => {
    if(form.union_type === 'Union of Employees') {
      return unionOfEmployees.nassau
    } else if(form.union_type === 'Union of Employers') {
      return unionOfEmployers.nassau
    } else if(form.union_type === 'Association of Employers') {
      return asscOfEmployers.nassau
    } else {
      return asscOfEmployees.nassau
    }
  }

  fields = form => [
    {
      // TODO: Change to Sweetalert popup on start of application
      name: 'union_type',
      options: [
        'Union of Employees',
        'Union of Employers',
        // `Employer's Union`,
        `Association of Employers`,
        // `Employee's Union`,
        `Association of Employees`
      ]
    },
    {
      name: 'union_name',
      label: (form.union_type === 'Union of Employees' || form.union_type === 'Union of Employers') ? 'Union Name' : 'Association Name',
      options: this.setUnionOptions(form),
    },
  ]
  //tets

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
