import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import { fullName } from '@modules/form-wizard/utils'
import { assertAll } from '@helpers/assert'
import ENV from '@constants/env'

/**
 * The code defines functions for submitting a service application for review and approving a service
 * application.
 */
async function setNumbers(record) {
  do {
    const { isDismissed } = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure that you would like to submit?',
      text: `
        By clicking "Confirm", you are acknowleding that you have reviewed this
        application and the accompanying attachments, and are ready to submi
        it to a Director/Approved Signatories. Would you like to proceed?
      `,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
    })

    if (isDismissed) return
  } while (false)

  let fdc_numb
  let license_numb

 /* This code block is displaying a confirmation dialog using the SweetAlert2 library to prompt the
 user to set license details for a service application. If the `record` object does not have a
 `renewal` property, the dialog will be displayed. The dialog contains two input fields for the user
 to enter a valid license number and FDC number. The `preConfirm` function is used to retrieve the
 values entered by the user and assign them to the `license_numb` and `fdc_numb` variables. */
  if (!record.renewal) {
    const { isDismissed } = await Swal.fire({
      title: 'Set Licence Details',
      html: `
        Please assign both a valid Licence Number & FDC Number for
        <strong>${fullName(record.user, false)}</strong>
        <div id='__dir-check'>
          <div>
            <label for='swal-lic-input'>Licence #</label>
            <input id="swal-lic-input" class="swal2-input"/>
          </div>
          <div>
            <label for='swal-fdc-input'>FDC #</label>
            <input id="swal-fdc-input" class="swal2-input"/>
          </div>
        </div>
      `,
      preConfirm: () => {
        license_numb = document.getElementById('swal-lic-input').value
        fdc_numb = document.getElementById('swal-fdc-input').value

        const inputError = assertAll(
          [license_numb, 'Please enter a Licence #'],
          [fdc_numb, 'Please enter an FDC #']
        )

        if (typeof inputError == 'string') {
          Swal.showValidationMessage(inputError)
          return false
        }

        return true
      },
    })
/* This code block defines a function called `setNumbers` that is used to submit a service application
for review. The function displays a confirmation dialog using the SweetAlert2 library to prompt the
user to set license details for a service application. If the `record` object does not have a
`renewal` property, the dialog will be displayed. The dialog contains two input fields for the user
to enter a valid license number and FDC number. The `preConfirm` function is used to retrieve the
values entered by the user and assign them to the `license_numb` and `fdc_numb` variables. */

    if (isDismissed) return
  }

  const { resource, form_key } = this
  const { history, location } = this.props

  try {
    const payload = {
      form_num: record.form_num,
      application_decision: 'approved',
    }

    if (fdc_numb) {
      Object.assign(payload, { license_numb, fdc_numb })
    }

    await $app.axios.put(resource + '/update_application', {
      [form_key]: payload,
    })

    if (location.state?.view) {
      const view = {
        ...location.state.view,
        license_numb,
        fdc_numb,
        application_decision: 'approved',
      }

      history.replace(location.pathname, { ...location.state, view })
    }

    toastr.success('Success', 'Application successfully submitted')
  } catch (err) {
    console.error(err)
    toastr.error('Error', 'Unable to submit application')
    return
  }
}
/* The `approveRecord` function is defining an asynchronous function that displays a confirmation
dialog using the SweetAlert2 library to prompt the user to confirm whether they would like to
approve a service application. The dialog contains a message asking the user to acknowledge that
they have reviewed the application and are satisfied with its contents. If the user clicks the
"Confirm" button, the `isConfirmed` variable will be set to `true`. If the user clicks the "Cancel"
button or dismisses the dialog, the function will return without performing any further actions. */

async function approveRecord(record) {
  const { isConfirmed } = await Swal.fire({
    icon: 'question',
    title: 'Are you sure that you would like to approve this application?',
    text: `
      By clicking "Confirm", you are acknowleding that you have reviewed this
      application and are satisfied with its contents.
      Would you like to approve?
    `,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Confirm',
  })

  if (!isConfirmed) return
  const { resource, form_key, long_name } = this
  const { service, history, location } = this.props

  try {
    await $app.axios.put(resource + '/update_application', {
      [form_key]: {
        form_num: record.form_num,
        application_status: true,
        application_decision: 'approved',
        license_numb: record.license_numb,
        fdc_numb: record.fdc_numb,
      },
    })

    if (location.state?.view) {
      const view = { ...location.state.view }
      view.application_decision = 'approved'

      history.replace(location.pathname, { ...location.state, view })
    }

    toastr.success('Success', 'Application successfully approved')
  } catch (err) {
    console.error(err)
    toastr.error('Error', 'Unable to approve application')
    return
  }

  try {
    const res = await $app.axios.get('/services/find_by_query', {
      params: {
        service: this.service_type || service.type,
        form_num: record.form_num,
      },
    })

    const updated = res.data[0]

    await $app.axios.post('/emails', {
      email: record.user.email,
      subject: `MyGateway Portal Update - ${long_name ?? service.title}`,
      message: `
        Good day ${record.user.first_name.capitalize()},
        <br />
        Your application for a ${long_name ?? service.title} has been
        approved. As promised, below is a link to your downloadable PDF Permit.
        <br />
        <br />
        <p><strong>Links</strong></p>
        <ul>
          <li>
            <a
              target='_blank'
              href='${ENV.WEB_SERVICE_URL}${updated?.image[0]}'
            >${long_name ?? service.title}</a>
          </li>
        </ul>
        <br />
        <br />
        Thank you for using MyGateway Portal.
      `,
    })

    toastr.success('Success', 'Email successfully sent')
  } catch (err) {
    toastr.error('Error', 'Unable to send permit email')
    console.error(err)
  }
}

export const submitForReview = service => ({
  text: 'Submit to Director',
  icon: 'id-badge',
  test: r =>
    (!r.renewal && !r.license_numb) || r.application_decision == 'pending',
  roles: ['officer'],
  fn: setNumbers.bind(service),
})

export const approveApplication = service => ({
  icon: 'check-square',
  text: 'Approve Application',
  test: r => (r.application_decision == 'processing' || r.application_decision == 'officer viewed') && r.license_numb,
  roles: ['director'],
  fn: approveRecord.bind(service),
})
