import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MCSidePanel from './sidebar'

import { extractKeys } from '@helpers/objects'

class SentMail extends React.Component {
  state = {
    current_page: 1,
    search: '',
  }

  componentDidMount() {
    //this.props.getAllNotes()
  }

  belongsName = name => {
    switch (name) {
      case "BirthCertificate":
        return "Registrar General Department"
      case "Passport":
        return "Passport Office"
      case "DriversLicense":
        return "Road Traffic Department"
      default:
    }
  }

  handleSearch = ev => {
    ev.preventDefault()
    const search = ev.target.value
    this.setState({ search, current_page: 1 })
    this.props.getSearchNotes(search, 1)
  }

  lastPage = () => {
    const { total_count } = this.props
    const result = Math.ceil(total_count / 10)

    return result == total_count ? 1 : result
  }

  pageChange = type => {
    let { current_page, search } = this.state

    if (type === "prev") --current_page
    else ++current_page

    this.setState({ current_page })
    this.props.getSearchNotes(search, current_page)
  }

  paginationAction = () => {
    const { current_page } = this.state

    return (
      <React.Fragment>
        {current_page > 1 ? <button type="button" className="btn btn-border-secondary" onClick={() => this.pageChange('prev')} ><i className="fa fa-angle-left"></i></button> : null}
        {current_page >= this.lastPage() ? null : <button type="button" className="btn btn-border-secondary" onClick={() => this.pageChange('next')} ><i className="fa fa-angle-right"></i></button>}
      </React.Fragment>)
  }

  render () {
    const { total_count } = this.props

    return (
      <div className="email-content email-content-main content content-full">
        <div className="page-inner-fill">
          <div className="page-with-aside mail-wrapper bg-white">
            <MCSidePanel {...this.props} />
            <div className="page-content mail-content">
              <div className="inbox-head d-lg-flex d-block">
                <h3>Sent Mail</h3>
                <form onSubmit={(e) => this.handleSearch(e)} className="ml-auto">
                  <div className="input-group">
                    <input type="text" ref={this.srchRef} placeholder="Search Email" className="form-control" />
                    <div className="input-group-append">
                      <button type="submit" className="input-group-text">
                        <i className="fa fa-search search-icon"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="inbox-body">
                <div className="mail-option">
                  <div className="email-filters-left">
                    <div className="chk-all">
                      <div className="btn-group">
                        <div className="form-check">
                          <label className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" /><span className="custom-control-label"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="email-filters-right ml-auto"><span className="email-pagination-indicator">{this.state.current_page}-{this.lastPage()} of {total_count}</span>
                    <div className="btn-group ml-3">
                      {this.paginationAction()}
                    </div>
                  </div>
                </div>

                <div className="email-list">
                  {this.props.notes.map(item => (
                      <div className={`email-list-item ${item == 0 ? 'read' : 'unread'}`}>
                        <div className="email-list-actions">
                          <div className="d-flex">
                            <label className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" /><span className="custom-control-label"></span>
                            </label>
                          </div>
                        </div>
                        <Link to={`/message-center/inbox/${item.id}`}>
                          <div className="email-list-detail">
                            <span className="date float-right">
                              {/*<i className="fa fa-paperclip paperclip"></i>*/}
                              {new Date(item.created_at).dateMonth()}</span><span className="from">{item.notable_type && item.notable_type.spaceBeforeCap()}</span>
                            <p className="msg">{this.belongsName(item.notable_type)}</p>
                          </div>
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => extractKeys(state.notes, 'notes', 'total_count')
)(SentMail)
