import React from 'react'
import SelectField from '@shared/form-fields/select'
import DateField from '@shared/form-fields/date'
import moment from 'moment'

export const TimePeriod = ({ on, slots, timePeriod }) => {
  const getField = ({ name, type }, index) => {
    const field = type == 'date' ? showDate(name, index) : showSlot(index)

    return field
  }

  const showDate = (name, index) => {
    const obj = timePeriod.filter((t, i) => i === index)[0]

    return (
      <DateField
        type='date'
        className='form-control'
        name={name}
        showTimeSelect
        showTimeSelectOnly
        onChange={on['time'](index, name)}
        selected={obj[name]}
        value={obj[name]}
        timeIntervals={60}
        minTime={moment().hour(9).minute(0)._d}
        maxTime={moment().hour(16).minute(0)._d}
        dateFormat='h:mm aa'
        disabled={name == 'from' ? true : false}
      />
    )
  }

  const showSlot = index => {
    const obj = timePeriod.filter((t, i) => i === index)[0]

    return (
      <SelectField
        onChange={on['amount'](index)}
        name='max_slots'
        icon={null}
        options={slots}
        values={[obj['slot_amount']]}
        className='form-control w-100'
        placeholder='Amount of Slots'
      />
    )
  }

  const rows = timePeriod.map((time, index) => {
    const fields = Object.keys(time).map((key, i) => {
      const obj = columns.filter(c => c.name === key)
      return <td key={i}>{getField(obj[0], index)}</td>
    })

    const close =
      timePeriod.length > 1 ? (
        <span
          data-action
          onClick={() => {
            on['delete'](index)
          }}
        >
          <i className='fas fa-times-circle'></i>
        </span>
      ) : null

    return (
      <tr key={index}>
        {fields}
        <td>{close}</td>
      </tr>
    )
  })

  return (
    <div className='form-wizard-form-table table'>
      <table className='table table-striped table-striped-bg-black'>
        <colgroup>
          <col span='2' width='256px' />
          <col span='1' width='128px' />
        </colgroup>
        <tbody>{rows}</tbody>
      </table>
      {/* <aside>
        <span data-action onClick={on['add']}>
          Add Row <i className='fas fa-plus-circle'></i>
        </span>
      </aside> */}
    </div>
  )
}

const columns = [
  {
    name: 'from',
    label: 'From',
    width: 256,
    type: 'date',
  },
  {
    name: 'to',
    label: 'To',
    width: 256,
    type: 'date',
  },
  {
    name: 'slot_amount',
    label: 'Slot Amount',
    width: 128,
    type: 'slots',
  },
]
