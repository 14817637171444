import React from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'

// import InputField from '@shared/form-fields/input'
// import Loader from '@shared/loader'
import Wizard from '@modules/form-wizard'
import islands from '@constants/islands'
import SERVICE_PROPS from '@helpers/extract-service-props'
import toastr from '@modules/toastr'

class BahamaHostCompanyRegistration extends React.Component {
  state = {
    profile: '',
    session_numb: '',
    session_date: '',
    venue: '',
    record: null,
    loading: false,
  }

  async componentDidMount () {
    try {
      const { data } = await $app.axios.get('/profiles/')
      this.setState({ profile: data })
    } catch (error) {
      console.error(error)
    }
  }

  resource = '/bahama_host_companies'
  form_key = 'bahama_host_company'

  pausable  = true // Resumable Form Application
  postpaid = true
  defaults = {
    // exempt_payment: true,
  }
  
  skip_location = true
  custom_acceptance = false

  hide_columns = ['Name']
  columns = props => [
    {
      name: 'Company Name',
      selector: 'r.company_name',
      sortable: true,
      cell: r => (
        <span onClick={props.view(r)} data-action>
          {`${r.company_name || r.trading_name}`.capitalize()}
        </span>
      ),
    },
    // {
    //   name: 'Company Name',
    //   selector: row => row.company_name?.capitalize() || row.trading_name?.capitalize(),
    //   sortable: true,
    //   searchable: r => r.company_name,
    // },
    {
      name: 'Program Type',
      selector: row => row.industry_type?.capitalize() || 'N/A',
      sortable: true,
      searchable: r => r.industry_type,
    },
    {
      name: 'Session Number',
      selector: row => row.session_numb || 'Not Set',
      sortable: true,
      searchable: r => r.session_numb,
    },
    {
      name: 'Session Date',
      selector: row => row.session_date,
      cell: r => (
        <span>{moment(r?.session_date).format('MMMM Do YYYY') || 'N/A'}</span>
      ),
      sortable: true,
      searchable: r => r.session_date,
    },
    {
      name: 'Officer Name',
      selector: 'r.officer_name',
      sortable: true,
      cell: r => (
        <span>
          {`${r.user?.last_name}, ${r.user?.first_name}`.capitalize()}
        </span>
      ),
    },
  ]

  // fee = ({ industry_type, in_business }) => {
  //   switch (industry_type) {
  //     // case 'BahamaHost General':
  //     case 'Tour Guide Training':
  //       return 150
  //     case 'BahamaHost Recertification':
  //       return 75
  //     case 'Water Sports Training':
  //       if(in_business) {return 0} else {return 25}
  //       // if(!in_business) {return 25}
  //     case 'Certificate Reprint':
  //       return 5
  //     default:
  //       return 150
  //   }
  // }

  free = true


  hide_buttons = {
    status: true,
    deny: true,
    email: $app.roles?.includes('senior_officer'),
  }

  // filter_records = r => $app.is_citizen
  //   || r.island_location === this.state.profile.island
  //   || this.state.profile.island === 'New Providence'

  // before_apply = () => {
  //   const { profile } = this.state;

  //   const employer = profile.addresses[1]?.business_name || null;

  //   if (!profile.education_level && !employer) {
  //     return "In order to apply for this form, both the Employment Details and Education Details sections in the Profile tab must be filled out.";
  //   }
  // };

  custom_actions = [
    {
      text: 'Approve Application',
      icon: 'check-square',
      test: r => $app.roles?.includes('officer'),
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        })
        
        if (isDismissed) return

        const { resource, form_key, props } = this
        const { history, location } = props
        const { axios } = $app

        try {
          await axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          toastr.success('Success', 'Application status successfully set')
        } catch (error) {
          console.error(error)
          Swal.fire(
            'Oops...',
            'There has been an error with the status change of the application',
            'error'
          )
        }
      }
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r => $app.roles?.includes('officer'),
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        })
        if (isDismissed) return

        const { resource, form_key, props } = this
        const { history, location } = props
        const { axios } = $app

        try {
          await axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'denied',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'denied'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          toastr.success('Success', 'Application status successfully set')
        } catch (error) {
          console.error(error)
          Swal.fire(
            'Oops...',
            'There has been an error with the status change of the application',
            'error'
          )
        }
      }
    },
  ]



  fields = form => [
    // {
    //   name: 'reason',
    //   label: 'Reason for Application',
    //   options: [
    //     'Public Service Driver',
    //     'Professional Tour Guide',
    //     'Water Sports',
    //     'General',
    //     'Other',
    //   ],
    //   callback: async v => {
    //     if (v == 'Professional Tour Guide') {
    //       await Swal.fire(
    //         'Notice',
    //         `
    //           *Prerequisite for Tour Guiding course is a current
    //           (3 years or less) BahamaHost Certificate.
    //         `,
    //         'info'
    //       )
    //     } else if (v == 'Water Sports') {
    //       await Swal.fire(
    //         'Notice',
    //         `
    //           *Prerequisite for Water Sports course is a current
    //           (3 years or less) BahamaHost Certificate.
    //         `,
    //         'info'
    //       )
    //     }

    //     if (v != 'Other') {
    //       return { other_reason: '' }
    //     }
    //   },
    // },
    "::Company Details",
    {
      name: 'form_num',
      label: 'Application ID#',
      save: true,
      hide: true,
    },
    {
      name: 'session_numb',
      label: 'Session Number',
      hide: true,
      view: true,
    },
    {
      name: 'venue',
      hide: true,
      view: true,
    },
    {
      name: 'session_date',
      label: 'Start Date of Session',
      hide: true,
      view: true,
    },
    {
      name: 'company_name',
      label: 'Company Name',
    },
    {
      name: 'company_address',
      label: 'Company Address',
      required: false,
    },
    {
      name: 'bl_number',
      label: 'Business Licence #',
      required: false,
      view: false,
    },
    {
      name: 'business_nib',
      label: 'Business NIB',
      required: false,
      view: false,
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      type: 'phone',
      required: false,
    },
    {
      name: 'company_email',
      label: 'Company Email',
      type: 'email',
      required: false,
    },
    "::Session Details",
    // {
    //   name: 'other_reason',
    //   label: 'Other',
    //   hide: form.reason != 'Other' && form.reason !== form.other_reason,
    //   view: false,
    // },
    {
      name: 'time_of_session',
      label: 'Time of Session',
      options: ['Morning', 'Evening'],
      // options: ['Morning', 'Afternoon', 'Evening'],
    },
    {
      name: 'industry_type',
      label: 'Program Type',
      options: [
        // {label: "Public Service Driver Training ($150)", value: 'Public Service Driver Training'},
        // {label: "Tour Guide Training ($150)", value: 'Tour Guide Training'},
        // {label: `Water Sports Training ($25)`, value: 'Water Sports Training'},
        // {label: "BahamaHost General ($150)", value: 'BahamaHost General'},
        // {label: "BahamaHost Recertification ($75)", value: 'BahamaHost Recertification'},
        // {label: "Certificate Reprint ($5)", value: 'Certificate Reprint'},
        'Customer Service Training',
        { name: 'BahamaHost General', value: 'BahamaHost General'},
        'Specialized Training',
      ],
    },
    // {
    //   name: 'in_business',
    //   type: 'select:bool',
    //   label: 'Are you currently in the Water Sports business?',
    //   hide: form.industry_type != 'Water Sports Training',
    //   required: false,
    //   hint: `${!form.in_business ? 'If not in the <b>Water Sports business</b> the certificate <b>fee</b> is <b>$25</b>' : ''}`,
    // },
    // {
    //   name: 'cert_type',
    //   label: 'Type of Certificate (Reprint)',
    //   hide: form.industry_type != 'Certificate Reprint',
    //   options: [
    //     'Public Service Driver Training',
    //     'Tour Guide Training',
    //     'Water Sports Training',
    //     'BahamaHost General',
    //     'BahamaHost Recertification',
    //     'Certificate Reprint',
    //     // 'BahamaHost Seminar', 
    //   ],
    // },
    {
      name: 'island_location',
      options: islands,
      callback: () => {
        const other = {}

        if (form.other_reason) {
          other.reason = form.other_reason
        }
        return other
      },
    },
    {
      name: 'island_sub_location',
      label: '',
      hide: !['Andros', 'Eleuthera'].includes(form.island_location),
      options: [
        'North',
        'Central',
        'South',
      ],
    },
    {
      name: 'agent_contact_list',
      label: 'Agent Contact Details',
      required: true,
      rowLabel: 'Contact',
      fields: [
        {
          name: 'first_name',
          required: true,
        },
        {
          name: 'middle_name',
          required: false,
        },
        {
          name: 'last_name',
          required: true,
        },
        {
          name: 'gender',
          options: ['male', 'female'],
          required: true,
        },
        {
          name: 'nib',
          label: 'NIB #',
          type: 'integer',
          required: true,
        },
        {
          name: 'position',
          required: false,
        },
        {
          name: 'email',
          type: 'email',
          required: false,
        },
        // {
        //   name: 'identification_upload',
        //   label: 'Government Issued Photo ID',
        //   type: 'file:all',
        //   required: false,
        // },
      ],
    },

    {
      name: 'employees',
      label: 'Employee Details',
      required: true,
      rowLabel: 'Participant',
      fields: [
        {
          name: 'first_name',
          required: true,
        },
        {
          name: 'middle_name',
          required: false,
        },
        {
          name: 'last_name',
          required: true,
        },
        {
          name: 'gender',
          options: ['male', 'female'],
          required: true,
        },
        {
          name: 'nib',
          label: 'NIB #',
          type: 'integer',
          required: true,
        },
        {
          name: 'position',
          required: false,
        },
        {
          name: 'email',
          type: 'email',
          required: false,
        },
        // {
        //   name: 'identification_upload',
        //   label: 'Government Issued Photo ID',
        //   type: 'file:all',
        //   required: false,
        // },
      ],
    },
    // {
    //   name: 'photo_upload',
    //   key: 'image.1',
    //   label: 'Government Issued ID',
    //   type: 'file:image',
    //   // key: f => f.image,
    // },
    // {
    //   name: 'exempt_payment',
    //   label:
    //     'By clicking here, you are certifying that this application is exempt from payment.',
    //   type: 'checkbox',
    //   required: false,
    //   hide: true,
    //   save: true,
    // },
    // {
    //   name: 'non_refundable',
    //   type: 'checkbox',
    //   view: false,
    //   required: true,
    //   label: `
    //     Registration fee is nonrefundable and remains
    //     valid for a period of six (6) months).
    //   `,
    // }
    // {
    //   name: 'exempt_reason',
    //   label: 'What is your reason for payment exemption?',
    //   type: 'textarea',
    // },
  ]

  // render() {
  //   const { state } = this

  //   return (
  //     <React.Fragment>
  //       {/* {console.log(this)} */}
  //       {/* {console.log($app)} */}
  //       <Loader loading={state.loading} />
  //       <Wizard {...SERVICE_PROPS(this)} />
  //     </React.Fragment>
  //   )
  // }
  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default BahamaHostCompanyRegistration
