const levels = [
  'None',
  'Pre K',
  'Kindergarten',
  'Primary School',
  'High School',
  'College/University',
  'Post Graduate',
]

export const allEducationLevels = levels.slice()
export default levels.slice(3)
