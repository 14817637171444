const styles = {
  container: showingMap => ({
    maxWidth: showingMap ? '800px' : '640px',
    padding: showingMap ? '4rem 0.25rem 0' : '4rem 1rem 2rem',
  }),

  fullWidth: {
    width: '100%',
  },
}

export default styles
