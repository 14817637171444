export const AVAILABLE_SERVICES = {
  'Police Force': {
    PoliceCertificate: 'Police Certificate',
    TrafficRecord: 'Traffic Record',
  },

  'Registrar General': {
    BirthCertificate: 'Birth Certificate',
    DeathCertificate: 'Death Certificate',
    MarriageCertificate: 'Marriage Certificate',
  },

  'Road Traffic Dept.': {
    DriversLicense: `Friver's Licence`,
    DriverLicenseReplacement: `Driver's Licence (Replacement)`,
    DriversLearnersPermitRenewal: `Driver's Permit Renewal`,
  }
}

export const getPickupRequirements = (service, form) => {
  if (service == 'DriverLicenseReplacement' && form.reason == 'Broken') {
    return "The broken driver's licence that is being replaced."
  }

  return 'No special requirements.'
}