import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class TrafficRecordRenewal extends React.Component {
  id = "TrafficRecordRenewal";
  service = "traffic record renewal";
  title = "Traffic Record Renewal";
  agency = "rbpf";
  item = "294";
  admin = "police";
  resource = "/traffic_record_renewals";
  form_key = "traffic_record_renewal";

  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 5;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "public_dl_numb",
      label: "Public Service Driver's License #",
      is: "integer",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
