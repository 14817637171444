import React, { useState, useEffect, Fragment } from 'react'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import Switch from 'react-switch'

import { updateProfile } from '@actions/users'
import StateStore from '@helpers/state-store'
import { extractKeys } from '@helpers/objects'
import Loader from '@shared/loader'

const OTPPreferences = ({ id, children }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [profile, setProfile] = StateStore()

  useEffect(() => {
    if (!open) return
    // Fetch manually instead using getUserProfile()
    // to avoid bothering with a store selector
    $app.axios
      .get(id ? '/profiles/' + id : '/profiles')
      .then(res => {
        setProfile(
          extractKeys(
            res.data,
            'id',
            'allow_sms',
            'enable_sms_otp',
            'enable_email_otp'
          )
        )
      })
      .catch(err => {
        console.error(err)
      })
  }, [open])

  const update = async () => {
    if (id && $app.hasRole('support')) {
      await $app.axios.put(`/profiles/${profile.id}/support`, { profile })
    } else {
      await dispatch(updateProfile(id, profile))
    }

    setOpen(false)
    Swal.fire('Success', 'OTP Preferences successfully updated.', 'success')
  }

  const on = {
    // prettier-ignore
    sms: bool => {
      if (bool) {
        setProfile({
          allow_sms: true,
          enable_sms_otp: bool
        })
      } else setProfile({ enable_sms_otp: false })
    },
  }

  return (
    <Fragment>
      {children
        ? React.cloneElement(children, {
            onClick: ev => {
              children.props.onClick?.(ev)
              setOpen(true)
            },
          })
        : null}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        classNames={{ modal: 'w-full sm:w-96' }}
        center
      >
        <header className='modal-header'>
          <h4>OTP Preferences</h4>
        </header>
        <article className='modal-body'>
          {!('allow_sms' in profile) && <Loader loading contained />}
          <div className='flex items-center justify-between mb-3'>
            <span className='mb-0' style={{ fontSize: '0.875rem' }}>
              Receive Via SMS
            </span>
            <Switch
              checked={profile.enable_sms_otp ?? false}
              onChange={on.sms}
              onColor='#49ABF7'
            />
          </div>
        </article>
        <footer className='modal-footer flex justify-end'>
          <button className='btn btn-muted' onClick={() => setOpen(false)}>
            Close
          </button>
          <button className='btn btn-primary' onClick={update}>
            Update
          </button>
        </footer>
      </Modal>
    </Fragment>
  )
}

export default OTPPreferences
