import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import Swal from '@sweetalert'
import Form from '@modules/validator-form'
import Loader from '@shared/loader'
import { Spinner, fullName, getUploadPath } from '@modules/form-wizard/utils'
import '@styles/service-request.css'

const resource = '/service_request_forms'
const form_key = 'service_request_form'

const fields = form => [
  {
    name: 'existing_service',
    label: 'Is this a new or existing service?',
    options: [
      { label: 'New', value: false },
      { label: 'Existing', value: true },
    ],
  },
  'agency_name',
  'service_name',
  // {
  //   name: 'form_upload',
  //   type: 'file:pdf',
  //   label: form.existing_service
  //     ? 'Requirements Document'
  //     : 'Existing Form Upload'
  // },
  // {
  //   required: false,
  //   name: 'legal_document_upload',
  //   type: 'file:pdf',
  //   label: 'Additional Documents',
  // },
  {
    name: 'desc',
    type: 'textarea',
    label: 'Please describe the nature of this service',
  },
]

export const ServiceSuggestions = props => {
  const [loading, setLoading] = useState(false)

  const save = async form => {
    try {
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Confirm Submission',
        text: `
          Are you sure you want to submit? By clicking "Submit" you agree
          that the information provided is accurate.
        `,
        showCancelButton: true,
        confirmButtonText: 'Submit',
      })

      if (!isConfirmed) return
      setLoading('Submitting Your Suggestion...')
      await $app.axios.post(resource, {
        ...form,
        acceptance: true,
      })

      await Swal.fire({
        icon: 'success',
        title: 'Thank You',
        text: `
          Your suggestion has been saved and will be reviewed.
        `,
      })

      props.history.push('/')
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'We were unable to submit your suggestion.',
      })
    } finally {
      setLoading(false)
    }
  }

  const fprops = { fields, form_key, save, name: '__SSG' }

  return (
    <div className='row' style={{ marginTop: 70 }}>
      <Loader loading={loading} />
      <div className='wizard-container wizard-round col-md-9'>
        <div className='wizard-header text-center'>
          <h3 className='wizard-title'>
            <strong>Service</strong> Request
          </h3>
          <small>MyGateway</small>
        </div>
        <div className='wizard-body'>
          <div className='tab-content'>
            <div className='tab-pane active form-wizard__form'>
              <Form {...fprops} />
            </div>
          </div>
        </div>

        <div className='wizard-action'>
          <div className='pull-right'>
            <button
              type='submit'
              form={fprops.name}
              className='btn btn-next btn-primary'
            >
              Next
            </button>
          </div>
          <div className='clearfix'></div>
        </div>
      </div>
    </div>
  )
}

export const SuggestionsTable = props => {
  const [data, setData] = useState(null)
  const [params, setParams] = useState({})

  const fetch = async (change = {}) => {
    try {
      const { data } = await $app.axios.get(resource, {
        params: { ...params, ...change },
      })

      setData(data.records)
      setParams(data.records_params)
    } catch (err) {
      await Swal.fire(
        'Error',
        `
          We were unable to fetch the current list of service requests.
          Please try again later
        `,
        'error'
      )
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const show = record => async () => {
    await Swal.fire({
      icon: 'info',
      confirmButtonText: 'Close',
      title: (
        <React.Fragment>
          {record.service_name}
          <span className='block text-xl text-gray-700 font-normal'>
            {record.agency_name}
          </span>
        </React.Fragment>
      ),
      html: (
        <article className='service-request__details'>
          <dl>
            <dt>Requested By</dt>
            <dd>{fullName(record.user, false)}</dd>
            <dt>Submission Date</dt>
            <dd>{moment(record?.created_at).format('Do MMMM, YYYY')}</dd>
            <dt>
              {record.existing_service ? 'Requirements Doc.' : 'Original Form'}
            </dt>
            <dd>
              <a
                href={getUploadPath(record.image[0], 1)}
                target='_blank'
                rel='noreferrer'
              >
                View
              </a>
            </dd>
            <dt>Additional Upload</dt>
            <dd>
              {/original\/missing/.test(record.image[1]) ? (
                'N/A'
              ) : (
                <a
                  href={getUploadPath(record.image[1], 1)}
                  target='_blank'
                  rel='noreferrer'
                >
                  View
                </a>
              )}
            </dd>
          </dl>
          {record.desc ? (
            <div>
              <span>Description</span>
              <span>{record.desc}</span>
            </div>
          ) : null}
        </article>
      ),
    })
  }

  const columns = [
    {
      name: 'Service',
      selector: 'service_name',
      sortable: true,
      cell: r => (
        <span onClick={show(r)} data-action>
          {r.service_name}
        </span>
      ),
    },
    {
      name: 'Agency',
      selector: 'agency_name',
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'existing_service',
      sortable: true,
      format: r => (r.existing_service ? 'Amendment' : 'New Service'),
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      width: '128px',
      format: ({ created_at }) => moment(created_at).format('YYYY-MM-DD'),
      sortFunction: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
  ]

  const tprops = {
    columns,
    data: data ?? [],

    noHeader: true,
    defaultSortField: 'created_at',
    defaultSortAsc: false,
    progressPending: data === null,
    progressComponent: <Spinner />,

    paginationTotalRows: params.no_of_records,
    onChangeRowsPerPage: per_page => fetch({ per_page }),
    onChangePage: page => fetch({ page }),
  }

  return (
    <div className='content'>
      <div className='page-inner'>
        <div className='card card-round'>
          <div className='card-header'>
            <h4 style={{ margin: 0 }}>Service Requests</h4>
          </div>
          <div className='card-body'>
            <div className='table-responsive form-records-table clickable-rows'>
              <DataTable {...tprops} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
