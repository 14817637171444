import React from 'react'
import { connect } from 'react-redux'
import { GoogleApiWrapper } from 'google-maps-react'

import { extractKeys } from '@helpers/objects'
import { fetchKey } from '@reducers/gmap'

const MapsWrapper = component => {
  class Wrapper extends React.Component {
    componentDidMount() {
      !this.props.key && this.props.fetchKey()
    }

    render() {
      const { apiKey, ...props } = this.props
      const GoogleMap = apiKey ? GoogleApiWrapper({ apiKey })(component) : null

      return GoogleMap ? <GoogleMap {...props} /> : null
    }
  }

  return connect(state => extractKeys(state.gmap, 'key:apiKey'), { fetchKey })(
    Wrapper
  )
}

export default MapsWrapper
