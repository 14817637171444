export default [
  'New Providence',
  'Grand Bahama',
  'Abaco',
  'Eleuthera',
  'Exuma',
  'Andros',
  'Berry Islands',
  'Bimini',
  'Acklins',
  'Mayaguana',
  'Cat Island',
  'Crooked Island',
  'Inagua',
  'Long Island',
  'Ragged Island',
  'Rum Cay',
  'San Salvador',
  'Long Cay',
].map(c => ({ value: c, label: c }))
