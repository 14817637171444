import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { ValidatorForm } from 'react-form-validator-core'
import { Modal } from 'react-responsive-modal'
import Select from 'react-dropdown-select'
import { confirmAlert } from 'react-confirm-alert'

import ScheduleModal from './AppointmentModal'
import ScheduleCalendar from './AppointmentCalendar'
import ConfirmationModal from './ConfirmationModal'
import { SlotModal } from './SlotModal'

import {
  getAppointments,
  removeAppointment,
  updateAppointment,
  getBlackoutDates,
} from '@actions/appointments'

import {
  getAgencyEvents,
  getCalendarEvents,
  getAgencyLocations,
  getAgencyServices,
} from '@actions/agencies'

import toastr from '@modules/toastr'
import SelectField from '@shared/form-fields/select'
import Loader from '@shared/loader'
import { extractKeys } from '@helpers/objects'
import { hasAny } from '@helpers/arrays'

import { serviceRecord, serviceAlias } from '@constants/service-record-map'

import '@styles/calendar.css'

class ScheduleAppointment extends React.Component {
  constructor(props) {
    super(props)

    this.user = $app.current_user
    this.isCitizen = $app.roles.includes('pilot')

    const pre_config = props.location.state?.appointment
    this.preconfigured = pre_config
    this.__redirect = props.location.state?.redirect

    this.services = [
      { label: 'Vehicle Licence Renewal', value: 'IndividualLicense' },
      { label: "Driver's Licence Renewal", value: 'DriversLicense' },
      { label: 'Fingerprinting Service', value: 'FingerPrinting' },
      { label: 'Chassis Check', value: 'ChassisCheck' },
      { label: 'New Firearms License', value: 'GunLicense' },
    ]

    this.state = {
      appointments: [],
      appointment: {},
      status: null,
      modal: false,
      createModal: props.location?.state?.createModal || false,
      service: this.services.find(
        s =>
          s.value ==
          (props.location?.state?.schedulable_type ||
            pre_config?.schedulable_type)
      )?.value,
      location: '',
      appointmentDate: null,
      minTime: moment().hour(9).minute(0)._d,
      maxTime: moment().hour(16).minute(0)._d,
      error: '',
      confirmModal: false,
      deleteModal: false,
      recordId: props.location?.state?.recordId || null,
      selectService: [{ label: 'All Services', value: null }],
      timesExcluded: [],
      locations: [],
      loading: true,
      intro: true,
      reason: '',
      scheduleId: pre_config?.schedulable_id || '',
      slotModal: false,
      interval: { label: '20', value: 20 },
      userName: '',
      timePeriod: [],
      slotDisabled: false,
      userInfo: props.location?.state?.userInfo || {},
      slotPeriod: [
        {
          from: moment().hour(9).minute(0)._d,
          to: moment().hour(16).minute(0).second(0)._d,
          slot_amount: { label: '1', value: 1 },
        },
      ],
      initialView: props?.location?.state?.initialDate
        ? 'dayGridDay'
        : 'dayGridMonth',
      initialDate: props?.location?.state?.initialDate || new Date(),
      limits: null,
      finishedDay: null,
    }
  }

  remainder = 20 - (moment().minute() % 20)

  async componentDidMount() {
    try {
      const { data } = await $app.axios.get('/schedules')
      this.setState({ appointments: data.schedules, loading: false })
    } catch (error) {
      console.error(error)
      this.setState({ error, loading: false })
    }

    this.props.getAppointments(!this.isCitizen)
    this.props.getCalendarEvents()
    this.props.getAgencyLocations()
    this.props.getAgencyServices()
    // this.props.getAgencyEvents()
    this.props.getBlackoutDates()
  }

  appointmentIntro = `
    To book an appointment, simply click on any available time slot or use the 'Request Appointment' button 
    at the top.
    <br>
    Please ensure that the accompanying form has already been completed before scheduling an appointment.
    <br>
    Click OK to get started.
  `

  initialCaps = str =>
    str
      ?.split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
      .join(' ')

  getMinTime = () => {
    const { maxTime } = this.state
    const now = new Date()
    const remainder = 20 - (moment().minute() % 20)
    const minTime =
      now < moment().hour(9).minute(0)._d
        ? moment().hour(9).minute(0)._d
        : now > maxTime
        ? maxTime
        : moment().add(remainder, 'm').toDate()

    return minTime
  }

  getNextDay = date => {
    return date.getDay() >= 0 && date.getDay() < 5
      ? moment().add(1, 'days').hours(9).startOf('hour').toDate()
      : moment()
          .add(8 - date.getDay(), 'days')
          .hours(9)
          .startOf('hour')
          .toDate()
  }

  getDateTime(date, onlyDate = false) {
    return moment(date).format('DD-MM-YYYY' + (onlyDate ? '' : ' @ h:mm A'))
  }

  handleSelect = ({ start, allDay }) => {
    if (allDay) return
    const { maxTime } = this.state

    const now = new Date()
    const minTime = this.getMinTime()

    const remainder = 20 - (moment().minute() % 20)
    const date =
      start < now || !start
        ? now < maxTime && now > moment().hour(9).minute(0)._d
          ? moment().add(remainder, 'm').toDate()
          : now < moment().hour(9).minute(0)._d
          ? moment().hour(9).minute(0)._d
          : this.getNextDay(now)
        : start

    if (date.toDateString() == now.toDateString()) {
      this.setState({ minTime })
    } else {
      this.setState({ minTime: moment().hour(9).minute(0)._d })
    }

    this.setState({ createModal: true })
  }

  handleSlotSubmit = async e => {
    e && e.preventDefault()
    const { slotPeriod, interval, scheduleId } = this.state

    const newTimePeriod = slotPeriod.map(({ from, to, slot_amount }) => {
      const start = moment(from).hour().toString()
      const end = moment(to).hour().toString()
      const amount = slot_amount[0].value
      return JSON.stringify({ time: `${start}-${end}`, max_slots: amount })
    })

    try {
      const { data } = await $app.axios.patch(
        `/schedules/${scheduleId}/modify_schedule_limit`,
        {
          slot_interval: interval[0].value,
          time_period: newTimePeriod,
          active: true,
        }
      )

      data &&
        !data.error &&
        toastr.success(
          'Success',
          'Appointment Slots have been updated successfully'
        )
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to update appointment slots')
    }

    this.setState({
      slotModal: false,
      scheduleId: '',
      interval: { label: '20', value: 20 },
      slotPeriod: [
        {
          from: moment().hour(9).minute(0)._d,
          to: moment().hour(16).minute(0).second(0)._d,
          slot_amount: { label: '1', value: 1 },
        },
      ],
    })
  }

  closeModal = () => {
    this.setState({
      createModal: false,
      timesExcluded: [],
      locations: [],
      recordId: '',
    })
    if (this.preconfigured && this.__redirect)
      this.props.history.replace(this.__redirect)
  }

  slot = {
    handleSlotState: (index, value, name) => {
      const { slotPeriod } = this.state
      const obj = slotPeriod.filter((s, i) => i === index)[0]
      obj[name] = value

      this.setState(prevState => ({
        slotPeriod: prevState.slotPeriod.map((s, i) => {
          if (i === index) s = obj
          return s
        }),
      }))
    },

    time: (index, name) => value => {
      const { handleSlotState } = this.slot
      handleSlotState(index, value, name)
    },

    add: () => {
      const { slotPeriod } = this.state

      const value = slotPeriod[slotPeriod.length - 1]?.to

      return this.setState(state => {
        state.slotPeriod.push({
          from: value,
          to: moment().hour(16).minute(0).second(0)._d,
          slot_amount: { label: 1, value: 1 },
        })
        return state
      })
    },

    delete: index => {
      return this.setState(state => {
        state.slotPeriod.splice(index, 1)
        return state
      })
    },

    amount: index => value => {
      const { handleSlotState } = this.slot
      handleSlotState(index, value, 'slot_amount')
    },
  }

  getOccupiedSlots = (location, date, setDate = false) => {
    const metadata = this.state.limits.locations[location]
    let startDate = metadata?.min
    let endDate = metadata?.max
    const currentDate = new Date()
    const pastTimes = []
    const selectedDate = date
    let start = moment(date)
      .hour(Number(metadata?.start))
      .minute(0)
      .second(0)._d
    let end = moment(date).hour(Number(metadata?.end)).minute(0).second(0)._d

    if (!this.isAllowedDays(selectedDate)) {
      while (startDate?.getTime() <= endDate?.getTime()) {
        pastTimes.push(startDate)
        startDate = moment(startDate).add(metadata?.interval, 'm').toDate()
      }
    } else if (
      moment(selectedDate).format('MMMM Do YYYY') ==
      moment(currentDate).format('MMMM Do YYYY')
    ) {
      while (startDate?.getTime() < currentDate.getTime()) {
        pastTimes.push(startDate)
        startDate = moment(startDate).add(metadata?.interval, 'm').toDate()
      }
      if (pastTimes[pastTimes.length - 1]?.getTime() >= endDate?.getTime()) {
        this.setState({ finishedDay: pastTimes[pastTimes.length - 1] })
      }
    }

    const filledSlots = this.getFilledSlots(location, selectedDate)

    const timesExcluded = [...pastTimes, ...filledSlots]

    this.setState({
      timesExcluded,
      appointmentDate: setDate
        ? this.getVacantSlot(selectedDate, timesExcluded, start, end)
        : null,
    })
  }

  isAllowedDays = date => {
    const newLocation = this.state.location || 'sports stadium'

    const holidays = this.props.publicEvents.map(e => {
      return moment(e.event_date).format('MMM Do YY')
    })

    const filledSlots = this.getFilledSlots(newLocation, date)

    const day = moment(date).day()
    return (
      day !== 0 &&
      day !== 6 &&
      !holidays.includes(moment(date).format('MMM Do YY')) &&
      !moment(date).isSame(this.state.finishedDay, 'day') &&
      !this.isDayBooked(filledSlots)
    )
  }

  getAppointmentSlots = (location, date) => {
    return this.state.appointments
      .filter(
        a =>
          true &&
          a.active &&
          a.schedulable_type == this.state.service &&
          a.location == location &&
          moment(a.slot).format('MMMM Do YYYY') ==
            moment(date).format('MMMM Do YYYY')
      )
      .map(a => a.slot.toString())
  }

  getFilledSlots = (location, date) => {
    const metadata = this.state.limits.locations[location || 'sports stadium']
    const counts = this.getAppointmentSlots(location, date)?.reduce(
      (tally, slot) => {
        tally[slot] = (tally[slot] || 0) + 1
        return tally
      },
      {}
    )

    return Object.keys(counts)
      .filter(slot => counts[slot] >= metadata?.time_period[0]?.max_slots)
      .map(slot => new Date(slot))
  }

  isDayBooked = filledSlots => {
    const metadata =
      this.state.limits.locations[this.state.location || 'sports stadium']
    const times = filledSlots.map(slot => {
      return moment(slot).format('MMMM Do YYYY h:mm')
    })

    let start = moment(filledSlots[0])
      .hour(Number(metadata?.start))
      .minute(0)
      .second(0)._d
    let end = moment(filledSlots[0])
      .hour(Number(metadata?.end))
      .minute(0)
      .second(0)._d

    while (start.getTime() <= end.getTime()) {
      const startString = moment(start).format('MMMM Do YYYY h:mm')
      if (times.includes(startString)) {
        start = moment(start).add(metadata?.interval, 'm').toDate()
        continue
      }
      return false
    }

    return true
  }

  getVacantSlot = (selectedDate, timesExcluded, start, end) => {
    let startDate = start
    const times = timesExcluded.map(time => {
      return moment(time).format('MMMM Do YYYY, h:mm')
    })
    //Checks if there are blocked off times
    if (times.length) {
      //if time selected is not blocked off, return it
      if (
        !times.some(
          time => time === moment(selectedDate).format('MMMM Do YYYY, h:mm')
        ) &&
        moment(selectedDate)._d >= startDate
      )
        return selectedDate

      //otherwise... search for next available time slot
      while (startDate.getTime() <= end.getTime()) {
        if (times.includes(moment(startDate).format('MMMM Do YYYY, h:mm'))) {
          startDate = moment(startDate).add(20, 'm').toDate()
          continue
        }
        return startDate
      }
      return startDate
    } else if (moment(selectedDate)._d < startDate) {
      return startDate
    }

    return selectedDate
  }

  on = {
    select: name => value => this.setState({ [name]: value }),

    service: async v =>
      v?.[0]?.value !== undefined &&
      this.setState(
        {
          service: v[0].value,
        },
        async () => {
          const { state, props } = this
          const val = state.service

          console.log(val)

          if (!val) return

          const appointmentIDs = state.appointments
            .filter(app => app.schedulable_type == val)
            .map(app => app.schedulable_id)

          try {
            const route =
              serviceRecord[val?.replace(/([a-z])([A-Z])/g, '$1 $2')]
            const { data } = await $app.axios.get(route)

            const unusedIDs = [...data?.records, ...data?.records_by_proxy]
              .sort((a, b) => a.id - b.id)
              .filter(
                d =>
                  d.application_decision == 'pending' ||
                  d.application_decision == 'approved'
              )
              .filter(d => (d.payment_status = true))
              .filter(d => !appointmentIDs.includes(d.id))
              .map(data => data.id)

            const formId = unusedIDs.length
              ? props.location.state
                ? unusedIDs[unusedIDs.length - 1]
                : unusedIDs[0]
              : appointmentIDs[0]

            const recordId = this.state?.recordId
              ? this.state.recordId
              : this.state.rescheduling
              ? this.state.appointment?.schedulable_id
              : formId

            data &&
              !data.error &&
              this.setState({
                recordId,
              })
          } catch (error) {
            console.error(error)
          }

          try {
            const { data: limits } = await $app.axios.get(
              '/schedules/schedule_limits',
              {
                params: {
                  'scope[form_type]': val,
                },
              }
            )

            const locations = limits.reduce((obj, loc) => {
              const [start, end] = loc.time_period[0].time.split('-')

              const [min, max] = [start, end].map(
                h => moment().hour(h).minute(0).second(0)._d
              )

              if (
                loc.form_type === 'IndividualLicense' &&
                loc.location !== 'sports stadium'
              ) {
                return obj
              }

              obj[loc.location] = {
                min,
                max,
                interval: loc.slot_interval,
                start,
                end,
                ...loc,
              }

              return obj
            }, {})

            this.setState({ limits: { locations } })

            const newlocations = Object.keys(locations).map(l => ({
              label: l.capitalize(),
              value: l,
            }))

            const location = newlocations[0]
            this.setState({ locations: newlocations })

            this.on.location([location])
          } catch (error) {
            console.error(error)
          }
        }
      ),

    date: async date => {
      this.getOccupiedSlots(this.state.location, date, true)
    },

    location: v =>
      v?.[0]?.value !== undefined &&
      this.setState(
        {
          location: v[0].value,
        },
        () => this.getOccupiedSlots(this.state.location, new Date())
      ),

    // remove: (appointment) => () =>
    //   this.props.removeAppointment(this, appointment),

    // update: ({ appointment, status }) => {
    //   this.props.updateAppointment(appointment, status)
    //   this.on.close()
    // },

    submit: async () => {
      const { state, user, __redirect, isCitizen } = this
      const { appointmentDate, recordId, userInfo } = this.state

      const service = this.services.find(s => s.value == state.service)
      const location = state.locations.find(l => l.value == state.location)

      const route = isCitizen ? '/schedules' : '/schedules/officer_create'

      const getMessage = requirements => {
        return `
        Good day ${
          isCitizen
            ? user.first_name?.capitalize()
            : userInfo?.user_first_name?.capitalize()
        },
        <br />
        Thank you for using our platform to book an appointment for your ${
          service.label
        }.
        <br/><br />
         of your appointment:
        <ul>
          <li>Service: ${service.label}</li>
          <li>Date: ${moment(appointmentDate).format(
            'Do MMMM YYYY, h:mm a'
          )}</li>
          <li>Location: ${location.label}</li>
        </ul>
        <br/><br />
        ${
          requirements
            ? `For this appointment, you will need to bring the following documents: <ul><li> ${requirements} </li></ul>
            <br/>
            Thank you for using the MyGateway Portal.`
            : '<br/><br/> Thank you for using the MyGateway Portal.'
        }
      `
      }

      try {
        const { data: appt } = await $app.axios.post(route, {
          schedulable_type: service.value,
          schedulable_id: recordId,
          date: appointmentDate,
          location: location.value,
          user_id: isCitizen ? null : userInfo?.user_id,
        })

        const newEvent = appt && {
          id: appt.schedule.id,
          schedulable_type: service.value,
          schedulable_id: recordId,
          slot: appointmentDate,
          location: this.state.location,
          active: true,
          status: 'approved',
          user_id: isCitizen ? user.id : userInfo?.user_id,
          user_first_name: isCitizen
            ? user.first_name
            : userInfo?.user_first_name,
          user_last_name: isCitizen ? user.last_name : userInfo?.user_last_name,
          user_email: isCitizen ? user.email : userInfo?.user_email,
          reference_code: appt.schedule.reference_code,
        }

        appt && !appt.error
          ? this.setState(state => ({
              appointments: [...state.appointments, newEvent],
              createModal: false,
              loading: false,
              confirmModal: false,
              rescheduling: false,
              appointment: '',
              recordId: '',
            }))
          : toastr.error('Error', appt.message || appt.error)

        appt && toastr.success('Success', 'Appointment successfully created')

        if (!isCitizen) {
          try {
            const requirements = $app.getService(
              service.value
            )?.collection_requirements
            const message = getMessage(requirements || '')

            const { data } = await $app.axios.post('/emails', {
              email: isCitizen ? user.email : userInfo?.user_email,
              subject: `MyGateway Portal: ${service.label} Appointment`,
              message,
            })

            data.message && !data.error
              ? toastr.success(
                  'Success',
                  'You should receive a confirmation email shortly'
                )
              : toastr.error('Error', `${data?.message}(${data?.error})`)

            __redirect && this.props.history.replace(__redirect)
          } catch (error) {
            console.error(error)
            toastr.error('Error', 'Unable to send email')
          }
        }
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to create appointment')
        this.setState({
          createModal: false,
          confirmModal: false,
          recordId: '',
        })
      }
    },

    event: async ({ el, event, view }) => {
      const { isCitizen, user } = this

      if (
        !event.allDay &&
        ((isCitizen && user.id == event.extendedProps.userId) || !isCitizen)
      ) {
        const id = event.extendedProps.eventId

        const appointment = this.state.appointments.find(a => a.id == id)

        this.setState({
          modal: true,
          appointment,
        })
      }
    },

    remove: async () => {
      const {
        id,
        user_email,
        schedulable_type,
        user_first_name,
        user_id,
        user_last_name,
        reference_code,
      } = this.state.appointment

      const { user, isCitizen } = this

      const appointments = this.state.appointments.filter(app => {
        return app.id !== id
      })

      const message = `        
      Good Day ${
        isCitizen
          ? user?.first_name?.capitalize()
          : user_first_name?.capitalize()
      },
        <br/>
        This email is a confirmation that your appointment has been cancelled. You can reschedule the appointment if you haven't already chosen to do so.
       <br/>
       Thank you for using the MyGateway Portal.
      `

      try {
        const { data } = await $app.axios.patch(`/schedules/update_status`, {
          reference_code,
          active: true,
          status: 'rescheduled',
          comment: `Rescheduling appointment for ${
            isCitizen
              ? `${user.first_name.capitalize()} ${user.last_name.capitalize()}`
              : `${user_first_name.capitalize()} ${user_last_name.capitalize()}`
          }`,
        })

        if (data) {
          this.setState({
            modal: false,
            deleteModal: false,
            appointments,
            createModal: true,
            userInfo: {
              user_id,
              user_first_name,
              user_last_name,
              user_email,
            },
          })
          toastr.success(
            'Success',
            'Successfully removed appointment. You may now reschedule.'
          )
        }

        if (!this.isCitizen) {
          try {
            const { data } = await $app.axios.post('/emails', {
              email: user_email,
              subject: `MyGateway Portal: ${serviceAlias[schedulable_type]} Appointment Cancellation`,
              message,
            })

            data &&
              !data.error &&
              toastr.success(
                'Success',
                'Cancellation email will be sent to customer'
              )
          } catch (error) {
            console.error(error)
          }
        }
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Could not remove appointment')
        this.setState({ modal: false, deleteModal: false })
      }
    },

    confirm: async status => {
      const { appointment } = this.state
      const { reference_code, user_first_name, schedulable_type } = appointment

      const message = `
        Good Day ${user_first_name.capitalize()},
        <br/>

        ${
          status == 'arrived'
            ? 'Your appointment has been confirmed by an Agency Representative.'
            : 'Your appointment has been marked as "No Show" by an Agency representative.'
        }
       <br/>
       Thank you for using the MyGateway Portal.
      `

      const confirmStatus = async status => {
        const { data } = await $app.axios.patch('/schedules/update_status', {
          reference_code,
          status,
        })

        return data
      }

      const sendEmail = async () => {
        const { data } = await $app.axios.post('/emails', {
          email: appointment.user_email,
          subject: `MyGateway Portal: ${serviceAlias[schedulable_type]} Appointment Status Update`,
          message,
        })

        return data
      }

      try {
        const data = await confirmStatus(status)

        if (data && !data.error) {
          toastr.success(
            'Success',
            `Appointment has been marked as ${status.capitalize()}`
          )

          try {
            const email = await sendEmail()

            email &&
              !email.error &&
              toastr.success(
                'Success',
                'Confirmation email has been sent to customer'
              )
          } catch (error) {
            console.error(error)
            toastr.error('Error', 'Unable to send email to customer')
          }

          const appointments = this.state.appointments.map(app => {
            if (app.reference_code == reference_code) {
              app.status = status
            }
            return app
          })
          this.setState({ appointments, modal: false })
        }
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to confirm appointment')
        this.setState({ modal: false })
      }
    },

    update: async ev => {
      ev && ev.preventDefault()
      const { appointment, status, reason } = this.state
      const { reference_code, user_first_name } = appointment

      const message = `
        Good day ${user_first_name.capitalize()},
        <br />
        ${
          status == 'approved'
            ? 'You are receieving this email as a confirmation that your appointment has been ' +
              status +
              '. Log into the portal to see the details of your appointment.'
            : 'We regret to inform you that your appointment has been ' +
              status +
              '. ' +
              'Reason cited for the denial of the appointment: <ul><li>' +
              reason +
              '</li></ul>' +
              'You can try to book another appointment or reach out to a representative as to why your request has been denied.'
        }
        <br/><br />
        Thank you for using the MyGateway Portal.
      `

      try {
        const { data } = await $app.axios.patch('/schedules/update_status', {
          reference_code,
          status,
        })

        data.message && !data.error
          ? toastr.success(
              'Success',
              'Appointment status has been updated successfully'
            )
          : toastr.error('Error', `${data?.message}(${data?.error})`)
        const appointments = this.state.appointments.map(app => {
          if (app.reference_code == reference_code) {
            app.status = status
          }
          return app
        })

        try {
          const service = appointment.schedulable_type
            .split(/(?=[A-Z])/)
            .join(' ')

          const { data } = await $app.axios.post('/emails', {
            email: appointment.user_email,
            subject: `MyGateway Portal: ${service} Appointment Status Update`,
            message,
          })

          data && !data.error
            ? toastr.success(
                'Success',
                'Email will be sent to the customer to confirm'
              )
            : toastr.error('Error', `${data?.message}(${data?.error})`)
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to send confirmation email')
        }
        if (status == 'denied') {
          try {
            const { data } = await $app.axios.patch(
              `/schedules/${appointment.id}/soft_remove`
            )

            !data ||
              (data.error &&
                toastr.error('Error', 'Did not remove appointment'))
          } catch (error) {
            console.error(error)
          }
        }
        this.setState({ appointments, modal: false })
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to update appointment status')
        this.setState({ modal: false })
      }
    },

    close: () => this.setState({ modal: false, status: null }),

    input: name => ev => this.setState({ [name]: ev.target.value }),
  }

  getCurrentAppointments = () =>
    this.state.appointments
      .filter(a =>
        a.status != 'denied' && a.status != 'no show' ? a.active : a
      )
      .filter(a => {
        let filter = true
        if (this.state.selectService[0].value) {
          filter = a.schedulable_type == this.state.selectService[0].value
        }
        return filter
      })
      .filter(a => new Date() <= moment(a.slot).add(15, 'm').toDate())
      .map(a => ({
        title:
          serviceAlias[a.schedulable_type] ||
          (a.schedulable_type || '').replace(/([A-Z])/g, ' $1'),
        start: a.slot,
        end: moment(a.slot).add(20, 'm').toDate(),
        email: a.user_email,
        eventId: a.id,
        first: a.user_first_name && a.user_first_name.capitalize(),
        last: a.user_last_name && a.user_last_name.capitalize(),
        status: a.status.toLowerCase(),
        isUrgent:
          a.status.toLowerCase() != 'denied' &&
          new Date() >= moment(a.slot).subtract(40, 'm').toDate() &&
          new Date() < moment(a.slot).subtract(30, 'm').toDate(),
        isStatusPending:
          a.status.toLowerCase() == 'submitted' &&
          new Date() >= moment(a.slot).subtract(10, 'm').toDate() &&
          new Date() < new Date(a.slot),
        isLate:
          a.status.toLowerCase() == 'approved' &&
          new Date() <= moment(a.slot).add(15, 'm').toDate() &&
          new Date() >= moment(a.slot).add(5, 'm').toDate(),
        isReady:
          a.status.toLowerCase() == 'approved' &&
          new Date() < moment(a.slot).add(5, 'm').toDate() &&
          new Date() >= moment(a.slot).subtract(30, 'm').toDate(),
      }))

  getUserAppointments = (status, id) =>
    this.state.appointments
      .filter(a => a.status && a.status.toLowerCase() == status)
      .filter(a => a.active)
      .filter(a => a.user_id === id)
      .filter(a => {
        let filter = true
        if (this.state.selectService[0].value) {
          filter = a.schedulable_type == this.state.selectService[0].value
        }
        return filter
      })
      .map(a => ({
        title:
          serviceAlias[a.schedulable_type] ||
          (a.schedulable_type || '').replace(/([A-Z])/g, ' $1'),
        start: a.slot,
        end: moment(a.slot).add(20, 'm').toDate(),
        email: a.user_email,
        userId: a.user_id,
        eventId: a.id,
        first: a.user_first_name && a.user_first_name.capitalize(),
        last: a.user_last_name && a.user_last_name.capitalize(),
        status: a.status.toLowerCase(),
      }))

  getPastAppointments = () =>
    this.state.appointments
      .filter(a =>
        a.status.toLowerCase() != 'no show' &&
        a.status.toLowerCase() != 'denied'
          ? a.active
          : a
      )
      .filter(a => new Date() > moment(a.slot).add(15, 'm').toDate())
      .filter(a => {
        let filter = true
        if (this.state.selectService[0].value) {
          filter = a.schedulable_type == this.state.selectService[0].value
        }
        return filter
      })
      .map(a => ({
        title:
          serviceAlias[a.schedulable_type] ||
          (a.schedulable_type || '').replace(/([A-Z])/g, ' $1'),
        start: a.slot,
        end: moment(a.slot).add(20, 'm').toDate(),
        email: a.user_email,
        eventId: a.id,
        first: a.user_first_name && a.user_first_name.capitalize(),
        last: a.user_last_name && a.user_last_name.capitalize(),
        status: a.status.toLowerCase(),
        isPast: true,
      }))

  getPublicEvents = () =>
    this.props.publicEvents.map(ev => ({
      title: ev.event_name,
      start: ev.event_date,
      allDay: true,
      display: 'background',
    }))

  Appointment = () => {
    const { state, on, getDateTime, isCitizen, user, services } = this
    const data = state.appointment || {}

    const service = services.find(s => s.value === data.schedulable_type)?.value

    const isReady =
      new Date() >= moment(data.slot).subtract(30, 'm').toDate() &&
      new Date() < moment(data.slot).add(15, 'm').toDate() &&
      data.status == 'approved'

    const rows = Object.keys(data)
      .map(key => {
        switch (key) {
          case 'user_id':
            const name = isCitizen
              ? `${user.first_name.capitalize()} ${user.last_name.capitalize()}`
              : `${data.user_first_name.capitalize()} ${data.user_last_name.capitalize()}`
            return ['Name', name]
          case 'id':
          case 'user_first_name':
          case 'user_middle_name':
          case 'user_last_name':
          case 'reference_code':
          case 'schedulable_id':
          case 'updated_at':
          case 'active':
            return null
          case 'status':
            const stat = data[key]
            return ['Status', stat === null ? 'Pending' : stat.capitalize()]
          case 'slot':
            return [
              'Date/Time',
              moment(data.slot).format('Do MMM YYYY @ h:mm A'),
            ]
          case 'created_at':
            return isCitizen
              ? null
              : [key.replace(/_/g, ' ').capitalize(), getDateTime(data[key])]
          // case "active":
          //   return ["Active", data.active ? "Yes" : "No"];
          case 'location':
            return ['Location', data[key]?.capitalize()]
          case 'schedulable_type':
            return [
              'Service',
              serviceAlias[data[key]] || data[key].split(/(?=[A-Z])/).join(' '),
            ]
          default:
            return [key.replace(/_/g, ' ').capitalize(), data[key]]
        }
      })
      .filter(r => r)
      .map(([key, val], i) => (
        <tr key={i}>
          <td>{key}</td>
          {key == 'Service' ? (
            <td>
              <b>{val}</b>
            </td>
          ) : (
            <td>{val}</td>
          )}
        </tr>
      ))

    const confirmAppointment = isCitizen
      ? null
      : isReady && (
          <React.Fragment>
            <button
              className='btn btn-round btn-success mr-2'
              aria-label='Close'
              onClick={() => {
                on.confirm('arrived')
              }}
            >
              Confirm
            </button>
            <button
              className='btn btn-round btn-danger mr-2'
              aria-label='Close'
              onClick={() => {
                on.confirm('no show')
              }}
            >
              No Show
            </button>
          </React.Fragment>
        )

    const statusChange = isCitizen
      ? null
      : new Date(data.slot) >= new Date() &&
        data.status == 'submitted' && (
          <div className='mt-4'>
            <ValidatorForm onSubmit={on.update} style={{ width: '100%' }}>
              <p>Change Status</p>
              <SelectField
                onChange={v =>
                  this.setState({ status: v.length && v[0].value })
                }
                value={state.status}
                options={[
                  { label: 'Approve', value: 'approved' },
                  { label: 'Deny', value: 'denied' },
                ]}
                validators={['required']}
                errorMessages={['Required']}
                required
                className='form-control col-md-4'
              />
              <div
                className='mt-4'
                style={{
                  display: state.status === 'denied' ? 'block' : 'hidden',
                }}
              >
                <p>Comments</p>
                <textarea
                  value={state.reason}
                  onChange={on.input('reason')}
                ></textarea>
              </div>
              <div className='text-right col-md-4 ml-auto mt-4'>
                <input
                  className='btn custom-btn'
                  type='submit'
                  value='Submit'
                />
              </div>
            </ValidatorForm>
          </div>
        )

    const reschedule = hasAny(['approved', 'no show'], [data.status]) ? (
      <button
        className='btn btn-round btn-primary mr-2'
        aria-label='Close'
        onClick={() =>
          this.setState({
            deleteModal: true,
            rescheduling: true,
            service,
          })
        }
      >
        Reschedule Appointment
      </button>
    ) : null

    return (
      <Modal
        open={state.modal}
        onClose={on.close}
        styles={{ modal: { width: isCitizen ? '700px' : '950px' } }}
        center
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='AppointmentModalTitle'>
              Appointment Details
            </h5>
          </div>
          <div className='modal-body'>
            <table className='table'>
              <tbody>{rows}</tbody>
            </table>
            {statusChange}
          </div>
          <div className='modal-footer'>
            {confirmAppointment}
            {reschedule}
          </div>
        </div>
      </Modal>
    )
  }

  AppointmentModal = () => {
    const {
      state,
      on,
      closeModal,
      props,
      services,
      preconfigured,
      isCitizen,
      isAllowedDays,
    } = this
    const { publicEvents } = props

    // const services = this.props.services
    //   .filter((service) => service.agency === 'road traffic department')
    //   .map((service) => {
    //     return {
    //       label: this.initialCaps(service.name),
    //       value: this.initialCaps(service.name).replace(/ /g, ''),
    //     }
    //   })

    const modalProps = {
      ...extractKeys(
        state,
        'createModal',
        'rescheduling',
        'location',
        'service',
        'appointmentDate',
        'timesExcluded',
        'locations',
        'userInfo',
        'appointments',
        'limits',
        'finishedDay'
      ),
      closeModal,
      isAllowedDays,
      on,
      services,
      publicEvents,
      preconfigured,
      isCitizen,
      submit: e => {
        e && e.preventDefault()
        this.setState({ confirmModal: true })
      },
    }

    return <ScheduleModal {...modalProps} />
  }

  AppointmentCalendar = () => {
    const {
      handleSelect,
      isCitizen,
      on,
      getUserAppointments,
      getPublicEvents,
      getPastAppointments,
      getCurrentAppointments,
      user,
      props,
      state,
    } = this
    const { id } = user
    const { publicEvents } = props
    const { initialView, initialDate } = state

    const statusBadge = {
      'submitted': 'primary',
      'denied': 'danger',
      'approved': 'success',
      'arrived': 'success',
      'no show': 'danger',
    }

    const renderEventContent = eventInfo => {
      const { event } = eventInfo
      const { isCitizen } = this
      const {
        first,
        last,
        isUrgent,
        status,
        isStatusPending,
        isReady,
        isLate,
      } = event.extendedProps

      const fullName = `${first} ${last}`

      return (
        <>
          {!isCitizen && !event.allDay && (
            <h5 style={{ fontSize: '10px' }}>{fullName}</h5>
          )}
          <h5 style={{ fontSize: '10px' }}>
            {!event.allDay && `${moment(event.start).format('h:mm')} -`}{' '}
            {event.title}
          </h5>
          {!isCitizen && status && (
            <span className={`badge badge-${statusBadge[status]}`}>
              {status.capitalize()}
            </span>
          )}
          {isUrgent && (
            <div>
              <span className='badge badge-danger'>Upcoming Appointment</span>
            </div>
          )}
          {isReady && (
            <div>
              <span className='badge badge-success'>Ready to Confirm</span>
            </div>
          )}
          {isStatusPending && (
            <div>
              <span className='badge badge-danger'>Needs Approval</span>
            </div>
          )}
          {isLate && (
            <div>
              <span className='badge badge-danger'>Running Late</span>
            </div>
          )}
        </>
      )
    }

    const sources = isCitizen
      ? [
          {
            events: getUserAppointments('submitted', id),
            textColor: '#FFFFFF',
            color: '#2169DA',
            display: 'block',
          },
          {
            events: getUserAppointments('approved', id),
            textColor: '#FFFFFF',
            color: '#218839',
            display: 'block',
          },
          {
            events: getUserAppointments('arrived', id),
            textColor: '#FFFFFF',
            color: '#218839',
            display: 'block',
          },
          {
            events: getUserAppointments('no show', id),
            textColor: '#FFFFFF',
            color: '#FF0000',
            display: 'block',
          },
        ]
      : [
          {
            events: getCurrentAppointments(),
            textColor: '#000000',
            backgroundColor: '#FFF',
            display: 'block',
          },
          {
            events: getPastAppointments(),
            textColor: '#FFF',
            backgroundColor: '#696969',
            display: 'block',
          },
        ]

    sources.push({
      events: getPublicEvents(),
      textColor: '#FFF',
      backgroundColor: '#FA8072',
    })

    const cProps = isCitizen
      ? {
          handleSelect,
          initialView: 'timeGridWeek',
          sources,
          tabs: 'timeGridWeek',
          dayHeaderFormat: {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          },
          on,
          eventContent: renderEventContent,
          publicEvents,
        }
      : {
          handleSelect,
          initialView,
          sources,
          tabs: 'dayGridMonth,dayGridWeek,dayGridDay,list',
          dayHeaderFormat: {
            weekday: 'long',
            // month: 'numeric',
            // day: 'numeric',
          },
          on,
          eventContent: renderEventContent,
          initialDate,
        }

    return <ScheduleCalendar {...cProps} />
  }

  SlotModalForm = () => {
    const { on, handleSlotSubmit, state, services, slot } = this
    const {
      locations,
      slotModal,
      service,
      location,
      firstSlot,
      secondSlot,
      interval,
      start,
      stop,
      resume,
      end,
      slotDisabled,
      slotPeriod,
    } = state

    const formProps = {
      services,
      locations,
      on,
      slotModal,
      handleSlotSubmit,
      service,
      location,
      firstSlot,
      secondSlot,
      interval,
      start,
      stop,
      resume,
      end,
      slotDisabled,
      slotPeriod,
      slot,
      onClose: () => this.setState({ slotModal: false }),
    }

    return <SlotModal {...formProps} />
  }

  getActions = () => {
    const actions = []
    const action = (text, icon, fn) => ({
      icon: /^fa(r|s)\s/.test(icon) ? icon : 'fas fa-' + icon,
      text,
      fn,
    })

    actions.push(
      action('Update Time Slots', 'plus', () =>
        this.setState({ slotModal: true })
      )
    )

    return actions
  }

  render() {
    const {
      state,
      on,
      Appointment,
      AppointmentModal,
      AppointmentCalendar,
      SlotModalForm,
      isCitizen,
      handleSelect,
      appointmentIntro,
      services,
      getActions,
    } = this
    const { loading, intro } = state

    if (loading) return <Loader loading />

    const defaultValue = { label: 'All Services', value: null }

    const allServices = services.map(x => x)
    allServices.unshift(defaultValue)

    const actions = getActions()
    const classes =
      'flex justify-between items-center text-sm text-left mb-2 p-2 rounded-lg cursor-pointer text-gray-700 hover:bg-primary hover:text-white'

    const rightActions = isCitizen ? (
      <div className='btn custom-btn btn-round ml-auto' onClick={handleSelect}>
        <i className='fa fa-calendar-plus mr-2'></i>
        Request Appointment
      </div>
    ) : (
      <>
        <div className='ml-auto'>
          <Select
            name='service'
            options={allServices}
            values={[defaultValue]}
            onChange={on.select('selectService')}
            className='form-control'
            placeholder='Service'
          />
        </div>
        <div className='nav-item dropdown hidden-caret submenu'>
          <a
            className='flex items-center btn custom-btn btn-round nav-link dropdown-toggle'
            href='#'
            id='help-numbers'
            role='button'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
            style={{
              float: 'left',
              overflow: 'hidden',
              clear: 'both',
              marginLeft: '3%',
            }}
          >
            <i className='fas fa-chevron-down'></i>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>Actions</span>
          </a>
          <ul className='dropdown-menu w-48 p-2' aria-labelledby='help-numbers'>
            {actions.map((act, i) => {
              return (
                <li key={i} onClick={act.fn} className={classes}>
                  <span>{act.text}</span>
                  <i className={'fas fa-' + (act.icon || 'circle')}></i>
                </li>
              )
            })}
          </ul>
        </div>
        {/* <div
          className="btn custom-btn btn-round"
          onClick={() => {
            this.props.history.push({
              pathname: "/appointments/dashboard",
              state: appointments,
            });
          }}
          style={{
            float: "left",
            overflow: "hidden",
            clear: "both",
            marginLeft: "3%",
          }}
        >
          <i className="fa fa-chart-line mr-2"></i>
          Admin Panel
        </div> */}
      </>
    )

    const userApps = this.state.appointments
      .filter(a => a.active)
      .filter(a => a.user_id && a.user_id == this.user.id)

    return (
      <div className='content'>
        <div className='page-inner'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex align-items-center'>
                    <h4 className='card-title'>Appointments</h4>
                    {rightActions}
                  </div>
                </div>
                <div id='appointment-calendar' className='card-body'>
                  {isCitizen &&
                    !userApps.length &&
                    intro &&
                    confirmAlert({
                      customUI: ({ onClose }) => (
                        <div className='custom-ui'>
                          <h3 style={{ marginBottom: '1rem' }}>
                            Welcome to Appointments/Scheduling
                          </h3>
                          <div
                            style={{ marginTop: '1rem', fontSize: '1rem' }}
                            className='smaller-tr'
                            dangerouslySetInnerHTML={{
                              __html: appointmentIntro,
                            }}
                          />
                          <button
                            className='btn btn-primary'
                            onClick={e => {
                              this.setState({ intro: false })
                              onClose()
                            }}
                          >
                            OK
                          </button>
                        </div>
                      ),
                    })}
                  <AppointmentCalendar />
                  <AppointmentModal />
                  <ConfirmationModal
                    open={state.confirmModal}
                    close={() => {
                      this.setState({ confirmModal: false })
                    }}
                    confirm={on.submit}
                    description='Are you sure that you want to book this appointment?'
                  />
                  <ConfirmationModal
                    open={state.deleteModal}
                    close={() => {
                      this.setState({ deleteModal: false })
                    }}
                    confirm={on.remove}
                    description='By rescheduling, you will be cancelling the specified appointment. Are you sure you want to move forward with this action?'
                  />
                  <SlotModalForm />
                  <Appointment />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(
  state => ({
    ...extractKeys(
      state.appointments,
      'success_message',
      'appointments',
      'loading',
      'blackouts',
      'success'
    ),
    ...extractKeys(
      state.agencies,
      'events',
      'publicEvents',
      'locations',
      'services'
    ),
  }),
  {
    getAppointments,
    removeAppointment,
    updateAppointment,
    getAgencyEvents,
    getCalendarEvents,
    getAgencyLocations,
    getAgencyServices,
    getBlackoutDates,
  }
)(ScheduleAppointment)
