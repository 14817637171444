const getAgencyNames = (serviceID = '', style = 'full') => {
  switch (serviceID) {
    case 'Adoption':
    case 'BirthCertificate':
    case 'DeathCertificate':
    case 'MarriageCertificate':
    case 'DeedsAndDocument':
    case 'DeedSearch':
      return names(
        "Registrar General's Department",
        'Registrar General',
        'RGD'
      )(style)

    case 'GunLicense':
    case 'GunLicenseRenewal':
    case 'PoliceCertificate':
    case 'TrafficRecord':
    case 'TrafficRecordRenewal':
    case 'FingerPrinting':
    case 'ChassisCheck':
      return names('Royal Bahamas Police Force', 'Police Force', 'RBPF')(style)

    case 'NewDriverLicense':
    case 'DriversLicense':
    case 'DriverLicenseReplacement':
    case 'DriversLearnersPermitRenewal':
    case 'DrivingPermit':
      return names('Road Traffic Department', 'Road Traffic', 'RTD')(style)

    case 'MoneyOrder':
    case 'RentBox':
    case 'RentBoxRenewal':
      return names('Post Office', 'Post Office', 'PO')

    case 'Passport':
      return names('Passport Office', 'Passport Office', 'PPO')

    case 'Serviced Lot Sale':
      return names('Department Of Housing', 'Dept. of Housing', 'DOH')(style)

    default:
      return ''
  }
}

function names(full, medium, short) {
  const o = { full, medium, short }
  return style => o[style] || full
}

export default getAgencyNames
