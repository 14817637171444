import React from 'react'
import islands from '@constants/islands'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class RenewableGeneration extends React.Component {
  resource = '/renewable_generations'
  form_key = 'renewable_generation'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    '::Customer Information',
    {
        name: 'po_box',
        label: 'P.O. Box'
    },
    {
        name:'street_address',
    },
    {
        name: 'island',
        options: islands
    },
    {
        name: 'account_number',
        label: 'BPL Account Number'
    },
    {
        name: 'meter_number',
        label: 'BPL Meter Number'
    },
    {
        name: 'Telephone_contacts',
        columns:[
            {
                name: 'Work',
                type: 'phone'
            }, 
            {
                name: 'Mobile',
                type: 'phone'
            },
            { 
                name: 'Home',
                type: 'phone'
            },
            
        ],
        
    },
    {
        name: 'account_type',
        options: ['Residential', 'Other'],
        hint: 'Note ($50 Residential Application Fee due in advance of submission)'
    },
    {
        name: 'not_residential',
        label: 'Please specify',
        hide: form.account_type != 'Other'
    },
    "::SPACER",
    '::System Installer Information-RE Contractor',
    {
        name: 'contact_person_c',
        label: 'Contact Person'
    },
    {
        name: 'company_name_c',
        label: 'Company Name'
    },
    {
        name: 'second_po_box_c',
        label: 'P.O. Box'
    },
    {
        name: 'Telephone_contacts_c',
        label: 'Telephone Contacts',
        columns:[
            {
                name: 'Work',
                type: 'phone'
            }, 
            {
                name: 'Mobile',
                type: 'phone'
            },
            { 
                name: 'Home',
                type: 'phone'
            },
            
        ],
    },
    {
        name: 'email-address_c',
        label: 'Email',
        type: 'email'
    },
    {
        name: 'license_number_c',
        label: 'License Number'
    },
    "::SPACER",
    '::System Installer Information-Electrical Contractor',
    {
        name: 'contact_person_e',
        label: 'Contact Person'         
    },
    {
        name: 'company_name_e',
        label: 'Company Name'
    },
    {
        name: 'second_po_box_e',
        label: 'P.O. Box'
    },
    {
        name: 'Telephone_contacts_e',
        label: 'Telephone Contacts',
        columns:[
            {
                name: 'Work',
                type: 'phone'
            }, 
            {
                name: 'Mobile',
                type: 'phone'
            },
            { 
                name: 'Home',
                type: 'phone'
            },
            
        ],
    },
    {
        name: 'email_address_e',
        label: 'Email',
        type: 'email'
    },
    {
        name: 'license_number_e',
        label: 'License Number'
    },
    "::SPACER",
    '::Facility Information',
    {
        name: 'plate_rating',
        label: 'Name Plate Rating of each inverter in the SSRG System:',
        hint: 'kW AC'
    },
    {
        name: 'total_generation',
        label: 'Total Installed Generation:',
        hint: 'kW AC'
    },
    {
        name: 'type',
        options:['Wind Turbine', 'Photovolataic(Solar)', 'Wind/Solar Hybrid']
    },
    {
        name: 'installation_type',
        options: ['Grid Tied', 'Other']
    },
    {
        name: 'other_option',
        label: 'Please specify',
        type: 'textarea',
        hide: form.installation_type != 'Other'
    },
    {
        name: 'inverter_utilized',
        options: ['Yes','No']
    },
    {
        name: 'make_and_model',
        label: 'Make and Model of Inverter',
        type: 'textarea',
        hide: form.inverter_utilized != 'Yes'
    },
    {
        name: 'battery_storage',
        label: 'Battery storage installed',
        options: ['Yes', 'No']
    },
    {
        name: 'capacity',
        label: 'Capacity(Ah)',
        hide: form.battery_storage != 'Yes'
    },
    {
        name: 'output_capable',
        label: 'Off-Grid output capable (Backup Power Capable)',
        options: ['Yes', 'No']
    },
    {
        name: 'existing_equipment',
        label: 'Is there any existing electric generating equipment at this location?',
        options: ['Yes', 'No']
    },
    {
        name: 'if_yes',
        label: 'Please provide details:',
        type: 'textarea',
        hide: form.existing_equipment != "Yes"
    },
    '::Uploads',

    {
        name: 'interconnection_agreement',
        label: 'Signed Interconnection Agreement',
        type: 'file:all',
        key: 'image.0'
    },
    {
        name: 'schematic_diagram',
        label: 'Schematic diagram of proposed installation',
        type: 'file:all',
        key: 'image.1'
    },
    {
        name: 'technical_specifications',
        label: 'Technical specifications, operation and installation manuals of the proposed equipment',
        type: 'file:all',
        key:'image.2'
    }


  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
