import React from 'react'
import YAML from 'yaml'

import Swal from '@sweetalert'
import { listKeys } from '@helpers/objects'
import SERVICES_YAML from '@/services.yml'
import { getCertMap } from './maps'

const verifyDigitalCert = async (
  service = '',
  form_num = '',
  { onFetch, onClose, onError }
) => {
  const url = `/cert_check/${service}/${form_num}`

  try {
    const { data } = await $app.axios.get(url)
    onFetch?.(data)
    console.log(data)

    if (data.valid) {
      await Swal.fire({
        icon: 'success',
        title: 'Application Approved',
        html: values(service, data.service),
        customClass: 'swal--wide',
      })

      onClose?.(true)
    } else {
      await Swal.fire(
        'Certificate Invalid',
        'Your certificate has likely already expired',
        'warning'
      )

      onClose?.(false)
    }

    return true
  } catch (err) {
    console.error(err)

    const msg = err.response?.data?.message
    onError?.(msg)

    if (msg) {
      const title = /not approved/.test(msg)
        ? 'Application Not Approved'
        : 'Application Not Found'

      await Swal.fire({
        icon: 'warning',
        title,
      })
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Something Went Wrong',
      })
    }

    onClose?.(false)
    return false
  }
}

const findServiceTitle = service_type => {
  for (let agency of YAML.parse(SERVICES_YAML)) {
    const target = agency.services.find(s => s._fid == service_type)
    if (target) return target.name
  }
}

export const values = (service_type, form) => {
  try {
    const title = findServiceTitle(service_type)
    const owner = listKeys(form.user, 'first_name', 'middle_name', 'last_name')
      .join(' ')
      .capitalize()

    const business = form.bl_number
      ? [
          ['SPACE'],
          ['Company Details'],
          ['Company Name', form.company_name],
          ['Business Licence #', form.bl_number],
          ['SPACE'],
        ]
      : [['SPACE']]

    const spans = [
      ['Service', title],
      ['Applicant', owner],
      ...business,
      ...getCertMap(service_type, form),
    ].map(([heading, value]) =>
      heading == 'SPACE' ? (
        <span className='w-full'>&nbsp;</span>
      ) : (
        <React.Fragment>
          <strong className='text-left w-1/3'>{heading}</strong>
          <span className='text-right w-2/3'>{value}</span>
        </React.Fragment>
      )
    )

    return <div className='flex flex-row flex-wrap'>{spans}</div>
  } catch (err) {
    return null
  }
}

export default verifyDigitalCert
