import React from "react";

import { ValidatorForm } from "react-form-validator-core";
import Modal from "react-responsive-modal";
import InputField from "@shared/form-fields/input";
import Loader from "@shared/loader";
import toastr from "@modules/toastr";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";

export default class FisheryResource extends React.Component {
  state = {
    permit_numb: "",
    record: null,
    loading: false,
  };
  id = "FisheryResourcePermit";
  service = "fishery resource permit";
  title = "Application for Permit to sell Fishery Resource";
  agency = "moa";
  resource = "/fishery_resource_permits";
  form_key = "fishery_resource_permit";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = {
    status: true,
    deny: true,
  };
  custom_action = {
    text: "Set Details",
    icon: "mouse-pointer",
    test: (r) => r.payment_status,
    fn: (record) => this.setState({ record }),
  };
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "type_of_resource",
      label: "Type of Fishery Resources to be sold", //Need options
      type: "textarea",
    },
    {
      name: "how_resource_is_sold",
      label: "How Fishery resource is to be sold", // Need options
      type: "textarea",
    },
    {
      name: "where_resource_is_sold",
      label: "Where is the resource to be sold?",
      options: islands,
    },
    {
      name: "helpers",
      label: "Helper (if any)",
      required: false,
      columns: [
        { name: "first_name", width: 64 },
        { name: "last_name", width: 64 },
      ],
    },
    {
      name: "permit_numb",
      label: "Permit Number",
      hide: true,
      view: true,
    },
    {
      name: "bl_number",
      label: "Business License #",
      is: "integer",
    },
    {
      name: "business_license_upload",
      label: "Business Licence",
      type: "file:pdf",
      key: (f) => f.image[1],
    },
    {
      name: "health_cert_upload",
      label: "Health Certificate",
      type: "file:pdf",
      key: (f) => f.image[0],
    },
    {
      name: "food_safety_cert_upload",
      label: "Food Handler's course",
      type: "file:pdf",
      key: (f) => f.image[2],
    },
  ];

  closeModal = () => this.setState({ record: null });

  on = {
    input: (name) => (ev) =>
      this.setState({ [name]: ev.target.value.replace(/[^0-9a-z ]/gi, "") }),

    submit: async () => {
      this.setState({ loading: true });

      const { state, props, resource, form_key } = this;
      const { history, location } = props;
      const { record, permit_numb } = state;
      const { axios } = $app;

      try {
        await axios.put(resource + "/update_application", {
          [form_key]: {
            form_num: record.form_num,
            application_decision: "approved",
            permit_numb,
          },
        });

        if (location.state?.view) {
          const view = { ...location.state.view };
          view.application_decision = "approved";
          view.fields[4].value = permit_numb;

          history.replace(location.pathname, { ...location.state, view });
        }

        toastr.success("Success", "Application has been approved");
      } catch (error) {
        console.error(error);
        toastr.error("Error", "Unable to approve application");
        return;
      }

      try {
        await axios.post("/emails", {
          email: record.user.email,
          subject: "MyGateway Portal Update - Permit to sell Fishery Resource",
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
            Your Permit to sell Fishery Resource has been approved. Here are your details: 
            <ul>
            <li><b>Permit Number:</b> ${permit_numb}</li>
            </ul>
            <br/><br />
            Thank you for using the MyGateway Portal.
          `,
        });

        toastr.success("Success", "Email successfully sent");
      } catch (err) {
        console.error(err);
        toastr.error("Error", "Unable to send email");
      }

      this.setState({ loading: false, record: null });
    },
  };

  render() {
    const { state, on, closeModal } = this;
    const { permit_numb } = state;

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 720 }}
          onClick={closeModal}
          center
        >
          <div className="modal-header">
            <h5 className="modal-title">Set Details</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className="modal-body">
              <div className="form-group form-show-validation">
                <label htmlFor="permit_numb">
                  Permit Number
                  <span className="required-label">*</span>
                </label>
                <InputField
                  name="permit_numb"
                  value={permit_numb}
                  onChange={on.input("permit_numb")}
                  className="form-control"
                  validators={["required"]}
                  errorMessages={["Required"]}
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-round mr-2"
                aria-label="Close"
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className="btn custom-btn btn-round"
                type="submit"
                value="Submit"
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    );
  }
}
