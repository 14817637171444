import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import daysOfWeek from '@constants/weekdys'

export default class NationalYouthBand extends React.Component {
  resource = '/national_youth_bands'
  form_key = 'national_youth_band'
  defaults = {}

  // Delete lines as needed
  fee = 99
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'band_name',
      label: 'Name of band'
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'band_director',
      type: 'select:bool',
      label: 'Are You The Band Director?'
    },
    {
      heading: `Director's Details`,
      hide: form.band_director,
    },
    {
      name: 'contact_first_name',
      hide: form.band_director,
      label: 'First Name',
    },
    {
      name: 'contact_last_name',
      hide: form.band_director,
      label: 'Last Name',
    },
    {
      name: 'contact_email',
      type: 'email',
      is: 'email',
      hide: form.band_director,
      label: 'Email',
    },
    {
      name: 'contact_phone_numb',
      type: 'phone',
      hide: form.band_director,
      label: 'Phone',
    },
    '::SPACER',
    {
      name: 'band_type',
      options: [
        { value: 'school', label: 'School' },
        { value: 'church', label: 'Church' },
        { value: 'community', label: 'Community' },
        { value: 'marching', label: 'Marching' },
        { value: 'orchestra/concert', label: 'Orchestra/Concert' },
        { value: 'pop', label: 'Pop' },
      ]
    },
    {
      name: 'band_start_date',
      type: 'date',
      label: 'Date Started',
      showYearDropdown: true,
    },
    {
      name: 'band_meeting_days',
      label: 'Meeting Days',
      multi: true,
      options: daysOfWeek,
      format: val => val.join(', ')
    },
    {
      name: 'band_meeting_time',
      type: 'time',
      label: 'Meeting Time',
    },
    {
      name: 'band_practice_location',
      label: 'Practice Location',
    },
    {
      name: 'number_of_members',
      is: 'integer',
      label: 'Number of Memebers',
    },
    {
      name: 'shareholders',
      label: 'Band Officers',
      columns: [
        'first_name',
        'last_name',
        'position'
      ]
    },
    {
      name: 'uniformed',
      type: 'select:bool',
      label: 'Is there a uniform?',
    },
    {
      name: 'member_age_range',
      label: 'Age Range of Members',
      options: [
        { value: 'under 12', label: 'Under 12' },
        '13 - 19',
        '20 - 25',
        { value: 'over 25', label: 'Over 25' },
      ],
    },
    {
      name: 'members_sex',
      label: 'Gender Ratio',
      options: [
        { value: 'male', label: 'All Male' },
        { value: 'female', label: 'All Female' },
        { value: 'mixed', label: 'Mixed' },
      ]
    },
    {
      name: 'number_of_males',
      hide: !form.members_sex != 'mixed',
      label: '# of Males',
    },
    {
      name: 'number_of_females',
      hide: !form.members_sex != 'mixed',
      label: '# of Females',
    },
    {
      name: 'number_of_instruments',
      label: 'How many instruments are in the band?',
      is: 'integer'
    },
    {
      name: 'additional_instruments_required_list',
      label: 'Additional Instruments Required',
      columns: [
        {
          name: 'instrument',
          required: false,
        }
      ]
    },
    {
      name: 'performance_details_list',
      label: 'Band Performances',
      columns: [
        'location',
        'frequency',
        'function'
      ]
    },
    {
      name: 'constituency',
      label: '',
    },

    '::Uploads',
    {
      name: 'band_profile_upload',
      type: 'file:pdf',
      label: 'Aims & Objectives',
    },
    {
      name: 'instrument_list_upload',
      type: 'file:pdf',
      label: 'Additional Instruments Attachment',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
