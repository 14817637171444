import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class IcaoIdentifier extends React.Component {
  resource = '/icao_identifiers'
  form_key = 'icao_identifier'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Details of Aircraft",
    {
        name: 'reg_mark',
        label:'Registration Mark',
        columns: ['previous', 'allocated']
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Manufacturer of Aircraft'
    },
    {
        name: 'manu_date',
        label: 'Date of Manufacture',
        type: 'date',
        showDropdownyear: true
    },
    "::Details of Applicant",
    {
        name: 'date',
        type: 'date'
    },
    {
        name: 'comp_name',
        label: 'Name of Company'
    },
    {
        name: 'app_name',
        label: 'Name of Applicant'
    },
    {
        name: 'position_held'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
