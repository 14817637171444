import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class TextField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () =>
    this.state.isValid ? null : (
      <div style={this.errorStyles}>{this.getErrorMessage()}</div>
    )

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      withRequiredValidator,
      icon,
      ...rest
    } = this.props

    if (!rest.className) rest.className = 'form-control'
    if (!this.state.isValid) rest.className += ' input_error'

    const charsLeft = rest.maxLength ? (
      <span className='text-muted textarea-chars-remaining'>
        {Number(rest.maxLength) - rest.value.length} characters left
      </span>
    ) : null

    return (
      <React.Fragment>
        <div className='input-group'>
          <textarea {...rest}></textarea>
        </div>
        {this.errorText()}
        {charsLeft}
      </React.Fragment>
    )
  }
}

export default TextField
