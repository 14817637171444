import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class PrivateFlight extends React.Component {
  resource = '/private_flights'
  form_key = 'private_flight'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'private_op',
      label: 'Name of Private Operator'
    },
    {
      name: 'air_reg',
      label: 'Aircraft Registration'
    },
    {
      name: 'air_type',
      label: 'Aircraft Type'
    },
    {
      name: 'travel_date',
      label: 'Intended Travel Date',
      type: 'date',
      showYearDropdown: true
    },
    {
      name: 'destination',
      label: 'Destination(Please provide airport codes)',
      columns: ['from', ' ', ' ']
    },
    {
      name: 'purpose',
      label: 'Purpose of Flight',
      type: 'textarea'
    },
    {
      name: 'pax_num',
      label: 'Number of Inbound Pax',
    },
    {
      name: 'visa_upload',
      label: 'Please attach Bahamas Health Visa for all travelers',
      key: 'image.0',
      type: 'file:all'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
