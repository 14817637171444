
    export const danceCodes = {
        pre_school: {
            code: 'PS',
            categories: [
                {
                   name : 'Dance',
                   titles: [
                        {
                            label: 'Classical Ballet',
                            value: '1'
                        },
                        {
                            label: 'Bahamian Folk Dance',
                            value: '2'
                        },
                        {
                            label: 'International Folk Dance',
                            value: '3'
                        },
                        {
                            label: 'Modern Dance',
                            value: '4'
                        },
                        {
                            label: 'Tap Dance',
                            value: '5'
                        }

                   ]
                }
            ]
        },
        primary_school: {
            code: 'P',
            categories: [
                {
                   name : 'Dance',
                   titles: [
                        {
                            label: 'Classical Ballet',
                            value: '1'
                        },
                        {
                            label: 'Bahamian Folk Dance',
                            value: '2'
                        },
                        {
                            label: 'International Folk Dance',
                            value: '3'
                        },
                        {
                            label: 'Modern Dance',
                            value: '4'
                        },
                        {
                            label: 'Jazz Dance',
                            value: '5'
                        },
                        {
                            label: 'Popular Dances',
                            value: '6'
                        },
                        {
                            label: 'Dance Drama',
                            value: '7'
                        },
                        {
                            label: 'Liturgical Dance',
                            value: '8'
                        },
                        {
                            label: 'Tap Dance',
                            value: '9'
                        },
                        {
                            label: 'Cheerleading',
                            value: '10'
                        },
                        {
                            label: 'Flagettes',
                            value: '11'
                        },
                        {
                            label: 'Best Choreographer',
                            value: '12'
                        },

                   ]
                }
            ]
        },
        junior_high:{
            code: 'J',
            categories: [
                {
                   name : 'Dance',
                   titles: [
                        {
                            label: 'Classical Ballet',
                            value: '1'
                        },
                        {
                            label: 'Bahamian Folk Dance',
                            value: '2'
                        },
                        {
                            label: 'International Folk Dance',
                            value: '3'
                        },
                        {
                            label: 'Modern Dance',
                            value: '4'
                        },
                        {
                            label: 'Jazz Dance',
                            value: '5'
                        },
                        {
                            label: 'Popular Dances',
                            value: '6'
                        },
                        {
                            label: 'Dance Drama',
                            value: '7'
                        },
                        {
                            label: 'Liturgical Dance',
                            value: '8'
                        },
                        {
                            label: 'Tap Dance',
                            value: '9'
                        },
                        {
                            label: 'Cheerleading',
                            value: '10'
                        },
                        {
                            label: 'Flagettes',
                            value: '11'
                        },
                        {
                            label: 'Best Choreographer',
                            value: '12'
                        },

                   ]
                }
            ]
        },
        senior_high: {
            code: 'S',
            categories: [
                {
                   name : 'Dance',
                   titles: [
                        {
                            label: 'Classical Ballet',
                            value: '1'
                        },
                        {
                            label: 'Bahamian Folk Dance',
                            value: '2'
                        },
                        {
                            label: 'International Folk Dance',
                            value: '3'
                        },
                        {
                            label: 'Modern Dance',
                            value: '4'
                        },
                        {
                            label: 'Jazz Dance',
                            value: '5'
                        },
                        {
                            label: 'Popular Dances',
                            value: '6'
                        },
                        {
                            label: 'Dance Drama',
                            value: '7'
                        },
                        {
                            label: 'Liturgical Dance',
                            value: '8'
                        },
                        {
                            label: 'Tap Dance',
                            value: '9'
                        },
                        {
                            label: 'Cheerleading',
                            value: '10'
                        },
                        {
                            label: 'Flagettes',
                            value: '11'
                        },
                        {
                            label: 'Best Choreographer',
                            value: '12'
                        },

                   ]
                }
            ]
        },
        open_and_community: {
            code: 'C',
            categories: [
                {
                   name : 'Dance',
                   titles: [
                        {
                            label: 'Classical Ballet',
                            value: '1'
                        },
                        {
                            label: 'Bahamian Folk Dance',
                            value: '2'
                        },
                        {
                            label: 'International Folk Dance',
                            value: '3'
                        },
                        {
                            label: 'Modern Dance',
                            value: '4'
                        },
                        {
                            label: 'Jazz Dance',
                            value: '5'
                        },
                        {
                            label: 'Hip-Hop',
                            value: '6'
                        },
                        {
                            label: 'Dance Drama',
                            value: '7'
                        },
                        {
                            label: 'Liturgical Dance',
                            value: '8'
                        },
                        {
                            label: 'Tap Dance',
                            value: '9'
                        },
                        {
                            label: 'Cheerleading',
                            value: '10'
                        },
                        {
                            label: 'Flagettes',
                            value: '11'
                        },
                        {
                            label: 'Best Choreographer',
                            value: '12'
                        },

                   ]
                }
            ]
        }


    }

   export const dramaCodes = {
        pre_school: {
            code: 'PS',
            categories: [
                {
                    name: 'Drama',
                    titles: [
                        {
                            label: 'Individual Verse Speaking',
                            value: '1'
                        },
                        {
                            label: 'Choral Verse Speaking',
                            value: '2'
                        }
                    ]
                }
            ]
        },
        primary_school: {
            code: 'P',
            categories: [
                {
                    name: 'Drama',
                    titles: [
                        {
                            label: 'Individual Verse Speaking - Grades 1-3',
                            value: '1'
                        },
                        {
                            label: 'Individual Verse Speaking - Grades 4-6',
                            vale: '2'
                        },
                        {
                            label: 'Choral Verse Speaking - Grades 1-3',
                            value: '3'
                        },
                        {
                            label: 'Choral Verse Speaking - Grades 4-6',
                            value: '4'
                        },
                        {
                            label: 'Dramatized Characterization - One Person - Grades 4-6',
                            value: '5'
                        },
                        {
                            label: 'Dramatized Characterization - Two Persons - Grades 4-6',
                            value: '6'
                        },
                        {
                            label: 'Skit - Grades 1-3',
                            value: '7'
                        },
                        {
                            label: 'Skit - Grades 4-6',
                            value: '8'
                        },
                        {
                            label: 'Storytelling - Grades 1-3',
                            value: '9'
                        },
                        {
                            label: 'Storytelling - Grades 5-6',
                            value: '10'
                        }
                    ]
                }
            ]
        },
        junior_high: {
            code: 'J',
            categories: [
                {
                    name: 'Drama',
                    titles: [
                        {
                            label: 'Individual Verse Speaking',
                            value: '1'
                        },
                        {
                            label: 'Choral Verse Speaking',
                            value: '2'
                        },
                        {
                            label: 'Dramatized Characterization - One Person - Provided Piece',
                            value: '3'
                        },
                        {
                            label: 'Dramatized Characterization - Two Persons - Provided Piece',
                            value: '4'
                        },
                        {
                            label: 'Dramatized Characterization - One Person - Chosen Piece',
                            value: '5'
                        },
                        {
                            label: 'Dramatized Characterization - Two Persons - Chosen Piece',
                            value: '6'
                        },
                        {
                            label: 'Skit',
                            value: '7'
                        },
                        {
                            label: 'Play - One Act',
                            value: '8'
                        },
                        {
                            label: 'Play - Full Length',
                            value: '9'
                        }

                    ]
                }
            ]
        },
        senior_high: {
            code: 'S',
            categories: [
                {
                    name: 'Drama',
                    titles: [
                        {
                            label: 'Individual Verse Speaking',
                            value: '1'
                        },
                        {
                            label: 'Choral Verse Speaking',
                            value: '2'
                        },
                        {
                            label: 'Dramatized Characterization - One Person - Provided Piece',
                            value: '3'
                        },
                        {
                            label: 'Dramatized Characterization - One Person - Classic - Provided Piece',
                            value: '4'
                        },
                        {
                            label: 'Dramatized Characterization - Two Persons - Provided Piece',
                            value: '5'
                        },
                        {
                            label: 'Dramatized Characterization - Two Persons - Classic - Provided Piece',
                            value: '6'
                        },
                        {
                            label: 'Dramatized Characterization - Two Persons - Chosen Piece',
                            value: '7'
                        },
                        {
                            label: 'Skit',
                            value: '8'
                        },
                        {
                            label: 'Play - One Act',
                            value: '9'
                        },
                        {
                            label: 'Play - Full Length',
                            value: '10'
                        },
                        {
                            label: 'Storytelling',
                            value: '11'
                        }

                    ]
                }
            ]
        },

        open_and_community: {
            code: 'C',
            categories: [
                {
                    name: 'Drama',
                    titles: [
                        /*
                        {
                            label: 'Individual Verse Speaking',
                            value: '1'
                        },
                        {
                            label: 'Choral Verse Speaking',
                            value: '2'
                        },
                        {
                            label: 'Dramatized Characterization',
                            value: '3'
                        },
                        {
                            label: 'Dramatized Characterization',
                            value: '4'
                        },
                        {
                            label: 'Dramatized Characterization',
                            value: '5'
                        },
                        {
                            label: 'Dramatized Characterization',
                            value: '6'
                        }, */
                        {
                            label: 'Dramatized Characterization',
                            value: '7'
                        },
                        {
                            label: 'Skit',
                            value: '8'
                        },
                        {
                            label: 'Play - One Act',
                            value: '9'
                        },
                        {
                            label: 'Play - Full-Length',
                            value: '10'
                        },
                        {
                            label: 'Storytelling',
                            value: '11'
                        }

                    ]
                }
            ]
        }

    }

 /*   const musicCodes = {
        pre_school: {
            code: 'PS',
            categories: [
                {
                    name: 'Music',
                    titles: [
                        {
                            
                        },
                        {
                            
                        }
                    ]
                }
            ]
        },
        primary_school: {
            code: 'PR',
            categories: [
                {
                    name: 'Music',
                    titles: [
                        {

                        }
                    ]
                }
            ]
        },
        junior_high: {
            code: 'JR',
            categories: [
                {
                    name: 'Music',
                    titles: [
                        {
                            
                        }
                    ]
                }
            ]
        },
        senior_high: {
            code: 'SR',
            categories: [
                {
                    name: 'Music',
                    titles: [
                        {
                            
                        }
                    ]
                }
            ]
        }
    }
*/






