import React from 'react'
import { connect } from 'react-redux'
import { ValidatorForm }from 'react-form-validator-core'
import { Link } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import DataTable from 'react-data-table-component'

import InputField from '@shared/form-fields/input'
import { extractKeys } from '@helpers/objects'
import Loader from '@shared/loader'

import { getIssuedCertificates, revokeCertificates } from '@actions/blockcerts'

class CertificateTable extends React.Component {
  constructor (props) {
    super(props)
    !props.history.length && props.getIssuedCertificates()
  }

  state = {
    mode     : window.sessionStorage.getItem('fw_selected') || 'initial',
    revoking : null,
    selected : [],
    reason   : '',
  }

  componentWillUnmount () {
    window.sessionStorage.removeItem('fw_selected')
  }

  columns = [
    {
      name: 'UUID',
      selector: 'uuid'
    },
    {
      name: 'Company',
      selector: 'company',
      format: r => r.company.replace(/-/g, ' ').capitalize()
    },
    {
      name: 'Type',
      selector: 'cert_type',
      format: r => r.cert_type.replace(/-/g, ' ').capitalize()
    },
    {
      name: 'Name',
      selector: 'user_name',
    },
    {
      name: 'Status',
      selector: 'status',
    },
    {
      name: 'Created At',
      selector: 'created_at'
    },
    {
      name: 'Action',
      cell: r => r.status == 'VALID' ? (
        <span data-action onClick={this.revokeOne(r)}>Revoke</span>
      ) : null
    }
  ]

  revokeOne = record => () => this.setState({ revoking: [record] })

  revokeSelected = () => this.setState({ revoking: this.state.selected })

  cancelRevoke = () => this.setState({ revoking: null })

  revokeCerts = () => {
    const { state, props } = this
    const { reason } = this.state

    const { company } = state.revoking[0]
    const revoked = state.revoking.map(r => ({
      uuid: r.uuid,
      reason,
    }))

    this.setState({
      revoking: null,
      reason: '',
      selected: []
    })

    props.revokeCertificates(company, revoked)
  }

  SelectScreen = () => {
    const move = mode => () => {
      window.sessionStorage.setItem('fw_selected', mode)
      this.setState({ mode })
    }

    const toTable = move(this.isAdmin ? 'admin' : 'table' )

    return (
      <div className="content" style={{marginTop: '15%'}}>
        <div className="page-header"></div>
        <div className="page-inner">
          <article style={{ textAlign: 'center', maxWidth: 640, margin: '0 auto' }}>
              <h2>Vehicle Insurance</h2>
              <h4>Certificates</h4>
              <br/>
              <ul className="nav nav-pills nav-primary nav-pills-no-bd nav-pills-icons justify-content-center" id="pills-tab-with-icon" role="tablist">
                <li className="nav-item">
                  <a onClick={toTable} className="nav-link active" id="pills-home-tab-icon" data-toggle="pill" href="#pills-home-icon" role="tab" aria-controls="pills-home-icon" aria-selected="false">
                    <i className="fas fa-list"></i>
                    <span className='fw-bold'>View Applications</span>
                  </a>
                </li>
                <li className="nav-item">
                  <Link to='/certs/issue/single' onClick={toTable} className="nav-link active">
                    <i className="fas fa-plus"></i>
                    <span className='fw-bold'>Issue Single</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='/certs/issue/batch' onClick={toTable} className="nav-link active">
                    <i className="fas fa-plus"></i>
                    <span className='fw-bold'>Issue Batch</span>
                  </Link>
                </li>
              </ul>
          </article>
        </div>
      </div>
    )
  }

  RevokeForm = () => {
    const { state, revokeCerts, cancelRevoke } = this
    const { revoking } = state

    const message = revoking?.length > 1
      ? <span>Are you sure you want to revoke the <strong>{revoking?.length}</strong> selected certificates?</span>
      : <span>Are you sure you want to revoke the certificate with the UUID <strong>{revoking?.[0].uuid}</strong>?</span>

    return (
      <Modal open={!!state.revoking} close={cancelRevoke} style={{width:640}} center>
        <div className="modal-content" style={{width:640}}>
          <div className="modal-header">
            <h5 className="modal-title" id="confirm-revoke-modal-title">Confirm Revoke Action</h5>
          </div>
          <div className="modal-body">
            <ValidatorForm id='revoke-form' onSubmit={revokeCerts}>
              {message}
              <br/>
              <div className='form-group form-show-validation'>
                <label htmlFor='name'>Please provide a reason*</label>
                <InputField
                  type='text'
                  className='form-control'
                  name='reason'
                  onChange={ev => this.setState({ reason: ev.target.value })}
                  value={state.reason}
                  validators={['required']}
                  errorMessages={['Required']}
                />
              </div>
              </ValidatorForm>
            <span style={{color:'red'}}>THIS ACTION IS IRREVERSIBLE*</span>
          </div>
          <div className="modal-footer">
            <button className='btn btn-round mr-2' onClick={cancelRevoke}>Cancel</button>
            <button className='btn btn-danger btn-round' type='submit' form='revoke-form'>Revoke</button>
          </div>
        </div>
      </Modal>
    )
  }

  render () {
    const { state, props, columns, SelectScreen, RevokeForm } = this

    if (props.loading) return <Loader {...props} />
    if (state.mode == 'initial') return <SelectScreen />

    const tprops = {
      columns,
      data: props.history,
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="page-header"></div>
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Certificates</h4>
                      <Link to={"/certs/issue/single"} className="btn custom-btn btn-round mr-2 ml-auto">
                        <i className="fas fa-plus mr-2"></i>
                        Issue Single
                      </Link>
                      <Link to={"/certs/issue/batch"} className="btn custom-btn btn-round mr-2">
                        <i className="fas fa-plus mr-2"></i>
                        Issue Batch
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive form-records-table">
                      <DataTable {...tprops} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RevokeForm/>
      </React.Fragment>
    )
  }
}

export default connect(state => extractKeys(state.blockcerts, 'history'), {
  getIssuedCertificates,
  revokeCertificates,
})(CertificateTable)
