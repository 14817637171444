import * as types from '@action-types'

const initial = {
  loading: false,
  history: [],
  error: null,
}

const reducer = (state = initial, { type, payload }) => {
  switch (type) {
    case types.RESET_CERT_STATE:
      return initial
    case types.SEND_BLOCKCERTS_INVITES_ATTEMPT:
    case types.FETCH_ISSUE_HISTORY_ATTEMPT:
    case types.ISSUE_CERTIFICATES_ATTEMPT:
    case types.REVOKE_CERTIFICATES_ATTEMPT:
      return {
        ...state,
        loading: true,
      }
    case types.SEND_BLOCKCERTS_INVITES_FAILURE:
    case types.FETCH_ISSUE_HISTORY_FAILURE:
    case types.ISSUE_CERTIFICATES_FAILURE:
    case types.REVOKE_CERTIFICATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case types.FETCH_ISSUE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: payload,
      }
    case types.SEND_BLOCKCERTS_INVITES_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case types.ISSUE_CERTIFICATES_SUCCESS:
      return {
        ...state,
        loading: false,
        history: state.history.length
          ? [...payload.history, ...state.history]
          : payload.history,
      }
    case types.REVOKE_CERTIFICATES_SUCCESS:
      const { history } = state

      for (let i = payload.length; i--; ) {
        const entryIndex = history.findIndex(h => h.uuid == payload[i])
        if (entryIndex) history[entryIndex].status = 'REVOKED'
      }

      return {
        ...state,
        loading: false,
        history,
      }
    default:
      return state
  }
}

export default reducer
