import { useEffect, useState, useRef, Fragment } from 'react'
import DataTable from 'react-data-table-component'

import Loader from '@shared/loader'
import toastr from '@modules/toastr'
import { fullName } from '@modules/form-wizard/utils'
import { splitEvery } from '@helpers/arrays'
import { getPosition } from '@modules/geo'
import mapServiceName from '@constants/maps.services'

const BATCH_UPDATE_URL = '/deliveries/bulk_deliverable_status_update'

const BatchCollection = () => {
  const [loading, setLoading] = useState(true)
  const [batches, setBatches] = useState(null)
  const [active, setActive] = useState(null)
  const selected = useRef()

  useEffect(() => {
    const fetchRecords = async () => {
      const { data } = await $app.axios.get('/deliveries', {
        params: {
          delivered: false
        }
      })

      setBatches(data.records)
      setLoading(false)
    }

    fetchRecords()
  }, [])

  useEffect(() => {
    selected.current = []
  }, [active])

  const toggle = ({ selectedRows }) => {
    selected.current = selectedRows.map(r => `${r.service_type}:${r.id}`)
  }

  const activateBatch = batch => {
    if (!batch.service_forms?.length) {
      setActive({ ...batch })
      return
    }

    const packages = batch.service_forms.reduce((list, item) => {
      const service_name = mapServiceName(item.service_type)

      list.push(
        ...item.forms.map(f => ({
          ...f,
          service_name,
          service_type: item.service_type,
          username: fullName(f.user, 'initial'),
        }))
      )

      return list
    }, [])

    setActive({
      ...batch,
      packages
    })
  }

  /*
    Batches & their attached packages have separate statuses
    due to the fact that there is a chance that a batch could
    be delivered with contained items being physically missing when a
    Collections Clerk does their confirmation check

    So we first update the batch itself, then start batch updates of
    the contained items in the background so that the clerk may continue their
    work
  */
  const confirmArrival = async () => {
    const { qr_code, name, packages } = active
    setLoading(true)

    try {
      const coords = await getPosition()
      await $app.axios.patch(`/deliveries/${qr_code}/delivered`, {
        location: `${coords?.latitude},${coords?.longitude}`,
      })
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to change status of delivery')
      return
    }

    const { here, miss } = packages.reduce((obj, f) => {
      const { service_type, delivery_status, id } = f

      if (delivery_status == 'ready for pickup') return obj

      const prop = selected.current.includes(`${service_type}:${id}`)
        ? 'here'
        : 'miss'
      obj[prop][service_type].push(id)
      return obj
    }, (() => {
      const formTypes = [...new Set(packages.map(p => p.service_type))]
      const initial = () => formTypes.reduce((o, k) => ({
        ...o,
        [k]: [],
      }), {})

      return {
        here: initial(),
        miss: initial(),
      }
    })())

    // Make sure all missing items are marked as such so that we may safely
    // assume remaining items can be marked as "ready for pickup" on resume
    // in case of a page refresh
    for (let [service_type, service_id] of Object.entries(miss)) {
      if (!service_id.length) continue
      const status = 'not present'

      for (let group of splitEvery(service_id, 20)) {
        await $app.axios.patch(BATCH_UPDATE_URL, {
          status,
          service_type,
          service_id: group,
        })
      }
    }

    setLoading(false)
    toastr.success(
      'Updates Started',
      'Applications in this batch have began to be updated'
    )

    for (let [service_type, service_id] of Object.entries(here)) {
      if (!service_id.length) continue
      const status = 'ready for pickup'

      for (let group of splitEvery(service_id, 20)) {
        await $app.axios.patch(BATCH_UPDATE_URL, {
          status,
          service_type,
          service_id: group,
        })
      }
    }

    toastr.success(
      'Batch Complete',
      `All applications in <b>${name}</b> have been updated`
    )
  
  }

  const columns = [
    {
      name: 'Citizen',
      selector: 'username',
      sortable: true,
    },
    {
      name: 'Service',
      selector: 'service_name',
      sortable: true,
    },
  ]

  return (
    <section className='container'>
      <Loader loading={loading} />
      <h2>Collect Batches</h2>

      <article className='flex flex-col md:flex-row lg:items-start'>
        <nav className='card md:sticky top-0 p-4 w-full lg:w-96'>
        {batches?.map(batch => (
          <div
            key={batch.id}
            onClick={() => activateBatch(batch)}
            className='flex flex-col rounded-lg hover:bg-gray-200 px-2 py-1 mb-2 cursor-pointer'
          >
            <strong>{batch.name}</strong>
            <span className='text-gray-500'>{fullName(batch.officer)}</span>
          </div>
        ))}
        </nav>
        <div className='card flex flex-col w-full max-w-4xl lg:ml-4 xl:ml-12'>
          {!active
            && <span className='text-center text-gray-600 2xl:py-20'>Select A Batch</span>
          }
          {!!active
            && (
              <Fragment>
                <div className='card-header'>
                  <h3 className='text-lg mb-0'>Confirm Arrival</h3>
                </div>
                <div className='card-body form-records-table'>
                  <DataTable
                    data={active.packages}
                    columns={columns}
                    onSelectedRowsChange={toggle}
                    noHeader
                    selectableRows
                  />
                </div>
                <div className='card-footer'>
                  <button className='btn custom-btn ml-auto' onClick={confirmArrival}>
                    Confirm
                  </button>
                </div>
              </Fragment>
            )
          }
        </div>
      </article>
    </section>
  )
}

export default BatchCollection