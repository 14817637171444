import React from 'react'
import Swal from '@sweetalert'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import { extractKeys } from '@helpers/objects'
import SERVICE_PROPS from '@/helpers/extract-service-props'

export default class PassportRenewal extends React.Component {
  constructor(props) {
    super(props)
    Object.assign(this, extractKeys($app, 'axios', 'current_user:user'))
  }

  state = {
    loading: false,
    base_64_string: '',
  }

  resource = '/passports'
  form_key = 'passport'
  r_key = 'passports'

  custom_acceptance = true
  skip_location = true
  skip_update_payment = true

  overrides = {
    columns: true,
  }

  fee = () => this.props.location.state?.passport?.fee

  hooks = {
    'create': async (form, done) => {
      const { props, resource, getPassportInvoice } = this

      try {
        const { data: create } = await $app.axios.post(resource, form)

        if (create.error?.match(/[a-z]/)) {
          toastr.error('Error', create.error)
          return false
        }

        if (create.verified || create.is_verified) {
          const submission = await $app.axios.post('/passports/submit_application', form)
          const application_id = submission.data?.response.response
          const invoice = await getPassportInvoice(form.passport_no, application_id)

          props.history.replace(props.location.pathname, {
            ...(this.props.location.state || {}),
            passport: { application_id, ...invoice },
          })

          done(create)
        }
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to submit passport application')
      }

      return false
    },

    'post:update:payment': async form => {
      try {
        await $app.axios.post('/passports/submit_receipt', {
          passport: {
            ...form,
            ...extractKeys(
              this.props.location.state.passport,
              'application_id'
            ),
          },
        })
      } catch (err) {
        console.error(err)
        toastr.error(
          'Error',
          'Unable to submit final receipt to Passport Office'
        )

        return false
      }
    },
  }

  getPassportInvoice = async (
    passport_number,
    application_id,
    suppress_error = false
  ) => {
    const { resource } = this

    try {
      const { data } = await $app.axios.post(resource + '/get_invoice', {
        passport: {
          application_id,
          passport_number,
        },
      })

      const fee = Number(data.response['feesToPay'][0]['feeAmount']).toFixed(2)
      const invoice_id = data.response.invoiceId

      return { fee, invoice_id }
    } catch (err) {
      if (!suppress_error) {
        toastr.error('Error', 'Unable to fetch application invoice')
      }

      console.error(err)
      return null
    }
  }

  getPassportStatus = (pay, passport) => async () => {
    console.log(pay)
    this.setState({ loading: true })

    try {
      const { data } = await $app.axios.post('/passports/status_puid', {
        passport_no: passport.application_id,
      })

      this.setState({ loading: false })
      const status = data?.response?.response
      const { isConfirmed } = await Swal.fire({
        icon: 'info',
        title: 'Passport Status',
        showCancelButton: true,
        cancelButtonText: 'Close',
        showConfirmButton: status == 'PENDING FEE PAYMENT',
        confirmButtonText: 'Pay Now',
        html: (
          <dl>
            <dt>Passport Number</dt>
            <dd>{passport?.passport_number}</dd>
            <dt>Application Status</dt>
            <dd>{status || 'N/A'}</dd>
            <dt>Submission Date</dt>
            <dd>
              {moment(passport?.created_at).format('Do MMMM, YYYY @ h:mm A')}
            </dd>
          </dl>
        ),
      })

      if (isConfirmed) {
        this.setState({ loading: true })

        try {
          const { getPassportInvoice } = this
          const { application_id, passport_no: pnum } = passport
          const invoice = await getPassportInvoice(pnum, application_id, true)
          if (!invoice) throw new Error(null)
          
          await pay({ ...passport, payment_amount: invoice.fee }, {
            additionalState: {
              passport: { ...invoice, application_id },
            }
          })()
        } catch (err) {
          console.error(err)
          Swal.fire('Error', 'Unable to get passoport invoice', 'error')
        } finally {
          this.setState({ loading: false })
        }
      }
    } catch (err) {
      console.error(err)
      toastr.error(
        'Error',
        'Unable to fetch status of application for passport ' +
          passport.passport_number
      )
      this.setState({ loading: false })
    }
  }

  columns = props => [
    {
      name: 'Passport Number',
      selector: 'passport_number',
    },
    {
      name: 'Submission Date',
      selector: 'created_at',
      format: r => moment(r?.created_at).format('YYYY-MM-DD'),
    },
    {
      name: 'Status',
      cell: r => (
        <span onClick={this.getPassportStatus(props.pay, r)} data-action>
          Get Status
        </span>
      ),
    },
  ]

  validate = ({ passport_no }) => {
    const { base_64_string } = this.state

    if (base_64_string) {
      return {
        __OVERWRITE__: true,
        __TRANSFORM__: false,
        passport_no,
        base_64_string,
        acceptance: true,
      }
    } else {
      toastr.warning(
        'Unable To Submit',
        'Please upload a valid passport photo first'
      )

      return false
    }
  }

  fields = () => [
    {
      label: 'Passport Number',
      name: 'passport_no',
    },
    {
      name: 'passport_photo',
      type: 'file:image',
      save: false,

      callback: async file => {
        if (!file) return
        this.setState({ base_64_string: null })

        const reader = new FileReader()

        reader.onload = async () => {
          try {
            const base_64_string = reader.result.split(',')[1]
            await $app.axios.post('/passports/check_photo', { base_64_string })
            toastr.success(
              'Photo Validated',
              'Your photo has been successfully validated'
            )
            this.setState({ base_64_string })
            return
          } catch (err) {
            toastr.warning(
              'Photo Validation Failed',
              err.response.data?.error?.replace('["', '').replace('"]', '')
            )
          }
        }

        reader.readAsDataURL(file)
      },
    },
    {
      use_as_acceptance: true,
      name: 'acceptance',
      type: 'checkbox',
      label: `
        By selecting this box, I hereby acknowledge that the photo and passport number
        to be uploaded is that of the MyGateway account holder.
      `,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
