import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class DriversLicenseResearch extends React.Component {
  resource = '/drivers_license_researches'
  form_key = 'drivers_license_research'

  skip_location = true
  // fee = 15
  free = true

  fields = () => [
    '::*** Please note that this form is to be completed by persons who have not obtained a photographic drivers license.',
    {
      name: 'previous_address',
      label: 'Previous Address'
    },
    {
      name: 'last_renewal_year',
      label: 'Last Renewal Year'
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}