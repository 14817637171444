import ENV from '@constants/env'

import '@styles/toastr.css'

const icons = {
  primary : 'fas fa-bell',
  success : 'fas fa-check',
  warning : 'fas fa-exclamation-triangle',
  error   : 'fas fa-times-circle',
  info    : 'fas fa-info-circle',
}

let last = ''
let timestamp = Date.now()

const toast = type => (title = '', message = '', options = {}) => {
  // Prevent accidental spam toasts from rendering
  // Fix for 401:Unauthorized error flooding the screen
  const m = `${title}:${message}`
  const t = Date.now()

  if (last == m && t - timestamp < 2000 ) return

  const state = type == 'error'
    ? 'danger'
    : type

  let msg = message
  const opts = {
    type: state,
    timer: 1000,
    delay: 4000,
    mouse_over: 'pause',

    placement: {
      from: 'top',
      align: 'right'
    },
  }

  if (typeof options == 'object') {
    Object.assign(opts, options)
  }

  if (options == 'SERVICE_ERROR') {
    msg += `<br/>For technical support, contact the Helpdesk toll free at ${ENV.HELPDESK.HTML.PHONE} or email ${ENV.HELPDESK.HTML.EMAIL}.`
    opts.delay = 10000
  }

  window.$.notify({
    title,
    message: msg || '',
    icon: icons[type],
  }, opts)

  last = m
  timestamp = t
}

export default Object.keys(icons).reduce((o, k) => ({
  ...o,
  [k]: toast(k)
}), {})
