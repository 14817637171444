import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

export default class JusticeOfPeace extends React.Component {
  resource = '/justice_of_peaces'
  form_key = 'justice_of_peace'
  free = true

  fields = form => [
    'sponsor',
    {
      name: 'proposed_island',
      label: 'Proposed District',
      options: ['The Bahamas', ...islands],
    },
    {
      name: 'reason',
      type: 'textarea',
      label:
        'Please indicate why you wish to be appointed a Justice of the Peace.',
      maxLength: 255,
      rows: 5,
      view: {
        label: 'Reason For Appointment',
      },
    },
    {
      name: 'special_reason',
      type: 'textarea',
      label:
        'Mention any special circumstances (community service, etc.) that may be of assistance in determining your application for a Justice of the Peace.',
      maxLength: 255,
      rows: 5,
      view: {
        label: 'Special Considerations',
      },
    },
    {
      name: 'previous_application',
      type: 'select:bool',
      label: 'Have you submitted a previous application?',
      long: true,
      view: {
        label: 'Previous Application?',
      },
    },
    {
      name: 'previous_application_date',
      type: 'date',
      label: 'Please provide the approximate date of your submission',
      maxDate: new Date(),
      showYearDropdown: true,
      long: true,
      hide: !form.previous_application,
      view: {
        label: 'Previous Application Date',
      },
    },
    {
      name: 'biography_upload',
      label: 'Single Page Biography',
      type: 'file:pdf',
      key: 'image',
      hint: 'Accepts: PDF',
      long: true,
      view: {
        label: 'Biography',
      },
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
