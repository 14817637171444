import React from 'react'
import { Modal } from 'react-responsive-modal'

const ConfirmationModal = ({ open, close, confirm, description }) => (
  <Modal open={open} onClose={close} center>
    <div className='swal-modal' role='dialog' aria-modal='true'>
      <div className='swal-icon swal-icon--warning'>
        <span className='swal-icon--warning__body'>
          <span className='swal-icon--warning__dot'></span>
        </span>
      </div>
      <div className='swal-title'>Confirmation</div>
      <div className='swal-text text-center' style={{ margin: '0 2rem' }}>
        {description}
      </div>
      <div className='swal-footer'>
        <div className='swal-button-container'>
          <button
            className='swal-button swal-button--confirm btn-primary mr-4'
            onClick={confirm}
          >
            Yes
          </button>
          <button
            className='swal-button swal-button--danger btn btn-danger'
            onClick={close}
          >
            No
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

export default ConfirmationModal
