import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class SelfDriveFranchiseRenewal extends React.Component {
  // id       = 'SelfDriveFranchise'
  // service  = 'self drive franchise grant'
  // title    = 'Self-Drive Franchise Grant'
  // agency   = 'rtd'
  // item     = '396'
  // admin    = 'road_traffic'
  resource = '/self_drive_franchises'
  form_key = 'self_drive_franchise'
  skip_location = true

  // fee = 15

  defaults = {
    client_type: 'individual',
    granted: false,
    renewal: true,
    variation: false,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        granted: false,
        renewal: true,
        variation: false,
      },
    },
  }

  fields = () => [
    {
      name: 'client_type',
      save: true,
      hide: true,
    },
    {
      name: 'granted',
      save: true,
      hide: true,
    },
    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    {
      name: 'variation',
      save: true,
      hide: true,
    },

    {
      name: 'existing_vehicles_numb',
      label: 'Number of vehicles specified on existing franchise',
      type: 'integer',
    },
    {
      name: 'applied_vehicles_numb',
      label: 'Number of vehicles now applied for',
      type: 'integer',
    },
    {
      name: 'variation_requested',
      label: 'Variation requested',
      type: 'text',
    },
    {
      name: 'vehicles_kept_list',
      label: 'Where are the vehicles to be kept',
      // required: false,
      // hint: 'Please separate each company with a newline',
      view: {
        value: v => {
          if (!v?.length) return null
          return v.map(r => JSON.parse(r))
        }
      },
      columns: [
        {
          name: 'address',
          label: 'Address',
          type: 'text',
          required: false,
        },
        {
          name: 'phone',
          label: 'Phone #',
          type: 'phone',
          required: false,
        },
        {
          name: 'po_box',
          label: 'PO Box',
          type: 'text',
          required: false,
        },
      ]
    },
    {
      name: 'max_charge_daily',
      label: 'Maximum charge per day',
      type: 'curency',
    },
    {
      name: 'max_charge_weekly',
      label: 'Maximum charge per week',
      type: 'curency',
    },
    {
      name: 'max_charge_monthly',
      label: 'Maximum charge per month',
      type: 'curency',
    },
    {
      name: 'substitute_vehicle',
      label: 'What provision is made for a substitue vehicle should hired vehicle become immobilised',
      type: 'text',
    },
    {
      name: 'max_numb_vehicles_used',
      label: 'Maximum number of vehicles to be operated',
      type: 'integer',
    },
    {
      name: 'vehicles_used_list',
      label: 'Vehicles to be used',
      // required: false,
      // hint: 'Please separate each company with a newline',
      view: {
        value: v => {
          if (!v?.length) return null
          return v.map(r => JSON.parse(r))
        }
      },
      columns: [
        {
          name: 'make',
          label: 'Make',
          required: false,
        },
        {
          name: 'model',
          label: 'Model',
          required: false,
        },
        {
          name: 'year',
          label: 'Year',
          type: 'integer',
          required: false,
        },
        {
          name: 'registration_numb',
          label: 'Registration #',
          required: false,
        },
      ]
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
