const plantsList = [
  {
    "common_name": "ACEROLA, BARBADOS CHERRY, WEST INDIAN CHERRY",
    "scientific_name": "MALPIGHIA GLABRA"
  },
  {
    "common_name": "MANDARIN (KING), NARANJITA",
    "scientific_name": "CITRUS RETICULATA"
  },
  {
    "common_name": "ALLSPICE",
    "scientific_name": "PIMENTA DIOICA"
  },
  {
    "common_name": "AMBARELLA, OTAHEITE APPLE, GREAT HOG PLUM",
    "scientific_name": "SPONDIAS DULCIS- CYTHEREA"
  },
  {
    "common_name": "ANISE",
    "scientific_name": "PIMPINELLA ANISUM"
  },
  {
    "common_name": "APPLE, MANSANAS",
    "scientific_name": "MALUS DOMESTICA"
  },
  {
    "common_name": "APRICOT",
    "scientific_name": "PRUNUS ARMENIACA"
  },
  {
    "common_name": "ARTICHOKE",
    "scientific_name": "CYNARA CARDUNCULUS VAR. SCOLYMUS"
  },
  {
    "common_name": "ARTOCARPUS - JACKFRUIT",
    "scientific_name": "ARTOCARPUS SPP."
  },
  {
    "common_name": "ARUGULA (BABY)",
    "scientific_name": "ERUCA SATIVA"
  },
  {
    "common_name": "ARUGULA (STANDARD)",
    "scientific_name": "ERUCA VESICARIA SSP. SATIVA"
  },
  {
    "common_name": "ASPARAGUS",
    "scientific_name": "ASPARAGUS OFFICINALIS"
  },
  {
    "common_name": "ATEMOYA",
    "scientific_name": "ANNONA X ATEMOYA"
  },
  {
    "common_name": "AVOCADO",
    "scientific_name": "PERSEA AMERICANA"
  },
  {
    "common_name": "BALIMBING, CARAMBOLA, STARFRUIT",
    "scientific_name": "AVERRHOA CARAMBOLA"
  },
  {
    "common_name": "BALUNO, BINJAI, BAUNO, BAYUNO",
    "scientific_name": "MANGIFERA CAESIA / M. KEMANGA"
  },
  {
    "common_name": "BASIL",
    "scientific_name": "OCIMUM BASILICUM"
  },
  {
    "common_name": "BATATA",
    "scientific_name": "IPOMOEA BATATAS"
  },
  {
    "common_name": "BAY LEAF",
    "scientific_name": "LAURUS NOBILIS"
  },
  {
    "common_name": "BEAN",
    "scientific_name": "PHASEOLUS VULGARIS"
  },
  {
    "common_name": "BEET",
    "scientific_name": "BETA VULGARIS"
  },
  {
    "common_name": "BILIMBI, CUCUMBER TREE, KAMIAS, IBA",
    "scientific_name": "AVERRHOA BILIMBI"
  },
  {
    "common_name": "BITTER MELON / BITTER GOURD",
    "scientific_name": "MOMORDICA CHARANTIA"
  },
  {
    "common_name": "BLACKBERRY",
    "scientific_name": "RUBUS FRUTICOSUS"
  },
  {
    "common_name": "BLUEBERRY",
    "scientific_name": "VACCINIUM SECT. CYANOCOCCUS"
  },
  {
    "common_name": "BOK CHOY",
    "scientific_name": "BRASSICA RAPA SUBSP. CHINENSIS"
  },
  {
    "common_name": "BROCCOLI",
    "scientific_name": "BRASSICA OLERACEA VAR. ITALICA"
  },
  {
    "common_name": "BROCCOLINI",
    "scientific_name": "BRASSICA OLERACEA VAR. BOTRYTIS"
  },
  {
    "common_name": "BUDDHA HAND",
    "scientific_name": "CITRUS MEDICA VAR. SARCODACTYLIS"
  },
  {
    "common_name": "CABBAGE",
    "scientific_name": "BRASSICA OLERACEA VAR. CAPITATA"
  },
  {
    "common_name": "CALAMANSI",
    "scientific_name": "CITRUS MICROCARPA, C. MITIS"
  },
  {
    "common_name": "CALAMONDIN, GOLDEN LIME, KALAMANSI, LEMONSITO",
    "scientific_name": "CITROFORTUNELLA MICROCARPA SYN"
  },
  {
    "common_name": "CANISTEL, EGG-FRUIT, YELLOW SAPOTE,",
    "scientific_name": "POUTERIA CAMPECHIANA"
  },
  {
    "common_name": "CANTALOUPE",
    "scientific_name": "CUCUMIS MELO VAR. CANTALUPENSIS"
  },
  {
    "common_name": "CAPULIN, JAMAICA CHERRY, DATILES, MANSANITAS, SERESA",
    "scientific_name": "PRUNUS SALICIFOLIA"
  },
  {
    "common_name": "CARROT",
    "scientific_name": "DAUCUS CAROTA"
  },
  {
    "common_name": "CARROT CELLO",
    "scientific_name": "DAUCUS CAROTA VAR. SATIVUS"
  },
  {
    "common_name": "CASHEW, KASOY",
    "scientific_name": "ANACARDIUM OCCIDENTALE"
  },
  {
    "common_name": "CASSAVA",
    "scientific_name": "MANIHOT ESCULENTA"
  },
  {
    "common_name": "CELERY",
    "scientific_name": "APIUM GRAVEOLENS"
  },
  {
    "common_name": "CHARD/SWISS CHARD",
    "scientific_name": "BETA VULGARIS SUBSP."
  },
  {
    "common_name": "CHEMPEDAK",
    "scientific_name": "ARTOCARPUS INTEGER"
  },
  {
    "common_name": "CHERIMOYA",
    "scientific_name": "ANNONA CHERIMOLA"
  },
  {
    "common_name": "CHERRY",
    "scientific_name": "PRUNUS AVIUM"
  },
  {
    "common_name": "CHERVIL",
    "scientific_name": "ANTHRISCUS CEREFOLIUM"
  },
  {
    "common_name": "CHICORY",
    "scientific_name": "CICHORIUM INTYBUS"
  },
  {
    "common_name": "CHIVE",
    "scientific_name": "ALLIUM SCHOENOPRASUM"
  },
  {
    "common_name": "CILANTRO",
    "scientific_name": "CORIANDRUM SATIVUM"
  },
  {
    "common_name": "CITRON, BULID, SIDRIS",
    "scientific_name": "CITRUS MEDICA"
  },
  {
    "common_name": "CLEMENTINE",
    "scientific_name": "CITRUS X CLEMENTINA"
  },
  {
    "common_name": "COCONUT",
    "scientific_name": "COCOS NUCIFERA"
  },
  {
    "common_name": "COLE SLAW (CABBAGE MIX)",
    "scientific_name": "BRASSICA OLERACEA VAR. CAPITATA"
  },
  {
    "common_name": "COLLARD GREEN",
    "scientific_name": "BRASSICA OLERACEA VAR. VIRIDIS"
  },
  {
    "common_name": "CORN WHITE",
    "scientific_name": "ZEA MAYS"
  },
  {
    "common_name": "CORN YELLOW",
    "scientific_name": "ZEA MAYS"
  },
  {
    "common_name": "CRANBERRY",
    "scientific_name": "VACCINIUM SUBG. OXYCOCCUS"
  },
  {
    "common_name": "CUCUMBER",
    "scientific_name": "CUCUMIS SATIVUS"
  },
  {
    "common_name": "CURRANT",
    "scientific_name": "RIBES"
  },
  {
    "common_name": "CUSTARD APPLE",
    "scientific_name": "ANNONA RETICULATA"
  },
  {
    "common_name": "DATE",
    "scientific_name": "PHOENIX DACTYLIFERA"
  },
  {
    "common_name": "DATES MEDJOOL",
    "scientific_name": "PHOENIX DACTYLIFERA"
  },
  {
    "common_name": "DILL",
    "scientific_name": "ANETHUM GRAVEOLENS"
  },
  {
    "common_name": "DURIAN, DULIAN",
    "scientific_name": "DURIO ZIBETHINUS"
  },
  {
    "common_name": "EGGPLANT",
    "scientific_name": "SOLANUM MELONGENA"
  },
  {
    "common_name": "FENNEL",
    "scientific_name": "FOENICULUM VULGARE"
  },
  {
    "common_name": "FIG",
    "scientific_name": "FICUS CARICA"
  },
  {
    "common_name": "GALANGA ROOT",
    "scientific_name": "ALPINIA GALANGA"
  },
  {
    "common_name": "GARDENCRESS",
    "scientific_name": "LEPIDIUM SATIVUM"
  },
  {
    "common_name": "GARLIC",
    "scientific_name": "ALLIUM SATIVUM"
  },
  {
    "common_name": "GINGER",
    "scientific_name": "ZINGIBER OFFICINALE"
  },
  {
    "common_name": "GRAPEFRUIT RED",
    "scientific_name": "CITRUS X PARADISI"
  },
  {
    "common_name": "GRAPES",
    "scientific_name": "VITIS VINIFERA"
  },
  {
    "common_name": "GREEN BEAN",
    "scientific_name": "PHASEOLUS VULGARIS"
  },
  {
    "common_name": "GUAVA, BAYABAS",
    "scientific_name": "PSIDIUM GUAJAVA"
  },
  {
    "common_name": "TAMARIND, SWEET INGA",
    "scientific_name": "PITHECELLOBIUM DULCE"
  },
  {
    "common_name": "HONEYDEW",
    "scientific_name": "CUCUMIS MELO"
  },
  {
    "common_name": "JACKFRUIT",
    "scientific_name": "ARTOCARPUS HETEROPHYLLUS"
  },
  {
    "common_name": "JAMBOLAN",
    "scientific_name": "SYZYGIUM CUMINI"
  },
  {
    "common_name": "JEW'S MALLOW",
    "scientific_name": "CORCHORUS OLITORIUS"
  },
  {
    "common_name": "JICAMA",
    "scientific_name": "PACHYRHIZUS EROSUS"
  },
  {
    "common_name": "KALE",
    "scientific_name": "BRASSICA OLERACEA VAR. SABELLICA"
  },
  {
    "common_name": "KIWI",
    "scientific_name": "ACTINIDIA"
  },
  {
    "common_name": "LANZONES, LANGSAT, BOBOA, BUAHAN",
    "scientific_name": "LANSIUM DOMESTICUM"
  },
  {
    "common_name": "LEMON",
    "scientific_name": "CITRUS LIMON"
  },
  {
    "common_name": "LEMON GRASS",
    "scientific_name": "CYMBOPOGON CITRATUS"
  },
  {
    "common_name": "LETTUCE (ARCADIAN BLEND)",
    "scientific_name": "LACTUCA SATIVA"
  },
  {
    "common_name": "LETTUCE (ARTISAN)",
    "scientific_name": "LACTUCA SATIVA"
  },
  {
    "common_name": "LETTUCE (BOSTON)",
    "scientific_name": "LACTUCA SATIVA ASTERACEA"
  },
  {
    "common_name": "LETTUCE (HYDRO BIBB)",
    "scientific_name": "LACTUCA SATIVA BIBB"
  },
  {
    "common_name": "LETTUCE (ICEBERG CELLO)",
    "scientific_name": "LACTUCA SATIVA VAR. CAPITATA"
  },
  {
    "common_name": "LETTUCE (MESCLUN)",
    "scientific_name": "LACTUCA SATIVA"
  },
  {
    "common_name": "LETTUCE (ROMAINE)",
    "scientific_name": "LACTUCA SATIVA VAR. LONGIFOLIA ASTERALES"
  },
  {
    "common_name": "LIBAS, LUBAS, ALUBIHON, ADUAS",
    "scientific_name": "SPONDIAS PINNATA"
  },
  {
    "common_name": "LIME",
    "scientific_name": "CITRUS X AURANTIIFOLIA"
  },
  {
    "common_name": "LOQUAT",
    "scientific_name": "ERIIOBOTRYA JAPONICA"
  },
  {
    "common_name": "LOTUS ROOT {SACRED LOTUS}",
    "scientific_name": "NELUMBO NUCIFERA"
  },
  {
    "common_name": "LYCHEE, LITCHI, LETSIAS",
    "scientific_name": "LITCHI CHINENSIS"
  },
  {
    "common_name": "MABOLO, VELVET APPLE",
    "scientific_name": "DIOSPYROS BLANCOI"
  },
  {
    "common_name": "MALANGA",
    "scientific_name": "XANTHOSOMA SAGITTIFOLIUM"
  },
  {
    "common_name": "MAMEY",
    "scientific_name": "POUTERIA SAPOTA"
  },
  {
    "common_name": "MANDARIN, MANDARIN ORANGE",
    "scientific_name": "CITRUS RETICULATA"
  },
  {
    "common_name": "MANGGA WANI, KUWINI, HUANI, WANI",
    "scientific_name": "MANGIFERA ODORATA"
  },
  {
    "common_name": "MANGO",
    "scientific_name": "MANGIFERA INDICA"
  },
  {
    "common_name": "MANGOSTEEN, MANGGUSTAN",
    "scientific_name": "GARCINIA MANGOSTANA"
  },
  {
    "common_name": "MARANG, MADANG, LOLOI",
    "scientific_name": "ARTOCARPUS ODORATISSIMUS"
  },
  {
    "common_name": "MARJORAM",
    "scientific_name": "ORIGANUM MAJORANA"
  },
  {
    "common_name": "MELON",
    "scientific_name": "CUCUMIS MELO"
  },
  {
    "common_name": "MINT",
    "scientific_name": "MENTHA"
  },
  {
    "common_name": "MOMBIN, RED MOMBIN, SPANISH HOG PLUM SINIGUELAS, SIRHUELAS",
    "scientific_name": "SPONDIAS PURPUREA"
  },
  {
    "common_name": "MORINGA",
    "scientific_name": "MORINGA OLEIFERA"
  },
  {
    "common_name": "MUSCADINE GRAPE",
    "scientific_name": "VITIS ROTUNDIFOLIA"
  },
  {
    "common_name": "MUSHROOM (BROWN)",
    "scientific_name": "AGARICUS BISPORUS"
  },
  {
    "common_name": "MUSHROOM (HOSHIMI)",
    "scientific_name": "JUNNA"
  },
  {
    "common_name": "MUSHROOM (MAIITAKE)",
    "scientific_name": "GRIFOLA FRONDOSA"
  },
  {
    "common_name": "MUSHROOM (OYSTER)",
    "scientific_name": "PLEUROTUS OSTREATUS"
  },
  {
    "common_name": "MUSTARD GREEN",
    "scientific_name": "BRASSICA JUNCEA"
  },
  {
    "common_name": "NECTARINE",
    "scientific_name": "PRUNUS PERSICA VAR. NUCIPERSICA"
  },
  {
    "common_name": "NEEM",
    "scientific_name": "AZADIRACHTA INDICA"
  },
  {
    "common_name": "OKRA",
    "scientific_name": "ABELMOSCHUS ESCULENTUS"
  },
  {
    "common_name": "OLIVE",
    "scientific_name": "OLEA EUROPAEA"
  },
  {
    "common_name": "ONION (GREEN)",
    "scientific_name": "ALLIUM CEPA"
  },
  {
    "common_name": "ONION (PEARL WHITE)",
    "scientific_name": "ALLIUM AMPELOPRASUM VAR. SECTIVUM"
  },
  {
    "common_name": "ONION (RED)",
    "scientific_name": "ALLIUM CEPA"
  },
  {
    "common_name": "ONION (VIDALIA)",
    "scientific_name": "ALLIUM CEPA"
  },
  {
    "common_name": "ONION SHALLOT",
    "scientific_name": "ALLIUM CEPA GR. AGGREGATUM"
  },
  {
    "common_name": "ORANGE, SWEET ORANGE, KAHEL",
    "scientific_name": "CITRUS X SINENSIS"
  },
  {
    "common_name": "OREGANO",
    "scientific_name": "ORIGANUM VULGARE"
  },
  {
    "common_name": "PAHUTAN, PAHO, PANGAMANGAEN",
    "scientific_name": "MANGIFERA ALTISSIMA"
  },
  {
    "common_name": "PAK CHOI",
    "scientific_name": "BRASSICA RAPA VAR CHINENSIS"
  },
  {
    "common_name": "PAPAYA",
    "scientific_name": "CARICA PAPAYA"
  },
  {
    "common_name": "PARSLEY",
    "scientific_name": "PETROSELINUM CRISPUM"
  },
  {
    "common_name": "PASSION FRUIT",
    "scientific_name": "PASSIFLORA EDULIS"
  },
  {
    "common_name": "PASSIONFRUIT (GIANT)",
    "scientific_name": "PASSIFLORA QUADRANGULARIS"
  },
  {
    "common_name": "PASSIONFRUIT (PURPLE)",
    "scientific_name": "PASSIFLORA INCARNATA"
  },
  {
    "common_name": "PASSIONFRUIT (YELLOW)",
    "scientific_name": "PASSIFLORA EDULIS FLAVICARPA"
  },
  {
    "common_name": "PEA (SNOW)",
    "scientific_name": "PISUM SATIVUM VAR. SACCHARATUM"
  },
  {
    "common_name": "PEA (SUGAR SNAP)",
    "scientific_name": "PISUM SATIVUM MACROCARPON"
  },
  {
    "common_name": "PEACH",
    "scientific_name": "PRUNUS PERSICA"
  },
  {
    "common_name": "PEANUT",
    "scientific_name": "ARACHIS HYPOGAEA"
  },
  {
    "common_name": "PEAR",
    "scientific_name": "PYRUS"
  },
  {
    "common_name": "PEAR (ASIAN)",
    "scientific_name": "PYRUS PYRIFOLIA"
  },
  {
    "common_name": "PEAR (BOSC)",
    "scientific_name": "PYRUS COMMUNIS BOSC"
  },
  {
    "common_name": "PEAR (D'ANJOU)",
    "scientific_name": "PYRUS COMMUNIS D'ANJOU"
  },
  {
    "common_name": "PEPPER (ANCHO)",
    "scientific_name": "CAPSICUM ANNUUM VAR. POBLANO"
  },
  {
    "common_name": "PEPPER (BELL)",
    "scientific_name": "CAPSICUM ANNUUM"
  },
  {
    "common_name": "PEPPER (CHIPOTLE)",
    "scientific_name": "CAPSICUM ANNUUM"
  },
  {
    "common_name": "PEPPER (CUBANELLE)",
    "scientific_name": "CAPSICUM ANNUUM CUBANELLE"
  },
  {
    "common_name": "PEPPER (GUAJILLO)",
    "scientific_name": "CAPSICUM ANNUUM GUAJILLO"
  },
  {
    "common_name": "PEPPER (HABANERO)",
    "scientific_name": "CAPSICUM CHINENSE HABANERO"
  },
  {
    "common_name": "PEPPER (JALAPENO)",
    "scientific_name": "CAPSICUM ANNUUM JALAPENO"
  },
  {
    "common_name": "PEPPER (RAINBOW)",
    "scientific_name": "CAPSICUM ANNUUM BOLIVIAN RAINBOW"
  },
  {
    "common_name": "PEPPER (SHISITO)",
    "scientific_name": "CAPSICUM ANNUUM VAR. GROSSUM"
  },
  {
    "common_name": "PERSIMMON",
    "scientific_name": "DIOSPYROS VIRGINIANA"
  },
  {
    "common_name": "PERSIMMON (BLACK)",
    "scientific_name": "DIOSPYROS DIGYNA"
  },
  {
    "common_name": "PERSIMMON (CHINESE)",
    "scientific_name": "DIOSPYROS KAKI"
  },
  {
    "common_name": "PINEAPPLE",
    "scientific_name": "ANANAS COMOSUS"
  },
  {
    "common_name": "PLANTAIN",
    "scientific_name": "MUSA X PARADISIACA"
  },
  {
    "common_name": "PLUM",
    "scientific_name": "PRUNUS DOMESTICA"
  },
  {
    "common_name": "POMEGRANATE",
    "scientific_name": "PUNICA GRANATUM"
  },
  {
    "common_name": "POTATO",
    "scientific_name": "SOLANUM TUBEROSUM"
  },
  {
    "common_name": "PUMMELO, POMMELO, POMELO,",
    "scientific_name": "CITRUS MAXIMA"
  },
  {
    "common_name": "PUMPKIN",
    "scientific_name": "CUCURBITA PEPO"
  },
  {
    "common_name": "RADISH",
    "scientific_name": "RAPHANUS SATIVUS"
  },
  {
    "common_name": "RAMBUTAN",
    "scientific_name": "NEPHELIUM LAPPACEUM"
  },
  {
    "common_name": "RASPBERRY",
    "scientific_name": "RUBUS IDAEUS"
  },
  {
    "common_name": "RHUBARD",
    "scientific_name": "RHEUM RHABARBARUM"
  },
  {
    "common_name": "ROSEMARY",
    "scientific_name": "SALVIA ROSMARINUS"
  },
  {
    "common_name": "SAGE",
    "scientific_name": "SALVIA OFFICINALIS"
  },
  {
    "common_name": "SANTOL, KECHAPI, SENTOL",
    "scientific_name": "SANDORICUM KOETJAPE"
  },
  {
    "common_name": "SAPODILLA, NASEBERRY, CHICO",
    "scientific_name": "MANILKARA ZAPOTA"
  },
  {
    "common_name": "SCALLION",
    "scientific_name": "ALLIUM FISTULOSUM"
  },
  {
    "common_name": "SCOTCH BONNET",
    "scientific_name": "CAPSICUM CHINENSE"
  },
  {
    "common_name": "SIAMESE GINGER",
    "scientific_name": "ALPINIA GALANGA"
  },
  {
    "common_name": "SOUR ORANGE, SEVILLE ORANGE",
    "scientific_name": "CITRUS X AURANTIUM"
  },
  {
    "common_name": "SOURSOP, GUYABANO, GUAYABANO",
    "scientific_name": "ANNONA MURICATA"
  },
  {
    "common_name": "SPINACH",
    "scientific_name": "SPINACIA OLERACEA"
  },
  {
    "common_name": "SPONDIAS, JUNE PLUM",
    "scientific_name": "SPONDIAS SPP."
  },
  {
    "common_name": "SPROUT",
    "scientific_name": "BRASSICA OLERACEA VAR. GEMMIFERA"
  },
  {
    "common_name": "SPROUT (BRUSSEL)",
    "scientific_name": "BRASSICA OLERACEA VAR. GEMMIFERA"
  },
  {
    "common_name": "SQUASH",
    "scientific_name": "CUCURBITA"
  },
  {
    "common_name": "STARAPPLE, CAIMITO",
    "scientific_name": "CHRYSOPHYLLUM CAINITO"
  },
  {
    "common_name": "STARFRUIT",
    "scientific_name": "AVERRHOA CARAMBOLA"
  },
  {
    "common_name": "STRAWBERRY",
    "scientific_name": "FRAGARIA X ANANASSA"
  },
  {
    "common_name": "SUGARAPPLE, SWEESOP, ATIS",
    "scientific_name": "ANNONA SQUAMOSA"
  },
  {
    "common_name": "SYZYGIUM",
    "scientific_name": "SYZYGIUM SUPP."
  },
  {
    "common_name": "TAMARIND, SAMPALOK, SAMBARTAG",
    "scientific_name": "TAMARINDUS INDICA"
  },
  {
    "common_name": "TANGERINE",
    "scientific_name": "CITRUS RETICULATA"
  },
  {
    "common_name": "TARRAGON",
    "scientific_name": "ARTEMISIA DRACUNCULUS"
  },
  {
    "common_name": "THYME",
    "scientific_name": "THYMUS VULGARIS"
  },
  {
    "common_name": "TOFU (SOYBEAN)",
    "scientific_name": "GLYCINE MAX"
  },
  {
    "common_name": "TOMATO",
    "scientific_name": "SOLANUM LYCOPERSICUM"
  },
  {
    "common_name": "TOMATO {CHERRY/GRAPE}",
    "scientific_name": "SOLANUM LYCOPERSICUM VAR. CERASIFORME"
  },
  {
    "common_name": "TURMERIC",
    "scientific_name": "CURCUMA LONGA"
  },
  {
    "common_name": "TURNIP",
    "scientific_name": "BRASSICA RAPA SUBSP. RAPA"
  },
  {
    "common_name": "VEGETABLE FERN",
    "scientific_name": "DIPLAZIUM ESCULENTUM"
  },
  {
    "common_name": "WATER APPLE, BELL FRUIT, TAMBIS",
    "scientific_name": "SYZIGIUM SAMARANGENSE"
  },
  {
    "common_name": "WATERCRESS",
    "scientific_name": "NASTURTIUM OFFICINALE"
  },
  {
    "common_name": "WATERMELON",
    "scientific_name": "CITRULLUS LANATUS"
  },
  {
    "common_name": "WAX GOURD",
    "scientific_name": "BENINCASA HISPIDA"
  },
  {
    "common_name": "WAX JAMBU, JAVA APPLE, MAKOPA",
    "scientific_name": "SYZIGIUM SAMARANGENSE"
  },
  {
    "common_name": "WHEAT GRASS",
    "scientific_name": "TRITICUM AESTIVUM"
  },
  {
    "common_name": "YAM",
    "scientific_name": "DIOSCOREA"
  },
  {
    "common_name": "YUCCA",
    "scientific_name": "YUCCA"
  },
  {
    "common_name": "YUCCA ROOT",
    "scientific_name": "MANIHOT ESCULENTA"
  }
].reduce((plants, curr) => {
  if (!curr?.common_name) return plants

  plants[curr.common_name] = curr.scientific_name
  return plants
}, {})

export default plantsList
