import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class RegistrationCertificate extends React.Component {
  resource = '/registration_certificates'
  form_key = 'registration_certificate'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'nationality',
        label: 'Nationality or common mark and registration'
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Aircraft serial no.'
    },
    {
        name: 'name',
        label: 'Name of owner'
    },
    {
        name: 'adress',
        label: 'Address of owner'
    },
    {
        name: 'reg_basis',
        label: 'Basis of registration',
        options: ['ownership of aircraft', 'operator of aircraft', 'other']
    },
    {
        name: 'other',
        label: 'Explain',
        type: 'textarea',
        hide: form.reg_basis != 'other'
    },
    {
        name: 'cert_address',
        label: 'Address of owner certificate holder'
    },
    {
        name: 'contact_info',
        label: 'Name and contact information of owner, if different from certifcate holder',
        required: false
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
