import env from '@constants/env'
import React from 'react'
// import Modal from 'react-responsive-modal'

// import Alert from '@modules/alert'

export default class ErrorBoundary extends React.Component {
  state = {
    error: null,
  }

  componentDidCatch(error, info) {
    this.setState({ error })
  }

  render() {
    const { state, props } = this

    if (state.error && !env.PRODUCTION) {
      return <pre>{state.error.toString()}</pre>
    }

    return props.children
  }
}
