import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class JubileeGamesVendor extends React.Component {
  resource = '/jubilee_game_vendors'
  form_key = 'jubilee_game_vendor'
  defaults = {}

  postpaid      = true
  hide_buttons  = false
  business_only = true
  skip_location = true

  custom_actions = []

  validate = form => ({
    [form.place_type]: true,
  })

  fields = form => [
    ...BUSINESS_FIELDS(form),
    // Hidden Fields Start
    {
      name: 'booth',
      save: true,
      hide: true,
    },
    {
      name: 'tent',
      save: true,
      hide: true,
    },
    // Hidden Fields End

    {
      name: 'product_type',
      label: 'Type of Product',
    },
    {
      name: 'preference',
      hide: true,
      view: {
        label: 'Prefers Booth/Tent?',
        value: form.booth ? 'Booth' : 'Tent'
      }
    },
    {
      name: 'place_type',
      hint: 'NOTE: Application fees are due following processing and approval',
      label: 'Would you prefer a booth or tent?',
      options: [
        { value: 'booth', label: 'Booth ($150)' },
        { value: 'tent', label: 'Tent ($250)' },
      ]
    },
    {
      name: 'items_for_sale_list',
      label: 'Items For Sale',
      columns: ['item'],
      format: v => v.item,
    },

    '::Special Needs',
    {
      name: 'electrical_outlet',
      type: 'checkbox',
      label: 'Electrical Outlets',
      view: true,
    },
    {
      as: 'integer',
      name: 'numb_of_outlets',
      hide: !form.electrical_outlet,
      label: '# of Outlets',
    },
    {
      name: 'lighting',
      type: 'checkbox',
      label: 'Lighting',
      view: true,
    },

    '::Uploads',
    {
      name: 'health_permit_upload',
      type: 'file:pdf',
      label: 'Health Permit',
    },
    {
      name: 'food_handler_permit_upload',
      type: 'file:pdf',
      label: 'Food Handler Permit',
    },
    {
      name: 'police_record_upload',
      type: 'file:pdf',
      label: 'Valid Police Certificate',
    },

    '::SPACER',
    {
      name: 'hold_harmless_agreement',
      type: 'checkbox',
      label: `
        <strong>HOLD  HARMLESS  AGREEMENT:</strong>
        The applicant acknowledges that the Bahamas Games Secretariat is not
        responsible for any damage or loss of personal property belonging to
        the applicant and waives any claim against the above organization as
        a consequence of such possible damage or loss.
      `
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
