import React from 'react'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import { fullName } from '@modules/form-wizard/utils'
import SERVICE_PROPS from '@helpers/extract-service-props'

import {
  recommendToMinister,
  addPermitNote,
} from './_security-utils'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

const today = new Date()

export default class NationalSymbolPermissionRenewal extends React.Component {
  resource = '/national_symbol_permissions'
  form_key = 'national_symbol_permission'
  defaults = {}

  isRenewal = true

  // Delete lines as needed
  // fee = true
  // postpay_key = 'fee'
  // postpaid = true
  // custom_postpaid_message =
  //   `Thank you for using MyGateway. Your request for a new National Symbol Use Licence has been successfully submitted and will be reviewed. 
  //   An email confirming the licence approval or denial will be sent to your inbox. 
  //   If approved, you will receive a notification to proceed with the payment for your new Firearm Licence.`
  // pay_on_approval = true
  skip_location = true

  hide_buttons = {
    status: true,
    // fee: true,
    deny: !$app.hasRole('minister'),
  }

  custom_actions = [
    recommendToSeniorOfficer.call(this),
    recommendToUS.call(this),
    recommendToPS.call(this),
    recommendToMinister.call(this),
    addPermitNote.call(this),
  ]

  defaults = {
    renewal: this.isRenewal
  }

  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
      },
    },
  }

  validate = async form => {
    if (!form.acceptance_1 && !form.acceptance_2 && !form.acceptance_3) {
      await Swal.fire(
        'Response Missing',
        'Please select at least one of the checkboxes below Question 1.',
        'warning'
      )
      
      setTimeout(() => {
        document.querySelector('.wizard-body > .row').scrollIntoView()
      }, 100)

      return false
    }

    return true
  }

  fee = form => {
    if (Array.isArray(form.symbols_used)) {
      return 22 * form.symbols_used.length
    } else {
      return 22 * form.symbols_used.split(',')?.length
    }
  }

  fields = (form, viewing = false) => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      save: true,
      hide: true
    },
    {
      heading: '1. I, the above named applicant, hereby apply for the permission of the Minister to -',
      view: false,
    },
    {
      name: 'acceptance_1',
      type: 'checkbox',
      view: true,
      required: false,
      label: viewing ? 'Produced/Distributed By Applicant?' : `
        (1) apply to the goods/articles/things which are described in paragraph 2
        below and which have been made/produced/distributed/sole by me, the design
        illustrated in paragraph 3 below;
      `
    },
    {
      name: 'acceptance_2',
      type: 'checkbox',
      view: true,
      required: false,
      label: viewing ? 'Importing Goods?' : `
        (2) import into The Bahamas the goods/articles/things which are described
        in paragraph 2 below and to which there has been applied the design
        illustrated in paragraph 3 below;
      `
    },
    {
      name: 'acceptance_3',
      type: 'checkbox',
      view: true,
      required: false,
      label: viewing ? 'Using For Business?' : `
        (3) use or permit to be used in the manner specified in paragraph 4
        below and in connection with a business/trade/calling/profession, the
        design illustrated in paragraph 3 below.
      `
    },
    {
      name: 'symbols_used',
      label: 'Symbol(s) Requesting Permission For',
      multi: true,
      options: [
        { value: 'National Flag', label: 'National Flag' },
        { value: 'Coat of Arms', label: 'National Coat of Arms' },
       // { value: 'part of the design of the National Flag', label: 'Partial Use of the National Flag' },
        { value: 'part of the design of the Coat of Arms', label: 'Partial Use of the Coat of Arms' },
        { value: 'flag colours', label: 'Official Colours of the National Flag' },
      ]
    },
    {
      hide: !form.symbols_used?.includes?.('flag colours'),
      heading: `
        <div>
        NOTE: The colour code required for the National Flag is as follows (Pantone Standard):
        <div class='flex flex-wrap space-x-4 mt-1'>
          <span style='padding:0.5rem;background:#00778b;color:white;border-radius:0.5rem'>
            Aquamarine : PMS 3145
          </span>
          <span style='padding:0.5rem;background:#fcc917;color:#000;border-radius:0.5rem'>
            Gold : PMS 123
          </span>
          <span style='padding:0.5rem;background:#000;color:white;border-radius:0.5rem'>
            Black : PMS Standard
          </span>
        </div>
        </div>
      `,
    },
    {
      name: 'detailed_desc',
      type: 'textarea',
      label: `
        2. Details of goods/articles/things to which the application relates:
      `
    },
    {
      name: 'illustration_description',
      type: 'textarea',
      label: `
        3. Illustration of the National Flag/Coat of Arms/part of the design
        of the National Flag/part of the design of the Coat of Arms/other
        flag/devices, to which the application relates:
      `,
    },
    {
      name: 'proposed_use',
      type: 'textarea',
      label: `
        4. Manner in which it is proposed to use the design illustrated in
        paragraph 3 above in connection with a business/trade/calling/profession:
      `,
    },
    {
      name: 'permission_date',
      type: 'date',
      label: '5. Date on which permission is required',
      minDate: today,
    },

    '::Uploads',
    {
      key: 'image.1',
      name: 'illustration_upload',
      type: 'file:image',
      label: 'Illustration Using Symbol',
    },
    {
      key: 'image.2',
      name: 'illustration_extra_upload',
      type: 'file:image',
      label: '2nd Illustration Using Symbol',
      required: false,
    }
  ]

  hidden_fields = () => [
    {
      key: 'image.0',
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Letter of Approval',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} stacked />
}

function recommendToSeniorOfficer () {
  return {
    text: 'Forward To Sr. Officer',
    icon: 'user-tie',
    roles: ['officer'],
    test: r => r.application_decision == 'pending',
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Forward To Sr. Officer',
        html: `
          What is your recommendation to the Sr. Officer
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

function recommendToUS () {
  return {
    text: 'Forward To Under Secretary',
    icon: 'user-tie',
    roles: ['senior_officer'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Forward To Under Secretary',
        html: `
          What is your recommendation to the Under Secretary
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

function recommendToPS () {
  return {
    text: 'Forward To Permanent Secretary',
    icon: 'user-tie',
    roles: ['under_secretary'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Forward To Permanent Secretary',
        html: `
          What is your recommendation to the Minister of National Security
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}
