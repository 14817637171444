import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys } from '@helpers/objects'
import { confirmAlert } from 'react-confirm-alert'
import toastr from '@modules/toastr'
import Swal from 'sweetalert2'

export default class NewTrafficRecord extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: null,
    }

    Object.assign(this, extractKeys($app, 'axios', 'current_user:user'))

    this.licence = null
  }

  getLicenceDetails = async (refetch = false) => {
    try {
      if (refetch || !this.licence) {
        const { data } = await this.axios.get(
          '/drivers_licenses/fetch_rtd/' + this.user.NIB
        )
        this.licence = data['RTD Data']
      }

      return this.licence
    } catch (err) {
      console.error(err)
      return {
        error: 'Unable to retrieve licence details from the RTD database',
      }
    }
  }

  id = 'TrafficRecord'
  service = 'traffic record'
  title = 'Traffic Record'
  agency = 'rbpf'
  item = '294'
  admin = 'police'
  resource = '/traffic_records'
  form_key = 'traffic_record'

  free = false
  postpaid = false
  defaults = {}
  
  skip_location = false
  hide_buttons = {
    status: true,
    email: true,
  }
  custom_actions = [
    {
      text: 'Mark as Completed',
      icon: 'check-circle',
      test: r => r.payment_status,
      fn: record =>
        confirmAlert({
          customUI: ({ onClose }) => (
            <div className='custom-ui'>
              <h3>Mark as Completed</h3>
              <p style={{ color: '#333' }}>
                Are you sure you want to mark this application as completed?
                Ensure that all relevant processes are completed before clicking
                'Yes'.
              </p>
              <button
                className='btn btn-primary'
                onClick={this.completeApplication(record, onClose)}
              >
                Yes
              </button>
              <button className='btn btn-danger' onClick={onClose}>
                No
              </button>
            </div>
          ),
        }),
    },
    {
      text: 'Outstanding Warrant',
      icon: 'envelope-open-text',
      test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          title: 'Send outstanding warrant email',
          text: `By clicking confirm, you will be notifying ${`${record.user.first_name} ${record.user.last_name}`.capitalize()} regarding an outstanding warrant (s).
           Are you sure that you want to proceed?`,
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          confirmButtonColor: '#26AEEF',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.sendEmail(record)
          }
        }),
    },
  ]
  custom_acceptance = false

  fee = 5

  validate = async form => {
    const { TMN, error } = await this.getLicenceDetails(true)

    if (error) return error

    return TMN === form.dl_numb
      ? true
      : "Driver's Licence # not assigned to the user's NIB #"
  }

  foremost = null

  sendEmail = async record => {
    const message = ` 
    Greetings ${record?.user.first_name.capitalize()}, 
    <br/><br/>
    An outstanding warrant(s) has been identified.
    <br/><br/> 
    In order to proceed with your request, please satisfy the outstanding warrant (s) and attach the associated receipts of the same in an email to <a href="mailto:TRAFFICRECORDSUPPORT@BAHAMAS.GOV.BS?subject= Outstanding Warrant Receipt - ${`${record.user.first_name} ${record.user.last_name}`.capitalize()}">TRAFFICRECORDSUPPORT@BAHAMAS.GOV.BS</a>.
    <br/><br/> 
    Thank you for using MyGateway.
    
    `

    try {
      await $app.axios.post('/emails', {
        email: record.user.email,
        subject:
          'MyGateway Portal Update - Outstanding Warrant for Traffic Record',
        message,
      })

      toastr.success('Success', 'Email successfully sent')
      Swal.fire(
        'Sent!',
        'The customer will be notified about their outstanding warrant shortly!',
        'success'
      )
    } catch (error) {
      toastr.error('Error', 'Unable to send email')
      console.error(error)
    }
  }

  completeApplication = (record, onClose) => async ev => {
    const { resource, form_key } = this

    try {
      this.setState({ loading: true })

      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      toastr.success('Success', 'Application has been marked as completed')

      await $app.axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateqway Portal Update - Traffic Record Application',
        message: `
          Good day ${record.user.first_name.capitalize()},
          <br/>
          Your traffic record service has been completed. Please provide us with your feedback on how we can enhance the customer experience
          https://www.surveymonkey.com/r/Preview/?sm=sEmnqd14nxLzKGxhyYuuDJAZUeco2ApGWHVju7BgliT1T5W3BqSbPtivQ8gMTf2a
          <br/><br />
          Thank you for using MyGateway Portal.
        `,
      })

      toastr.success('Success', 'Email has been sent to user')
    } catch (err) {
      toastr.error('Error', 'Unable to mark application as completed')
      console.error(err)
    } finally {
      this.setState({ loading: false })
      onClose()
    }
  }
  fields = form => [
    {
      name: 'renewal',
      label: "Do you have a Public Service Driver's Licence?",
      type: 'select:bool',
    },
    {
      name: 'dl_numb',
      label: form.renewal
        ? "Public Service Driver's Licence #"
        : "Driver's Licence #",
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
