import React from 'react'
import moment from 'moment'
import Modal from 'react-responsive-modal'
// import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys } from '@helpers/objects'
import toastr from '@modules/toastr'
import ENV from '@constants/env'

import Checklist from './_inspection-checklist.html'
import SampleCert from '@assets/images/examples/cert-of-insurance.jpg'

const FIVE_MB = 5 * 1024 * 1024

export default class VehicleLicence extends React.Component {
  constructor(props) {
    super(props)
    this.checklist = React.createRef()
  }

  state = {
    blockcert: null,
    aware: true,
    declined: true,
  }

  async componentDidMount() {
    Object.assign(this, extractKeys($app, 'axios', 'current_user:user'))

    // const cert = await this.getCertificate()
    // cert && this.setState({ blockcert: cert })
  }

  resource = '/individuals'
  form_key = 'individual'
  c_key = 'vehicle_license'
  u_key = 'individual'

  skip_location = true
  has_appointment = true
  refresh_every = 30000

  before_apply = () => {
    this.setState({
      aware: false,
      declined: false,
    })

    setTimeout(() => {
      if (this.checklist.current) {
        this.checklist.current.scrollTop = 0
      }
    }, 200)
  }

  fee = async ({ plate_number }) => {
    // if (retry) return 25

    const { subclass, licenceDueDate } = await this.getVehicleDetails(
      plate_number
    )

    const calc = (rate, rateChanged = false) => {
      const today = moment()
      const expDate = moment(licenceDueDate).endOf('month')

      if (rateChanged && expDate.year() <= 2023) {
        let total = rate
        const _2023 = expDate.clone().year(2023)
  
        // fees for B & C Class Vehicles were both increased by $5
        const yearsBeforeChange = _2023.diff(expDate, 'years')
        if (yearsBeforeChange > 0) total += ((rate - 5) * yearsBeforeChange)
        
        const yearsAfterChange = today.diff(_2023, 'years')
        if (yearsAfterChange > 0) total += (rate * yearsAfterChange)
        
        return total
      }

      const diff = today.diff(expDate, 'years')
      return diff > 0 ? rate * (diff + 1) : rate
    }

    switch (subclass.match(/\d+/)[0]) {
      // Motorcycle Engine Sizes
      case '50':
        return calc(75)
      case '126':
        return calc(85)
      case '351':
        return calc(95)
      case '751':
        return calc(105)

      // Vehicle Weights
      case '0':
        return calc(195)
      case '5001':
        return calc(645, true)
      case '15001':
        return calc(795, true)
      default:
        return calc(10)
    }
  }

  getCertificate = async () => {
    try {
      const { data } = await this.axios.get('/uuids/find_by_type', {
        params: {
          'uuid[cert_type]': 'vehicle-insurance',
          'uuid[nib]': this.user.NIB,
        },
      })

      return data
    } catch (err) {
      console.error(err)
    }
  }

  getVehicleDetails = async (plate, refetch = false) => {
    if (refetch || !this.vehicle) {
      try {
        const { data, status } = await this.axios.get(
          '/individuals/fetch_rtd/' + plate,
          {
            validateStatus: status =>
              (status >= 200 && status < 300) || status == 404,
          }
        )

        if (status == 404) {
          this.vehicle = null
          return { error: 'Plate Number Not Found' }
        }

        this.vehicle = data['RTD Data']
      } catch (err) {
        this.vehicle = null
        console.error(err)

        return { error: 'Unable to fetch vehicle details' }
      }
    }

    return this.vehicle
  }

  getLicenceDetails = async (refetch = false) => {
    try {
      if (refetch || !this.licence) {
        const { data } = await this.axios.get(
          '/drivers_licenses/fetch_rtd/' + this.user.NIB
        )
        this.licence = data['RTD Data']
      }

      return this.licence
    } catch (err) {
      console.error(err)
      return {
        error: 'Unable to retrieve licence details from the RTD database',
      }
    }
  }

  validate = async form => {
    const obj = {}

    // if (this.state.blockcert) {
    //   obj.blockcert_insurance_id = this.state.blockcert.id
    // }

    // if (form.insurance_uuid) {
    //   const url = 'http://localhost:8888/certs/' + form.insurance_uuid

    //   try {
    //     const { data } = await $app.axios.get(url)

    //     if (data.revocation) {
    //       await Swal.fire(
    //         'Certificate Revoked',
    //         'This certificate has been revoked and is unable to be used.',
    //         'warning'
    //       )

    //       return false
    //     }
    //   } catch (err) {

    //   }
    // }

    const { ownerTMN, licenceDueDate, ...vehicle } =
      await this.getVehicleDetails(form.plate_number, true)
    const { TMN, Expiry, Type, ...licence } = await this.getLicenceDetails(true)

    if (licence.error || vehicle.error) return vehicle.error || licence.error

    if (ownerTMN == TMN) {
      if (
        moment().endOf('month').diff(licenceDueDate, 'milliseconds', true) < 0
      ) {
        toastr.warning(
          'Unable To Continue',
          'Your licence has not yet expired and is not expiring this month.'
        )
        return false
      }

      return {
        driver_licence: TMN,
        // insurance: form.insurance_digital || form.insurance,
        ...obj,
        ...extractKeys(
          vehicle,
          'year:year_of_vehicle',
          'make:make_of_vehicle',
          'model:model_of_vehicle',
          'vin:vin_of_vehicle'
        ),
      }
    } else return 'Plate number not assigned to licence # provided'
  }

  // The fields that will make up the form
  foremost = {
    name: 'blue_licence',
    label: "Do you hold a blue Driver's Licence?",
    type: 'select:bool',
    test: v =>
      v ||
      'Sorry, but this service is only available to citizens that currently hold a blue licence',
  }

  columns = () => [
    {
      name: 'Plate#',
      selector: 'plate_number',
      width: '96px',
    },
    {
      name: 'Inspection',
      selector: 'verdict',
      width: '128px',
      format: r =>
        typeof r.verdict == 'boolean'
          ? r.verdict
            ? 'PASSED'
            : 'FAILED'
          : 'PENDING',
    },
  ]

  fields = form => {
    const { blockcert } = this.state

    return [
      {
        heading: (
          <small style={{ color: 'green' }}>
            Using your blockcert certificate that was issued{' '}
            {moment(blockcert?.created_at).format('YYYY-MM-DD')}
          </small>
        ),
        hide: !blockcert,
        view: { hide: true },
      },
      {
        name: 'plate_number',
        format: v => v.toUpperCase(),
      },
      // {
      //   name: 'digital_cert',
      //   label: 'Do you want to use a Digital Certificate of Insurance?',
      //   options: [
      //     { label: 'No', value: false },
      //     { label: 'Yes, via PDF', value: 'upload' },
      //     { label: 'Yes, via UUID', value: 'link' },
      //   ]
      // },
      // {
      //   name: 'insurance_digital',
      //   type: 'file:pdf',
      //   hide: form.digital_cert !== 'upload',
      //   label: 'PDF of Digital Insurance Cetificate',
      // },
      // {
      //   name: 'insurance_uuid',
      //   hide: form.digital_cert !== 'link',
      //   label: 'UUID of Digital Insurance Certificate',
      // },
      {
        name: 'insurance_policy_provider',
        label: 'Insurance Company',
        // hide: !!blockcert,
        hide: form.digital_cert,
        view: {
          hide: form.blockcert_insurance_id,
        },
      },
      {
        name: 'insurance_policy_number',
        label: 'Policy Number',
        // hide: !!blockcert,
        hide: form.digital_cert,
        view: {
          hide: form.blockcert_insurance_id,
        },
      },
      {
        name: 'insurance_policy_start',
        label: 'Policy Start Date',
        type: 'date',
        showYearDropdown: true,
        // hide: !!blockcert,
        hide: form.digital_cert,
        view: {
          hide: form.blockcert_insurance_id,
        },
      },
      {
        name: 'insurance_policy_end',
        label: 'Policy End Date',
        type: 'date',
        // hide: !!blockcert,
        hide: form.digital_cert,
        showYearDropdown: true,
        view: {
          hide: form.blockcert_insurance_id,
        },
      },
      {
        key: 'image.2',
        name: 'vehicle_lic_disc',
        label: 'Licence Disc',
        type: 'file:image',
        hint: "A clear picture of your vehicle's disc. <strong>Do not remove from windshield</strong>. (Max Size: 5MB, Accepts JPG/PNG)",
        validators: ['required', 'isFile', 'maxFileSize:' + FIVE_MB],
        errorMessages: [
          'Required',
          'A file is required',
          `File size must not exceed 5MB`,
        ],
      },
      {
        key: 'image.0',
        name: 'insurance',
        label: 'Valid Insurance Policy',
        type: 'file:image',
        hint: `
          The insurance document whose heading is "Certificate of Insurance". (Max Size: 5MB, Accepts JPG/PNG)<br/>
          <img class='w-64' src='${SampleCert}'/>
        `,
        validators: ['required', 'isFile', 'maxFileSize:' + FIVE_MB],
        errorMessages: [
          'Required',
          'A file is required',
          `File size must not exceed 5MB`,
        ],
        // hide: !!blockcert,
        hide: form.digital_cert,
        view: {
          hide: form.blockcert_insurance_id,
        },
      },
      {
        name: 'insurance_acknowledgement',
        type: 'checkbox',
        save: false,
        label: `I acknowledge that the original certificate of motor insurance must be presented at the time of the inspection.`,
      },
      {
        name: 'blockcert_insurance',
        hide: true,
        view: {
          label: 'Insurance Certificate',
          value: () =>
            `href::${ENV.BLOCKCERTS_SERVER}/verify-cert.html?uuid=${form.uuid}&company=${form.company}::Verify Certificate`,
          hide: !form.blockcert_insurance_id,
        },
      },
    ]
  }

  // Fields that aren't on the form
  // But to be added to the submission view
  hidden_fields = form => [
    '::Inspection Details',
    {
      name: 'verdict',
      view: {
        value: v =>
          v === null ? 'AWAITING INSPECTION' : v ? 'PASSED' : 'FAILED',
      },
    },
    {
      name: 'inspected_by',
      view: {
        hide: !form.inspector,
        value: () =>
          `${form.inspector.first_name} ${form.inspector.last_name}`.capitalize(),
      },
    },
    {
      name: 'inspection_date',
      view: {
        hide: form.verdict === null,
        value: () => moment(form.rear_side_updated_at).format('Do MMMM, YYYY'),
      },
    },
  ]

  render = () => (
    <React.Fragment>
      <Wizard {...SERVICE_PROPS(this)} />
      <Modal
        open={!(this.state.aware || this.state.declined)}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
      >
        <article className='max-w-3xl w-full'>
          <div className='modal-header'>
            <h5>Before You Apply...</h5>
          </div>
          <div
            className='modal-body overflow-y-scroll'
            style={{ maxHeight: '50vh' }}
            ref={this.checklist}
          >
            <div dangerouslySetInnerHTML={{ __html: Checklist }} />
            <div className='flex flex-col-reverse md:flex-row justify-center items-center mt-8'>
              <button
                className='btn bg-red-600 text-white md:mr-4'
                onClick={() => {
                  this.setState({ declined: true })
                  this.props.history.goBack()
                }}
              >
                My Vehicle Is Not Ready
              </button>
              <br className='md:hidden' />
              <button
                className='btn custom-btn'
                onClick={() => this.setState({ aware: true })}
              >
                My Vehicle Is In Working Order
              </button>
            </div>
          </div>
        </article>
      </Modal>
    </React.Fragment>
  )
}
