import React from "react";
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
// import { fullName } from "@modules/form-wizard/utils";
import { getUploadPath } from "@modules/form-wizard/utils";


const FormView = ({ methods, passedProps }) => {
  const { service, formSelected, } = passedProps;
  const { setFormSelected, approve, deny, defer, change_programme, setActive, writeNote } = methods;

  const populateUploads = (certsOnly = false) => (
    formSelected.image.map((data, index) => {
      const dataSplit = data.split('/');
      const isMissing = dataSplit[1] != 'system';
      let uploadName = isMissing ? dataSplit[1] : dataSplit[3];
      uploadName = uploadName.replaceAll('_', ' ');
      uploadName = uploadName.slice(0, -1)

      if(certsOnly && uploadName != 'pdf upload'){return null}
      if(!certsOnly && uploadName == 'pdf upload'){return null}
      if(uploadName == 'pdf upload'){ uploadName = 'view certificate'; }

      if(isMissing){ return null; }

      return (
        <div key={index} className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub" style={{textTransform: 'capitalize'}}>{uploadName}</h5>
          <p>
            {isMissing ? <span>N/A</span> :
            <a href={getUploadPath(data, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>}
          </p>
        </div>
      );
    }));

    const populateEmployeeDetails = () => {
      const data = JSON.parse(formSelected['employee_details']);
        // const allUploads = formSelected.additional_images.filter(x => x.title.includes(data.name.toLowerCase()))
  
      return (
        <div data-row="true" className="row py-2">
          <div className="info-invoice col-md-6 col-lg-4">
            <h5 className="sub">Name</h5>
            <p style={{textTransform: 'capitalize'}}>{`${data.first_name} ${data.last_name}`}</p>
          </div>
          <div className="info-invoice col-md-6 col-lg-4">
            <h5 className="sub">NIB #</h5>
            <p style={{textTransform: 'capitalize'}}>{data.nib}</p>
          </div>
          <div className="info-invoice col-md-6 col-lg-4">
            <h5 className="sub">Position</h5>
            <p style={{textTransform: 'capitalize'}}>{data.position}</p>
          </div>
          <div className="info-invoice col-md-6 col-lg-4">
              <h5 className="sub">Email</h5>
              <p>{data.email}</p>
            </div>
          {/* {allUploads.map((x, index) => (
            <div key={index} className="infor-invoice col-md-6 col-lg-4">
              <h5 className="sub" style={{textTransform: 'capitalize'}}>{x.desc}</h5>
              <p>
                <a href={getUploadPath(x.url, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>
              </p>
            </div>
          ))} */}
        </div>
      );
    };

  const bahamaHostCspRender = () => {
    return (
      <div className="row">
      <div className="col-md-12">
        <div className="separator-solid mt-0"></div>
        <div className="invoice-detail">
          <div className="invoice-top">
            <h3 className="title"><strong>Details</strong></h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Session Number</h5>
              <p>{formSelected.session_numb ? formSelected.session_numb : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Venue</h5>
              <p style={{textTransform: 'capitalize'}}>{formSelected.venue ? formSelected.venue : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Start Date of Session</h5>
              <p>{formSelected.session_date ? formSelected.session_date : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Reason for Application</h5>
              <p style={{textTransform: 'capitalize'}}>{formSelected.reason ? formSelected.reason : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Time of Session</h5>
              <p>{formSelected.time_of_session ? formSelected.time_of_session : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Program Type</h5>
              <p style={{textTransform: 'capitalize'}}>{formSelected.industry_type ? formSelected.industry_type : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Island Location</h5>
              <p style={{textTransform: 'capitalize'}}>{formSelected.island_location ? formSelected.island_location : 'N/A'}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Island Sub Location</h5>
              <p style={{textTransform: 'capitalize'}}>{formSelected.island_sub_location ? formSelected.island_sub_location : 'N/A'}</p>
          </div>
          {formSelected.employee_details && (
            <div className="col-md-12 info-invoice">
              <h5 className="sub">Employee Details</h5>
              <div className="form-wizard-form-array">
                {populateEmployeeDetails()}
              </div>
            </div>
          )}
          <div className="col-md-12 info-invoice">
            <h5 className="sub text-muted">Uploads</h5>
          </div>
          {populateUploads()}
        </div>
        <div className="separator-solid mb-3">
        </div>
      </div>
    </div>
    );
  };

  return (
    <div>
      {/* Service Record View Page */}
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-9">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="page-pretitle text-primary font-semibold text-lg">
                {formSelected.payment_status ? 'PAID' : 'UNPAID'}&nbsp; | {formSelected.application_decision}
              </h6>
              <h4 className="page-title">{service.serviceType(formSelected)}</h4>
            </div>
            <div className="col-auto">
              <button className="flex items-center btn custom-btn btn-round ml-auto" onClick={() => setFormSelected(null)}>
              <span>Back</span>
              </button>
            </div>
            <div className="col-auto">
              <button className="flex items-center btn btn-round ml-auto">
                <Menu
                menuButton={
                <div className='btn custom-btn'>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                  </svg>
                  <span data-action>Actions</span>
                </div>
                }
                transition
                portal
                arrow
                >
                <MenuItem onClick={approve(formSelected)}>
                  Approve
                </MenuItem>
                <MenuItem onClick={deny(formSelected)}>
                  Deny
                </MenuItem>
                <MenuItem onClick={defer(formSelected)}>
                  Defer
                </MenuItem>
                <MenuItem onClick={change_programme(formSelected)}>
                  Change Programme Type
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setActive(formSelected)}>
                  View Notes
                </MenuItem>
                <MenuItem onClick={() => writeNote(formSelected)}>
                  Create Note
                </MenuItem>
                </Menu>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          </div>
          <div className="page-divider">
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-invoice">
                <div className="card-header">
                  <div className="invoice-header">
                    <div className="invoice-logo">
                      <img src="/static/media/bs-coat-of-arms.bb54f8a8.png" alt="Bahamas Coat of Arms"/>
                    </div>
                    <h3 className="invoice-title lg:text-right">Application</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="separator-solid"></div>
                  <div className="row">
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application ID</h5>
                      <p>{formSelected.form_num}</p>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application Date</h5>
                      <p>{formSelected.created_at.slice(0, formSelected.created_at.indexOf("T"))}</p>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application Status</h5>
                      <p style={{textTransform: 'uppercase'}}>{formSelected.application_decision}</p>
                    </div>
                  </div>
                  <div className="separator-solid"></div>
                  <div className="row">
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Applicant Name</h5>
                      <div>{`${formSelected.user.first_name.capitalize()} ${formSelected.user.middle_name || ''} ${formSelected.user.last_name.capitalize()}`}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">NIB #</h5>
                      <div>{formSelected.user.NIB}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">D.O.B</h5>
                      <div>{formSelected.user.date_of_birth}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Email</h5>
                      <div>{formSelected.user.email}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Mobile Number</h5>
                      <div>{formSelected.user?.additional_phone_numb || formSelected.phone_number}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Address</h5>
                      <div>
                        <address>{formSelected.street_address}<br/>{formSelected.po_box}<br/>{formSelected.user?.home_address?.island}, Bahamas</address>
                      </div>
                    </div>
                  </div>
                  {service?.key == 'BAHAMAHOST_CSP' && bahamaHostCspRender()}
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-sm-7 col-md-5 mb-3 mb-md-0 transfer-to"></div>
                    <div className="col-sm-5 col-md-7 transfer-total">
                      <h5 className="sub">Total Amount</h5>
                      <div className="price">${formSelected.payment_amount}</div>
                      <span>Taxes Included</span>
                    </div>
                  </div>
                  <div className="separator-solid"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormView;
