import React from 'react'
import DataTable from 'react-data-table-component'

import toastr from '@modules/toastr'

export default class PollingTable extends React.Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, this.props.interval)
    this.refresh()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  refresh = async () => {
    let data
    const { endpoint, service_type, filter, transform } = this.props

    try {
      if (service_type) {
        const res = await $app.axios.get('/services/approved_forms', {
          params: { service_type },
        })

        data = res.data.approved_forms
      } else if (endpoint) {
        const res = await $app.axios.get(endpoint, {
          params: {
            per_page: 9999,
          }
        })

        data = res.data?.records
          ? [...(res.data.records_by_proxy ?? []), ...res.data.records]
          : res.data
      }

      if (typeof filter == 'function') {
        data = data.filter(filter)
      }
      if (typeof filter == 'string') {
        data = data.filter(d => d[filter])
      }

      if (transform) {
        data = await Promise.all(data.map(transform))
      }

      this.setState({ data })
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to fetch data')
      clearTimeout(this.timer)
    }
  }

  render = () => {
    const { state, props } = this

    const tprops = {
      columns: props.columns,
      data: state.data,

      noHeader: true,
      defaultSortField: 'created_at',
      defaultSortAsc: false,

      onRowClicked: props.onRowClicked,
    }

    return (
      <div className='content'>
        <div className='page-inner'>
          <div className='card card-round'>
            <div className='card-header'>
              <h4 style={{ margin: 0 }}>{props.title}</h4>
            </div>
            <div className='card-body'>
              <div className='table-responsive form-records-table clickable-rows'>
                <DataTable {...tprops} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
