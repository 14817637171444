import { useEffect, useReducer, useState, useRef, Fragment, useMemo } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import { fullName } from '@modules/form-wizard/utils'
import mapServiceName from '@constants/maps.services'
import getAgencyNames from '@constants/maps.agencies'
import { extractKeys, listKeys } from '@helpers/objects'
import { useCollections } from './contexts'
import { debounce } from '@helpers/utils'

const BatchesList = ({ history, match }) => {
  const { openUser, openHistory } = useCollections()
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const params = useRef({ page: 1, per_page: 5 })
  const top = useRef()
  const aborter = useRef()
  const profiles = useRef({})

  const [docs, setDocuments] = useReducer((state, payload) => {
    if (payload.reset) return payload.batches
    return [...state, ...payload]
  }, [])

  useEffect(() => {
    aborter.current?.abort()
    if (match.params.agency) fetchBatches(true)
  }, [match.params?.agency])

  const changeAgency = ev => {
    history.replace(`/collections/batches/${ev.target.value}`)
  }

  const fetchBatchesInBackground = () => fetchBatches(false, true)
  // const scrollToTop = () => top.current.scrollIntoView(true)

  const getUser = async (id, data) => {
    if (!id) return 'N/A'
    
    if (profiles.current[id]) {
      return profiles.current[id]
    }
  
    // const { data } = await $app.axios.get('/users/' + id)
    const name = fullName(data, false).trim()

    profiles.current[id] = name
    return name
  }

  const fetchBatches = async (reset = false, background = false) => {
    if (reset === true) {
      params.current = { page: 1, per_page: 5 }
    }

    if (params.current.page > params.current.max_page) {
      console.log(params.current.page, params.current.max_page)
      if (background) {
        toastr.info('Batches Loaded', 'Background loading completed')
      } else {
        toastr.warning('No more delivery batches from this agency.')
      }

      return
    } else if (!reset) params.current.page++

    const documents = []
    aborter.current = new AbortController()
    !background && setLoading(true)
    
    try {
      const { data } = await $app.axios.get('/deliveries', {
        signal: aborter.current.signal,
        params: {
          ...params.current,
          agency: match.params.agency,
        }
      })
  
      for (let batch of data.records) {
        const parent = {
          agency: getAgencyNames(batch.services[0]),
          courier: await getUser(batch.courier_id, batch.courier),
          officer: fullName(batch.officer, false),
          ...extractKeys(batch,
            'id',
            'collected',
            'rf_pickup',
            'delivered',
            'qr_code'
          )
        }
  
        for (let { service_type, forms } of batch.service_forms) {
          const service_name = mapServiceName(service_type)
  
          const next = forms.map(f => ({
            ...f,
            username: fullName(f.user, 'initial'),
            collectable: batch.delivered && f.delivery_status != 'not present',
            service_type,
            service_name,
            batch: parent,
          }))
        
          documents.push(...next)
        }
      }
  
      params.current.max_page = data.max_page
      reset ? setDocuments({ reset, batches: documents }) : setDocuments(documents)
    } catch (err) {
      if (!reset) params.current.page--
    } finally {
      aborter.current = null

      if (background) {
        toastr.success('Batches Fetched', 'Additional batches loaded')
        await fetchBatches(false, true)
      } else {
        setLoading(false)
      }
    }
  }

  const cols = useMemo(() => {
    return [
      {
        name: 'Applicant',
        selector: 'username',
        sortable: true,
        cell: row => (
          <span onClick={() => openUser(row)} data-action>
            {row.username}
          </span>
        ),
      },
      {
        name: 'Proxy',
        selector: 'proxy',
        sortable: true,
        cell: row => row.proxy ? (
          <span onClick={() => openUser(row, true)} data-action>
            {fullName(row.proxy, 'initial')}
          </span>
        ) : 'N/A'
      },
      {
        name: 'Agency',
        selector: row => row.batch.agency,
        omit: true,
      },
      {
        name: 'Service',
        selector: row => row.service_name,
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'delivery_status',
        sortable: true,
        cell: r => {
          let status
          switch (r.delivery_status) {
            case 'pending':
              status = 'In Transit'
              break
            default:
              status = r.delivery_status?.capitalize()
              break
          }
  
          return (
            <Fragment>
              <span onClick={() => openHistory(r)} data-action>
                {status}
              </span>
            </Fragment>
          )
        },
      },
      {
        name: 'Last Update',
        sortable: true,
        selector: row => moment(row.updated_at).format('Do MMM @ hh:mm A'),
        // sortFunction: (a, b) => new Date(a.last_status.created_at).getTime() > new Date(b.last_status.created_at).getTime(),
      },
    ]
  }, [])

  const matched = useMemo(() => {
    if (!docs?.length) return []
    if (!query) return docs

    const rx = new RegExp(query, 'i')

    const searchable = doc => console.log(doc) || [
      `${doc.user.first_name} ${doc.user.last_name}`,
      ...listKeys(doc, 'username', 'updated_at'),
      ...(doc.proxy ? [fullName(doc.proxy, false)] : []),
      // ...listKeys(doc.batch, 'courier', 'officer'),
    ]

    return docs.filter(r => searchable(r)
      .filter(s => typeof s == 'boolean' || s?.trim())
      .some(str => str === true || rx.test(str))
    )
  }, [docs, query])

  return (
    <section className='container'>
      <header className='flex items-center justify-between' ref={top}>
        <aside className='inline-flex flex-col lg:flex-row items-center lg:items-stretch w-full lg:w-auto space-x-8 mb-4 bg-white p-3 rounded-lg shadow'>
          <label className='flex items-center mb-0'>
            <span className='font-semibold w-20 pr-8'>Agency</span>
            <select
              className='form-control'
              value={match.params.agency}
              onChange={changeAgency}
            >
              <option value =''>Please Select An Agency</option>
              <option value ='RBPF'>Police Force</option>
              <option value ='RGD'>Registrar General</option>
              <option value ='RTD'>Road Traffic Det.</option>
            </select>
          </label>
        </aside>

        <aside className='inline-flex flex-col lg:flex-row items-center w-full lg:w-auto space-x-8 mb-4 bg-white p-3 rounded-lg shadow'>
          <label className='flex items-center mb-0'>
            <span className='font-semibold w-20 pr-8'>Search</span>
            <input
              className='form-control'
              onChange={debounce(ev => setQuery(ev.target.value), 150)}
            />
          </label>
          <span
            className='text-blue-700 cursor-pointer'
            onClick={fetchBatchesInBackground}
            data-hide={params.current.page >= params.current.max_page}
          >
            Fetch Batches In Background
          </span>
        </aside>
      </header>
      {match.params.agency ? (
        <article className='card form-records-table'>
          <Loader loading={loading} />
          <div className='card-body'>
            <DataTable
              columns={cols}
              data={matched}
              striped
              noHeader
            />
          </div>
          {params.current.page < params.current.max_page ? (
            <footer className='card-footer'>
              <button className='load-more' onClick={ev => fetchBatches()}>
                Load More
              </button>
            </footer>
          ) : null}
        </article>
      ) : null}
    </section>
  )
}

export default BatchesList