import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class CheckboxField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    width: '100%',
    fontWeight: 600,
    paddingLeft: '2rem',
    maxWidth: 640,
  }

  errorText = () =>
    this.state.isValid ? null : (
      <div style={this.errorStyles}>{this.getErrorMessage()}</div>
    )

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      withRequiredValidator,
      label,
      ...rest
    } = this.props

    if (!rest.className) rest.className = 'form-control'
    if (!this.state.isValid) rest.className += ' input_error'

    const __html =
      label +
      (rest.required !== false ? `<span class='required-label'>*</span>` : '')

    return (
      <label className='form-check-label flex flex-wrap items-center'>
        <input type='checkbox' {...rest} />
        <span
          className='form-check-sign'
          dangerouslySetInnerHTML={{ __html }}
        />
        {this.errorText()}
      </label>
    )
  }
}

export default CheckboxField
