import React from 'react'
import Modal from 'react-responsive-modal'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys, withoutKeys } from '@helpers/objects'

import {
  ExemptionsForm,
  calculateExemptionToatal,
  ApprovalForm,
  VehicleForm,
} from './_exemptions-form'
// import redirectToCustoms from './_redirect'

export default class OverHillEconomic extends React.Component {
  state = {
    record: null,
    loading: false,
    picker: null,
  }

  resource = '/over_hill_economic_zones'
  form_key = 'over_hill_economic_zone'
  free = true

  // before_apply = redirectToCustoms
  skip_location = true
  hide_buttons = {
    status: true,
  }

  validate = form => {
    if (!form.exemption_category.size) {
      return 'No exemption categories were selected'
    }

    const exemptions = [
      'building_supplies_exemption_list',
      'machinery_exemption_list',
      'furniture_exemption_list',
      'electrical_exemption_list',
      'hardware_exemption_list',
      'plumbing_exemption_list',
      'appliances_exemption_list',
      'business_license_exemption_list',
      'real_property_tax_exemption_list',
    ]

    const transformed = exemptions.reduce((obj, prop) => {
      const ex = form[prop]
        ?.filter(row => row.item)
        ?.map(row => ({
          ...withoutKeys({ ...row }, 'tariff'),
          value: Number(row.value).toFixed(2),
          tariff_id: row.tariff?.id,
          tariff_code: row.tariff?.value,
        }))

      if (ex) obj[prop] = ex
      return obj
    }, {})

    return {
      ...transformed,
      total_value_of_exemption: exemptions
        .reduce((sum, prop) => sum + calculateExemptionToatal(form[prop]), 0)
        .toFixed(2),
    }
  }

  openPicker = (data, target) => {
    const options = data.map(d => ({
      label: `<span class='text-muted'>${d.value}</span><br/>${d.label}`,
      value: extractKeys(d, 'id', 'value'),
    }))

    this.setState({
      picker: {
        options,
        unskippable: true,
        heading: 'Please Select The Correct Tariff Code',
        target: target.replaceAll('::', '.').replace('item', 'tariff'),
        onSelect: () => this.setState({ picker: null }),
      },
    })
  }

  custom_action = {
    text: 'Approve Exemptions',
    icon: 'check-square',
    fn: record => this.setState({ record }),
  }

  close = () => this.setState({ record: null })

  update = status => {
    switch (status) {
      case 'SAVING':
        return this.setState({ record: null, loading: true })
      case 'DONE':
      case 'FAILED':
        return this.setState({ loading: false })
      default:
    }
  }

  getCategories = company_type =>
    company_type === 'individual'
      ? ['Building Supplies', 'Real Property Tax']
      : [
          'Building Supplies',
          'Commercial Vehicles',
          'Business Licence',
          'Real Property Tax',
        ]

  foremost = {
    name: 'live_over_hill',
    type: 'select:bool',
    label:
      'Have you resided in the zone for 12 years or more? If not, are you registering as a business?',
    long: true,
    test: v =>
      v ||
      'Sorry, this application is only available for businesses, or individuals that have resided in this zone for 12 years or more',
    hint: `
    <br/>
    <br/>
    <div class='flex flex-row items-center'>
      <a href="https://www.overthehillbahamas.org/media/page/1595352015_5f1723cf0f093_800.png" target= "_blank" rel="noopener noreferrer">(View Over the Hill Zone Map)</a>
    </div>
          `,
  }

  AltExemptionsForm = (table, viewMode) => ({
    columns: [
      {
        name: 'item',
        placeholder: 'Item Description...',
      },
      {
        name: 'qty',
        is: 'integer',
        placeholder: '0',
        width: viewMode ? 80 : 128,
      },
      {
        name: 'assessment_number',
        is: 'integer',
        width: 128,
        hide: viewMode,
      },
      {
        name: 'value',
        is: 'currency',
        placeholder: '0.00',
        width: 128,
      },
      ...(viewMode
        ? [
            { name: 'assessment_number', width: 128 },
            { name: 'status', width: 128 },
          ]
        : []),
    ],

    footer: (
      <div className='float-left ml-4'>
        <strong>Total: ${calculateExemptionToatal(table).toFixed(2)}</strong>
      </div>
    ),
  })

  BuildingSuppliesExemptionForm = (table, viewMode) => ({
    columns: [
      {
        name: 'item',
        placeholder: 'Item Description...',
      },
      {
        name: 'qty',
        is: 'integer',
        placeholder: '0',
        width: viewMode ? 80 : 128,
      },
      {
        name: 'value',
        is: 'currency',
        placeholder: '0.00',
        width: 128,
      },
    ],

    footer: (
      <div className='float-left ml-4'>
        <strong>Total: ${calculateExemptionToatal(table).toFixed(2)}</strong>
      </div>
    ),
  })

  fields = (form, viewMode) => {
    const {
      openPicker,
      getCategories,
      AltExemptionsForm,
      BuildingSuppliesExemptionForm,
    } = this
    const { is_citizen } = $app

    return [
      {
        name: 'client_type',
        label: 'Application For?',
        options: [
          { label: 'Individual', value: 'individual' },
          { label: 'Business', value: 'business' },
        ],
        view: {
          label: 'Application Type',
        },
      },
      {
        name: 'application_purpose',
        label: 'Purpose of Application',
        options: ['Reconstruction', 'Renovation', 'New Construction'],
        view: false,
      },
      {
        name: 'owner',
        label: 'Are you the owner of the property?',
        type: 'select:bool',
        hide: form.client_type != 'individual',
      },
      {
        name: 'company_name',
        label: 'Name of Company',
        hide: form.client_type != 'business',
      },
      {
        name: 'company_type_age',
        label: 'New or Existing Business',
        options: ['New', 'Existing'],
        hide: form.client_type != 'business',
      },
      {
        name: 'company_type',
        label: 'Company Type',
        options: [
          'Incorporated / Limited Company',
          'Sole Proprietorship',
          'Partnership',
          'Home Based',
        ],
        hide: form.client_type != 'business',
      },
      {
        name: 'trading_name',
        hide: form.company_type != 'Sole Proprietorship',
      },
      {
        name: 'bl_number',
        label: 'Business License Number',
        hide: form.client_type != 'business',
      },
      {
        name: 'tax_id_number',
        label: 'TIN number',
        hide: form.client_type != 'business',
      },
      {
        name: 'tax_comp_cert_expiry',
        label: 'Tax Compliance Certificate Expiry Date',
        type: 'date',
        showYearDropdown: true,
        hide: form.client_type != 'business',
      },
      {
        name: 'business_turnover',
        label: 'Sales/Business Turnover',
        hide: form.client_type != 'business',
        hint: 'Per Annum.',
        is: 'currency',
      },
      {
        name: 'years_of_business',
        label: 'Years of Business',
        type: 'integer',
        hide: form.client_type != 'business',
        hint: 'In Years',
        is: 'float',
      },
      {
        name: 'shareholders',
        label: 'Please list Shareholders/Directors',
        columns: [{ name: 'shareholders', width: 64 }],
        hide:
          form.company_type != 'Incorporated / Limited Company' &&
          form.company_type != 'Partnership',
      },
      {
        name: 'exemption_category',
        type: 'checklist',
        label: 'Exemption Types',
        options: getCategories(form.client_type),
        hide: !form.client_type,
      },

      '::Supporting Documents',
      {
        name: 'tax_comp_cert_upload',
        label: 'Tax Compliance Certificate',
        type: 'file:pdf',
        hide: form.client_type != 'business',
        key: 'image.1',
      },
      {
        name: 'business_license_upload',
        label: 'Business Licence',
        type: 'file:pdf',
        hide: form.client_type != 'business',
        key: 'image.6',
      },
      // {
      //   name: "site_plan_upload",
      //   label: "Min. Of Public Works Stamped Floor Plan",
      //   type: "file:pdf",
      //   key: 'image.3',
      // },
      // {
      //   name: "building_plan_upload",
      //   label: "Building Plan",
      //   type: "file:pdf",
      //   key: 'image.4',
      // },
      // {
      //   name: "occupancy_letter_upload",
      //   label: "Occupancy Certificate",
      //   type: "file:pdf",
      //   key: 'image.5',
      //   hide: !form.exemption_category?.has?.("Furniture"),
      // },
      // {
      //   name: "invoice_upload",
      //   label: "Invoice",
      //   type: "file:pdf",
      //   key: 'image.2',
      // },
      {
        name: 'conveyance_papers_upload',
        label: 'Conveyance Papers',
        type: 'file:pdf',
        hide: form.client_type != 'individual',
        key: 'image.7',
      },
      {
        name: 'approval_letter_upload',
        label: 'Copy of Approval Letter',
        type: 'file:pdf',
        hide: form.client_type != 'individual' || form.owner_bool,
        key: 'image.8',
      },
      {
        name: 'work_permit_upload',
        label: 'Work Permit',
        type: 'file:image',
        key: 'image.3',
        hide: is_citizen,
      },
      {
        name: 'incorporation_upload',
        label: 'Certificate of Incorporation',
        type: 'file:pdf',
        hide:
          form.client_type != 'business' ||
          form.company_type != 'Incorporated / Limited Company',
        key: 'image.5',
      },
      {
        name: 'investment_authority_upload',
        label: 'Letter from Investment Authority',
        type: 'file:pdf',
        hide: form.client_type != 'business' || is_citizen,
        key: 'image.4',
      },
      {
        name: 'invoice_upload',
        label: 'Invoice Upload',
        type: 'file:pdf',
        key: 'image.2',
        hide: !form.exemption_category?.has?.('Building Supplies'),
      },
      {
        heading: 'Exemption Details',
        hide: !form.client_type,
      },
      {
        name: 'building_supplies_exemption_list',
        label: 'Building Supplies',
        ...BuildingSuppliesExemptionForm(
          form.building_supplies_exemption_list,
          openPicker,
          viewMode
        ),
        hide: !form.exemption_category?.has?.('Building Supplies'),
      },
      {
        name: 'machinery_exemption_list',
        label: 'Commercial Vehicles',
        ...ExemptionsForm(form.machinery_exemption_list, openPicker, viewMode),
        columns: VehicleForm(viewMode),
        hide: !form.exemption_category?.has?.('Commercial Vehicles'),
      },
      {
        name: 'business_license_exemption_list',
        label: 'Business License',
        ...AltExemptionsForm(form.business_license_exemption_list, viewMode),
        hide: !form.exemption_category?.has?.('Business Licence'),
      },
      {
        name: 'solar_exemption_list',
        label: 'Real Property Tax',
        ...AltExemptionsForm(form.solar_exemption_list, viewMode),
        hide: !form.exemption_category?.has?.('Real Property Tax'),
      },
      '::SPACER',
      {
        name: 'penalty',
        type: 'checkbox',
        label: `
          Providing false or misleading information
          &mdash; or using exemptions for purposes other than applied for
          &mdash; will lead to prosecution and related fines and criminal penalties.
        `,
      },
    ]
  }

  hidden_fields = () => [
    {
      name: 'total_value_of_exemption',
      view: {
        label: 'Total Exemption Value',
        value: v => '$' + Number(v).toLocaleString(),
      },
    },
    {
      name: 'pdf_upload',
      type: 'file:image',
      label: 'Exemption Certificate',
      key: 'image.9',
    },
  ]

  render = () => {
    const { state, props, close, fields, resource, form_key, update } = this
    const { record } = state

    const fprops = {
      update,
      resource,
      close,
      form_key,
      location: props.location,
      history: props.history,
    }

    if (record)
      Object.assign(fprops, {
        record,
        fields: fields(record),
      })

    return (
      <React.Fragment>
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal open={!!record} onClose={close} center>
          <ApprovalForm {...fprops} />
        </Modal>
      </React.Fragment>
    )
  }
}
