import React from 'react'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

export default class TimberHarvestPermit extends React.Component {
  resource = '/timber_harvest_permit_small_scales'
  form_key = 'timber_harvest_permit_small_scale'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'Applicant first name',
        hide: form.i_am_applicant,
    },
    {
        name: 'm_name',
        label: 'Applicant middle name',
        hide: form.i_am_applicant,
    },
    {
        name: 'l_name',
        label: 'Applicant last name',
        hide: form.i_am_applicant,
    },
    {
        name: 'address',
        label: 'Permanent Street Address'
    },
    {
        name: 'telephone_phone',
        type: 'phone'
    },
    {
        name: 'fax',
        label: 'Fax No.',
        type: 'integer'
    },
    {
        name: 'email_address',
        type: 'email'
    },
    {
        name: 'location',
        type: 'textarea'
    },
    {
        name: 'nearest_settlement'
    },
    {
        name: 'island',
        options: islands
    },
    {
        name: 'specifics',
        label: 'Specifics of timber or non-timber produce to be removed',
        type: 'textarea'
    },
    {
        name: 'equipment_used',
        label: 'Equipment to be used to harvest produce',
        columns: ['equipment'],
        format: v=> v.equipment
    },
    {
        name: 'valid_permit',
        label: 'Are you a holder of a valid permit to use and/or transport a power saw?',
        type: 'select:bool'
    },
    {
        name: 'has_permit',
        label:'Please provide the permit number',
        hide: form.valid_permit != true
    },
    {
        name: 'no_permit',
        label: 'Date of issue',
        type: 'date',
        hide: form.valid_permit != false,
        showYearDropdown: true
    },
    {
        name: 'proposed_date',
        label: 'Proposed date of commencement of removal',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'completion_date',
        label: 'Proposed date of completion',
        type: 'date',
        showYearDropdown: true
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
