import React from 'react'
import { Link } from 'react-router-dom'

export default class SidePanel extends React.Component {
  user = JSON.parse(window.sessionStorage.getItem('user') || {})

  render () {
    const { match, total_count } = this.props
    const composeBtn = !match.url.match('compose-mail') && this.user.roles.includes('pilot') ? (
      <Link to="/message-center/compose-mail" className="btn custom-btn btn-block fw-mediumbold">Compose Email</Link>
    ) : null

    return (
      <div className="page-aside bg-grey1">
        <div className="aside-header">
          <div className="title">Service Center</div>
          <div className="description">Contact For Help</div>
          <a className="btn btn-primary toggle-email-nav" data-toggle="collapse" href="#email-app-nav" role="button" aria-expanded="false" aria-controls="email-nav">
            <span className="btn-label">
              <i className="fas fa-bars"></i>
            </span>
            Menu
          </a>
        </div>
        <div className="aside-nav collapse" id="email-app-nav">
          <ul className="nav">
            <li className={`${match.url.match("inbox") ? 'active' : ''}`}>
              <Link to={"/message-center/inbox"}>
                <i className="flaticon-inbox"></i> Inbox
                <span className="badge badge-primary float-right">{total_count}</span>
              </Link>
            </li>
          </ul>
    
          <div className="aside-compose">{composeBtn}</div>
        </div>
      </div> 
    )
  }
}
