import { Switch, Route } from 'react-router'

import SecurityLicenceRegistration from '../security-licence-new'
import SecurityLicenceRenewal from '../security-licence-renewal'
import SecurityLicenceAppeal from '../security-licence-appeal'
import TempSecurityLicence from '../security-licence-temp'
import BusinessSecurityLicence from '../security-licence-business'

const subroute = (Component, service) => router => (
  <Component {...router} service={$app.getService(service)} />
)

const NationalSecurityMinisterView = (props = {}) => {
  const BASE_URL = props.match.url
  const sublink = path => (BASE_URL + '/' + path).replace('//', '/')

  return (
    <section className='lg:flex lg:items-start'>


      {/* Service Components */}
      <Switch>
        {/* New */}
        <Route
          path={sublink('security-new')}
          render={subroute(
            SecurityLicenceRegistration,
            'security guards license'
          )}
        />
        <Route
          path={sublink('inquiry-new')}
          render={subroute(
            SecurityLicenceRegistration,
            'inquiry agents license'
          )}
        />

        {/* Renewal */}
        <Route
          path={sublink('security-renewal')}
          render={subroute(
            SecurityLicenceRenewal,
            'security guards license renewals'
          )}
        />
        <Route
          path={sublink('inquiry-renewal')}
          render={subroute(
            SecurityLicenceRenewal,
            'inquiry agents license renewals'
          )}
        />

        {/* Appeal */}
        <Route
          path={sublink('security-appeal')}
          render={subroute(
            SecurityLicenceAppeal,
            'security guards license appeals'
          )}
        />
        <Route
          path={sublink('inquiry-appeal')}
          render={subroute(
            SecurityLicenceAppeal,
            'inquiry agents license appeals'
          )}
        />

        {/* Temp */}
        <Route
          path={sublink('security-temp')}
          render={subroute(
            TempSecurityLicence,
            'security guards license temporary'
          )}
        />
        <Route
          path={sublink('inquiry-temp')}
          render={subroute(
            TempSecurityLicence,
            'inquiry agents license temporary'
          )}
        />

        {/* New - Business */}
        <Route
          path={sublink('security-new-business')}
          render={subroute(
            BusinessSecurityLicence,
            'security and inquiry firms license'
          )}
        />
        <Route
          path={sublink('inquiry-new-business')}
          render={subroute(
            BusinessSecurityLicence,
            'security and inquiry firms license'
          )}
        />

        {/* Renewal - Business */}
        <Route
          path={sublink('security-business-renewal')}
          render={subroute(
            BusinessSecurityLicence,
            'security and inquiry firms license'
          )}
        />
        <Route
          path={sublink('inquiry-business-renewal')}
          render={subroute(
            BusinessSecurityLicence,
            'security and inquiry firms license'
          )}
        />
      </Switch>


    </section>
  )
}

export default NationalSecurityMinisterView
