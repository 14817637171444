import React from 'react'
import islands from '@constants/islands'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import generateRange from '@helpers/range'
import { BGCSES, BJCS } from '@constants/national-exams'
//import nationalities from '@constants/nationalities'

const currentYear = new Date().getFullYear()

export default class FreshStartProgramme extends React.Component {
  resource = '/youth_fresh_starts'
  form_key = 'youth_fresh_start'
  defaults = {}

  // Delete lines as needed
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'made_aware_by',
      label: 'How did you hear about the Program?',
      datalist: [
        'WhatsApp',
        'Social Media',
        'Newspaper',
        'Radio',
        'Word of Mouth',
      ],
    },
    /*
    `::Child's Details`,
    {
      name: 'youth_first_name',
      label: 'First Name'
    },
    {
      name: 'youth_middle_name',
      label: 'Middle Name'
    },
    {
      name: 'youth_last_name',
      label: 'Last Name'
    },*/
    '::Address',
    {
      name: 'street_name',
      label: 'Street Address',
    },
    {
      name: 'subdivision',
      label: 'Subdivision',
    },
    {
      name: 'house_numb',
      label: 'House #',
    },
    "::Contacts",
    {
      name: 'home_phone',
      label: 'Home Phone No.',
      type: 'phone',
    },
    {
      name: 'cell_phone',
      label: 'Cell Phone No.',
      type: 'phone',
    },
    {
      name: 'email',
      type: 'email',
    },
    "::Additional Info",
    {
      name: 'island_of_residence',
      options: islands,
    },
    {
      name: 'marital_status',
      options: [
        'Single',
        'Engaged',
        'Married',
        'Separated',
      ]
    },
    '::Household',
    {
      name: 'numb_of_children',
      label: 'How many children do you have?',
      is: 'integer',
    },
    {
      name: 'numb_of_workers',
      label: 'How many people work in your household?',
      is: 'integer',
    },
    {
      name: 'reliable_childcare',
      type: 'select:bool',
      label: 'Do you have reliable childcare, given you are accepted?'
    },
    '::SPACER',
    {
      name: 'other_youth_programs',
      type: 'select:bool',
      label: `
        Have you participated in any other Ministry of
        Youth Sports & Culture Programs?
      `
    },
    {
      name: 'youth_programs_list',
      hide: !form.other_youth_programs,
      hint: 'Selecta all that apply',
      multi: true,
      options: [
        'Youth Leader Certification',
        'Summer Program',
        'CEBO',
        'Summer Apprentice',
        'Job Ready',
        'Self Starter',
        'Other'
      ]
    },
    {
      name: 'other_programs',
      hide: form.youth_programs_list !='Other',
    },
    {
      name: 'criminal_charge',
      type: 'select:bool',
      label: 'Have you ever been charged with a crime?',
    },
    {
      name: 'criminal_details',
      type: 'textarea',
      hide: !form.criminal_charge,
      label: 'Please explain',
    },
    {
      name: 'convicted',
      type: 'select:bool',
      label: 'Have you ever been convicted of a crime?',
    },
    {
      name: 'convicted_details',
      type: 'textarea',
      hide: !form.convicted,
      label: 'Please explain',
    },
    {
      name: 'other_job_programs',
      type: 'select:bool',
      label: 'Have you particiated in any other job training programs?',
    },
    {
      name: 'job_programs_list',
      hide: !form.other_job_programs,
      label: 'Other job programs',
      columns: ['program'],
      format: v => v.program,
    },
    {
      name: 'medical_issues',
      type: 'select:bool',
      label: `
        Do you have any medical conditions (inluding pregnancy) that
        may impact your ability to complete this program?
      `,
    },
    {
      name: 'medical_details_list',
      hide: form.medical_issues != true,
      label: 'Medical conditions',
      columns: ['condition'],
      format: v => v.condition
    },
    {
      name: 'other_conditions',
      type: 'textarea',
      required: false,
      label: `
        If you have any other conditions/situations that may affect your
        ability to complete the Program, explain
      `,
      hide: form.medical_issues != true,
    }, 
    /*{
      name: 'youth_date_of_birth',
      type: 'date',
      label: 'Date of Birth',
      showYearDropdown: true,
    },
    {
      name: 'youth_nationality',
      otpions: nationalities,
    },
    {
      name: 'youth_sex',
      label: 'Sex',
      options: ['Male', 'Female']
    },
    {
      name: 'youth_phone_numb',
      type: 'phone',
      label: 'Primary Contact #',
      required: false,
    },
    {
      name: 'youth_home_numb',
      type: 'phone',
      label: 'Home Contact #',
      required: false,
    },
    {
      name: 'youth_po_box',
      label: 'P. O. Box',
      required: false
    },
    {
      name: 'youth_email',
      label: 'Email Address',
      type: 'email',
    },
    {
      name: 'youth_relationship',
      label: 'Relationship To You',
      datalist: [
        'Son',
        'Daughter',
        'Niece',
        'Nephew',
        'Cousin',
        'Grandson',
        'Granddaughter',
      ]
    }, */
    "::Education & Training",
    {
      name: 'schools_attended_list',
      label: 'Schools attended (Most recent first)',
      columns: ['school'],
      format: v => v.school,
    },
    {
      name: 'grade_level_completed',
      label: 'Highest grade completed',
      options: generateRange(1, 12).reverse().map(n => ({
        label: n,
        value: n
      }))
    },
    {
      name: 'year_left_school',
      label: 'Year left school',
      options: generateRange(1960, currentYear).map(n => String(n)).reverse()
    },
    {
      name: 'bjcs_passed_list',
      label: `BJC's passed`,
      multi: true,
      options: BJCS,
      hide: form.grade_level_completed < 7
    },
    {
      name: 'bgcses_passed_list',
      label: `BGCSE's passed`,
      multi: true,
      options: BGCSES,
      hide: form.grade_level_completed < 10
    },
    {
      name: 'other_certs_list',
      label: 'Other certifications',
      hint: 'Select all that apply',
      required: false,
      multi: true,
      options: [
        'GGYA Award',
        'City & Guilds',
        'Royal School of Music',
        'Bahama Host',
        'First Aid',
        'Other',
      ]
    },
    {
      name: 'additional_certs',
      hide: form.other_certs_list != 'Other',
    },
    {
      name: 'extra_activities_list',
      hint: 'Select all that apply',
      label: 'Have you participated in...',
      required: false,
      multi: true,
      options: [
        'Technical Cadets',
        'GGYA',
        'Volunteering'
      ]
    },
    {
      name: 'internships_list',
      label: 'Volunteering/Internship experience',
      required: false,
      columns: ['item'],
      format: v => v.item,
    },
    /*
    {
      name: 'computer_literate',
      label: 'Are you able to use a computer?',
      type: 'select:bool',
    },
    {
      name: 'accomplishments_list',
      label: 'Other Awards/Accomplishments',
      columns: ['accomplishment'],
      format: v => v.accomplishment
    },
    */
    "::Employment History",
    {
      name: 'currently_working',
      type: 'select:bool',
      label: 'Are you currently working?'
    },
    {
      name: 'place_of_employment',
      hide: !form.currently_working,
      label: 'Place of employment',
    },
    {
      name: 'previously_employed',
      type: 'select:bool',
      label: 'Have you been employed before?',
    },
    {
      name: 'previous_employment_length',
      hide: !form.previously_employed,
      label: `Longest time employed`,
      is: 'integer',
      hint: 'In years',
    },
    {
      name: 'previous_job',
      hide: !form.previously_employed,
      label: 'Most recent job',
    },
    {
      name: 'previous_job_duties_list',
      hide: !form.previously_employed,
      label: 'Duties at previous job',
      columns: ['duty'],
      format: v => v.duty
    },
    {
      name: 'previous_job_end_date',
      type: 'date',
      hide: !form.previously_employed,
      label: 'End date of last job',
      showYearDropdown: true
    },
    {
      name: 'reason_for_leaving',
      type: 'textarea',
      hide: !form.previously_employed,
      label: 'Reason For leaving employment',
    },
    /*
    {
      name: 'last_place_employed',
      label: 'Place of Last Employment',
      required: false,
      hint: 'Leave blank if not applicable'
    },
    {
      name: 'employment_interest',
      // label: '',
    },
    {
      name: 'completion_goals',
      type: 'textarea',
      label: 'What are your goals upon completing the programme?',
    }, 
    */
    '::Vocational Interest',
    {
      name: 'career_interests_list',
      label: 'Top 3 career interests (From greatest to least)',
      columns: ['interest'],
      format: v => v.interest
    },
    '::Emergency Contact Info',
    {
      name: 'emergency_contacts',
      label: 'Emergency Contact(s)',
      columns: [
        'first_name',
        'last_name',
        {
          name: 'relationship',
          datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
        },
        {
          name: 'phone_numb',
          label: 'Phone #',
          type: 'phone',
        }
      ]
    },
    {
      name: 'doctor_first_name',
      label: `Doctor's first name`,
    },
    {
      name: 'doctor_last_name',
      label: `Doctor's last name`,
    },
    {
      name: 'doctor_phone_numb',
      type: 'phone',
      label: `Doctor's phone#`,
    },
   /*
    {
      name: 't_shirt_size',
      label: 'T-Shirt Size',
      options: [
        'Small',
        'Medium',
        'Large',
        'X-Large',
        '2X-Large',
        '3X-Large',
        '4X-Large',
      ],
    },

    '::Personal Details',
    {
      name: 'number_of_dependents',
      is: 'integer',
    },
    {
      name: 'place_of_employment',
    }, 
    */
    {
      view: 'false',
      heading: `
        <span>
          The Fresh Start Program requires commitment, hard work, and dedication.
          What experiences that you have had that would help you succeed in
          this Program and why is it important for you to do this at this time
          in your life?
        </span>
      `
    },
    {
      name: 'personal_statement',
      type: 'textarea',
    },
    '::Uploads',
    {
      name: 'police_cert_upload',
      type: 'file:image',
      label: 'Current Police Record',
    },
    {
      name: 'photo_upload',
      type: 'file:image',
      label: `Copy of NIB card`,
    },
    {
      name: 'identification_upload',
      type: 'file:image',
      label: 'Passport/Birth Certificate',
    },
    {
      name: 'Academic or technical certificates (if any)',
      type: 'file:all',
      required: false
    },
    '::SPACER',
    {
      name: 'acceptance',
      type: 'checkbox',
      use_as_acceptance: true,
      label: `
        I certify that my answers are true and complete
        to the best of my knowledge
        <br />
        If this application leads to my employment, I understand that
        false or misleading information in my application or interview
        may result in my release
      `
    }

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
