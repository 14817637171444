import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class LivestockPig extends React.Component {
  resource = "/livestock_pigs";
  form_key = "livestock_pig";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};
  long_name = "Sale of Livestock Pigs";

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "pig_array",
      label: "National Swine Breeding Center (Purchase Order Form)",
      columns: [
        { name: "pig_id", label: "Pig ID #", is: "integer" },
        { name: "tag_num", label: "Tag #", is: "integer" },
        { name: "rate", label: "Rate", is: "integer" },
        { name: "weight", label: "Weight(lbs)", is: "integer" },
        {
          name: "price",
          label: "Price (Standard + Additional)",
          is: "integer",
        },
        { name: "remarks", label: "Remarks" },
      ],
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
