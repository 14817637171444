import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
import toastr from '@modules/toastr'
import Swal from 'sweetalert2'

export default class JunkanooRegistration extends React.Component {
  resource = '/junkanoo_registrations'
  form_key = 'junkanoo_registration'
  free     = true

  skip_location  = true
  hide_buttons  = {
    status: true,
    deny: false,
    fee: true,
  }
  custom_actions = [
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: [['senior_officer']],
      fn: record =>
        Swal.fire({
          title: 'Approve Application',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I approve this applicant",
            'denied': "I deny this applicant",
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.value) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })
              toastr.success('Success', 'Application status updated successfully')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
    {
      text: 'Recommend To Cultural Officer',
      icon: 'check-square',
      roles: [['officer']],
      fn: record =>
        Swal.fire({
          title: 'Recommend To Cultural Officer',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I recommend this applicant",
            'denied': "I do not recommend this applicant",
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.value) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })
              toastr.success('Success', 'Application status updated successfully')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
  ]

  validate = null

  foremost = null

  categoryOptions = (form) => {
    if (form.client_type == 'business') {
      switch(form.island) {
        case 'New Providence':
          return [
            // { label: 'Adult Group (Divison A)', value: 'adult group division a' },
            // { label: 'Adult Group (Divison B)', value: 'adult group division b' },
            { label: 'Fun Group (Division F)', value: 'fun group division f' }
          ]
        case 'Grand Bahama':
          return [
            { label: 'Adult Group (Divison A)', value: 'adult group division a' },
            { label: 'Adult Group (Divison B)', value: 'adult group division b' },
            { label: 'Fun Group (Division F)', value: 'fun group division f' }
          ]
        default:
          return [
            { label: 'Fun Group (Division F)', value: 'fun group division f' }
          ]
      }
    } else {
      switch(form.island) {
        case 'New Providence':
          return [
            { label: 'Adult Group (Divison D)', value: 'adult individual division d' },
          ]
        case 'Grand Bahama':
          return [
            { label: 'Adult Group (Divison D)', value: 'adult individual division d' },
          ]
        default:
          return [
            { label: 'Family Island Senior Groups', value: 'family island senior groups' },
          ]
      }
    }
  }

  fields = form => {
    const list = [
      ...BUSINESS_FIELDS(form),

      {
        name: 'island',
        options: islands,
      },
      {
        name: 'preferred_parade_list',
        label: 'Preferred Parade(s)',
        multi: true,
        options: [
          'Boxing Day',
          'New Years Day',
        ],
      },
      {
        name: 'organization_name',
      },
      {
        name: 'category',
        // options: form.client_type == 'business' ? [
        //   { label: 'Adult Group (Divison A)', value: 'adult group division a' },
        //   { label: 'Adult Group (Divison B)', value: 'adult group division b' },
        //   { label: 'Fun Group (Division F)', value: 'fun group division f' }
        // ] : [
        //   { label: 'Adult Group (Divison D)', value: 'adult individual division d' },
        // ],
        options: this.categoryOptions(form),
        view: {
          value: (v) => {
            switch(v) {
              case 'adult group division a':
                return 'Adult Group (Divison A)'
              case 'adult group division b':
                return 'Adult Group (Divison B)'
              case 'fun group division f':
                return 'Fun Group (Division F)'
              case 'adult individual division d':
                return 'Adult Group (Divison D)'
              default:
                return 'No Group Selected'
            }
          },
        },
      },
      {
        name: 'group_number',
        label: 'Number in group',
        is: 'integer',
        required: false,
      },
      {
        name: 'sponsor',
        required: false,
      },
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'select:bool',
        required: false,
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    ]

    if (!form.i_am_contact) {
      list.push(
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_phone_numb',
          label: 'Primary Phone #',
          type: 'phone'
        },
        {
          name: 'contact_work_numb',
          label: 'Work Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_home_numb',
          label: 'Home Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_po_box',
          label: 'P. O. Box',
          required: false
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
      )
    }

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
