import React from 'react'

import InputField from '@shared/form-fields/input'
import DateField from '@shared/form-fields/date'

export const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation row'>
    <label htmlFor={name} className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
      {label || stringify(name)}
      {required !== false ? <span className="required-label">*</span> : null}
    </label>
    <div>{children}</div>
  </div>
)

export const InsuranceRecipient = ({
  recipient,
  onChange,
  onDate
}) => {
  const rec = recipient

  return (
    <React.Fragment>
      <Field name='name' label='Policy Holder'>
        <InputField
          type='text'
          className='form-control'
          name={'name'}
          onChange={onChange}
          value={rec.name}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='identity' label='Email'>
        <InputField
          type='text'
          className='form-control'
          name={'identity'}
          onChange={onChange}
          value={rec.identity}
          validators={['required', 'isEmail']}
          errorMessages={['Required', 'Please enter valid email']}
        />
      </Field>
      <Field name='nib' label='NIB'>
        <InputField
          type='text'
          className='form-control'
          name={'nib'}
          onChange={onChange}
          value={rec.nib}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='policy_number'>
        <InputField
          type='text'
          className='form-control'
          name={'policy_number'}
          onChange={onChange}
          value={rec.policy_number}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='insurance_type'>
        <InputField
          type='text'
          className='form-control'
          name='insurance_type'
          onChange={onChange}
          value={rec.insurance_type}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='licence_plate'>
        <InputField
          type='text'
          className='form-control'
          name={'licence_plate'}
          onChange={onChange}
          value={rec.licence_plate}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='named_drivers'>
        <InputField
          className='form-control'
          name='named_drivers'
          onChange={onChange}
          value={rec.named_drivers}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>

      <Field name='start_date'>
        <DateField
          type='date'
          className='form-control'
          name={'start_date'}
          onChange={val => onDate('start_date', val)}
          selected={rec.start_date}
          value={rec.start_date}
          validators={['required']}
          errorMessages={['Required']}
          dateFormat='do MMMM, yyyy'
          showYearDropdown
        />
      </Field>
      <Field  name='end_date'>
        <DateField
          type='date'
          className='form-control'
          name={'end_date'}
          onChange={val => onDate('end_date', val)}
          selected={rec.end_date}
          value={rec.end_date}
          validators={['required']}
          errorMessages={['Required']}
          dateFormat='do MMMM, yyyy'
          showYearDropdown
        />
      </Field>

      <Field name='vehicle_make'>
        <InputField
          type='text'
          className='form-control'
          name={'vehicle_make'}
          onChange={onChange}
          value={rec.vehicle_make}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='vehicle_model'>
        <InputField
          type='text'
          className='form-control'
          name={'vehicle_model'}
          onChange={onChange}
          value={rec.vehicle_model}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field name='vehicle_year'>
        <InputField
          type='text'
          className='form-control'
          name={'vehicle_year'}
          onChange={onChange}
          value={rec.vehicle_year}
          validators={['required', 'matchRegexp:^[0-9]{4}$']}
          errorMessages={['Required', 'Please enter full year (YYYY)']}
        />
      </Field>
      <Field name='vehicle_vin' label='Vehicle VIN#'>
        <InputField
          type='text'
          className='form-control'
          name={'vehicle_vin'}
          onChange={onChange}
          value={rec.vehicle_vin}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field label='Allow authorized drivers over 25?'>
        <div style={{ marginLeft: '1rem' }}>
          <input type='checkbox'/>
        </div>
      </Field>
    </React.Fragment>
  )
}

export const MedicalRecipient = ({
  recipient,
  onChange,
  onDate
}) => {
  const rec = recipient

  return (
    <React.Fragment>
    <Field name='name'>
      <InputField
        type='text'
        className='form-control'
        name={'name'}
        onChange={onChange}
        value={rec.name}
        validators={['required']}
        errorMessages={['Required']}
      />
    </Field>
    <Field name='identity' label='Email'>
      <InputField
        type='text'
        className='form-control'
        name={'identity'}
        onChange={onChange}
        value={rec.identity}
        validators={['required']}
        errorMessages={['Required']}
      />
    </Field>
    <Field name='test_date'>
      <DateField
        type='date'
        className='form-control'
        name={'test_date'}
        onChange={val => onDate('test_date', val)}
        selected={rec.test_date}
        value={rec.test_date}
        validators={['required']}
        errorMessages={['Required']}
        dateFormat='do MMMM, yyyy'
      />
    </Field>
    <Field name='dob' label='D.O.B'>
      <DateField
        type='date'
        className='form-control'
        name={'dob'}
        onChange={val => onDate('dob', val)}
        selected={rec.dob}
        value={rec.dob}
        validators={['required']}
        errorMessages={['Required']}
        dateFormat='do MMMM, yyyy'
      />
    </Field>
    <Field name='nib' label='N.I.B'>
      <InputField
        type='text'
        className='form-control'
        name={'nib'}
        onChange={onChange}
        value={rec.nib}
        validators={['required']}
        errorMessages={['Required']}
      />
    </Field>
    </React.Fragment>
  )
}

export const RevokeRecipient = ({
  recipient,
  onChange,
}) => {
  const rec = recipient

  return (
    <React.Fragment>
      <Field >
        <label className='placeholder'>UUID</label>
        <InputField
          type='text'
          className='form-control'
          name={'uuid'}
          onChange={onChange}
          value={rec.uuid}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
      <Field >
        <label className='placeholder'>Reason</label>
        <InputField
          type='text'
          className='form-control'
          name={'reason'}
          onChange={onChange}
          value={rec.reason}
          validators={['required']}
          errorMessages={['Required']}
        />
      </Field>
    </React.Fragment>
  )
}

export const InviteRecipient = ({
  recipient,
  onChange,
}) => {
  const rec = recipient

  return (
    <React.Fragment>
    <Field >
      <label>Name</label>
      <InputField
        type='text'
        className='form-control'
        name={'name'}
        onChange={onChange}
        value={rec.name}
        validators={['required']}
        errorMessages={['Required']}
      />
    </Field>
    <Field >
      <label>Email</label>
      <InputField
        type='text'
        className='form-control'
        name={'identity'}
        onChange={onChange}
        value={rec.identity}
        validators={['required']}
        errorMessages={['Required']}
      />
    </Field>
    <Field >
      <label>Force Resend?&nbsp;</label>
      <InputField
        type='checkbox'
        name={'force'}
        onChange={onChange}
        value={rec.force}
      />
    </Field>
    </React.Fragment>
  )
}

function stringify (name) {
  return name.split('_')
    .map(w => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ')
}