import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class AirTransportLicense extends React.Component {
  resource = '/air_transport_licenses'
  form_key = 'air_transport_license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    /*{
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'First Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name',
        hide: form.i_am_applicant != false
    },*/
    "::Section (1)",
    {
        name:'name',
        label:'Name of Operator or Carrier applying for Licence'
    },
    "::Particulars of President or CEO of Applicant",
    {
        name:'full_name',
        label:'Full Name (Including initials)'
    },
    {
        name: 'birth_date',
        label: 'Date of Birth.',
        type: 'date',
        showYearDropdown: true,
        //hide: form.i_am_applicant != false
    },
    {
        name: 'address',
        label: 'Present Address & Passport Number',
        type:'textarea'
    },
    {
        name:'nationality_proof',
        label:'Proof of Nationality.',
        type:'textarea'
    },
    {
        name:'bio',
        label:'Professional Biography of President or CEO and names and position all key Management personnel - On letter head -',
        type:'textarea'
    },
    /*{
        name: 'passport_number',
        //hide: form.i_am_applicant != false
    },*/
    {
        name: 'applicant_email',
        label: 'Email of applicant',
        type: 'email',
        //hide: form.i_am_applicant != false
    },
    {
        name: 'phone_num',
        label:'Phone Contact of Applicant',
        type: 'phone'
    },
    "::Section (2)",
    {
        name: 'contact_details',
        label: 'Name and contact details of Local Representatrive retained in The Bahamas if applicable',
        required: false,
        type:'textarea'
        /*columns: [
            {
                name: 'first_name'
            },
            {
                name: 'middle_name'
            },
            {
                name: 'last_name'
            },
            {
                name: 'phone_contact',
                label: 'Phone Number',
                type: 'phone'
            }
        ]*/
    },
    /*
    //":: Scheduled Services",
    {
        name: 'registration_country',
        label: 'Aircraft Country of Registration',
        hide: form.license_type != 'scheduled'
    },
    {
        name: 'business_address',
        label: 'Registered business address',
        columns: ['Head office', 'In the Colony'],
        hide: form.license_type != 'scheduled'
    },
    {
        name: 'departure_point',
        label: 'Point of Departure',
        hide: form.license_type != 'scheduled'
    },
    {
        name: 'destination_point',
        label: 'Destination(Point)',
        hide: form.license_type != 'scheduled'
    },
    {
        name: 'intermaediate_landing',
        label: 'Intermediate landing points',
        hide: form.license_type != 'scheduled',
        rowLabel: 'Landing point',
        fields: [
            {
                name: 'traffic_purposes',
                label:'For traffic purposes'

            },
            {
                name: 'non_traffic',
                label:'For non-traffic purposes'
            }
        ]
    },
    {
        name: 'duration',
        options: ['1 Year', '2 Years'],
        hide: form.license_type != 'scheduled'
    },
    {
        name: 'seating_capacity',
        label: 'Type of Aircraft',
        hide: form.license_type != 'scheduled'
    },
    {
        name:'seating_capacity',
        label: 'Passenger Seating Capacity',
        hide: form.license_type != 'scheduled'
    },
    //"::Non-scheduled Services",
    {
        name: 'countries_list',
        label: 'List of Countries likely to be operated from when coming to The Bahamas.',
        type: 'textarea',
        hide: form.license_type != 'non-scheduled'
    },*/
    "::Section (3)",
    /*{
        name: 'nationality_proof_upload',
        label: "Proof of Nationality",
        type: 'file:all',
        key: 'iamge.0',
        hide: form.i_am_applicant != false
    },
    {
        name: 'professional_bio_upload',
        label: 'Professional Biography of President or CEO(including names and positions of all key management personnel)',
        type: 'file:pdf',
        key: 'image.1'
    },*/
    {
        name: 'insurance_cert_upload',
        label: 'Certificate of Insurance',
        type: 'file:all',
        key: 'iamge.2'
    },
    {
        name: 'airworthiness_cert_upload',
        label: 'Certifcate of Airworthiness',
        type: 'file:all',
        key: 'images.3'
    },
    {
        name: 'registration_cert_upload',
        label: 'Certificate of Registration',
        type: 'file:all',
        key:  'images.4'
    },
    {
        name: 'noise_cert_upload',
        label: 'Noise Certificate',
        type: 'file:all',
        key: 'image.5'
    },
    {
        name: 'radio_license_upload',
        label: 'Radio License',
        type: 'file:all',
        key: 'image.6'
    },
    {
        name: 'air_operator_cert_upload',
        label: 'Air Operator Certificate (AOC)',
        type: 'file:all',
        key: 'image.7'
    },
    {
        name: 'operato_specs_upload',
        label: `Complete Operator’s/Air Carrier’s Operations Specifications`,
        type: 'file:all',
        key:'image.8'
    },
    {
        name: 'executive_summary_upload',
        label: 'Brief executive summary',
        type: 'file:pdf',
        key: 'image.9'
    },
    "::Section (4)",
    {
        name:'service_type',
        label:'The type of service applied for:',
        options:[
            '(a) If Scheduled - proceed to Section(5)',
            '(b) If Non-scheduled - proceed to section (6)'
        ]
    },
    "::Section (5)",
    {
        heading:<h5>SCHEDULED SERVICES</h5>
    },
    {
        heading:<p>Submit an accurate <strong>Time-Table</strong> for <strong>Scheduled Services, proposed passenger, freight, air mail conveyance rates, baggage allowance and excess baggage rates.</strong></p>
    },
    {
        name:'reg_count',
        label:'Aircraft Country of Registration'
    },
    {
        name:'reg_bus',
        label:'Registered business address',
        fields:[
            {
                name:'Head Office'
            },
            {
                name:'In the Colony'
            }
        ]
    },
    {
        name:'particulars',
        label:'Particulars of Route desired to operate',
fields:[
        {
            name:'Point of Departure'
        },
        {
            name:'Destination (Point)'
        },
        ]
    },
        {
            name:'Intermediate landing points',
            fields:[
            {
                name:'traffic',
                label:'for traffic purposes'
            },
            {
                name:'non_traffic',
                label:'for non-traffic purposes'
            }

        ]
    },
    {
        name:'duration',
        label:'Duration of',
        options:['1 YEAR', '2 YEARS']
    },
    {
        name:'capacity',
        label:'Type of aircraft and Passenger Seating Capacity',
        hint:'Crew to be carried (number and function)',
        type:'textarea'
    },
    "::Section (6)",
    {
        heading:<h5>NON-SCHEDULED SERVICES.</h5>
    },
    {
        name:'countris_list',
        label:'List of Countries likely to be operated from when coming to The Bahamas.',
        type:'textarea'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
