import React, { createElement, useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'

import AppRouter from './routes'
import Header from '@pages/common/partials/header'
import Footer from '@pages/common/partials/footer'
import { extractKeys } from '@helpers/objects'
import AppGlobals from '@modules/setup-globals'
import Loader from '@shared/loader'
import SidePane from '@pages/common/partials/sidebar'

import { SearchResultsContainer } from '@modules/site-search'
import VehicleInspection from '@agencies/rtd/vehicle-inspection'
// import ApprovedInspections from '@agencies/rtd/approved-inspections'
import PendingSecurityLicences from '@agencies/national_security/pending-approvals'
// import { RoleManagement } from '@modules/officer-management/role-management'
// import PendingRegistries from '@agencies/mysc/culture/pending-approvals'
import MotaDirectorView from '@agencies/mota/director/index'
import FarmerInspections from '@agencies/moa/farm-inspection'
import CourierScanner from '@modules/delivery/courier'
import CollectionsCentre from '@modules/ccc'

import { isSubset } from '@helpers/arrays'

import {
  getAgencies,
  getAgencyServices,
  getAgencyAlerts,
} from '@actions/agencies'
import ENV from '@constants/env'

// const LAST_CONFIG_STORE_KEY = 'LAST_MAINTENENCE_MESSAGE'

export const Main = ({ history, ...props }) => {
  const [ready, setReady] = useState(false)
  const [toggled, setToggled] = useState(false)
  const [topToggle, setTopToggle] = useState(false)
  const [maintenance, setMaintenance] = useState(false)

  const page = document.documentElement
  const { current_user, roles, token } = $app ?? {}
  const numAgencies = props.agencies?.length
  const numServices = props.services?.length

  let component = null
  let skipAR = false
  let blank = false
  let guard = true

  useEffect(() => {
    const getConfig = async () => {
      let text = null

      try {
        const { data } = await $app.axios('/api_config')

        const { maintenance_mode, maintenance_message } = data
        // const lastUpdate = window.localStorage.getItem(LAST_CONFIG_STORE_KEY)
        // const thisUpdate = new Date(updated_at).getTime()

        // Display Error Always, regardless of last config update
        if (maintenance_mode && maintenance_message) {
          text = data.maintenance_message
          // Message is set & user did not click "Do Not Show This Again"
          // since last change to config
        } else if (maintenance_message) {
          await Swal.fire({
            icon: 'info',
            title: 'Alert',
            html: maintenance_message
              .replaceAll('&lt;', '<')
              .replaceAll('&gt;', '>')
              .replaceAll('&#39;', "'"),
            // confirmButtonText: "Don't Show Me This Message Again",
            confirmButtonText: "Okay",
            showCancelButton: false,
            // cancelButtonText: 'Okay',
          })

          // if (isConfirmed) {
          //   window.localStorage.setItem(LAST_CONFIG_STORE_KEY, thisUpdate)
          // }

          return
        }
      } catch (err) {
        if (/^5[0-9]{2}/.test(err.response?.status)) {
          text = `
            We are currently unable to reach the server.
            Please try again later; we are sorry for the inconvenience
          `
        }

        console.error(err)
      }

      if (text) {
        Swal.fire({
          icon: 'warning',
          title: 'Temporary Service Interruption/Maintenance',
          text,
          showConfirmButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })

        setMaintenance(true)
      }
    }

    getConfig()

    if (ENV.SIDENAV_MODE == 'pilot-qa') {
      window.addEventListener('keyup', updateProfile)

      return () => {
        window.removeEventListener('keyup', updateProfile)
      }
    }
  }, [])

  useEffect(() => {
    if (token) {
      props.getAgencyServices()
      props.getAgencies()

      const fn = () => props.getAgencyAlerts()
      const timer = setInterval(fn, 300000) // 5 MINUTES
      fn()

      return () => clearInterval(timer)
    }
  }, [token]) // eslint-disable-line

  useEffect(() => {
    window.$app = new AppGlobals(
      sessionStorage.getItem('user'),
      props.agencies,
      props.services
    )

    $app.ready && setReady(true)
  }, [numAgencies, numServices]) // eslint-disable-line

  const togglePanel = () => {
    setToggled(!toggled)
    page.classList.toggle('nav_open', !toggled)
  }

  const openTopBar = () => {
    setTopToggle(!topToggle)
    page.classList.toggle('topbar_open', !topToggle)
  }

  if (maintenance) return null
  if (token && !ready) return <Loader loading={true} />

  if (roles?.includes('collections_centre')) {
    component = CollectionsCentre
    skipAR = true
  } else if (
    roles?.length == 2 &&
    roles.includes('courier') &&
    roles.some(r => /_dispatcher$/.test(r))
  ) {
    component = CourierScanner
  // } else if (isSubset(roles, ['moss'])) {
  //   component = PendingDisabilities
  // } else if (roles?.includes('role_admin')) {
  //   component = RoleManagement
  } else if (isSubset(roles, ['mota', 'director']) || isSubset(roles, ['mota', 'senior_officer', 'trainer'])) {
    component = MotaDirectorView
  // } else if (isSubset(roles, ['culture', 'registry'])) {
  //   component = PendingRegistries
  } else if (isSubset(roles, ['national_security', 'minister'])) {
    component = PendingSecurityLicences
  // } else if (isSubset(roles, ['road_traffic', 'financial_officer'])) {
  //   component = ApprovedInspections
  } else if (roles?.includes('inspector')) {
    if (roles.includes('road_traffic')) component = VehicleInspection
    if (roles.includes('moa')) component = FarmerInspections
  } else if (/^\/(print)/.test(window.location.pathname)) {
    blank = true
  }

  if (skipAR) {
    return <Router history={history} children={createElement(component)} />
  }

  if (component || blank)
    return (
      <Router history={history}>
        <AppRouter component={component} noAuthGuard={!guard} />
      </Router>
    )

  if (current_user) {
    return (
      <Router history={history}>
        <div className={'wrapper ' + (toggled ? 'sidebar_minimize' : '')}>
          <Header {...{ toggled, togglePanel, openTopBar }} />
          <SearchResultsContainer />
          <SidePane toggled={toggled} togglePanel={togglePanel} hide={blank} />
          <div className='main-panel'>
            <div className='container'>
              <AppRouter />
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    )
  }

  return (
    <Router history={history}>
      <AppRouter />
    </Router>
  )
}

export default connect(
  state => ({
    ...extractKeys(state.users, 'error', 'success_message'),
    ...extractKeys(state.search, 'term'),
    ...extractKeys(state.agencies, 'agencies', 'services'),
  }),
  {
    getAgencies,
    getAgencyAlerts,
    getAgencyServices,
  }
)(Main)

async function updateProfile({ altKey, ctrlKey, key }) {
  const { axios, current_user, roles } = $app
  if (!current_user) return

  if (altKey && ctrlKey && key == 'q') {
    const id = roles.length == 1 && roles[0] == 'pilot' ? '' : current_user.id
    const { data: profile } = await axios.get('/profiles/' + id)

    const data = {
      ...current_user,
      ...extractKeys(
        profile,
        'NIB',
        'roles',
        'island',
        'gender',
        'drivers_license_number',
        'phone_number',
        'additional_phone_number'
      ),
    }

    sessionStorage.setItem('user', JSON.stringify(data))
    window.location.reload()
  }

  if (altKey && ctrlKey && key == 'w') {
    console.log($app.token)
  }
}
