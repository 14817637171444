import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import sanitize from 'sanitize-html'

import toastr from '@modules/toastr'
import normalizeField from '@helpers/normalize-form-field'
import { extractKeys } from '@helpers/objects'

import Form from './form'
import CCForm from './payment'
import LastStep from './success'
import ProxyForm from './proxy'
import Appointment from './appointment'
import UserSelect from './proxy.users'
import * as STEPS from './steps'
import { fullName } from './utils'

export default function SteppedForm(props) {
  // console.log(props);
  const [step, setStep] = useState(props.start || 1)
  const continueButton = useRef()

  useEffect(() => {
    const initial = rs => {
      if (!rs) return STEPS.FORM

      if (rs.proxy_select === true) return STEPS.SELECT_PROXY
      if (rs.modify_proxy) return STEPS.PROXY_PICKUP
      if (rs.appointment_retry || rs.appointment_pick) return STEPS.APPOINTMENT
      if (rs.dependencies) return STEPS.DEPENDENCIES
      // if (rs.legal_disclaimer) return STEPS.LEGAL_DISCLAIMER
      if (rs.payment) return STEPS.PAYMENT
      if (rs.success) return STEPS.FINISH

      return STEPS.FORM
    }

    const first = initial(props.state)

    if (first == STEPS.FORM && props.service.instructions) {
      Swal.fire('Info', sanitize(props.service.instructions), 'info')
    }

    setStep(first)
  }, [])

  useEffect(() => {
    if (props.step && props.step != step) setStep(props.step)
  }, [props.step])

  const {
    // history state
    state,
    // props
    tab,
    any,
    free,
    postpaid,
    long_name,
    service,
    stacked,
    skip_location,
    has_appointment,
    // legal_disclaimer,
    // methods
    navigate,
    goBack,
    setLoading,
    submitPayment,
    blockSubmission,
    appointment,
    proxy,
    CRUD,
  } = props

  // console.log('FORM CONTAINER\n', 'STATE\n', state, 'SERVICE\n', service)
  const formid = service.name
    .toLowerCase()
    .replace(/[^a-z ]/g, '')
    .replace(/\s+/g, '-')

  // prettier-ignore
  const noPayment = free
    || (postpaid && state.record?.payment_amount)
    || state?.appointment_retry

  const tabs = [
    ['Proxy Select', 'users', !state?.proxy_select],
    ['Dependencies', 'hands-helping', !any?.dependencies],
    [tab.text, tab.icon, state?.appointment_retry],
    ['Pickup', 'map-marker', skip_location],
    ['Proxy', 'user-friends', skip_location],
    // ['Disclaimer', 'hands-helping', legal_disclaimer],
    ['Appointment', 'clock', !has_appointment],
    ['Payment', 'money-bill', noPayment],
    ['Confirmation', 'check-square'],
  ].map(generateWizardTab(step))

  const currentProxy = state.proxy ? (
    <div className='text-center font-semibold mb-4'>
      Proxying For: {fullName(state.proxy?.user || state.proxy)}
    </div>
  ) : null

  let activeTab
  let nextBtn = null

  switch (step) {
    case STEPS.SELECT_PROXY:
      activeTab = content(
        <UserSelect
          form={formid}
          children={state.children}
          submit={proxy.v2.request}
        />
      )
      nextBtn = (
        <button
          type='submit'
          form={formid}
          className='btn btn-next btn-primary'
          onClick={checkForFormErrors}
        >
          Next
        </button>
      )

      break

    case STEPS.DEPENDENCIES:
    case STEPS.FORM:
    case STEPS.PICKUP:
      const { record, dependencies, business } = props.state

      // prettier-ignore
      const missingDeps = dependencies && Object.values(dependencies).some(v => !v)
      const editing = !!record

      const validate = async json => {
        if (!props.validate) return business || true

        try {
          setLoading(true)
          const append = {}
          const valid = await props.validate(json)

          if (valid instanceof FormData) return valid
          if (valid === false || typeof valid == 'string') {
            valid && toastr.error('Error', valid)
            setLoading(false)
            setStep(STEPS.FORM)
            return false
          } else if (valid?.error) {
            blockSubmission(valid)
            setStep(STEPS.FORM)
            return false
          }

          typeof valid == 'object' && Object.assign(append, valid)
          Object.assign(append, business ?? {})

          setLoading(false)
          return append
        } catch (err) {
          console.error(err)
          setLoading(false)
          toastr.error(
            'Error',
            'Something went wrong while validating your request'
          )
          return false
        }
      }

      const formProps = {
        step,
        validate,
        stacked,
        skip_location,

        name: formid,
        save: CRUD(props.pausable ? 'create:complete' : 'create'),
        partialSave: CRUD('create'),
        // partialSave: CRUD(props.pausable ? 'create:complete' : 'create'),
        continueButton: continueButton.current,
        back: goBack,
        dependencies,
        missingDeps,
        editing,
        record: state?.record,
        defaults: getFieldValues(props, editing),
        next: () => setStep(step + 1),
        ...extractKeys(service, 'type:service_type', 'locations'),
        ...extractKeys(
          props,
          'custom_acceptance',
          'profile',
          'pausable',
          'multiple_uploads',
          'generate_form_data',
          'skip_location',
          'legal_disclaimer',
          'use_profile',
          'fields',
          'picker',
          'foremost',
          'form_key',
          'custom_notice'
        ),
      }

      activeTab = content(<Form {...formProps} />)

      nextBtn = missingDeps ? null : (
        <button
          type='submit'
          form={formid}
          className='btn btn-next btn-primary'
          onClick={checkForFormErrors}
        >
          Next
        </button>
      )

      break

    case STEPS.PROXY_PICKUP:
      activeTab = content(
        <ProxyForm
          form={formid}
          editing={state.modify_proxy}
          service={service.type}
          submit={proxy.v1.set}
          goBack={goBack}
        />
      )

      nextBtn = (
        <button
          type='submit'
          form={formid}
          className='btn btn-next btn-primary'
          onClick={checkForFormErrors}
        >
          {state.modify_proxy ? 'Submit' : 'Next'}
        </button>
      )

      break

    case STEPS.APPOINTMENT:
      activeTab = content(
        <Appointment
          form={formid}
          record_id={state.record.id}
          service_type={service.type}
          submit={
            state.appointment_retry ? appointment.retry : appointment.setParams
          }
        />
      )

      nextBtn = (
        <button
          type='submit'
          form={formid}
          className='btn btn-next btn-primary'
          onClick={checkForFormErrors}
        >
          {state.appointment_retry ? 'Submit' : 'Next'}
        </button>
      )

      break

    case STEPS.PAYMENT:
      activeTab = content(
        <CCForm
          service_key={props.form_key}
          name={formid}
          fee={state.payment?.payment_amount}
          form={state.record}
          submit={submitPayment}
        />
      )

      nextBtn = (
        <button
          type='submit'
          form={formid}
          className='btn btn-next btn-primary'
        >
          Next
        </button>
      )

      break

    case STEPS.FINISH:
      activeTab = content(<LastStep {...{ service, ...state }} />)
      break
    default:
  }

  return (
    <div className='row' style={{ marginTop: 70 }}>
      <div className='wizard-container wizard-round col-md-9'>
        <div className='wizard-header text-center'>
          <h3
            className='wizard-title'
            dangerouslySetInnerHTML={{
              __html: (long_name || service.title).replace(
                /^([a-z'-]+)/i,
                `<strong>$1</strong> `
              ),
            }}
          ></h3>
          <small>{service.agency?.name.capitalize()}</small>
        </div>
        <div className='wizard-body'>
          <div className='row'>
            <ul className='wizard-menu nav nav-pills nav-primary'>{tabs}</ul>
          </div>

          <div className='tab-content'>
            {currentProxy}
            {activeTab}
          </div>
        </div>

        <div className='wizard-action'>
          <div className='pull-left'>
            <input
              type='button'
              className={'btn' + (step == STEPS.FINISH ? ' btn-primary' : '')}
              onClick={() =>
                step == STEPS.FORM + 1
                  ? setStep(STEPS.FORM)
                  : navigate('applications', null)
              }
              name='back'
              value={step == STEPS.FINISH ? 'Done' : 'Back'}
            />
          </div>
          {props.pausable ? (
            <div
              id='__save-and-exit__'
              ref={continueButton}
              className='pull-right mr-3'
            />
          ) : <div className='pull-right'>{nextBtn}</div>}
          <div className='clearfix'></div>
        </div>
      </div>
    </div>
  )
}

function generateWizardTab(step) {
  return ([text, icon, hide], index) => {
    if (hide) return null
    const active = step == index - 1 ? 'active' : ''

    return (
      <li className='step' key={index}>
        <a className={`nav-link ${active}`} href='#' aria-expanded='true'>
          <i className={`fas fa-${icon} mr-0`}></i>
          <span className='hidden xl:inline'>&nbsp;&nbsp;{text}</span>
        </a>
      </li>
    )
  }
}

function content(child) {
  return <div className='tab-pane active'>{child}</div>
}

function getFieldValues(props, editing = false) {
  const { fields, defaults, state } = props
  const { record, business } = state

  // Always set client type since it can be safely ignored
  const obj = defaults || {}
  business && Object.assign(obj, business)

  if (!editing) return obj

  return fields(record, false, true)
    .map(normalizeField)
    .reduce((obj, f) => {
      if (!/^file/.test(f.type)) {
        obj[f.name] = record[f.name]
      }

      return obj
    }, obj)
}

function checkForFormErrors() {
  setTimeout(() => {
    // eslint-disable-next-line
    document.querySelector('.form-wizard__form .input_error')?.focus()
  }, 100)
}
