import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

const textlist = {
  type: 'textarea',
  hint: 'Please separate each entry with a new line',
  format: v =>
    v
      .split('\n')
      .map(v => v.trim())
      .filter(v => v),
}

export default class ProductAssembly extends React.Component {
  long_name = 'Product Assembly'
  resource = '/moea_product_assemblies'
  form_key = 'moea_product_assembly'
  fee = 99
  free = false
  postpaid = false
  pausable = true
  defaults = {}

  skip_location = true
  hide_buttons = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),

    '::Applicant Details',
    {
      name: 'occupation',
      hide: form.client_type == 'business',
    },
    {
      name: 'street_address',
      hide: form.client_type == 'business',
    },

    '::Contact Details',
    {
      name: 'po_box',
      label: 'P. O. Box',
    },
    {
      name: 'phone_number',
      type: 'phone',
    },

    '::Principal Executive Sponsor',
    {
      name: 'principal_exec_f_name',
      label: 'First Name',
    },
    {
      name: 'principal_exec_l_name',
      label: 'Last Name',
    },
    {
      name: 'principal_exec_title',
      label: 'Job Title',
    },

    '::Project Consultant',
    {
      name: 'project_consultant_f_name',
      label: 'First Name',
    },
    {
      name: 'project_consultant_l_name',
      label: 'Last Name',
    },
    {
      name: 'project_consultant_address',
      label: 'Address',
    },

    '::Project Details',
    {
      name: 'project_name',
      label: 'Proposed Project Name',
    },
    'location_planned',
    {
      name: 'facility_site',
      label: 'Site Area Needed',
    },
    {
      name: 'facilities_needed',
      type: 'checklist',
      options: ['Electricity', 'Water', 'Other'],
    },
    {
      name: 'facilities_needed_other',
      label: 'Other Facilities Needed',
      type: 'textarea',
      hide: !form.facilities_needed?.has?.('Other'),
      view: false,
    },
    {
      name: 'est_project_period',
      label: 'Estimated Period For Project Establishment',
    },
    {
      name: 'products_planned',
      label: 'Range of Products Planned (Per Year)',
      columns: [
        {
          name: 'product_name',
          label: 'Product',
        },
        {
          name: 'unit',
        },
        {
          name: 'unit_value',
          is: 'currency',
        },
        {
          name: 'local_quantity',
          label: 'Local Quantity',
          is: 'integer',
        },
        {
          name: 'local_value',
          is: 'currency',
        },
        {
          name: 'foreign_quantity',
          label: 'Foreign Quantity',
          is: 'integer',
        },
        {
          name: 'foreign_value',
          is: 'currency',
        },
      ],
    },
    {
      name: 'product_brand_names',
      label: 'Proposed Local Products Brand Names',
      ...textlist,
    },
    {
      name: 'production_sequence_desc',
      label: 'Production Sequence Description',
      type: 'textarea',
    },

    '::Financing Details ',
    {
      name: 'planned_capital_structure',
      columns: [
        {
          name: 'organization',
          options: ['Share Company', 'Partnership', 'Individual'],
        },
        { name: 'market_type', options: ['foreign', 'local'] },
        { name: 'capital_type', options: ['loan', 'project'] },
        { name: 'amount', is: 'currency' },
      ],
    },
    {
      name: 'planned_investment_analysis',
      label: 'Analysis of Planned Investment',
      fixed: true,
      columns: [
        {
          name: 'item',
          disabled: true,
        },
        // {
        //   name: 'market_type',
        //   options: ['foreign', 'local'],
        // },
        {
          name: 'local',
          is: 'currency',
          width: 180,
        },
        {
          name: 'foreign',
          is: 'currency',
          width: 180,
        },
      ],
    },
    {
      name: 'equipment_exemption_list',
      label: 'Equipment Exemptions',
      view: false,
      columns: [
        {
          name: 'desc',
          label: 'Description',
        },
        {
          name: 'qty',
          label: 'QTY',
          is: 'integer',
        },
        {
          name: 'cost',
          label: 'Cost Per Unit',
          is: 'currency',
        },
      ],
    },

    '::SPACER',
    {
      name: 'operational_expenditure',
      columns: [
        'item',
        'unit',
        { name: 'quantity', is: 'integer' },
        { name: 'foreign_cost', is: 'currency' },
        { name: 'local_cost', is: 'currency' },
      ],
    },
    {
      name: 'assembly_raw_materials',
      label: 'List Raw Materials',
      columns: [
        'item',
        'unit',
        { name: 'quantity', is: 'integer' },
        { name: 'foreign_cost', is: 'currency' },
        { name: 'local_cost', is: 'currency' },
      ],
    },
    {
      name: 'packing_materials',
      columns: [
        'item',
        'unit',
        { name: 'quantity', is: 'integer' },
        { name: 'foreign_cost', is: 'currency' },
        { name: 'local_cost', is: 'currency' },
      ],
    },
    {
      name: 'components',
      columns: [
        'item',
        'unit',
        { name: 'quantity', label: 'Qty.', is: 'integer' },
        { name: 'foreign_cost', is: 'currency' },
        { name: 'local_cost', is: 'currency' },
      ],
    },

    '::SPACER',
    {
      name: 'immigration_facilities',
      label: 'Immigration Facilities Needed',
      columns: [
        'staff_grade',
        {
          name: 'residence_period_months',
          label: 'Residence Period (Months)',
          is: 'integer',
        },
        'reason',
      ],
    },
    {
      name: 'employment_local_wages',
      label: 'Employment Per Shift & Estimated Local Emplyee Wages (Per Year)',
      fixed: true,
      columns: [
        {
          name: 'grade',
          disabled: true,
        },
        {
          name: 'shift_1_no',
          label: 'Shift #1 Hours',
          is: 'integer',
        },
        {
          name: 'shift_1_wage',
          label: 'Wage',
          is: 'currency',
        },
        {
          name: 'shift_2_no',
          label: 'Shift #2 Hours',
          is: 'integer',
        },
        {
          name: 'shift_2_wage',
          label: 'Wage',
          is: 'currency',
        },
      ],
    },
    {
      name: 'anti_pollution_measures',
      hint: `
        * The composition of all wastes and effluents should be disclosed,
        e.g. solids in waste water; dust; gases
      `,
      rowLabel: 'Pollutant',
      addLabel: 'Add Measure',
      fields: [
        {
          name: 'effluent',
          label: 'Nature of Waste or Effluent',
        },
        {
          name: 'annual_unit',
          label: 'Annual Unit',
          is: 'integer',
        },
        {
          name: 'quantity',
          label: 'Quantity Amount',
          is: 'integer',
        },
        {
          name: 'desc',
          label: 'Description of Anti-Pollution Measures',
        },
      ],
    },

    '::Utilities Details',
    {
      name: 'electric_installed',
      type: 'select:bool',
      label: 'Electricity Installed?',
    },
    {
      name: 'electric_max_demand',
      label: 'Max Electrical Demand',
    },
    {
      name: 'electric_annual_kvp',
      label: 'Electrical Annual KVP',
    },
    {
      name: 'main_water_supply_net_consumption',
      label: 'Net Consumption of Main Water Supply',
    },
    {
      name: 'consumption_indices',
      fixed: true,
      rowLabel: 'Index',
      fields: [
        {
          name: 'item',
        },
        {
          name: 'annual_amount',
          is: 'currency',
        },
        {
          name: 'liability_period',
          label: 'Liability Period if Recurrent',
          required: false,
        },
        {
          name: 'remarks',
          required: false,
        },
      ],
    },

    '::SPACER',
    {
      name: 'concession_incentives',
      fields: [
        {
          name: 'short_desc',
          label: 'Short Title of Concesion/Incentive',
        },
        {
          name: 'reason',
          label: 'Reasons',
        },
      ],
    },

    '::Additional Details',
    {
      name: 'main_production_machinery',
      label: 'Main Productive Machinery',
      options: ['New', '2nd Hand', 'Re-conditioned'],
    },
    {
      name: 'main_production_machinery_percentage',
      label: 'Percentage Re-Conditioned',
      hide: form.main_production_machinery != 'Re-conditioned',
      save: false,
      type: 'float',
    },
    '::Foreign Collaboration',
    {
      name: 'joint_venture',
      hint: 'Percentages refer to Invested Capital',
      columns: [
        {
          name: 'equity_capital',
          label: 'Equity Capital %',
          is: 'float',
        },
        {
          name: 'loan_capital',
          label: 'Loan Capital %',
          is: 'float',
        },
        {
          name: 'machinery_contribution',
          label: 'Machinery Contribution %',
          is: 'float',
        },
      ],
    },
    {
      name: 'erection_commissioning_cost',
      is: 'currency',
    },
    {
      name: 'turnkey_contract_cost',
      is: 'currency',
    },
    {
      name: 'management_contract',
      columns: [
        {
          name: 'period',
          label: 'Period',
        },
        {
          name: 'months_years',
          label: 'Months/Years',
          options: ['Month(s)', 'Year(s)'],
        },
      ],
    },
    {
      name: 'bahamians_in_training',
      label: 'Training of Bahamians',
      is: 'integer',
    },
    {
      name: 'technical_servicing',
      columns: [
        { name: 'period', label: '#' },
        {
          name: 'months_years',
          label: 'Months/Years',
          options: ['Month(s)', 'Year(s)'],
        },
      ],
    },
    {
      name: 'product_number_specification',
      type: 'textarea',
    },
    {
      name: 'additional_comments',
      label: 'Additional Comments?',
      type: 'textarea',
      required: false,
    },
    {
      name: 'declare',
      type: 'checkbox',
      label: `
        I declare that the information entered is true and correct
        to best of my knowledge
      `,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
