import { createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import StateStore from '@helpers/state-store'
import CitizenActions from '@modules/ccc/components/citizen-actions'

const CollectionsContext = createContext({})

const CollectionsProvider = ({ children }) => {
  const location = useLocation()
  const [modal, setModal] = StateStore({
    type: null,
    item: null,
  })

  useEffect(() => {
    setModal({ type: null, item: null })
  }, [location])

  const closeModal = () => {
    setModal({ type: null })
  }

  const openUser = (item, isProxy = false) => {
    setModal({ item, proxy: !!isProxy, type: 'user' })
  }

  const openHistory = item => {

  }

  const ctx = {
    openUser,
    openHistory,
  }

  return (
    <CollectionsContext.Provider value={ctx}>
      {children}
      <CitizenActions
        open={modal.type == 'user'}
        item={modal.item ?? {}}
        proxy={modal.proxy}
        onClose={closeModal}
      />
    </CollectionsContext.Provider>
  )
}

export const useCollections = () => useContext(CollectionsContext)
export default CollectionsProvider
