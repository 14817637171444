import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import generateRange from '@helpers/range'
import convertToBase64 from '@helpers/file-to-base64'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'

const currYear = new Date().getFullYear()
const years = generateRange(currYear - 30, currYear)
  .map(n => n.toFixed())
  .reverse()

export default class SelfStarterProgramme extends React.Component {
  resource = '/self_starter_programmes'
  form_key = 'self_starter_programme'
  defaults = {}

  before_apply = () => {
    if ($app.applicant.age > 30) {
      return 'Only persons aged 18-30 years old are permitted are eligible'
        + ' to apply'
    }

    return true
  }

  // Delete lines as needed
  free          = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []
  generate_form_data = false

  validate = async ({ references, ...form }) => {
    if (references?.length < 2) {
      return 'You must enter at least two references.'
    }

    const payload = {
      ...form,
      references: [],
      additional_images: [],
    }

    for (let ref of references) {
      const { upload, ...rest } = ref

      payload.references.push(rest)
      payload.additional_images.push({
        title: `Reference Letter: ${fullName(rest, false)}`,
        desc: 'Supplemental Reference Letter',
        url: await convertToBase64(upload),
      })
    }
  }

  fields = form => [
    ...BUSINESS_FIELDS(form),
    '::SPACER',

    {
      name: 'number_of_dependents',
      is: 'integer',
    },
    {
      name: 'website',
      required: false,
    },
    {
      name: 'emergency_contacts',
      columns: [
        'name',
        {
          name: 'phone',
          type: 'phone'
        }
      ]
    },

    '::Education',
    {
      name: 'colleges_attended_bool',
      type: 'select:bool',
      label: 'Have you attended a college?'
    },
    {
      name: 'schools_attended_list',
      label: 'Schools Attended',
      columns: [
        'name',
        {
          name: 'year_started',
          options: years,
        },
        {
          name: 'year_completed',
          options: years,
        },
        {
          name: 'achievements'
        }
      ]
    },
    {
      name: 'colleges_attended_list',
      hide: !form.colleges_attended_bool,
      label: 'College(s) Attended',
      columns: [
        'name',
        {
          name: 'year_started',
          options: years,
        },
        {
          name: 'year_completed',
          options: years,
        },
        {
          name: 'programme'
        }
      ]
    },

    {
      name: 'interests_list',
      label: 'Intersets',
      columns: ['interest'],
      hint: 'Sports, Drama, Community Involvement, etc.'
    },

    '::Business Information',
    {
      name: 'business_start_date',
      label: 'Date Established',
      type: 'date',
      showYearDropdown: true,
    },
     {
      name: 'shareholders',
      columns: ['first_name', 'last_name'],
     },
     {
      name: 'name_of_bank',
     },
     {
      name: 'address_of_bank',
     },
     {
      name: 'outstanding_loans',
      type: 'select:bool',
      label: 'Does this business have any outstanding loans?',
     },
     {
      name: 'monthly_payment',
      hide: !form.outstanding_loans,
     },
     {
      name: 'funds_received',
      label: 'Have you ever received or are receiving unds for a business?',
      type: 'select:bool'
    },
    {
      name: 'funds_details',
      type: 'textarea',
      hide: !form.funds_received,
      label: 'Please provide details',
     },

    '::SPACER',
    {
      name: 'services_offered_list',
      label: 'Service(s) Offered',
      columns: ['description']
    },
    {
      name: 'target_market',
      type: 'textarea',
      label: 'Impact On Community & Target Market',
    },
    {
      name: 'target_number',
      is: 'integer',
      label: 'How much people are you expecting to serve?',
    },
    {
      name: 'project_expenses',
      label: 'Projected Budget',
      columns: ['item']
    },
    {
      name: 'business_difference',
      type: 'textarea',
      label: 'How does your business differ from competitors?'
    },
    {
      name: 'promotion_plans',
      type: 'textarea',
      label: 'What are your plans for promoting your business to clients'
    },
    {
      name: 'business_sustainability',
      type: 'textarea',
      label: 'How will you sustain this business after the grant?',
    },
    {
      name: 'grant_amount_requested',
      is: 'currency',
      label: 'What is the grant amount you are requesting?',
    },
    {
      name: 'fund_sources',
      label: 'Source(s) of Funds',
      columns: [
        'source',
        {
          name: 'support_type',
          label: 'Type of Support',
         },
         {
          name: 'committed/solicited',
          label: 'Committed/Solicted?',
          options: ['Committed', 'Solicited'],
         },
         {
          name: 'amount',
          is: 'currency'
         }
      ]
    },

    {
      heading: (
        <div className='my-2'>
          Please note that you are required to upload at least&nbsp;
          <strong>two (2)</strong> references
        </div>
      ),
      view: false
    },
    {
      name: 'references',
      fields: [
        'first_name',
        'last_name',
        'occupation',
        'address',
        {
          name: 'phone_numb',
          type: 'phone',
          label: 'Phone',
        },
        {
          name: 'upload',
          type: 'file:pdf',
          label: 'PDF Upload',
        }
      ]
    },

    '::Uploads',
    {
      key: 'image.0',
      name: 'photo_upload',
      type: 'file:image',
      label: 'Passport Photo',
    },
    {
      key: 'image.1',
      name: 'reference_upload',
      type: 'file:pdf',
      label: '1st Reference',
    },
    {
      key: 'image.2',
      name: 'reference_2_upload',
      type: 'file:pdf',
      label: '2nd Reference',
    },
    {
      key: 'image.3',
      name: 'business_license_upload',
      type: 'file:all',
      label: 'Business Licence',
    },
    {
      key: 'image.4',
      name: 'health_cert_upload',
      type: 'file:all',
      label: 'Health Certificate',
      required: false,
      hint: 'Only required IF your business involves food'
    },
    // # Multiple Attachment Uploads enabled on POST route
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
