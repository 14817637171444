import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class MedicalCertificate extends React.Component {
  resource = '/medical_certificates'
  form_key = 'medical_certificate'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'cert_class',
        label: 'Class of Medical Certificate Applied for',
        options: ['1','2','3']
    },
    {
        name: 'name',
        label: 'Name(Last, First, Middle)'
    },
    {
        name: 'pel_num',
        label: 'PEL Number'
    },
    {
        name: 'citizenship'
    },
    {
        name: 'address',
        label: 'Address(Number and Street)'
    },
    {
        name: 'city',
        label: 'City and State/Providence'
    },
    {
        name: 'country',
        label: 'Country and Postal Code'
    },
    {
        name: 'tele',
        label: 'Telephone No. & Email'
    },
    {
        name: 'dob',
        label: 'Dte of Birth',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'hair'
    },
    {
        name: 'eyes'
    },
    {
        name: 'sex',
        options: ['male', 'female']
    },
    {
        name: 'licence_type',
        label: 'Types of licences you currently hold',
        multi: true,
        options: [
            'None',
            'Airline Transport',
            'Commercial Pilot',
            'Multi-crew Pilot',
            'ATC',
            'Flight Engineer',
            'Remote Pilot',
            'Cabin Crew',
            'Flight Instructor',
            'Private',
            'Student Pilot Authorisation',
            'Baloon',
            'Glider'
        ]
    },
    {
        name: 'occupation'
    },
    {
        name: 'employer',
        label: 'Employer(Name and Telephone Number)'
    },
    {
        name: 'cert_denied',
        label: 'Has your Airman Certificate ever been denied, suspended, or revoked?',
        type: 'select:bool'
    },
    {
        name: 'if_yes',
        label: 'If yes, provide date',
        type: 'date',
        showYearDropdown: true,
        hide: form.cert_denied != true
    },
    {
        name: 'total_time',
        label: 'Total Pilot Time(Cevilian only)',
        columns: [
            'To date',
            'Past 6 months'
        ]
    },
    {
        name: 'last_med',
        label: 'Date of last medication',
        type: 'date',
        showYearDropdown: true,
        required: false
    },
    {
        name: 'use_meds',
        label: 'Do you currently use any medication (prescription or non-prescription)',
        type: 'select:bool'
    },
    {
        name: 'yes',
        label: 'If yes, list medication(s) used and indicate whether previously reported',
        columns: [
            {
                name: 'medication'
            },
            {
                name: 'previously_reported',
                type: 'select:bool'
            }
        ]
    },
    {
        name: 'near_vision',
        label: 'Do you ever use near-vision contact lenses when flying?',
        type: 'select:bool'
    },
    "::Medical History",
    {
        heading: <p>Have you ever been diagnosed with or had, or do you presently have, any of the following?</p>
    },
    {
        name: 'severe_headach',
        label: 'Frequent or severe headaches',
        type: 'select:bool'
    },
    {
        name: 'dizzy',
        label: 'Dizzyness or fainting spells',
        type: 'select:bool'
    },
    {
        name: 'unconscious',
        label: 'Unconscious for any reason',
        type: 'select:bool'
    },
    
    {
        name: 'eye_vision',
        label: 'Eye or vision trouble except glasses',
        type: 'select:bool'
    },
    {
        name: 'hay_fever',
        label: 'Hay fever or allergy',
        type: 'select:bool'
    },
    {
        name: 'asthma',
        label: 'Asthma or lung disease',
        type: 'select:bool'
    },
    {
        name: 'heart_trouble',
        label: 'Heart or vascular trouble',
        type: 'select:bool'
    },
    {
        name: 'low_blood',
        label: 'Low blood pressure',
        type: 'select:bool'
    },
    {
        name: 'stomach_trouble',
        label: 'Stomach, liver, or intestinal trouble',
        type: 'select:bool'
    },
    {
        name: 'kidney_stones',
        label: 'Kidney stones or blood in urine',
        type: 'select:bool'
    },
    {
        name: 'diabetes',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'nuero_dirorders',
        label: 'Neurological disorders, epilepsy, siezures, stroke, paralysis, etc.',
        type: 'select:bool'
    },
    {
        name: 'mental_disorders',
        label: 'Mental disorders of any sort anxiety, depression, etc.',
        type: 'select:bool'
    },
    {
        name: 'substance_abuse',
        label: 'Substance abuse or dependence; failed a drug test or used illegal substance(s)',
        type: 'select:bool'
    },
    {
        name: 'alcohol_abuse',
        label: 'Alcohol abuse or dependence; failed an alcohol test',
        type: 'select:bool'
    },
    {
        name: 'suicide_attempt',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'motion_sick',
        label: 'Motion Sickness medication required',
        type: 'select:bool'
    },
    {
        name: 'military',
        label: 'Military medical discharge',
        type: 'select:bool'
    },
    {
        name: 'medical_rejection',
        label: 'Medical rejection by military service',
        type: 'select:bool'
    },
    {
        name: 'health_insurance',
        label: 'Rejection for life or health insurance',
        type: 'select:bool'
    },
    {
        name: 'hospital',
        label: 'Admission to hospital',
        type: 'select:bool'
    },
    {
        name: 'other_illness',
        label: 'Other illness, disability or surgery',
        type: 'select:bool'
    },
    {
        name: 'heart_disease',
        label: '',
        type: 'select:bool'
    },{
        name: 'blood_pressure',
        label: 'High blood pressure',
        type: 'select:bool'
    },
    {
        heading: <p>Family medical history: Do any of the following pertain to your family's medical history?</p>
    },
    {
        name: 'inherited_disorders',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'high_chol',
        label: 'High cholesterol levels',
        type: 'select:bool'
    },
    {
        name: 'diabetes_fam',
        label: 'Diabetes',
        type: 'select:bool'
    },
    {
        name: 'glaucoma',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'epilepsy',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'tuberculosis',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'mental_illness',
        label: '',
        type: 'select:bool'
    },
    {
        name: 'allergies',
        label: 'Allergies/asthma/eczema',
        type: 'select:bool'
    },
    {
        name: 'explanations',
        type: 'textarea'
    },
    "::Conviction And/Or Administrative Action History",
    {
        name: 'been_convicted',
        label: 'Have you ever has (1) any convictions involving driving, flying, or operating as an airman while intoxicated by, while impaired by or while under the influence of alcohol or a drug; or (2) any convictions or administrative actions involving an offense(s) which resulted in the denial, suspension, cancellation, or revocation of driving or airman privilages or which resulted in the attendance at an educational or rehabilitation program?',
        type: 'select:bool'
    },
    {
        name: 'non_traffic',
        label: 'Have you ever had ny non-traffic convictions(misdemeanours or felonies)?',
        type: 'slect:bool'
    },
    {
        name: 'explanations_two',
        label: 'Explanations',
        type: 'textarea'
    },
    {
        name: 'visted_pro',
        label: 'Have you ever visited a health professional within the last 3 years?',
        type: 'select:bool'
    },
    {
        heading: <p>If yes, explain below:</p>
    },
    {
        name: 'date_visited',
        label: 'Date',
        type:'date',
        showYearDropdown:true
    },
    {
        name: 'pro_info',
        label: 'Name, address and type of health professional consulted',
        type: 'textarea'
    },
    {
        name: 'reason',
        type: 'textarea'
    }
    
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
