export const FirearmReference = [
  {
    name: "reference_first_name",
    label: "First Name",
  },
  {
    name: "reference_middle_name",
    label: "Middle Name",
  },
  {
    name: "reference_last_name",
    label: "Last Name",
  },
  {
    name: "reference_first_number",
    label: "Primary Phone",
    is: "phone",
  },
  {
    name: "reference_secondary_number",
    label: "Secondary Phone",
    is: "phone",
  },
  {
    name: "reference_email",
    label: "Email Address",
  },
];
