import React, { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import Switch from 'react-switch'

import toastr from '@modules/toastr'
import Swal from '@sweetalert'

const noop = () => {}

export default function withAdminEditForms(Component) {
  if (!$app.roles?.includes('moa')) {
    return props => <Component {...props} />
  }

  return function AdminFormsProvider(props) {
    const [modal, setModal] = useState('')
    const [origins, setOrigins] = useState([])
    const [ports, setPorts] = useState([])
    const [ids, setIds] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      async function getFreshMetadata() {
        const { data } = await $app.axios.get(
          `/services/metadata?service[name]=${props.service.name}`
        )

        const o = data.metadata?.find(m => m.desc == 'country origin of goods')
        const p = data.metadata?.find(m => m.desc == 'entry ports')

        setOrigins(o.data)
        setPorts(p.data)
        setIds({ origins: o.id, ports: p.id })
      }

      getFreshMetadata()
    }, [props.service.name])

    const actions = [
      {
        text: 'Edit Active Countries of Origins',
        icon: 'far fa-flag',
        test: origins.length,
        fn: () => setModal('origins'),
      },
      {
        text: 'Edit Active Ports of Entry',
        icon: 'anchor',
        test: ports.length,
        fn: () => setModal('ports'),
      },
    ]

    const close = () => setModal('')

    const confirmMutations = async () => {
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Confirm Changes',
        text: 'Are you sure you want to apply changes?',
        showCancelButton: true,
        confirmButtonText: 'Submit',
      })

      if (isConfirmed) {
        const json_data =
          modal == 'origins'
            ? origins.map(item => JSON.stringify(item))
            : ports.map(item => JSON.stringify(item))

        try {
          setLoading(true)
          await $app.axios.put('/services/metadata/' + ids[modal], {
            json_data,
          })

          toastr.success('Success', 'Successfully updated data')
        } catch (err) {
          toastr.error('Error', 'Unable to update data')
        } finally {
          setModal('')
          setLoading(false)
        }
      }
    }

    const modalContent = heading => (
      <div style={{ width: 800, maxWidth: '100%' }}>
        <div className='modal-header'>
          <h4>{heading}</h4>
        </div>
        <div
          className='modal-body overflow-y-scroll divide-y divide-gray-400'
          style={{ maxHeight: '50vh' }}
        >
          {switches}
        </div>
        <div className='modal-footer'>
          <button className='btn btn-round mr-2' onClick={close}>
            Cancel
          </button>
          <button
            className='btn custom-btn btn-round'
            onClick={confirmMutations}
          >
            Submit
          </button>
        </div>
      </div>
    )

    let switches = null

    if (modal == 'origins') {
      const set = (index, active) => () => {
        const list = [...origins]
        list[index].active = active

        setOrigins(list)
      }

      switches = origins.map((o, i) => (
        <div
          key={i}
          className='flex flex-row justify-between items-center rounded-md my-2 p-2 hover:bg-gray-200 cursor-pointer'
          onClick={set(i, !o.active)}
        >
          <span>{o.country}</span>
          <Switch checked={o.active} onChange={noop} onColor='#00AEEF' />
        </div>
      ))
    }

    if (modal == 'ports') {
      const set = (index, active) => () => {
        const list = [...ports]
        list[index].active = active

        setPorts(list)
      }

      switches = ports.map((p, i) => (
        <div
          key={i}
          className='flex flex-row justify-between items-center rounded-md my-2 p-2 hover:bg-gray-200 cursor-pointer'
          onClick={set(i, !p.active)}
        >
          <div>
            {p.city}, {p.island}
            <br />
            <em>
              {p.address}
              <span className='text-gray-600'>({p.port_type})</span>
            </em>
          </div>
          <Switch checked={p.active} onChange={noop} onColor='#00AEEF' />
        </div>
      ))
    }

    return (
      <React.Fragment>
        <Component {...props} admin_actions={actions} loading={loading} />
        <Modal open={modal == 'origins'} onClose={close} center>
          {modalContent('Edit Active Countries Of Origin')}
        </Modal>
        <Modal open={modal == 'ports'} onClose={close} center>
          {modalContent('Edit Active Ports of Entry')}
        </Modal>
      </React.Fragment>
    )
  }
}
