import React, { Fragment } from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
import generateRange from '@helpers/range'
import Swal from '@sweetalert'

export default class CulturalGrant extends React.Component {
  resource = '/cultural_grants'
  form_key = 'cultural_grant'

  // defaults = {
  //   "project_category": "individual",
  //   "website": "testsite.com",
  //   "island": "New Providence",
  //   "settlement": "Marathon Estates",
  //   "contact_person": true,
  //   "contact_first_name": "",
  //   "contact_last_name": "",
  //   "position": "Director",
  //   "dept_culture_funding": true,
  //   "dept_culture_reasons": [
  //     {
  //       "amount": "5000",
  //       "year": "2017",
  //       "reason": "Youth Concert"
  //     }
  //   ],
  //   "funding_reported": true,
  //   "artist_registry": true,
  //   "mission_statement": "MISSION STATEMENT",
  //   "project_discipline_list": [
  //     "Performing Arts",
  //     "Audio/Visual Production",
  //     "Film/Cinematography"
  //   ],
  //   "project_discipline_list_other": "Other Discipline ",
  //   "project_start_date": "2023-02-01T05:00:00.000Z",
  //   "project_end_date": "2023-08-01T04:00:00.000Z",
  //   "project_desc": "TEST DESCRIPTION",
  //   "project_primary_activities": [
  //     {
  //       "item": "TEST"
  //     }
  //   ],
  //   "project_primary_contributors": [
  //     {
  //       "item": "TEST"
  //     }
  //   ],
  //   "past_experience": [
  //     {
  //       "project": "TEST"
  //     }
  //   ],
  //   "partnerships_formed": [
  //     {
  //       "partnership": "TEST"
  //     }
  //   ],
  //   "project_funding": "Fundraiser",
  //   "project_funding_secured": "1000",
  //   "request_amount_impact": "We will be sad :(",
  //   "target_audience": "Young Adults",
  //   "number_of_engagements": "500",
  //   "community_benefit": "Fun Times & Good Vibes",
  //   "success_evaluation": "Ticket Sales",
  //   "project_continuation": false,
  //   "sustainability_insurance": "",
  //   "community_opportunities": [
  //     {
  //       "item": "Fun"
  //     },
  //     {
  //       "item": "Family Time"
  //     }
  //   ],
  //   "experience_level": "Mid Career",
  //   "training_completed": "None",
  //   "support_materials_list": [
  //     {
  //       "item": "https://youtube.com"
  //     }
  //   ],
  //   "project_revenue": [
  //     {
  //       "budget_item": "Cultural Grant",
  //       "budget_notes": "Test",
  //       "projected_amount": "5000",
  //       "actual_amount": "5000"
  //     },
  //     {
  //       "budget_item": "Ticket Sales",
  //       "budget_notes": "Test",
  //       "projected_amount": "10000",
  //       "actual_amount": "10000"
  //     }
  //   ],
  //   "project_expenses": [
  //     {
  //       "budget_item": "Venue",
  //       "budget_notes": "Test",
  //       "projected_amount": "2000",
  //       "actual_amount": "2000"
  //     }
  //   ],
  // }

  free = true
  skip_location = true
  // hide_buttons = false
  hide_buttons = {
    status: true,
    deny: !$app.hasAnyRole(['minister', 'deputy_director', 'director']),
  }

  customTitle = () => {
    if ($app.hasRole('officer')) return 'Recommend To ADOC';
    // if ($app.hasRole('senior_officer')) return 'Recommend To Director';
    if ($app.hasRole('department_officer')) return 'Recommend To ADOC';
    if ($app.hasAnyRole(['deputy_director'])) return 'Recommend To Director';
    if ($app.hasAnyRole(['director'])) return 'Recommend To Secretary';
    if ($app.hasAnyRole(['under_secretary', 'permanent_secretary'])) return 'Recommend To Minister';
    if ($app.hasRole('minister')) return 'Approve Application';
  }

  custom_actions = [
    {
      text: 'Assign Department Officer',
      icon: 'user-tie',
      roles: [['deputy_director', 'director']],
      test: r => ['officer viewed'].includes(r.application_decision),
      fn: async record => {
        const departmentOfficerList = {};
        const { data } = await $app.axios.get('/cultural_grants/show_department_officers')
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          departmentOfficerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Department Officer',
          html: `
            Select which department officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: departmentOfficerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },
    // Recommend to Director as Assistant/Deputy Director
    {
      text: 'Recommend To Director',
      icon: 'user-tie',
      roles: [['deputy_director']],
      test: r => ['department officer viewed'].includes(r.application_decision),
      fn: async record => {
        let grantAmount = null;

        const { value, isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Recommend To Director',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I recommend this applicant",
            'denied': "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (isDismissed) return

        // Set value of Granted Amount
        const grantPrompt = await Swal.fire({
          icon: 'question',
          title: 'Amount to be granted?',
          html: `
            What is your recommended amount to be granted regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'number',
          inputValue: record?.grant_amount_given,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (grantPrompt.isDismissed) return

        grantAmount = grantPrompt.value

        await this.submitToNewLevel(value, record, null, grantAmount)
      },
    },
    {
      text: this.customTitle(),
      icon: 'user-tie',
      roles: [['officer', 'department_officer', 'director', 'under_secretary', 'permanent_secretary', 'minister']],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: async record => {
        let grantAmount = null;

        const { value, isDismissed } = await Swal.fire({
          icon: 'question',
          title: this.customTitle(),
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I recommend this applicant",
            'denied': "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (isDismissed) return

        // Set value of Granted Amount
        if ($app.hasAnyRole(['director', 'department_officer', 'under_secretary', 'permanent_secretary'])) {
          const { value, isDismissed } = await Swal.fire({
            icon: 'question',
            title: 'Amount to be granted?',
            html: `
              What is your recommended amount to be granted regarding the applicant,
              <strong>${fullName(record.user, 'initial')}</strong>?
            `,
            input: 'number',
            inputValue: record?.grant_amount_given,
            showCancelButton: true,
            confirmButtonText: 'Send',
          })
    
          if (isDismissed) return

          grantAmount = value
        }

        await this.submitToNewLevel(value, record, null, grantAmount)
      },
    },
  ]

  submitToNewLevel = async (value, record, officerID = null, grantAmount = null) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    const newAppDecision = () => {
      if ($app.hasRole('officer')) return 'officer viewed';
      if ($app.hasRole('department_officer')) return 'department officer viewed';
      if ($app.hasRole('deputy_director')) return 'deputy director viewed';
      if ($app.hasRole('director')) return 'director viewed';
      if ($app.hasAnyRole(['under_secretary', 'permanent_secretary'])) return 'secretary viewed';
      if ($app.hasRole('minister')) return value;
    }

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          department_officer_id: officerID,
          grant_amount_given: grantAmount,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = newAppDecision()

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application successfully submitted.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application',
        'error'
      )
      return
    }
  }

  validate = form => {
    const update = {
      project_discipline_list: form.project_discipline_list.slice(0, 3),
    }
    return update
  }

  foremost = null

  columns = props => [
    {
      name: 'ID',
      selector: row => row.form_num,
      sortable: true,
      searchable: r => r.form_num,
    },
    {
      name: 'Grant Amount',
      selector: row => row.grant_amount_given ? `$${parseFloat(row.grant_amount_given).toFixed(2)}` : 'N/A',
      sortable: true,
      searchable: r => r.grant_amount_given,
    },
  ]

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'project_category',
      options: [
        { label: 'Individual', value: 'individual' },
        { label: 'Organization', value: 'organization' },
        { label: 'Homecoming/Regatta', value: 'home coming/regatta' },
        { label: 'Festival', value: 'festival' },
        { label: 'Government Entity', value: 'government entity' },
        { label: 'Auspice', value: 'auspice' }
      ]
    },
    {
      name: 'website',
      required: false
    },


    '::Contact Person',
    {
      name: 'contact_person',
      type: 'select:bool',
      label: 'I, the applicant, would be the main contact person for the project',
      required: false,
      view: {
        label: 'Applicant is Primary Contact?'
      }
    },
    {
      name: 'contact_first_name',
      label: `First Name`,
      hide: form.contact_person === true
    },
    {
      name: 'contact_last_name',
      label: `Last Name`,
      hide: form.contact_person === true
    },
    {
      name: 'position'
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'settlement',
      label: 'Settlement / Constituency',
      hide: form.contact_person === true
    },
    {
      name: 'po_box',
      label: 'P.O. Box',
      hide: form.contact_person === true
    },
    {
      name: 'street_address',
      hide: form.contact_person === true
    },
    {
      name: 'contact_phone',
      label: 'Contact Phone Number',
      type: 'phone',
      hide: form.contact_person === true
    },

    '::Financial Request',
    {
      name: 'dept_culture_funding',
      type: 'select:bool',
      label: 'Have you received funding from the Dept. of Culture before?'
    },
    {
      name: 'dept_culture_reasons',
      hide: !form.dept_culture_funding,
      label: 'Past/Current Grants Received',
      columns: [
        {
          name: 'amount',
          width: 128,
          is: 'currency'
        },
        {
          name: 'year',
          width: 128,
          options: generateRange(1970, new Date().getFullYear())
            .reverse().map(n => String(n))
        },
        {
          name: 'reason',
        }
      ]
    },
    {
      name: 'funding_reported',
      type: 'select:bool',
      hide: !form.dept_culture_funding,
      label: 'Did you submit a report(s) after receiving funding?',
      hint: 'Future funding will not be considered without a received report'
    },
    {
      name: 'artist_registry',
      type: 'select:bool',
      label: 'Have you registered with the Cultural Artist Registry?',
      callback: bool => {
        if (bool) return
        // const { path } = $app.getService('cultural registry')

        Swal.fire({
          title: 'Please Note',
          icon: 'info',
          html: (
            <Fragment>
              In order to be considered for a Cultural Grant, you will also be
              required to submit a <em>Bahamas National Cultural Registry </em>
              application, a service which is also available on MyGateway
            </Fragment>
          ),
        })
      }
    },

    '::Organization Information',
    {
      name: 'mission_statement',
      type: 'textarea'
    },
    {
      name: 'company_date_registered',
      type: 'date',
      label: 'Date Registered',
    },

    '::Project Details',
    {
      name: 'project_discipline_list',
      type: 'select',
      multi: true,
      max: 3,
      options: [
        'Performing Arts',
        'Visual Arts',
        'Literature/Publication',
        'Film/Cinematography',
        'Audio/Visual Production',
        'Digital Arts',
        'Craft',
        'Architecture',
        'History/Heritage Research',
        'Culinary Arts',
        'Cultural Workshop',
        'Cultural Exchange',
        'Exhibition',
        'Fashion',
        'Music Production',
        'Festival/HomeComing',
        'Other'
      ],
      hint: 'Select up to three (3) project disciplines'
    },
    {
      name: 'project_discipline_list_other',
      hide: !form.project_discipline_list?.includes('Other'),
      label: 'Please Specify'
    },
    {
      name: 'project_start_date',
      type: 'date',
      label: 'Start Date',
    },
    {
      name: 'project_end_date',
      type: 'date',
      label: 'End Date',
    },
    {
      name: 'project_desc',
      type: 'textarea',
      label: 'Description of Project'
    },
    {
      name: 'project_primary_activities',
      label: `Details & Primary Activities`,
      columns: ['item'],
      format: v => v.item,
      view: {
        value: v => v?.join('\n'),
      }
    },
    {
      name: 'project_primary_contributors',
      label: 'Contributors And/Or Artists Involved',
      columns: ['item'],
      format: v => v.item,
      view: {
        value: v => v?.join('\n'),
      }
    },
    {
      name: 'past_experience',
      label: 'Past projects planned & delivered',
      columns: ['project'],
      format: v => v.project,
    },
    {
      name: 'partnerships_formed',
      label: 'Partnerships that will be formed',
      columns: ['partnership'],
      format: v => v.partnership,
    },
    {
      name: 'project_funding',
      type: 'textarea',
      label: 'How will the project be funded?',
    },
    {
      name: 'project_funding_secured',
      label: 'How much funding have you already secured?',
      is: 'currency'
    },
    {
      name: 'request_amount_impact',
      type: 'textarea',
      label: `
        How will your project be impacted if the
        requested amount is not granted?
      `,
      hint: `
        Note that the Division of Culture will only
        fund a percentage you project.
      `
    },
    {
      name: 'target_audience',
      label: 'Who is your target audience?'
    },
    {
      name: 'number_of_engagements',
      label: 'How many participants will the project engage?'
    },
    {
      name: 'community_benefit',
      type: 'textarea',
      label: 'How will the community benefit from this project?',
    },
    {
      name: 'success_evaluation',
      type: 'textarea',
      label: `How will the project's success be evaluated?`,
    },
    {
      name: 'project_continuation',
      label: `Will this project continue beyond the grant's term? `,
      type: 'select:bool'
    },
    {
      name: 'sustainability_insurance',
      label: 'How will the project sustain itelf?',
      hide: form.project_continuation !== true
    },
    {
      name: 'community_opportunities',
      label: `
        What arts education/opportunities will
        the project provide to the community?
      `,
      columns: ['item'],
      format: v => v.item,
      view: {
        value: v => v?.join('\n'),
      }
    },
    {
      name: 'experience_level',
      label: 'What is your experience level?',
      hide: form.client_type == 'business',
      options: [
        'Emerging',
        'Mid Career',
        'Established'
      ]
    },
    {
      name: 'training_completed',
      type: 'textarea',
      label: 'What training (formal or informal) have you completed?',
      hide: form.client_type == 'business',
    },
    {
      name: 'support_materials_list',
      label: 'Supporting Material',
      columns: ['item'],
      format: v => v.item,
      required: false,
      hint: 'YouTube/Social Media Links to videos or other media can be placed here for consideration',
    },


    '::Financial Information',
    {
      name: 'project_revenue',
      label: 'Projected Revenue',
      columns: [
        'budget_item',
        {
          name: 'budget_notes',
          label: 'Notes',
        },
        {
          name: 'projected_amount',
          label: 'Projected $',
          is: 'currency'
        },
        {
          name: 'actual_amount',
          label: 'Final $',
          is: 'currency'
        }
      ]
    },
    {
      name: 'project_expenses',
      label: 'Projected Expenses',
      columns: [
        'budget_item',
        {
          name: 'budget_notes',
          label: 'Notes',
        },
        {
          name: 'projected_amount',
          label: 'Projected $',
          is: 'currency'
        },
        {
          name: 'actual_amount',
          label: 'Final $',
          is: 'currency'
        }
      ]
    },

    '::Uploads',
    {
      name: 'vendor_form_upload',
      label: 'Vendor Form',
      key: 'image.0',
      type: 'file:all'
    },
    {
      name: 'business_nib_upload',
      label: form.client_type == 'business' ? 'Business NIB' : 'National Insurance Smart Card',
      key: 'image.2',
      type: 'file:all'
    },
    {
      name: 'passport_upload',
      label: 'Passport',
      key: 'image.3',
      type: 'file:image'
    },
    {
      name: 'bank_info_upload',
      label: 'Bank Account Information and Proof of Recent Activity',
      key: 'image.1',
      type: 'file:all',
      hint: 'Card with Bank name, branch, account number and transit code or Bank statement'
    },
    {
      name: 'budget_upload',
      label: 'Budget',
      key: 'image.4',
      type: 'file:all',
    },
    {
      name: 'zip_upload',
      key: 'image.5',
      label: 'Supporting Documents',
      required: false,
      type: 'file', 
      // accept: '.zip', 
      allow: 'application/zip',
      allow_msg: 'Please upload a valid .zip file',
      hint: 'Accepts .zip',
      maxSize: 10 
    },
    {
      name: 'report_upload',
      key: 'image.6',
      type: 'file:all',
      hide: form.funding_reported,
    },
    

    '::Agreement',
    {
      name: 'advertise_direction',
      label: 'How do you intend to promote the Division of Culture, if you are granted?',
      type: 'textarea'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
