import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class TourCompany extends React.Component {
  id = "TourCompanyExemption";
  service = "tour company exemption";
  title = "Tour Company Exemption";
  agency = "mofrd";
  item = "";
  resource = "/tour_company_exemptions";
  form_key = "tour_company_exemption";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "client_type",
      label: "Name of Franchise Holder",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "type_of_franchise",
      label: "Type of Franchise",
    },
    {
      name: "plate_number",
      label: "Taxi/Livery/Tour Car/Omnibus Plate No.",
    },
    {
      name: "bl_number",
      label: "Business License No.",
    },
    {
      name: "vehicle_type",
      label: "Type of Vehicle",
    },
    {
      name: "vehicle_year",
      label: "Year of Vehicle",
    },
    {
      name: "vehicle_serial_number",
      label: "Serial Number",
    },
    {
      name: "exemption_rtd_upload",
      label:
        "Original Exemption Form (Must be stamped by Road Traffic Department)",
      type: "file:pdf",
    },
    {
      name: "franchise_letter_upload",
      label: "Current Franchise Letter (Must be stamped by RTD)",
      type: "file:pdf",
    },
    {
      name: "identification_upload",
      label: "Copy of Identification for Franchise Owner",
      type: "file:pdf",
    },
    {
      name: "vehicle_invoice_upload",
      label: "Copy of Invoice for Vehicle",
      type: "file:pdf",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
