import * as TYPES from '@action-types'

const initial = {
  loading: false,
  data: [],
  success_message: null,
  success: false,
  error: null,
  citizens: [],
  countries: [],
  islands: [],
  unconfirmed: false,
}

const reducer = (state = initial, { type, payload }) => {
  // Reset certain props on every action
  Object.assign(state, {
    loading: false,
    unconfirmed: false,
    success_message: null,
    error: null,
  })

  switch (type) {
    case TYPES.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case TYPES.GET_RESPONSE_ERROR:
      return {
        ...state,
        error: payload.response.data.error,
      }

    case TYPES.GET_ALL_USERS:
      return {
        ...state,
        citizens: payload.response.all_profiles,
      }
    case TYPES.GET_USER_DATA:
      return {
        ...state,
        loading: false,
        userData: payload.response.user,
        success_message: null,
        error: null,
      }
    case TYPES.GET_USER_PROFILE_DATA:
      return {
        ...state,
        loading: false,
        userProfile: payload.response,
        success_message: null,
        error: null,
      }
    case TYPES.UPDATE_USER_PROFILE:
      return {
        ...state,
        loading: false,
        success: true,
        success_message: 'Successfully updated',
        userData: payload.response.user,
      }
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case TYPES.UNCONFIRMED_LOGIN:
      return {
        ...state,
        unconfirmed: true,
      }
    case TYPES.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        success_message: 'Successfully Registered',
      }
    case TYPES.CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        success_message: null,
        error: null,
      }
    case TYPES.GET_USER_PARENT_DATA:
      return {
        ...state,
        loading: false,
        parent_info: payload.response || {},
      }
    case TYPES.CREATE_PARENT_INFO:
      return {
        ...state,
        loading: false,
        success_message: 'Successfully Created',
        parent_info: payload.response,
      }
    case TYPES.UPDATE_PARENT_INFO:
      return {
        ...state,
        loading: false,
        success_message: 'Successfully Updated',
        parent_info: payload.response,
      }
    case TYPES.GET_COUNTRY_ISLAND_DATA:
      return {
        ...state,
        ...payload.response,
        loading: false,
        lists: payload.response,
      }
    case TYPES.GET_ADDRESS_INFO:
      return {
        ...state,
        loading: false,
        work_info: payload.response.addresses[1],
        address_info: payload.response.addresses[0],
      }
    case TYPES.UPDATE_ADDRESS_INFO:
      return {
        ...state,
        loading: false,
        success_message: 'Successfully Updated',
        success: true,
        address_info: payload.response.address,
      }
    case TYPES.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        otp_success: true,
        success_message: 'Successfully Logged In',
        currentUser: payload.response,
      }
    case TYPES.IS_VERIFIED:
      return {
        ...state,
        loading: false,
        is_verified: payload.response.data.is_verified,
      }
    case TYPES.GET_PERSON_STATUS:
      return {
        ...state,
        loading: false,
        in_person_status: payload.status,
      }

    default:
      return state
  }
}

export default reducer
