import React, { useState } from 'react'
import Swal from 'sweetalert2'

import Form from '@modules/validator-form'
import STRINGS from '@constants/strings'

const SelectProxyUser = ({ form, children, submit }) => {
  const [active, select] = useState(null)
  const [nonce, setNonce] = useState(null)

  const clearChild = () => select(null)
  const onSelect = child => () => {
    select(child.id)
    setNonce(nonce + 1)
  }

  const onSubmit = async json => {
    if (json && !active) {
      const { value: accept } = await Swal.fire({
        icon: 'question',
        title: 'WARNING',
        input: 'checkbox',
        inputValue: false,
        inputPlaceholder: 'I understand and agree to the terms.',
        html: STRINGS.INFO.PROXY_REQUEST_TERMS,
        showCancelButton: true,
        confirmButtonText: 'Request',
        inputValidator: acc =>
          acc == 1 ? null : 'You may not proceed without accepting the terms',
      })

      if (accept) {
        return submit({
          ...json,
          accept_proxy_terms: true,
        })
      } else return
    }

    const child = children.find(c => c.id == active)
    submit(child)
  }

  const cards = children?.map(c => (
    <UserCard
      key={c.id}
      child={c}
      onSelect={onSelect}
      active={active == c.id}
    />
  ))

  const fields = () => [
    {
      name: 'nib',
      label: 'NIB',
      is: 'integer',
      callback: clearChild,
    },
    {
      name: 'first_name',
      callback: clearChild,
    },
    {
      name: 'last_name',
      callback: clearChild,
    },
  ]

  return (
    <section
      id='form-wizard__proxy-select'
      className='flex flex-col items-center'
    >
      <p>Select a user you wish to make an application for</p>
      <div className='flex flex-row flex-wrap justify-center'>{cards}</div>
      <div className='separator-solid my-2 w-full'></div>
      <span>OR</span>
      <p>Enter the name and NIB# of the individual</p>
      <Form name={form} key={nonce} save={onSubmit} fields={fields} />
    </section>
  )
}

function UserCard({ child, onSelect, active }) {
  return (
    <article
      key={child.id}
      className={'sub-account-card' + (active ? ' active' : '')}
      onClick={onSelect(child)}
    >
      <div>{child.first_name.charAt(0).toUpperCase()}</div>
      <p>{child.first_name.capitalize()}</p>
    </article>
  )
}

export default SelectProxyUser
