import React from 'react'
import { connect } from 'react-redux'
import sanitize from 'sanitize-html'

import { getAgencyFaqs } from '@actions/agencies'
import { extractKeys } from '@helpers/objects'

import '@styles/faqs.css'

const cases = {
  snake: str =>
    str
      .toLowerCase()
      .replace(/[^-_ 0-9a-z]+/, '')
      .replace(/[ _-]+/g, '_'),
  dash: str =>
    str
      .toLowerCase()
      .replace(/[^-_ 0-9a-z]+/, '')
      .replace(/[ _-]+/g, '-'),
  initial: str =>
    str
      .toLowerCase()
      .split(/[ _-]/)
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' '),
}

class FAQs extends React.Component {
  constructor(props) {
    super(props)
    !props.faqs.length && props.getAgencyFaqs()
  }

  generate = {
    tabs: ({ name, id, icon }, i) => (
      <a
        key={i}
        className='nav-link'
        id={'v-pills-faqs-tab-' + cases.dash(id)}
        data-toggle='pill'
        href={'#v-pills-faqs-content-' + cases.dash(id)}
        role='tab'
        aria-controls={'v-pills-faqs-content-' + cases.dash(id)}
      >
        <i className={'fas fa-' + icon}></i>
        <span>{name}</span>
      </a>
    ),

    content: ({ id, faqs }, i) => {
      const list = faqs.map(({ heading, question, answer }, i) =>
        heading ? (
          <strong key={i} className='block mb-4 ml-1'>
            {heading}
          </strong>
        ) : (
          <div className='card' key={i}>
            <div
              id={'heading-' + i}
              className='card-header collapsed'
              data-toggle='collapse'
              data-target={'#collapse-' + i}
              aria-controls={'collapse-' + i}
              aria-expanded='false'
              role='button'
            >
              <div className='span-title'>{question}</div>
              <div className='span-mode'>
                <i className='fas fa-plus'></i>
                <i className='fas fa-minus'></i>
              </div>
            </div>

            <div
              id={'collapse-' + i}
              className='collapse'
              aria-labelledby={'heading-' + i}
              data-parent={'#accordion-' + cases.dash(id)}
            >
              <div
                className='card-body whitespace-pre-wrap'
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    answer
                      .replaceAll('&lt;', '<')
                      .replaceAll('&gt;', '>')
                      .replaceAll('&#39;', "'")
                  ),
                }}
              />
            </div>
          </div>
        )
      )

      return (
        <div
          key={i}
          className={'tab-pane fade' + (!i ? ' active show' : '')}
          id={'v-pills-faqs-content-' + cases.dash(id)}
          role='tabpanel'
          aria-labelledby={'v-pills-faqs-tab-' + cases.dash(id)}
        >
          <h5>{cases.initial(id)}</h5>
          <hr />
          <div
            id={'accordion-' + cases.dash(id)}
            className='accordion accordion-primary'
          >
            {list}
          </div>
        </div>
      )
    },
  }

  render() {
    const { props, generate } = this
    const { faqs } = props

    const list = faqs?.reduce((arr, { services, name }) => {
      if (!services.length) return arr

      const target = $app.agencies.find(a => a.id == name)
      if (!target) return arr

      const faqs = services.reduce(
        (list, { name, faqs }) => [
          ...list,
          { heading: $app.getServiceProp(name, 'title') || name.capitalize() },
          ...faqs,
        ],
        []
      )

      return [...arr, { ...target, faqs }]
    }, [])

    return (
      <div id='faqs-page' className='page-inner'>
        <h4 className='page-title'>FAQS</h4>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card card-space'>
              <div className='card-body'>
                <div className='relative row items-start'>
                  <div className='col-12 col-md-3 md:sticky md:top-0'>
                    <div
                      className='nav flex-column nav-pills nav-primary nav-pills-no-bd nav-pills-icons'
                      id='v-pills-tab'
                      role='tablist'
                      aria-orientation='vertical'
                    >
                      {list.map(generate.tabs)}
                    </div>
                  </div>
                  <div className='col-12 col-md-9'>
                    <div className='tab-content' id='v-pills-tabContent'>
                      {list.map(generate.content)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => extractKeys(state.agencies, 'faqs'), {
  getAgencyFaqs,
})(FAQs)
