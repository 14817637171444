import React from 'react'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@/helpers/extract-service-props'

export default class NewNotary extends React.Component {
  resource = '/new_notaries'
  form_key = 'new_notary'
  fee = 125
  custom_acceptance = true

  hide_buttons = {
    status: true
  }

  metadata = {
    denied: { license_code: '#####' }
  }

  custom_actions = [
    {
      text: 'Approve Application',
      icon: 'check',
      fn: async record => {
        const { value: license_code } = await Swal.fire({
          title: "Please enter the Client's Permit Number",
          input: 'text',
          inputLabel: 'Permit Number',
          inputPlaceholder: 'Permit Number',
        })

        if (!license_code) return
        
        try {
          await $app.axios.put(this.resource + '/update_application', {
            [this.form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
              application_status: true,
              license_code
            }
          })

          Swal.fire('Success', 'Application Status Updated', 'success')
        } catch (err) {
          console.error(err)
          Swal.fire('Error', 'Failed To Update Application Status', 'error')
        }
      }
    }
  ]

  fields = (form, viewMode) => [
    {
      name: 'license_code',
      hide: !viewMode || !form.license_code,
    },
    {
      name: 'full_name',
      hint: "Enter the full name on your passport if it's not the same as your NIB",
      view: !!form?.full_name,
      required: false,
    },
    {
      name: 'work_street',
      label: 'Street Address',
    },
    {
      name: 'work_p_o_box',
      label: 'P.O. Box',
    },
    {
      name: 'work_phone',
      label: 'Phone Number',
      type: 'phone',
    },
    {
      name: 'cover_note_upload',
      label: 'Cover Note (Optional)',
      type: 'file:pdf',
      required: false,
      key: 'image.0',
    },
    {
      name: 'call_certificate_upload',
      label: 'Call Certificate',
      type: 'file:image',
      key: 'image.1',
    },
    {
      name: 'educational_certificate_upload',
      label: 'Educational Certificate',
      type: 'file:image',
      key: 'image.2',
    },
    {
      name: 'grant_license',
      type: 'checkbox',
      view: false,
      label:
        'I confirm my intention to apply for a Notary Public Licence under the Notaries Public Act, 1971.',
    },
    {
      name: 'seal_impression',
      type: 'checkbox',
      label: 'Upon request, I will provide a seal impression',
    },
    {
      name: 'set_out',
      type: 'checkbox',
      use_as_acceptance: true,
      view: false,
      label:
        'The information provided above is correct and true to the best of my knowledge, information and belief.',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
