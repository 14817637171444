import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import countries from "@/constants/countries";
import islands from "@constants/islands";
import { AnimalRequestDetails } from "./_item-imports";

export default class DomesticHorses extends React.Component {
  id = "DomesticHorseImportPermit";
  service = "domestic horse import permit";
  title = "Application for Permit to import Domestic Horses";
  agency = "moa";
  resource = "/domestic_horse_import_permits";
  form_key = "domestic_horse_import_permit";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "animal_type",
      label: "Type of Horse",
      options: [
        "Arabian Horse",
        "Thoroughbred",
        "Mustang",
        "Pony",
        "Appaloosa",
        "Shire Horse",
        "Standardbred",
        "Belgian Horse",
        "Gypsy Horse",
      ],
    },
    {
      name: "number_of_animals",
      label: "Number of Animals",
      is: "integer",
    },
    {
      name: "animal_breed",
      label: "Breed/Cross",
    },
    ":: Age",
    {
      name: "animal_age_years",
      label: "Years",
    },
    {
      name: "animal_age_months",
      label: "Months",
    },
    "::SPACER",
    {
      name: "animal_sex",
      options: ["Male", "Female"],
    },
    {
      name: "neutered",
      label: "Neutered?",
      type: "select:bool",
    },
    {
      name: "markings",
    },
    {
      name: "initial_country_of_export",
      label: "Country of Export",
      options: countries,
    },
    {
      name: "country_of_birth",
      label: "Country of birth of animal",
      options: countries,
    },
    {
      name: "countries_visited",
      label:
        "Countries visited within the last 6 months prior to the arrival in the Bahamas",
      options: countries,
    },
    {
      name: "facility_name",
      label: "Facility/Source name where animals are currently",
    },
    {
      name: "facility_address",
      label: "Physical Address where animals are currently",
    },
    {
      name: "entry_port",
      label: "Anticipated Port of Entry",
      options: islands,
    },
    {
      name: "date_of_arrival",
      label: "Anticipated Date of Arrival",
      type: "date",
      maxDate: new Date(),
    },
    "::Purpose of importing animals",
    {
      name: "pet",
      label: "Pet",
      type: "checkbox",
      required: false,
    },
    {
      name: "commercial",
      label: "Commercial",
      type: "checkbox",
      required: false,
    },
    {
      name: "other_purpose",
      label: "Other",
      type: "checkbox",
      required: false,
    },
    {
      name: "purpose_other",
      label: "Other Purpose",
      hide: !form.other_purpose,
    },
    {
      name: "island_destinations",
      label: "Island Destinations in the Bahamas",
      options: islands,
    },
    {
      name: "animal_request_details",
      columns: AnimalRequestDetails,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
