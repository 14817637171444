import React from 'react'
import AgriImport from './_agri-import'

export default function BananaImport(props) {
  const pass = {
    ...props,

    resource: '/banana_imports',
    form_key: 'banana_import',
    _sid: 'banana import',
    form_name: 'Banana',

    importProps: {
      metric_list: [
        'Each Per Item',
        'Cases per item',
        'Bags per item',
        'Bins per item',
        'Pounds per item',
        'Sacks per item',
      ],
      datalist: {
        Banana: 'Musa spp.',
      },
      cb: true,
    },
  }

  return <AgriImport {...pass} />
}
