import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { logoutUser } from '@actions/users'
import Loader from '@shared/loader'

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser())
  }, [])

  return <Loader loading />
}

export default Logout
