import React from 'react'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import { common, hull_types } from './duty-free'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import generateRange from '@helpers/range'
import islands from '@constants/islands'
// import ENV from '@constants/env'

const years = generateRange(1900, new Date().getFullYear())
  .map(v => ({
    value: v + '-01-01',
    label: v,
  }))
  .reverse()

export default class DutyFreeCommercialFishing extends React.Component {
  resource = '/duty_free_commercial_fishings'
  form_key = 'duty_free_commercial_fishing'
  free = true

  defaults = {}
  business_only = true
  skip_location = true

  metadata = {
    denied: { license_numb: '####' }
  }

  hide_buttons = {
    approve: true,
    status: true,
    deny: true,
  }

  submitToDirector = async (record, license_numb) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          license_numb,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'officer viewed'
        view.license_numb = license_numb

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval by the Director/Approved Signatories.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          license_numb: record.license_numb,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    this.setState({ loading: false })
  }

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
          license_numb: record.license_numb || 0,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    this.setState({ loading: false })
  }

  custom_actions = [
    {
      text: 'Submit to Director',
      icon: 'id-badge',
      roles: ['officer'],
      test: r => r.payment_status && r.application_decision == 'pending',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to submit?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and the accompanying attachments, and are ready to submit it to a Director/Approved Signatories. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.submitToDirector(record)
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['director'],
      test: r => r.payment_status && (r.application_decision == 'processing' || r.application_decision == 'officer viewed'),
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.approve(record)
          }
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]

  fields = values => [
    ...BUSINESS_FIELDS(values),
    {
      name: 'phone',
      is: 'phone',
      use_profile: 'additional_phone_number',
    },
    {
      name: 'po_box',
      label: 'P. O. Box',
      required: false,
    },
    {
      name: 'nationality',
      use_profile: 'country_of_citizenship',
      disabled: true,
      view: {
        value: v => v?.initialCaps()
      }
    },
    {
      name: 'company_name',
      label: 'Name of Company',
    },
    '::Captain Detais',
    {
      name: 'captain',
      type: 'select:bool',
      label: 'Are you the intended captain?',
      view: {
        hide: !values.captain,
        label: 'Is Own Intended Captain',
        value: v => v ? 'Yes' : 'No',
      },
    },
    {
      name: 'captain_license_numb',
      label: 'Captain Licence #'
    },
    {
      name: 'captain_first_name',
      label: 'First Name',
      hide: values.captain !== false,
    },
    {
      name: 'captain_last_name',
      label: 'Last Name',
      hide: values.captain !== false,
    },
    {
      name: 'captain_license_upload',
      key: 'image.8',
      type: 'file:image',
      label: 'Copy of Captain Licence',
    },
    // {
    //   name: 'captain_nationality',
    //   label: 'Nationality',
    //   hide: values.captain !== false,
    //   options: countries,
    // },
    {
      heading: `
        <div>
          <h5 class='mb-0'>Vessel Details</h5>
          <span class='text-sm'>All length measurements should be in feet.</span>
        </div>
      `,
      view: {
        heading: 'Vessel Details'
      }
    },
    {
      name: 'vessel_builder',
      label: "Vessel Builder's Name",
    },
    {
      name: 'vessel_build_year',
      label: 'Year Built',
      options: years
    },
    {
      name: 'vessel_hull_type',
      label: 'Hull Type',
      options: hull_types,
    },
    {
      name: 'vessel_length',
      is: 'float',
      label: 'Overall Length',
      view: {
        value: v => v + ' ft.'
      }
    },
    {
      name: 'vessel_breadth',
      is: 'float',
      label: 'Breadth',
      required: false,
      hint: 'Omit if not available',
      view: {
        value: v => v + ' ft.'
      }
    },
    {
      name: 'vessel_draft',
      is: 'float',
      label: 'Draft',
      required: false,
      hint: 'Omit if not available',
      view: {
        value: v => v + ' ft.'
      }
    },
    {
      name: 'vessel_engine',
      label: 'Make of Engine(s)',
    },
    {
      name: 'vessel_hp',
      is: 'float',
      label: 'Total Horsepower of Engine(s)',
      view: {
        value: v => v + 'hp'
      }
    },
    {
      name: 'vessel_generators',
      label: 'Make of Generator(s)',
    },
    {
      name: 'vessel_hold',
      label: 'Type of Fish Hold',
      options: ['Cooler', 'Freezer', 'Ice Chest', 'Other'],
    },
    {
      name: 'vessel_capacity',
      is: 'float',
      label: 'Hold Capacity',
      hint: 'In pounds',
      view: {
        value: v => v + ' lbs.'
      }
    },

    '::Registration Details',
    {
      name: 'vessel_registry_port',
      label: 'Port of Registry',
      // options: ports,
      required: false,
      hint: 'Omit if not available',
    },
    {
      name: 'vessel_registry_number',
      label: 'Port Official Number',
      required: false,
      hint: 'Omit if not available',
    },
    {
      name: 'vessel_home_port',
      label: 'Intended Home Port',
      // options: ports,
    },
    {
      name: 'fishing_areas',
      label: 'Main Fishing Areas',
      options: islands,
      multi: true,
      hint: 'Select all that apply'
    },
    {
      name: 'vessel_value',
      is: 'currency',
      label: 'Value of Vessel',
    },
    '::Uploads',
    {
      key: 'image.1',
      type: 'file:all',
      name: 'bill_sale_upload',
      label: 'Vessel Bill Of Sale',
    },
    {
      key: 'image.2',
      type: 'file:all',
      name: 'proof_payment_upload',
      label: 'Proof of Payment',
    },
    {
      key: 'image.3',
      type: 'file:all',
      name: 'vessel_certificate_upload',
      label: 'Vessel Certificate of Title',
    },
    {
      key: 'image.4',
      type: 'file:all',
      name: 'copy_transfer_upload',
      label: 'Copy of Transfer Order',
    },
    {
      key: 'image.5',
      type: 'file:all',
      name: 'registration_notice_upload',
      label: 'Notice of Intended Registration',
      required: false
    },
    {
      key: 'image.6',
      type: 'file:all',
      name: 'exchange_permission_upload',
      label: 'Exchange Control Permission',
      hint: 'Permission from Exchange Control to purchase foreign currency',
    }
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Certificate of Registration',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...common(this)} />
}
