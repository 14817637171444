import React from "react";
import SummerCampBase from "../summer-camp-base";

export default class SummerCampYouth extends React.Component {
  render = () => {
    const { props } = this

    const aprops = {
      resource: '/summer_camps',
      form_key: 'summer_camp',
      _sid: 'youth summer camp',
      service: props.service,
      form_name: 'Summer Camp (Youth)',
      ...props,
    }

    return (
      <>
        <SummerCampBase {...aprops}/>
      </>
    )
  }
}

