import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Link, useLocation } from 'react-router-dom'

import '@styles/search.css'

const SEARCH_RESULTS_CONTAINER_ID = 'site-search-results-container'

export default function SiteSearch () {
  const [query, setQuery] = useState('')
  const [matches, setMatches] = useState([])
  const { pathname } = useLocation()
  const citizenAcct = useRef($app.hasRole('pilot'))

  const clear = () => setQuery('')

  useEffect(() => {
    const input = document.getElementById('services-searchbar')
    const listenForHotkey = ev => {
      try {
        if (
          !document.activeElement?.closest('input,form,textarea,select') &&
          ev.keyCode == 191
        )
          input.focus()
      } catch (err) {}
    }

    window.addEventListener('keyup', listenForHotkey)
    return () => window.removeEventListener('keyup', listenForHotkey)
  }, [])

  useEffect(() => {
    if (!query) {
      setMatches([])
      return
    }

    const q = new RegExp(query, 'i')

    const next = [
      ...STATIC_PAGES(citizenAcct.current),
      ...$app.getServices(),
    ].filter(
      s => !s.subheading && (
        q.test(s.title) ||
        (s.alias && q.test(s.alias)) ||
        (s.agency && q.test(s.agency.name))
      )
    )

    setMatches(next)
  }, [query])

  useEffect(() => {
    setQuery('')
  }, [pathname])

  return (
    <React.Fragment>
      <div className='collapse' id='search-nav'>
        <form className='navbar-left navbar-form nav-search mr-md-3'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <button type='submit' className='btn btn-search pr-1'>
                <i className='fa fa-search search-icon'></i>
              </button>
            </div>
            <input
              id='services-searchbar'
              placeholder='Search Services...'
              className='form-control'
              autoComplete='off'
              value={query}
              onChange={ev => setQuery(ev.target.value)}
              required
            />
            <div className='input-group-append'>
              <button
                className='btn btn-search pl-1'
                onClick={() => setQuery('')}
              >
                <i className='fas fa-times search-icon'></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <SearchResults query={query} matches={matches} clear={clear} />
    </React.Fragment>
  )
}

function SearchResults ({ query, matches, clear }) {
  const [open, setOpen] = useState(false)
  const el = document.getElementById(SEARCH_RESULTS_CONTAINER_ID)

  useEffect(() => {
    if (el && query) setOpen(true)
    if (!query) setOpen(false)
  }, [el, query])

  useEffect(() => {
    if (el) {
      const closeOnOutsideClick = ev => {
        const selector = '#site-search-results-container nav'
        if (!ev.target.closest(selector)) setOpen(false)
      }

      const closeOnEsc = ev => {
        if (ev.keyCode == 27) setOpen(false)
      }

      window.addEventListener('keydown', closeOnEsc)
      el.addEventListener('click', closeOnOutsideClick)

      return () => {
        window.removeEventListener('keydown', closeOnEsc)
        el.removeEventListener('click', closeOnOutsideClick)
      }
    }
  }, [el])

  if (!el) return null

  const list = matches?.slice(0, 12).map(m => (
    <Link key={m.path} to={m.path} onClick={clear}>
      <span>{m.title}</span>
      <span>&mdash;&nbsp;{m.agency?.name || 'General'}</span>
    </Link>
  ))

  const noResults = (
    <span className='block mx-auto text-center text-gray-600'>
      No Results Found.
    </span>
  )

  const markup = (
    <div className={open ? 'is-visible' : 'is-hidden'}>
      <nav>{matches?.length ? list : noResults}</nav>
    </div>
  )

  return createPortal(markup, el)
}

export const SearchResultsContainer = () => (
  <section id={SEARCH_RESULTS_CONTAINER_ID}></section>
)

const STATIC_PAGES = citizen =>
  citizen
    ? [
        {
          path: '/faqs',
          title: 'Frequently Asked Questions',
          alias: 'faqs',
        },
        {
          path: '/dashboard',
          title: 'Dashboard',
        },
        {
          path: '/profile',
          title: 'My Profile',
        },
        {
          path: '/transactions',
          title: 'My Transactions',
        },
        {
          path: '/appointments',
          title: 'My Appointments',
        },
      ]
    : [
        {
          path: '/appointments',
          title: 'Appointments',
        },
        {
          path: '/nib-search',
          title: 'Search Users By NIB',
          alias: 'NIB Search',
        },
        {
          path: '/deliveries/scanner',
          title: 'Scan Delivery Batch Code',
        },
      ]
