import React from 'react'
import Modal from 'react-responsive-modal'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys, withoutKeys } from '@helpers/objects'

import {
  ExemptionsForm,
  calculateExemptionToatal,
  VehicleForm,
  ApprovalForm,
} from './_exemptions-form'
// import redirectToCustoms from './_redirect'

export default class SmallBusinessExemption extends React.Component {
  state = {
    record: null,
    loading: false,
    picker: null,
  }

  resource = '/small_medium_business_exemptions'
  form_key = 'small_medium_business_exemption'
  free = true

  skip_location = true
  hide_buttons = {
    status: true,
  }

  // before_apply = redirectToCustoms

  validate = form => {
    if (!form.exemption_category.size) {
      return 'No exemption categories were selected'
    }

    const exemptions = [
      'materials_exemption_list',
      'inventory_exemption_list',
      'equipment_exemption_list',
      'machinery_exemption_list',
      'vehicle_exemption_list',
    ]

    const transformed = exemptions.reduce((obj, prop) => {
      const ex = form[prop]
        ?.filter(row => row.item || row.make)
        ?.map(row => ({
          ...withoutKeys({ ...row }, 'tariff'),
          value: Number(row.value).toFixed(2),
          tariff_id: row.tariff?.id,
          tariff_code: row.tariff?.value,
        }))

      if (ex?.length) obj[prop] = ex
      return obj
    }, {})

    return {
      ...transformed,
      total_value_of_exemption: exemptions
        .reduce((sum, prop) => sum + calculateExemptionToatal(form[prop]), 0)
        .toFixed(2),
    }
  }

  openPicker = (data, target) => {
    const options = data.map(d => ({
      label: `<span class='text-muted'>${d.value}</span><br/>${d.label}`,
      value: extractKeys(d, 'id', 'value'),
    }))

    this.setState({
      picker: {
        target,
        options,
        unskippable: true,
        heading: 'Please Select The Correct Tariff Code',
        onSelect: () => this.setState({ picker: null }),
      },
    })
  }

  custom_action = {
    text: 'Approve Exemptions',
    icon: 'check-square',
    fn: record => this.setState({ record }),
  }

  close = () => this.setState({ record: null })

  update = status => {
    switch (status) {
      case 'SAVING':
        return this.setState({ record: null, loading: true })
      case 'DONE':
      case 'FAILED':
        return this.setState({ loading: false })
      default:
    }
  }

  foremost = {
    name: 'turnover',
    label:
      "Is your business' gross turnover more than $5,000,000 (Five Million Dollars) per annum?",
    type: 'select:bool',
    test: v =>
      !v ||
      'Sorry, but this service is not available for businesses with a gross turnover of more than $5,000,000 (Five Million Dollars) per annum.',
  }

  fields = (form, viewMode) => [
    '::Company Details',
    'company_name',
    {
      name: 'bl_number',
      label: 'Business Licence #',
      required: false,
    },
    {
      name: 'tax_id_number',
      label: 'TIN',
      required: false,
    },
    {
      name: 'tax_comp_cert_expiry',
      type: 'date',
      label: 'Tax Compliance Expiry',
      required: false,
    },
    {
      name: 'company_type_age',
      label: 'New / Existing Company?',
      options: ['New', 'Existing'],
    },
    {
      name: 'expanding',
      type: 'select:bool',
      hide: !form.company_type_age || form.company_type_age == 'New',
      label: 'Are you expanding or opening a new location?',
      view: {
        hide: !form.company_type_age || form.company_type_age == 'New',
        label: 'Expanding?',
        value: () =>
          form.site_plan_upload_file_name ||
          form.exemption_category.includes('Inventory')
            ? 'Yes'
            : 'No',
      },
    },
    {
      name: 'company_type',
      label: 'Company Type',
      options: [
        'Incorporated / Limited Company',
        'Sole Proprietorship',
        'Partnership',
        'Home Based',
      ],
    },
    {
      name: 'trading_name',
      hide: form.company_type != 'Sole Proprietorship',
    },
    {
      name: 'shareholders',
      label: 'Please list shareholders/directors',
      columns: [{ name: 'name' }],
      hide: [undefined, 'Sole Proprietorship', 'Home Based'].includes(
        form.company_type
      ),
    },
    {
      name: 'years_of_business',
      hint: 'In Years',
      hide: form.company_type == 'New',
      is: 'integer',
      label: 'Company Age',
      view: {
        value: v => v + ' years(s)',
      },
    },
    {
      name: 'business_turnover',
      hint: 'Per Annum',
      hide: form.company_type == 'New',
      is: 'currency',
    },
    '::SPACER',
    {
      name: 'exemption_category',
      type: 'checklist',
      label: 'Exemption Types',
      options: [
        'Building Materials',
        'Equipment / Machinery',
        'Company Vehicle',
        'Inventory',
      ].slice(
        0,
        form.expanding || form.company_type_age == 'New' ? undefined : -1
      ),
    },
    '::Supporting Documents',
    {
      label: 'Tax Compliance',
      name: 'tax_comp_cert_upload',
      type: 'file:pdf',
      key: f => f.image[1],
    },
    {
      label: 'Business Licence',
      name: 'business_license_upload',
      type: 'file:pdf',
      key: f => f.image[6],
    },
    {
      label: 'Min. Of Public Works Stamped Floor Plan',
      name: 'site_plan_upload',
      type: 'file:pdf',
      hide: !form.exemption_category?.has?.('Inventory') || form.expanding,
      key: f => f.image[3],
    },
    {
      name: 'invoice_upload',
      type: 'file:pdf',
      label: 'Invoice / Quote',
      key: f => f.image[2],
    },
    '::Exemption Details',
    {
      name: 'materials_exemption_list',
      label: 'Building Materials',
      ...ExemptionsForm(
        form.materials_exemption_list,
        this.openPicker,
        viewMode
      ),
      hide: !form.exemption_category?.has?.('Building Materials'),
    },
    {
      name: 'equipment_exemption_list',
      label: 'Equipment',
      ...ExemptionsForm(
        form.equipment_exemption_list,
        this.openPicker,
        viewMode
      ),
      hide: !form.exemption_category?.has?.('Equipment / Machinery'),
    },
    {
      name: 'inventory_exemption_list',
      label: 'Inventory',
      ...ExemptionsForm(
        form.inventory_exemption_list,
        this.openPicker,
        viewMode
      ),
      hide: !form.exemption_category?.has?.('Inventory'),
    },
    {
      name: 'vehicle_exemption_list',
      label: 'Company Vehicles',
      tooltip:
        'Please note that all vehicles must be less than 10 (ten) years old, except for heavy duty vehicles',
      hide: !form.exemption_category?.has?.('Company Vehicle'),
      ...ExemptionsForm(form.vehicle_exemption_list),
      columns: VehicleForm(viewMode),
    },
    '::SPACER',
    {
      name: 'penalty',
      type: 'checkbox',
      label: `
        Providing false or misleading information
        &mdash; or using exemptions for purposes other than applied for
        &mdash; will lead to prosecution and related fines and criminal penalties.
      `,
    },
  ]

  hidden_fields = () => [
    {
      name: 'total_value_of_exemption',
      view: {
        label: 'Total Exemption Value',
        value: v => '$' + Number(v).toLocaleString(),
      },
    },
    {
      name: 'pdf_upload',
      type: 'file:image',
      label: 'Exemption Certificate',
      key: f => f.image[7],
    },
  ]

  render = () => {
    const { state, props, close, fields, resource, form_key, update } = this
    const { record } = state

    const fprops = {
      update,
      resource,
      close,
      form_key,
      location: props.location,
      history: props.history,
    }

    if (record)
      Object.assign(fprops, {
        record,
        fields: fields(record),
      })

    return (
      <React.Fragment>
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal open={!!record} onClose={close} center>
          <ApprovalForm {...fprops} />
        </Modal>
      </React.Fragment>
    )
  }
}
