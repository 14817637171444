import axios from 'axios'

import * as types from '@action-types'
import ENV from '@constants/env'
import toastr from '@modules/toastr'
import { extractKeys } from '@helpers/objects'

const BLOCKCERTS_SERVER = ENV.BLOCKCERTS_SERVER + '/api/v1'

export function reset () {
  return {
    type: types.RESET_CERT_STATE
  }
}

export const issueCertificates = payload => async dispatch => {
  dispatch({
    type: types.ISSUE_CERTIFICATES_ATTEMPT
  })

  const transformDates = recipient => Object.keys(recipient).reduce((obj, key) => {
    if (/(dob|_date)/.test(key)) {
      const d = new Date(obj[key])
      obj[key] = d.toISOString().slice(0, 10)
    }

    return obj
  }, recipient)

  const { company, type } = payload
  const user_id = $app.current_user.id

  try {
    const { data } = await axios.post(BLOCKCERTS_SERVER + '/issue-certificates', {
      company,
      type,
      recipients: payload.recipients.map(transformDates),
    })

    const { success, issued } = data

    if (success) {
      const uuids = issued.map(cert => ({
          company,
          user_id,
          cert_type: type,
          status: 'VALID',
          ...extractKeys(cert, 'uuid', 'name:user_name', 'nib:user_nib', 'email:user_email'),
      }))

      for (let uuid of uuids) {
        try {
          await $app.axios.post('/uuids', { uuid })
        } catch (err) {
          console.error(err)
        }
      }

      for (let r of issued) {
        try {
          await $app.axios.post(ENV.WEB_SERVICE_URL + '/api/v1/emails', {
            email: r.email,
            subject: 'A Certificate Has Been Issued To You',
            message: `
              <strong>${r.name.split(' ')[0]},</strong>
              <p>
              A certificate from ${company} has been issued to you. Please touch the link below to import your certificate into your Blockcerts Wallet.
              </p>
              <br/>
              <a href='${r.wallet_url}'>Import Certificate</a>
              <br/>
              <p>Also, your certificate is available as a web-ready pass that can be saved to your home screen. Click the link below to view</p>
              <br/>
              <a href='${r.pass_url}'>View Web Pass</a>
              <br/>
              <br/>
              <br/>
              <p>Regards,</p>
              <p>Digital Transformation Unit</p>
            `,
          })
        } catch (err) {
          console.error(err)
        }
      }

      toastr.success('Success', 'Issued Certificates')
      dispatch({
        type: types.ISSUE_CERTIFICATES_SUCCESS,
        payload: { history: uuids },
      })

      return true
    } else {
      console.error(data)
      toastr.error('Error', 'Unable to issue certificates')
      dispatch({
        type: types.ISSUE_CERTIFICATES_FAILURE
      })

      return false
    }
  } catch (err) {
    console.error(err)
    toastr.error('Error', 'Unable to get response from server')
    dispatch({
      type: types.ISSUE_CERTIFICATES_FAILURE,
    })

    return false
  }
}

export const getIssuedCertificates = () => async dispatch => {
  dispatch({ type: types.FETCH_ISSUE_HISTORY_ATTEMPT })

  try {
    const { data } = await $app.axios.get('/uuids')

    dispatch({
      type    : types.FETCH_ISSUE_HISTORY_SUCCESS,
      payload : data.uuids || [],
    })
  } catch (err) {
    toastr.error('Error', 'Unable to get issue history')
    console.error(err)

    dispatch({
      type    : types.FETCH_ISSUE_HISTORY_FAILURE,
      payload : err,
    })
  }
}

export const revokeCertificates = (company, revoked) => async dispatch => {
  dispatch({ type: types.REVOKE_CERTIFICATES_ATTEMPT })

  try {
    const { data } = await axios.post(BLOCKCERTS_SERVER + '/revoke-certificates', { company, revoked })
    const { success, results } = data

    if (success) {
      for (let i = results.length; i--;) {
      
        try {
          await axios.post('uuids', {
            id: results[i].uuid,
            uuid: { status: 'REVOKED' }
          })
        } catch (err) {
          console.error(err)
        }
      }
      
      toastr.success('Success', 'Revoked Certificates')
      dispatch({
        type: types.REVOKE_CERTIFICATES_SUCCESS,
        payload: revoked.map(r => r.uuid)
      })
    } else {
      toastr.error('Error', 'Unable to revoke certificates')
      dispatch({
        type: types.REVOKE_CERTIFICATES_FAILURE,
        payload: data
      })
    }
  } catch (err) {
    toastr.error('Error', 'Unable to get response from server')
    dispatch({
      type: types.REVOKE_CERTIFICATES_FAILURE,
    })
  }
}

export function getRequest() {
  return {
    type: types.GET_REQUEST_SUCCESS,
    payload: {}
  }
}