import React from 'react'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'

import Loader from '@shared/loader'
import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import withAdminEditForms from './_with-metadata-editors'
import {
  ItemImport,
  addPermitNote,
  calculateExemptionToatal,
  ItemApprovalForm,
} from './_item-imports'

import SERVICE_PROPS from '@helpers/extract-service-props'
import ENV from '@constants/env'

class AgriImport extends React.Component {
  state = {
    loading: false,
    record: null,
    license_expiry_date: null,
    picker: null,
    finalRecord: null,
    attachments: null,
    metadata: null,
  }

  isOrchidImport = /orchid-import/.test(this.props.location.pathname)

  // Shutdown applications
  shutdownMsg = `
    <b>Notice of Service Transition</b><br><br>

    <b>The Department of Agriculture Services is Moving to Click2Clear</b><br><br>

    Effective August 30, 2024, the following services the Department of Agriculture provided will no longer be available on MyGateway. These services will now be accessible through the Click2Clear platform: <br><br>

    <ul>
    <li>CITES Permit (available 6-8 months after legal work is completed)</li>
    <li>Permit to Import Bananas Permit to Import Fruits and Vegetables</li>
    <li>Permit to Import Plants and Flowers, Permit to Import Meats</li>
    <li>Permit to Import Poultry Permit to Import Eggs</li>
    <li>Permit to Import Dairy</li>
    </ul>

    We encourage all customers to visit Click2Clear to access these services moving forward. We apologise for any inconvenience this transition may cause and appreciate your understanding as we continue to enhance our service offerings.<br><br>

    <b>Need Assistance? Contact the Bahamas Customs Helpdesk!</b><br><br>

    <b>New Providence</b>: 604-3004-07, 3011-3014, 3168 <br><br>

    Mon - Fri 9:00 AM - 5:00 PM <br><br>

    <b>Grand Bahama</b>: 602-9419, 602-9530, 605-9549 <br><br>

    Mon - Fri 9:00 AM - 5:00 PM <br><br>

    <b>For inquiries and help after hours and on weekends</b>: <br><br>

    Phone 1 (242) 376-7657, 1 (242) 357-7568, 1 (242) 357-7904
  `

  before_apply = () => {
    if (!this.isOrchidImport)
      return `
        ${this.shutdownMsg}
      `
  }

  async componentDidMount() {
    try {
      const { data } = await $app.axios.get('/agency_services')
      this.setState({
        metadata: data.services.filter(s => s.name === this.props._sid)[0]
          ?.metadata,
      })
    } catch (error) {
      console.error(error)
    }
    (!this.isOrchidImport) && Swal.fire(
      'Applications Closed',
      `
        ${this.shutdownMsg}
      `,
      'info'
    )
  }

  resource = this.props.resource
  form_key = this.props.form_key
  free = false
  postpaid = false
  defaults = {}
  
  skip_location = this.props.skip_location ?? true
  hide_buttons = {
    status: true,
    deny: true,
  }
  // hide_columns = ['Status']

  custom_actions = [
    addPermitNote(this.props.service),
    {
      text: 'Submit to Sr. Officer',
      icon: 'user-tie',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('officer') &&
        (r.application_decision == 'pending' || r.application_decision == 'on hold' || r.application_decision == 'reprocess'),
      fn: record => this.setState({ record }),
    },
    {
      text: 'Upload Local Purchase Receipt',
      icon: 'receipt',
      user: true,
      test: r =>
        r.payment_status &&
        r?.upload_reqs?.includes('surplus_receipt_upload') &&
        r.application_decision == 'processing',
      fn: async record => {
        const { resource, form_key } = this
        const { axios } = $app
        const { value: file } = await Swal.fire({
          title: 'Upload Proof of Local Purchase Receipt',
          input: 'file',
          inputAttributes: {
            'accept': 'pdf/*',
            'aria-label': 'Upload your proof of local purchase receipt',
          },
          inputValidator: value => !value && 'PDF upload needed',
          showCancelButton: true,
          confirmButtonText: 'Upload',
          confirmButtonColor: '#218838',
        })

        if (file) {
          var bodyFormData = new FormData()
          bodyFormData.append(`${form_key}[form_num]`, record.form_num)
          bodyFormData.append(
            `${form_key}[upload_reqs][]`,
            'surplus_receipt_upload'
          )
          bodyFormData.append(`${form_key}[surplus_receipt_upload]`, file)

          try {
            await axios.put(resource + '/update_upload', bodyFormData)

            Swal.fire('Local Purchase Receipt Received!')
          } catch (error) {
            console.error(error)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'There was a problem with uploading the receipt',
            })
          }
        }
      },
    },
    {
      text: 'Approve Application',
      icon: 'check-circle',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('senior_officer') &&
        (r.application_decision == 'processing' || r.application_decision == 'officer viewed' || r.application_decision == 'on hold' || r.application_decision == 'reprocess') &&
        r.upload_reqs.length === 0,
      fn: record => this.setState({ finalRecord: record }),
    },
    {
      text: 'Place on Hold',
      icon: 'ban',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('senior_officer') &&
        (r.application_decision == 'pending' || r.application_decision == 'processing' || r.application_decision == 'officer viewed'),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Place Application on Hold',
          text: 'By clicking hold, you are confirming that you are placing this application on hold. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Hold',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: 'on hold',
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = 'on hold'

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire('On Hold!', 'The application has been placed on hold', 'success')
            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error when trying to put the application on hold',
                'error'
              )
            }
          }
        })
      },
    },
    {
      text: 'Reprocess',
      icon: 'ban',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('senior_officer') &&
        (r.application_decision == 'processing' || r.application_decision == 'officer viewed'),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Reprocess Application',
          text: 'By clicking reprocess, you are confirming that you are sending this application back down to the clerk level. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Reprocess',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: 'reprocess',
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = 'reprocess'

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire('Reprocess!', 'The application has been sent back to clerk level for reprocessing', 'success')
            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the reprocessing of the application',
                'error'
              )
            }
          }
        })
      },
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('senior_officer') &&
        (r.application_decision != 'approved' || r.application_decision != 'denied'),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Deny Application',
          text: 'By clicking deny, you are confirming that you are denying this application. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Deny',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: 'denied',
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = 'denied'

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire('Denied!', 'The application has been denied', 'success')

              await $app.axios.post('/emails', {
                email: record.user.email,
                subject: `MyGateqway Portal Update -  ${props.form_name} Imports Application status`,
                message: `
                Good day ${record.user.first_name.capitalize()},
                <br/>
                  
                We regret to inform you that your application for a licence to import ${
                  this.props.form_name
                } has been denied.  
                  <br/><br />
                

                  Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services.
                `,
              })
            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the denial of the application',
                'error'
              )
            }
          }
        })
      },
    },
  ]

  custom_notice = `
    This application is submitted to the Department of Agriculture on your
    behalf for consideration. The agency reserves the right to reject this
    application for reasons of non-compliance. Applicant(s) understand
    that once a permit is issued with respect to this application, the
    permit holder is under an obligation to observe the laws, policies,
     rules, guidelines, and/or procedures of Department of Agriculture.
  `

  stringifyJSON = json => json.map(j => JSON.stringify(j))

  testSubmit = async approvedState => {
    const { record } = this.state

    const { item_imports: items } = approvedState

    const modifiedItems = items
      .filter(
        item => item.status == 'surplus' || item.status == 'local purchase'
      )
      .map(val => {
        return {
          ...val,
          qtyDiff: Number(val?.qty_req) - Number(val?.qty),
        }
      })

    const isSurplus = modifiedItems.length ? true : false

    if (isSurplus) {
      Swal.fire({
        title: 'Local Purchase Receipt Needed',
        icon: 'info',
        html: `
          By clicking confirm, you will not only be submitting this application to a Senior officer, but you will also be notifying ${`${record.user.first_name} ${record.user.last_name}`.capitalize()} regarding an outstanding proof of local purchase receipt that needs to be uploaded to complete application.
          <br/>
          <br/>
          Are you sure that you want to proceed?
           
           `,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#26AEEF',
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sendEmail(modifiedItems)
          await this.submitToSenior(approvedState, isSurplus)
        }
        if (result.isDismissed) {
          this.setState({ record: null })
        }
      })
    } else {
      await this.submitToSenior(approvedState)
    }
  }

  submitToSenior = async (approvedState, isSurplus) => {
    this.setState({ loading: true })
    const { resource, form_key, props, stringifyJSON } = this
    const { history, location } = props
    const { axios } = $app
    const { record } = this.state

    const tables = Object.keys(approvedState)

    const stringifyState = tables.reduce((obj, val) => {
      obj[val] = Array.isArray(approvedState[val])
        ? stringifyJSON(approvedState[val])
        : approvedState[val]
      return obj
    }, {})

    approvedState['item_imports'] = approvedState.item_imports.filter(
      item => item.status == 'approved' || item.status == 'surplus'
    )

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          upload_reqs: isSurplus
            ? [...record?.upload_reqs, 'surplus_receipt_upload']
            : [...record?.upload_reqs],
          ...stringifyState,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'
        const fields = [...view.fields].map(f => {
          return tables.includes(f.name)
            ? {
                ...f,
                value: approvedState[f.name],
              }
            : f
        })

        view.fields = fields

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval by the Sr. Officer.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }

    this.setState({ loading: false, record: null })
  }

  sendEmail = async items => {
    const { record } = this.state
    const message = `
    Greetings ${record?.user.first_name.capitalize()}, 
    <br/><br/>
  You are receiving this email to notify you about an outstanding Proof of Local Purchase Receipt(s) that you are required to submit to the Department of Agriculture before your application is approved..
    <br/><br/>
    The following items require evidence of proof of local purchase:
    <br/><br/> 
    ${items.map(
      item => `
     <li>${`${item.qtyDiff || item.purchase_plus} (${item.metric}) ${
       item.item_common
     }`}</li>
     `
    )} 
    <br/><br/>
     In order to proceed with your application, please upload the outstanding Proof of Local Purchase Receipt(s) as a consolidated document using this <a href='${
       ENV.PILOT_URL
     }/login?callback=/dashboard' target='_blank'>link</a>.
    <br/><br/> 
    Thank you for using MyGateway.
    
    `

    try {
      await $app.axios.post('/emails', {
        email: record.user.email,
        subject: `MyGateway Portal Update - Outstanding Local Purchase Receipt for ${this.props.form_name} Imports`,
        message,
      })

      toastr.success('Success', 'Email successfully sent')
      Swal.fire(
        'Sent!',
        'The customer will be notified about the outstanding receipt.',
        'success'
      )
    } catch (error) {
      toastr.error('Error', 'Unable to send email')
      console.error(error)
    }
  }

  completeApplication = async approvedState => {
    const { resource, form_key, state, props, stringifyJSON } = this
    const { location, history } = props
    const { finalRecord: record } = state
    const notDenied = approvedState?.item_imports?.filter(
      item => item.status != 'denied'
    )

    if (!notDenied.length) {
      Swal.fire({
        icon: 'error',
        title: 'Deny Application',
        text: 'By denying all of the line items listed, you are confirming that you are denying this entire application. Are you sure that you want to proceed?',
        showCancelButton: true,
        confirmButtonText: 'Deny',
        confirmButtonColor: '#C82333',
      }).then(async result => {
        if (result.isConfirmed) {
          const { resource, form_key, props } = this
          const { history, location } = props
          const { axios } = $app

          try {
            await axios.put(resource + '/update_application', {
              [form_key]: {
                form_num: record.form_num,
                application_decision: 'denied',
              },
            })

            if (location?.state?.view) {
              const view = { ...location.state.view }
              view.application_decision = 'denied'

              history.replace(location.pathname, {
                ...location.state,
                view,
              })
            }

            Swal.fire('Denied!', 'The application has been denied', 'success')

            await $app.axios.post('/emails', {
              email: record.user.email,
              subject: `MyGateqway Portal Update -  ${props.form_name} Imports Application status`,
              message: `
                Good day ${record.user.first_name.capitalize()},
                <br/>
                  
                We regret to inform you that your application for a licence to import ${
                  this.props.form_name
                } has been denied.  
                  <br/><br />
                

                  Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services.
                `,
            })
          } catch (error) {
            console.error(error)
            Swal.fire(
              'Oops...',
              'There has been an error with the denial of the application',
              'error'
            )
          } finally {
            this.setState({ loading: false, finalRecord: null })
          }
        }
      })
    } else {
      Swal.fire({
        title: 'Confirm?',
        icon: 'question',
        text: 'Are you sure you want to approve this application?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#26AEEF',
      }).then(async result => {
        if (result.isConfirmed) {
          const tables = Object.keys(approvedState)
          const deniedItems = approvedState?.item_imports?.filter(
            item => item.status === 'denied'
          )
          const stringifyState = tables.reduce((obj, val) => {
            obj[val] = Array.isArray(approvedState[val])
              ? stringifyJSON(approvedState[val])
              : approvedState[val]
            return obj
          }, {})
          this.setState({ loading: true })
          try {
            await $app.axios.put(resource + '/update_application', {
              [form_key]: {
                form_num: record.form_num,
                application_decision: 'approved',
                ...stringifyState,
              },
            })
            if (location?.state?.view) {
              const view = { ...location.state.view }
              view.application_decision = 'approved'
              const fields = [...view.fields].map(f => {
                return tables.includes(f.name)
                  ? {
                      ...f,
                      value: approvedState[f.name],
                    }
                  : f
              })
              view.fields = fields
              history.replace(location.pathname, {
                ...location.state,
                view,
              })
            }
            toastr.success(
              'Success',
              'Application has been approved by Senior Officer'
            )
            await $app.axios.post('/emails', {
              email: record.user.email,
              subject: `MyGateqway Portal Update -  ${this.props.form_name} Imports Application status`,
              message: `
                Good day ${record.user.first_name.capitalize()},
                <br/>
                Congratulations! Your application for a licence to import
                ${this.props.form_name} has been completed. A downloadable
                PDF document that contains your permit will be sent to your
                email shortly.
                <br/><br />
                ${
                  deniedItems.length &&
                  `The following line items have been denied: <br/>`
                }
               ${
                 deniedItems.length &&
                 deniedItems.map(
                   item => `
                <li>${`${item.qty_req} (${item.metric}) ${item.item_common} `}</li>
                `
                 )
               } 
               <br/>

            Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services.
          `,
            })
            toastr.success('Success', 'Email has been sent to user')
          //   try {
          //     const { data } = await $app.axios.get(
          //       '/services/all_paid_forms',
          //       {
          //         params: { service_type: props.service.type },
          //       }
          //     )
          //     const updated_record = data.paid_forms.filter(
          //       d => d.id === record?.id
          //     )[0]
          //     await $app.axios.post('/emails', {
          //       email: record.user.email,
          //       subject: `MyGateqway Portal Update -  ${this.props.form_name} Imports Permit`,
          //       message: `
          //   Good day ${record.user.first_name.capitalize()},
          //   <br/>
          //  Your application for a licence to import ${
          //    this.props.form_name
          //  } has been approved. As promised, below is a link to your downloadable PDF Permit.
          //   <br/><br />
          //   <p><b>Links</b></p>
          //   <ul>
          //   <li><a href='${`${ENV.WEB_SERVICE_URL}${updated_record?.image[0]}`}' target='_blank'>Permit to Import ${
          //         this.props.form_name
          //       }</a></li>
          //   </ul>
          //   <br/><br/>
          //   Thank you for using MyGateway Portal.
          // `,
          //     })
          //   } catch (error) {
          //     toastr.error('Error', 'Unable to send permit email')
          //     console.error(error)
          //   }
          } catch (err) {
            toastr.error('Error', 'Unable to approve application')
            console.error(err)
          } finally {
            this.setState({ loading: false, finalRecord: null })
          }
        }
      })
    }
  }

  columns = props => [
    {
      name: 'Country of Origin',
      selector: r => {
        try {
          const v = JSON.parse(r.country_origin)
          return v.join(', ')
        } catch (err) {
          return r.country_origin ?? 'N/A'
        }
      },
      sortable: true,
      searchable: true,
    },
    {
      name: 'Port of Entry',
      selector: 'entry_port',
      cell: r => <span>{r.entry_port || 'N/A'}</span>,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Importation Purpose',
      selector: 'import_purpose',
      cell: r => <span>{r.import_purpose || 'N/A'}</span>,
      searchable: true,
    },
    {
      name: 'Current Stage',
      selector: 'application_decision',
      width: '300px',
      cell: r => <span>{this.getCurrentStage(r) || 'N/A'}</span>,
      searchable: r => this.getCurrentStage(r),
    },
  ]

  getCurrentStage = ({ application_decision, upload_reqs, payment_status }) => {
    if (!payment_status) return 'N/A'

    if (application_decision == 'pending') {
      return 'Awaiting Permit Clerk Approval'
    }
    if (
      application_decision === 'processing' &&
      upload_reqs?.includes('surplus_receipt_upload')
    ) {
      return 'Awaiting Local Purchase Receipt Upload'
    }
    if (
      application_decision === 'officer viewed' &&
      upload_reqs?.includes('surplus_receipt_upload')
    ) {
      return 'Awaiting Local Purchase Receipt Upload'
    }

    if (application_decision === 'approved') {
      return 'Application Completed'
    }

    if (application_decision === 'reprocess') {
      return 'Application Requires Reprocessing'
    }

    if (application_decision === 'on hold') {
      return 'Application On Hold'
    }

    return 'Awaiting Sr. Officer Approval'
  }

  // prettier-ignore
  fee = ({ item_imports }) => item_imports.length > 48
    ? Math.ceil((item_imports.length - 48) / 30) * 11.0 + 11.0
    : 11.0

  closeModal = () => this.setState({ record: null })

  fields = form => {
    const { metadata } = this.state

    const countries = metadata
      ?.filter(m => m.desc === 'country origin of goods')[0]
      ?.data.filter(d => d.active)
      .map(c => ({ label: c.country, value: c.country }))

    const entry_port_data = metadata
      ?.filter(m => m.desc === 'entry ports')[0]
      ?.data.filter(d => d.active)

    const islands = entry_port_data?.reduce((arr, val) => {
      if (arr.includes(val.island)) return arr
      arr.push(val.island)

      return arr
    }, [])

    const getIslandCities = island =>
      entry_port_data
        ?.filter(data => data.island === island)
        .reduce((arr, val) => {
          if (arr.includes(val.city)) return arr
          arr.push(val.city)

          return arr
        }, [])

    const getEntryPorts = (city, island, method) => {
      let type
      if (method === 'Air') type = 'Airport'
      if (method === 'Sea') type = 'Dock'

      if (!city) {
        return entry_port_data
          ?.filter(data => data.island === island && data.port_type === type)
          .map(data => data.address)
      }

      return entry_port_data
        ?.filter(data => data.city === city && data.port_type === type)
        .map(data => data.address)
    }

    const hasCity = island => {
      return entry_port_data
        ?.filter(data => data.island === island)
        .some(port => port.city)
    }

    return [
      {
        name: 'client_type',
        label: 'Client Type',
        disabled: true,
        options: [
          { label: 'Individual', value: 'individual' },
          { label: 'Business', value: 'business' },
        ],
      },
      {
        name: 'import_purpose',
        label: 'Importation Purpose',
        options:
          form.client_type == 'individual'
            ? ['Private Events', 'Weddings', 'Personal Use', 'Other']
            : ['Commercial Imports', 'Charitable Exercise', 'Other'],
        callback: v => {
          if (v != 'Other') {
            return { other_purpose: '' }
          }
        },
      },
      {
        name: 'other_purpose',
        label: 'Other Purpose',
        hide:
          form.import_purpose != 'Other' &&
          form.import_purpose !== form.other_purpose,
        view: false,
      },
      {
        heading: 'Company Details',
        hide: form.client_type == 'individual',
        view: false,
      },
      {
        name: 'company_name',
        label: "Company Name",
        hide: form.client_type != 'business',
        disabled: form.client_type == 'business',
      },
      {
        name: 'company_type',
        hide: form.client_type == 'individual',
        disabled: true,
        view: false,
      },
      {
        name: 'company_email',
        label: "Company Email",
        hide: form.client_type != 'business',
        disabled: form.client_type == 'business',
        required: false,
      },
      {
        name: 'trading_name',
        hide: form.client_type != 'business',
        disabled: form.client_type == 'business',
        view: false,
        required: false,
      },
      {
        name: 'bl_number',
        label: 'Business Licence #',
        hide: form.client_type == 'individual',
        required: false,
        disabled: true,
        view: false,
      },
      {
        name: 'tin_number',
        label: 'TIN #',
        hide: form.client_type == 'individual',
        required: false,
        disabled: true,
        view: false,
      },
      {
        name: 'business_nib',
        label: 'Business NIB',
        hide: form.client_type == 'individual',
        // disabled: true,
        view: false,
      },
      {
        heading: '<span>&nbsp;</span>',
        hide: form.client_type == 'individual',
      },
      {
        name: 'country_origin',
        label: 'Country of origin of goods',
        options: countries?.slice?.(1),
        multi: true,
        hint: 'Select all that apply',
        view: {
          value: value => {
            try {
              const v = JSON.parse(value)
              return v.join(', ')
            } catch (err) {
              return value ?? 'N/A'
            }
          }
        }
      },
      ':: Expected Port of Entry',
      {
        name: 'entry_port_island',
        label: 'Island',
        options: islands,
        callback: v => {
          if (!hasCity(v)) return { entry_port_city: '' }
        },
      },
      {
        name: 'entry_port_city',
        options: form.entry_port_island
          ? getIslandCities(form.entry_port_island)
          : [],
        hide: !hasCity(form.entry_port_island),
      },
      {
        name: 'entry_port_type',
        label: 'Entry Method',
        options: ['Air', 'Sea', 'Postal', 'FedEx', 'Other'],
        callback: v => {
          if (v != 'Other') {
            return { other_port_type: '' }
          }
        },
      },
      {
        name: 'other_port_type',
        label: 'Other Method',
        hide:
          form.entry_port_type != 'Other' &&
          form.entry_port_type !== form.other_port_type,
        view: false,
      },
      {
        name: 'entry_port',
        label: 'Port of Entry',
        options: form.entry_port_island
          ? getEntryPorts(
              form.entry_port_city,
              form.entry_port_island,
              form.entry_port_type
            )
          : [],
        hide:
          form.entry_port_type === 'Other' ||
          form.entry_port_type === 'FedEx' ||
          form.entry_port_type === 'Postal',
      },
      '::SPACER',
      {
        name: 'item_imports',
        label: 'Items to be Imported',
        columns: ItemImport(form, this.props.importProps),
        footer: (
          <div className='float-left ml-4'>
            <strong>
              Total Import Value: $
              {calculateExemptionToatal(form.item_imports).toFixed(2)}
            </strong>
          </div>
        ),
      },
      {
        name: 'proforma_invoice_upload',
        label: 'Proforma Invoice/Quote',
        type: 'file:all',
        key: f => f.image[1],
      },
      {
        name: 'surplus',
        label: 'Do you have a proof of local purchase receipt?',
        type: 'select:bool',
        view: false,
        callback: v => {
          const other = {}

          if (form.other_purpose) {
            other.import_purpose = form.other_purpose
          }

          if (form.other_port_type) {
            other.entry_port_type = form.other_port_type
          }

          return other
        },
      },
      {
        name: 'surplus_receipt_upload',
        label: 'Proof of Local Purchase Receipt',
        type: 'file:all',
        key: f => f.image[2],
        hide: !form.surplus,
        view: true,
      },
      {
        name: 'business_license_upload',
        label: 'Business License Upload',
        type: 'file:all',
        key: f => f.image[3],
        required: form.client_type == 'business'
      },
      {
        name: 'final_declartion',
        long: true,
        label:
          'By clicking this box, I confirm that I understand my obligations under the respective laws and regulations applicable to the import of goods and agree to observe all applicable laws, regulations, policies and guidelines.',
        type: 'checkbox',
      },
    ]
  }

  hidden_fields = () => [
    {
      name: 'license_expiry_date',
      label: 'Permit Expiration Date',
      type: 'date',
    },
    {
      name: 'pdf_upload',
      key: 'image.0',
      type: 'file:pdf',
      label: `Permit to Import ${this.props.form_name}`,
    },
  ]

  render = () => {
    const {
      state,
      props,
      closeModal,
      fields,
      resource,
      form_key,
      update,
      testSubmit,
      completeApplication,
    } = this
    const { record, loading, finalRecord } = state

    const fprops = {
      update,
      resource,
      close: closeModal,
      form_key,
      location: props.location,
      history: props.history,
      tableName: `${this.props.form_name} Imports`,
      submit: testSubmit,
      hide_scientific: this.props.importProps?.hide_scientific,
    }

    const tprops = {
      update,
      resource,
      close: () => this.setState({ finalRecord: null }),
      form_key,
      location: props.location,
      history: props.history,
      tableName: `${this.props.form_name} Imports - Final Approval`,
      submit: completeApplication,
      hide_scientific: this.props.importProps?.hide_scientific,
    }

    if (record)
      Object.assign(fprops, {
        record,
        fields: fields(record),
      })

    if (finalRecord) {
      Object.assign(tprops, {
        record: finalRecord,
        fields: fields(finalRecord),
      })
    }

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!record}
          onClose={closeModal}
          center
          styles={{ modal: { maxWidth: '100%', width: 1000 } }}
        >
          <ItemApprovalForm {...fprops} />
        </Modal>
        <Modal
          open={!!finalRecord}
          onClose={() => this.setState({ finalRecord: null })}
          center
          styles={{ modal: { maxWidth: '100%', width: 1000 } }}
        >
          <ItemApprovalForm {...tprops} />
        </Modal>
      </React.Fragment>
    )
  }
}

export default withAdminEditForms(AgriImport)
