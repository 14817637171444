const PostOffices = {
  'New Providence': [
    'General Post Office - Town Center Mall',
    'Airport',
    'Cable Beach',
    'Carmichael Road',
    'Elizabeth Estates',
    'Fox Hill Post Office',
    'Grants Town',
    'SandyPort',
    'Shirley Street Post Office',
    'South Beach Post Office',
  ],
  'Grand Bahama': ['Freeport', 'Eight Mile Rock', 'West End'],
  'Eleuthera': [
    'Governors Harbour',
    'Tarpum Bay',
    'Palmetto Point',
    'Winsors Bite',
    'Harbour Island',
    'Spanish Wells',
    'Lower Bogue',
    'Hatchet Bay',
    'Gregory Town',
  ],
  'Andros': ['Fresh Creek', 'Mangrove Cay', 'Kemps Bay', 'Nichols Town'],
  'Abaco': [
    'Marsh Harbour',
    'Moores Island',
    'Hope Town',
    'Coopers Town',
    'Treasure Cay',
    'Sandy Point',
  ],
  'Long Island': ['Clarence Town', 'Simms', 'Deadmans Cay', 'Stella Maris'],
  'Exuma': ['George Town', 'Black Point'],
  'San Salvador': ['United Estate'],
  'Bimini': ['Alice Town'],
}

export default PostOffices
