import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ElectricalPermit extends React.Component {
  resource = '/electrical_permits'
  form_key = 'electrical_permit'
  defaults = {}

  // Delete lines as needed
  // fee = 99
  free = true
  // postpaid = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null


  fields = form => [
    {
      name: 'specification_number',
      label: 'Specification No.',
    },
    {
      name: 'building_permit_numb',
      label: 'Building Permit number',
    },
    {
      name: 'building_lot_number',
      label: 'Building Lot No.',
    },
    '::Property Owner',
    {
      name: 'property_owner',
      label: 'I am the Property Owner',
      type: 'select:bool',
    },
    {
      name: 'property_owner_first_name',
      label: 'First Name',
      hide: form.property_owner,
    },
    {
      name: 'property_owner_last_name',
      label: 'Last Name',
      hide: form.property_owner,
    },
    '::Property information',
    {
      name: 'building_po_box',
      label: 'P.O. Box',
    },
    {
      name: 'building_address',
      label: 'Address',
    },
    {
      name: 'total_floor_area',
      label: 'Total Floor Area',
    },
    {
      name: 'sq_ft',
      label: 'SQ. FT.',
    },
    '::Supply Required',
    {
      name: 'type_of_occupancy',
      label: 'Type of Occupancy',
      options: [
        'Residential',
        'Commercial',
        'Industrial'
      ],
    },
    {
      name: 'supply_req',
      label: 'Supply Required',
      options: [
        'Overhead',
        'Underground',
        '2-wires',
        '3-wires',
        '4-wires',
      ],
    },
    {
      name: 'voltage',
      label: 'Voltage',
      options: [
        '120/208',
        '120/240',
        '240/416',
        '254/440',
        '266/460',
        '277/480',
      ],
    },
    {
      name: 'max_demand',
      label: 'Max. Demand',
      hint: 'KVA'
    },
    {
      name: 'main_switch_size',
      label: 'Size of Main Switch',
      hint: 'AMPS'
    },
    {
      name: 'submain_switch',
      label: 'Is there a Sub-Main Switch?',
      type: 'select:bool',
    },
    {
      name: 'submain_switch_size',
      label: 'Size of Sub-Main Switch',
      hint: 'AMPS',
      hide: !form.submain_switch
    },
    {
      name: 'service_conduit_size',
      label: 'Size of Service Conduit',
      // hint: ''
    },
    {
      name: 'circuits_number',
      label: 'No. of Circuits',
      type: 'integer'
      // hint: ''
    },
    {
      name: 'service_conductors_list',
      label: 'Service Conductors (Size and Insulation)',
      rowLabel: 'Conductors',
      fields: [
        { name: 'size', label: 'Size', type: 'integer' },
        { name: 'insulation', label: 'Insulation' },
      ],
    },
    {
      name: 'proposed_wiring_system',
      label: 'Proposed Wiring System',
      // type: 'integer'
      // hint: ''
    },
    '::Details of Load',
    {
      name: 'lighting',
      label: 'Lighting',
      hint: 'KW',
    },
    {
      name: 'water_heater',
      label: 'Water Heater',
      hint: 'KW',
    },
    {
      name: 'range',
      label: 'Range(s)',
      hint: 'KW',
    },
    {
      name: 'other_load_list',
      label: 'Other',
      required: false,
      hint: 'KW',
      fields: [
        { name: 'purpose', label: 'Purpose' },
        { name: 'voltage', label: 'Voltage', type: 'integer' },
      ],
    },
    {
      name: 'motors_list',
      label: 'Motors',
      required: false,
      rowLabel: 'Motors',
      fields: [
        { name: 'number', label: 'No.', type: 'integer' },
        { name: 'purpose', label: 'Purpose' },
        { name: 'voltage', label: 'Voltage', type: 'integer' },
        { name: 'horse_power', label: 'H. P.', type: 'integer' },
        { name: 'phase', label: 'Phase' },
      ],
    },
    // '::Site Inspection Date',
    // {
    //   name: 'inspection_date',
    //   label: 'Inspection Date',
    //   type: 'date',
    // },

    "::Uploads",
    {
      name: 'drawing_upload',
      type: 'file:all',
      required: false,
    },
    {
      name: 'drawing_two_upload',
      type: 'file:all',
      required: false,
    },
    '::SPACER',
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
