import React from 'react'

import Calendar from '@fullcalendar/react'
import DayGridPlugin from '@fullcalendar/daygrid'
import ListViewPlugin from '@fullcalendar/list'
import InteractionPlugin from '@fullcalendar/interaction'
import TimeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment'

const AppointmentCalendar = ({
  handleSelect,
  initialView,
  sources,
  tabs,
  dayHeaderFormat,
  on,
  eventContent,
  publicEvents,
  initialDate,
}) => {
  const businessHours = {
    startTime: '09:00',
    endTime: '16:00',

    daysOfWeek: [1, 2, 3, 4, 5],
  }

  const selectAllow = ({ start }) => {
    const holidays = publicEvents.map(e => {
      return moment(e.event_date).format('MMM Do YY')
    })

    return !holidays.includes(moment(start).format('MMM Do YY'))
  }

  return (
    <Calendar
      plugins={[
        DayGridPlugin,
        ListViewPlugin,
        InteractionPlugin,
        TimeGridPlugin,
      ]}
      dayHeaderFormat={dayHeaderFormat}
      slotDuration={'00:20:00'}
      businessHours={businessHours}
      selectable
      select={handleSelect}
      selectConstraint={businessHours}
      selectAllow={selectAllow}
      initialView={initialView}
      weekends={false}
      eventClick={on.event}
      eventSources={sources}
      headerToolbar={{
        start: 'prev,next today',
        center: 'title',
        end: tabs,
      }}
      nowIndicator={true}
      eventContent={eventContent}
      slotEventOverlap={false}
      slotMinTime={'09:00:00'}
      slotMaxTime={'16:00:00'}
      initialDate={initialDate}
    />
  )
}

export default AppointmentCalendar
