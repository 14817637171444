import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'
import moment from 'moment'

import Loader from '@shared/loader'
import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import InputField from '@shared/form-fields/input'

import locations from '@constants/post-office-locations'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys } from '@helpers/objects'

const today = moment()

const BASE_FEES = {
  small  : 8.25,
  medium : 13.75,
  large  : 22.00,
}

export default class RentalBoxNew extends React.Component {
  state = {
    record: null,
    loading: false,
    box_number: '',
  }

  id = 'RentBox'
  service = 'rent box'
  title = 'Rent of Private Letter Boxes'
  agency = 'po'
  item = '479'
  admin = 'post_office'
  resource = '/rent_boxes'
  form_key = 'rent_box'
  postpaid = false

  skip_location = true
  // postpay_key = 'fee_per_year'

  fee = ({ box_type }) => {
    if(box_type == 'small') {
      return BASE_FEES.small
    }
    else if(box_type == 'medium') {
      return BASE_FEES.medium
    } else {
      return BASE_FEES.large
    }
  }

  validate = form =>
    extractKeys(
      form,
      'location:pickup_location',
      'sub_location:pickup_sub_location'
    )

  color_codes = (props, bg) => [
    {
      when: r => r.payment_status && today.diff(r.updated_at, 'days') < 3,
      style: bg('#0C15'),
    },
    {
      when: r => r.payment_status && today.diff(r.updated_at, 'days') == 3,
      style: bg('#FFD5809F'),
    },
    {
      when: r => r.payment_status && today.diff(r.updated_at, 'days') > 3,
      style: bg('#C003'),
    },
  ]

  columns = () => [
    {
      name: 'Location',
      selector: 'sub_location',
      format: r => `${r.sub_location}, ${r.location}`,
    },
  ]

  hide_buttons = {
    status: true,
    deny: true,
  }

  custom_action = {
    text: 'Set Box Number',
    icon: 'hashtag',
    test: r => r.payment_status,
    fn: record => this.setState({ record, box_number: '' }),
  }

  metadata = {
    denied: { box_number: 'XXXXXXX' },
  }

  fields = form => [
    {
      name: 'box_number',
      hide: true,
      view: true,
    },
    {
      name: 'box_type',
      options: [
        { label: `Small ($${BASE_FEES.small})`, value: 'small' },
        { label: `Medium ($${BASE_FEES.medium})`, value: 'medium' },
        { label: `Large ($${BASE_FEES.large})`, value: 'large' },
      ],
    },
    {
      name: 'entity_type',
      type: 'select',
      show: true,
      options: ['Commercial', 'Private', 'Both'],
    },
    {
      name: 'location',
      options: Object.keys(locations),
    },
    {
      key: form.location || '*',
      name: 'sub_location',
      disabled: !form.location,
      options: locations[form.location] || [],
    },
  ]

  closeModal = () => this.setState({ record: null })

  on = {
    input: name => ev =>
      this.setState({ [name]: ev.target.value.replace(/[^0-9a-z ]/gi, '') }),

    submit: async () => {
      this.setState({ loading: true })

      const { state, props, resource, form_key } = this
      const { history, location } = props
      const { record, box_number } = state
      const { axios } = $app

      const formatedBoxNumber = box_number.replaceAll(' ', '').replaceAll('-', '').trim().toUpperCase();

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
            application_status: true,
            box_number: formatedBoxNumber,
          },
        })

        if (location.state?.view) {
          const view = { ...location.state.view }
          view.application_decision = 'approved'
          view.fields[0].value = formatedBoxNumber

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success('Success', 'Postal Box # successfully set')
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to set Box Number')
        return
      }

      try {
        await axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal Update - Post Box Rental',
          message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
            Your Post Box Rental application has been approved.
            Your new postal box number is <strong>${formatedBoxNumber}</strong>.
            <br/><br />
            Thank you for using the MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false, record: null })
    },
  }

  // Define props that will be passed to the wizard here
  render() {
    const { state, on, closeModal } = this

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 480 }}
          onClick={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Set Box Number</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='box_number'>
                  Box Number
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='box_number'
                  icon='hashtag'
                  value={state.box_number}
                  onChange={on.input('box_number')}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  required
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-round mr-2'
                aria-label='Close'
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    )
  }
}
