import React, { Fragment, isValidElement, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import Switch from 'react-switch'

import { fullName, getMatchedLocations } from './utils'
import PostOffices from '@constants/post-office-locations'
// import allIslands from '@constants/islands'

export default function TableContainer({
  state,
  form,
  delivery,
  is,
  children,
  searching,
  read_params,
  current_param,
  setParams,
  ...methods
}) {

  const decideDivision = () => {
    return read_params?.values?.Default?.division_selected
  }

  const programOptionsMYSC = () => {
      let options = {}
  
      if (decideDivision() == 'youth') {
        options = {
          'camp placement': 'Camp',
          'apprentice programme': 'Apprentice',
          'corporate placement': 'Corporate',
        }
      } else if (decideDivision() == 'sports') {
        options = {
          'summer camp instructor': 'Summer Camp Instructor',
          'sports internship': 'Career in Sports Internship',
        }
      } else {
        options = {
          '': ''
        }
      }
  
      return options
  }

  const campOptionsMYSC = () => {
    let options = {}

    if (decideDivision() == 'youth') {
      options = {
        'Camp iMatter': 'Camp iMatter',
        'CampLit': 'CampLit',
      }
    } else if (decideDivision() == 'sports') {
      options = {
        'Basketball': 'Basketball',
        'Volleyball': 'Volleyball',
        'Baseball': 'Baseball',
        'Soccer': 'Soccer',
        'Softball': 'Softball',
        'Boxing': 'Boxing',
        'Archery': 'Archery',
        'Tennis': 'Tennis',
        'Flag Football': 'Flag Football',
        // 'Beep Baseball': 'Beep Baseball',
        'Swimming': 'Swimming(located at south beach swimming pools)',
      }
    } else {
      options = { '': ''}
    }

    return options
  }

  const allIslands = { 'new providence': 'New Providence',
      'grand bahama': 'Grand Bahama',
      'abaco': 'Abaco',
      'eleuthera': 'Eleuthera',
      'exuma': 'Exuma',
      'andros': 'Andros',
      'berry islands': 'Berry Islands',
      'bimini': 'Bimini',
      'acklins': 'Acklins',
      'mayaguana': 'Mayaguana',
      'cat island': 'Cat Island',
      'crooked island': 'Crooked Island',
      'inagua': 'Inagua',
      'long island': 'Long Island',
      'ragged island': 'Ragged Island',
      'rum cay': 'Rum Cay',
      'san salvador': 'San Salvador',
      'long cay': 'Long Cay',
    }

  const locations = useMemo(() => {
    if (form.service.agency.name == 'Post Office') {
      return Object.entries(PostOffices).reduce((obj, [island, locations]) => {
        for (let l of locations) {
          obj[l] = `${island} - ${l}`
        }

        return obj
      }, {})
    }

    if (!form.locations?.length || form.skip_location) return null

    return getMatchedLocations(form.locations, form.service.agency.__id).reduce(
      (options, loc) => ({
        ...options,
        [loc.street_address]: `${loc.island} - ${loc.street_address}`,
      }),
      {}
    )
  }, [form.locations])

  const islands = useMemo(() => {
    if (form.service.agency.name == 'Post Office') {
      return Object.keys(PostOffices).reduce(
        (obj, island) => ({
          ...obj,
          [island]: island,
        }),
        {}
      )
    }

    if (!form.locations?.length || form.skip_location) return null

    // prettier-ignore
    return getMatchedLocations(form.locations, form.service.agency.__id)
      .reduce((options, loc) => ({
        ...options,
        [loc.island]: loc.island,
      }), {})
  }, [form.locations])

  let action = null
  let qrPrintable = false
  let paramOptions = null

  if (is.dispatcher && state.mode == 'dispatcher') {
    if (delivery.canEdit()) {
      qrPrintable = true

      const click = () =>
        Swal.fire({
          title: 'Close Delivery',
          text: 'Are you sure you want to submit this delivery?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Close Delivery',
        }).then(result => {
          if (result.isConfirmed) delivery.submit()
        })

      action = (
        <button
          className='btn custom-btn btn-rounded'
          onClick={click}
          disabled={delivery.empty()}
        >
          <i className='fas fa-stop mr-2'></i>
          Close Delivery
        </button>
      )
    } else if (delivery.closed()) {
      qrPrintable = true

      action = (
        <button className='btn custom-btn btn-rounded btn-disabled' disabled>
          <i className='fas fa-circle-notch mr-2'></i>
          Delivery In Process
        </button>
      )
    } else if (state.records.length) {
      action = (
        <button
          onClick={delivery.create}
          className='btn custom-btn btn-rounded'
        >
          <i className='fas fa-plus mr-2'></i>
          New Delivery
        </button>
      )
    }
  } else if (form.admin_actions?.length) {
    const classes = 'flex justify-between items-center text-sm text-left'
      + ' mb-2 p-2 rounded-lg cursor-pointer text-gray-700'
      + ' hover:bg-primary hover:text-white'

    const list = form.admin_actions
      .filter(act => act.test)
      .map((act, i) => {
        const icon = act.icon
          ? /^fa[rsb]/.test(act.icon)
            ? act.icon
            : 'fas fa-' + act.icon
          : 'fas fa-circle'

        return (
          <li key={i} onClick={act.fn} className={classes}>
            <span>{act.text}</span>
            <i className={icon}></i>
          </li>
        )
      })

    action = list.length ? (
      <div className='dropdown hidden-caret submenu'>
        <a
          className='inline-flex items-center btn custom-btn btn-round nav-link dropdown-toggle'
          href='#'
          id='help-numbers'
          role='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <i className='fas fa-chevron-down'></i>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>Admin Actions</span>
        </a>
        <ul className='dropdown-menu w-64 p-2' aria-labelledby='help-numbers'>
          {list}
        </ul>
      </div>
    ) : null
  } else if ((is.citizen && form.before_apply !== false ) || form.admin_form) {
    action = (
      <button
        onClick={methods.openNewForm}
        className='btn custom-btn btn-rounded'
        disabled={!state.eligible}
      >
        <i className='fas fa-plus mr-2'></i>
        {typeof form.admin_form == 'string' ? form.admin_form : 'Apply Now'}
      </button>
    )
  }

  const isActive = target => (state.mode == target ? 'active' : undefined)

  const search = {
    nib: async () => {
      const { value } = await Swal.fire({
        icon: 'question',
        title: 'Search By NIB',
        input: 'number',
        inputLabel: 'NIB Number',
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please enter a value to search'
          }
        },
      })

      if (!value) return
      methods.searchRecords({ nib: value })
    },

    name: async () => {
      const { value } = await Swal.fire({
        icon: 'question',
        title: 'Search By Name',
        input: 'text',
        inputLabel: 'First & Last Name',
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please enter a value to search'
          }
        },
      })

      if (!value) return
      const [, first_name, last_name] = value.match(/^([A-Za-z'-]+)\s(.+)$/)
      methods.searchRecords({ first_name, last_name })
    },

    location: async () => {
      const { value: location } = await Swal.fire({
        icon: 'question',
        title: 'Search By Location',
        input: 'select',
        inputLabel: 'Location',
        inputOptions: locations,
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please select a location'
          }
        },
      })

      if (!location) return
      methods.searchRecords({ location })
    },

    island: async () => {
      const { value: island } = await Swal.fire({
        icon: 'question',
        title: 'Search By Island',
        input: 'select',
        inputLabel: 'Island',
        inputOptions: islands,
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please select an island'
          }
        },
      })

      if (!island) return
      methods.searchRecords({ location: island })
    },

    islandLocation: async () => {
      const { value: island_location } = await Swal.fire({
        icon: 'question',
        title: 'Search By Island',
        input: 'select',
        inputLabel: 'Island',
        inputOptions: allIslands,
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please select an island'
          }
        },
      })

      if (!island_location) return
      methods.searchRecords({ location: island_location })
    },

    email: async () => {
      const { value: email } = await Swal.fire({
        icon: 'question',
        title: 'Search By Email',
        input: 'text',
        inputLabel: 'Email Address',
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please enter a value to search'
          }
        },
      })

      if (!email) return
      methods.searchRecords({ email })
    },

    formnum: async () => {
      const { value: form_num } = await Swal.fire({
        icon: 'question',
        title: 'Search By Application ID',
        input: 'text',
        inputLabel: 'Application ID',
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please enter a value to search'
          }
        },
      })

      if (!form_num) return
      methods.searchRecords({ form_num })
    },

    programType: async () => {
      const { value: program_type } = await Swal.fire({
        icon: 'question',
        title: 'Search By Program Type',
        input: 'select',
        inputLabel: 'Which Program',
        inputOptions: programOptionsMYSC(),
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please select a program'
          }
        },
      })

      if (!program_type) return
      methods.searchRecords({ program_type, division_selected: decideDivision() })
    },

    campName: async () => {
      const { value: program_type } = await Swal.fire({
        icon: 'question',
        title: 'Search By Camp',
        input: 'select',
        inputLabel: 'Which Camp',
        inputOptions: campOptionsMYSC(),
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please select a camp'
          }
        },
      })

      if (!program_type) return
      methods.searchRecords({ program_type, division_selected: decideDivision() })
    },

    sessionNumb: async () => {
      const { value } = await Swal.fire({
        icon: 'question',
        title: 'Search By Session #',
        input: 'text',
        inputLabel: 'Session Number',
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return 'Please enter a value to search'
          }
        },
      })

      if (!value) return
      methods.searchRecords({ session_numb: value })
    },
  }

  const switcher = () => {
    // console.log(form);
    const isMota = form.location.pathname.includes('/mota/bahamahost-company')
    const isCultureRegistry = form.location.pathname.includes('/mysc/culture')

    const result = is.admin ? (
    <div className='card card-round form-wizard__mode-switch'>
      <div className='card-body'>
        <h5>Mode</h5>
        {is.dispatcher && !form.skip_location ? (
          <span
            onClick={methods.switchOfficerRole('dispatcher')}
            className={isActive('dispatcher')}
          >
            Dispatcher
          </span>
        ) : null}
        {(isMota && $app.hasRole('officer')) ? (
          <span
            onClick={methods.openNewForm}
            // onClick={methods.switchOfficerRole('new bhost form')}
            className={isActive('new bhost form')}
          >
            New Form
          </span>
        ) : null}
        <span
          onClick={methods.switchOfficerRole('admin')}
          className={isActive('admin')}
        >
          Approval Officer
        </span>
        <span
          onClick={methods.switchOfficerRole('paid')}
          className={isActive('paid')}
        >
          {form.free ? 'All' : 'Paid'} Requests
        </span>
        {(isCultureRegistry && $app.hasRole('registry')) ? (
          <Link
            to={'registry'}
          >
            Registry (Detailed View)
          </Link>
        ) : null}
      </div>
    </div>
  ) : null

  return result
}

  const left = (
    <div className='col-md-3'>
      <div className='card card-post card-round text-center'>
        <div className='card-img-top text-center rounded'>
          <img
            src={form.service.agency.thumbnail}
            alt='Agency Thumbnail'
            className='card-img-top'
          />
        </div>
        <div className='card-body text-left'>
          <div style={{ marginBottom: '1rem' }}>
            <h3
              className='card-title text-capitalize fw-bold'
              dangerouslySetInnerHTML={{
                __html: form.service.title,
              }}
            />
            <p className='card-text'>
              {form.service.agency?.name.capitalize().replace(' Of ', ' of ')}
            </p>
          </div>
          {action}
          <CustomTableActions actions={methods.determineTableActions()} />
          {qrPrintable ? (
            <React.Fragment>
              <div className='separator-solid mb-2' />
              <Link target='_blank' to={'/print/qr/' + state.delivery.qr_code}>
                Print QR Code
              </Link>
            </React.Fragment>
          ) : null}
          {state.mode == 'dispatcher' &&
          $app.hasRole('courier') &&
          delivery.collectable() ? (
            <React.Fragment>
              <br />
              <span
                className='cursor-pointer xs:text-primary sm:text-primary'
                onClick={delivery.send}
              >
                Mark Batch As In Transit
              </span>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {switcher()}
      <ProxyRequests
        requests={state.proxies?.approved_requests}
        start={req => () => methods.openNewForm(req)}
      />
    </div>
  )

  const _searchClasses =
    'flex justify-between items-center text-sm text-left mb-2 p-2 rounded-lg cursor-pointer text-gray-700 hover:bg-primary hover:text-white'

  const _searchButtons = searching ? (
    <button key='clear' onClick={methods.clearSearch}>
      <i className='fas fa-times'></i>
      Clear Current Search
    </button>
  ) : (
    <div className='nav-item dropdown hidden-caret submenu'>
      <a
        className='nav-link dropdown-toggle p-0'
        href='#'
        id='record-actions-search-options'
        role='button'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <i className='fas fa-search'></i>
        <span className='ml-2'>Search</span>
      </a>
      <ul
        style={{ width: '15rem' }}
        className='dropdown-menu p-2'
        aria-labelledby='record-actions-search-options'
      >
        <li onClick={search.formnum} className={_searchClasses}>
          <span>Search by Application ID</span>
          <i className='fas fa-file'></i>
        </li>
        <li onClick={search.nib} className={_searchClasses}>
          <span>Search by NIB</span>
          <i className='fas fa-hashtag'></i>
        </li>
        <li onClick={search.name} className={_searchClasses}>
          <span>Search by Name</span>
          <i className='fas fa-user'></i>
        </li>
        <li onClick={search.email} className={_searchClasses}>
          <span>Search by Email</span>
          <i className='fas fa-at'></i>
        </li>
        <li
          onClick={search.island}
          className={_searchClasses}
          data-hide={islands ? undefined : true}
        >
          <span>Search by Island</span>
          <i className='fas fa-map-marker'></i>
        </li>

        {/* BahamaHost CSPs */}
        <li
          onClick={search.islandLocation}
          className={_searchClasses}
          data-hide={form.form_key === 'bahama_host_csp' ? undefined : true}
        >
          <span>Search by Island</span>
          <i className='fas fa-map-marker'></i>
        </li>
        <li
          onClick={search.sessionNumb}
          className={_searchClasses}
          data-hide={form.form_key === 'bahama_host_csp' ? undefined : true}
        >
          <span>Search by Session #</span>
          <i className='fas fa-map-marker'></i>
        </li>

        {/* Ministry of Youth, Sports and Culture */}
        <li
          onClick={search.programType}
          className={_searchClasses}
          data-hide={form.form_key === 'summer_employment' ? undefined : true}
        >
          <span>Search by Program</span>
          <i className='fas fa-map-marker'></i>
        </li>
        <li
          onClick={search.campName}
          className={_searchClasses}
          data-hide={form.form_key === 'summer_camp' ? undefined : true}
        >
          <span>Search by Camp</span>
          <i className='fas fa-map-marker'></i>
        </li>
        <li
          onClick={search.location}
          className={_searchClasses}
          data-hide={locations ? undefined : true}
        >
          <span>Search by Location</span>
          <i className='fas fa-map-marker'></i>
        </li>
      </ul>
    </div>
  )

  const inProgressFilter = searching ? (
    <label className='flex items-center mb-0 ml-4'>
      <Switch
        checked={state.inProgress ?? false}
        onChange={methods.toggleinProgress}
      />
      <span className='ml-2'>Show Processing Only</span>
    </label>
  ) : null

  const searchButtons = !is.citizen ? (
    <Fragment>
      {/* {console.log(form)} */}
      <div className='form-records-table-search'>{_searchButtons}</div>
      {inProgressFilter}
    </Fragment>
  ) : null

  if (!searching && read_params) {
    const { admin, citizen, label, values } = read_params

    // prettier-ignore
    if (
      false ||
      (!citizen && !admin) ||
      (citizen && is.citizen) ||
      (admin && is.admin)
    ) {
      paramOptions = (
        <div className='flex space-x-4 items-center ml-auto'>
          <span className='flex-shrink-0'>{label}</span>
          <select
            className='form-control'
            value={current_param}
            onChange={setParams}
          >
            {Object.keys(values).map(k => (
              <option key={k} value={k}>
                {k}
              </option>
            ))}
          </select>
        </div>
      )
    }
  }

  return (
    <div className='content'>
      <div className='page-inner'>
        <div className='row'>
          {left}
          <div className='col-md-9'>
            <div className='card'>
              <div
                className='card-header flex items-center'
                data-hide={is.citizen}
              >
                {searchButtons}
                {paramOptions}
              </div>
              <div className='card-body'>
                <div className='table-responsive form-records-table'>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CustomTableActions ({ actions }) {
  if (!actions?.length) return null

  const action = ({ text, icon, fn }) => (
    <div
      key={text}
      onClick={fn}
      className={
        'flex items-center space-x-2 cursor-pointer rounded-lg mb-1 p-1'
        + ' transition-colors hover:bg-gray-200 hover:text-black'
      }
    >
      {isValidElement(icon) ? icon : null}
      {typeof icon == 'string' ? <i className={'text-xl fas fa-' + icon} /> : null}
      <span className='text-lg' children={text} />
    </div>
  )

  return (
    <Fragment>
      <div className='separator-solid mb-2' />
      {actions.map(action)}
    </Fragment>
  )
}

function ProxyRequests ({ requests, start }) {
  if (!requests?.length) return null

  const list = requests.map(req => (
    <span key={req} onClick={start(req)}>
      {fullName(req.user)}
    </span>
  ))

  return (
    <div className='card card-round form-wizard__proxy-reqs'>
      <div className='card-body'>
        <h5>Approved Proxies</h5>
        <div className='overflow-y-auto' style={{ maxHeight: '7rem' }}>
          {list}
        </div>
      </div>
    </div>
  )
}
