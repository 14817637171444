import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import countries from '@constants/countries'

export default class YouthLeader extends React.Component {
  resource = '/certified_youth_leaders'
  form_key = 'certified_youth_leader'
  defaults = {}

  // Delete lines as needed
  fee           = 25
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'nationality',
    },
    {
      name: 'marital_status',
      options: ['Single', 'Married', 'Divorced', 'Widowed'], 
    },
    {
      name: 'Address',
      label: 'Street address',
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'country',
      options: countries,
    },
    {
      name: 'qualifications_list',
      label: 'Qualifications (Degrees, Certifications, Etc.)',
      columns: ['qualification'],
      format: v => v.qualification 
    },
    {
      name: 'experience_list',
      label: 'Experience in Youth/Work Organizations',
      columns: ['organization', 'position'],
      // format: v => [v.organization, v.position]
    },
    /*{
      name: 'vaccination_status',
      options: ['Fully vaccinated', 'Partilly Vaccinated (1 dose)', 'Not Vaccinated'],
    },*/
    {
      name: 'place_of_employment',
    },
    {
      name: 'position',
    },

    '::Organization & Contact Reference',
    {
      name: 'organization_name',
      label: 'Name of organiztion',
    },
    {
      name: 'current_position',
      label: 'Current position'
    },
    {
      name: 'years_served',
      label: 'Years served in organization',
      is: 'integer'
    },
    {
      name: 'experience',
      label:'Experience in Youth work/Organization & positions Held',
      type: 'textarea',
    },
    "::Reference 1- Organization",
    /*{
      name: 'contact_first_name',
      label: 'Contact Reference First Name',
    },
    {
      name: 'contact_last_name',
      label: 'Contact Reference Last Name',
    },
    {
      name: 'contact_position',
      label: 'Contact Reference Position',
    },
    {
      name: 'contact_years_as_leader',
      label: 'Contact Reference # of Years as Member/Leader',
      is: 'integer'
    },
    {
      name: 'contact_phone_numb',
      type: 'phone',
      label: 'Contact Reference Phone #',
    }, */

    {
      name: 'first_name',
    },
    {
      name: 'last_name',
    },
    {
      name: 'home_phone_number',
      type: 'phone'
    },
    {
      name: 'work_phone_number',
      type: 'phone'
    },
    {
      name: 'cell_phone_number',
      type: 'phone',
    },
    {
      name: 'oragnization_name',
      label: 'Name of organization',
    },
    {
      name: 'organization_position',
      label: 'Position in Organization',
    },
    "::Reference 2-Character Reference",
    {
      name: 'ref_first_name',
      label: 'First name',
    },
    {
      name: 'ref_last_name',
      label: 'Last name',
    },{
      name: 'phone_contact',
      label: 'Phone number',
      type: 'phone',
    },
    {
      name: 'relation',
      label: 'Relation to you'
    },
    {
      name: 'org_name',
      label: 'Name of Organization',
    },
    {
      name: 'position',
    },

    '::Emergency Contact(s)',
    {
      name: 'emergency_contacts',
      columns: [
        'first_name',
        'last_name',
        {
          name: 'relationship',
          datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
        },
        {
          name: 'phone_numb',
          label: 'Phone #',
          type: 'phone',
        }
      ]
    },
  
    '::SPACER',
    {
      name: 'photo_upload',
      key: 'image.0',
      type: 'file:image',
      label: 'Self portrait',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
