import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import toastr from "@modules/toastr";
import Swal from "sweetalert2";

export default class Phytosanitary extends React.Component {
  resource = "/phytosanitaries";
  form_key = "phytosanitary";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};

  skip_location = true;
  hide_buttons = false;

  custom_actions = [
    {
      text: "Enter Certificate Number",
      icon: "stamp",
      test: (r) => r.payment_status,
      fn: (record) =>
        Swal.fire({
          title: "Enter Certificate Number",
          input: "text",
          inputLabel: "Certificate Number",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: "Submit",
          confirmButtonColor: "#26AEEF",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.updateCertNumber(result.value, record);
          }
        }),
    },
  ];

  validate = null;

  foremost = null;

  updateCertNumber = async (cert_numb, record) => {
    const { props, resource, form_key } = this;
    const { history, location } = props;
    const { axios } = $app;

    try {
      await axios.put(resource + "/update_application", {
        [form_key]: {
          form_num: record.form_num,
          application_decision: "approved",
          cert_numb,
        },
      });

      if (location.state?.view) {
        const view = { ...location.state.view };
        view.application_decision = "approved";
        view.fields[4].value = cert_numb;

        history.replace(location.pathname, { ...location.state, view });
      }

      Swal.fire(
        "Updated!",
        "Certificate Number has been successfully updated",
        "success"
      );
    } catch (error) {
      console.error(error);
      toastr.error("Error", "Unable to set Certificate Number");
      return;
    }

    try {
      await axios.post("/emails", {
        email: record.user.email,
        subject: "MyGateway Portal Update - Commercial Fishing",
        message: `
            Good day ${record.user.first_name.capitalize()},
            <br/>
            Your Phytosanitary application has been approved.
            Your certificate number is <strong>${cert_numb}</strong>.
            <br/><br />
            Thank you for using the MyGateway Portal.
          `,
      });

      toastr.success("Success", "Email successfully sent");
    } catch (err) {
      console.error(err);
      toastr.error("Error", "Unable to send email");
    }
  };

  fields = (form) => [
    ":: Fumingation or disinfectant treatment (if required by importing country)",
    {
      name: "fumigation_date",
      label: "Date",
      type: "date",
    },
    {
      name: "treatment",
    },
    {
      name: "fumigation_duration",
      label: "Duration of Exposure",
    },
    {
      name: "chemical_concentration",
      label: "Chemical and Concentration",
    },
    {
      name: "cert_number",
      hide: true,
      view: true,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
