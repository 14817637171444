import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import { Modal } from 'react-responsive-modal'

import DateField from '@shared/form-fields/date'
import SelectField from '@shared/form-fields/select'
import InputField from '@shared/form-fields/input'

const AppointmentModal = props => {
  const {
    createModal,
    closeModal,
    on,
    locations,
    location,
    services,
    service,
    appointmentDate,
    submit,
    timesExcluded,
    preconfigured,
    isCitizen,
    userInfo,
    limits,
    isAllowedDays,
  } = props

  const metadata = limits?.locations[location || 'sports stadium'] || null

  const fullName = `${userInfo?.user_first_name?.capitalize()} ${userInfo?.user_last_name?.capitalize()}`

  return (
    <Modal
      open={createModal}
      styles={{ modal: { width: '600px' } }}
      onClose={closeModal}
      center
    >
      <div className='modal-header'>
        <h5 className='modal-title'> Schedule Appointment</h5>
      </div>
      <ValidatorForm onSubmit={submit}>
        {!isCitizen && (
          <Field name='user_name' label='Name'>
            <InputField
              name='user_name'
              value={fullName}
              onChange={() => {}}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
              required
              disabled
            />
          </Field>
        )}
        <Field name='service' label='Service'>
          <SelectField
            name='service'
            key={service}
            options={services}
            values={[services.find(s => s.value == service) || {}]}
            value={service}
            onChange={on.service}
            className='form-control'
            validators={['required']}
            errorMessages={['Required']}
            required
            disabled={!!preconfigured?.schedulable_type || props.rescheduling}
          />
        </Field>
        <Field name='location' label='Location'>
          <SelectField
            name='location'
            key={location}
            options={locations}
            value={location}
            values={[locations.find(l => l.value == location) || {}]}
            onChange={on.location}
            className='form-control'
            validators={['required']}
            errorMessages={['Required']}
            required
            disabled={!!preconfigured?.location}
          />
        </Field>
        <Field name='appointment_date' label='Date'>
          <DateField
            type='date'
            className='form-control'
            name={'appointment_date'}
            showTimeSelect
            onChange={on.date}
            selected={appointmentDate}
            value={appointmentDate}
            validators={['required']}
            errorMessages={['Required']}
            dateFormat='MMMM d, yyyy h:mm aa'
            filterDate={isAllowedDays}
            minDate={new Date()}
            timeIntervals={metadata?.interval}
            timeCaption='Slots'
            minTime={metadata?.min}
            maxTime={metadata?.max}
            excludeTimes={timesExcluded}
          />
        </Field>
        <div className='modal-footer'>
          <input
            className='btn custom-btn btn-round'
            type='submit'
            value='Submit'
          />
        </div>
      </ValidatorForm>
    </Modal>
  )
}

const Field = ({ name, label, children }) => {
  return (
    <div className='form-group form-show-validation row'>
      <label htmlFor={name} className='col-md-3 col-sm-4 mt-sm-2 text-right'>
        {label}
        <span className='required-label'>*</span>
      </label>
      <div className='col-md-8'>{children}</div>
    </div>
  )
}

export default AppointmentModal
