import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import Select from 'react-dropdown-select'

class SelectField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () =>
    this.state.isValid ? null : (
      <div style={this.errorStyles}>{this.getErrorMessage()}</div>
    )

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props

    if (!rest.className) rest.className = 'form-control'
    if (!this.state.isValid) rest.className += ' input_error'

    return (
      <div>
        <Select {...rest} />
        {this.errorText()}
      </div>
    )
  }
}

export default SelectField
