import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class FirearmLicenceRenewal extends React.Component {
  id = 'GunLicenseRenewal'
  service = 'gun license renewal'
  title = 'Firearm Licencing Renewal'
  agency = 'rbpf'
  resource = '/gun_license_renewals'
  form_key = 'gun_license_renewal'

  postpaid = true
  postpay_key = 'fee'

  foremost = {
    label: 'Are you able to provide your last Firearms Certificate?',
    type: 'select:bool',
    long: true,
    test: v => v || 'Please apply in person at the nearest Police Station',
  }

  fields = () => [
    {
      name: 'license_number',
      label: 'Gun Licence Number',
      is: 'text',
      regex: /^[0-9-]+$/,
    },
    {
      label: 'Type of Licence',
      name: 'type_of_license',
      options: ['Shotgun', 'Rifle', 'Special'],
    },
    {
      name: 'book',
      label: 'Firearms Certificate',
      type: 'file:image',
      hint: 'The first two pages AND the last page w/ the stamp',
      key: 'image.0',
    },
    {
      name: 'book_two',
      label: 'Additional Upload #1',
      type: 'file:image',
      key: 'image.1',
      required: false,
    },
    {
      name: 'book_three',
      label: 'Additional Upload #2',
      type: 'file:image',
      key: 'image.2',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
