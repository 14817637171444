import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class FactoryVessel extends React.Component {
  id = "FactoryVesselLicense";
  service = "factory vessel license";
  title =
    "Licence to Operate A Factory Vessel,Holding Station, or Processing Plant";
  agency = "moa";
  resource = "/factory_vessel_licenses";
  form_key = "factory_vessel_license";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    "::Type of License",
    {
      name: "factory_vessel",
      label: "Factory Vessel",
      type: "checkbox",
    },
    {
      name: "processing_plant",
      label: "Processing Plant",
      type: "checkbox",
    },
    {
      name: "holding_station",
      label: "Holding Station",
      type: "checkbox",
    },
    {
      name: "license_type",
      label: "Type of License",
      options: ["Factory Vessel", "Buying/Holding Station", "Processing Plant"],
    },
    `::${form.license_type || ""}`,
    {
      name: "facility_size",
      label: "Facility Size",
      hint: form.license_type === "Factory Vessel" ? "ft" : "sq ft",
    },
    {
      name: "num_of_rooms",
      label: "Number of Refridgerated Storage Rooms",
      is: "integer",
    },
    {
      name: "storage_capacity",
      label: "Total Refridgerated Storage Capacity",
      hint: "lbs",
    },
    {
      name: "num_of_frozen_rooms",
      label: "Number of Frozen Storage Rooms",
      is: "integer",
    },
    {
      name: "frozen_storage_capacity",
      label: "Total Frozen Storage Capacity",
      hint: "lbs",
    },
    {
      name: "num_of_freezers",
      label: "Number of Freezers",
    },
    {
      name: "type_of_freezers",
      label: "Type of Freezers",
    },
    {
      name: "production_capacity",
      label: "Production Capacity",
      hint: "lbs/day",
    },
    {
      name: "ice_making_capacity",
      label: "Ice Making Capacity",
      hint: "lbs/hr",
    },
    {
      name: "major_equipment",
      label: "Other Major Equipment",
    },
    "::SPACER",
    {
      name: "portable_water_source",
      label: "Source of Portable Water",
    },
    {
      name: "additional_docs",
      label: "List of additional Documentation accompanying application",
      type: "checklist",
      options: [
        "Valid Business License",
        "Certificate of Incorporation",
        "Annual Return for preceeding year",
        "Certificate of Sanitization",
        "Water Distribution Map",
        "Pest Control Map",
        "Flow Chart",
        "Standard Operating Procedures",
        "Sanitization Standard Operating Procedures",
        "Hazard Analysis",
        "HACCP Plan",
        "Scaled Drawing of Facility Layout",
      ],
    },
    {
      name: "business_license_upload",
      label: "Valid Business License",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Valid Business License"),
    },
    {
      name: "incorporation_cert_upload",
      label: "Certificate of Incorporation",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Certificate of Incorporation"),
    },
    {
      name: "annual_return_upload",
      label: "Annual Return for preceeding year",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Annual Return for preceeding year"),
    },
    {
      name: "sanitization_cert_upload",
      label: "Certificate of Sanitization",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Certificate of Sanitization"),
    },
    {
      name: "water_distribution_map_upload",
      label: "Water Distribution Map",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Water Distribution Map"),
    },
    {
      name: "pest_control__map_upload",
      label: "Pest Control Map",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Pest Control Map"),
    },
    {
      name: "flow_chart_upload",
      label: "Flow Chart",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Flow Chart"),
    },
    {
      name: "standard_operating_procedure_upload",
      label: "Standard Operating Procedures",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Standard Operating Procedures"),
    },
    {
      name: "sanitization",
      label: "Sanitization Standard Operating Procedures",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.(
        "Sanitization Standard Operating Procedures"
      ),
    },
    {
      name: "hazard_analysis_upload",
      label: "Hazard Analysis",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Hazard Analysis"),
    },
    {
      name: "haacp_plan_upload",
      label: "HACCP Plan",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("HACCP Plan"),
    },
    {
      name: "facility_layout_upload",
      label: "Scaled Drawing of Facility Layout",
      type: "file:pdf",
      hide: !form.additional_docs?.has?.("Scaled Drawing of Facility Layout"),
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
