import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class MontagueVendor extends React.Component {
  resource = '/montague_vendors'
  form_key = 'montague_vendor'

  fee = 11.2
  skip_location = true
  business_only = true

  custom_actions = []

  validate = null

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      heading: '<span>&nbsp;</span>',
      view: false,
    },
    {
      name: 'area_of_use',
      label: 'I hereby apply for permission for the use of the following area',
      type: 'textarea',
    },
    {
      name: 'stall_type',
      label:
        'for the purpose of setting a stall/stand for the display for the sale of the following',
      type: 'textarea',
    },
    '::Supporting Documents',
    {
      name: 'health_cert_upload',
      label: 'Current Health Certificate',
      type: 'file:image',
      key: 'image.0',
    },
    {
      name: 'food_handlers_cert_upload',
      label: 'Certificate (Food Handlers Health Programme)',
      type: 'file:image',
      key: 'image.3',
    },
    {
      name: 'police_approval_upload',
      label: 'Police Approval',
      type: 'file:pdf',
      key: 'image.2',
    },
    {
      name: 'business_license_upload',
      label: 'Business License',
      type: 'file:pdf',
      key: 'image.1',
    },
    {
      name: 'food_van_approval_upload',
      label: 'Approval from Buildings control section, for food vans',
      type: 'file:pdf',
      key: 'image.4',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
