// Only use default if `val === undefined`
export const fallback = (val, fallback) => (val === undefined ? fallback : val)

export const debounce = (fn, ms = 300) => {
  let timer
  return (...args) => {
    // If we're debouncing events, we need to call event.persist() since React 'pools' events
    // (TL;DR the props will be nullified before the timeout is ever called)
    // See: https://reactjs.org/docs/legacy-event-pooling.html
    if (args[0]?.target && typeof args[0]?.persist == 'function') args[0].persist()

    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), ms)
  }
}
