import React from 'react'
import Modal from 'react-responsive-modal'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys, withoutKeys } from '@helpers/objects'

import {
  ExemptionsForm,
  calculateExemptionToatal,
  ApprovalForm,
} from './_exemptions-form'
// import redirectToCustoms from './_redirect'

export default class EconomicRecovery extends React.Component {
  state = {
    record: null,
    loading: false,
    picker: null,
  }

  long_name = 'Special Economic Recovery Zone<br />(Grand Bahama & Abaco)'
  resource = '/special_eco_recovery_zones'
  form_key = 'special_eco_recovery_zone'
  free = true

  // before_apply = redirectToCustoms
  skip_location = true
  hide_buttons = {
    status: true,
  }

  validate = form => {
    if (!form.exemption_category.size) {
      return 'No exemption categories were selected'
    }

    const exemptions = [
      'building_supplies_exemption_list',
      'machinery_exemption_list',
      'furniture_exemption_list',
      'electrical_exemption_list',
      'hardware_exemption_list',
      'plumbing_exemption_list',
      'appliances_exemption_list',
      'business_license_exemption_list',
    ]

    const transformed = exemptions.reduce((obj, prop) => {
      const ex = form[prop]
        ?.filter(row => row.item)
        ?.map(row => ({
          ...withoutKeys({ ...row }, 'tariff'),
          value: Number(row.value).toFixed(2),
          tariff_id: row.tariff?.id,
          tariff_code: row.tariff?.value,
        }))

      if (ex) obj[prop] = ex
      return obj
    }, {})

    return {
      ...transformed,
      total_value_of_exemption: exemptions
        .reduce((sum, prop) => sum + calculateExemptionToatal(form[prop]), 0)
        .toFixed(2),
    }
  }

  openPicker = (data, target) => {
    const options = data.map(d => ({
      label: `<span class='text-muted'>${d.value}</span><br/>${d.label}`,
      value: extractKeys(d, 'id', 'value'),
    }))

    this.setState({
      picker: {
        options,
        unskippable: true,
        heading: 'Please Select The Correct Tariff Code',
        target: target.replaceAll('::', '.').replace('item', 'tariff'),
        onSelect: () => this.setState({ picker: null }),
      },
    })
  }

  custom_action = {
    text: 'Approve Exemptions',
    icon: 'check-square',
    fn: record => this.setState({ record }),
  }

  close = () => this.setState({ record: null })

  update = status => {
    switch (status) {
      case 'SAVING':
        return this.setState({ record: null, loading: true })
      case 'DONE':
      case 'FAILED':
        return this.setState({ loading: false })
      default:
    }
  }

  getCategories = company_type =>
    company_type === 'individual'
      ? ['Building Supplies', 'Furniture', 'Appliances']
      : [
          'Building Supplies',
          'Commercial Machinery',
          'Furniture',
          'Electrical Fixtures',
          'Hardware Supplies',
          'Plumbing Fixtures',
          'Business Licence',
        ]

  foremost = {
    name: 'island',
    label: 'Are you from Abaco or Grand Bahama?',
    type: 'select:bool',
    test: v =>
      v ||
      'Sorry, but this service is only available for citizens residing in Abaco or Grand Bahama.',
  }

  fields = (form, viewMode) => {
    const { openPicker, getCategories } = this

    return [
      {
        name: 'client_type',
        label: 'Application For?',
        options: [
          { label: 'Individual', value: 'individual' },
          { label: 'Business', value: 'business' },
        ],
        view: {
          label: 'Application Type',
        },
      },
      {
        name: 'company_name',
        label: 'Name of Company',
        hide: form.client_type != 'business',
      },
      {
        name: 'company_type_age',
        label: 'New or Existing Business',
        options: ['New', 'Existing'],
        hide: form.client_type != 'business',
      },
      {
        name: 'company_type',
        label: 'Company Type',
        options: [
          'Incorporated / Limited Company',
          'Sole Proprietorship',
          'Partnership',
          'Home Based',
        ],
        hide: form.client_type != 'business',
      },
      {
        name: 'trading_name',
        hide: form.company_type != 'Sole Proprietorship',
      },
      {
        name: 'shareholders',
        label: 'Please list Shareholders/Directors',
        columns: [{ name: 'shareholders', width: 64 }],
        hide:
          form.company_type != 'Incorporated / Limited Company' &&
          form.company_type != 'Partnership',
      },

      {
        name: 'bl_number',
        label: 'Business License Number',
        hide: form.client_type != 'business',
      },
      {
        name: 'tax_id_number',
        label: 'TIN number',
        hide: form.client_type != 'business',
      },
      {
        name: 'tax_comp_cert_expiry',
        label: 'Tax Compliance Certificate Expiry Date',
        type: 'date',
        showYearDropdown: true,
        hide: form.client_type != 'business',
      },
      {
        name: 'business_turnover',
        label: 'Sales/Business Turnover',
        hide: form.client_type != 'business',
        hint: 'Per Annum.',
        is: 'currency',
      },
      {
        name: 'years_of_business',
        label: 'Years of Business',
        type: 'integer',
        hide: form.client_type != 'business',
        hint: 'In Years',
        is: 'float',
      },
      {
        name: 'exemption_category',
        type: 'checklist',
        label: 'Exemption Types',
        options: getCategories(form.client_type),
        hide: !form.client_type,
      },
      '::Supporting Documents',
      {
        name: 'tax_comp_cert_upload',
        label: 'Tax Compliance Certificate',
        type: 'file:pdf',
        key: f => f.image[1],
        hide: form.client_type != 'business',
      },
      {
        name: 'business_license_upload',
        label: 'Business Licence',
        type: 'file:pdf',
        key: f => f.image[6],
        hide: form.client_type != 'business',
      },
      {
        name: 'site_plan_upload',
        label: 'Min. Of Public Works Stamped Floor Plan',
        type: 'file:pdf',
        key: f => f.image[3],
      },
      {
        name: 'building_plan_upload',
        label: 'Building Plan',
        type: 'file:pdf',
        key: f => f.image[4],
      },
      {
        name: 'occupancy_letter_upload',
        label: 'Occupancy Certificate',
        type: 'file:pdf',
        key: f => f.image[5],
        hide: !form.exemption_category?.has?.('Furniture'),
      },
      {
        name: 'invoice_upload',
        label: 'Invoice',
        type: 'file:pdf',
        key: f => f.image[2],
      },
      {
        heading: 'Exemption Details',
        hide: !form.client_type,
      },
      {
        name: 'building_supplies_exemption_list',
        label: 'Building Supplies',
        ...ExemptionsForm(
          form.building_supplies_exemption_list,
          openPicker,
          viewMode
        ),
        hide: !form.exemption_category?.has?.('Building Supplies'),
      },
      {
        name: 'furniture_exemption_list',
        label: 'Furniture',
        ...ExemptionsForm(form.furniture_exemption_list, openPicker, viewMode),
        hide: !form.exemption_category?.has?.('Furniture'),
      },
      {
        name: 'machinery_exemption_list',
        label: 'Commercial Machinery',
        ...ExemptionsForm(form.machinery_exemption_list, openPicker, viewMode),
        hide: !form.exemption_category?.has?.('Commercial Machinery'),
      },
      {
        name: 'electrical_exemption_list',
        label: 'Electrical Fixtures',
        ...ExemptionsForm(form.electrical_exemption_list, openPicker, viewMode),
        hide: !form.exemption_category?.has?.('Electrical Fixtures'),
      },
      {
        name: 'hardware_exemption_list',
        label: 'Hardware Supplies',
        ...ExemptionsForm(form.hardware_exemption_list, openPicker, viewMode),
        hide: !form.exemption_category?.has?.('Hardware Supplies'),
      },
      {
        name: 'plumbing_exemption_list',
        label: 'Plumbing Fixtures',
        ...ExemptionsForm(form.plumbing_exemption_list, openPicker, viewMode),
        hide: !form.exemption_category?.has?.('Plumbing Fixtures'),
      },
      {
        name: 'business_license_exemption_list',
        label: 'Business License and Real Property Tax',
        ...ExemptionsForm(
          form.business_license_exemption_list,
          openPicker,
          viewMode
        ),
        hide: !form.exemption_category?.has?.('Business Licence'),
      },
      {
        name: 'appliances_exemption_list',
        label: 'Appliances',
        ...ExemptionsForm(form.appliances_exemption_list, openPicker, viewMode),
        hide: !form.exemption_category?.has?.('Appliances'),
      },
      '::SPACER',
      {
        name: 'penalty',
        type: 'checkbox',
        label: `
          Providing false or misleading information
          &mdash; or using exemptions for purposes other than applied for
          &mdash; will lead to prosecution and related fines and criminal penalties.
        `,
      },
    ]
  }

  hidden_fields = () => [
    {
      name: 'total_value_of_exemption',
      view: {
        label: 'Total Exemption Value',
        value: v => '$' + Number(v).toLocaleString(),
      },
    },
    {
      name: 'pdf_upload',
      type: 'file:image',
      label: 'Exemption Certificate',
      key: f => f.image[7],
    },
  ]

  render = () => {
    const { state, props, close, fields, resource, form_key, update } = this
    const { record } = state

    const fprops = {
      update,
      resource,
      close,
      form_key,
      location: props.location,
      history: props.history,
    }

    if (record)
      Object.assign(fprops, {
        record,
        fields: fields(record),
      })

    return (
      <React.Fragment>
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal open={!!record} onClose={close} center>
          <ApprovalForm {...fprops} />
        </Modal>
      </React.Fragment>
    )
  }
}
