import React from 'react'
import Papa from 'papaparse'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

import { constituencies } from '@constants/prd'

export default class AdvancePollerRegistration extends React.Component {
  state = {
    form_key: 'advanced_poller_register_prd',
    resource: '/advanced_poller_register_prds',
  }

  id = 'AdvancedPollerRegisterPrd'
  service = 'advanced poller register prd'
  title = 'Election Agent Application'
  agency = 'prd'
  free = true

  admin_form = 'New Applicant'
  skip_location = true
  disable_pending_check = true

  overrides = {
    search: true,
    columns: true,
  }

  hooks = {
    'post:create': () => {
      this.setState({
        form_key: 'advanced_poller_register_prd',
        resource: '/advanced_poller_register_prds',
      })
    },
  }

  search = query => r =>
    [`${r.first_name} ${r.last_name}`, r.constituency].some(str =>
      query.test(str)
    )

  columns = props => [
    {
      name: 'Name',
      cell: r => (
        <span onClick={props.view(r, false, { hide_user: true })} data-action>
          {`${r.first_name} ${r.last_name}`.capitalize()}
        </span>
      ),
    },
    {
      name: 'Constituency',
      selector: 'constituency',
      sortable: true,
      format: r => r.constituency.capitalize(),
    },
    {
      name: 'Address',
      selector: 'constituency_address',
      sortable: true,
    },
    {
      name: 'Applied?',
      selector: 'form_link',
      format: r => (r.form_link ? 'Yes' : 'No'),
    },
  ]

  validate = form =>
    new Promise((resolve, reject) => {
      if (form.using_csv) {
        Papa.parse(form.csv, {
          header: true,
          complete: ({ data }) => {
            this.setState(
              {
                resource: '/advanced_poller_register_prds/bulk_create',
                // form_key: ''
              },
              () => {
                resolve({
                  form_list: data
                    .filter(d => d.nib_number)
                    .map(d => JSON.stringify(d)),
                })
              }
            )
          },
        })
      } else {
        resolve({
          constituency_code: constituencies.indexOf(form.constituency) + 1,
        })
      }
    })

  fields = (form, viewing) => {
    const sf = [
      {
        name: 'name',
        hide: true,
        view: {
          value: () => `${form.first_name} ${form.last_name}`.capitalize(),
        },
      },
      {
        name: 'constituency',
        options: constituencies,
      },
      {
        name: 'constituency_address',
      },
      {
        name: 'application_reason',
        label: 'Reason for Application',
      },
      {
        name: 'nib_number',
        type: 'integer',
        label: 'Applicant NIB#',
      },
      {
        name: 'first_name',
        view: false,
      },
      {
        name: 'last_name',
        view: false,
      },
    ].map(f => ({ ...f, hide: f.hide || form?.using_csv !== false }))

    if (viewing) return sf

    return [
      {
        name: 'using_csv',
        type: 'select:bool',
        save: false,
        hide: typeof form?.using_csv == 'boolean',
        label: 'Uploading A CSV?',
      },
      {
        name: 'csv',
        save: false,
        hide: form?.using_csv !== true,
        type: 'file',
        accept: '.csv',
        label: 'CSV Upload',
      },
      ...sf,
    ]
  }

  render = () => <Wizard {...SERVICE_PROPS({ ...this, ...this.state })} />
}
