import React, { Fragment } from 'react'
import Swal from 'sweetalert2'
import Modal from 'react-responsive-modal'
import { fullName } from '@modules/form-wizard/utils'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import SERVICE_PROPS from '@helpers/extract-service-props'
// import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
// import InputField from '@shared/form-fields/input'
import SelectField from '@shared/form-fields/select'
import Loader from '@shared/loader'
// import DateTimeFieldV2 from '@shared/form-fields/datetime'
import DateTimeField from '@shared/form-fields/date'
import moment from 'moment'

export default class TradeDispute extends React.Component {
  state = { record: null, loading: false, date: new Date() }

  resource = '/trade_disputes'
  form_key = 'trade_dispute'

  free = true
  skip_location = true
  hide_buttons = false
  legal_disclaimer = true

  hide_buttons = {
    status: true,
    deny: true,
  }

  color_codes = (wizard, bg) => {
    if (wizard.citizen) return []

    return [
      {
        style: bg('#0E23'),
        when: r => r.notes.find(note =>
          /marked as ready .+ industrial tribunal/i.test(note.text)
        )
      }
    ]
  }

  custom_actions = [
    // Records Manager / Clerk ->
    // Director ->
    // Concilliator ->
    // Typist
    {
      text: 'Forward To Director',
      icon: 'chevron-right',
      roles: ['officer'],
      test: r => r.application_decision == 'pending',
      fn: async record => this.approve(record, 'Forward to Director'),
    },
    {
      text: 'Process Application',
      icon: 'bullhorn',
      roles: ['director'],
      test: r => r.application_decision == 'officer viewed' || r.application_decision == 'pending',
      fn: async record => this.approve(record, 'Forward to Concilliator'),
    },
    {
      text: 'Set Appointment Date',
      icon: 'chevron-right',
      roles: ['concilliator'],
      test: r => r.application_decision == 'director viewed',
      fn: async record => this.setState({ record })
    },
    {
      text: 'Notify Document for Pickup',
      icon: 'chevron-right',
      roles: ['typist'],
      test: r => r.application_decision == 'concilliator viewed',
      fn: async record => this.approve(record, 'Notify Document for Pickup'),
    },
  ]

  // defaults = {
  //   "client_type": "individual",
  //   "po_box": "N00001",
  //   "phone_number": "(242) 441-3229",
  //   "employer_first": "Wendy's\n(242) 555-5555",
  //   "employer_position": "Store Manager",
  //   "union_name": "Bahamas Hotel Catering And Allied Workers Union.",
  //   "job_title": "Floor Staff",
  //   "relevant_dispute_issues": "Overtime not being clocked and paid correctly",
  //   "action_taken": "N/A",
  // }

  // validate = values => {
  //   const update = {
  //     employer: {
  //       sex: values.employer_sex,
  //       first_name: values.employer_first,
  //       middle_name: values.employer_middle,
  //       last_name: values.employer_last,
  //     },
  //   }

  //   return update
  // }

  foremost = null

  validate = form => {
    const disputeList = form.relevant_dispute_issues_ghost.filter(x => x != 'Other')
    if (form.relevant_dispute_issues_ghost.includes('Other')) {
      const otherList = form.other_dispute_issue.map(x => x.List_all_issues)
      const mergedList = disputeList.concat(otherList);
      // const objectList = mergedList.map(x => ({ issue: x }));
      // console.log(disputeList);
      // console.log(otherList);
      // console.log(mergedList);
      // console.log(objectList);

      return { relevant_dispute_issues: mergedList }
    } else {
      return { relevant_dispute_issues: disputeList }
    }
  }

  // hooks = $app.roles.includes('director') ? {
  //   'read': async () => {
  //     const { data } = await $app.axios.get('/trade_disputes/show_concilliators')
  //     this.setState({ all_concilliators: data })
  //     console.log(data)
  //     console.log('concilliators', this.state.all_concilliators)
  //     return null
  //   }
  // } : null

  fields = form => [
    {
      heading: `
        <span class='uppercase'>
          Persuant to the provisions of paragraph (a), (b), (c) and (d) of
          subsection (1) of section (68) and subsection (3) of
          section 68 of the industrial relations act, chapter 321 of the
          statute law counselate of The Bahamas 2000, you are hereby
          notified that a trade dispute exists between the parties below:
        </span>
      `,
      view: false,
    },
    {
      name: 'appointment_date',
      hide: true,
      view: {
        label: 'Appointment Date',
        value: v => (v ? moment(v).format('MMMM Do YYYY, h:mm:ss a') : 'N/A')
      },
    },
    {
      name: 'appointment_location',
      hide: true,
      view: true,
    },
    // ...BUSINESS_FIELDS(form),
    // '::Personal Details',
    // {
    //   name: 'po_box',
    //   label: 'P. O. Box',
    //   required: false,
    // },
    // {
    //   name: 'phone_number',
    //   type: 'phone',
    //   use_profile: 'additional_phone_number'
    // },
    '::Employer Details',
    {
      name: 'company_name',
      label: '(1) Name of Company',
      // type: 'textarea',
      // long: true,
    },
    {
      name: 'company_address',
      label: '(1) Address of Company',
      // type: 'textarea',
      // long: true,
    },
    {
      name: 'company_phone',
      label: '(1) Company Phone Number',
      type: 'phone',
      // long: true,
    },
    // {
    //   name: 'employer_middle',
    //   label: 'Middle Name',
    //   required: false,
    // },
    // {
    //   name: 'employer_last',
    //   label: 'Last Name',
    // },
    // {
    //   name: 'employer_sex',
    //   label: 'Sex',
    //   options: ['Male', 'Female'],
    // },
    {
      name: 'employer',
      label: '(1a) Name of the Employer',
      // type: 'textarea',
      // long: true
    },
    {
      name: 'employer_position',
      label: '(1a) Position of the Employer',
      // type: 'textarea',
      // long: true
    },

    '::SPACER',
    {
      name: 'union_name',
      label: '(2) Name of Employee or Trade Union',
      type: 'textarea',
      long: true,
    },
    {
      name: 'job_title',
      label: '(2a) Job Title of Employee',
      type: 'textarea',
      long: true,
    },

    '::Legal Details',
    {
      name: 'attorney_representative',
      type: 'select:bool',
      label: 'Are you being represented by an attorney?',
    },
    {
      name: 'attorney_firm',
      type: 'string',
      label: 'Attorney Firm Name',
      hide: !form.attorney_representative,
    },
    {
      name: 'attorney_address',
      type: 'string',
      label: 'Attorney Firm Address',
      hide: !form.attorney_representative,
    },
    {
      name: 'employee_names_on_behalf',
      label: '(3) Name of Person(s) on behalf of whom the report is made',
      hide: !form.attorney_representative,
      required: false,
      columns: [
        // 'title',
        // {
        //   name: 'nib',
        //   label: 'NIB',
        // },
        {
          name: 'first_name',
          required: false,
        },
        {
          name: 'middle_name',
          required: false,
        },
        {
          name: 'last_name',
          required: false,
        },
      ],
    },
    // Automated Email Section For Attorney
    {
      heading: `
        <span class='uppercase'>
          Optional Automated Mailer - Attorney
        </span>
      `,
      view: false,
      hide: !form.attorney_representative,
    },
    {
      name: 'automated_copy_attorney',
      type: 'select:bool',
      label: 'Do you want MyGateway to send this dispute to your attorney via email?',
      save: false,
      view: false,
      hide: !form.attorney_representative,
    },
    {
      heading: `
        <span><b>
          I acknowledge and understand that it is my responsibility to forward a copy of this Trade Dispute to my attorney.
          I understand that MyGateway is not responsible if my attorney does not receive the generated Trade Dispute.
        </b></span>
      `,
      hide: !form.automated_copy_attorney,
      view: false,
    },
    {
      name: 'attorney_email',
      label: "Attorney's email address",
      type: 'email',
      hide: !form.automated_copy_attorney,
      required: false
    },
    {
      name: 'attorney_declare',
      type: 'checkbox',
      label: `
        I acknowledge and understand that it is my responsibility to forward a copy of this Trade Dispute to my attorney.
        I understand that MyGateway is not responsible if my attorney does not receive the generated Trade Dispute.
      `,
      hide: !form.attorney_representative,
    },
    // Attorney Mailer Section END
    {
      name: 'authority_to_act',
      label: '(4) The Authority to act on behalf of the person desiring the dispute to be reported',
      type: 'textarea',
      long: true,
      hide: !form.attorney_representative,
      required: false,
    },
    {
      name: 'counsel_address',
      label: '(4a) Address of Counsel/Representative',
      type: 'textarea',
      long: true,
      hide: !form.attorney_representative,
      required: false,
    },
    {
      name: 'relevant_dispute_issues',
      label: '(5) Issues Relevant to the Trade Dispute',
      hint: 'Select all that apply',
      columns: ['List_all_issues'],
      view: {
        format: list => list.map(row => ({List_all_issues: row}))
      },
      save: true,
      hide: true,
    },
    {
      name: 'relevant_dispute_issues_ghost',
      // type: 'textarea',
      label: '(5) Select ALL Issues Relevant to the Trade Dispute',
      // columns: ['List_all_issues'],
      hint: 'Please note: Once the application is submitted you are unable to change the dispute. *',
      multi: true,
      view: false,
      options: [
        'Unfair dismissal',
        'Wrongful dismissal', 
        'Overtime ',
        'Vacation pay ',
        'Notice payment',
        'Termination pay',
        'Salary owed',
        'Maternity benefit',
        'Other'
      ]
    },
    {
      name: 'other_dispute_issue',
      type: 'textarea',
      label: '(5b) Other Issue',
      columns: ['List_all_issues'],
      hide: !form.relevant_dispute_issues_ghost?.includes('Other'),
      view: false
    },
    {
      name: 'action_taken',
      type: 'textarea',
      label: '(6) Action taken for dealing with dispute under existing agreement',
      required: false,
    },

    // Automated Email Section
    {
      heading: `
        <span class='uppercase'>
          Optional Automated Mailer - Employer
        </span>
      `,
      view: false,
    },
    {
      name: 'automated_copy',
      type: 'select:bool',
      label: 'Do you want MyGateway to send this dispute to your employer via email?',
      save: false,
      view: false,
    },
    {
      heading: `
        <span><b>
          I acknowledge and understand that it is my responsibility to forward a copy of this Trade Dispute to my former employer.
          I understand that MyGateway is not responsible if my former employer does not receive the generated Trade Dispute.
        </b></span>
      `,
      hide: !form.automated_copy,
      view: false,
    },
    {
      name: 'employer_email',
      label: "Employer's email address",
      type: 'email',
      hide: !form.automated_copy,
      required: false
    },
    {
      name: 'acknowledge_forwarding',
      type: 'checkbox',
      label: `
        I acknowledge and understand that it is my responsibility to forward a copy of this Trade Dispute to my former employer.
        I understand that MyGateway is not responsible if my former employer does not receive the generated Trade Dispute.
      `,
    },
    {
      name: 'declare',
      type: 'checkbox',
      label: `
        I declare that the information entered is true and correct
        to the best of my knowledge.
      `,
    },
    {
      name: 'physical_copy',
      type: 'select:bool',
      // required: false,
      label: `
        I would like to collect a hard-copy of the official notice from the Department of Labour.
      `,
    },
  ]

  closeModal = () => this.setState({ record: null })

  setAppt = async () => {
    const { props, state, resource, form_key } = this
    const { history, location } = props
    const { date, appt_location, record } = state

    this.setState({ loading: true })

    try {
      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          appointment_date: new Date(date),
          appointment_location: appt_location
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'concilliator viewed'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      toastr.success('Success', 'Application successfully updated')
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your application',
        'error'
      )
    } finally {
      this.setState({ record: null, loading: false })
    }
  }

  async approve(record, mTitle, mText = 'Proceed to the next stage regarding') {
    let inputOpts = {
      'approved': "I recommend this applicant",
      'denied': "I do not recommend this applicant",
    }
    const concilliatorList = {};
    if (mTitle === 'Forward to Concilliator') {
      const { data } = await $app.axios.get('/trade_disputes/show_concilliators')
      const concilliatorMap = data.records.map(x => {
        return { id: x.id, name: `${x.first_name} ${x.last_name}` }
      })
      this.setState({ all_concilliators: concilliatorMap })
      this.state.all_concilliators.forEach(e => {
        concilliatorList[`${e.id}`] = e.name;
      })
      // console.log(concilliatorList);
      inputOpts = {
        'approved': "Approve this applicant",
        // 'return': "Application needs to be reprocessed",
        'denied': "Application needs to be reprocessed",
      }
    } else if (mTitle === 'Notify Document for Pickup') {
      inputOpts = {
        'approved': "Send out notification for document pickup",
      }
    }

    let value = null
    let officerID = null

    if (mTitle === 'Forward to Concilliator') {
      const firstStep = await Swal.fire({
        icon: 'question',
        title: mTitle,
        html: `
          ${mText} the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: inputOpts,
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (firstStep.isDismissed) return
      value = firstStep.value

      if (value === 'approved') {
        const lastStep = await Swal.fire({
          icon: 'question',
          title: mTitle,
          html: `
            Select which concilliator you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: concilliatorList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (lastStep.isDismissed) return
        officerID = lastStep.value
        // console.log(officerID)
      }

    } else {
      const firstStep = await Swal.fire({
        icon: 'question',
        title: mTitle,
        html: `
          ${mText} the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: inputOpts,
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (firstStep.isDismissed) return
      value = firstStep.value
    }
      this.setState({ loading: true })
  
    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app
  
    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          concilliator_id: officerID,
        },
      })
  
      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'processed'
  
        history.replace(location.pathname, { ...location.state, view })
      }
  
      toastr.success('Success', 'Application successfully updated')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to update application')
      return
    }
  
    this.setState({ loading: false })
  }

  options = [
    { label: 'Rosetta Street, New Providence', value: 'rosetta street, new providence' },
    { label: 'Carmichael, New Providence', value: 'carmichael, new providence' },
    { label: 'Bimini', value: 'bimini' },
    { label: 'Abaco', value: 'abaco' },
    { label: 'Eight Mile Rock, Grand Bahama', value: 'eight mile rock, grand bahama' },
    { label: "Freeport, Explorer's Way, Grand Bahama", value: "freeport, explorer's way, grand bahama" },
  ]

  onChange = date => this.setState({ date })

  render = () => (
    <Fragment>
      <Loader loading={this.state.loading} />
      <Wizard {...SERVICE_PROPS(this)} />
      <Modal
        open={!!this.state.record && !this.state.loading}
        onClose={this.closeModal}
        classNames={{ modal: 'w-full sm:w-128' }}
        center
      >
        <header className='modal-header'>
          <h4 className='mb-0'>Set Concilliation Hearing Date</h4>
        </header>
        <ValidatorForm onSubmit={this.setAppt}>
          <article className='modal-body'>
            <label htmlFor='date' className='form-label'>
              Please set the applicant's appointment date
            </label>
            {/* <InputField
              type='datetime'
              name='date'
              icon={null}
              min={new Date().toISOString().slice(0, 10)}
              value={this.state.date}
              onChange={ev => this.setState({ date: ev.target.value })}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
              className='form-control'
            /> */}
            <DateTimeField
              name='date'
              dateFormat="MMMM d, yyyy h:mm aa"
              timeInputLabel="Time:"
              showTimeInput
              icon={null}
              minDate={new Date()}
              selected={this.state.date}
              value={this.state.date}
              onChange={this.onChange}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
              className='form-control'
              required
            />
            <label htmlFor='location' className='form-label'>
              Please set the location for the hearing
            </label>
            <SelectField
              name='location'
              value={this.state.appt_location}
              options={this.options}
              // onChange={ev => console.log(ev)}
              onChange={ev => this.setState({ appt_location: ev?.[0]?.value })}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
              required
            />
          </article>
          <footer className='modal-footer'>
            <button type='button' className='btn' onClick={this.closeModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </footer>
        </ValidatorForm>
      </Modal>
    </Fragment>
  )
}
