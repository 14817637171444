import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";
import countries from "@constants/countries";

export default class AnglerLicenceResearch extends React.Component {
  id = "CommercialFishing";
  service = "commercial fishing";
  title =
    "Application for Personal Angler to engage in  Flats Fishing - Research";
  agency = "moa";
  resource = "/commercial_fishings";
  form_key = "commercial_fishing";
  free = false;
  postpaid = false;
  defaults = {
    research: true,
    sportsfishing: false,
  };
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99; //No price given

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "research",
      label: "Purpose for Application",
      hide: true,
      view: {
        value: (v) => (v ? "SportsFishing" : "Research"),
      },
    },
    {
      name: "research_institution_name",
    },
    "research_institution_address",
    {
      name: "research_institution_phone",
      is: "phone",
    },
    {
      name: "research_institution_country",
      options: countries,
    },
    {
      name: "research_institution_island",
      options: islands,
    },
    {
      name: "research_institution_reason",
      label: "Reason for Research",
    },
    {
      name: "fishing_date_start",
      label: "Proposed Start Date of Fishing",
      type: "date",
      max_date: new Date(),
    },
    {
      name: "fishing_date_end",
      label: "Proposed End Date of Fishing",
      type: "date",
    },
    {
      name: "proposed_fishing_areas",
      label: "Proposed Fishing areas",
      options: islands,
      multi: true,
      hint: "Select ALL of the options that apply",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
