import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Fields from '@shared/form-fields'

export const addPermitNote = ({ attachments, type: notable_type }) => ({
  text: 'Add Permit Note',
  icon: 'stamp',
  test: r => /(pending|processing|officer viewed|on hold|reprocess)/.test(r.application_decision),
  fn: async record => {
    let attachment_url

    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Add Permit Note',
      inputAttributes: {
        autocorrect: 'on',
      },
      showCancelButton: true,
      confirmButtonText: 'Next',
      inputValidator: v => (!!v ? null : 'Note cannot be empty'),
    })

    if (!text) return

    if (attachments?.length) {
      const inputOptions = attachments.reduce(
        (obj, { desc, image }) => ({
          ...obj,
          [image]: desc.capitalize(),
        }),
        {
          NONE: 'No attachment',
        }
      )

      const { value } = await Swal.fire({
        input: 'select',
        title: 'Add Permit Note',
        text: 'Choose an attachment',
        confirmButtonText: 'Submit',
        showCancelButton: true,
        inputOptions,
      })

      if (!value) return
      attachment_url = value == 'NONE' ? null : value
    }

    try {
      const data = await $app.axios.post('/services/notes/add_note', {
        note: {
          notable_type,
          attachment_url,
          note_type: 'permit',
          internal_view_only: true,
          notable_id: record.id,
          text: text.trim(),
        },
      })

      record.notes.push(data.note)
      toastr.success('Success', 'Permit Note successfully created')
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to create new permit note')
    }
  },
})

export const calculateExemptionToatal = arr => {
  if (!arr?.length) return 0
  return arr.reduce(
    (sum, item) =>
      sum +
      (Number(item.purchase_plus || '0') + Number(item.qty)) *
        Number(String(item.unit_price)?.replace('$', '') || 0),
    0
  )
}

export const ItemImport = (form, importProps) => {
  /* eslint-disable-next-line */
  const { metric_list, datalist, cb, hide_scientific } = importProps

  let columns = [
    {
      name: 'sort_id',
      label: 'ID',
      width: 32
    },
    {
      name: 'qty',
      label: 'Quantity of Goods',
      is: 'integer',
      width: 64,
    },
    {
      name: 'metric',
      label: 'Unit of Measure',
      options: metric_list,
      width: 128,
    },
    {
      name: 'item_common',
      label: 'Name',
      width: 128,
      datalist: datalist ? Object.keys(datalist) : [],
      callback: (v, s, index) => new Promise((resolve, reject) => {
        // if (!cb) return
        
        setTimeout(() => {
          const next = [...form.item_imports]
  
          if (datalist[v]) next[index].item_scientific = datalist[v];
          resolve({ item_imports: next })
        }, 100)
      }),
    },
    {
      name: 'item_scientific',
      label: 'Scientific Name',
      width: 128,
      required: false,
    },
    {
      name: 'unit_price',
      label: 'Unit Price',
      is: 'currency',
      width: 64,
    },
  ]

  const newCols = hide_scientific
    ? columns.filter(c => c.name != 'item_scientific')
    : columns

  return newCols
}

export const AnimalRequestDetails = [
  {
    name: 'quantity',
    is: 'integer',
    width: 16,
  },
  {
    name: 'animal_type',
    label: 'Type of Animal',
    width: 16,
  },
  {
    name: 'animal_breed',
    label: 'Breed',
    width: 16,
  },
  {
    name: 'animal_sex',
    label: 'Sex',
    options: ['M', 'F'],
    width: 8,
  },
  {
    name: 'age_years',
    label: 'Age (Years)',
    is: 'integer',
    width: 16,
  },
  {
    name: 'identification_number',
    is: 'integer',
    width: 32,
  },
  {
    name: 'markings',
    width: 32,
  },
]

export const PlantRequestDetails = [
  {
    name: 'quantity',
    width: 32,
  },
  {
    name: 'common_name',
    width: 64,
  },
  {
    name: 'scientific_name',
    width: 64,
  },
]

export const ConsignmentDetails = [
  {
    name: 'quantity',
    label: 'Quantity/Weight (kilograms)',
    is: 'integer',
  },
  {
    name: 'common_name',
    label: 'Species Common Name',
  },
  {
    name: 'scientific_name',
    label: 'Species Scientific Name',
  },
  {
    name: 'sex',
  },
  {
    name: 'age_years',
    label: 'Age (Years)',
  },
  {
    name: 'distinguishing_characteristics',
    label: 'Distinguishing Characteristics/Identification Number',
  },
  {
    name: 'wild',
    label: 'Wild Caught?',
    type: 'select:bool',
  },
  {
    name: 'bred',
    label: 'Bred in Captivity?',
    type: 'select:bool',
  },
]

export class ItemApprovalForm extends React.Component {
  constructor(props) {
    super(props)
    this.__labels = {}

    this.state = props.fields
      .filter(f => !!f.columns && props.record[f.name]?.length)
      .reduce(
        (obj, f) => ({
          ...obj,
          [f.name]: props.record[f.name].map(r => ({
            ...r,
            qty_req: r.qty_req || r?.qty,
            defaultItemSci: r.defaultItemSci || r?.item_scientific,
            defaultItemCom: r.defaultItemCom || r?.item_common,
            status: r.status || 'approved',
            purchase_plus: r.purchase_plus || 0,
          })),
        }),
        {}
      )

    this.state.total_import_value = this.computeTotal(this.state)
  }

  on = {
    change:
      (key, index) =>
      ({ target }) =>
        this.setState(state => {
          const val = target.value
          if (val && !/^[1-9][0-9,]{0,}$/.test(val)) return state

          state[key][index].qty = val ? Number(val) : ''
          state.total_import_value = this.computeTotal(state)
          return state
        }),

    purchase:
      (key, index) =>
      ({ target }) =>
        this.setState(state => {
          const val = target.value
          if (val && !/^[1-9][0-9,]{0,}$/.test(val)) return state

          state[key][index].purchase_plus = val ? Number(val) : ''
          state.total_import_value = this.computeTotal(state)
          return state
        }),

    input:
      (key, index, name) =>
      ({ target }) =>
        this.setState(state => {
          const val = target.value

          state[key][index][name] = val
          return state
        }),

    status: (table, index) => val => {
      return this.setState(state => {
        state[table][index].status = val[0]?.value
        state.total_import_value = this.computeTotal(state)
        if (val[0]?.value == 'denied') {
          state[table][index].qty = 0
        }
        if (val[0]?.value == 'approved') {
          state[table][index].qty = state[table][index].qty_req
          state.total_import_value = this.computeTotal(state)
        }
        return state
      })
    },
  }

  submit = async ev => {
    ev && ev.preventDefault()
    const { state, props } = this
    const { submit } = props

    // for (const [key, value] of Object.entries(state)) {
    //   if (!Array.isArray(value)) continue

    //   state[key] = value.reduce((arr, val) => {
    //     if (val.status === 'denied') {
    //       arr.push({ ...val, status: 'denied' })
    //       return arr
    //     }

    //     arr.push({ ...val, status: 'approved' })
    //     return arr
    //   }, [])
    // }

    submit(state)
  }

  computeTotal = state =>
    Object.entries(state).reduce((sum, [key, arr]) => {
      if (!Array.isArray(arr)) return sum
      return (
        sum + calculateExemptionToatal(arr.filter(r => r.status != 'denied'))
      )
    }, 0)

  formatDate = date =>
    typeof date == 'string'
      ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
      : date

  headings = (
    <thead>
      <tr>
        <th>Status</th>
        <th>Name</th>
        {!this.props.hide_scientific && <th>Scientific Name</th>}
        <th>Unit of Measure</th>
        <th>Unit Price</th>
        <th>Requested Qty</th>
        <th>Approved Qty</th>
        <th>Local Purchase Qty</th>
        {/* <th>Value</th> */}
      </tr>
    </thead>
  )

  colgroup = (
    <colgroup>
      <col span='1' width='160px'></col>
      <col span='1' width='160px'></col>
      {!this.props.hide_scientific && <col span='1' width='160px'></col>}
      <col span='1' width='160px'></col>
      <col span='1' width='128px'></col>
      <col span='1' width='64px'></col>
      <col span='1' width='64px'></col>
      <col span='1' width='64px'></col>
      {/* <col span='1' width='128px'></col> */}
    </colgroup>
  )

  render() {
    const { state, props, on, submit, headings, colgroup } = this
    const { close } = props

    const statuses = [
      { label: 'Approved', value: 'approved' },
      { label: 'Surplus', value: 'surplus' },
      { label: 'Denied', value: 'denied' },
      { label: 'Local Purchase', value: 'local purchase' },
    ]

    const tables = Object.entries(state).map(([key, data]) => {
      if (!Array.isArray(data)) return null

      const getRowClass = status => {
        switch (status) {
          case 'surplus':
            return 'table-success'
          case 'denied':
            return 'table-danger'
          case 'local purchase':
            return 'table-warning'
          default:
            return 'table-default'
        }
      }

      const rows = data.map((row, i) => {
        return (
          <tr key={i} className={getRowClass(row?.status)}>
            <td>
              <Fields.Select
                name='location'
                options={statuses}
                values={statuses.filter(status => status.value === row.status)}
                onChange={on.status(key, i)}
                className='form-control'
                // validators={['required']}
                // errorMessages={['Required']}
                // required
              />
            </td>
            <td>
              <Fields.Input
                icon={null}
                value={row?.item_common}
                className='form-control'
                onChange={on.input(key, i, 'item_common')}
                validators={['required']}
                errorMessages={['Required']}
              />
            </td>
            {!this.props.hide_scientific && (
              <td>
                <Fields.Input
                  icon={null}
                  value={row?.item_scientific}
                  className='form-control'
                  onChange={on.input(key, i, 'item_scientific')}
                  validators={['required']}
                  errorMessages={['Required']}
                />
              </td>
            )}
            <td>{row?.metric}</td>
            <td>{`$${Number(row.unit_price).toFixed(2)}`}</td>
            <td>{row?.qty_req}</td>
            <td>
              <Fields.Input
                icon={null}
                value={row.qty}
                className='form-control'
                onChange={on.change(key, i)}
                validators={['required']}
                errorMessages={['Required']}
                disabled={row?.status === 'surplus' ? false : true}
              />
            </td>
            <td>
              <Fields.Input
                icon={null}
                value={row.purchase_plus}
                className='form-control'
                onChange={on.purchase(key, i)}
                validators={['required']}
                errorMessages={['Required']}
                disabled={row?.status === 'local purchase' ? false : true}
              />
            </td>
          </tr>
        )
      })

      return (
        <div key={key} className='form-wizard-form-table table'>
          <h6 style={{ padding: '10px' }}>{props.tableName}</h6>
          <table className='table table-striped table-striped-bg-black'>
            {colgroup}
            {headings}
            <tbody>{rows}</tbody>
          </table>
        </div>
      )
    })

    return (
      <div style={{ maxWidth: '100%', width: '1280px' }}>
        <div className='modal-header'>
          <h5 className='modal-title' style={{ paddingLeft: '10px' }}>
            Approve Items
          </h5>
        </div>
        <ValidatorForm id='exemption-items-approvals' onSubmit={submit}>
          <div className='modal-body'>
            {tables}
            <br />
            <div className='clearfix'>
              <strong style={{ paddingLeft: '10px' }}>Total Value:</strong>
              <strong className='float-right'>
                ${Number(state.total_import_value || '0').toLocaleString()}
              </strong>
            </div>
          </div>
        </ValidatorForm>
        <div className='modal-footer'>
          <button className='btn btn-round mr-2' onClick={close}>
            Cancel
          </button>
          <input
            className='btn custom-btn btn-round'
            form='exemption-items-approvals'
            type='submit'
            value='Submit'
          />
        </div>
      </div>
    )
  }
}
