import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class YouthLeaderAlternate extends React.Component {
  resource = '/youth_leader_excellences'
  form_key = 'youth_leader_excellence'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Nominee",
    {
      name: 'nominee_contributions',
      label: "Select the area in which the nominee has made significant contributions",
      options: [
      'Youth in Agriculture',
      'Youth in Leadership',
      'Youth in Arts and Culture',
      'Youth in Education',
      'Youth in Entrepeneuership',
      'Youth in Governance',
      'Personal Triumph Award',
      'Youth Organization Awards',
      'Youth in Community Volunteerism',
      'Youth in Religion',
      'Youth in Sports',
      'Risin Star Award',
      'Youth Ally Award',
      'Youth in The Environment'
    ],
    },
    {
      name: 'relation',
      label: 'Relation to nominee',
      options: ['parent', 'guardian', 'spouse', 'other']
    },
    {
      name: 'nominee_first_name',
      label: `What is the nominee’s first name?`
    },
    {
      name: 'nominee_middle_name',
      label: `What is the nominee’s middle name?`,
      required: false
    },
    {
      name: 'nominee_last_name',
      label: `What is the nominee’s last name?`
    },
    {
      name: 'nominee_dob',
      label: `What is the nominee’s date of birth?`,
      type: 'date'
    },
    {
      name: 'nominee_address',
      label: 'Address'
    },
    {
      name: 'nominee_email',
      is: 'email'
    },
    {
      name: 'nominee_phone_numb',
      label: 'What is nominee’s phone contact? (Home or Cell)',
      type: 'phone'
    },
    {
      name: 'nominee_phone_alternate',
      label: 'What is nominee’s phone contact? (Alternate)',
      type: 'phone'
    },
    {
      name: 'nominee_activities_list',
      label: 'List all youth activities/organizations that the nominee has been involved in',
      columns: ['activity'],
      format: v => v.actvity
    },
    {
      name: 'special_awards_list',
      label: 'What are the special awards the nominee has received in the past?',
      columns: ['award'],
      format: v => v.award
    },
    {
      name: 'additional_comments',
      label: 'Is there anything interesting you wish to share about the nominee/organization?',
      type: 'textarea',
      required: 'false'
    },
    {
      name: 'nomination_reason',
      label: 'In no more than a paragraph, state why this nominee/organization should be selected',
      type: 'textarea'
    },

    {
      name: 'rising_star_award',
      label: 'If being nominated for a Rising Star Award, which category are you nominating for?',
      options: [
        'Community Volunteerism',
        'Leadership',
        'Arts',
        'Education', 
        'Sports', 
        'Culture',
        'Entrepeneurship'
      ]
    },
    {
      name: 'organization_affiliation'
    },
   
    "::Uploads",
    {
      name: 'citizenship_upload',
      label: 'Proof of citizenship',
      hint: 'Passport, birth certificate, etc.',
      type: 'file:all',
      key: 'image.0'
    },
    {
      name: 'photo_upload',
      label: 'Profesional photo of Nominee',
      key: 'image.1',
      hint: 'One that is appropriate to be displayed if selected as a winner',
      type: 'file:image'
    },
    {
      name: 'contribution_proof_upload',
      label: 'Proof of Contribution',
      key: 'image.2',
      type: 'file:all',
      required: false,
    },
    {
      name: 'endorsement_letter_upload',
      label: 'Letters of Endorsement',
      key: 'image.3',
      type: 'file:all'

    },
    {
      heading: 
      <p>
        Please note, the nominee's biography should be at least one page in length and will be used in the event that they are selected as the winner. As such, be sure to give us a detailed, complete and well written biography that reflects the contributions made for the nominated category.
      </p>
    },
    {
      name: 'biography_upload',
      label: "Nominee's Contribution Biography",
      key: 'image.4',
      type: 'file:all'
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
