import React from 'react'
import Swal from 'sweetalert2'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

import SERVICE_PROPS from '@helpers/extract-service-props'
import Wizard from '@modules/form-wizard'
import breeds from '@constants/dog-breeds'
import countries from '@constants/countries'
import islands from '@constants/islands'
import { extractKeys } from '@helpers/objects'

export default class DogLicensing extends React.Component {
  title = 'Annual Dog Registration & Licencing Renewal'
  resource = '/dog_licenses'
  form_key = 'dog_license'

  skip_location = true

  state = { dogs: [] }
  today = new Date()
  before_apply = false

  custom_actions = [
    {
      user: true,
      text: 'Accept Transfer',
      icon: 'check',
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Accept Transfer',
          text: `
            Are you sure that you want to accept the
            transfer of ${record.dog_name}
          `,
          showCancelButton: true,
          confirmButtonText: 'Accept Transfer',
        })

        if (isDismissed) return
        
        try {
          await $app.axios.put('/dog_licenses/accept_transfer', {
            dog_license: extractKeys(record, 'form_num')
          })

          Swal.fire(
            'Transfer Successful',
            '',
            'success'
          )
        } catch (err) {
          console.error(err)

          Swal.fire(
            'Error',
            'An error occurred while accepting the transfer',
            'error'
          )
        }
      }
    },
    {
      user: true,
      text: 'Decline Transfer',
      icon: 'times',
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Decline Transfer',
          text: `
            Are you sure that you want to decline the
            transfer of ${record.dog_name}
          `,
          showCancelButton: true,
          confirmButtonText: 'Decline Transfer',
        })

        if (isDismissed) return
        
        try {
          await $app.axios.put('/dog_licenses/decline_transfer', {
            dog_license: extractKeys(record, 'form_num')
          })

          Swal.fire(
            'Transfer Declined',
            '',
            'success'
          )
        } catch (err) {
          console.error(err)

          Swal.fire(
            'Error',
            'An error occurred while declining the transfer',
            'error'
          )
        }
      }
    },
  ]

  columns = () => [
    {
      name: 'Dog Name',
      selector: row => row.dog_name,
    },
    {
      name: 'Tag',
      selector: row => row.license_num || 'N/A'
    }
  ]

  hooks = {
    'read': async () => {
      const { data } = await $app.axios.get('/dog_licenses/show_transfer_requests')
      return data
    }
  }

  fields = (form, viewing) => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'license_numb',
      label: 'License Number',
      hide: !form.renewal,
      view: true,
    },
    {
      name: 'renewal_date',
      type: 'date',
      hide: !form.renewal,
      view: true,
    },
    {
      name: 'island',
      label: 'Island of Residence',
      options: [
        'New Providence'
      ]
    },
    {
      heading: '',
      hide: true,
    },
    {
      heading: viewing ? '<span>&nbsp;</span>' : `
        <strong>NOTE:</strong>
        <span>
          This form should be completed once
          the dog is 3 months of age or older.
        </span>
      `,
    },
    {
      name: 'dog_name',
      label: `Dog's Name`
    },
    {
      name: 'dog_sex',
      label: 'Sex',
      options: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
      ],
    },
    {
      name: 'dog_age',
      label: `Dog's Age (In Years)`,
      is: 'integer',
    },
    {
      name: 'dog_date_of_birth',
      label: 'Birth Date',
      type: 'date',
      showYearDropdown: true,
      required: false,
    },
    {
      name: 'mixed_breed',
      type: 'select:bool',
      label: 'Is your dog a mixed breed?',
      view: false
    },
    {
      name: 'predominant_breed',
      hide: !form.mixed_breed,
      options: breeds,
    },
    {
      name: 'breed',
      hide: true,
      save: true,
      view: {
        label: form.mixed_breed ? 'Additional Breeds' : 'Breed',
        value: v => Array.isArray(v) ? v.join(', ') : v
      }
    },
    {
      name: 'breed_single',
      label: 'Breed of Dog',
      save: false,
      options: breeds,
      hide: form.mixed_breed,
      view: false,
    },
    {
      name: 'breed_multi',
      label: 'Other Breed(s)',
      options: breeds,
      hide: !form.mixed_breed,
      required: false,
      multi: true,
      view: false,
      save: false,
    },
    {
      name: 'microchip_numb_or_brand',
      label: 'Microchip #',
      hint: 'Leave blank if dog is not chipped',
      required: false,
    },
    {
      name: 'neutered',
      label: 'Is this dog neutered/spayed?',
      type: 'select:bool',
      view: {
        label: 'Neutered?'
      }
    },
    {
      name: 'dog_color',
      label: 'Color',
      multi: true,
      hint: 'Select the colors which apply',
      options: [
        'Tan',
        'Black',
        'Blonde',
        'Brindle',
        'Brown',
        'Fawn',
        'Golden',
        'Silver',
        'Grey',
        'Tri-Color',
        'White',
        'Red',
        'Spotted',
        'Blue Merle',
        'Other',
      ],
    },
    {
      name: 'dog_color_other',
      save: false,
      label: 'Please specify',
      hide: !form.dog_color?.includes?.('Other')
    },
    {
      name: 'dog_place_of_birth',
      label: 'Country of Birth',
      options: countries
    },
    {
      name: 'dog_island_birth',
      label: 'Island of Birth',
      hide: form.dog_place_of_birth != 'Bahamas',
      options: islands
    },
    {
      name: 'countries_visited_last_6_months',
      label: 'What countries has this dog visited in the last 6 months?',
      multi: true,
      options: countries,
      required: false,
      view: {
        label: 'Countries visited in last 6 months'
      }
    },
    {
      name: 'dog_desc_of_use',
      label: `Dog's Purpose`,
      multi: true,
      options: [
        'Pet/Companion',
        'Breeding Animal',
        'National Security Canine',
        'Private Security Canine',
        'Show Dog',
        'Service Dog',
        'Other',
      ]
    },
    {
      name: 'dog_desc_of_use_other',
      save: false,
      label: 'Please specify',
      hide: !form.dog_desc_of_use?.has?.('Other'),
      view: false,
    },
    {
      name: 'rabies_expiration_date',
      label: 'Rabies Expiration Date',
      type: 'date',
      showYearDropdown: true,
      minDate: this.today,
      required: false,
    },
    {
      name: 'dhlpp_expiration_date',
      label: 'DHLPP Expiration Date',
      type: 'date',
      showYearDropdown: true,
      minDate: this.today,
      required: false,
    },
    {
      key: 'image.1',
      name: 'vaccination_upload',
      label: 'Vaccination Card',
      type: 'file:image',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
