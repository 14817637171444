import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import islands from '@constants/islands'

export default class AdoptionCertificate extends React.Component {
  resource = '/adoptions'
  form_key = 'adoption'

  fee = 10

  fields = () => [
    "::Adoptee's Information",
    {
      name: 'adoptee_first_name',
      label: 'First Name',
    },
    {
      name: 'adoptee_last_name',
      label: 'Last Name',
    },
    {
      name: 'born_at',
      label: 'D.O.B',
      type: 'date',
      showYearDropdown: true,
      showMonthDropdown: true,
      maxDate: new Date(),
      view: {
        value: val => moment(val).format('Do MMM, YYYY')
      }
    },
    {
      name: 'born_place',
      label: 'Place Of Birth',
      options: countries.map(c => c.name).sort(),
    },
    "::Adopters' Information",
    {
      name: 'adopted_island',
      label: 'Island',
      options: islands,
    },
    {
      name: 'adopted_fathers_first_name',
      label: "Father's First Name",
    },
    {
      name: 'adopted_fathers_last_name',
      label: "Father's Last Name",
    },
    {
      name: 'adopted_mothers_first_name',
      label: "Mother's First Name",
    },
    {
      name: 'adopted_mothers_last_name',
      label: "Mother's Last Name",
    },
    {
      label: 'I desire to have a search made for a copy of the Registration of Adoption.',
      name: 'search',
      type: 'checkbox',
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

