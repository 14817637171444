import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import occupations from "@constants/occupations";

export default class CottageAndLight extends React.Component {
  id = "CottageAndLightExemption";
  service = "cottage and light exemption";
  title = "Cottage and Light Exemption";
  agency = "mofrd";
  item = "";
  resource = "/cottage_and_light_exemptions";
  form_key = "cottage_and_light_exemption";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "client_type",
      label: "Name of Applicant",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "directors",
      label:
        "Please give the name and nationality of each beneficial shareholder or principal",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
      type: "textarea",
      list: true,
      placeholder: "Separate names using commas (e.g., John, David, Mark, etc)",
    },
    {
      name: "business_type",
      label: "Nature of Business",
      options: occupations,
    },
    {
      name: "products_to_be_manufactured",
      label: "Names of products to be manufactured",
    },
    {
      name: "production_method",
      label: "Method of Production",
    },
    {
      name: "bl_number",
      label: "Business License No.",
      is: "integer",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "tin_number",
      label: "Tax Identification No.",
      is: "integer",
    },
    {
      name: "tax_comp_cert_issue",
      label: "Tax Compliance Certificate Issue Date",
      type: "date",
      maxDate: new Date(),
      showYearDropdown: true,
    },
    {
      name: "tax_comp_cert_expiry",
      label: "Tax Compliance Certificate Expiry Date",
      type: "date",
      showYearDropdown: true,
    },
    {
      name: "address",
      label: "Street Address of Business",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "po_box",
    },
    {
      name: "phone_number",
      label: "Telephone Number",
      type: "phone",
    },
    {
      name: "years_of_business",
      label: "Years of Business Existence",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
      is: "integer",
    },
    {
      name: "number_of_employees",
      label: "Number of Employees",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
      is: "integer",
    },
    {
      name: "sales_amount",
      label: "State amount of Sales or Turnover during the past twelve months",
      is: "currency",
    },
    {
      name: "materials_upload",
      type: "file:image",
      label: "Application for Raw Materials/Supplies to be imported duty-free",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
