import axios from 'axios'

import * as TYPES from '@action-types'
import toastr from '@modules/toastr'
import ENV from '@constants/env'

const headers = () => {
  const user = JSON.parse(sessionStorage.getItem('user') || '{}')

  return {
    'Content-Type': 'application/json',
    'Authorization': user && user.token
  }
}

const getRequest = {
  type: TYPES.GET_REQUEST_SUCCESS,
  payload: {}
}

const Resource = async (dispatch, path, body, _method) => {
  dispatch(getRequest)

  const method  = _method || (body ? 'post' : 'get')
  const url     = /^http/.test(path) ? path : `${ENV.WEB_SERVICE_URL}/${ENV.API_V}/${path}`

  try {
    const { data } = await (method == 'get'
      ? axios.get(url, { params: body, headers: headers() })
      : axios[method.toLowerCase()](url, body, { headers: headers() })
    )

    return data
  } catch (err) {
    dispatch(createError(err))
    return null
  }
}

export const getUserTransactions = () => async dispatch => {
  const response = await Resource(dispatch, 'dashboard/show_transaction_history')

  response && dispatch({
    type: TYPES.GET_USER_TRANSACTIONS,
    payload: { response }
  })
}

export const getTotalSpend = () => async dispatch => {
  const response = await Resource(dispatch, 'total_spend')

  response && dispatch({
    type:  TYPES.GET_TOTAL_SPEND,
    payload: { response }
  })
}

export const getTopRequest = () => async dispatch => {
  const response = await Resource(dispatch, 'top_recent_request')

  response && dispatch({
    type:  TYPES.GET_TOP_REQUEST,
    payload: { response }
  })
}

export const recentHistory = () => async dispatch => {
  const response = await Resource(dispatch, 'recent_history')

  response && dispatch({
    type:  TYPES.RECENT_HISTORY,
    payload: { response }
  })
}

export const getCounts = id => async dispatch => {
  const response = await Resource(dispatch, 'get_counts/')

  response && dispatch({
    type:  TYPES.GET_COUNTS,
    payload: { response }
  })
}

function createError (error) {
  console.error(error)
  toastr.error('Error', error && error.response.data.error)

  if (error.response.status == 401) {
    sessionStorage.clear()
    window.location.href = "/login"
  }

  return function (dispatch) {
    setTimeout(function () {
      dispatch({ type: TYPES.CLEAR_ERROR })
    }, 2000)

    dispatch({
      type: TYPES.GET_RESPONSE_ERROR,
      payload: error
    })
  }
}
