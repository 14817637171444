import React, { useEffect, useState } from "react";
import moment from "moment";
import { serviceAlias } from "@constants/service-record-map";
import Select from "react-dropdown-select";

const { Circles } = window;

const AppointmentDashboard = ({ location, history }) => {
  const [month, setMonth] = useState("");

  useEffect(() => {
    const circleParams = {
      radius: 45,
      width: 7,
      duration: 400,
      wrpClass: "circles-wrp",
      textClass: "circles-text",
      styleWrapper: true,
      styleText: true,
    };

    const getAppointmentsCount = (status) => {
      const { state } = location;
      return state
        .filter((a) =>
          month[0]
            ? moment(a.slot).format("MMMM") == month[0].value
            : moment(a.slot).format("MMMM") == moment().format("MMMM")
        )
        .filter((a) => a.active)
        .filter((a) => a.status == status).length;
    };

    Circles.create({
      ...circleParams,

      id: "circles-arrived",
      value: getAppointmentsCount("arrived"),
      maxValue: 100,
      text: getAppointmentsCount("arrived") || "0",
      colors: ["#f1f1f1", "#218839"],
    });

    Circles.create({
      ...circleParams,

      id: "circles-no-show",
      value: getAppointmentsCount("no show"),
      maxValue: 100,
      text: getAppointmentsCount("no show") || "0",
      colors: ["#f1f1f1", "#C82433"],
    });
  }, [location, month]);

  const sortDate = (a, b) => {
    return new Date(b.slot) - new Date(a.slot);
  };
  const statusClass = {
    submitted: "primary",
    denied: "danger",
    approved: "success",
    arrived: "success",
    "no show": "danger",
  };

  const cancellations = location.state
    .filter((a) => !a.active)
    .filter((a) =>
      month[0]
        ? moment(a.slot).format("MMMM") == month[0].value
        : moment(a.slot).format("MMMM") == moment().format("MMMM")
    ).length;
  const months = moment.months().map((month) => {
    return { label: month, value: month };
  });

  return (
    <>
      <div className="container">
        <div className="panel-header bg-primary-gradient">
          <div className="page-inner py-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="text-white pb-2 fw-bold">
                  Admin Panel - Appointments
                </h2>
              </div>
              <div className="ml-auto">
                <Select
                  options={months}
                  values={[]}
                  onChange={(value) => {
                    setMonth(value);
                  }}
                  style={{ backgroundColor: "#FFFFFF" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row mt--2">
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title">
                    Appointments -{" "}
                    {month[0] ? month[0].value : moment().format("MMMM")}
                  </div>
                  <div className="card-category">
                    Appointments for the current month, broken down by status
                  </div>
                  <div className="d-flex flex-wrap justify-content-around pb-2 pt-4">
                    <div className="px-2 pb-2 pb-md-0 text-center">
                      <div id="circles-no-show"></div>
                      <h6 className="fw-bold mt-3 mb-0">No Show</h6>
                    </div>
                    <div className="px-2 pb-2 pb-md-0 text-center">
                      <div id="circles-arrived"></div>
                      <h6 className="fw-bold mt-3 mb-0">Arrived</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">Most Recent Appointments</div>
                    <div className="card-tools"></div>
                  </div>
                </div>
                <div className="card-body">
                  {location.state
                    .sort(sortDate)
                    .filter((a) =>
                      a.status.toLowerCase() != "denied" ? a.active : a
                    )
                    .slice(0, 8)
                    .map((a, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="d-flex">
                            <div className="flex-1 ml-3 pt-1">
                              <h6 className="text-uppercase fw-bold mb-1">
                                {`${a.user_first_name} ${a.user_last_name}`}
                                <span
                                  className={`text-${
                                    statusClass[a.status]
                                  } pl-3`}
                                >
                                  {a.status}
                                </span>
                              </h6>
                              <span className="text-muted text-uppercase">
                                {serviceAlias[a.schedulable_type]}
                              </span>
                            </div>
                            <div className="float-right pt-1">
                              <small className="text-muted">
                                {moment(a.slot).format("DD-MM-YYYY h:mm")}
                              </small>
                            </div>
                          </div>
                          <div className="separator-dashed"></div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="card card-stats card-round">
                <div className="card-body">
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center">
                        <i className="fas fa-times-circle text-danger"></i>
                      </div>
                    </div>
                    <div className="col-7 col-stats">
                      <div className="number">
                        <p className="card-category">
                          Cancellations -{" "}
                          {month[0] ? month[0].value : moment().format("MMMM")}
                        </p>
                        <h4 className="card-title">{cancellations}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn btn-next custom-btn ml-auto"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default AppointmentDashboard;
