import React from 'react'
import Modal from 'react-responsive-modal'
import { ValidatorForm } from 'react-form-validator-core'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import InputField from '@shared/form-fields/input'
import DateField from '@shared/form-fields/date'
import TextField from '@shared/form-fields/textarea'
import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import ENV from '@constants/env'
import { fullName } from '@modules/form-wizard/utils'

export default class FirearmLicense extends React.Component {
  state = {
    profile: '',
    payment_amount: '',
    inspected_by: '',
    inspection_date: '',
    inspection_note: '',
    inspector_note: '',
    loading: false,
    record: null,
    notesRecord: null,
    role: 'Sargent',
  }

  async componentDidMount() {
    try {
      const { data: profile } = await $app.axios.get('/profiles/')
      this.setState({ profile })
    } catch (error) {
      console.error(error)
    }
  }

  resource = '/gun_licenses'
  form_key = 'gun_license'
  postpaid = true
  custom_postpaid_message =
    'Thank you for using MyGateway. Your request for a new Firearm Licence has been successfully submitted and will be reviewed. The review period is expected to take approximately six to seven weeks. You may be contacted for an interview and an inspection. An email confirming the licence approval or denial will be sent to your inbox. If approved, you will receive a notification to proceed with the payment for your new Firearm Licence.'

  disable_proxy = true
  show_spouse = true

  hide_buttons = {
    fee: true,
    status: true,
  }

  custom_actions = [
    // {
    //   text: "Additional Notes",
    //   icon: "clipboard-check",
    //   test: (r) =>
    //     !r.payment_status &&
    //     r.application_decision !== "processing" &&
    //     this.state.profile?.roles?.includes("police_inspector") &&
    //     r?.notes.length > 0,
    //   fn: (record) =>
    //     this.setState({
    //       record,
    //     }),
    // },
    // {
    //   text: "Inspection Notes",
    //   icon: "clipboard",
    //   test: (r) => r.application_decision !== "approved",
    //   fn: (record) =>
    //     this.setState({
    //       notesRecord: record,
    //     }),
    // },
    {
      text: 'Approve For Payment',
      icon: 'balance-scale',
      test: r => !r.payment_status && !r.payment_amount,
      roles: ['police_commissioner'],
      fn: async record => {
        const { isConfirmed, isDenied } = await Swal.fire({
          icon: 'question',
          title: 'Approve For Payment',
          html: `
            <p class='text-gray-800'>
              By clicking 'Approve', you are confirming that the Firearms
              Licence application for ${fullName(record.user, false)}
              can be approved for payment. If you click deny, then the
              application will be terminated.
            </p>
          `,
          showDenyButton: true,
          denyButtonText: 'Deny',
          confirmButtonText: 'Approve',
        })

        if (isConfirmed) this.approveForPayment(record)
        if (isDenied) this.denyPayment(record)
      },
    },
    {
      text: 'Mark as Completed',
      icon: 'check-circle',
      test: r => r.payment_status,
      fn: async record => {
        const { isConfirmed } = await Swal.fire({
          icon: 'warning',
          title: 'Mark as Completed',
          html: `
            <p class='text-gray-800'>
            Are you sure you want to mark this application as completed?
            Ensure that all relevant processes are completed before clicking
            'Yes'.
            </p>
          `,
          showCancelButton: true,
          confirmButtonColor: '#00AEEF',
        })

        if (isConfirmed) this.completeApplication(record)
      },
    },
  ]

  buildFee = ({ license_type }) => {
    switch (license_type) {
      case 'Rifle':
        return 300
      default:
        return 150
    }
  }

  updateView = update => {
    const { history, location } = this.props
    const inview = location.state?.view

    inview &&
      history.replace(location.pathname, {
        ...location.state,
        view: { ...inview, ...update },
      })
  }

  closeModal = name => this.setState({ [name]: null })

  completeApplication = async record => {
    const { resource, form_key } = this

    try {
      this.setState({ loading: true })

      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      toastr.success('Success', 'Application has been marked as completed')

      await $app.axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateqway Portal Update - New Firearms Licence Application',
        message: `
          Good day ${record.user.first_name.capitalize()},
          <br/>
          
          Thank you for using MyGateway to apply for your new Firearm Licence. Please provide us with your feedback on your experience using MyGateway. 
          https://www.surveymonkey.com/r/Preview/?sm=sEmnqd14nxLzKGxhyYuuDJAZUeco2ApGWHVju7BgliT1T5W3BqSbPtivQ8gMTf2a
          <br/><br />
          Thank you for using MyGateway Portal.
        `,
      })

      toastr.success('Success', 'Email has been sent to user')
    } catch (err) {
      toastr.error('Error', 'Unable to mark application as completed')
      console.error(err)
    } finally {
      this.setState({ loading: false })
    }
  }

  denyPayment = async record => {
    const { resource, form_key, props } = this
    const { history, location } = props

    const message = `
      Good day ${record.user.first_name.capitalize()},
      <br />
      Thank you for using MyGateway. Your request for a new Firearm Licence has been denied. Please contact The Criminal Records Office at (242) 356-2641-2 or 3 between the hours of 9am-3pm for further details.
      <br /><br />
      Thank you for using the MyGateway Portal.
    `

    try {
      this.setState({ loading: true })
      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application has been denied')

      try {
        const { data } = await $app.axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal: Denial for Firearms Licence',
          message,
        })

        data.message && !data.error
          ? toastr.success('Success', 'Email sent to citizen')
          : toastr.error('Error', data.message || data.error)
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email to citizen')
      }
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to process application.')
    } finally {
      this.setState({ loading: false })
    }
  }

  approveForPayment = async record => {
    const { resource, form_key } = this

    const message = payment => `
        Good day ${record.user.first_name.capitalize()},
        <br />
        We are pleased to inform you that your Firearms Licence Application has been inspected, reviewed and approved for payment.
        <br /><br />
        Your service fee is as follows:
        <br /><br />
        Service Fee : <b>$${payment}</b><br />
        <br/><br />
        <a href='${ENV.PILOT_URL}'>Click here</a>
        to login and complete payment on the Portal.
        <br/>
        Upon payment, please bring in your Firearms Licence Booklet to the
        Firearms Licencing Office, University Drive or Police Headquarters
        Sir Gerald Bartlett Building (Freeport, Grand Bahama) to have
        the same updated.
        <br/><br />
        Thank you for using the MyGateway Portal.
      `

    try {
      this.setState({ loading: true })

      const fee = this.buildFee(record)

      const { data } = await $app.axios.put(
        resource + '/update_payment_amount',
        {
          [form_key]: {
            form_num: record.form_num,
            fee,
          },
        }
      )

      const { payment_amount } = data.gun_license

      payment_amount
        ? toastr.success(
            'Success',
            'Application has been approved for Payment. Final Approval will take place once customer has paid.'
          )
        : toastr.error('Error', 'Unable to approve application for payment')

      this.updateView({ payment_amount })

      try {
        const { data } = await $app.axios.post('/emails', {
          email: record.user.email,
          subject: 'MyGateway Portal: Payment Approval for Firearms Licence',
          message: message(Number(payment_amount).toFixed(2)),
        })

        data.message && !data.error
          ? toastr.success('Success', 'Email sent to citizen')
          : toastr.error('Error', data.message || data.error)
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email to citizen')
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to set Approve for Payment')
    } finally {
      this.setState({ loading: false })
    }
  }

  on = {
    input: name => ev => this.setState({ [name]: ev.target.value }),

    select: v => this.setState({ role: v.length && v[0].value }),

    date: val => this.setState({ inspection_date: val }),

    role: ev => {
      ev.preventDefault()
      toastr.success('Success', `Role has been changed to ${this.state.role}`)
      this.setState({ record: null })
    },

    fee: ev => {
      const val = ev.target.value
      if (val && !/^[0-9]+(\.[0-9]{0,2})?$/.test(val)) return
      this.setState({ payment_amount: val })
    },

    submitSargeant: async ev => {
      ev.preventDefault()
      this.setState({ loading: true })
      const { axios } = $app
      const {
        notesRecord,
        // inspected_by,
        // inspection_date,
        // profile,
        inspection_note,
      } = this.state

      // const notes = `Inspected By: ${
      //   `${profile?.first_name} ${profile?.last_name}`.capitalize() ||
      //   inspected_by
      // }.       Inspection Date: ${moment(inspection_date).format(
      //   "MMMM Do, YYYY"
      // )}.      Inspection Notes: ${inspection_note}.`;

      try {
        await axios.post('/services/notes/add_note', {
          note: {
            internal_view_only: true,
            notable_type: 'GunLicense',
            notable_id: notesRecord?.id,
            text: inspection_note,
          },
        })

        toastr.success(
          'Success',
          'Application Notes have been inserted by Sargeant. Now awaiting Inspector Approval along with additional notes'
        )
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to insert application notes at this time')
      }

      this.setState({ notesRecord: null, loading: false })
    },

    submitInspector: async ev => {
      ev.preventDefault()
      this.setState({ loading: true })
      const { state, resource, form_key, props } = this
      const { history, location } = props
      const { record, inspector_note } = state
      const { axios } = $app
      try {
        await axios.post('/services/notes/add_note', {
          note: {
            internal_view_only: true,
            notable_type: 'GunLicense',
            notable_id: record?.id,
            text: inspector_note,
          },
        })

        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'processing',
          },
        })

        if (location.state?.view) {
          const view = { ...location.state.view }
          view.application_decision = 'processing'

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success(
          'Success',
          'Application notes have been updated and submitted by Inpsector. Now awaiting Police Commissioner Approval'
        )
      } catch (error) {
        console.error(error)
        toastr.error(
          'Error',
          'Unable to process application for final approval.'
        )
        return
      }

      this.setState({ loading: false, record: null })
    },
  }

  fields = form => [
    {
      name: 'license_purpose',
      label: 'Personal or Business',
      options: ['Personal', 'Business'],
    },
    '::Firearm Details',
    {
      name: 'license_type',
      label: 'Is this a shotgun or rifle?',
      options: ['Shotgun', 'Rifle'],
    },
    {
      name: 'firearm_make',
      label: 'Make of Firearm',
      options: [
        'Smith and Wesson',
        'Beretta',
        'Taurus',
        'Wilson Combat',
        'FN',
        'BushMaster',
        'Chiappa',
        'Colt',
        'Other',
      ],
      callback: v => {
        if (v != 'Other') {
          return { other_firearm_make: '' }
        }
      },
    },
    {
      name: 'other_firearm_make',
      hide:
        form.firearm_make != 'Other' &&
        form.firearm_make !== form.other_firearm_make,
    },
    {
      name: 'firearm_model',
      label: 'Model',
      options: [
        '110 Hog Hunter',
        'Rascal FV-SR',
        'B22 F Compact',
        'B17 Left Hand',
        'Other',
      ],
      callback: v => {
        if (v != 'Other') {
          return { other_firearm_model: '' }
        }
      },
    },
    {
      name: 'other_firearm_model',
      hide:
        form.firearm_model != 'Other' &&
        form.firearm_model !== form.other_firearm_model,
    },
    {
      name: 'serial_check',
      label: 'Do you have a serial number?',
      type: 'select:bool',
      hint: 'Located on your receipt or on the gun',
    },
    {
      name: 'firearm_serial_number',
      label: 'Serial Number',
      hide: !form.serial_check,
      hint: 'The serial number is located on your receipt or on the gun',
      is: 'integer',
    },
    {
      name: 'firearm_gauge',
      label: 'Gauge',
      options: ['12', '16', '20', '28', '.410'],
      hide: form.license_type !== 'Shotgun',
      hint: 'i.e 12 for 12 gauge',
    },
    {
      name: 'firearm_barrel_length',
      label: 'Barrel Length',
      hide: form.license_type !== 'Shotgun',
      hint: 'In Inches',
      is: 'integer',
    },
    {
      name: 'firearm_caliber',
      label: 'Caliber Size',
      options: ['.22', '.20', '.24'],
      hide: form.license_type !== 'Rifle',
    },
    {
      name: 'firearm_desc',
      label: 'Description',
      hint:
        form.license_type == 'Shotgun'
          ? 'e.g., pump action'
          : form.license_type == 'Rifle'
          ? 'e.g., semi-automatic'
          : '',
    },
    {
      name: 'firearm_obtained_from',
      label: 'Obtained/Purchased From',
      options: ['Outdoor Sportsman', 'NBC Outdoor World', 'Other'],
      callback: v => {
        if (v != 'Other') {
          return { other_firearm_obtained_from: '' }
        }
      },
    },
    {
      name: 'other_firearm_obtained_from',
      label: 'Other Location',
      hide:
        form.firearm_obtained_from != 'Other' &&
        form.firearm_obtained_from !== form.other_firearm_obtained_from,
    },
    {
      name: 'firearm_date_purchased',
      label: 'Date Obtained/Purchased',
      type: 'date',
      showYearDropdown: true,
      showMonthDropdown: true,
      maxDate: new Date(),
    },
    '::Employer Details',
    //Fill out with profile info
    // {
    //   name: "occupation",
    //   options: occupations,
    //   profile: true,
    // },
    // {
    //   name: "self_employed",
    //   label: "Are you self employed?",
    //   type: "select:bool",
    // },
    {
      name: 'employment_status',
      label: 'Employment Status',
      options: ['Employed', 'Self Employed', 'Unemployed'],
      view: false,
    },
    {
      name: 'employer',
      label: 'Organization Name',
      hide: form.employment_status === 'Unemployed',
    },
    {
      name: 'employer_address',
      label: 'Organization Address',
      hide: form.employment_status === 'Unemployed',
      view: false,
    },
    {
      name: 'employer_number',
      label: 'Organization Number',
      hide: form.employment_status === 'Unemployed',
      type: 'phone',
    },
    '::Previous Applications',
    {
      name: 'applied_for_gun_license_before',
      label: 'First Time Applying?',
      type: 'select:bool',
    },
    {
      name: 'previous_application_date',
      label: 'Last Application Date',
      type: 'date',
      showYearDropdown: true,
      showMonthDropdown: true,
      maxDate: new Date(),
      hide: form.applied_for_gun_license_before !== false,
      required: false,
    },
    {
      name: 'firearm_license_holder',
      type: 'select:bool',
      label: 'Current/Past Licences?',
      hide: form.applied_for_gun_license_before !== false,
    },
    {
      name: 'number_of_firearms_owned',
      hide: !form.held_licenses,
      label: 'How Many Firearms Do You Own?',
      is: 'integer',
    },
    {
      name: 'firearm_request_reason',
      lable: 'Reason For Request',
      type: 'textarea',
      hide: form.applied_for_gun_license_before !== false,
    },
    '::Criminal Record',
    {
      name: 'previous_criminal_offence',
      label: 'Previously Arrested?',
      type: 'select:bool',
      callback: v => {
        const other = {}

        if (form.other_firearm_make) {
          other.firearm_make = form.other_firearm_make
        }
        if (form.other_firearm_model) {
          other.firearm_model = form.other_firearm_model
        }
        if (form.other_firearm_obtained_from) {
          other.firearm_obtained_from = form.other_firearm_obtained_from
        }

        return other
      },
    },
    {
      name: 'criminal_offence_desc',
      label: 'Details of Arrest',
      type: 'textarea',
      hide: !form.previous_criminal_offence,
    },
    '::SPACER',
    {
      name: 'is_married',
      type: 'select:bool',
      label: 'Are you married?',
      view: false,
      hide: this.state.profile.spouse,
    },
    {
      name: 'has_kin',
      type: 'select:bool',
      label: 'Do you have a next of kin?',
      hide: form.is_married || form.is_married === undefined,
      hint: 'Next of kin is defined as a relative or person whom you have the closest relationship with',
      view: false,
    },
    { heading: 'Spousal Information', hide: !form.is_married },
    {
      name: 'spouse_first_name',
      label: 'First Name',
      regex: /^[a-z'-]{0,}$/i,
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_middle_name',
      label: 'Middle Name',
      required: false,
      regex: /^[a-z'-]{0,}$/i,
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_last_name',
      label: 'Last Name',
      regex: /^[a-z'-]{0,}$/i,
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_maiden_name',
      label: 'Maiden Name',
      required: false,
      regex: /^[a-z'-]{0,}$/i,
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'same_address',
      label: 'My Spouse and I share the same address',
      type: 'checkbox',
      required: false,
      sub_obj: 'spouse',
      hide: !form.is_married,
      view: false,
      callback: v => {
        const { profile } = this.state
        return v
          ? {
              spouse_address: profile.addresses[0]?.street || '',
              spouse_po_box: profile.addresses[0]?.p_o_box || '',
              spouse_home_number: profile.addresses[0?.phone_number] || '',
            }
          : { spouse_address: '', spouse_pobox: '', spouse_home_number: '' }
      },
    },
    {
      name: 'spouse_address',
      label: 'Address',
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_po_box',
      label: 'P. O. Box',
      is: 'integer',
      required: false,
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_NIB',
      label: 'NIB #',
      is: 'integer,',
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_home_number',
      label: 'Home Phone',
      type: 'phone',
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_work_number',
      label: 'Cell Number',
      type: 'phone',
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    {
      name: 'spouse_date_of_birth',
      label: 'D.O.B.',
      type: 'date',
      showYearDropdown: true,
      showMonthDropdown: true,
      maxDate: new Date(),
      sub_obj: 'spouse',
      hide: !form.is_married,
      save: form.is_married,
    },
    // {
    //   name: "spouse_self_employed",
    //   label: "Is your spouse self employed?",
    //   type: "select:bool",
    //   sub_obj: "spouse",
    //   hide: !form.is_married,
    // },
    {
      name: 'spouse_employment_status',
      label: 'Employment Status',
      options: ['Employed', 'Self Employed', 'Unemployed'],
      hide: !form.is_married,
    },
    {
      name: 'spouse_employer',
      label: 'Organization Name',
      required: false,
      sub_obj: 'spouse',
      hide: !form.is_married || form.spouse_employment_status === 'Unemployed',
      save: form.is_married,
    },
    {
      name: 'spouse_employer_address',
      label: 'Organization Address',
      required: false,
      sub_obj: 'spouse',
      hide: !form.is_married || form.spouse_employment_status === 'Unemployed',
      save: form.is_married,
    },
    {
      name: 'spouse_employer_number',
      label: 'Organization Number',
      type: 'phone',
      required: false,
      sub_obj: 'spouse',
      hide: !form.is_married || form.spouse_employment_status === 'Unemployed',
      save: form.is_married,
    },
    { heading: 'Next of Kin Details', hide: !form.has_kin },
    {
      name: 'next_kin_first_name',
      label: 'First Name',
      hint: 'The person(s) identified for next of kin must be over the age of 18.',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_middle_name',
      label: 'Middle Name',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_last_name',
      label: 'Last Name',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_maiden_name',
      label: 'Maiden Name',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
      required: false,
    },
    {
      name: 'kin_relationship',
      label: 'Relationship',
      options: [
        'Mother',
        'Father',
        'Brother',
        'Sister',
        'Spouse',
        'Daughter',
        'Son',
        'Cousin',
        'Aunt',
        'Uncle',
      ],
      hide: !form.has_kin,
    },

    {
      name: 'next_kin_address',
      label: 'Address',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_po_box',
      label: 'P. O. Box',
      required: false,
      is: 'integer',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
    },
    {
      name: 'next_kin_NIB',
      label: 'NIB #',
      is: 'integer',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_home_number',
      label: 'Home Phone',
      type: 'phone',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_work_number',
      label: 'Work Phone',
      type: 'phone',
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_date_of_birth',
      label: 'D.O.B.',
      type: 'date',
      showYearDropdown: true,
      showMonthDropdown: true,
      maxDate: new Date(),
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    // {
    //   name: "kin_occupation",
    //   label: "Occupation",
    //   options: occupations,
    //   required: false,
    //   hide: !form.has_kin,
    //   sub_obj: "next_kin",
    // },
    {
      name: 'next_kin_employer',
      label: 'Employer',
      required: false,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_employer_address',
      label: 'Employer Address',
      required: false,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_employer_number',
      label: 'Employer Phone',
      type: 'phone',
      required: false,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_fathers_first_name',
      label: "Father's First Name",
      required: false,
      regex: /^[a-z'-]{0,} ?[a-z'-]{0,}$/i,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_fathers_last_name',
      label: "Father's Last Name",
      required: false,
      regex: /^[a-z'-]{0,} ?[a-z'-]{0,}$/i,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_mothers_first_name',
      label: "Mother's First Name",
      required: false,
      regex: /^[a-z'-]{0,} ?[a-z'-]{0,}$/i,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_mothers_last_name',
      label: "Mother's Last Name",
      required: false,
      regex: /^[a-z'-]{0,} ?[a-z'-]{0,}$/i,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    {
      name: 'next_kin_mothers_maiden_name',
      label: "Mother's Maiden Name",
      required: false,
      regex: /^[a-z'-]{0,} ?[a-z'-]{0,}$/i,
      hide: !form.has_kin,
      sub_obj: 'next_kin',
      save: form.has_kin,
    },
    // {
    //   name: "image_one",
    //   label: "Upload a passport sized photo",
    //   type: "file:image",
    // },
    // "::Add Reference",
    // {
    //   name: "references",
    //   label: "References",
    //   columns: [{ name: "Name", label: "Names" }],
    // },
  ]

  InspectionNote = ({ note, key, i }) => {
    const getTitle = index => {
      switch (index) {
        case 0:
          return 'Sargeant Notes'
        case 1:
          return 'Inspector Notes'
        default:
          return ''
      }
    }

    return (
      <div className='col' key={key}>
        <div className='modal-body'>
          <div className='card'>
            <div className='card-body pt-2'>
              <h5 className='card-title text-capitalize fw-bold'>
                {getTitle(i)}
              </h5>
              <p className='card-text'>{note}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { state, on, closeModal, InspectionNote } = this
    const { inspected_by, inspection_date, inspection_note, inspector_note } =
      state

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 640 }}
          onClose={() => {
            closeModal('record')
          }}
          center
        >
          <ValidatorForm onSubmit={on.submitInspector}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='inspector_notes'>
                  Additional Notes (From Inspector)
                  <span className='required-label'>*</span>
                </label>
                <TextField
                  key={1}
                  className='form-control'
                  value={inspector_note}
                  onChange={on.input('inspector_note')}
                  maxLength='255'
                />
              </div>
            </div>
            <div className='modal-footer'>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
        <Modal
          open={state.notesRecord}
          onClose={() => {
            closeModal('notesRecord')
          }}
          center
          styles={{ modal: { width: 600 } }}
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Notes</h5>
          </div>
          {!state.notesRecord?.notes.length &&
          state.profile?.roles?.includes('police_sargent') ? (
            <ValidatorForm onSubmit={on.submitSargeant}>
              <div className='modal-body'>
                <div className='form-group form-show-validation'>
                  <label htmlFor='inspected_by'>
                    Inspected By
                    <span className='required-label'>*</span>
                  </label>
                  <InputField
                    name='inspected_by'
                    value={
                      `${state.profile?.first_name} ${state.profile?.last_name}`.capitalize() ||
                      inspected_by
                    }
                    onChange={on.input('inspected_by')}
                    className='form-control'
                    validators={['required']}
                    errorMessages={['Required']}
                    required
                    disabled={state.profile?.first_name ? true : false}
                  />
                </div>
                <div className='form-group form-show-validation'>
                  <label htmlFor='inspection_date'>
                    Notes
                    <span className='required-label'>*</span>
                  </label>
                  <TextField
                    key={1}
                    className='form-control'
                    value={inspection_note}
                    onChange={on.input('inspection_note')}
                    maxLength='255'
                  />
                </div>
                <div className='form-group form-show-validation'>
                  <label htmlFor='inspection_date'>
                    Inspection Date
                    <span className='required-label'>*</span>
                  </label>
                  <DateField
                    type='date'
                    className='form-control'
                    name={'end_date'}
                    onChange={on.date}
                    selected={inspection_date}
                    value={inspection_date}
                    maxDate={new Date()}
                    validators={['required']}
                    errorMessages={['Required']}
                    dateFormat='do MMMM, yyyy'
                    showYearDropdown
                  />
                </div>
              </div>

              <div className='modal-footer'>
                <input
                  className='btn custom-btn btn-round'
                  type='submit'
                  value='Submit'
                />
              </div>
            </ValidatorForm>
          ) : (
            state.notesRecord?.notes.map((note, i) => (
              <InspectionNote note={note.text} key={i} i={i} />
            ))
          )}
        </Modal>
      </React.Fragment>
    )
  }
}
