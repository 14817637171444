import * as T from '@action-types'

const initial = {
  appointments: null,
  blackouts: [],
  types: [],

  loading: false,
  success: false,
  error: null,

  success_message: null,
}

const reducer = (state = initial, { type, payload }) => {
  switch (type) {
    case T.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case T.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload && payload.response.data.error,
      }
    case T.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: payload.response.schedules,
      }
    case T.GET_APPOINTMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        types: payload,
      }
    case T.GET_APPOINTMENT_BLACKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        blackouts: payload || [],
      }
    case T.CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        schedule_appointment: payload.response.schedule_appointment,
      }
    case T.CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case T.REMOVE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success_message: 'Successfully removed',
        success: true,
      }
    default:
      return {
        ...state,
        loading: false,
      }
  }
}

export default reducer
