/* These are import statements in JavaScript that import various modules and components needed for the
TradeUnionRegistration class to function properly. */
import React, { Fragment, useState } from 'react'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'
import Loader from '@shared/loader'
import Modal from 'react-responsive-modal'
import { extractKeys } from '@helpers/objects'
// import Form from '@modules/form-wizard/form'
// import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

/* This code exports a React component called `TradeUnionRegistration` as the default export. The
component extends the `React.Component` class and defines various properties and methods that are
used to render a form wizard for registering a trade union. */
export default class TradeUnionRegistration extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  // async componentDidMount() {
  //   Object.assign(this, extractKeys($app, 'current_user:user'))
  // }

  resource = '/trade_unions'
  form_key = 'trade_union'
  isRenewal = this.props.location.pathname.includes('-renewal')
  isTransfer = this.props.location.pathname.includes('-transfer')

  // postpaid = true
  custom_postpaid_message =
    `Thank you for using MyGateway. Your request for a new Trade Union Registration Licence has been successfully submitted and will be reviewed. 
    An email confirming the licence approval or denial will be sent to your inbox. 
    If approved, you will receive a notification to proceed with the payment for your new Licence.`
  pay_on_approval = true
  skip_location = true

  hide_buttons = {
    status: true,
    deny: false,
    fee: true,
  }

  state = {
    loading: false,
  }

  defaults = {
    renewal: this.isRenewal,
    // transfer: this.isTransfer
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
        // transfer: this.isTransfer,
      },
    },
  }

  custom_actions = [
    {
      text: 'Approve For Payment',
      icon: 'check',
      roles: ['dol'],
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Approve For Payment',
          text: `
            Are you sure you want to approve this application and allow
            the applicant to pay & receive their certificate? 
          `,
          showCancelButton: true,
        })

        if (isDismissed) return
        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_payment_amount', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          toastr.success('Success', 'Application fee successfully set')
        } catch (err) {
          console.error(err)
          Swal.fire(
            'Oops...',
            'An error occurred while approving the application',
            'error'
          )
        }
      }
    },
    {
      user: true,
      text: 'Transfer License',
      icon: 'exchange-alt',
      test: r => r.application_decision == 'approved'
        && !r.transferee_id && !r.transferred_to_id,
      fn: record => this.setState({ record })
    },
    {
      user: true,
      text: 'Cancel Transfer',
      icon: 'times',
      test: r => r.application_decision == 'approved'
        && r.transferee_id && r.transferred_to_id && ($app?.current_user?.id == r.user?.id),
      fn: async record => {
        const { form_num } = record
        const { isDismissed } = await Swal.fire({
          icon: 'info',
          title: 'Cancel Transfer',
          text: 'Are you sure that you want to cancel this transfer?',
          showCancelButton: true,
          cancelButtonText: 'Nevermind',
          confirmButtonText: 'Cancel Transfer'
        })

        if (isDismissed) return

        try {
          await $app.axios.put(`${this.resource}/cancel_transfer`, { form_num })

          await Swal.fire(
            'Transfer Cancelled',
            `Transfer request for ${form_num} has been cancelled.`,
            'success'
          )
        } catch (err) {
          console.error(err)

          await Swal.fire(
            'Error',
            'We failed to cancel your transfer request',
            'error'
          )
        }
      }
    },
    {
      user: true,
      table: true,
      text: 'Transfer License',
      icon: 'exchange-alt',
      fn: recs => runActionOnSelectedRecord.call(
        this,
        recs,
        rec => (rec.application_decision == 'approved' && !rec.transferee_id && !rec.transferred_to_id),
        record => this.setState({ record })
      )
    },
    this.isTransfer && {
      user: true,
      text: 'Accept Transfer',
      icon: 'check',
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Accept Transfer',
          text: `
            Are you sure that you want to accept the
            transfer of ${record.union_name}, ${record.union_street_address}
          `,
          showCancelButton: true,
          confirmButtonText: 'Accept Transfer',
        })

        if (isDismissed) return
        
        try {
          await $app.axios.put('/trade_unions/accept_transfer', {
            trade_union: extractKeys(record, 'form_num')
          })

          Swal.fire(
            'Transfer Successful',
            '',
            'success'
          )
        } catch (err) {
          console.error(err)

          Swal.fire(
            'Error',
            'An error occurred while accepting the transfer',
            'error'
          )
        }
      }
    },
    this.isTransfer && {
      user: true,
      text: 'Decline Transfer',
      icon: 'times',
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Decline Transfer',
          text: `
            Are you sure that you want to decline the
            transfer of ${record.union_name}, ${record.union_street_address}
          `,
          showCancelButton: true,
          confirmButtonText: 'Decline Transfer',
        })

        if (isDismissed) return
        
        try {
          await $app.axios.put('/trade_unions/decline_transfer', {
            trade_union: extractKeys(record, 'form_num')
          })

          Swal.fire(
            'Transfer Declined',
            '',
            'success'
          )
        } catch (err) {
          console.error(err)

          Swal.fire(
            'Error',
            'An error occurred while declining the transfer',
            'error'
          )
        }
      }
    },
  ]

 /* `defaults` is an object that contains default values for the fields in the form. These values will
 be used to pre-populate the form when it is first loaded. The default values include the type of
 union, whether a new name will be used, the name of the employer, the name of the union, the street
 address of the union, and information about the union officers, including their titles, names,
 email addresses, NIB numbers, addresses, and occupations. */
  // defaults = {
  //   "union_type": "Union of Employees",
  //   "new_name": true,
  //   "employer_name": "Woodworking",
  //   "union_name": "Totally New Union #242",
  //   "union_street_address": "TEST STREET",
  //   "union_officers": [
  //     {
  //         "title": "President",
  //         "email": "test@email.com",
  //         "first_name": "John",
  //         "middle_name": "J",
  //         "last_name": "Doe",
  //         "nib": "12345678",
  //         "address": "TEST ADDRESS",
  //         "occupation": "Business Management & Administration"
  //     },
  //     {
  //         "title": "Treasurer",
  //         "email": "test@email.com",
  //         "first_name": "Jane",
  //         "middle_name": "",
  //         "last_name": "Doe",
  //         "nib": "754732624",
  //         "address": "Test Address",
  //         "occupation": "Arts, Audio/Video Technology & Communications"
  //     },
  //     {
  //         "title": "General Secretariat",
  //         "email": "test@email.com",
  //         "first_name": "Jack",
  //         "middle_name": "T",
  //         "last_name": "Doe",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     },
  //     {
  //         "title": "Trustee",
  //         "email": "test@email.com",
  //         "first_name": "Johnathon",
  //         "middle_name": "D",
  //         "last_name": "Munnings",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     }
  //   ],
  // }

  validate = form => {

  }

  canned = {
    'TEST CANNED': `
      This is a test canne message; it can be whatever you like.
    `
  }

  foremost = null

  closeModal = () => this.setState({ record: null })

  initiateTransfer = async (transferValues) => {
    await $app.axios.put('/trade_unions/transfer_license', {
      [this.form_key]: {
        form_num: this.state.record.form_num,
        nib: transferValues.nib,
        first_name: transferValues.firstName,
        last_name: transferValues.lastName,
      }
    })

    this.setState({ record: null })

    await Swal.fire(
      'Licence Transfer Started',
      'Your transfer licence has been sent to the requested recipient',
      'success'
    )
  }

  hooks = this.isTransfer ? {
    'read': async () => {
      const { data } = await $app.axios.get('/trade_unions/show_transfer_requests')
      return data
    }
  } : null

  fields = form => [
    // ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      hide: true,
      save: true,
    },
    {
      // TODO: Change to Sweetalert popup on start of application
      name: 'union_type',
      options: [
        'Union of Employees',
        'Union of Employers',
        // `Employer's Union`,
        `Association of Employers`,
        // `Employee's Union`,
        `Association of Employees`
      ]
    },
    {
      name: 'new_name',
      type: 'select:bool',
      label: 'Will this union be using a new name?',
    },
    {
      name: 'employer_name',
      label: `Craft/Industry`,
    },
    {
      name: 'union_name',
    },
    {
      name: 'union_street_address',
      label: 'Street Address',
    },
    // {
    //   heading: '<span></span>',
    //   // Pres, Treaurer, At least 2 Trustees, Secretary
    // },
    {
      name: 'union_officers',
      rowLabel: 'Officer',
      addLabel: 'Add Officer',
      min: form.client_type == 'business' ? 3 : 15,
      fields: form.union_type == 'Union of Employees' || 'Association of Employees'
      ? [
        {
          name: 'title',
          datalist: [
            'President',
            'Vice President',
            'Treasurer',
            'General Secretariat',
            'Trustee',
          ]
        },
        {
          name: 'email',

        },
        'first_name',
        {
          name: 'middle_name',
          required: false,
        },
        'last_name',
        {
          name: 'nib',
          label: 'NIB',
        },
        'address',
        {
          name: 'occupation',
          options: occupations,
        },
      ] : [
        {
          name: 'title',
          datalist: [
            'President',
            'Vice President',
            'Treasurer',
            'General Secretariat',
            'Trustee',
          ]
        },
        {
          name: 'email',
        },
        'business_name',
        {
          name: 'buusiness_nib',
          label: 'Business NIB',
        },
        'address',
      ],
    },
    {
      heading: '<span>&nbsp;</span>',
      view: false,
    },
    {
      name: 'constitution_upload',
      type: 'file:pdf',
      label: 'Copy of Constitution',
    }
  ]

  render = () => {
    const { record, loading } = this.state
    // console.log($app.current_user.id)

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!record}
          onClose={this.closeModal}
          classNames={{ modal: 'w-full max-w-xl' }}
          center
        >
          <TransferLicense closeModal={this.closeModal} initiateTransfer={this.initiateTransfer}/>
        </Modal>
      </Fragment>
    )
  }
}

async function runActionOnSelectedRecord (records, filter, callback) {
  const licenses = records.reduce((obj, rec, i) => {
    if (!filter || filter(rec)) {
      obj[i] = [
        rec.union_name,
        rec.union_street_address
      ].join(', ')
    }

    return obj
  }, {})

  if (!Object.keys(licenses)?.length) {
    await Swal.fire(
      'Oops',
      'The action you are trying to do cannot currently be done on/with any license.',
      'warning'
    )

    return null
  }

  const { value: index, isDismissed } = await Swal.fire({
    input: 'select',
    title: 'Please Select A License',
    inputOptions: licenses,
  })

  if (isDismissed) return null
  callback.call(this, records[index])
}

const TransferLicense = ({closeModal, initiateTransfer}) => {
  const [nib, setNib] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  return (
    <div>
      <header className='modal-header'>
        <h5 className='mb-0'>Transfer Licence</h5>
      </header>
      <p className='my-2'>
        Enter the details for whom you want to transfer this licence to.&nbsp;
        <strong>
          Please note that the recipient must have a MyGateway account
        </strong>
      </p>
      <article className='modal-body flex flex-col space-y-6'>
        <div className='flex'>
          <label htmlFor="nib">NIB #:</label>
          <input type="number" id="nib" name="nib" className='ml-2 rounded-sm' onChange={e => setNib(e.target.value)}/>
        </div>

        <div className='flex'>
          <label htmlFor="firstName">First Name:</label>
          <input type="text" id="firstName" name="firstName" className='ml-2 rounded-sm' onChange={e => setFirstName(e.target.value)}/>
        </div>
        <div className='flex'>
          <label htmlFor="lastName">Last Name:</label>
          <input type="text" id="lastName" name="lastName" className='ml-2 rounded-sm' onChange={e => setLastName(e.target.value)}/>
        </div>
      </article>
      <footer className='modal-footer flex justify-end'>
        <button className='btn btn-muted' onClick={() => closeModal()}>
          Close
        </button>
        <button className='btn btn-primary' onClick={() => initiateTransfer({nib, firstName, lastName})}>
          Submit
        </button>
    </footer>
    </div>

  )
}