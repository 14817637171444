import React from 'react'
import Tooltip from 'react-tooltip'
import { ValidatorForm } from 'react-form-validator-core'
import { confirmAlert } from 'react-confirm-alert'
import MaskedInput from 'react-input-mask'

import { extractKeys, withoutKeys, getProperty } from '@helpers/objects'
import { fallback } from '@helpers/utils'
import Fields from '@shared/form-fields'
import Alert from '@modules/alert'
import Digger from '@modules/traverse-object'

const ignoredKeys = [
  'submitting',
  'uploading',
  'modal',
  'previews',
  'eligible',
  undefined,
]

const initialCaps = str =>
  str
    .trim()
    .split(/[ _-]/)
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')

const snakeCase = str => str.trim().toLowerCase().replace(/[ -]/g, '_')

const generateState = ({ fields, defaults }) =>
  fields(defaults).reduce(
    (state, field) => {
      // prettier-ignore
      if (defaults?.[field.name]) return {
        ...state,
        [field.name]: defaults[field.name],
      }

      // prettier-ignore
      if (typeof field == 'string') return field.startsWith('::')
        ? state
        : { ...state, [snakeCase(field)]: fallback(defaults?.[field], '') }

      // prettier-ignore
      if (field.use_profile) return {
        ...state,
        [field.name]: getProperty($app.applicant, field.use_profile),
      }

      // prettier-ignore
      if (field.options || /^(file|select)/.test(field.type)) return {
        ...state,
        [field.name]: fallback(defaults?.[field.name], null),
      }

      return {
        ...state,
        [field.name]: '',
      }
    },
    {
      error: null,
      submitting: false,
      uploading: false,
      previews: {},
    }
  )

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = generateState(props)
    this.form = React.createRef()
  }

  static defaultProps = {
    name: 'validator-form',
    field: Field,
    fields: [],
    validate: () => true,
    save: () => {},
    defaults: {},
    hideButton: true,
    submitText: 'Submit',
  }

  options = arr =>
    arr?.length
      ? arr.map(value =>
          typeof value == 'string' ? { value, label: value } : value
        )
      : []

  findSelected = (options, target) => [
    options.find(o => o.value === target) || {},
  ]

  snakeCase = str => str.trim().toLowerCase().replace(/[ -]/g, '_')

  formatDate = date =>
    typeof date == 'string'
      ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
      : date

  checkKnownTypes = (name, is) => {
    const obj = (fn, icon, regex, type, inputMode) => ({
      type,
      fn,
      icon,
      regex,
      inputMode,
    })

    const num = (fn, icon, regex) => obj(fn, icon, regex, 'text', 'decimal')
    const { on } = this

    if (/(first|middle|last)_name$/.test(name))
      return obj(on.alphanumeric, 'font')

    if (/^passport/.test(name) || is == 'passport')
      return obj(on.passport, 'passport')

    if (/^(drivers?_licen[cs]e|licen[cs]e_number)$/.test(name))
      return num(on.integer, 'id-card', /^[0-9]{0,9}$/)

    if (is == 'currency') return num(on.float, 'dollar-sign')

    if (is == 'time') return obj(on.text, 'clock')

    if (/^(integer|float)$/.test(is)) return num(on[is], 'hashtag')

    return obj(on.text, 'font')
  }

  getField = field => {
    const { fields, heading, table } = this
    const { type, options } = field

    if (typeof field == 'string') {
      if (field == '::SPACER')
        return heading('<span>&nbsp;</span>', Math.random())
      if (field.startsWith('::')) return heading(field.slice(2))

      if (/^phone/.test(field)) return fields.phone(field)
      return fields.text(field)
    }

    if (field.heading && !field.hide) return heading(field.heading)

    if (!type && options) return fields.select(field)

    if (field.columns) return table(field)

    return (fields[type] || fields.text)(field)
  }

  heading = (label, key) =>
    label ? (
      <div className='form-group row' key={key || label}>
        <h5
          style={{ marginLeft: '2rem' }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>
    ) : null

  handleCallback = async (cb, val) => {
    if (typeof cb == 'function') {
      const result = await cb(val)

      if (['object', 'function'].includes(typeof result)) {
        this.setState(result)
      }
    }

    setTimeout(Tooltip.rebuild, 200)
  }

  selectPickerOption = (target, value) => () =>
    this.setState(
      state => {
        const finder = new Digger(state)
        finder.dig(target, value)
        return finder.data
      },
      () => this.props.picker.onSelect(value)
    )

  attemptSubmit = async e => {
    const { state, props, prepare } = this
    const { save, validate, fields } = props

    let valid = false
    let _keys = new Set()
    let _data = {}

    e.preventDefault()
    if (state.uploading) return

    valid = await this.form.current.isFormValid(false)
    if (!valid) return

    const form = fields(state)
    valid = await validate(state)
    if (!valid) return

    if (typeof valid == 'object') {
      _data = valid
      for (let key of Object.keys(valid)) {
        if (!form.find(f => f == key || f?.name == key)) {
          _keys.add(key)
        }
      }
    }

    Object.assign(state, _data)
    form.push(..._keys)

    const args = form.some(f => /^file/.test(f.type))
      ? [prepare.form(state), new FormData()]
      : [prepare.json(state), {}]

    save(form.reduce(...args))
  }

  prepare = {
    json: state => (obj, field) => {
      const key = typeof field == 'string' ? field : field.name

      if (
        false ||
        ignoredKeys.includes(key) ||
        field.admin ||
        field.profile ||
        field.save === false ||
        (field.hide && !field.save)
      )
        return obj

      const val = state[key]
      if (['', undefined, null].includes(val) && !field.save) return obj

      if (field.columns) {
        obj[key] =
          typeof field.format == 'function'
            ? field.format(val)
            : val.map(JSON.stringify)
      } else if (field.type == 'checklist') {
        obj[key] =
          typeof field.format == 'function'
            ? field.format(val)
            : field.single
            ? val
            : [...val].join(', ')
      } else {
        obj[key] = val
      }

      return obj
    },

    form: state => {
      const { form_key: f } = this.props
      return (data, field) => {
        const key = typeof field == 'string' ? field : field.name

        if (
          false ||
          ignoredKeys.includes(key) ||
          field.admin ||
          field.profile ||
          field.save === false ||
          (field.hide && !field.save)
        )
          return data

        const val = state[key]
        let prop = f ? `${f}[${key}]` : key
        if (['', undefined, null].includes(state[key])) return data

        if (key == 'serviceables') {
          data.append(prop, JSON.stringify(val))
        } else if (field.columns) {
          prop += '[]'
          for (let row of val) {
            data.append(
              prop,
              typeof field.format == 'function'
                ? field.format(row)
                : JSON.stringify(row)
            )
          }
        } else if (field.type == 'checklist') {
          data.append(
            prop,
            typeof field.format == 'function'
              ? field.format(val)
              : [...val].join(', ')
          )
        } else if (field.multi) {
          prop += '[]'
          for (let row of val) {
            data.append(prop, row)
          }
        } else {
          data.append(prop, val)
        }

        return data
      }
    },
  }

  modal = {
    open: modal => this.setState({ modal }),
    close: () => this.setState({ modal: null }),
  }

  on = {
    text: (name, rx, cb, format) => ev => {
      const val = ev.target.value
      if (rx && val && !rx.test(val)) return

      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        this.setState(state => {
          state[table][index][prop] = format ? format(val) : val
          return state
        })
        this.handleCallback(cb, val, index)
      } else {
        this.setState({ [name]: format ? format(val) : val })
        this.handleCallback(cb, val)
      }
    },

    select: (name, validator, cb, multi) => values => {
      const { value } = values?.[0] || {}
      if (value === undefined || (validator && !validator(value))) return

      if (name.includes('::')) {
        const [table, index, prop] = name.split('::')
        this.setState(state => {
          state[table][index][prop] = value
          return state
        })
        this.handleCallback(cb, value, index)
      } else if (multi) {
        const newValues = values.map(v => v?.value)
        this.setState({ [name]: newValues })
      } else {
        this.setState({ [name]: value })
        this.handleCallback(cb, value)
      }
    },

    date: (name, validator, cb) => val => {
      if (validator && !validator(val)) return
      this.setState({ [name]: val })
      this.handleCallback(cb, val)
    },

    check:
      (name, cb) =>
      ({ target }) => {
        this.setState({ [name]: target.checked })
        this.handleCallback(cb, target.checked)
      },

    checklist:
      (name, cb, single = false) =>
      ({ target }) => {
        this.setState(state => {
          if (single) {
            state[name] = target.checked ? target.value : ''
          } else {
            state[name][target.checked ? 'add' : 'delete'](target.value)
          }
          return state
        })

        this.handleCallback(cb, this.state[name])
      },

    alphanumeric: (name, cb) => this.on.text(name, /^[0-9a-z .'-]{0,}$/i, cb),

    integer: (name, cb) => this.on.text(name, /^[0-9]+$/, cb),

    passport: (name, cb) =>
      this.on.text(name, /^[adocre][a-tz]?[0-9]{0,7}$/i, cb, v =>
        v.toUpperCase()
      ),

    float: (name, cb) => this.on.text(name, /^[0-9]+(\.[0-9]{0,3})?$/, cb),

    currency: (name, cb) =>
      this.on.text(name, /^[0-9]{0,}(\.[0-9]{0,2})?$/, cb),

    file:
      ({ name, callback: cb, width, height }) =>
      ({ target }) => {
        const file = target?.files?.[0]
        this.setState({ [name]: file })
        this.handleCallback(cb, file)
      },

    block: field => val => {
      if (val === undefined) return

      const { back } = this.props
      const result = field.test(val)
      const block = {
        heading: 'Unable To Continue',
        message:
          'Sorry, but you will be unable to continue with the provided response',
      }

      if (result === true) {
        this.setState({ eligible: true })
        return
      }

      if (typeof result == 'object') {
        Object.assign(block, result)
      } else if (typeof result == 'string') {
        block.message = result
      }

      confirmAlert({
        customUI: ({ onClose }) => (
          <div className='custom-ui'>
            <h3 dangerouslySetInnerHTML={{ __html: block.heading }} />
            <div
              style={{ color: '#333' }}
              dangerouslySetInnerHTML={{ __html: block.message }}
            />
            <button
              className='btn btn-primary'
              onClick={e => {
                typeof back == 'function' && back()
                onClose()
              }}
            >
              Return
            </button>
          </div>
        ),
      })
    },
  }

  fields = {
    'text': field => {
      const { state, props, on, snakeCase, checkKnownTypes } = this

      if (typeof field == 'string') {
        const { fn, icon, regex, type, inputMode } = checkKnownTypes(field)
        const name = snakeCase(field)
        const label = initialCaps(
          field
            .replace(/^po_?box/, 'P. O. Box')
            .replace(/^wife_/, "wife's_")
            .replace(/^husband_/, "husband's_")
            .replace(/^mothers/, "mother's")
            .replace(/^fathers/, "father's")
            .replace(/^drivers/, "driver's")
        )

        return (
          <props.field name={name} label={label} key={name}>
            <Fields.Input
              type={type || 'text'}
              name={name}
              icon={icon}
              inputMode={inputMode}
              value={state[name]}
              onChange={fn(
                name,
                regex ||
                  (/(first|middle|last)_name$/.test(name) &&
                    /^[0-9a-z .'-]{0,}$/i)
              )}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
            />
          </props.field>
        )
      } else {
        const {
          name,
          label,
          is,
          long,
          regex,
          callback,
          datalist,
          tooltip,
          table,
          index,
          value,
          format,
          test,
          ...rest
        } = field

        const fullName = table ? [table, index, name].join('::') : name
        const fullValue = table ? state[table][index][name] : state[name]

        const verify = test
          ? () => {
              on.block(field)(fullValue)
            }
          : null

        const fieldProps = {
          name,
          long,
          tooltip,
          key: name,
          label: label || initialCaps(name),
          verify,
          ...(rest || {}),
        }

        if (value) {
          const r =
            rest.required === false
              ? rest
              : {
                  validators: ['required'],
                  errorMessages: ['Required'],
                  ...rest,
                }

          const v = typeof value == 'function' ? value(fullValue) : value
          const f = (
            <Fields.Input
              {...r}
              className='form-control'
              value={v}
              icon={table ? null : rest.icon}
              disabled
            />
          )

          return table ? f : <props.field {...fieldProps}>{f}</props.field>
        }

        const {
          fn,
          icon,
          type,
          regex: rx,
          inputMode,
        } = checkKnownTypes(name, is || rest.type)

        const suggestions =
          !table && datalist ? (
            <datalist id={name + '_datalist'}>
              {datalist.map((val, i) => (
                <option key={i}>{val}</option>
              ))}
            </datalist>
          ) : null

        const args =
          (on[is] || fn).length == 2
            ? [fullName, callback]
            : [fullName, regex || rx, callback, format]

        const iprops = {
          inputMode,
          name: fullName,
          type: type || 'text',
          icon: rest.icon || icon,
          list: name + '_datalist',
          value: fullValue,

          onChange: (on[is] || fn)(...args),
          className: test ? 'form-control col' : 'form-control',

          ...(rest.required === false
            ? {}
            : {
                validators: ['required'],
                errorMessages: ['Required'],
              }),

          ...(table ? { icon: null } : {}),
          ...(rest || {}),
        }

        if (table) return <Fields.Input {...iprops} />

        return (
          <props.field {...fieldProps}>
            <Fields.Input {...iprops} />
            {suggestions}
          </props.field>
        )
      }
    },

    'phone': field => {
      const { state, props, on } = this
      const use = typeof field == 'string' ? { name: field } : null

      const { name, label, long, callback, ...rest } = use || field

      const iprops = {
        name,
        icon: 'phone',
        type: 'tel',
        value: state[name],

        onChange: on.text(name, null, callback),
        className: 'form-control',
        inputMode: 'tel',

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(rest || {}),
      }

      const fieldProps = {
        name,
        long,
        label: label || initialCaps(name),
        key: name,
        ...(rest || {}),
      }

      const input = childProps => <Fields.Input {...iprops} />

      return (
        <props.field {...fieldProps}>
          <MaskedInput
            mask='(999) 999-9999'
            value={props.value}
            onChange={props.onChange}
          >
            {input}
          </MaskedInput>
        </props.field>
      )
    },

    'select': field => {
      const { state, props, on, findSelected } = this
      const {
        name,
        label,
        long,
        options,
        validator,
        callback,
        tooltip,
        table,
        index,
        value,
        multi,
        ...rest
      } = field

      const opts = this.options(options)
      const fullName = table ? [table, index, name].join('::') : name
      const fullValue = table ? state[table][index][name] : state[name]

      const sprops = {
        name: fullName,
        options: opts,
        values: !multi ? findSelected(opts, fullValue) : [],
        value: fullValue,
        onChange: field.test
          ? val => {
              if (!val || !val.length) return
              on.block(field)(val.length && val[0].value)
              on.select(name, validator, callback)(val)
            }
          : on.select(fullName, validator, callback, multi),
        className: 'form-control',
        required: rest.required || true,
        multi,

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(rest || {}),
      }

      if (table) return <Fields.Select {...sprops} />

      const fieldProps = {
        name,
        long,
        tooltip,
        label: label || initialCaps(name),
        key: name,
        ...(rest || {}),
      }

      return (
        <props.field {...fieldProps}>
          <Fields.Select {...sprops} />
        </props.field>
      )
    },

    'date': field => {
      const { state, props, on, formatDate } = this
      const {
        name,
        label,
        placeholder,
        long,
        validator,
        callback,
        tooltip,
        ...rest
      } = field
      const selected = state[name] ? formatDate(state[name]) : null

      const dprops = {
        name,
        style: { width: 600 },
        selected: selected,
        value: selected,
        className: 'form-control',
        dateFormat: 'do MMMM, yyyy',
        dropdownMode: 'select',
        placeholderText: placeholder,
        onChange: on.date(name, validator, callback),

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(rest || {}),
      }

      const fieldProps = {
        name,
        long,
        tooltip,
        label: label || initialCaps(name),
        key: name,
        ...(rest || {}),
      }

      return (
        <props.field {...fieldProps}>
          <Fields.Date {...dprops} />
        </props.field>
      )
    },

    'file': field => {
      const { state, props, on } = this
      const {
        name,
        label,
        btnText,
        accept,
        long,
        parser,
        example,
        hint,
        tooltip,
        callback,
        send,
        maxSize,
        ...rest
      } = field

      const fprops = {
        name,
        accept,
        label: btnText,
        value: state[name],
        preview: state.previews[name],

        ...(rest.required === false
          ? {}
          : {
              validators: [
                'required',
                'isFile',
                'maxFileSize:' + (maxSize || 3) * 1024 * 1024,
              ],
              errorMessages: [
                'Required',
                'A file is required',
                `File size must not exceed ${maxSize || 3}MB`,
              ],
            }),

        ...(rest || {}),

        onChange: on.file(field),
      }

      if (props.allow && field.required) {
        props.validators.push(`allowedExtensions:${props.allow}`)
        props.errorMessages.push(props.allow_msg)
      }

      const component = /^image/.test(accept) ? (
        <Fields.Image {...fprops} />
      ) : (
        <Fields.File {...fprops} />
      )

      const fieldProps = {
        name,
        long,
        hint,
        tooltip,
        label: label || initialCaps(name),
        key: name,
        ...(rest || {}),
      }

      return (
        <React.Fragment key={name}>
          <props.field {...fieldProps}>{component}</props.field>
        </React.Fragment>
      )
    },

    'checkbox': field => {
      const { state, on } = this
      const { name, label, callback, ...rest } = field

      const __html =
        label +
        (rest.required === false ? '' : `<span class='required-label'>*</span>`)

      return (
        <div key={name} className='form-check form-show-validation'>
          <label className='form-check-label flex items-center'>
            <input
              type='checkbox'
              name={name}
              checked={state[name]}
              className='form-check-input'
              onChange={on.check(name, callback)}
              required={rest.required !== false}
            />
            <span
              className='form-check-sign'
              dangerouslySetInnerHTML={{ __html }}
            />
          </label>
        </div>
      )
    },

    'checklist': field => {
      const { state, props, on } = this
      const { name, options, callback, single, inline } = field
      const boxClasses = 'form-check' + (inline ? ' inline-block' : '')

      const boxes = this.options(options).map((o, i) => (
        <div key={o.value} className={boxClasses}>
          <label className='form-check-label flex items-center'>
            <input
              type='checkbox'
              name={name}
              value={o.value}
              checked={
                single ? state[name] == o.value : state[name].has(o.value)
              }
              onChange={on.checklist(name, callback, single)}
            />
            <span className='form-check-sign'>{o.label}</span>
          </label>
        </div>
      ))

      return (
        <props.field
          {...extractKeys(field, 'name', 'label', 'stack', 'name:key')}
          wider={true}
        >
          <div className='form-wizard-checklist'>{boxes}</div>
        </props.field>
      )
    },

    'textarea': field => {
      const { state, props, on } = this
      const { name, label, callback, tooltip, list, ...rest } = field

      const format = val => {
        return list ? val.split(',') : val
      }

      const tprops = {
        name,
        value: state[name],

        onChange: on.text(name, null, callback, format),
        className: 'form-control',

        ...(rest.required === false
          ? {}
          : {
              validators: ['required'],
              errorMessages: ['Required'],
            }),

        ...(rest || {}),
      }

      const fieldProps = {
        name,
        tooltip,
        key: name,
        label: label || initialCaps(name),
        stack: true,
      }

      return (
        <props.field {...fieldProps}>
          <Fields.Textarea {...tprops} />
        </props.field>
      )
    },

    'select:bool': field =>
      this.fields.select({
        ...field,
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      }),

    'file:image': field =>
      this.fields.file({
        allow: 'image/png,image/jpeg,image/jpg',
        allow_msg: 'Only PNG or JPG images are allowed',
        accept: 'image/*',
        hint: 'Accepts: .jpg, .png',
        ...field,
      }),

    'file:pdf': field =>
      this.fields.file({
        allow: 'application/pdf',
        allow_msg: 'Only PDF allowed',
        hint: 'Accepts: .pdf',
        ...field,
        accept: 'application/pdf',
        type: 'file',
      }),
      /*
      'file:zip': field =>
      this.fields.file({
        allow: 'application/zip',
        accept: '.zip',
        allow_msg: 'Only ZIP files are allowed',
        hint: 'Accepts: .zip',
        type: 'file',
        ...field,
    }),*/

    'file:all': field =>
      this.fields.file({
        allow: 'application/pdf,image/png,image/jpeg,image/jpg',
        allow_msg: 'Only PDF, JPG, JPEG and PNG allowed',
        hint: 'Accepts: .pdf, .jpg, .jpeg, .png',
        ...field,
        accept: 'application/pdf,image/jpeg,image/jpg,image/gif,image/png',
        type: 'file',
      }),

    'email': field =>
      this.fields.text({
        ...field,
        inputMode: 'email',
        validators: ['required', 'isEmail'],
        errorMessages: ['Required', 'Invalid email address'],
      }),
  }

  freshTableRow = columns =>
    columns?.reduce((obj, col) => {
      if (typeof col == 'string')
        return {
          ...obj,
          [col]: '',
        }

      if (col.options)
        return {
          ...obj,
          [col.name]: null,
        }

      return {
        ...obj,
        [col.name]: '',
      }
    }, {})

  table = field => {
    const { state, getField, freshTableRow } = this
    const { name, label, columns, footer } = field

    const headings = columns.map(c => {
      const [id, text] =
        typeof c == 'string' ? [c(c)] : [c.name, c.label || initialCaps(c.name)]

      return <th key={id}>{text}</th>
    })

    const widths = columns.map(c => (
      <col key={c.name || c} span='1' width={c.width || 'auto'} />
    ))

    const fresh = () =>
      this.setState(state => {
        state[name].push(freshTableRow(columns))
        return state
      })

    const remove = index => () =>
      this.setState(state => {
        state[name].splice(index, 1)
        return state
      })

    const rows = state[name].map((row, index) => {
      const fields = columns
        .map(c =>
          typeof c == 'string'
            ? { name: c, table: name, index }
            : { ...c, table: name, index }
        )
        .map((c, i) => (
          <td key={c.name}>{getField(withoutKeys(c, 'width'))}</td>
        ))

      const close =
        state[name].length > 1 ? (
          <span data-action onClick={remove(index)}>
            <i className='fas fa-times-circle'></i>
          </span>
        ) : null

      return (
        <tr key={index}>
          {fields}
          <td>{close}</td>
        </tr>
      )
    })

    return (
      <div className='form-wizard-form-table table-responsive' key={name}>
        <h5>{label || initialCaps(name)}</h5>
        <table className='table table-striped table-striped-bg-black'>
          <colgroup>
            {widths}
            <col span='1' width='32px' />
          </colgroup>
          <thead>
            <tr className='header'>
              {headings}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        {footer ? footer : null}
        <aside>
          <span data-action onClick={fresh}>
            Add Row <i className='fas fa-plus-circle'></i>
          </span>
        </aside>
      </div>
    )
  }

  render() {
    const { state, props, form, modal, getField, attemptSubmit } = this

    const submitBtn = props.hideButton ? null : (
      <input
        type='submit'
        className='btn custom-btn float-right'
        value={props.submitText}
      />
    )

    const fields = props.fields(state).reduce((fields, f) => {
      if (!f || f.hide || f.admin || f.profile) return fields

      delete f.hide
      delete f.admin
      delete f.view
      delete f.value
      delete f.save

      fields.push(getField(f))
      return fields
    }, [])

    return (
      <React.Fragment>
        <ValidatorForm id={props.name} onSubmit={attemptSubmit} ref={form}>
          {fields}
          {submitBtn}
          <Tooltip id='form' html={true} />
        </ValidatorForm>
        <Alert
          {...state.error}
          open={!!state.error}
          type='critical'
          style={{ width: 480 }}
          onClose={modal.close}
        />
      </React.Fragment>
    )
  }
}

export function Field({
  name,
  label,
  required,
  long,
  stack,
  hint,
  tooltip,
  children,
}) {
  const labelClasses = long
    ? 'col-lg-6 col-md-6 col-sm-6 mt-sm-2 text-right large-label'
    : 'col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'

  const hintText = hint ? (
    <span
      className='text-muted'
      dangerouslySetInnerHTML={{ __html: hint }}
    ></span>
  ) : null

  const info = tooltip ? (
    <span data-for='form' data-tip={tooltip}>
      <i className='fas fa-info-circle'></i>
    </span>
  ) : null

  return (
    <div
      className={
        'form-group form-show-validation row' + (stack ? ' stacked-field' : '')
      }
    >
      <label
        htmlFor={name}
        className={labelClasses}
        style={long || stack ? { whiteSpace: 'normal' } : null}
      >
        {label}
        {required !== false ? <span className='required-label'>*</span> : null}
      </label>
      <div>
        {children}
        {hintText}
      </div>
      {info}
    </div>
  )
}

export function Heading(label) {
  return label ? (
    <div className='form-group row' key={label}>
      <h5 style={{ marginLeft: '2rem' }}>{label}</h5>
    </div>
  ) : null
}
