import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class WaterTaxi extends React.Component {
  id = "WaterTaxiExemption";
  service = "water taxi exemption";
  title = "Water Taxi Exemption";
  agency = "mofrd";
  item = "";
  resource = "/water_taxi_exemptions";
  form_key = "water_taxi_exemption";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "client_type",
      label: "Name of Applicant",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "vessel_type",
      label: "Type of Vessel",
    },
    {
      name: "vessel_year",
      label: "Year of Vessel",
    },
    {
      name: "vessel_serial_number",
      label: "Vessel Serial No.",
    },
    {
      name: "vessel_value",
      label: "Value of Vessel",
      type: "float",
    },
    {
      name: "bl_number",
      label: "Business License No.",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "tin_number",
      label: "Tax Identification No.",
    },
    {
      name: "identification_upload",
      label: "Copy of Identification for Owner",
      type: "file:image",
    },
    {
      name: "license_from_port_upload",
      label: "Copy of License from The Port Department",
      type: "file:image",
    },
    {
      name: "vessel_invoice_upload",
      label: "Copy of Invoice for Vessel",
      type: "file:image",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
