import React from 'react'
// import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'

export default class ServicedLot extends React.Component {
  state = {
    self_employed: false,
    more_income: false,
    co_applicant: false,
  }

  // componentDidMount() {
  //   Swal.fire(
  //     'Applications Closed',
  //     `
  //       Sorry, but this application form is no longer available on MyGateWay.
  //       Please visit the Department of Housing's website
  //       <a href='https://www.mothbahamas.com/signup'>here</a> for further
  //       instructions
  //     `,
  //     'info'
  //   )
  // }

  title = 'Serviced Lot Sale'
  agency = 'doh'
  item = '302'
  admin = 'housing'

  resource = '/serviced_lot_sales'
  form_key = 'serviced_lot_sale'

  custom_acceptance = true
  skip_location = true
  free = true

  defaults = {
    co_applicant_birth_country: 'Bahamas',
    co_applicant_citizenship: 'Bahamas',
  }

  foremost = {
    type: 'select:bool',
    label:
      'Do you have ready a Mortgage Preapproval Letter and a current Job Letter (or a Business Licence if you are self employed)?',
    long: true,
    test: v =>
      v ||
      'Sorry, but you must meet the prerequisites before being allowed to apply. Please try again after obtaining the necessary documents.',
  }

  set = key => val => this.setState({ [key]: val })

  districts = {
    'New Providence': {
      'Carmichael Village': true,
      'Fire Trail': true,
      'Dignity Garden': true,
      'Pinecrest, South Beach': true,
      'Renaissance ': true,
    },
    'Grand Bahama': {
      'West Height': true,
      'Pine Forest': true,
      'Heritage': true,
    },
    'Abaco': {
      'Spring City': true,
      'Sands Cove': true,
      'Central Pines': true,
      'Fox Town, Crown Haven': true,
    },
    'Eleuthera': {
      'Alice Town, Hatchet Bay': true,
      'Rock Sound': true,
      'North Eleuthera': true,
    },
  }

  validate = form => {
    return {
      district: [form.district_area, form.district_island].join(', '),
    }
  }

  hooks = {
    'post:read': data => {
      if ($app.hasRole('pilot') && data?.length) {
        for (let d of data) {
          if (d.district) {
            const [k1, k2] = d.district.split(', ')
            delete this.districts[k2][k1]
          }
        }

        for (let key of Object.keys(this.districts)) {
          if (!Object.values(this.districts[key]).length) {
            delete this.districts[key]
          }
        }
      }

      return data
    },
  }

  // before_apply = () => {
  //   return `
  //     Sorry, but this application form is no longer available on MyGateWay.
  //     Please visit the Department of Housing's website
  //     <a href='https://www.mothbahamas.com/signup'>here</a> for further
  //     instructions.
  //   `

  //   // if (!Object.keys(this.districts).length)
  //   //   return 'Sorry, but you have already submitted applications for all available districts.'
  // }

  fields = form => {
    const { state, set, districts } = this
    const noCo = !form.co_applicant_first_name

    return [
      {
        name: 'is_self_employed',
        type: 'select:bool',
        view: false,
        label: 'Are you self employed?',
        callback: set('self_employed'),
      },
      '::Uploads',
      {
        key: 'image.5',
        name: 'mortgage_letter_upload',
        type: 'file:all',
        label: 'Mortgage Preapproval',
        required: false,
      },
      {
        key: 'image.0',
        name: 'job_letter_upload',
        type: 'file:all',
        label: 'Job Letter',
        hide: state.self_employed,
        view: {
          hide: !form.tenure_of_employment,
        },
        required: false,
      },
      {
        key: 'image.4',
        name: 'business_license_upload',
        type: 'file:image',
        hide: !state.self_employed,
        label: 'Business Licence',
        tooltip: 'Business Licence must be up to date.',
        view: {
          hide: form.tenure_of_employment,
        },
      },
      {
        key: 'image.1',
        name: 'passport_upload',
        type: 'file:image',
        label: 'Identification',
        hint: "A valid passport or voter's card",
      },
      '::Personal Details',
      {
        name: 'application_type',
        options: ['Vacant Land Only', 'Land & House Package'],
      },
      {
        name: 'property_type',
        options: ['Single Family Lot', 'Multi Family Lot'],
      },
      {
        name: 'district',
        hide: true,
        view: true,
      },
      {
        name: 'district_island',
        label: 'Island',
        options: Object.keys(districts),
        view: false,
      },
      {
        name: 'district_area',
        key: form.district_island,
        label: 'District',
        options: form.district_island
          ? Object.keys(districts[form.district_island])
          : [],
        view: false,
        disabled: !form.district_island,
      },
      {
        name: 'living_arrangements',
        options: ['Rent', 'Own', 'Other'],
      },
      {
        name: 'tenure_of_employment',
        label: 'Employment Tenure (years)',
        is: 'integer',
        hide: state.self_employed,
        view: {
          hide: !form.tenure_of_employment,
          label: 'Employment Tenure',
          value: v => v && +v + ' year(s)',
        },
      },
      {
        name: 'more_income',
        type: 'select:bool',
        label: 'Additional Income?',
        callback: set('more_income'),
        view: {
          value: () => (parseInt(form.additional_income) ? 'Yes' : 'No'),
        },
      },
      {
        name: 'additional_income',
        label: 'Amount',
        is: 'float',
        hide: !state.more_income,
        hint: 'Per Annum.',
        view: {
          hide: !parseInt(form.additional_income),
          value: v => `$${Number(v).toLocaleString()} per annum.`,
        },
      },
      {
        name: 'additional_income_source',
        hide: !state.more_income,
        label: 'Source',
        maxLength: 255,
        view: {
          hide: !parseInt(form.additional_income),
        },
      },
      '::Co-Applicant Details',
      {
        name: 'co_applicant',
        type: 'select:bool',
        label: 'Is There A Co-Applicant?',
        callback: set('co_applicant'),
        view: {
          label: 'Co-Applicant?',
          value: () => (noCo ? 'No' : 'Yes'),
        },
      },
      {
        name: 'co_applicant_name',
        hide: true,
        view: {
          hide: noCo,
          label: 'Name',
          value: () =>
            ['prefix', 'first_name', 'middle_name', 'last_name', 'suffix']
              .map(k => form['co_applicant_' + k])
              .join(' '),
        },
      },
      {
        name: 'co_applicant_prefix',
        label: 'Prefix',
        hide: !state.co_applicant,
        datalist: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.', 'Rev.'],
        required: false,
        view: false,
      },
      {
        name: 'co_applicant_first_name',
        label: 'First Name',
        hide: !state.co_applicant,
        view: false,
      },
      {
        name: 'co_applicant_middle_name',
        label: 'Middle Name',
        hide: !state.co_applicant,
        required: false,
        view: false,
      },
      {
        name: 'co_applicant_last_name',
        label: 'Last Name',
        hide: !state.co_applicant,
        view: false,
      },
      {
        name: 'co_applicant_maiden_name',
        label: 'Maiden Name',
        hide: !state.co_applicant,
        required: false,
        view: false,
      },
      {
        name: 'co_applicant_suffix',
        label: 'Suffix',
        hide: !state.co_applicant,
        datalist: ['Sr.', 'Jr.', 'II', 'III', 'M.D'],
        required: false,
        view: false,
      },
      {
        name: 'co_applicant_relationship',
        label: 'Relationship',
        hide: !state.co_applicant,
        datalist: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
        view: {
          hide: noCo,
        },
      },
      {
        is: 'integer',
        name: 'co_applicant_nib',
        label: 'NIB Number',
        hide: !state.co_applicant,
        view: {
          hide: noCo,
        },
      },
      {
        is: 'passport',
        name: 'co_applicant_passport',
        label: 'Passport Number',
        hide: !state.co_applicant,
        view: {
          hide: noCo,
        },
      },
      {
        is: 'integer',
        name: 'co_applicant_voters_card',
        label: "Voter's Card",
        hide: !state.co_applicant,
        required: false,
        view: {
          hide: noCo,
        },
      },
      {
        name: 'co_applicant_employment',
        options: ['Yes', 'No'],
        label: 'Employed?',
        hide: !state.co_applicant,
        view: {
          hide: noCo,
        },
      },
      {
        name: 'co_applicant_employment_address',
        label: 'Work Address',
        hide: !state.co_applicant || form.co_applicant_employment != 'Yes',
        view: {
          hide: noCo || form.co_applicant_employment != 'Yes',
        },
      },
      {
        name: 'co_applicant_occupation',
        label: 'Occupation',
        options: occupations,
        hide: !state.co_applicant || form.co_applicant_employment != 'Yes',
        view: {
          hide: noCo || form.co_applicant_employment != 'Yes',
        },
      },
      {
        name: 'co_applicant_years',
        label: 'Tenure',
        hide: !state.co_applicant || form.co_applicant_employment != 'Yes',
        is: 'integer',
        view: {
          hide: noCo || form.co_applicant_employment != 'Yes',
          value: v => v + ' year(s)',
        },
      },
      {
        use_as_acceptance: true,
        name: 'acceptance',
        type: 'checkbox',
        view: false,
        label: `
          This application, to the best of my/our knowledge and belief is completed and accurate
          and I/We agree and consent that employment and credit inquiries may be made by the lender
          at any time relative to any and all information given by me/us in this application.
        `,
      },
    ]
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
