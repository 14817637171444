import islands from './islands'

const list = [
  ...islands,
  "Moore's Island",
  'Jumentos Cay',
  'Great Bahama Bank',
  'Cay Sal Bank',
  'ALL BAHAMIAN WATERS',
]

export default list
