import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer className='footer'>
      <div className='flex w-full mx-auto px-4 flex-col md:flex-row items-center md:items-start md:justify-between'>
        <nav>
          <ul className='nav'>
            <li className='flex flex-col items-center md:items-start xl:flex-row nav-item mb-4 md:mb-0 space xl:space-x-4'>
              <Link to='/legal/terms-of-use'>Terms and Conditions</Link>
              <Link to='/legal/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </nav>
        <div>
          <Link
            to='/'
            className='flex flex-col items-center md:items-end xl:flex-row'
          >
            <span>{year} My Gateway</span>
            <span className='hidden xl:inline'>&nbsp;|&nbsp;</span>
            <span>Making Government Work For You</span>
          </Link>
        </div>
      </div>
    </footer>
  )
}
