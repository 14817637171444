import * as T from '@action-types'
import toastr from '@modules/toastr'

const AGENCIES_URL = '/agencies'
const CALENDAR_URL = '/calendar_events'
const FEEDBACK_URL = '/customer_care'
const SERVICES_URL = '/agency_services'
const DELIVERY_URL = '/deliveries'

const clearSession = () => {
  window.sessionStorage.clear()
  window.location.href = '/login'
}

const createError = err => dispatch => {
  const { status, data } = err.response || {}

  status == 401 && clearSession()
  toastr.error('Error', data?.error)

  setTimeout(() => {
    dispatch({
      type: T.CLEAR_ERROR,
    })
  }, 2000)

  dispatch({
    type: T.GET_RESPONSE_ERROR,
    payload: err,
  })
}

const getRequest = { type: T.GET_REQUEST_SUCCESS }

const Agency = async (
  dispatch,
  subresource = '',
  prop = '',
  action = '',
  silent = false
) => {
  !silent && dispatch(getRequest)

  try {
    const url = AGENCIES_URL + (subresource ? '/' + subresource : '')
    const { data } = await $app.axios.get(url)

    dispatch({
      type: T[action || ('get_agency_' + subresource).toUpperCase()],
      payload: data[prop],
    })

    return true
  } catch (err) {
    dispatch(createError(err))
    return false
  }
}

export const getAgencies = cb => dispatch =>
  Agency(dispatch, '', 'agency', 'GET_AGENCIES').then(cb)

export const getAgencyLocations = cb => dispatch =>
  Agency(dispatch, 'locations', 'agency_location').then(cb)

export const getAgencyEvents = cb => dispatch =>
  Agency(dispatch, 'events', 'event').then(cb)

export const getAgencyFaqs = cb => dispatch =>
  Agency(dispatch, 'faqs', 'faq').then(cb)

export const getAgencyAlerts = cb => dispatch =>
  Agency(dispatch, 'alerts', 'alert', null, true).then(cb)

export const setAgencyAlert = payload => ({
  type: T.SET_AGENCY_ALERT,
  payload,
})

export const getAgencyServices = () => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get(SERVICES_URL)

    dispatch({
      type: T.GET_AGENCY_SERVICES,
      payload: data.services,
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const getDeliveries = cb => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get(DELIVERY_URL)

    dispatch({
      type: T.GET_DELIVERIES,
      payload: data.records,
    })

    typeof cb == 'function' && cb(true)
  } catch (err) {
    dispatch(createError(err))
    typeof cb == 'function' && cb(false)
  }
}

export const createDelivery = (delivery, cb) => async dispatch => {
  dispatch(getRequest)

  try {
    await $app.axios.post(DELIVERY_URL, { delivery })
    await dispatch(getDeliveries())
    toastr.success('Success', 'Delivery successfully created')

    cb && cb.call && cb()
  } catch (err) {
    dispatch(createError(err))
  }
}

export const addDelivery =
  (delivery_id, service, form_ids, cb) => async dispatch => {
    dispatch(getRequest)

    try {
      const url = [DELIVERY_URL, delivery_id, 'bulk_add_form'].join('/')
      await $app.axios.patch(url, { service, form_ids })

      dispatch({
        type: T.ADD_DELIVERY,
      })

      cb && cb.call && cb()
    } catch (err) {
      dispatch(createError(err))
    }
  }

export const removeDelivery =
  (delivery_id, service, form_ids, cb) => async dispatch => {
    dispatch(getRequest)

    try {
      const url = [DELIVERY_URL, delivery_id, 'bulk_remove_form'].join('/')
      await $app.axios.patch(url, { service, form_ids })

      dispatch({
        type: T.REMOVE_DELIVERY,
      })

      cb && cb.call && cb()
    } catch (err) {
      dispatch(createError(err))
    }
  }

export const closeDelivery = (delivery_id, cb) => async dispatch => {
  dispatch(getRequest)

  try {
    const url = [DELIVERY_URL, delivery_id, 'submit'].join('/')
    await $app.axios.patch(url)
    toastr.success('Success', 'Delivery batch successfully closed')
    cb?.call && cb(true)
  } catch (err) {
    toastr.error('Error', 'Failed to close delivery')
    dispatch(createError(err))
    cb?.call && cb(false)
  }

  dispatch({
    type: T.CLOSE_DELIVERY,
    payload: delivery_id,
  })
}

export const receiveDelivery = id => ({
  type: T.RECEIVE_DELIVERY,
  payload: id,
})

export const pickupDelivery = (delivery_id, form_id) => ({
  type: T.PICKUP_DELIVERY,
  payload: [delivery_id, form_id],
})

export const receiveLostPackage = payload => ({
  type: T.RECEIVE_PACKAGE,
  payload,
})

export const getCalendarEvents = () => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get(CALENDAR_URL)

    dispatch({
      type: T.GET_CALENDAR_EVENTS,
      payload: data.event,
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const getFeedback = cb => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get(FEEDBACK_URL + '/feedback_all')

    dispatch({
      type: T.GET_AGENCY_FEEDBACK,
      payload: data.feedback,
    })

    cb && cb.call && cb(data)
  } catch (err) {
    dispatch(createError(err))
    cb && cb.call && cb(false)
  }
}

export const submitFeedback = (service, review, cb) => async dispatch => {
  const feedback = {
    service,
    ...review,
  }

  const { data } = await $app.axios.post(FEEDBACK_URL + '/feedback', {
    feedback,
  })

  window.sessionStorage.setItem(
    'user',
    JSON.stringify({
      ...JSON.parse(window.sessionStorage.getItem('user')),
      hide_feedback: data.hide_feedback,
    })
  )

  typeof cb == 'function' && cb(true)
}
