import $ from 'jquery'

const { Circles, Chart } = window

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'october',
  'November',
  'December',
]

$(() => {
  $('.collapse-link').on('click', ev => {
    const el = $(ev.currentTarget).children()[2]

    if ($(el).hasClass('ct-0')) {
      $(el).removeClass('ct-0').addClass('ct-180')
    } else {
      $(el).removeClass('ct-180').addClass('ct-0')
    }
  })
})

export function staticalCircle (getcounts = {}) {
  const common = {
    radius: 45,
    width: 7,
    duration: 400,
    wrpClass: 'circles-wrp',
    textClass: 'circles-text',
    styleWrapper: true,
    styleText: true
  }

  Circles.create({
    ...common,
  
    id: 'circles-1',
    value: getcounts.total_submissions,
    maxValue: getcounts.total_submissions,
    text: getcounts.total_submissions || "0",
    colors: ['#f1f1f1', '#FF9E27'],
  });

  Circles.create({
    ...common,

    id: 'circles-2',
    value: getcounts.pending_submissions,
    maxValue: getcounts.total_submissions,
    text: getcounts.pending_submissions || "0",
    colors: ['#f1f1f1', '#2BB930'],
  })

  Circles.create({
    ...common,
    id: 'circles-3',
    value: getcounts.completed_submissions,
    maxValue: getcounts.total_submissions,
    text: getcounts.completed_submissions || "0",
    colors: ['#f1f1f1', '#F25961'],
  })

  Circles.create({
    ...common,

    id: 'task-complete',
    radius: 50,
    value: 80,
    maxValue: 100,
    width: 5,
    text: v => v + '%',
    colors: ['#36a3f7', '#fff'],
  })
}

export function incomeChart (data) {
  const fn = () => {
    const el = document.getElementById('totalIncomeChart')

    if (!el) return

    const ctx = el.getContext('2d')
    const cfg = {
      type: 'bar',
      data: {
        labels: Object.keys(data),
        datasets: [{
          label: "Total Income",
          backgroundColor: '#ff9e27',
          borderColor: 'rgb(23, 125, 255)',
          data: Object.values(data),
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false //this will remove only the label
            },
            gridLines: {
              drawBorder: false,
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              drawBorder: false,
              display: false
            }
          }]
        },
      }
    }

    new Chart(ctx, cfg)
  }

  setTimeout(fn, 500)
}

export function userStaticChart () {
  const fn = () => stats(sales)

  setTimeout(fn, 300)

  function stats (cb) {
    const el = document.getElementById('statisticsChart')

    if (!el) return

    const ctx = el.getContext('2d')
    const cfg = {
      type: 'line',
      data: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{
          label: "Subscribers",
          borderColor: '#f3545d',
          pointBackgroundColor: 'rgba(243, 84, 93, 0.6)',
          pointRadius: 0,
          backgroundColor: 'rgba(243, 84, 93, 0.4)',
          legendColor: '#f3545d',
          fill: true,
          borderWidth: 2,
          data: [154, 184, 175, 203, 210, 231, 240, 278, 252, 312, 320, 374]
        }, {
          label: "New Visitors",
          borderColor: '#fdaf4b',
          pointBackgroundColor: 'rgba(253, 175, 75, 0.6)',
          pointRadius: 0,
          backgroundColor: 'rgba(253, 175, 75, 0.4)',
          legendColor: '#fdaf4b',
          fill: true,
          borderWidth: 2,
          data: [256, 230, 245, 287, 240, 250, 230, 295, 331, 431, 456, 521]
        }, {
          label: "Active Users",
          borderColor: '#177dff',
          pointBackgroundColor: 'rgba(23, 125, 255, 0.6)',
          pointRadius: 0,
          backgroundColor: 'rgba(23, 125, 255, 0.4)',
          legendColor: '#177dff',
          fill: true,
          borderWidth: 2,
          data: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610, 700, 900]
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          bodySpacing: 4,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        layout: {
          padding: { left: 5, right: 5, top: 15, bottom: 15 }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontStyle: "500",
              beginAtZero: false,
              maxTicksLimit: 5,
              padding: 10
            },
            gridLines: {
              drawTicks: false,
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 10,
              fontStyle: "500"
            }
          }]
        },
        legendCallback: function (chart) {
          var text = [];
          text.push('<ul class="' + chart.id + '-legend html-legend">');
          for (var i = 0; i < chart.data.datasets.length; i++) {
            text.push('<li><span style="background-color:' + chart.data.datasets[i].legendColor + '"></span>');
            if (chart.data.datasets[i].label) {
              text.push(chart.data.datasets[i].label);
            }
            text.push('</li>');
          }
          text.push('</ul>');
          return text.join('');
        }
      }
    }

    const chart = new Chart(ctx, { ...cfg })

    const legend = document.getElementById("myChartLegend")
    legend.innerHTML = chart.generateLegend()

    cb()
  }

  function sales () {
    const ctx = document.getElementById('dailySalesChart').getContext('2d')
    const cfg = {
      type: 'line',
      data: {
        labels: months,
        datasets: [{
          label: "Sales Analytics", fill: true, backgroundColor: "rgba(255,255,255,0.2)", borderColor: "#fff", borderCapStyle: "butt", borderDash: [], borderDashOffset: 0, pointBorderColor: "#fff", pointBackgroundColor: "#fff", pointBorderWidth: 1, pointHoverRadius: 5, pointHoverBackgroundColor: "#fff", pointHoverBorderColor: "#fff", pointHoverBorderWidth: 1, pointRadius: 1, pointHitRadius: 5, data: [65, 59, 80, 81, 56, 55, 40, 35, 30]
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        animation: {
          easing: "easeInOutBack"
        },
        scales: {
          yAxes: [{
            display: false, ticks: {
              fontColor: "rgba(0,0,0,0.5)", fontStyle: "bold", beginAtZero: true, maxTicksLimit: 10, padding: 0
            },
            gridLines: {
              drawTicks: false, display: false
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: -20, fontColor: "rgba(255,255,255,0.2)", fontStyle: "bold"
            }
          }]
        }
      }
    }

    new Chart(ctx, cfg);
  }
}

export function providerChart () {
  const fn = () => {
    const el = document.getElementById('topProductsChart')

    if (!el) return

    const ctx = el.getContext('2d')
    const cfg = {
      type: "line",
      data: {
        labels: [
          ...months,
          ...months,
          ...months,
          ...months.slice(0, 4)
        ],
        datasets: [{
          label: "Sales Analytics",
          fill: true,
          backgroundColor: "rgba(53, 205, 58, 0.2)",
          borderColor: "#35cd3a",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0,
          pointBorderColor: "#35cd3a",
          pointBackgroundColor: "#35cd3a",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#35cd3a",
          pointHoverBorderColor: "#35cd3a",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 5,
          data: [20, 10, 18, 14, 32, 18, 15, 22, 8, 6, 17, 12, 17, 18, 14, 25, 18, 12, 19, 21, 16, 14, 24, 21, 13, 15, 27, 29, 21, 11, 14, 19, 21, 17]
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        animation: {
          easing: "easeInOutBack"
        },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              fontColor: "rgba(0,0,0,0.5)",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 10,
              padding: 0
            },
            gridLines: {
              drawTicks: false,
              display: false,
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: -20,
              fontColor: "rgba(255,255,255,0.2)",
              fontStyle: "bold",
            }
          }]
        }
      }
    }

    new Chart(ctx, cfg)
  }

  setTimeout(fn, 300)
}

export function labelError(isValid, input) {
  input && $(input)
    .parents()
    .eq(3)
    .find('label')
    .toggleClass('label_error', !isValid)
}