import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'
import islands from '@constants/islands'
export default class FlightCrew extends React.Component {
  resource = '/flight_crews'
  form_key = 'flight_crew'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'made_for',
        label: 'Application is hereby made for renwal',
        type: 'select:bool'
    },
    {
        name: 'reissue',
        label: 'Reissuance of the following Bahamas Airman License',
        multi: true,
        options: [
            'Student Pilot',
            'Commercial Pilot',
            'Flight Engineer',
            'Private Pilot',
            'Airline Transport Pilot',
            'Flight Instructor',
            'Other',
        ]
    },
    {
        heading: <p>License Or Rating Applied For On Basis Of:</p>
    },
    {
        name: 'complete',
        label: 'Completion Of Required Written And/or Practical Test'
    },
    {
        name: 'aircaft_used',
        label: 'Aircraft to be used(If flight test required)',
    },
    {
        name: 'total_time',
        label: 'Total Time in This Aircraft/SIM/FTD',
        type: 'integer',
        hint: 'hours'
    },
    {
        name: 'pilot',
        label: 'Pilot-In-Command',
        type: 'integer',
        hint: 'hours'
    },
    {
        name: 'cat_class',
        label: 'The following category and/or class involved:',
        multi: true,
        options: [
            'Aeroplane – Single Engine Land', 
            'Glider',
            'Aeroplane – Multiengine Land',
            'Rotorcraft',
            'Aeroplane – Single Engine Sea', 
            'Lighter Than Air',
            'Aeroplane – Multiengine Sea', 
            'Powered Lift',
        ]
    },
    {
        name: 'rating',
        label: 'The fllowing rating is involved:',
        multi: true,
        options: [
                'Instrument',
                'Added Type Rating',
                'Category Ii Or Iii Approaches',
                'Other Rating',
            ]
    },
    "::Airman Personal Information",
    {
        name: 'name',
        label: 'Name(Last. First, Middle)'
    },
    {
        name: 'perm_address',
        label: 'Permanent Address',
        hint: 'Street or PO Box Number'
    },
    {
        name: 'tele_fax',
        label: 'Telehpone/Fax/Cellular',
        type: 'phone'
    },
    {
        name: 'city'
    },
    {
        name: 'island',
        label: 'Island/State/Providence',
        options: islands
    },
    {
        name: 'mail_code'
    },
    {
        name: 'passport',
        label: 'Passport Number & State of Issue',
        type: 'textarea'
    },
    {
        name: 'country',
        options: countries
    },
    {
        name: 'dob',
        label: 'Date of Birth',
        type: 'date',
        showDropdownYear: true
    },
    {
        name: 'birth_country',
        label: 'Country of Birth',
        options: countries
    },
    {
        name: 'birth_place',
        label: 'Place of Birth',
        options: countries
    },
    {
        name: 'nationality',
        label: 'Nationality(Citizenship)',
        options: nationalities
    },
    {
        name: 'lang_pro',
        label: 'Language Proficiency Formal Evaluation Results',
        type: 'select:bool'
    },
    {
        name: 'height',
        hint: 'In'
    },
    {
        name: 'weight',
        hint: 'Lbs'
    },
    {
        name: 'eyes'
    },
    {
        name: 'sex',
        options: ['male', 'female']
    },
    {
        name: 'email',
        label: 'E-mail Address',
        type: 'email'
    },
    "::Medical Evaluation Information",
    {
       name: 'cert_class',
       label: 'Class of Certificate'
    },
    {
        name: 'state_iss',
        label: 'State of Issue'
    },
    {
        name: 'issue_date',
        label: 'Date of Issue',
        type: 'date',
        showDropdownYear: true
    },
    {
        name: 'med_exam',
        label: 'Medical Examiner'
    },
    {
        name: 'revoked',
        label: 'Has Your License Ever Been Suspended/revoked Or Have You Failed A Test For This License Or Rating?',
        type: 'select:bool'
    },
    {
        name: 'explain',
        label: 'If YES, attach explanation',
        type: 'textarea',
        hide: form.revoked != true
    },
    {
        name: 'photo_upload',
        label: 'Attach Applicant Photo Here',
        key: 'image.0',
        type: 'file:image'
    }
    
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
