import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";

export default class BahamianFishingVessel extends React.Component {
  id = "CommercialFishingBahamian";
  service = "commercial fishing bahamian";
  title = "Permit to Use a Bahamian Fishing Vessel for Commercial Purposes";
  agency = "moa";
  resource = "/commercial_fishing_bahamians";
  form_key = "commercial_fishing_bahamian";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "vessel_name",
      label: "Name of Vessel",
    },
    {
      name: "vessel_desc",
      label: "Descripton of Vessel",
    },
    {
      name: "vessel_reg_numb",
      label: "Registration Number of Vessel",
      is: "integer",
    },
    {
      name: "registration_port",
      label: "Port of Registration of Vessel",
      options: islands,
    },
    {
      name: "persons_on_board",
      label: "Number of Persons to be carried on board vessel",
      is: "integer",
    },
    {
      name: "fishing_gear_type",
      label: "Type of fishing gear to be used for fishing",
    },
    {
      name: "fishing_gear_desc",
      label: "Description of fishing gear to be used for fishing",
      type: "textarea",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
