/**
 * The above code contains various functions related to updating and managing security guard and
 * inquiry agent license applications, including updating payment, marking as vetted, forwarding to
 * SIB, recommending to under/ permanent secretary or minister, appealing application, and adding
 * permit notes.
 */
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import ENV from '@constants/env'
import { fullName } from '@modules/form-wizard/utils'

/**
 * This function returns an array of objects with properties related to security columns, including a
 * "SIB Recommended?" column that is only visible to certain user roles and displays a recommendation
 * status.
 */
export const SECURITY_COLUMNS = () => [
  {
    name: 'SIB Recommended?',
    omit: !$app.hasAnyRole(
      'officer',
      'senior_officer',
      'under_secretary',
      'permanent_secretary',
      'sib_officer'
    ),
    sortable: true,
    selector: row => {
      switch (row.sib_recommendation_status) {
        case 'approved':
          return 'YES'
        case 'denied':
          return 'NO'
        default:
          return 'PENDING'
      }
    }
  },
]


/**
 * This function updates the payment amount for an application and sends an email notification to the
 * user.
 * @param record - The `record` parameter is an object that contains information about a specific
 * record or application. It likely includes details such as the user's name, email, and payment
 * amount.
 * @param resource - The `resource` parameter is a string that represents the API endpoint or resource
 * that the `updatePayment` function will send a PUT request to in order to update the payment amount
 * for a specific application.
 * @param form_key - The `form_key` parameter is a key used to identify the form number in the request
 * payload when updating the payment amount. It is used to specify which form's payment amount should
 * be updated.
 */
export const updatePayment = async (record, resource, form_key) => {
  const { isConfirmed } = await Swal.fire({
    title: 'Set Payment Amount',
    text: 'By clicking "Apply Payment", you will be setting the payment amount for this application. Do you want to proceed?',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Apply Payment',
  })

  if (isConfirmed) {
    const message = payment => `
        Good day ${record.user.first_name.capitalize()},
        <br />
        We are pleased to inform you that your Security Guards and Inquiry Agents application has been approved for payment.
        <br /><br />
        Your service fee is as follows:
        <br /><br />
        Service Fee : <b>$${payment}</b><br />
        <br/><br />
        <a href='${ENV.PILOT_URL}'>Click here</a>
        to login and complete payment on the Portal.
        <br/><br />
        Thank you for using the MyGateway Portal.
      `

    try {
      const { data } = await $app.axios.put(
        resource + '/update_payment_amount',
        {
          [form_key]: {
            form_num: record.form_num,
          },
        }
      )

      const { payment_amount } = data?.inquiry_and_security_license

      payment_amount
        ? toastr.success(
          'Success',
          'Application has been approved for Payment.'
        )
        : toastr.error('Error', 'Unable to approve application for payment')

      try {
        const { data } = await $app.axios.post('/emails', {
          email: record.user.email,
          subject:
            'MyGateway Portal: Payment Approval for Inquiry and Security Guards Licence',
          message: message(Number(payment_amount).toFixed(2)),
        })

        data.message && !data.error
          ? toastr.success('Success', 'Email sent to citizen')
          : toastr.error('Error', data.message || data.error)
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email to citizen')
      }
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to set Approve for Payment')
    } finally {
    }
  }
}

/**
 * This function marks a pending application as vetted and updates its status based on the user's
 * input.
 * @returns A function is being returned.
 */
export function markAsVetted () {
  return {
    text: 'Mark As Vetted',
    icon: 'shoe-prints',
    test: r => r.application_decision == 'pending',
    roles: ['officer'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props
      
      const { isConfirmed, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Complete Vetting',
        text: `
          After having done your due diligence, what is the state of this
          applicant's criminal record?
        `,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Record Is Clean',
        denyButtonText: 'Conviction',
      })

      if (isDismissed) return

      const { value: license_numb } = await Swal.fire({
        icon: 'info',
        title: 'Set Licence Number',
        input: 'text',
        inputAutoTrim: true,
        inputPlaceholder: 'Licence #',
        text: `Please enter the applicant's licence number.`,
        preConfirm: val => {
          if (val) return
          
          Swal.showValidationMessage('Please enter a licence number')
          return false
        }
      })

      if (!license_numb) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            license_numb: license_numb.toUpperCase(),
            form_num: record.form_num,
            application_decision: isConfirmed ? 'approved' : 'denied',
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

/**
 * This function forwards an application to the SIB and issues a recommendation to the Minister of
 * National Security if certain conditions are met.
 * @returns A function that returns an object with properties such as text, icon, test, roles, and fn.
 */
export function forwardToSIB () {
  return {
    text: 'Forward To SIB',
    icon: 'eye',
    test: r => r.application_decision == 'officer viewed',
    roles: ['senior_officer'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props
      
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Forward To SIB',
        text: `
          Are you ready to forward this application to the SIB and issue a
          recommendation to the Minister of National Security?
        `,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      })

      if (!isConfirmed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

/**
 * This function recommends an applicant to the Under/Permanent Secretary and updates the application
 * decision in the database.
 * @returns A function is being returned.
 */
export function recommendToPS () {
  return {
    text: 'Recommend To Under/Permanent Secretary',
    icon: 'user-tie',
    roles: ['senior_officer'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend To Under/Permanent Secretary',
        html: `
          What is your recommendation to the Minister of National Security
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>?
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

/**
 * This function recommends an applicant to the Minister of National Security and updates the
 * application decision and comment in the database.
 * @returns A function that returns an object with properties `text`, `icon`, `roles`, and `fn`.
 */
export function recommendToMinister () {
  return {
    text: 'Recommend To Minister',
    icon: 'user-tie',
    roles: [['under_secretary', 'permanent_secretary', 'sib_officer']],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      let comment

      const { value, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend To Minister',
        html: `
          What is your recommendation to the Minister of National Security
          regarding the applicant,
          <strong>${fullName(record.user, 'initial')}</strong>
        `,
        input: 'select',
        inputOptions: {
          'approved': "I recommend this applicant",
          'denied': "I do not recommend this applicant",
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
      })

      if (isDismissed) return
      
      if (value == 'denied' && $app.hasRole('sib_officer')) {
        const { value, isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Enter ',
          html: `Please enter your reason(s) for not recommending the applicant`,
          input: 'textarea',
          showCancelButton: true,
          confirmButtonText: 'Send',
        })

        if (isDismissed) return
        comment = value
      }

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: value,
            comment: comment || 'No Issue Found'
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

/**
 * This function handles the appeal process for a denied application.
 * @returns A function that returns an object with properties such as `text`, `user`, `test`, `icon`,
 * and `fn`.
 */
export function appealApplication () {
  return {
    text: 'Appeal Application',
    user: true,
    test: r => r.application_decision == 'denied' && !r.attempted_appeal,
    icon: 'gavel',
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      const { isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Appeal Application',
        html: 'Are you sure that you want to appeal this application?',
        showCancelButton: true,
        confirmButtonText: 'Appeal',
      })

      if (isDismissed) return

      try {
        await $app.axios.post(resource, {
          [form_key]: {
            appeal: true,
            form_num: record.form_num,
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Appeal successfully sent')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}

/**
 * This function adds a permit note to a record and displays a success or error message.
 * @returns A function is being returned.
 */
export function addPermitNote () {
  return {
    text: 'Add Permit Note',
    icon: 'stamp',
    test: r => $app.hasAnyRole(['permanent_secretary', 'under_secretary']),
    fn: async record => {
      const { value: text } = await Swal.fire({
        input: 'textarea',
        title: 'Add Permit Note',
        inputAttributes: {
          autocorrect: 'on',
        },
        showCancelButton: true,
        confirmButtonText: 'Next',
        inputValidator: v => (!!v ? null : 'Note cannot be empty'),
      })

      try {
        const { data } = await $app.axios.post('/services/notes/add_note', {
          note: {
            notable_type: this.props.service.type,
            note_type: 'permit',
            internal_view_only: true,
            notable_id: record.id,
            text: text.trim(),
          },
        })

        record.notes = record.notes || []
        record.notes.push(data.note)

        toastr.success('Success', 'Permit Note successfully created')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to create new permit note')
      }
    },
  }
}
