import React from "react";
import SummerEmploymentBase from "../summer-employment-base";

export default class SummerEmploymentYouth extends React.Component {
  render = () => {
    const { props } = this

    const aprops = {
      resource: '/summer_employments',
      form_key: 'summer_employment',
      _sid: 'youth summer employment',
      service: props.service,
      form_name: 'Summer Employment (Youth)',
      ...props,
    }

    return (
      <>
        <SummerEmploymentBase {...aprops}/>
      </>
    )
  }
}

