import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'

/**
 * NOTE: Fish are not separated by sex;
 * Need to make a separate list for these species
*/

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import convertToBase64 from '@helpers/file-to-base64'
import education_levels from '@constants/education_levels'
import islands from '@constants/islands'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'
import { hasAny } from '@helpers/arrays'
import nationalities from '@constants/nationalities'
import { inspectionFields } from './farm-inspection/form.inspection'

const _18Years = moment().subtract(18, 'years').toDate()

export const ownerTableColumns = (form, key, viewing = false) => [
  {
    name: 'is_bahamian',
    label: 'Is Bahamian?',
    type: 'select:bool',
    hide: viewing,
    required: false,
    callback: val => {
      if (!val && (key == 'owner_details' || key == 'co_owner_details'))
        showAdditionalInfoNeededPopup()
    }
  },
  {
    name: 'nib',
    label: 'NIB#',
    is: 'integer',
    hide: index => !form[key][index].is_bahamian,
    required: false,
  },
  {
    name: 'first_name',
    required: false,
  },
  {
    name: 'last_name',
    required: false,
  },
  {
    name: 'sex',
    required: false,
    options: [
      { label: 'Male', value: 'M' },
      { label: 'Female', value: 'F' },
    ],
  },
  {
    name: 'dob',
    required: false,
    label: 'D.O.B',
    type: 'date',
    showYearDropdown: true,
    maxDate: _18Years,
  },
  {
    name: 'level_of_education',
    required: false,
    label: 'Highest Education',
    options: education_levels,
  },
  {
    name: 'passport_upload',
    label: 'Copy of Passport',
    type: 'file:image',
    hide: index => form[key][index].is_bahamian,
    required: false,
  },
  {
    name: 'residency_card_upload',
    label: 'Copy of Residency Card',
    type: 'file:image',
    hide: index => form[key][index].is_bahamian,
    required: false,
  }
]

export const commonLivestock = [
  'Cattle',
  'Swine',
  'Goats',
  'Sheep',
  'Rabbits',
  'Poultry',
]

export const chickenCategory = {
  name: 'production_category',
  required: false,
  datalist: [
    'Broliers',
    'Layers',
    'Yard Chickens',
    'Ducks',
    'Turkeys',
  ],
}

export const lastYearRevenue = {
  name: 'last_year_earning',
  label: `Last Year's Earnings ($)`,
  is: 'currency',
}

export const precentSoldLocally = {
  name: 'sold_locally',
  label: '% Sold Locally',
  is: 'float',
}

export const numSoldDirectlyToBusinesses = {
  name: 'direct_to_business',
  label: '% Sold To Businesses',
  is: 'float',
}

export const numSoldDirectlyToWholesalers = {
  name: 'direct_to_wholsesale',
  label: '% Sold Wholesale',
  is: 'float',
}

export const numUnsold = {
  name: 'home_user_or_not_sold',
  label: '% Unsold/Used For Home',
  is: 'float',
}

export const waterSources = [
  'Well',
  'City/Municipal',
  'Rain',
  'Other',
]

export const earningsAndCounts = form => [
  precentSoldLocally,
  numSoldDirectlyToBusinesses,
  numSoldDirectlyToWholesalers,
  numUnsold,
]

export const showAdditionalInfoNeededPopup = (manager = false) => {
  const message = `
    Please be advised that you will be required to upload an approval letter
    from the Bahamas Investment Authority and a clear image of your residency
    card from Immigration
  `

  Swal.fire('Info', message, 'info')
}

export const disableAdjacentOwnershipOptions = (key = '') => {
  const options = ['farm_owner', 'co_owner', 'manager', 'family']

  if ($app.applicant.country_of_citizenship != 'bahamas' && (
    key == 'farm_owner' || key == 'co_owner'
  )) showAdditionalInfoNeededPopup()

  return bool => {
    if (!bool) return

    return options.reduce((o, k) => {
      o[k] = k == key
      return o
    }, {})
  }
}

export default class FarmerRegistration extends React.Component {
  resource = '/farm_registrations'
  form_key = 'farm_registration'
  free = true

  isRenewal = /-renewal/.test(window.location.pathname)

  custom_acceptance = true

  defaults = {
    "renewal": /-renewal/.test(this.props?.location.pathname),
    "current_other_species_info": [{ total_hives: '' }],

    "acres_burned": "",
    "acres_under_cultivation": "",
    "affected_by_disease": false,
    "affected_by_drought": false,
    "affected_by_fires": false,
    "affected_by_flooding": false,
    "affected_by_hurricanes": false,
    "affected_by_pests": false,
    "affected_by_wind": false,
    "agritourism": false,
    "agritourism_activities": "",
    "chemicals_used": [],
    "co_owner": false,
    "common_diseases": "",
    "cottage_processor": false,
    "declare": false,
    "family": false,
    "farm_island": "New Providence",
    "farm_latitude": "",
    "farm_location": "",
    "farm_longitude": "",
    "farm_owner": true,
    "farm_size": "",
    "farming_type": [],
    // "farming_type_other": "",
    "hurricane_preparedness_plan": false,
    "insecticide_spray": false,
    "land_ownership": "",
    "last_registration_date": "",
    "lease_agent": "",
    "manager": false,
    "manufactured_products": [],
    "nationality_of_workers": "",

    "po_box": "",
    "slash_burn_farming": false,
    "theft": false,
    "waste_disposal_method_liquids": "",
    "worker_work_permits": "",
    "phone_number": "",

    "crop_market": [
      {
        "crop": "",
        "last_year_earning": "",
        "sold_locally": "",
        "sold_packing_house_BAMSI": "",
        "direct_to_wholsesale": "",
        "donated": "",
        "home_not_sold": "",
        "wasted": "",
        "other": ""
      }
    ],
    "livestock": [
      {
        "species": "",
        "males_slaughtered_last_year": "",
        "females_slaughtered_last_year": "",
        "dress_weight_of_slaughterd": "",
        "avg_price_per_lb": ""
      }
    ],
    "poultry_eggs": [
      {
        "production_category": "",
        "number_of_eggs_collected_last_year_by_dozens": "",
        "poultry_slaughtered_last_year": ""
      }
    ],
    "current_poultry_eggs": [
      {
        "production_category": "",
        "total_number_of_birds": ""
      }
    ],
    "poultry_eggs_market": [
      {
        "production_category": "",
        "last_year_earning": "",
        "sold_locally": "",
        "direct_to_business": "",
        "direct_to_wholsesale": "",
        "home_user_or_not_sold": ""
      }
    ],
    "livestock_market": [
      {
        "species": "",
        "last_year_earning": "",
        "sold_locally": "",
        "direct_to_business": "",
        "direct_to_wholsesale": "",
        "home_user_or_not_sold": ""
      }
    ],
    "livestock_health": [
      {
        "species": "",
        "number_of_births_last_12_months_male": "",
        "number_of_births_last_12_months_female": "",
        "number_of_deaths_last_12_months_male": "",
        "number_of_deaths_last_12_months_female": "",
        "common_diseases": ""
      }
    ],
    "sanitation_livestock": [
      {
        "species": "",
        "cleaning_water_source": "",
        "drinking_water_source": "",
        "electricity_source": "",
        "waste_disposal_method_liquids": ""
      },
      {
        "species": "",
        "cleaning_water_source": "",
        "drinking_water_source": "",
        "electricity_source": "",
        "waste_disposal_method_liquids": ""
      },
    ],
    "current_crop_info": [
      {
        "growth_type": "",
        "variety": "",
        "acres": "",
        "number_of_plants": "",
        "yield_last_year": "",
        "crops_chemicals_used": [""],
        "irrigation_type_used": ""
      }
    ],
    "current_livestock_info": [
      {
        "species": "",
        "total_animals_male": "",
        "total_animals_female": "",
        "hormones_used": "",
        "antibiotics_used": ""
      }
    ],
  }

  skip_location = true
  hide_buttons = {
    status: true,
    deny: !$app.hasRole('director')
  }

  custom_actions = [
    forwardToDirector.call(this),
    {
      text: 'Approve Application',
      icon: 'check',
      roles: ['director'],
      fn: async record => {
        const { props, resource, form_key } = this
        const { history, location } = props
        const { axios } = $app

        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: `Approve Application`,
          html: `
            Are you sure that you want to approve this application belonging to
            &nbsp;<strong>${fullName(record.user, 'initial')}</strong>?
          `,
          showCancelButton: true,
          confirmButtonText: 'Approve',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        try {
          await axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          Swal.fire(
            'Success!',
            'The application has been approved',
            'success'
          )
        } catch (error) {
          console.error(error)
          Swal.fire(
            'Oops...',
            'There has been an error with processing your application',
            'error'
          )
        } finally {
          this.setState({ loading: false })
        }
      },
    },
  ]

  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
      },
    },
  }

  hide_columns = ['Name', 'Date']
  columns = props => [
    {
      name: 'Name',
      omit: props.citizen,
      sortable: true,
      cell: r => r.inspector_copy ? (
        <span onClick={props.view(r)} data-action>
          [Inspector Copy]: {`${r.user?.last_name}, ${r.user?.first_name}`.capitalize()}
        </span>
      ) : (
        <span onClick={props.view(r)} data-action>
          {`${r.user?.last_name}, ${r.user?.first_name}`.capitalize()}
        </span>
      ),
    },
    {
      name: 'App. Date',
      selector: row => moment(row.created_at).format('YYYY-MM-DD'),
      sortable: true,
      width: '180px',
      sortFunction: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
      name: 'Insp. Date',
      selector: row => row.inspector_form_copy
        ? moment(row.inspector_form_copy.created_at).format('YYYY-MM-DD')
        : 'N/A',
      width: '180px',
    },
  ]

  validate = async form => {
    const {
      current_livestock_info,
      farming_type,
      other_species: bees,
      other_species_market: beesM,
    } = form

    const update = {}
    const other_species = []
    const other_species_market = []

    if (farming_type?.includes('apiary') && bees?.length) {
      other_species.push(
        ...bees.map(row => ({ species: 'bees', ...row }))
      )

      other_species_market.push(
        ...beesM.map(row => ({ species: 'bees', ...row }))
      )
    }

    if (form.owner_details[0]) {
      update.owner_details = []

      for (let row of form.owner_details) {
        const {
          passport_upload,
          residency_card_upload,
          ...item
        } = row

        if(passport_upload){item.passport_upload = await convertToBase64(passport_upload);}
        if(residency_card_upload){item.residency_card_upload = await convertToBase64(residency_card_upload);}
        update.owner_details.push(item);
      }
    }

    if (form.co_owner_details[0]) {
      update.co_owner_details = []

      for (let row of form.co_owner_details) {
        const {
          passport_upload,
          residency_card_upload,
          ...item
        } = row

        if(passport_upload){item.passport_upload = await convertToBase64(passport_upload);}
        if(residency_card_upload){item.residency_card_upload = await convertToBase64(residency_card_upload);}
        update.co_owner_details.push(item);
      }
    }

    if (form.manager_details[0]) {
      update.manager_details = []

      for (let row of form.manager_details) {
        const {
          passport_upload,
          residency_card_upload,
          ...item
        } = row

        if(passport_upload){item.passport_upload = await convertToBase64(passport_upload);}
        if(residency_card_upload){item.residency_card_upload = await convertToBase64(residency_card_upload);}
        update.manager_details.push(item);
      }
    }

    // console.log('Update', update);

    return {
      ...update,
      other_species,
      other_species_market,
      current_livestock_info: current_livestock_info.map(row => {
        const { total_animals_male, total_animals_female, ...r } = row
        const total_number_animals =
          Number(total_animals_male) + Number(total_animals_female)

        return {
          ...r,
          total_number_animals,
          total_animals_by_sex: {
            male: total_animals_male,
            female: total_animals_female,
          },
        }
      }),
    }
  }

  fields = (form = {}, viewing = false, isCopy = false) => {
    const growthTypes = getAvailableGrowthTypes(form.farming_type)
    const hasForeignOwner =
      ($app.applicant.country_of_citizenship != 'bahamas'
        && (form.farm_owner || form.co_ownere)
      )
      || form.owner_details?.some(r => r.is_bahamian === false)
      || form.co_owner_details?.some(r => r.is_bahamian === false)

    return [
      ...(isCopy ? [] : BUSINESS_FIELDS(form)),
      {
        name: 'inspector_form_copy',
        type: 'linked',
        hide: form.inspector_copy,
        fields: sub => this.fields(sub, true, true)
      },
      {
        name: 'farm_inspection_form',
        label: 'Farm Observation Form',
        type: 'linked',
        hide: !!form.farm_registration_id,
        fields: inspectionFields,
      },
      {
        name: 'renewal',
        type: 'select:bool',
        hide: true,
        save: true,
      },
      {
        name: 'organization_type',
        hide: isCopy || form.client_type != 'Organization',
        options: [
          { name: 'Co-operative', label: 'Co-operative' },
          'Church',
          'School',
          'Other',
        ]
      },
      {
        name: 'po_box',
        hide: isCopy,
        label: 'P. O. Box',
        required: false,
      },
      {
        name: 'phone_number',
        hide: isCopy,
        is: 'phone',
        use_profile: 'additional_phone_number',
      },

      {
        heading: 'Ownership Details',
        hide: isCopy,
      },
      {
        name: 'farm_owner',
        hide: isCopy,
        type: 'checkbox',
        label: 'I am the owner of this farm',
        required: false,
        callback: () => disableAdjacentOwnershipOptions('farm_owner'),
        view: {
          label: 'Is Owner?',
        },
      },
      {
        name: 'co_owner',
        hide: isCopy,
        type: 'checkbox',
        label: 'I am the co-owner of this farm',
        required: false,
        callback: () => disableAdjacentOwnershipOptions('co_owner'),
        view: {
          label: 'Is Co-Owner?',
        },
      },
      {
        name: 'manager',
        hide: isCopy,
        type: 'checkbox',
        label: 'I am a manager of this farm',
        required: false,
        callback: () => disableAdjacentOwnershipOptions('manager'),
        view: {
          label: 'Is Manager?',
        },
      },
      {
        name: 'family',
        hide: isCopy,
        type: 'checkbox',
        label: 'This farm is owned by a family member',
        required: false,
        callback: () => disableAdjacentOwnershipOptions('family'),
        view: {
          label: 'Is Family Farm?',
        },
      },
      {
        name: 'owner_details',
        fields: ownerTableColumns(form, 'owner_details', viewing),
        rowLabel: 'Owner',
        addLabel: 'Add Owner',
        hide: isCopy || viewing
          ? !form.owner_details?.[0]?.first_name
          : form.farm_owner,
        view: {
          format: list => list.map(row => ({
            ...row,
            dob: row.dob ? new Date(row.dob).toISOString().slice(0, 10) : null
          }))
        }
      },
      {
        name: 'co_owner_details',
        fields: ownerTableColumns(form, 'co_owner_details', viewing),
        rowLabel: 'Co-Owner',
        addLabel: 'Add Co-Owner',
        required: false,
        hide: isCopy,
        view: {
          format: list => list.map(row => ({
            ...row,
            dob: row.dob ? new Date(row.dob).toISOString().slice(0, 10) : null
          }))
        }
        // hide: viewing
        //   ? !form.co_owner_details?.[0]?.first_name
        //   : form.co_owner,
      },
      {
        name: 'manager_details',
        fields: ownerTableColumns(form, 'manager_details', viewing),
        rowLabel: 'Manager',
        addLabel: 'Add Manager',
        required: false,
        hide: isCopy || viewing && !form.manager_details?.[0]?.first_name,
        view: {
          format: list => list.map(row => ({
            ...row,
            dob: row.dob ? new Date(row.dob).toISOString().slice(0, 10) : null
          }))
        }
      },

      {
        heading: 'Farm Location Details',
        hide: isCopy
      },
      {
        name: 'farm_street_address',
        label: 'Street Address',
        required: false,
        hide: isCopy,
      },
      {
        name: 'farm_settlement',
        label: 'Settlement/Subdivision',
        hide: isCopy,
      },
      {
        name: 'farm_island',
        label: 'Island',
        options: islands,
        hide: isCopy,
      },
      {
        name: 'farm_longitude',
        label: 'Longitude',
        is: 'float',
        hide: isCopy,
      },
      {
        name: 'farm_latitude',
        label: 'Latitude',
        is: 'float',
        hide: isCopy,
      },
      {
        name: 'land_owned',
        hide: true,
        save: true,
        view: {
          value: () => {
            if (form.land_owned) return 'YES'
            if (form.lease_agent) return 'NO (LEASED)'
            if (form.land_use_approval_upload_file_name) return 'NO (GIVEN PERMISSION)'
            return 'NO'
          }
        }
      },
      {
        name: 'land_ownership',
        hide: isCopy,
        hint: `
          If selecting the "given permission" option, you will be required
          to upload a Letter of Permission later below.
        `,
        options: [
          {
            value: 'land_owned',
            label: 'I own the land'
          },
          {
            value: 'physical_lease',
            label: 'I am leasing the land'
          },
          {
            value: 'given_permission',
            label: 'I am using the land with permission'
          },
          {
            value: 'commonage_land',
            label: 'This is commanage land'
          },
        ]
      },
      {
        name: 'physical_lease',
        type: 'select:bool',
        label: 'Do you have a physical lease?',
        hide: form.land_ownership != 'physical_lease',
        view: {
          hide: form.land_owned,
          label: 'Physical Lease?'
        }
      },
      {
        name: 'lease_agent',
        hide: !form.physical_lease || form.land_ownership != 'physical_lease',
      },

      '::Farm Details',
      {
        name: 'farming_type',
        label: 'Type of Farming',
        hint: 'Select All That Apply',
        // format: v => v.join(', '),
        multi: true,
        options: [
          'nursery',
          'aquaponics',
          'hydroponics',
          'ornamental',
          'livestock',
          'crop',
          'apiary',
          'other',
        ]
      },
      // {
      //   name: 'farming_type_other',
      //   hide: !form.farming_type?.includes('other'),
      //   label: 'Please Specify',
      // },
      {
        name: 'farm_size',
        label: 'Size of Farm',
        hint: 'Acres'
      },
      {
        name: 'acres_under_cultivation',
        is: 'float',
        label: form.farming_type?.includes('apiary')
          ? 'Number of Hives/Colonies'
          : 'Acres Under Cultivation',
        hide: hasAny(form?.farming_type, [
          'apiary',
          'hydroponics',
          'aquaponics',
        ]),
      },
      {
        name: 'percentage_propagated',
        is: 'float',
        label: '% of Plants Propagated On-site',
        hide: !hasAny(form?.farming_type, [
          'ornamental',
          'nursery'
        ]),
      },

      '::Market Details',
      {
        name: 'crop_market',
        hide: !form.renewal || !form.farming_type?.includes(
          'crop',
          'hydroponics',
          'aquaponics'
        ),
        columns: [
          'crop',
          lastYearRevenue,
          precentSoldLocally,
          {
            name: 'sold_packing_house_BAMSI',
            label: '% Sold at BAMSI',
            is: 'float',
          },
          numSoldDirectlyToWholesalers,
          {
            name: 'donated',
            label: '% Donated',
            is: 'float',
          },
          {
            name: 'home_not_sold',
            label: '% Unsold',
            is: 'float',
          },
          {
            name: 'wasted',
            label: '% Wasted',
            is: 'float',
          },
          {
            name: 'other',
            label: 'Other',
            is: 'float',
          },
        ],
      },
      {
        name: 'livestock',
        hide: !form.renewal || !form.farming_type?.includes('livestock'),
        columns: [
          'species',
          {
            name: 'males_slaughtered_last_year',
            label: '# Males Slaughtered Last Year',
            is: 'integer',
          },
          {
            name: 'females_slaughtered_last_year',
            label: '# Females Slaughtered Last Year',
            is: 'integer',
          },
          {
            name: 'dress_weight_of_slaughterd',
            is: 'integer',
            label: `Total Slaughtered Dress Weight (lbs.) Last Year`,
          },
          {
            name: 'avg_price_per_lb',
            label: 'Avg. Price Per lb. ($) Last Year',
            is: 'currency',
          },
        ],
      },
      {
        name: 'poultry_eggs',
        hide: !form.renewal || !form.farming_type?.includes('livestock'),
        label: 'Poultry',
        columns: [
          chickenCategory,
          {
            name: 'number_of_eggs_collected_last_year_by_dozens',
            label: '# Eggs Collected (Dozens)',
            is: 'float',
          },
          {
            name: 'poultry_slaughtered_last_year',
            label: '# Poultry Slaughtered Last Year (lbs.)',
            is: 'float',
          },
        ],
      },
      {
        name: 'current_poultry_eggs',
        label: 'Current Poultry Production',
        required: false,
        hide: !form.farming_type?.includes('livestock'),
        columns: [
          chickenCategory,
          {
            name: 'total_number_of_birds',
            label: '# of Birds',
            is: 'integer',
            required: false,
          },
        ],
      },
      {
        name: 'poultry_eggs_market',
        hide: !form.renewal || !form.farming_type?.includes('livestock'),
        columns: [chickenCategory, ...earningsAndCounts(form)],
      },
      // {
      //   name: 'other_species',
      //   hide: true,
      //   save: true,
      //   view: true,
      // },
      {
        name: 'other_species_market',
        hide: true,
        save: true,
        view: true,
      },
      {
        name: 'other_species',
        save: false,
        hide: !form.farming_type?.includes('apiary') || !form.renewal,
        label: 'Bees',
        columns: [
          {
            name: 'honey_harvested_last_year',
            hint: 'In Gallons',
            is: 'float',
          },
          {
            name: 'beeswax_harvested_last_year',
            hint: 'In lbs.',
            is: 'float',
          },
        ]
      },
      {
        name: 'other_species_market',
        save: false,
        label: 'Bees Market',
        hide: !form.farming_type?.includes('apiary') || !form.renewal,
        columns: earningsAndCounts(form)
      },
      {
        name: 'livestock_market',
        hide: !form.renewal,
        columns: [
          {
            name: 'species',
          },
          ...earningsAndCounts(form),
        ],
      },
      {
        name: 'livestock_health',
        label: 'Health of Livestock',
        hide: !form.renewal || !form.farming_type?.includes('livestock'),
        rowLabel: 'Animal',
        fields: [
          {
            name: 'species',
            datalist: commonLivestock,
          },
          {
            name: 'number_of_males',
            label: '# of Males',
            is: 'integer',
            hide: i => /poultry/i.test(form.livestock_health[i]?.species)
          },
          {
            name: 'number_of_females',
            label: '# of Females',
            is: 'integer',
            hide: i => /poultry/i.test(form.livestock_health[i]?.species)
          },
          {
            name: 'number_of_births_last_12_months_male',
            label: 'Males Born in Last 12 Months',
            is: 'integer',
          },
          {
            name: 'number_of_births_last_12_months_female',
            label: 'Females Born in Last 12 Months',
            is: 'integer',
          },
          {
            name: 'number_of_deaths_last_12_months_male',
            label: 'Males Died in Last 12 Months',
            is: 'integer',
          },
          {
            name: 'number_of_deaths_last_12_months_female',
            label: 'Females Died in Last 12 Months',
            is: 'integer',
          },
          {
            name: 'common_diseases',
            label: 'Noted Illnesses/Diseases',
            type: 'textarea',
            required: false,
          }
        ]
      },
      {
        name: 'sanitation_livestock',
        hide: !form?.farming_type?.includes('livestock'),
        label: 'Sanitation of Livestock',
        // fixed: true,
        rowLabel: 'Animal',
        fields: [
          {
            name: 'species',
            datalist: commonLivestock,
          },
          {
            name: 'cleaning_water_source',
            label: 'Source of Clean Water',
            options: waterSources,
          },
          {
            name: 'cleaning_water_source_other',
            label: 'Please specify',
            hide: form.cleaning_water_source != 'Other',
          },
          {
            name: 'drinking_water_source',
            label: 'Source of Drinking Water',
            options: ['Vendor Purchased', ...waterSources],
          },
          {
            name: 'drinking_water_source_other',
            label: 'Please specify',
            hide: form.drinking_water_source != 'Other',
          },
          {
            name: 'electricity_source',
            label: 'Electricity Source',
            datalist: [
              'BPL',
              'Solar',
              'Generator'
            ]
          },
          {
            name: 'waste_disposal_method_liquids',
            label: 'Waste Disposal Method',
            type: 'textarea',
          },
        ],
      },
      {
        name: 'current_crop_info',
        label: 'Crops',
        hide: !hasAny(form.farming_type, ['crop', 'aquaponics', 'hydroponics']),
        rowLabel: 'Crop',
        addLabel: 'Add Crop',
        fields: [
          {
            name: 'growth_type',
            label: 'Method Of Growth',
            options: growthTypes
          },
          {
            name: 'variety',
            label: 'Fish Name',
          },
          {
            name: 'acres',
            is: 'float',
            hint: '',
            hide: i => ['ornamental', 'nursery'].includes(form.current_crop_info[i]?.growth_type)
          },
          {
            name: 'number_of_plants',
            label: 'Number of plants',
            is: 'integer',
          },
          {
            name: 'percentage_propogated',
            label: '% Propagated On-site',
            is: 'float',
            hide: i => ['ornamental', 'nursery'].includes(form.current_crop_info[i]?.growth_type)
          },
          {
            name: 'yield_last_year',
            label: `Last Year's Yield`,
            is: 'float',
            hint: 'In lbs.',
            hide: !form.renewal,
          },
          {
            name: 'crops_chemicals_used',
            label: 'Type(s) of Chemicals Used On Farm',
            // format: v => v?.split('\n')?.map(str => str.trim()),
            // multi: true,
            options: [
              'None',
              'Organic (O)',
              'Herbicide (H)',
              'Insecticide (I)',
              'Fungicide (F)',
              'Fertilizer (Fe)',
              'Biocide (B)',
              'Growth Regulator (G)'
            ]
          },
          {
            name: 'chemicals_used_list',
            hide: i => form.current_crop_info[i]?.crops_chemicals_used?.includes('None'),
            label: 'List of Chemicals Used',
            format: v => v?.split('\n')?.map(str => str.trim()),
            type: 'textarea',
            hint: 'Please separate each by a new line',
          },
          {
            name: 'irrigation_type_used',
            label: 'Irrigation System Used',
            options: [
              'Surface',
              'Drip',
              'Sprinkler',
              'Sub-irrigation',
              'Manual',
              'None',
            ],
          },
        ],
      },
      {
        name: 'current_fish_info',
        hide: !form.farming_type?.includes('aquaponics'),
        rowLabel: 'Fish',
        addLabel: 'Add Fish',
        fields: [
          {
            name: 'species',
          },
        ]
      },
      {
        name: 'current_livestock_info',
        hide: !form.farming_type?.includes('livestock'),
        label: 'Current Livestock',
        rowLabel: 'Animal',
        addLabel: 'Add Animal',
        fields: [
          {
            name: 'species',
          },
          {
            name: 'total_animals_male',
            label: 'Number of Males',
          },
          {
            name: 'total_animals_female',
            label: 'Number of Females',
          },
          {
            name: 'hormones_used_bool',
            label: 'Are you using any hormones?',
            type: 'select:bool',
          },
          {
            name: 'hormones_used',
            // hint: 'Leave blank if none are used',
            hide: index => !form.current_livestock_info[index].hormones_used_bool,
            type: 'textarea',
            view: {
              value: v => v || 'None'
            }
          },
          {
            name: 'antibiotics_used_bool',
            label: 'Are you using any antibiotics?',
            type: 'select:bool',
          },
          {
            name: 'antibiotics_used',
            // hint: 'Leave blank if none are used',
            hide: index => !form.current_livestock_info[index].antibiotics_used_bool,
            type: 'textarea',
            view: {
              value: v => v || 'None'
            }
          },
        ],
      },
      {
        name: 'current_other_species_info',
        label: 'Bees',
        hide: !form.farming_type?.includes('apiary'),
        fixed: true,
        fields: [
          {
            name: 'total_hives',
            label: 'Number of Hives/Colonies',
          },
        ]
      },

      '::Workers Information',
      {
        name: 'family_workers',
        type: 'select:bool',
        label: 'Are there any family workers on this farm?'
      },
      {
        name: 'numb_male_family_workers',
        label: '# Male Family Workers',
        type: 'number',
        is: 'integer',
        min: 0,
        hide: form.family_workers != true
      },
      {
        name: 'numb_female_family_workers',
        label: '# Female Family Workers',
        type: 'number',
        is: 'integer',
        min: 0,
        hide: form.family_workers != true
      },
      {
        name: 'employee_details',
        required: false,
        columns: [
          {
            name: 'sex',
            required: false,
            options: [
              { value: 'M', label: 'Male' },
              { value: 'F', label: 'Female' },
            ]
          },
          {
            name: 'nationality',
            required: false,
            options: nationalities
          },
          {
            name: 'permanent',
            label: '# Full Time',
            width: '128px',
            type: 'number',
            is: 'integer',
            min: 0,
            required: false,
          },
          {
            name: 'temporary',
            label: '# Part Time',
            width: '128px',
            type: 'number',
            is: 'integer',
            min: 0,
            required: false,
          },
          {
            name: 'work_permits',
            label: '# Work Permits',
            width: '144px',
            type: 'number',
            is: 'integer',
            min: 0,
            required: false,
            disabled: index => form.employee_details[index]
              .nationality == 'Bahamian'
          },
        ]
      },

      '::SPACER',
      {
        name: 'current_business_license',
        type: 'checkbox',
        hide: form.client_type == 'individual',
        default: true,
        label: 'My business licence is up to date',
      },
      {
        name: 'agritourism',
        type: 'select:bool',
        label: 'I intend to conduct AgriTourism on this farm',
      },
      {
        name: 'agritourism_activities',
        multi: true,
        hide: !form.agritourism,
        hint: 'Select all that apply',
        options: [
          'farm tours',
          'petting farm',
          'bird watching',
          'other'
        ],
      },
      // {
      //   name: 'agritourism_activities_other',
      //   label: 'Please Specify',
      //   hide: !form.agritourism_activities?.includes('other')
      // },
      {
        name: 'cottage_processor',
        type: 'select:bool',
        label: `This farm is in the cottage processing industry`,
        required: false,
      },
      {
        name: 'manufactured_products',
        // type: 'textarea',
        // format: val => val.split('\n').map(str => str.trim()),
        required: form.cottage_processor,
        // columns: [{ name: 'product', label: 'Product' }],
        columns: ['name'],
        // format: v => v.product,
        // view: {
        //   format: list => list.map(row => ({
        //     product: row
        //   }))
        // }
      },
      {
        name: 'theft',
        type: 'select:bool',
        label: 'Do you experience praedial larceny/theft?',
      },
      {
        name: 'theft_report',
        type: 'select:bool',
        hide: !form.theft,
        label: `
          Did you report the incident(s) to the Department of Agriculture
          or the Police?
        `,
      },
      {
        name: 'insecticide_spray',
        save: false,
        type: 'select:bool',
        label: 'Are agri chemicals used at this farm?',
        hint: 'e:g: Insecticides, pesticides, etc.'
      },
      {
        name: 'chemicals_used',
        hide: !form.insecticide_spray,
        // type: 'textarea',
        label: 'Please specify (Chemicals Used)',
        columns: ['name'],
        // view: {
        //   label: 'Chemicals Used',
        //   value: v => v || 'N/A'
        // }
      },
      {
        name: 'affected_by_pests',
        type: 'checkbox',
        label: 'This farm is affected by pests',
        required: false,
      },
      {
        name: 'affected_by_drought',
        type: 'checkbox',
        label: 'This farm is affected by drought',
        required: false,
      },
      {
        name: 'affected_by_flooding',
        type: 'checkbox',
        label: 'This farm is affected by flooding',
        required: false,
      },
      {
        name: 'affected_by_disease',
        type: 'checkbox',
        label: 'This farm is affected by diseases?',
        required: false,
      },
      {
        name: 'affected_by_wind',
        type: 'checkbox',
        label: 'This farm is affected by wind?',
        required: false,
      },
      {
        name: 'affected_by_hurricanes',
        type: 'checkbox',
        label: 'This farm is affected by hurricanes?',
        required: false,
      },
      {
        name: 'affected_by_fires',
        type: 'checkbox',
        label: 'This farm is affected by fires?',
        required: false,
      },
      {
        name: 'hurricane_preparedness_plan',
        type: 'select:bool',
        label: 'Is there an existing hurricane preparedness plan?',
        required: false,
      },
      {
        name: 'slash_burn_farming',
        type: 'select:bool',
        label: 'Do you use slash & burn farming?',
      },
      {
        name: 'acres_burned',
        type: 'float',
        label: 'How many acres have you slashed & burned?',
        hide: !form.slash_burn_farming,
      },

      {
        heading: 'Uploads',
        hide: isCopy,
      },
      {
        key: 'image.2',
        name: 'passport_upload',
        hide: isCopy,
        label: 'Passport',
        type: 'file:image',
      },
      // {
      //   name: 'business_license_upload',
      //   type: 'file:pdf',
      //   label: 'Business Licence',
      // },
      // {
      //   name: 'registration_card_upload',
      //   type: 'file:image',
      //   label: 'Registration Card',
      // },
      {
        key: 'image.5',
        name: 'church_letter_upload',
        type: 'file:pdf',
        label: 'Church Letter',
        hide: isCopy || form.organization_type !== 'Church'
      },
      {
        key: 'image.6',
        name: 'cooperative_cert_upload',
        type: 'file:pdf',
        label: 'Cooperative Certificate',
        hide: isCopy || form.organization_type !== 'Co-operative'
      },
      {
        key: 'image.7',
        name: 'school_letter_upload',
        type: 'file:pdf',
        label: 'School Letter',
        hide: isCopy || form.organization_type !== 'School'
      },
      {
        key: 'image.15',
        name: 'land_use_approval_upload',
        type: 'file:pdf',
        hide: isCopy || form.land_ownership != 'given_permission',
        label: 'Letter of Approval To Use Land',
      },
      {
        key: 'image.14',
        name: 'lease_agreement_upload',
        type: 'file:pdf',
        hide: isCopy || form.land_ownership != 'physical_lease',
        label: 'Lease Agreement',
      },
      {
        key: 'image.10',
        name: 'inventory_report_upload',
        type: 'file:pdf',
        hide: isCopy || !form.farming_type?.includes('nursery'),
        label: 'Inventory Report',
      },
      {
        key: 'image.9',
        name: 'permission_letter_upload',
        type: 'file:pdf',
        hide: isCopy || !hasForeignOwner,
        label: 'Bahamas Investment Authority Approval Letter',
      },

      '::SPACER',
      {
        name: 'declare',
        hide: isCopy,
        type: 'checkbox',
        use_as_acceptance: true,
        label: `
          By clicking this box, I confirm that I understand my obligations under
          the respective laws and regulations applicable to the application for 
          farm registration and agree to observe all applicable laws,
          regulations, policies and guidelines.
        `,
      },
    ]
  }

  hidden_fields = form => [
    {
      key: 'image.0',
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Farm Registration Certificate',

    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export function getAvailableGrowthTypes (types) {
  return [
    'field grown',
    'hydroponic',
    'aquaponic',
    'ornamental',
    'nursery',
  ]
}

function forwardToDirector () {
  return {
    text: 'Forward To Director',
    icon: 'user-tie',
    test: r => r.application_decision == 'inspector viewed',
    roles: ['certificate_registrar'],
    fn: async record => {
      const { props, resource, form_key } = this
      const { history, location } = props

      let license_numb = '#######'
      
      const { isConfirmed, isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Recommend Application',
        text: `
          After having done your due diligence, would you recommend
          this application to the Director?
        `,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      })

      if (isDismissed) return
      if (isConfirmed) {
        const { value, isDismissed } = await Swal.fire({
          title: 'Set Licence Number',
          input: 'text',
          inputLabel: 'Licence Number',
          inputPlaceholder: 'Licence #',
          text: `Please enter the applicant's Registration #`,
          showCancelButton: true,
          confirmButtonText: 'Set Registration #',
        })
      
        if (isDismissed) return
        license_numb = value
      }

      try {
        await $app.axios.put(resource + '/update_application', {
          [form_key]: {
            license_numb,
            form_num: record.form_num,
            application_decision: isConfirmed ? 'approved' : 'denied',
          },
        })

        if (location?.state?.view) {
          const view = { ...location.state.view }

          history.replace(location.pathname, {
            ...location.state,
            view,
          })
        }

        toastr.success('Success', 'Application successfully updated')
      } catch (error) {
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }
    }
  }
}
