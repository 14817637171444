import { Fragment } from 'react'
import { QRCode } from 'react-qr-svg'
import Swal from '@sweetalert'

export const shareCert = async cert => {
  const { value: type } = await Swal.fire({
    icon: 'question',
    title: 'Share Certificate',
    text: 'Will you share this certificate via NIB or Email?',
    input: 'select',
    inputOptions: {
      'email address': 'Email',
      'NIB': 'NIB',
    },
    showCancelButton: true,
    confirmButtonText: 'Next',
  })

  if (!type) return
  let success, error

  const { isDismissed } = await Swal.fire({
    icon: 'question',
    title: 'Share Certificate',
    text: `Pleae enter your intended recipient's ${type}`,
    input: type == 'NIB' ? 'tel' : 'email',
    confirmButtonText: 'Share',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    preConfirm: async val => {
      if (!val) {
        // email is prevalidated; this will never be reached in that case
        Swal.showValidationMessage(`Please enter an NIB number`)
        return false
      }

      try {
        const { data } = await $app.axios.post(`/certs/${cert.id}/share`, {
          [type == 'NIB' ? 'nib' : 'email']: val,
        })

        success = data
      } catch (err) {
        console.error(err)
        error = err.response.data
      }
    },
  })

  if (isDismissed) return

  success &&
    Swal.fire(
      'Success',
      `
      Your certificate was successfully shared to
      ${success.shared_to.initialCaps()}
    `,
      'success'
    )

  error &&
    Swal.fire(
      'Error',
      error.message ??
        `
      We were unable to share your certificate to the recipient
    `,
      'error'
    )
}

export const viewRecord = async (cert, history) => {
  try {
    const { data } = await $app.axios.get(`/certs/${cert.id}/retrieve_cert`)
    const { path } = $app.getService(cert.service_type)
    const { record: view } = data

    history.push(path, { view })
  } catch (err) {
    console.error(err)
  }
}

export const getQRCode = async cert => {
  const qrSize = window.innerWidth < 640 ? 160 : 240

  try {
    const { data } = await $app.axios.post('/certs/generate_dynamic_code', {
      form_id: cert.service_id,
      form_type: cert.service_type,
    })

    const { dismiss } = await Swal.fire({
      icon: 'success',
      title: 'Success',
      timer: 180000,
      html: (
        <Fragment>
          <p>
            The following live code has been generated for you. It is only valid
            for three (3) minutes.
          </p>
          <QRCode value={data.qr_hash} width={qrSize} height={qrSize} />
        </Fragment>
      ),
      confirmButtonText: 'Close',
    })

    if (dismiss == 'timer') {
      await Swal.fire({
        icon: 'warning',
        title: 'Code Expired',
        text: `
          Your code expired. Please generate a new one if you still need it
        `,
      })
    }
  } catch (err) {
    console.error(err)

    await Swal.fire(
      'Error',
      'Sorry, but we were unable to generate a live QR Code at this time.',
      'error'
    )
  }
}
