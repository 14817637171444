import React, { useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core'

import SelectField from '@shared/form-fields/select'
import findSelected from '@helpers/find-selected'

const CannedEmailModal = ({ replies, custom, submit, close }) => {
  const [selected, setSelected] = useState(null)
  const setCannedMessage = v => v?.length && setSelected(v[0].value)

  return (
    <React.Fragment>
      <div className='modal-header'>
        <h5 className='modal-title' id='change-stat-modal-title'>
          Email User
        </h5>
      </div>
      <ValidatorForm onSubmit={() => submit(selected)}>
        <div className='modal-body'>
          <div className='form-group form-show-validation'>
            <label htmlFor='email-message'>Message*</label>
            <SelectField
              name='email-message'
              value={selected}
              values={findSelected(replies, selected)}
              options={replies}
              onChange={setCannedMessage}
              className='form-control'
              validators={['required']}
              errorMessages={['Required']}
              required
            />
          </div>
          <small className='block -mt-3 mx-2'>
            <a onClick={custom}>Send a custom email instead</a>
          </small>
          <div
            className='flex flex-col mt-2 items-center'
            data-hide={!selected}
          >
            <small>Preview</small>
            <p
              className='rounded-md bg-gray-200 p-3 mt-2 -mx-4'
              dangerouslySetInnerHTML={{ __html: selected }}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <button
            className='btn btn-round mr-2'
            aria-label='Close'
            onClick={close}
          >
            Cancel
          </button>
          <input
            className='btn custom-btn btn-round'
            type='submit'
            value='Confirm'
          />
        </div>
      </ValidatorForm>
    </React.Fragment>
  )
}

export default CannedEmailModal
