import React from "react";
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
// import { fullName } from "@modules/form-wizard/utils";
import { getUploadPath } from "@modules/form-wizard/utils";


const FormView = ({ methods, passedProps }) => {
  const { service, formSelected, } = passedProps;
  const { setFormSelected, approve, deny, setActive, writeNote } = methods;

  const populateUploads = (certsOnly = false) => (
    formSelected.image.map((data, index) => {
      const dataSplit = data.split('/');
      const isMissing = dataSplit[1] != 'system';
      let uploadName = isMissing ? dataSplit[1] : dataSplit[3];
      uploadName = uploadName.replaceAll('_', ' ');
      uploadName = uploadName.slice(0, -1)

      if(certsOnly && (uploadName != 'pdf upload' || uploadName != 'pdf 2 upload')){return null}
      if(!certsOnly && (uploadName == 'pdf upload' || uploadName == 'pdf 2 upload')){return null}
      if(uploadName == 'pdf upload'){ uploadName = 'view certificate'; }
      if(uploadName == 'pdf 2 upload'){ uploadName = 'view 2nd certificate'; }

      if(isMissing){ return null; }

      return (
        <div key={index} className="col-md-6 col-lg-4 info-invoice">
          <h5 className="sub" style={{textTransform: 'capitalize'}}>{uploadName}</h5>
          <p>
            {isMissing ? <span>N/A</span> :
            <a href={getUploadPath(data, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>}
          </p>
        </div>
      );
    }));

  // Populate either Owners and Shareholders property
  const populateShareholders = (ckey = 'shareholders') => (
    formSelected[ckey].map((data, index) => {
      // allUploads = Object.keys(data).filter(x => x !== 'name')
      const allUploads = formSelected.additional_images.filter(x => x.title.includes(data.name.toLowerCase()))

      return (
        <div key={index} data-row="true" className="row py-2">
          <h5 className="sub text-muted col-sm-12 mb-3">Item #{index + 1}</h5>
          <div className="infor-invoice col-md-6 col-lg-4">
            <h5 className="sub">Name</h5>
            <p style={{textTransform: 'capitalize'}}>{data.name}</p>
          </div>
          {allUploads.map((x, index) => (
            <div key={index} className="infor-invoice col-md-6 col-lg-4">
              <h5 className="sub" style={{textTransform: 'capitalize'}}>{x.desc}</h5>
              <p>
                <a href={getUploadPath(x.url, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>
              </p>
            </div>
          ))}
        </div>
      );
    }));

  const populateEmployers = () => formSelected.employers.map((data, index) => (
    <tr key={index}>
      <td>{data.company_name}</td>
      <td>{data.phone_number}</td>
    </tr>
  ));



  const securityServiceRender = () => {

    return (
      <div className="row">
      <div className="col-md-12">
        <div className="separator-solid mt-0"></div>
        <div className="invoice-detail">
          <div className="invoice-top">
            <h3 className="title"><strong>Details</strong></h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 info-invoice">
            <h5 className="sub">Licence #</h5>
            <p>{formSelected.license_numb || 'N/A'}</p>
          </div>
          {formSelected.employers.length > 0 && (
            <div className="col-md-12 info-invoice">
              <h5 className="sub text-muted">Employment Details</h5>
            </div>
          )}
          {formSelected.employers.length > 0 && (
            <div className="col-md-12 info-invoice">
              <h5 className="sub">Company Name(s)</h5>
              <div className="form-wizard-form-table table-responsive">
                <table className="table table-striped table-striped-bg-black">
                  {/* 
                  <colgroup>
                    <col span="1" width="auto">
                    <col span="1" width="auto">
                  </colgroup>
                  */}
                  <thead>
                    <tr className="header">
                      <th>Name</th>
                      <th>Phone #</th>
                    </tr>
                  </thead>
                  <tbody>
                    {populateEmployers()}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {formSelected.owners.length > 0 && (
            <div className="col-md-12 info-invoice">
              <h5 className="sub">Other Owners</h5>
              <div className="form-wizard-form-array">
                {populateShareholders('owners')}
              </div>
            </div>
          )}
          {formSelected.owners.length > 0 && (
            <div className="col-md-12 info-invoice">
              <h5 className="sub">Shareholders/Directors</h5>
              <div className="form-wizard-form-array">
                {populateShareholders('shareholders')}
              </div>
            </div>
          )}
          {/* {formSelected.sub_applications?.length > 0 && (
            <div>
              <h5 className='sub'>Linked Applications</h5>
              <div className='form-wizard-form-table table-responsive'>
                <table className='table table-striped table-striped-bg-black'>
                  <thead>
                    <tr>
                      <th>Applicant</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formSelected.sub_applications.map(row => (
                      <tr key={row.id}>
                        <td>{fullName(row.employee_details[0])}</td>
                        <td
                          // onClick={() => }
                          className='font-semibold cursor-pointer text-blue-600 hover:text-gray-700'
                          children='View'
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}
          {formSelected.agent_type == 'security guard' && (
            <div className="col-md-6 col-lg-4 info-invoice">
              <h5 className="sub">Is this your full time occupation?</h5>
              <p>{formSelected.full_time ? 'Yes' : 'No'}</p>
            </div>
          )}
          <div className="col-md-12 info-invoice">
            <h5 className="sub text-muted">Personal Contact Information</h5>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
            <h5 className="sub">Street Address</h5>
            <p>{formSelected.street_address}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
            <h5 className="sub">P.O. Box</h5>
            <p>{formSelected.po_box}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
            <h5 className="sub">Contact Number</h5>
            <p>{formSelected.phone_number || formSelected.user?.additional_phone_numb}</p>
          </div>
          <div className="col-md-12 info-invoice">
            <h5 className="sub text-muted">Uploads</h5>
          </div>
          {populateUploads()}
          <div className="col-md-12 info-invoice">
            <h5 className="sub text-muted"><span>&nbsp;</span></h5>
          </div>
          {populateUploads(true)}
        </div>
        <div className="separator-solid mb-3">
        </div>
      </div>
    </div>
    );
  };

  const symbolServiceRender = () => {
    return (
      <div className="row">
      <div className="col-md-12">
        <div className="separator-solid mt-0"></div>
        <div className="invoice-detail">
          <div className="invoice-top">
            <h3 className="title"><strong>Details</strong></h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 info-invoice">
            <h5 className="sub">Symbol(s) Requesting Permission For</h5>
            <p>
              {formSelected.symbols_used.map((symbol, index) => {
                const symbolsLength = formSelected.symbols_used.length - 1
                return symbolsLength != index ? `${symbol}, ` : symbol
              })}
            </p>
          </div>
          <div className="col-md-12 info-invoice">
            <h5 className="sub">
              2. Details of goods/articles/things to which the application relates:
            </h5>
            <p>{formSelected.detailed_desc}</p>
          </div>
          <div className="col-md-12 info-invoice">
            <h5 className="sub">
              3. Illustration of the National Flag/Coat of Arms/part of the design
              of the National Flag/part of the design of the Coat of Arms/other
              flag/devices, to which the application relates:
            </h5>
            <p>{formSelected.illustration_description}</p>
          </div>
          <div className="col-md-12 info-invoice">
            <h5 className="sub">
              4. Manner in which it is proposed to use the design illustrated in
              paragraph 3 above in connection with a business/trade/calling/profession:
            </h5>
            <p>{formSelected.proposed_use}</p>
          </div>
          <div className="col-md-6 col-lg-4 info-invoice">
            <h5 className="sub">5. Date on which permission is required</h5>
            <p>{formSelected.permission_date}</p>
          </div>
          <div className="col-md-12 info-invoice">
            <h5 className="sub text-muted">Uploads</h5>
          </div>
          {populateUploads()}
          <div className="col-md-12 info-invoice">
            <h5 className="sub text-muted"><span>&nbsp;</span></h5>
          </div>
          {populateUploads(true)}
        </div>
        <div className="separator-solid mb-3">
        </div>
      </div>
    </div>
    );
  };

  return (
    <div>
      {/* Service Record View Page */}
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-9">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="page-pretitle text-primary font-semibold text-lg">
                {formSelected.payment_status ? 'PAID' : 'UNPAID'}&nbsp; | {formSelected.application_decision}
              </h6>
              <h4 className="page-title">{service.serviceType(formSelected)}</h4>
            </div>
            <div className="col-auto">
              <button className="flex items-center btn custom-btn btn-round ml-auto" onClick={() => setFormSelected(null)}>
              <span>Back</span>
              </button>
            </div>
            <div className="col-auto">
              <button className="flex items-center btn btn-round ml-auto">
                <Menu
                menuButton={
                <div className='btn custom-btn'>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                  </svg>
                  <span data-action>Actions</span>
                </div>
                }
                transition
                portal
                arrow
                >
                <MenuItem onClick={approve(formSelected)}>
                  Approve
                </MenuItem>
                {
                service?.key != 'SECURITY_LICENCES'
                || formSelected.temporary
                || formSelected.application_decision == 'temp approved'
                ? null
                : (
                <MenuItem onClick={approve(formSelected, true)}>
                  Approve (Temp)
                </MenuItem>
                )
                }
                <MenuItem onClick={deny(formSelected)}>
                  Deny
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setActive(formSelected)}>
                  View Notes
                </MenuItem>
                <MenuItem onClick={() => writeNote(formSelected)}>
                  Create Note
                </MenuItem>
                </Menu>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          </div>
          <div className="page-divider">
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-invoice">
                <div className="card-header">
                  <div className="invoice-header">
                    <div className="invoice-logo">
                      <img src="/static/media/bs-coat-of-arms.bb54f8a8.png" alt="Bahamas Coat of Arms"/>
                    </div>
                    <h3 className="invoice-title lg:text-right">Application</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="separator-solid"></div>
                  <div className="row">
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application ID</h5>
                      <p>{formSelected.form_num}</p>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application Date</h5>
                      <p>{formSelected.created_at.slice(0, formSelected.created_at.indexOf("T"))}</p>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Application Status</h5>
                      <p style={{textTransform: 'uppercase'}}>{formSelected.application_decision}</p>
                      {service?.key == 'SECURITY_LICENCES' && (
                        <p style={{textTransform: 'uppercase'}}>
                          <span><strong>SIB Recommendation</strong></span>
                          <br/>
                          {formSelected.sib_recommendation_status}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="separator-solid"></div>
                  <div className="row">
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Applicant Name</h5>
                      <div>{`${formSelected.user.first_name} ${formSelected.user.middle_name} ${formSelected.user.last_name}`}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">NIB #</h5>
                      <div>{formSelected.user.NIB}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">D.O.B</h5>
                      <div>{formSelected.user.date_of_birth}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Email</h5>
                      <div>{formSelected.user.email}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Mobile Number</h5>
                      <div>{formSelected.user?.additional_phone_numb || formSelected.phone_number}</div>
                    </div>
                    <div className="col-md-4 info-invoice">
                      <h5 className="sub">Address</h5>
                      <div>
                        <address>{formSelected.street_address}<br/>{formSelected.po_box}<br/>{formSelected.user?.home_address?.island}, Bahamas</address>
                      </div>
                    </div>
                  </div>
                  {service?.key == 'SECURITY_LICENCES' && securityServiceRender()}
                  {service?.key == 'SYMBOL_USE_PERMISSION' && symbolServiceRender()}
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-sm-7 col-md-5 mb-3 mb-md-0 transfer-to"></div>
                    <div className="col-sm-5 col-md-7 transfer-total">
                      <h5 className="sub">Total Amount</h5>
                      <div className="price">${formSelected.payment_amount}</div>
                      <span>Taxes Included</span>
                    </div>
                  </div>
                  <div className="separator-solid"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormView;
