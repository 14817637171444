import React, { Fragment } from 'react'
import { FaChevronLeft } from 'react-icons/fa'

import { getUploadPath } from './utils'

const SubmissionLinkedView = props => {
  const { view, record, options } = props.location.state
  const { history, free } = props
  if (!view) return null

  const {
    payment_status,
    application_decision,
  } = view

  // const rawFields = props.fields(record)

  const generateTable = field => {
    const { name, fields, value } = field
    const columns = fields.map(c => c.name || c)

    const headings = fields.map(c => (
      <th key={c.name || c}>
        {(c.label || c.name || c).replaceAll('_', ' ').capitalize()}
      </th>
    ))

    const widths = fields.map(c => (
      <col key={c.name || c} span='1' width={c.width || 'auto'} />
    ))

    const rows = value.map((_row, i) => {
      const row = typeof _row == 'string' && _row.charAt(0) == '{'
        ? JSON.parse(_row)
        : _row
        
      const fields = columns.map((col, j) => {
        const rc = row[col]
        const val = col == 'status' ? rc?.capitalize() || 'Pending' : rc

        return <td key={j}>{val}</td>
      })

      return <tr key={i}>{fields}</tr>
    })

    return (
      <div className='form-wizard-form-table table-responsive' key={name}>
        <table className='table table-striped table-striped-bg-black'>
          <colgroup>{widths}</colgroup>
          <thead>
            <tr className='header'>{headings}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }

  const generateArray = field => {
    const { name, fields, rowLabel, value } = field

    const list = value.map((row, i) => {
      const values = fields.map((f, j) => {
        const classes =
          f?.type == 'textarea'
            ? 'infor-invoice col-md-12'
            : 'infor-invoice col-md-6 col-lg-4'

        return (
          <div key={j} className={classes}>
            <h5 className='sub'>{f?.label ?? (f?.name ?? f).initialCaps()}</h5>
            <p>{row[f?.name ?? f]}</p>
          </div>
        )
      })

      return (
        <div data-row key={i} className='row py-2'>
          <h5 className='sub text-muted col-sm-12 mb-3'>
            {(rowLabel ?? 'Item') + '#' + (i + 1)}
          </h5>
          {values}
        </div>
      )
    })

    return (
      <div className='form-wizard-form-array' key={name}>
        {list}
      </div>
    )
  }

  // prettier-ignore
  const submissionValues = fields => fields.map((field, i) => {
    const { label, value, heading, long } = field
    
    // if (value == 'CUSTOM') {
    //   const el = rawFields.find(f => f.name == name)
    //     ?.view?.element(record[name], record, extractKeys(
    //       props,
    //       'viewLinkedApplication'
    //     ))

    //   if (el === null || el === undefined) return <Fragment key={i} />

    //   return (
    //     <div
    //       key={i}
    //       className='col-md-12 info-invoice'
    //       children={el}
    //     />
    //   )
    // }

    // Don't allow deep nesting of linked records/applications; hide these
    if (value == 'LINKED') return <Fragment key={i} />

    if (heading) {
      return (
        <div key={i} className='col-md-12 info-invoice'>
          <h5
            className='sub text-muted'
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h5>
        </div>
      )
    }

    let table = false
    let val = value

    if (String(value).startsWith('href::')) {
      const [, link] = value.split('::')
      val = /(original|medium)\/missing\.png$/.test(link) ? (
        'N/A'
      ) : (
        <a href={link} target='_blank' rel='noopener noreferrer'>
          (Click To View)
        </a>
      )
    }

    if (Array.isArray(value)) {
      if (field.table) {
        table = true
        val = generateTable(field)
      } else if (field.array) {
        table = true
        val = generateArray(field)
      } else val = value.join(', ')
    }

    const classes = long ? 'col-md-12' : 'col-md-6 col-lg-4'

    return (
      <div key={i} className={classes + ' info-invoice'}>
        <h5 className='sub'>{label}</h5>
        {table ? val : <p>{val}</p>}
      </div>
    )
  })

  const header = (
    <div className='row align-items-center'>
      <div className='col'>
        <h6 className='page-pretitle text-primary font-semibold text-lg'>
          {free ? 'FREE' : payment_status ? 'PAID' : 'UNPAID'}&nbsp;
          {application_decision
            ? ' | ' + application_decision
            : (payment_status && ' | PENDING') || null}
        </h6>
        <h4
          className='page-title'
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></h4>
      </div>
      <div className='col-auto'>
        <button
          onClick={history.goBack}
          className='flex items-center btn custom-btn btn-round ml-auto'
        >
          <FaChevronLeft size={18} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>Back To Top</span>
        </button>
      </div>
    </div>
  )

  const summary = (
    <div className='card-body'>
      <div className='separator-solid'></div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='invoice-detail'>
            <div className='invoice-top'>
              <h3 className='title'>
                <strong>Details</strong>
              </h3>
            </div>
          </div>
          <div className='row'>
            {submissionValues(view.fields)}
          </div>
          {!!record.additional_images?.length && (
            <Fragment>
              <h5 className='sub text-muted'>Uploads</h5>
              <div className='flex flex-col flex-wrap md:flex-row'>
                {record.additional_images.map(img => (
                  <div
                    className='flex justify-between w-full md:w-1/2 md:px-4 mb-2 space-x-2'
                    key={img.url}
                  >
                    <span>{img.title.initialCaps()}</span>
                    <a
                      href={getUploadPath(img.url).slice(6)}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      View
                    </a>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          <div className='separator-solid mb-3' />
        </div>
      </div>
    </div>
  )

  return (
    <div
      className='application-details page-inner subview'
      style={{ marginTop: '60px' }}
    >
      <div className='row justify-content-center'>
        <div className='col-12 col-lg-10 col-xl-9'>
          {header}
          <div className='page-divider'></div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card card-invoice'>
                <div className='card-header'>
                  <div className='invoice-header'>
                    <h4
                      className='invoice-title'
                      children={`Linked: ${options?.title ?? 'Application'}`}
                    />
                  </div>
                </div>
                {summary}
                <div className='card-footer'>
                  <div className='row' />
                  <div className='separator-solid'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className='col-12 col-lg-10 col-xl-9 text-right'>
          <button className='btn btn-next custom-btn' onClick={history.goBack}>
            Back
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubmissionLinkedView
