const plants_flowers = [
  {
    scientific_name: 'Acacia koa',
    common_name: 'koa',
    Page: '47',
  },
  {
    scientific_name: 'Acacia koaia',
    common_name: 'koai‘a',
    Page: '47',
  },
  {
    scientific_name: 'Acacia mangium',
    common_name: 'mangium',
    Page: '47',
  },
  {
    scientific_name: 'Acalypha wilkensiana',
    common_name: 'beefsteak',
    Page: '47',
  },
  {
    scientific_name: 'Ajuga reptans',
    common_name: 'carpet bugle',
    Page: '47',
  },
  {
    scientific_name: 'Aleurites moluccana',
    common_name: 'kukui, lama, lumbang, sakan, raguar, candlenut tree',
    Page: '47',
  },
  {
    scientific_name: 'Alpinia zerumbet',
    common_name: 'shell ginger',
    Page: '47',
  },
  {
    scientific_name: 'Arachis glabrata',
    common_name: 'forage peanut',
    Page: '48',
  },
  {
    scientific_name: 'Arachis pintoi cv. Amarillo',
    common_name: 'forage peanut',
    Page: '48',
  },
  {
    scientific_name: 'Araucaria columnaris',
    common_name: 'Cook pine',
    Page: '48',
  },
  {
    scientific_name: 'Araucaria heterophylla',
    common_name: 'Norfolk Island pine',
    Page: '48',
  },
  {
    scientific_name: 'Artocarpus altilis',
    common_name: 'breadfruit, ulu, lemai',
    Page: '48',
  },
  {
    scientific_name: 'Artocarpus hetrophyllum',
    common_name: 'jackfruit',
    Page: '48',
  },
  {
    scientific_name: 'Atriplex semibaccata',
    common_name: 'Australian saltbush',
    Page: '48',
  },
  {
    scientific_name: 'Avena sativa',
    common_name: 'oats',
    Page: '49',
  },
  {
    scientific_name: 'Axonopus affinis (syn. Axonopus\r\nfissifolius)',
    common_name: 'narrowleaf carpetgrass,\r\nAustralian carpetgrass',
    Page: '49',
  },
  {
    scientific_name: 'Axonopus compressus',
    common_name: 'broadleaf carpetgrass',
    Page: '49',
  },
  {
    scientific_name: 'Azadirachta indica',
    common_name: 'neem',
    Page: '49',
  },
  {
    scientific_name: 'Bambusa sp.',
    common_name: 'bamboo',
    Page: '49',
  },
  {
    scientific_name: 'Bougainvillea spectabilis',
    common_name: 'Bougainvillea, felila',
    Page: '49',
  },
  {
    scientific_name: 'Bromus inermis',
    common_name: 'bromegrass',
    Page: '49',
  },
  {
    scientific_name: 'Bromus wildenowii',
    common_name: 'bromegrass',
    Page: '49',
  },
  {
    scientific_name: 'Cajanus cajan',
    common_name: 'pigeonpea',
    Page: '49',
  },
  {
    scientific_name: 'Callitris sp.',
    common_name: 'callitris',
    Page: '50',
  },
  {
    scientific_name: 'Calophyllum inophyllum',
    common_name:
      'kamani, daok, fetau, btaches,\r\nbiyuch, eet, isou, lueg, rakich',
    Page: '50',
  },
  {
    scientific_name: 'Canthium odoratum (syn. Psydrax\r\nodorata)',
    common_name: 'alahe‘e',
    Page: '50',
  },
  {
    scientific_name: 'Caryota mitis',
    common_name: 'fishtail palm',
    Page: '50',
  },
  {
    scientific_name: 'Cassia x nealii',
    common_name: 'rainbow shower tree',
    Page: '50',
  },
  {
    scientific_name: 'Casuarina cunninghamiana',
    common_name: 'smallcone ironwood',
    Page: '50',
  },
  {
    scientific_name: 'Casuarina equisetifolia',
    common_name: 'ironwood, toa, gagu, laash, ngas,\r\nmejinoki, weeku',
    Page: '50',
  },
  {
    scientific_name: 'Cenchrus ciliaris (syn.\r\nPennisetum ciliare)',
    common_name: 'buffelgrass',
    Page: '51',
  },
  {
    scientific_name: 'Chenopodium oahuense',
    common_name: '‘aweoweo',
    Page: '51',
  },
  {
    scientific_name: 'Chloris gayana',
    common_name: 'Rhodesgrass',
    Page: '51',
  },
  {
    scientific_name: 'Chrysalidocarpus letuscens',
    common_name: 'areca palm',
    Page: '51',
  },
  {
    scientific_name: 'Chrysopogon zizanioides cv.',
    common_name: 'vetivergrass',
    Page: '51',
  },
  {
    scientific_name: 'Sunshine (syn. Vetiveria\r\nzizanioides)',
  },
  {
    scientific_name: 'Cibotium menziesii',
    common_name: 'hapu‘u, tree fern',
    Page: '51',
  },
  {
    scientific_name: 'Citrus lycopersicaeformis',
    common_name: 'heen naran (seedy tangerine)',
    Page: '51',
  },
  {
    scientific_name: 'Coccolba uvifera',
    common_name: 'sea grape',
    Page: '51',
  },
  {
    scientific_name: 'Cocos nucifera',
    common_name: 'coconut, niu, lius, iru, lu, ni, niyog, nizok, nu',
    Page: '52',
  },
  {
    scientific_name: 'Cocos nucifera',
    common_name: 'dwarf coconut, niu, lius, iru, lu, ni, niyog, nizok, nu',
    Page: '52',
  },
  {
    scientific_name: 'Codium variegatum',
    common_name: 'croton',
    Page: '52',
  },
  {
    scientific_name: 'Coix lachryma',
    common_name: 'jobs tears',
    Page: '52',
  },
  {
    scientific_name: 'Cordia subcordata',
    common_name:
      'kou, niyoron, tauanave, galu,\r\nkoa, kalau, ikoik, ikoak, anau',
    Page: '52',
  },
  {
    scientific_name: 'Cordyline fruticosa',
    common_name: 'ti',
    Page: '52',
  },
  {
    scientific_name: 'Crotalaria juncea cv. Tropic Sun',
    common_name: 'sunn hemp',
    Page: '52',
  },
  {
    scientific_name: 'Croton reflexifolius',
    common_name: 'colpachi croton',
    Page: '53',
  },
  {
    scientific_name: 'Cryptomaria japonica',
    common_name: 'Japanese sugi pine',
    Page: '53',
  },
  {
    scientific_name: 'Cunninghamia lanceolata',
    common_name: 'Chinese fir',
    Page: '53',
  },
  {
    scientific_name: 'Cupressus lusitanica',
    common_name: 'Portuguese (Mexican) cypress',
    Page: '53',
  },
  {
    scientific_name: 'Cupressus macrocarpa',
    common_name: 'California cypress',
    Page: '53',
  },
  {
    scientific_name: 'Cupressus sempervirons',
    common_name: 'Italian cypress',
    Page: '53',
  },
  {
    scientific_name: 'Cymbopogon citratus',
    common_name: 'lemongrass',
    Page: '53',
  },
  {
    scientific_name: 'Cynodon dactylon',
    common_name: 'common Bermudagrass',
    Page: '53',
  },
  {
    scientific_name: 'Cynodon dactylon',
    common_name: 'giant Bermudagrass',
    Page: '53',
  },
  {
    scientific_name: 'Cynodon nlemfuensis cv. Florico',
    common_name: 'Puerto Rican stargrass',
    Page: '54',
  },
  {
    scientific_name: 'Cyperus javanicus',
    common_name: '‘ahu‘awa, marsh cypress',
    Page: '54',
  },
  {
    scientific_name: 'Cyperus polystachyos',
    Page: '54',
  },
  {
    scientific_name: 'Dactylis glomerata',
    common_name: 'orchardgrass',
    Page: '54',
  },
  {
    scientific_name: 'Desmodium heterophyllum',
    common_name: 'hetero',
    Page: '54',
  },
  {
    scientific_name: 'Desmodium intortum (or\r\nDesmodium aparines)',
    common_name: 'intortum, desmodium',
    Page: '54',
  },
  {
    scientific_name: 'Desmodium triflorum',
    common_name: 'three-flower beggarweed',
    Page: '54',
  },
  {
    scientific_name: 'Dichondra repens',
    common_name: 'dichondra',
    Page: '54',
  },
  {
    scientific_name: 'Digitaria eriantha cv. Pangola',
    common_name: 'digitgrass',
    Page: '54',
  },
  {
    scientific_name: 'Digitaria eriantha cv. Transvala',
    common_name: 'digitgrass',
    Page: '55',
  },
  {
    scientific_name: 'Dimorphotheca sinuata',
    common_name: 'cape marigold',
    Page: '55',
  },
  {
    scientific_name: 'Dodonaea viscosa',
    common_name: '‘a‘ali‘i, lampuye',
    Page: '55',
  },
  {
    scientific_name: 'Dracaena fragans',
    common_name: 'dracaena',
    Page: '55',
  },
  {
    scientific_name: 'Echinochloa colona',
    common_name: 'jungle rice',
    Page: '55',
  },
  {
    scientific_name: 'Echinochloa crus-galli',
    common_name: 'barnyardgrass',
    Page: '55',
  },
  {
    scientific_name: 'Eleocharis geniculata',
    common_name: 'spike rush',
    Page: '55',
  },
  {
    scientific_name: 'Eragrostis variabilis',
    common_name: '‘emoloa, kawelu, lovegrass',
    Page: '55',
  },
  {
    scientific_name: 'Eremochloa ophiuroides',
    common_name: 'centipedegrass',
    Page: '56',
  },
  {
    scientific_name: 'Erythrina sandwicensis',
    common_name: 'wiliwili',
    Page: '56',
  },
  {
    scientific_name: 'Erythrina variegata cv. Tropic\r\nCoral',
    common_name: 'tall erythrina',
    Page: '56',
  },
  {
    scientific_name: 'Eucalyptus camaldulensis',
    common_name: 'river-redgum eucalyptus',
    Page: '56',
  },
  {
    scientific_name: 'Eucalyptus dunnii',
    common_name: 'Dunn’s eucalyptus',
    Page: '56',
  },
  {
    scientific_name: 'Eucalyptus robusta',
    common_name: 'swamp mahogany',
    Page: '56',
  },
  {
    scientific_name: 'Eucalyptus torreliana',
    common_name: 'cadaga',
    Page: '56',
  },
  {
    scientific_name: 'Fagopyrum esculentum',
    common_name: 'buckwheat',
    Page: '56',
  },
  {
    scientific_name: 'Filicium decipiens',
    common_name: 'ferntree',
    Page: '57',
  },
  {
    scientific_name: 'Fimbristylis littoralis',
    Page: '57',
  },
  {
    scientific_name: 'Flueggea flexuosa',
    common_name: 'poumuli',
    Page: '57',
  },
  {
    scientific_name: 'Fragaria chiloensis',
    common_name: '‘ohelo papa',
    Page: '57',
  },
  {
    scientific_name: 'Gliricidia sepium',
    common_name: 'gliricidia, madra de cacao',
    Page: '57',
  },
  {
    scientific_name: 'Glycine max',
    common_name: 'soybean',
    Page: '57',
  },
  {
    scientific_name: 'Gossypium tomentosum',
    common_name: 'ma‘o, Hawaiian cotton',
    Page: '57',
  },
  {
    scientific_name: 'Hemarthria altissima cv. Bigalta',
    common_name: 'limpograss',
    Page: '57',
  },
  {
    scientific_name: 'Hemerocallis aurantiaca',
    common_name: 'day lily',
    Page: '57',
  },
  {
    scientific_name: 'Hetropogon contortus',
    common_name: 'piligrass, tanglehead',
    Page: '58',
  },
  {
    scientific_name: 'Hibiscus arnottianus var.\r\npunaluensis',
    common_name: 'Oahu white hibiscus',
    Page: '58',
  },
  {
    scientific_name: 'Hibiscus rosa-sinensis',
    common_name: 'hibiscus, Chinese, aute, flores rosa',
    Page: '58',
  },
  {
    scientific_name: 'Intsia bijuga',
    common_name: 'ifit, ifil, ipil, ifilele, dort, choyo, kubok, kuren',
    Page: '58',
  },
  {
    scientific_name: 'Ipomea pes-caprae',
    common_name: 'pohuehue, beach morning glory',
    Page: '58',
  },
  {
    scientific_name: 'Ischaemum polystachyum (syn.\r\nIschaemum digitatum)',
    common_name: 'Baron’s grass, paddlegrass, reh\r\npadil',
    Page: '58',
  },
  {
    scientific_name: 'Jacquemontia ovalifolia subsp.\r\nsandwicensis',
    common_name: 'pa‘uohi‘iaka',
    Page: '58',
  },
  {
    scientific_name: 'Juncus effusus',
    common_name: 'soft rush',
    Page: '58',
  },
  {
    scientific_name: 'Lablab purpureus',
    common_name: 'lablab',
    Page: '58',
  },
  {
    scientific_name: 'Lippia nodiflora',
    common_name: 'lippia',
    Page: '59',
  },
  {
    scientific_name: 'Lolium multiflorum',
    common_name: 'annual ryegrass',
    Page: '59',
  },
  {
    scientific_name: 'Lolium perenne',
    common_name: 'perennial ryegrass',
    Page: '59',
  },
  {
    scientific_name: 'Lophostemon confertus (syn.\r\nTristania conferta)',
    common_name: 'brushbox',
    Page: '59',
  },
  {
    scientific_name: 'Lotus pedunculatus',
    common_name: 'big trefoil',
    Page: '59',
  },
  {
    scientific_name: 'Lycium sandwicense',
    common_name: '‘ohelo kai',
    Page: '59',
  },
  {
    scientific_name: 'Magnifera indica',
    common_name: 'mango, kangit, idele, mago,',
    Page: '59',
  },
  {
    common_name: 'mangueira',
  },
  {
    scientific_name: 'Medicago sativa',
    common_name: 'alfalfa',
    Page: '59',
  },
  {
    scientific_name: 'Melitotus sp.',
    common_name: 'sweet clover',
    Page: '59',
  },
  {
    scientific_name: 'Metrosideros polymorpha',
    common_name: '‘ohi‘a lehua',
    Page: '60',
  },
  {
    scientific_name: 'Morinda citrifolia',
    common_name: 'noni, lada, nonu, kesengel, i,',
    Page: '60',
  },
  {
    common_name: 'nen, mangal’wag, Indian',
  },
  {
    common_name: 'mulberry',
  },
  {
    scientific_name: 'Musa sp. cv. Santa Catarina',
    common_name: 'dwarf Brazilian banana',
    Page: '60',
  },
  {
    scientific_name: 'Prata',
  },
  {
    scientific_name: 'Myoporum sandwicense',
    common_name: 'naio',
    Page: '60',
  },
  {
    scientific_name: 'Nerium oleander',
    common_name: 'oleander, oliana',
    Page: '60',
  },
  {
    scientific_name: 'Opuntia ficus-indica',
    common_name: 'panini, prickly pear cactus',
    Page: '60',
  },
  {
    scientific_name: 'Oryza sativa',
    common_name: 'rice',
    Page: '60',
  },
  {
    scientific_name: 'Osteomeles anthyllidifolia',
    common_name: '‘ulei',
    Page: '61',
  },
  {
    scientific_name: 'Osteospermum fruticosum',
    common_name: 'trailing African daisy',
    Page: '61',
  },
  {
    scientific_name: 'Pandanus tectorius',
    common_name: 'hala, kafu, fasa, ongor',
    Page: '61',
  },
  {
    scientific_name: 'Paspalum hieronymii cv. Tropic Lalo',
    common_name: 'paspalum',
    Page: '61',
  },
  {
    scientific_name: 'Paspalum orbiculare',
    common_name: 'mau‘u laiki, ricegrass',
    Page: '61',
  },
  {
    scientific_name: 'Paspalum vaginatum',
    common_name: 'seashore paspalum',
    Page: '61',
  },
  {
    scientific_name: 'Pennisetum purpureum cv. Mott',
    common_name: 'Napiergrass',
    Page: '61',
  },
  {
    scientific_name: 'Pennisetum purpureum x\r\nPennisetum glaucum cv. PMN',
    common_name: 'Napiergrass hybrid',
    Page: '62',
  },
  {
    scientific_name: 'Hybrid',
  },
  {
    scientific_name: 'Persea americana',
    common_name: 'avocado',
    Page: '62',
  },
  {
    scientific_name: 'Piper methysticum',
    common_name: '‘awa, ‘ava, kava, sakau',
    Page: '62',
  },
  {
    scientific_name: 'Plumeria obtusa',
    common_name: 'plumeria',
    Page: '62',
  },
  {
    scientific_name: 'Podocarpus sp.',
    common_name: 'podocarpus',
    Page: '62',
  },
  {
    scientific_name: 'Polygonum minus var. procerum',
    common_name: 'kamole, smartweed',
    Page: '62',
  },
  {
    scientific_name: 'Polyscias guilfoylei (syn.',
    common_name: 'panax, tanitani',
    Page: '62',
  },
  {
    scientific_name: 'Nothopanax guilfoylei)',
  },
  {
    scientific_name: 'Portulaca grandiflora',
    common_name: 'rose-moss',
    Page: '62',
  },
  {
    scientific_name: 'Pouteria sandwicensis',
    common_name: '‘ala‘a',
    Page: '63',
  },
  {
    scientific_name: 'Premna obtusifolia (syn. Premna',
    common_name: 'ahgao, aloalo, topwuk, fienkack,',
    Page: '63',
  },
  {
    scientific_name: 'serratifolia)',
    common_name: 'arr, nior, lior, false elder',
  },
  {
    scientific_name: 'Pritchardia sp.',
    common_name: 'loulu palm',
    Page: '63',
  },
  {
    scientific_name: 'Ptychosperma macarthurii',
    common_name: 'Macarthur palm',
    Page: '63',
  },
  {
    scientific_name: 'Rumex acetosella',
    common_name: 'sheep sorrel',
    Page: '63',
  },
  {
    scientific_name: 'Saccharum hybrid clone Moentai',
    common_name: 'wild cane hybrid',
    Page: '63',
  },
  {
    scientific_name: 'Samanea saman (syn. Albizia',
    common_name: 'monkeypod, filinganga, gumorni,',
    Page: '63',
  },
  {
    scientific_name: 'saman)',
    common_name: 'tamalini, trong-kon-mames',
  },
  {
    scientific_name: 'Sapindus saponaria',
    common_name: 'manele, soapberry',
    Page: '63',
  },
  {
    scientific_name: 'Scaevola sericea',
    common_name: 'naupaka, nanaso',
    Page: '63& 64',
  },
  {
    scientific_name: 'Schfflera arboricola',
    common_name: 'dwarf brassaia',
    Page: '64',
  },
  {
    scientific_name: 'Scirpus maritimus var. paludosus',
    common_name: 'makai',
    Page: '64',
  },
  {
    scientific_name: 'Scleria sp.',
    Page: '64',
  },
  {
    scientific_name: 'Secale cereale',
    common_name: 'cereal rye',
    Page: '64',
  },
  {
    scientific_name: 'Senna guadichaudii',
    common_name: 'kolomona',
    Page: '64',
  },
  {
    scientific_name: 'Sesbania tomentosa',
    common_name: '‘ohai',
    Page: '64',
  },
  {
    scientific_name: 'Sesbania tomentosa f. arborea',
    common_name: '‘ohai',
    Page: '64',
  },
  {
    scientific_name: 'Setaria verticillata',
    common_name: 'bristly foxtail',
    Page: '65',
  },
  {
    scientific_name: 'Sida fallax',
    common_name: '‘ilima',
    Page: '65',
  },
  {
    scientific_name: 'Sida fallax',
    common_name: '‘ilima papa',
    Page: '65',
  },
  {
    scientific_name: 'Sophora chrysophylla',
    common_name: 'mamane',
    Page: '65',
  },
  {
    scientific_name: 'Sorghum bicolor',
    common_name: 'forage sorghum hybrid',
    Page: '65',
  },
  {
    scientific_name: 'Sorghum bicolor x Sorghum\r\nbicolor var. sudanese',
    common_name: 'sorghum x sudangrass hybrid',
    Page: '65',
  },
  {
    scientific_name: 'Sporobolus virginicus',
    common_name: '‘aki‘aki, totoput',
    Page: '65',
  },
  {
    scientific_name: 'Stenotaphrum secondatum',
    common_name: 'St. Augustinegrass',
    Page: '65',
  },
  {
    scientific_name: 'Stylosanthes scabra cv. Seca',
    common_name: 'shrubby stylo',
    Page: '65',
  },
  {
    scientific_name: 'Styphelia tameiameiae',
    common_name: 'pukiawe',
    Page: '66',
  },
  {
    scientific_name: 'Swietenia macrophylla',
    common_name: 'mahogany, broad leaved',
    Page: '66',
  },
  {
    scientific_name: 'Swietenia mahagoni',
    common_name: 'mahogany',
    Page: '66',
  },
  {
    scientific_name: 'Syzygium paniculatum (syn.\r\nEugenia myrtifolia)',
    common_name: 'Australian bush-cherry',
    Page: '66',
  },
  {
    scientific_name: 'Tabebuia heterophylla',
    common_name: 'pink tecoma',
    Page: '66',
  },
  {
    scientific_name: 'Tamarindus indica',
    common_name: 'tamarind',
    Page: '66',
  },
  {
    scientific_name: 'Terminalia catappa',
    common_name: 'false kamani, talie, talisai, miich, tropical almond',
    Page: '66',
  },
  {
    scientific_name: 'Thespesia populnea',
    common_name:
      'milo, binalo, banalo, rosewood, badrirt, kilulo, panu, polo, pone, bang-beng',
    Page: '66',
  },
  {
    scientific_name: 'Tournefortia argentea',
    common_name:
      'beach heliotrope, hunig, amoloset, chen, kiden, tausuni,\r\ntechel, titin, sruhsruh',
    Page: '67',
  },
  {
    scientific_name: 'Tradescantia spathacea',
    common_name: 'oyster plant',
    Page: '67',
  },
  {
    scientific_name: 'Trifolium repens',
    common_name: 'white clover',
    Page: '67',
  },
  {
    scientific_name: 'Triticum aestivum',
    common_name: 'wheat',
    Page: '67',
  },
  {
    scientific_name: 'Urochloa brizantha (syn.\r\nBrachiaria brizantha)',
    common_name: 'signalgrass',
    Page: '67',
  },
  {
    scientific_name: 'Urochloa maxima (syn. Panicum maximum var. trichoglume)',
    common_name: 'green panicgrass',
    Page: '67',
  },
  {
    scientific_name: 'Urochloa maxima (syn. Panicum\r\nmaximum)',
    common_name: 'guineagrass',
    Page: '67',
  },
  {
    scientific_name: 'Vaccinium reticulatum',
    common_name: '‘ohelo',
    Page: '67',
  },
  {
    scientific_name: 'Vicia villosa ssp. varia cv. Lana',
    common_name: 'vetch, woolypod',
    Page: '67',
  },
  {
    scientific_name: 'Vigna marina',
    common_name: 'nanea',
    Page: '68',
  },
  {
    scientific_name: 'Vigna unguiculata',
    common_name: 'cowpea',
    Page: '68',
  },
  {
    scientific_name: 'Vitex rotundifolia (syn. Vitex\r\novata)',
    common_name: 'pohinahina, beach vitex',
    Page: '68',
  },
  {
    scientific_name: 'Vitex trifolia var. variegata',
    common_name: 'blue vitex, nanulega',
    Page: '68',
  },
  {
    scientific_name: 'Waltheria indica',
    common_name: '‘uhaloa, escobilla sabana,\r\nwaltheria',
    Page: '68',
  },
  {
    scientific_name: 'Wikstroemia uva-ursi',
    common_name: '‘akia',
    Page: '68',
  },
  {
    scientific_name: 'Zea mays',
    common_name: 'corn',
    Page: '68',
  },
  {
    scientific_name: 'Zoysia japonica',
    common_name: 'zoysia',
    Page: '68',
  },
]

export default plants_flowers.reduce((obj, curr) => {
  const { common_name, scientific_name } = curr ?? {}
  if (!common_name) return obj

  obj[common_name] = scientific_name
  return obj
}, {})
