import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import {
  BUSINESS_FIELDS,
  FIELD_WITH_OTHER,
  PHONE_NUMBER,
  PO_BOX
} from '@modules/form-wizard/utils'

export default class UrbanRenewalContractor extends React.Component {
  resource = '/urban_renewal_contractors'
  form_key = 'urban_renewal_contractor'

  free = true
  skip_location = true
  business_only = true

  custom_actions = []

  validate = form => ({
    references: form.references.map(r => r.name)
  })

  fields = form => [
    ...BUSINESS_FIELDS(form),
    '::Contact Information',
    PO_BOX,
    PHONE_NUMBER,

    '::',
    {
      name: 'preferred_contituency',
      label: 'Preferred Constituency'
    },
    ...FIELD_WITH_OTHER(form, {
      name: 'area_of_competency',
      label: 'Area(s) of Competency',
      multi: true,
      options: [
        'Carpentry',
        'Electrical',
        'Masonry',
        'Painting',
        'Plumbing',
        'Other',
      ]
    }),
    {
      name: 'references',
      columns: [
        { name: 'name', label: 'Name of Person/Company' },
        { name: 'phone', label: 'Phone #', },
        { name: 'email', label: 'Email', },
      ],
      hint: `
        Please ensure that you have pictures of your work for the references
        you place in this list.
      `
    },

    '::Uploads',
    {
      key: 'image.0',
      name: 'photo_upload',
      type: 'file:image',
      label: 'Passport Sized Photo',
    },
    {
      key: 'image.0',
      name: 'business_license_upload',
      type: 'file:all',
      label: 'Business Licence',
    },
    {
      key: 'image.1',
      name: 'tax_comp_cert_upload',
      type: 'file:all',
      label: 'Tax Compliance Certificate',
    },
    {
      key: 'image.2',
      name: 'reference_upload',
      type: 'file:all',
      label: '1st Reference',
    },
    {
      key: 'image.3',
      name: 'reference_2_upload',
      type: 'file:all',
      label: '2nd Reference',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
