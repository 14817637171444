import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class LpgSubDealerApplication extends React.Component {
  resource = '/liquid_petroleum_gas'
  form_key = 'liquid_petroleum_gas'
  defaults = {}

  // Delete lines as needed
  fee = ({ license_type }) => ({
    'dealer': 300.0,
    'sub-dealer': 150.0,
    'installer': 30.0,
  })[license_type]
  // free = true
  // postpaid = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  /*
  t.boolean :renewal, default: false
      t.string :license_type # dealer, sub-dealer, installer, container re-builder, or appliance dealer

      # Dealer Sub-Dealer or Installer
      t.string :certified_technicians_list, array: true, default: []
      t.string :storage_capacity
      t.attachment :insurance_letter_upload
      t.string :manager_f_name
      t.string :manager_l_name

      # Rebuilder of Containers
      t.string :rebuilding_equipment_list, array: true, default: []
      t.string :welding_equipment_details
      t.string :test_jacket_details
      t.string :certified_welders_list, array: true, default: []

      # Appliance Dealer
      t.string :appliance_manufacturer
      t.string :appliance_type
      t.string :appliance_model
      t.attachment :appliance_verification_upload
  */

  fields = form => [
    {
      name: 'license_type',
      label: 'What type of license are you applying for?',
      options: [
        'dealer',
        'sub-dealer',
        'installer',
      ],
    },
    {
      name: 'renewal',
      label: 'Are you applying for issue or renewal a license?',
      type: 'select:bool',
    },
    {
      name: 'storage_capacity',
      label: 'Storage capacity of appliances',
    },
    {
      name: 'insurance_letter_upload',
      label: 'Insurance letter',
      key: 'image.0',
      type: 'file:all',
    },
    '::Person who will manage operations',
    {
      name: 'manager_f_name',
      label: 'First Name',
    },
    {
      name: 'manager_l_name',
      label: 'Last Name',
    },

    '::Rebuilder of Containers',
    {
      name: 'welding_equipment_details',
      type: 'textarea',
    },
    {
      name: 'test_jacket_details',
      type: 'textarea',
    },
    {
      name: 'certified_welders_list',
      label:
        'Name and welding certificates of persons who will be carrying out the welding',
      type: 'textarea',
    },
    '::Appliance Dealer',
    {
      name: 'appliance_manufacturer',
    },
    {
      name: 'appliance_type',
    },
    {
      name: 'appliance_model',
    },
    {
      name: 'appliance_verification_upload',
      label:
        'Verification that the appliances have been manufactured in accordance with either the American or Canadian Gas Association standards',
      key: 'image.1',
      type: 'file:all',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
