import YAML from 'yaml'

import templateString from '@modules/template-string'

import ENV from './env'
import INFO from './strings/info.yml'
import ERRORS from './strings/errors.yml'
import SUCCESS from './strings/success.yml'
import SERVICE from './strings/services.yml'
import GENERAL from './strings/general.yml'

const replacements = {
  MYGATEWAY_LINK: `<a href='${ENV.PARENT_SITE_URL}'>${ENV.PARENT_SITE_NAME}</a>`,

  TECH_SUPPORT: `
    <br/>
    For technical support, please contact the Helpdesk at
    <a href='tel:2426044357'>(242) 604-4357</a> or toll free
    at <a href='2423005717'>(242) 300-5717</a>.
  `,
}

export default Object.entries({INFO, ERRORS, SUCCESS, SERVICE, GENERAL}).reduce((strings, [key, file]) => {
  strings[key] = {}

  try {
    const add = Object.entries(YAML.parse(file) || {})

    if (add.length) {
      for (let [k2, v] of add) {
        strings[key][k2] = templateString(v, replacements)
      }
    }

    return strings
  } catch (err) {
    console.error(err)
    return strings
  }
}, {})