import React, { Fragment } from 'react'
import { GrCertificate } from 'react-icons/gr'
import Swal from 'sweetalert2'
import Modal from 'react-responsive-modal'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'
// import InputField from '@shared/form-fields/input'
import Loader from '@shared/loader'

import countries from '@constants/countries'
import convertToBase64 from '@helpers/file-to-base64'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import toastr from '@modules/toastr'
import DateTimeFieldV2 from '@shared/form-fields/datetime'

export default class ChassisCheck extends React.Component {
  state = {
    year: false,
    make: false,
    model: false,
    colour: false,
    status: false,
    vinCheck: false,
  }

  // fee = 50
  free = true
  resource = '/chassis_checks'
  form_key = 'chassis_check'
  // postpaid = true
  custom_postpaid_message =
    `Thank you for using MyGateway. Your request for Vehicle Transfer/Chassis Check has been successfully submitted and will be reviewed. 
    An email confirming the licence approval or denial will be sent to your inbox. 
    If approved, you will receive a notification to proceed with the payment for your service.`
  pay_on_approval = false
  skip_location = true
  has_appointment = true

  hide_buttons = {
    status: true,
    deny: false,
    fee: true,
  }

  state = {
    loading: false,
  }

  // defaults = {
  //   renewal: this.isRenewal,
  // }

  // read_params = {
  //   admin: true,
  //   label: ' ',
  //   default: 'Default',
  //   values: {
  //     'Default': {
  //       renewal: this.isRenewal,
  //     },
  //   },
  // }

  custom_actions = [
    {
      text: 'Approve Appointment',
      icon: 'check',
      roles: ['officer'],
      test: r => r.application_decision == 'pending' || r.application_decision == 'processing',
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Approve Appointment',
          text: `
            Are you sure you want to approve this application and allow
            the applicant to come in to verify documents on their chosen appointment date? 
          `,
          showCancelButton: true,
        })

        if (isDismissed) return
        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/accept_appointment', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'awaiting appointment',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'awaiting appointment'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          toastr.success('Success', 'Application appointment approved successfully')
        } catch (err) {
          console.error(err)
          Swal.fire(
            'Oops...',
            'An error occurred while approving the application',
            'error'
          )
        }
      }
    },
    {
      text: 'Approve For Payment',
      icon: 'check',
      roles: ['officer'],
      test: r => r.application_decision == 'awaiting appointment',
      fn: async record => {
        const { isDismissed } = await Swal.fire({
          icon: 'question',
          title: 'Approve For Payment',
          text: `
            Are you sure you want to approve this application and allow
            the applicant to pay? 
          `,
          showCancelButton: true,
        })

        if (isDismissed) return
        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_payment_amount', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'awaiting payment',
            },
          })

          if (location?.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'awaiting payment'

            history.replace(location.pathname, {
              ...location.state,
              view,
            })
          }

          toastr.success('Success', 'Application payment set successfully')
        } catch (err) {
          console.error(err)
          Swal.fire(
            'Oops...',
            'An error occurred while approving the application',
            'error'
          )
        }
      }
    },
    {
      text: 'Mark as paid',
      icon: <GrCertificate className='text-blue-600' />,
      roles: ['officer'],
      test: r => r.application_decision == 'awaiting payment' || r.application_decision == 'awaiting appointment',
      fn: async record => {
        const { value: receipt_numb, isDismissed } = await Swal.fire({
          title: 'Set Invoice Number',
          input: 'text',
          inputLabel: 'Tag Number',
          inputPlaceholder: 'Tag Number',
          text: `Please set the applicant's receipt #`,
          showCancelButton: true,
          confirmButtonText: 'Set receipt #',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/mark_as_paid', {
            [form_key]: {
              form_num: record.form_num,
              paymyent_invoice: receipt_numb,
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.paymyent_invoice = receipt_numb

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application Receipt # set')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to set receipt #')
          return
        }

        this.setState({ loading: false, record: null })
      }
    }
    // {
    //   text: 'Set Appointment Date',
    //   icon: 'chevron-right',
    //   roles: ['police'],
    //   test: r => r.application_decision == 'awaiting appointment',
    //   fn: async record => this.setState({ record })
    // },
  ]

  validate = async form => {
    const update = {}

    let index = 0

    if(form.bill_of_sales_upload[0]) {
      update.bill_of_sales_upload = []

      for (let row of form.bill_of_sales_upload) {
        const {
          file_upload,
          ...item
        } = row
        index ++

        if(file_upload){
          item.file_upload = await convertToBase64(file_upload);
          item.title = `Bill of Sale - ${index}`;
          item.desc = `Bill of Sale - ${index}`;
          update.bill_of_sales_upload.push(item);
        }
      }
  }

    return update
  }

  // columns = props => [
  //   {
  //     name: 'Current Stage',
  //     selector: 'application_decision',
  //     width: '300px',
  //     cell: r => (<span>{r.application_decision.toUpperCase()}</span>),
  //     searchable: r => r,
  //   },
  // ]

  fields = form => [
    {
      name: 'purpose',
      label: 'Purpose for Chassis Check',
      options: ['Insurance Company', '3rd Party Sale', 'Shipping'],
    },
    {
      name: 'buyer_or_seller',
      label: 'Are you the buyer or the seller?',
      options: ['Buyer', 'Seller'],
      hide: form.purpose !== '3rd Party Sale',
    },
    '::Vehicle Details',
    {
      name: 'VIN',
      label: 'Chassis/Serial/VIN #',
      hint: form.new_vehicle
        ? 'VIN number will be verified by RTD to return other car info'
        : '',
      callback: async val => {
        try {
          const url = '/chassis_checks/fetch_rtd/' + val
          const { data } = await $app.axios.get(url)

          this.setState(state => {
            for (let [k, v] of Object.entries(data['RTD Data'])) {
              state[k] = !!v
            }

            state.vinCheck = true
            return state
          })

          return {
            active: data['RTD Data'].status == 'Active',
            vehicle_year: data['RTD Data'].year ?? '',
            vehicle_make: data['RTD Data'].make ?? '',
            vehicle_model: data['RTD Data'].model ?? '',
            vehicle_color: data['RTD Data'].colour ?? '',
          }
        } catch (error) {
          console.error(error)

          this.setState(state => {
            for (let k of Object.keys(state)) {
              state[k] = false
            }

            return state
          })

          return {
            active: false,
            vehicle_year: '',
            vehicle_make: '',
            vehicle_model: '',
            vehicle_color: '',
          }
        }
      },
    },
    {
      name: 'vehicle_make',
      disabled: this.state.make,
    },
    {
      name: 'vehicle_model',
      disabled: this.state.model,
    },
    {
      name: 'vehicle_year',
      disabled: this.state.year,
    },
    {
      name: 'vehicle_color',
      disabled: this.state.colour,
    },
    {
      name: 'plate_number',
      required: false,
    },
    {
      name: 'insurance_company',
      // label: '',
      required: false,
    },
    {
      name: 'vehicle_price',
      label: 'Amount paid for vehicle',
      type: 'currency',
    },
    // {
    //   name: 'active',
    //   type: 'select:bool',
    //   disabled: this.state.status,
    // },
    {
      name: 'steering_wheeel_position',
      options: ['Right', 'Left'],
    },
    {
      name: 'purchased_from',
      label: 'Where was this vehicle purchased from?',
      options: ['Locally', 'Abroad'],
    },
    {
      name: 'country_of_origin',
      label: 'Country this vehicle was purchased from?',
      options: countries,
      hide: form.purchased_from === 'Locally'
    },
    {
      name: 'seller_first_name',
      hide: form.buyer_or_seller == 'Seller',
    },
    {
      name: 'seller_last_name',
      hide: form.buyer_or_seller == 'Seller',
    },
    {
      name: 'seller_dob',
      label: 'Seller Date of Birth',
      type: 'date',
      hide: form.buyer_or_seller == 'Seller',
    },
    {
      name: 'seller_drivers_license',
      label: "Seller Driver's License",
      hide: form.buyer_or_seller == 'Seller',
      is: 'integer',
    },
    {
      name: 'seller_address',
      label: 'Seller Address',
      hide: form.buyer_or_seller == 'Seller',
    },
    {
      name: 'seller_phone_number',
      label: 'Seller Phone Number',
      hide: form.buyer_or_seller == 'Seller',
      type: 'phone',
    },
    {
      name: 'seller_cell_number',
      label: 'Seller Cell Number',
      hide: form.buyer_or_seller == 'Seller',
      type: 'phone',
    },
    {
      name: 'buyer_first_name',
      hide: form.buyer_or_seller == 'Buyer',
    },
    {
      name: 'buyer_last_name',
      hide: form.buyer_or_seller == 'Buyer',
    },
    {
      name: 'buyer_dob',
      label: 'Buyer Date of Birth',
      type: 'date',
      hide: form.buyer_or_seller == 'Buyer',
    },
    {
      name: 'buyer_drivers_license',
      label: "Buyer Driver's License",
      hide: form.buyer_or_seller == 'Buyer',
      is: 'integer',
    },
    {
      name: 'buyer_address',
      label: 'Buyer Address',
      hide: form.buyer_or_seller == 'Buyer',
    },
    {
      name: 'buyer_phone_number',
      label: 'Buyer Phone Number',
      hide: form.buyer_or_seller == 'Buyer',
      type: 'phone',
    },
    {
      name: 'buyer_cell_number',
      label: 'Buyer Cell Number',
      hide: form.buyer_or_seller == 'Buyer',
      type: 'phone',
    },
    '::Uploads',
    {
      name: 'windshield_license_decal_upload',
      label: 'Windshield License Decal',
      type: 'file:image',
    },
    {
      name: 'motor_vehicle_cert_upload',
      label: 'Motor Vehicle Title Certificate',
      type: 'file:all',
    },
    // {
    //   name: 'proof_sale_upload',
    //   label: 'Bill of Sale',
    //   type: 'file:all',
    // },
    {
      heading: '<b>Please supply a bill of sale for each previous owner.</br>'
    },
    {
      name: 'bill_of_sales_upload',
      label: 'Bill of Sale',
      hint: 'Please supply a bill of sale for each previous owner.',
      view: false,
      fields: [
        {
          name: 'file_upload',
          type: 'file:all',
        },
      ],
    },
    "::SPACER",
    {
      name: 'declare',
      type: 'checkbox',
      label: `
        I acknowledge and understand that the vehicle must be present
        at the inspection appointment and that I must bring the physical documents
        of all uploaded documents.
      `,
    },
    // {
    //   name: 'acceptance',
    //   type: 'checkbox',
    //   use_as_acceptance: true,
    //   label: `
    //     I declared that the information entered is true and correct to the best of my knowledge.
    //   `,
    // },
  ]

  closeModal = () => this.setState({ record: null })

  setAppt = async () => {
    const { props, state, resource, form_key } = this
    const { history, location } = props
    const { date, record } = state

    this.setState({ loading: true })

    try {
      await $app.axios.put(resource + '/update_appointment_date', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          appointment_date: new Date(date)
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      toastr.success('Success', 'Application successfully updated')
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your application',
        'error'
      )
    } finally {
      this.setState({ record: null, loading: false })
    }
  }

  render = () => (
    <Fragment>
      <Loader loading={this.state.loading} />
      <Wizard {...SERVICE_PROPS(this)} />
      <Modal
        open={!!this.state.record && !this.state.loading}
        onClose={this.closeModal}
        classNames={{ modal: 'w-full sm:w-128' }}
        center
      >
        <header className='modal-header'>
          <h4 className='mb-0'>Set Appointment Date</h4>
        </header>
        <ValidatorForm onSubmit={this.setAppt}>
          <article className='modal-body'>
            <label htmlFor='date' className='form-label'>
              Please set the applicant's appointment date
            </label>
            <DateTimeFieldV2
              // type='datetime'
              name='date'
              icon={null}
              min={new Date().toISOString().slice(0, 10)}
              value={this.state.date}
              // onChange={ev => console.log(ev)}
              onChange={ev => this.setState({ date: ev })}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
              className='form-control'
            />
          </article>
          <footer className='modal-footer'>
            <button type='button' className='btn' onClick={this.closeModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </footer>
        </ValidatorForm>
      </Modal>
    </Fragment>
  )
}
