import { Link } from 'react-router-dom'

const FarmInspectionLayout = ({ children }) => (
  <div
    id='farm-inspection-form'
    className='wrapper wrapper-login wrapper-login-full p-0'
  >
    <div className='login-aside flex flex-col items-center justify-center text-center bg-secondary-gradient'>
      <h1 className='title fw-bold text-white mb-3'>
        Farm Inspection
      </h1>
      <p className='subtitle text-white op-7'>Dept. of Agriculture</p>

      <nav>
        <Link to='/logout'>
          <i className='fas fa fa-sign-out-alt' aria-hidden='true'></i>
          <span>Logout</span>
        </Link>
        {/*
        <button>
          <i className='fas fa fa-search' aria-hidden='true'></i>
          <span>Find Application</span>
        </button>
        */}
      </nav>
    </div>
    <div className='login-aside d-flex align-items-center justify-content-center bg-white'>
      <div className='container container-login container-transparent animated fadeIn'>
        {children}
      </div>
    </div>
  </div>
)

export default FarmInspectionLayout
