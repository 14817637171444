const template = (str, obj) => {
  const keys = new Set(str.match(/\{[a-z0-9_]+\}/gi))
  if (!keys) return str

  return Array.from(keys).reduce((final, key) => {
    const rx = new RegExp(key, 'g')
    const val = find(obj, key.slice(1, -1))
    return final.replace(rx, [null, undefined].includes(val) ? '' : val)
  }, str)
}

function find(obj, _path) {
  const path = typeof _path == 'string' ? _path.split('.') : _path
  if (!obj || !path.length) return obj

  const prop = path.shift()
  return find(obj[prop], path)
}

export default template
