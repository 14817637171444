import React from 'react'
import '@styles/loader.css'

const Loader = ({ loading, message }) =>
  loading ? (
    <div className='loader-container'>
      <div className='animate-spin'>
        <i className='fas fa-circle-notch' />
      </div>
      <span className='mt-4 text-white'>
        {message ?? loading?.initialCaps?.()}
      </span>
    </div>
  ) : null

export default Loader
