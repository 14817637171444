import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'
import { commonBanks } from '@constants/banking-institutions'
import Swal from 'sweetalert2'
import { fullName } from '@modules/form-wizard/utils'
import { sportsFederations, sportsList } from '@constants/mysc-constants'

export default class SubventionProgram extends React.Component {
  resource = '/sports_subvention_programs'
  form_key = 'sports_subvention_program'
  defaults = {}

  // Delete lines as needed
//   fee           = 99
  free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons = {
    status: true,
    deny: !$app.hasAnyRole(['deputy_director']),
  }
  customTitle = () => {
    if ($app.hasRole('officer')) return 'Recommend To Assistant Director';
    if ($app.hasRole('deputy_director')) return 'Approve Application';
    // if ($app.hasRole('department_officer')) return 'Recommend To ADOC';
    // if ($app.hasAnyRole(['deputy_director'])) return 'Recommend To Director';
    // if ($app.hasAnyRole(['director'])) return 'Approve Application';
    // if ($app.hasAnyRole(['under_secretary', 'permanent_secretary'])) return 'Recommend To Minister';
    // if ($app.hasRole('minister')) return 'Approve Application';
  }

  custom_actions = [
    {
      text: this.customTitle(),
      icon: 'user-tie',
      roles: [['officer', 'deputy_director']],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: async record => {
        const isFinalApprover = $app.hasAnyRole(['deputy_director']);
        const { value, isDismissed } = await Swal.fire({
          icon: 'question',
          title: this.customTitle(),
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': isFinalApprover ? "I approve this applicant" : "I recommend this applicant",
            'denied': isFinalApprover ? "I will deny this applicant" : "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (isDismissed) return

        await this.submitToNewLevel(value, record)
      },
    },
  ]

  submitToNewLevel = async (value, record) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    const newAppDecision = () => {
      if ($app.hasRole('officer')) return 'officer viewed';
      if ($app.hasRole('deputy_director')) return value;
    }

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = newAppDecision()

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application successfully submitted.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application',
        'error'
      )
      return
    }
  }

  validate = null

  foremost = null

  columns = props => [
    {
      name: 'Federation',
      selector: row => row.federation_name?.capitalize() || 'N/A',
      sortable: true,
      searchable: r => r.federation_name,
    },
    {
      name: 'Athlete Name',
      selector: row => {
        const firstName = row.applicant_first_name?.capitalize();
        const middleName = row.applicant_middle_name ? `${row.applicant_middle_name[0].capitalize()}. ` : '';
        const lastName = row.applicant_last_name?.capitalize();

        if (firstName) {
          return `${firstName} ${middleName}${lastName}`
        } else { return 'I am applicant' }
      },
      sortable: true,
      searchable: r => r?.applicant_last_name,
    },
  ]

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },

    "::Athlete Information",
    {
        name: 'applicant_first_name',
        label: 'First Name',
        hide: form.i_am_applicant !=false
    },
    {
        name: 'applicant_middle_name',
        label: 'Middle Name',
        hide: form.i_am_applicant !=false,
        required: false,
    },
    {
        name: 'applicant_last_name',
        label: 'Last Name',
        hide: form.i_am_applicant !=false
    },
    {
        name: 'applicant_gender',
        label: 'Gender',
        options: ['male', 'female'],
        hide: form.i_am_applicant !=false
    },
    {
        name: 'applicant_date_of_birth',
        label: 'Date of birth',
        type: 'date',
        showYearDropdown: true,
        hide: form.i_am_applicant !=false
    },
    {
        name: 'applicant_phone_number',
        label: 'Phone Number',
        type: 'phone',
        hide: form.i_am_applicant !=false
    },
    {
        name: 'applicant_email',
        label: 'Email',
        type: 'email',
        hide: form.i_am_applicant !=false
    },
    {
        name: 'applicant_birth_country',
        label: 'Country of birth',
        options: countries,
        // hide: form.i_am_applicant !=false,
    },
    {
        name: 'applicant_religion',
        label: 'Religion',
        required: false,
    },
    {
        name: 'applicant_nationality',
        label: 'Nationality',
        options: nationalities,
        hide: form.i_am_applicant !=false,
    },
    {
        name: 'applicant_marital_status',
        label: 'Marital Status',
        options: ['single', 'married', 'widowed', 'separated', 'divorced']
    },
    {
        name: 'spouse_first_name',
        label: `Spouse's first name`,
        hide: form.applicant_marital_status != 'married'
    },
    {
        name: 'spouse_last_name',
        label: `Spouse's last name`,
        hide: form.applicant_marital_status != 'married'
    },
    {
        name: 'applicant_mailing_address',
        label: 'Mailing Address (City, P.O.Box, etc.)',
        hint: 'local and abroad',
        type: 'textarea'
    },

    {
        name: 'have_children',
        label: 'Do you have children?',
        type: 'select:bool',
        view: {
            value: (v) => (form.children_numb > 0 ? true : false),
          },
    },

    "::SPACER",
    {
        heading: "Number of Children",
        hide: !form.have_children,
        save: false,
        view: true,
    },
    {
        name: 'children_numb',
        label: 'How many children do you have?',
        type: 'integer',
        view: {
            value: (v) => (v.toString()),
        },
        hide: !form.have_children,
    },
    {
        name: 'children_list',
        label: 'Child',
        rowLabel: 'Child',
        hide: !form.have_children,
        fields: [
            {
                name: 'first_name'
            },
            {
                name: 'middle_name',
                required: false,
            },
            {
                name: 'last_name'
            },
            {
                name: 'sex',
                options: ['male', 'female']
            },
            {
                name: 'birth_date',
                label: 'Date of birth',
                type: 'date',
                showYearDropdown: true,
            },
            {
                name: 'birth_country',
                label: 'Country of birth',
                options: countries
            }

        ]
    },
    "::SPACER",
    "::Family Information",
    {
        name: 'parents_list',
        label: 'Parent',
        rowLabel: 'Parent',
        fields: [
            {
                name: 'first_name'
            },
            {
                name: 'middle_name',
                required: false,
            },
            {
                name: 'last_name'
            },
            {
                name: 'relation_to_applicant',
                options: ['father', 'mother']
            },
            {
                name: 'birth_date',
                label: 'Date of birth',
                type: 'date',
                showYearDropdown: true,

            },
            {
                name: 'birth_country',
                label: 'Country of birth',
                options: countries
            },
            {
                name: 'nationality',
                options: nationalities
            },
        ]
    },
    "::SPACER",
    {
        name: 'applicant_employment_status',
        // label: '',
        options: ['employed', 'unemployed', 'self employed']
    },
    {
        name: 'position_held',
        // label: '',
        hide: form.applicant_employment_status != 'employed' 
    },
    {
        name: 'date_employed',
        label: 'Date of Employment',
        type: 'date',
        showYearDropdown: true,
        hide: form.applicant_employment_status != 'employed' 
    },

    "::Banking Info",
    {
        name: 'bank_name',
        label: 'Name of Bank',
        options: commonBanks
    },
    {
        name: 'bank_number',
        label: 'Bank Account #',
        type: 'integer'
    },
    {
        name: 'bank_account_name',
        label: 'Name on the bank account'
    },
    {
        name: 'bank_routing_number',
        // label: '',
        type: 'integer'
    },
    {
        name: 'branch_information',
        // label: '',
        type: 'textarea'
    },
    "::Education",
    {
        name: 'high_school',
        label: 'High School/College'
    },
    {
        name: 'entry_date',
        // label: '',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'leaving_date',
        // label: '',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'certificates_list',
        label: 'Diploma(s) or Degree(s)',
        columns: ['certificate'],
        format: v => v.certificate,
    },
    
    "::SPACER",
    "::Athletic Career",
    {
        name: 'federation_name',
        label: 'Name of Organization',
        options: sportsFederations,
        
    },
    {
        name: 'sports_list',
        label: 'Sport(s) played',
        // multi: true, 
        options: sportsList,
    },
    {
        name: 'highest_level',
        label: 'Highest level of competition'
    },
    {
        name: 'accomplishments_list',
        label: 'Awards and accomplishments',
        hint: 'List any athletic awards, recognitions or achievements',
        columns: ['accomplishment'],
        format: v => v.accomplishment,
    },
    {
        name: 'first_time',
        label: `Is this your first time on the Government of The Bahamas' subvention program?`,
        type: 'select:bool'
    },
    {
        name: 'first_time_when',
        label: 'When was your first time?',
        type: 'date',
        showYearDropdown: true,
        hide: form.first_time
    },
    {
        name:'subvention_level',
        label: 'Level of subvention received',
        hint: 'e.g. Elite, Developmental',
        hide: form.first_time
    },
    {
        name: 'doping_rules',
        label: 'Have you ever violated any anti-doping rules or regulations and received suspensions?',
        type: 'select:bool'
    },
    {
        name: 'doping_rules_when',
        label: 'When did you violate the anti-doping rules?',
        type: 'date',
        showYearDropdown: true,
        hide: !form.doping_rules
    },
    {
        name: 'doping_suspension_reason',
        label: 'What was the reason for your suspension?',
        hide: !form.doping_rules
    },
    {
        name: 'suspension_length',
        label: 'How long were you suspended?',
        hide: !form.doping_rules
    },
    "::Uploads",
    {
        name: 'financials_upload',
        key: 'image.0',
        type: 'file:all',
        hide: form.first_time
    },
    {
        name: 'events_calendar_upload',
        label: 'Calendar of Events',
        key: 'image.1',
        type: 'file:all'
    },
    {
        name: 'photo_upload',
        type: 'file:image',
        key: 'image.2'
    },
    "::SPACER",
    {
        name: 'declare',
        type: 'checkbox',
        use_as_acceptance: true,
        label: `
          I am committed to ensuring clean and ethical competition.
        `
      }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
