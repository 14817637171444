const mapServiceName = serviceID => {
  if (!serviceID) return null

  switch (serviceID) {
    case 'PoliceCertificate':
      return 'Character Certificate'
    case 'DriverLicenseReplacement':
      return 'Licence Replacement'
    case 'DriversLearnersPermitRenewal':
      return 'Permit Renewal'
    case 'DrivingPermit':
      return 'New Permit'
    default:
      return serviceID
        .replace(/Drivers/, "Driver's")
        .replace(/License/, 'Licence')
        .spaceBeforeCap()
  }
}

export default mapServiceName
