import React from 'react'
import { connect } from 'react-redux'
import { Map, InfoWindow, Marker } from 'google-maps-react'

import { getAgencyLocations } from '@actions/agencies'

import Loader from '@shared/loader'
import { extractKeys } from '@helpers/objects'
import withGoogleMaps from '@wrappers/gmaps'

import '@styles/map.css'

const styles = {
  container: {
    position: 'relative',
    paddingTop: '4rem',
    height: 'calc(100vh - 132px)',
  },
}

class Locations extends React.Component {
  state = {
    location: {},
    marker: null,
  }

  componentDidMount() {
    this.props.getAgencyLocations()
  }

  on = {
    click: {
      marker: location => (props, marker, ev) =>
        this.setState({
          marker,
          location,
        }),

      map: () =>
        this.setState({
          marker: null,
          location: null,
        }),
    },
  }

  generate = {
    markers: locations => {
      if (!locations || !locations.length) return null

      const { marker } = this.on.click

      return locations.reduce((all, agency) => {
        const markers = agency.locations.map((loc, i) => {
          const name = `${agency.agency} #${i}`

          return (
            <Marker
              key={`${agency.id}-${i}`}
              name={name.capitalize()}
              position={{ lat: loc.latitude, lng: loc.longitude }}
              onClick={marker({ ...loc, name })}
            />
          )
        })

        return [...all, markers]
      }, [])
    },

    info: ({ marker, location }) => {
      if (!marker) return null

      const phoneText = location.phone.replace(
        /(.{3})(.{3})(.{4})/,
        '($1) $2-$3'
      )

      return (
        <aside className='agency-map__location-info'>
          <h6>{location.name}</h6>
          <address>
            {location.street_address}
            <br />
            {location.island}, Bahamas
          </address>
          <dl>
            <dt>Phone</dt>
            <dd>
              <a href={'tel:' + location.phone}>{phoneText}</a>
            </dd>
            <dt>P. O. Box</dt>
            <dd>{location.pobox}</dd>
          </dl>
        </aside>
      )
    },
  }

  render() {
    const { state, props, generate } = this
    const { marker } = state

    const config = {
      map: {
        google: props.google,
        zoom: 12,
        initialCenter: {
          lat: 25.02735461,
          lng: -77.3606875,
        },
      },
    }

    if (!props.locations) return <Loader loading={true} />

    return (
      <div id='map-page' style={styles.container}>
        <Map {...config.map}>
          {generate.markers(props.locations)}
          <InfoWindow visible={!!marker} marker={marker}>
            <generate.info {...state} />
          </InfoWindow>
        </Map>
      </div>
    )
  }
}

export default withGoogleMaps(
  connect(state => extractKeys(state.agencies, 'locations'), {
    getAgencyLocations,
  })(Locations)
)
