import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'

import Loader from '@shared/loader'
import { extractKeys } from '@helpers/objects'
import SERVICE_PROPS from '@helpers/extract-service-props'
import toastr from '@modules/toastr'
import InputField from '@shared/form-fields/input'
import DateField from '@shared/form-fields/date'
import Wizard from '@modules/form-wizard'
import ENV from '@constants/env'

export default class NotaryRenewal extends React.Component {
  title = 'Notary Renewal'
  resource = '/notary_renewals'
  form_key = 'notary_renewal'
  postpaid = true

  state = {
    fee_per_year: '',
    expiration_date: '',
    loading: false,
    record: null,
  }

  closeModal = () => this.setState({ record: null })

  updateView = update => {
    const { history, location } = this.props
    const inview = location.state?.view

    // prettier-ignore
    inview && history.replace(location.pathname, {
      ...location.state,
      view: { ...inview, ...update },
    })
  }

  on = {
    // prettier-ignore
    fee: ev => this.setState({
      fee_per_year: ev.target.value.replace(/\D/, '')
    }),

    date: val => this.setState({ expiration_date: val }),

    submit: async ev => {
      this.setState({ loading: true })

      const { state, resource, form_key } = this
      const { record, fee_per_year } = state

      const message = payment => `
        Good day ${record.user.first_name.capitalize()},
        <br />
        Please see below your Service Fee needed to complete
        your Notary Renewal Form.
        <br /><br />
        Service Fee : <b>$${payment}</b><br />
        <br/><br />
        <a href='${ENV.PILOT_URL}'>Click here</a>
        to complete payment on the Portal.
        <br/><br />
        Thank you for using the MyGateway Portal.
      `

      try {
        const { data } = await $app.axios.put(
          resource + '/update_payment_amount',
          {
            [form_key]: {
              form_num: record.form_num,
              fee_per_year: parseInt(fee_per_year, 10),
              ...extractKeys(state, 'expiration_date'),
            },
          }
        )

        const { payment_amount } = data[form_key]

        payment_amount
          ? toastr.success('Success', 'Application fee updated.')
          : toastr.error('Error', 'Unable to set payment amount')

        this.updateView({ payment_amount })

        try {
          const { data } = await $app.axios.post('/emails', {
            email: record.user.email,
            subject: 'MyGateway Portal: Notary Renewal Payment',
            message: message(Number(payment_amount).toFixed(2)),
          })

          data.message && !data.error
            ? toastr.success('Success', 'Email sent to citizen')
            : toastr.error('Error', data.message || data.error)
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to send email to citizen')
        }
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to set Payment Amount')
        return
      }

      this.setState({ loading: false, record: null })
    },
  }

  hide_buttons = {
    fee: true,
  }

  custom_actions = [
    {
      text: 'Set Fee',
      icon: 'dollar-sign',
      fn: record => this.setState({ record }),
    },
  ]

  columns = () => [
    {
      name: 'Licence Number',
      selector: 'license_code',
    },
  ]

  fields = () => [
    {
      name: 'license_code',
      label: 'Notary Licence Number',
    },
    {
      label: 'Expired Licence',
      name: 'expired_license_upload',
      type: 'file:pdf',
    },
  ]

  hidden_fields = () => [
    {
      type: 'date',
      name: 'expiration_date',
      label: 'Previous Expiration Date',
    },
  ]

  render() {
    const { state, on, closeModal } = this

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 640 }}
          onClose={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Set Payment Amount</h5>
          </div>
          <ValidatorForm onSubmit={on.submit}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='fee_per_year'>
                  Fee Per Year
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='fee_per_year'
                  icon='dollar-sign'
                  value={state.fee_per_year}
                  onChange={on.fee}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  required
                />
              </div>
              <div className='form-group form-show-validation'>
                <label htmlFor='expiration_date'>
                  Previous Expiration Date
                  <span className='required-label'>*</span>
                </label>
                <DateField
                  type='date'
                  className='form-control'
                  name={'end_date'}
                  onChange={on.date}
                  selected={state.expiration_date}
                  value={state.expiration_date}
                  maxDate={new Date()}
                  validators={['required']}
                  errorMessages={['Required']}
                  dateFormat='do MMMM, yyyy'
                  showYearDropdown
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-round mr-2'
                aria-label='Close'
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    )
  }
}
