import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class TheoryTest extends React.Component {
  resource = '/drivers_learners_permit_renewals'
  form_key = 'drivers_learners_permit_renewal'

  fee = 15

  fields = () => [
    {
      name: 'license',
      label: 'Permit #'
    },
    'passport',
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}