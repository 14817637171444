const commonInstitutions = [
  'Commonwealth Bank',
  'First Caribbean International Bank',
  'Royal Bank of Canada',
  'Scotiabank',
  'Finco',
  'Teacher\'s Credit Union',
]

const commonBanks = [
  'Bank of The Bahamas',
  'Central Bank of The Bahamas',
  'Commonwealth Bank',
  'First Caribbean International Bank',
  'Royal Bank of Canada', 
  'Scotiabank',
  'Fidelity'
]

export { commonInstitutions, commonBanks };
