import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class DisabilityForm extends React.Component {
  resource = '/disablity_forms'
  form_key = 'disablity_form'
  free     = false
  postpaid = false
  defaults = {}
  canned   = []

  skip_location     = false
  hide_buttons      = false
  custom_action     = false
  custom_acceptance = false

  fee = 15

  validate = null

  foremost = null

  fields = () => [
    {
      name: 'drivers_license',
      label: "Driver's Licence #",
    },
    {
      name: 'medical',
      type: 'file:pdf',
      label: 'Medical Sumary',
    },
    {
      name: 'assessment',
      type: 'file:image',
      label: 'Needs Assessment Slip',
    },
    {
      name: 'investigation',
      type: 'file:image',
      label: 'Home Investigation Slip',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
