import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class SecurityLicenceAppeal extends React.Component {
  resource = '/inquiry_and_security_licenses'
  form_key = 'inquiry_and_security_license'
  free     = true

  isSecurity = /security-/.test(this.props.location.pathname)

  defaults = {
    renewal: false,
    temporary: false,
    appeal: true,
    po_box: '#####',
    agent_type: this.isSecurity ? 'security guard' : 'inquiry agent',
  }

  hide_buttons = {
    status: true,
    deny: !$app.hasRole('minister'),
  }

  custom_acceptance = true
  skip_location = true
  custom_actions = []
  before_apply = false

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        temporary: false,
        renewal: false,
        appeal: true,
        agent_type: this.defaults.agent_type
      },
    },
  }

  filter_records = r => r.appeal

  fields = form => [
    {
      name: 'appeal',
      save: true,
      hide: true,
    },
    {
      name: 'agent_type',
      save: true,
      hide: true,
    },
    {
      name: 'po_box',
      save: true,
      hide: true,
    },
    {
      name: 'appeal_proof',
      type: 'textarea',
      label: 'Reason(s) For Reconsideration',
      long: true
    },
    {
      key: 'image.12',
      name: 'appeal_upload',
      type: 'file:all',
      label: 'Supporting Document(s)',
    },
    {
      name: 'final_declartion',
      long: true,
      label: `
        By clicking this box, I confirm that I understand my obligations under
        the respective laws and regulations applicable to the application for 
        security guards and/or inquiry agents licence and agree to observe
        all applicable laws, regulations, policies and guidelines.
      `,
      type: 'checkbox',
    },
    {
      name: 'acceptance',
      type: 'checkbox',
      use_as_acceptance: true,
      label: `
        By clicking here you agree that the information provided is accurate
        and lawful without false pretence
      `
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
