import { useState, useContext, createContext, createElement } from 'react'
import Swal from 'sweetalert2'

import Loader from '@shared/loader'
import ErrorBoundary from '@wrappers/error-boundary'

import Layout from './_layout'
import FarmSelection from './select-farm'
import InspectionForm from './form.inspection'

const InspectionContext = createContext()
export const useInspection = () => useContext(InspectionContext)

const STEPS = [
  FarmSelection,
  InspectionForm,
]

// const extractValues = (fields, form) => 
//   fields.reduce((obj, f) => {
//     if (typeof f == 'string') {
//       if (/^::/.test(f)) return obj

//       obj[f] = form[f]
//       return obj
//     }

//     if (f.heading || f.hide || !f.save) return obj

//     obj[f.name] = form[f.name]
//     return obj
//   }, {})

const FarmInspectionForm = (props) => {
  const [loading, setLoading] = useState(true)
  const [step, setStep] = useState(0)
  const [farm, setFarm] = useState(null)

  const ctx = {
    get step () {
      return step
    },

    set step (num = 0) {
      setStep(~~num)
    },

    get loading () {
      return loading
    },

    set loading (bool = true) {
      setLoading(!!bool)
    },

    get farm () {
      return farm
    },

    set farm (application = {}) {
      setFarm(application)
    },

    fetchApplications: async (renewal, includeAll = false) => {
      try {
        setLoading(true)
        const { data } = await $app.axios.get('/farm_registrations', {
          params: {
            renewal,
            per_page: 500,
            status: 'pending'
          }
        })
        setLoading(false)
  
        return includeAll
          ? data.records
          : data.records.filter(r => !r.inspector_copy
            && r.application_decision != 'approved'
            && r.application_decision != 'denied'
          )
      } catch (err) {
        console.error(err)
        return null
      }
    },

    submitInspectionForm: async form => {
      try {
        const { data } = await $app.axios.post('/farm_inspections', form)

        await $app.axios.put('/farm_inspections/update_application', {
          farm_inspection: {
            form_num: data.farm_inspection.form_num,
            application_decision: !!form.farm_inspection.farm_recommended
              ? 'approved'
              : 'denied'
          }
        })

        Swal.fire(
          'Form Submitted',
          'The Inpection Form has been successfully submitted',
          'success'
        )

        setStep(step -1)
      } catch (err) {
        console.error(err)

        await Swal.fire(
          'Error',
          `
            An error occurred while submitting the Farm Inspection Form
            Please contact an administrator or try again later.
          `,
          'error'
        )
      }
    },

    // updateInspectorRegistrationFormCopy: async form => {
    //   try {
    //     setLoading(true)
    //     await $app.axios.put('/farm_inspections/update_form', {
    //       farm_inspection: {
    //         form_num: farm.inspector_form_copy.farm_inspection_form.form_num,
    //         ...form,
    //       }
    //     })
        
    //     setLoading(false)

    //     await Swal.fire(
    //       'Form Copy Submitted',
    //       "Your copy of the Farmer's Registration Certificate was submitted",
    //       'success'
    //     )

    //     setStep(0)
    //     setFarm(null)
    //   } catch (err) {
    //     console.error(err)
    //     return null
    //   }
    // }
  }

  return (
    <Layout>
      <Loader loading={loading} />
      <InspectionContext.Provider value={ctx}>
        <ErrorBoundary>
          {createElement(STEPS[step], props)}
        </ErrorBoundary>
      </InspectionContext.Provider>
    </Layout>
  )
}

export default FarmInspectionForm
