import React from 'react'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import islands from '@constants/islands'
import dogBreeds from '@constants/dog-breeds'
import catBreeds from '@constants/cat-breeds'

const today = new Date()

export default class DogAndCatPermits extends React.Component {
  resource = '/cat_and_dog_permits'
  form_key = 'cat_and_dog_permit'
  defaults = {}

  // Delete lines as needed
  skip_location = true
  hide_buttons  = false

  custom_actions = [
    {
      text: 'Add Permit Note',
      icon: 'stamp',
      roles: [['officer', 'senior_officer']],
      fn: async record => {
        const { value: text } = await Swal.fire({
          input: 'textarea',
          title: 'Add Permit Note',
          inputAttributes: {
            autocorrect: 'on',
          },
          showCancelButton: true,
          confirmButtonText: 'Next',
          inputValidator: v => (!!v ? null : 'Note cannot be empty'),
        })
  
        try {
          const { data } = await $app.axios.post('/services/notes/add_note', {
            note: {
              notable_type: this.props.service.type,
              note_type: 'permit',
              internal_view_only: true,
              notable_id: record.id,
              text: text.trim(),
            },
          })
  
          record.notes = record.notes || []
          record.notes.push(data.note)
  
          toastr.success('Success', 'Permit Note successfully created')
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to create new permit note')
        }
      },
    }
  ]

  validate = form => ({
    no_consignee: !form.consignee
  })

  foremost = null

  fields = form => {
    const list = [
      '::Consignee Details',
      {
        name: 'consignee',
        type: 'select:bool',
        label: 'Do You Have A Consignee?'
      }
    ]

    if (form.consignee) {
      list.push(
        {
          name: 'consignee_first_name',
          label: 'First Name',
        },
        {
          name: 'consignee_middle_name',
          required: false,
          label: 'Middle Name',
        },
        {
          name: 'consignee_last_name',
          label: 'Last Name',
        },
        {
          name: 'consignee_email',
          label: 'Email Address',
          is: 'email'
        },
      )
    }

    list.push(
      '::Current Address of Animal',
      {
        name: 'permanent_street_address',
        label: 'Street Address',
      },
      {
        name: 'permanent_po_box',
        label: 'P. O. Box',
        required: false,
      },
      {
        name: 'permanent_building_numb',
        label: 'Building #',
      },
      {
        name: 'permanent_city',
        label: 'City',
      },
      {
        name: 'permanent_country',
        label: 'Country',
        options: countries,
      },
      {
        name: 'permanent_area_code',
        label: 'Area/Zip Code',
        hint: 'Enter "00000" if not applicable'
      },

      '::Address of Animal While In The Bahamas',
      {
        name: 'street_address',
        label: 'Street Address',
      },
      {
        name: 'po_box',
        label: 'P. O. Box',
        required: false,
      },
      {
        name: 'building_numb',
        label: 'Building #',
      },
      {
        name: 'city',
      },
      {
        name: 'island',
        options: islands,
      },

      '::Animal Details',
      {
        name: 'animal_type',
        label: 'Is The Animal A Cat Or Dog?',
        options: ['Cat', 'Dog']
      },
      {
        name: 'animal_breed',
        label: 'Breed of Animal',
        hint: 'If mixed, please select the primary/dominant breed',
        options: form.animal_type == 'Dog'
          ? dogBreeds
          : catBreeds
      },
      {
        name: 'animal_age_months',
        label: `Animal's Age (In Months)`,
      },
      {
        name: 'animal_sex',
        label: `Animal's Sex`,
        options: [
          { label: 'Male', value: 'male' },
          { label: 'Female', value: 'female' }
        ]
      },
      {
        name: 'neutered',
        type: 'select:bool',
        label: 'Is the animal neutered/spayed?',
      },
      {
        name: 'microchip_brand',
      },
      {
        name: 'microchip_numb',
        label: 'Microchip#',
      },
      {
        name: 'country_of_export',
        label: 'Country Departing From',
        options: countries,
      },
      {
        name: 'country_of_origin',
        label: 'Country of Origin',
        options: countries,
      },
      {
        name: 'intransit_countries',
        label: 'Countries Visiting In-Transit',
        multi: true,
        options: countries
      },
      {
        name: 'animal_birth_country',
        label: `Animal's Birth Country`,
        options: countries,
      },
      {
        name: 'countries_visited',
        label: 'Countries Animal Has Visited',
        multi: true,
        options: countries
      },
      {
        name: 'entry_port_island',
        label: 'Island of Entry',
        options: islands,
      },
      {
        name: 'entry_port_name',
        label: 'Port of Entry',
      },
      {
        name: 'date_of_arrival',
        label: 'Expected Date of Arrival',
        type: 'date',
        minDate: today
      },
      {
        name: 'import_purpose',
        label: 'Reason For Importing',
      },
      {
        name: 'expected_islands_visited',
        label: 'Other Island(s) Visiting',
        multi: true,
        options: islands,
        required: false
      },
      {
        name: 'duration_of_stay_days',
        is: 'integer',
        label: 'How Many Days Will The Animal Be In The Country?',
      },

      // '::Uploads',
      // {
      //   name: 'vaccination_upload',
      //   type: 'file:pdf',
      //   label: 'Vaccination Card/Certificate',
      // },
      // {
      //   name: 'photo_upload',
      //   type: 'file:pdf',
      //   label: 'Photo of Animal',
      // },
      // {
      //   name: 'rabies_test_upload',
      //   type: 'file:pdf',
      //   label: 'Rabies test',
      // },
    )

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
