import React from 'react'
import Modal from 'react-responsive-modal'

import '@styles/sweetalert.css'

const classes = {
  'success': 'success',
  'info': 'info',
  'warning': 'warning',
  'critical': 'danger',

  'maintenance mode': 'info',
}

const titles = {
  'success': 'Success',
  'info': 'Info',
  'warning': 'Warning',
  'critical': 'Error',

  'maintenance mode': 'Info',
}

const icons = {
  'success': (
    <div className='swal-icon swal-icon--success'>
      <span className='swal-icon--success__line swal-icon--success__line--long'></span>
      <span className='swal-icon--success__line swal-icon--success__line--tip'></span>
      <div className='swal-icon--success__ring'></div>
      <div className='swal-icon--success__hide-corners'></div>
    </div>
  ),

  'info': <div className='swal-icon swal-icon--info'></div>,

  'maintenance mode': <div className='swal-icon swal-icon--info'></div>,

  'warning': (
    <div className='swal-icon swal-icon--warning'>
      <span className='swal-icon--warning__body'>
        <span className='swal-icon--warning__dot'></span>
      </span>
    </div>
  ),

  'critical': (
    <div className='swal-icon swal-icon--error'>
      <div className='swal-icon--error__x-mark'>
        <span className='swal-icon--error__line swal-icon--error__line--left'></span>
        <span className='swal-icon--error__line swal-icon--error__line--right'></span>
      </div>
    </div>
  ),
}

const AlertModal = ({
  type,
  heading,
  message,
  hideBtn,
  btnText,
  btnColor,
  close,
  ...props
}) => (
  <Modal {...props} center>
    <div className='swal-modal' role='dialog' aria-modal='true'>
      {icons[type]}
      <div className='swal-title'>{heading || titles[type]}</div>
      <div
        className='swal-text'
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
      <div className='swal-footer'>
        <div className='swal-button-container'>
          {hideBtn ? null : (
            <button
              className={
                'swal-button swal-button--confirm btn btn-' + classes[type]
              }
              onClick={close || props?.onClose}
              style={btnColor ? { backgroundColor: btnColor } : undefined}
            >
              {btnText || 'OK'}
            </button>
          )}
        </div>
      </div>
    </div>
  </Modal>
)

export default AlertModal
