import React from 'react'
import AgriImport from './_agri-import'
import plants_flowers from '@constants/plants-flowers'

export default class PlantImport extends React.Component {
  render = () => {
    const { props } = this

    const aprops = {
      resource: '/plants_import_licenses',
      form_key: 'plants_import_license',
      _sid: 'plants import license',
      service: props.service,
      form_name: 'Plants/Flowers',
      importProps: {
        metric_list: [
          'Each Per Item',
          'Bunches per item',
          'Stems per item',
          'Pot gallon size per item',
          'Square foot per item',
          'Plants per item',
          'Pounds per item',
        ],
        cb: true,
        datalist: plants_flowers,
      },
      ...props,
    }

    return <AgriImport {...aprops} />
  }
}
