import React from 'react'
import { dramaCodes } from '../clementBethelArtsFestival/book-codes'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelDrama extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'island_deadline_date',
      label: 'Island Deadline Date',
      options: [
          'Abaco February 15th',
          'Acklins February 15th',
          'Andros April 1st',
          'Bimini May 1st',
          'Berry Islands May 1st',
          'Cat Island Feb 15th',
          'Crooked Island Feb 15th',
          'Eleuthera May 1st',
          'Exuma Feb 15th',
          'Grand Bahama April 1st',
          'Inagua Feb 15th',
          'New Providence March 1st',
          'Mayaguana Feb 15th',
          'San Salvador Feb 15th',
          'Rum Cay Feb 15th',
          'Ragged Island Feb 15th',
          'Long Island May 1st',
          'Other'
      ],
    },
    {
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.island_deadline_date !='Other',
    },
    {
      name: 'division',
      options: [
          'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Community COM (10 Dollars Single 20 Dollars Group/Ensemble)',
          'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)',
          'Other'
      ],
  },
  {
    name: 'division_other',
    label: ' ',
    hint: 'Please specify',
    type: 'textarea',
    hide: form.division != 'Other'
  },
    {
      name: 'class_title_pre',
      label: 'Class Title',
      options: dramaCodes.pre_school.categories[0].titles,
      hide: form.division != 'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
      name: 'class_title_primary',
      label: 'Class Title',
      options: dramaCodes.primary_school.categories[0].titles,
      hide: form.division != 'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
      name: 'class_title_junior',
      label: 'Class Title',
      options: dramaCodes.junior_high.categories[0].titles,
      hide: form.division != 'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
      name: 'class_title_senior',
      label: 'Class Title',
      options: dramaCodes.senior_high.categories[0].titles,
      hide: form.division != 'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
      name: 'class_title_com',
      label: 'Class Title',
      options: dramaCodes.open_and_community.categories[0].titles,
      hide: form.division != 'Community COM (10 Dollars Single 20 Dollars Group/Ensemble)'
    },
    {
      name: 'number_of_male_and_female',
      label: 'Please provide the number of females and males',
      options: ['one person', 'two persons', 'more than two persons']
    },
    {
    name: 'class_number_one',
    label: 'Class Number',
    hide: form.number_of_male_and_female != 'one person'
    },
    {
      name: 'class_number_two',
      label: 'Class Number',
      hide: form.number_of_male_and_female != 'two persons'
    },
    {
      name: 'class_number_more',
      label: 'Class Number',
      hide: form.number_of_male_and_female != 'more than two persons'
    },
    {
      name: 'works_name',
      label: 'Title of Work(s)',
    },
    {
      name: 'school_or_ensemble',
      label:'Name of school or Ensemble',
    },
    {
      name:'phone_number',
      type: 'phone',
    },
  {
      name:'other',
      label: ' ',
      hint: 'Please specify',
      hide: form.level !='Other',
  },
  {
    name: 'name_of_guardian',
    label: 'Name of Director/Teacher/Parent',
}

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
