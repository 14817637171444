import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import nationalities from '@constants/nationalities'

export default class NoiseCertificate extends React.Component {
  resource = '/noise_certificates'
  form_key = 'noise_certificate'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'First Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'address',
        label: 'Address of applicant',
        hide: form.i_am_applicant != false
    },
    {
        name: 'nationality',
        label: 'Nationality and Registration',
        options: nationalities
    },
    {
        name: 'aircraft_designation',
        label: 'Constructor and Designation of Aircraft',
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Aircraft Serial No.'
    },
    {
        name: 'engine_manufacturer'
    },
    {
        name: 'engine_type'
    },
    {
        name: 'engine_model'
    },
    {
        name: 'additional_mods',
        label: 'Additional modifications incorporated for the purpose of compliance',
        columns: ['modification(s)']
    },
    {
        name: 'propeller_manufacturer',

    },
    {
        name: 'propeller_type'
    },
    {
        name:'propeller_model'
    },
    {
        name: 'takeoff_mass',
        label: 'Maximum take off mass',
        hint: 'kgs'
    },
    {
        name: 'landing_mass',
        label:'Maximum landing mass',
        hint: 'kgs'
    },
    {
        name: 'certification_standard',
        hint: '(ICAO Annex 16)'
    },
    {
        name: 'lateral_power',
        label: 'Lateral/Full power noise level'
    },
    {
        name: 'approach_level',
        label: 'Approach noise level'
    },
    {
        name: 'flight_noise',
        label: 'Over flight noise level'
    },
    {
        name: 'takeoff_noise',
        label: 'Takeoff noise level'
    }
    
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
