/**
 * The function returns the current stage of an application based on its payment status and application
 * decision.
 * @returns The function `getCurrentStage` returns a string that represents the current stage of an
 * application based on its `application_decision` and `payment_status` properties. The possible return
 * values are: "Incomplete Application", "Awaiting Officer Approval", "Awaiting Director Approval",
 * "Application Completed", or "N/A".
 */
export const getCurrentStage = ({ application_decision, payment_status }) => {
  if (!payment_status) return 'Incomplete Application'

  if (application_decision == 'pending') {
    return 'Awaiting Officer Approval'
  }
  if (application_decision === 'officer viewed' || application_decision === 'processing') {
    return 'Awaiting Director Approval'
  }

  if (application_decision === 'approved') {
    return 'Application Completed'
  }

  return 'N/A'
}
