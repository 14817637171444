import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'

export default class DesignatedRep extends React.Component {
  resource = '/designated_reps'
  form_key = 'designated_rep'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'pel_number',
        label: 'PEL Number',
        hint: 'Leave blank if number not yet assigned',
        required: false
    },
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'suffix',
        hide: form.i_am_applicant != false
    },
    {
        name: 'f_name',
        label: 'First Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name',
        hide: form.i_am_applicant != false
    },
    {
        name: 'email',
        type: 'email',
        hide: form.i_am_applicant != false
    },
    {
        name: 'telephone',
        type: 'phone'
    },
    {
        name: 'address',
        label: 'Permanent address',
        hint: 'Street or PO Box Number'
    },
    {
        name: 'city'
    },
    {
        name: 'country',
        options: countries
    },
    {
        name: 'postal_code'
    },
    {
        name: 'birth_date',
        label: 'Date of Birth',
        type: 'date',
        showYearDropdown: true,
        hide: form.i_am_applicant != false
    },
    {
        name: 'height',
        hint: 'inches'
    },
    {
        name: 'weight',
        hint: 'lbs'
    },
    {
        name: 'hair'
      },
      {
        name: 'eyes'
      },
      {
        name: 'sex',
        options: ['male', 'female']
      },
      {
        name: 'nationality',
        hint: 'Citizenship',
        options: nationalities
      },
      "::Submission & Sponsoring Company",
      {
        heading: <p>Note: A sponsoring company is only required for "organizational" designees</p>
      },
      {
        name: 'submission_date',
        label: 'Date of submission',
        type: 'date',
        showYearDropdown: true
      },
      {
        name: 'sponsoring_company'
      },
      {
        name: 'designee_address',
        label: 'Designee Business Address'
      },
      {
        name: 'app_purpose',
        label: 'Application Purpose',
        options: ['initial designation', 'renewal of designation']
      },
      {
        name: 'select',
        label: 'Select all designations that apply',
        multi: true,
        options: [
            'Pilot Examiner',
            'Flight Crew Check Airman',
            'Flight Engineer Examiner',
            'Flight Dispatcher Examiner',
            'Flight Dispatcher Check Airman',
            'Cabin Crew Check Airman',
            'Knowledge Testing Examiner',
            'Operations Representative',
            'Aviation Medical Examiner',
            'Aviation Medical Assessor',
            'Language Proficiency Examiner',
            'Maintenance Engineer Examiner',
            'Airworthiness Representative',
            'Maintenance Representative',
            'Engineering Representative',
            'Air Traffic Examiner',
            'Air Traffic Representative',
            'Parachute Rigger Examiner',
            'Other Designation'
        ]
      },
      {
        name: 'explain',
        hide: form.select != 'Other Designation'
      },
      {
        name: 'perceived_need',
        label: 'State the perceived need that the designation would alleviate',
        type: 'textarea'
      },
      {
        name: 'proposed_functions',
        label: 'State the functions that ae requested',
        type: 'textarea'
      },
      {
        name: 'base_desig',
        label: `Is this designation to be based on another CAA's designation and is a copy of that designation attached?`,
        options: ['yes', 'no','not applicable']
      },
      {
        name:'if_yes',
        label: 'If YES include the designation number and related CAA phone and fax numbers',
        type: 'textarea',
        hide: form.base_desig != 'yes' 
      },
      {
        name: 'copy_attached',
        label: 'Is a copy of that designation attached?',
        type: 'select:bool'
        
      },
      "::Uploads",
      {
        name: 'resume_upload',
        label: 'Resume',
        type: 'file:pdf',
        key: 'image.0'
      },
      {
        name: 'formal_training_upload',
        label: 'Related formal training',
        type: 'file:pdf',
        key: 'iamge.1'
      },
      {
        name: 'functions_performed_upload',
        label: 'Chronological Summary of CAA functions performed',
        type: 'file:pdf',
        key: 'iamge.2'
      }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
