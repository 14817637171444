import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import occupations from "@constants/occupations";

export default class EconomicEmpowermentZonesIi extends React.Component {
  id = "EconomicEmpowermentBusiness";
  service = "economic empowerment business";
  title = "Economic Empowerment Zones Form II";
  agency = "mofrd";
  item = "";
  resource = "/economic_empowerment_businesses";
  form_key = "economic_empowerment_business";
  free = true;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = true;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "company_name",
      label: "Name of Applicant",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "company_place",
      label: "Place of Incorporate",
    },
    {
      name: "company_date",
      label: "Date of Incorporate",
      type: "date",
      showYearDropdown: true,
    },
    {
      name: "directors",
      label: "Names of Directors, Officers and Beneficial Owners",
      type: "textarea",
      list: true,
      placeholder: "Separate names using commas (e.g., John, David, Mark, etc)",
    },
    {
      name: "business_years",
      label: "Years of Existence",
      type: "integer",
    },
    {
      name: "number_of_employees",
      label: "Number of Employees",
      type: "integer",
    },
    {
      name: "business_number",
      label: "Business NIB number",
    },
    {
      name: "business_type",
      label: "Describe the type and nature of business",
      options: occupations,
    },
    {
      name: "type_of_products",
      label: "Description of the type of products sold",
    },
    {
      name: "sales",
      label: "Annual sales or turnover",
      type: "currency",
    },
    {
      name: "reason",
      label: "Reason for Exemption",
      options: [
        "Business License Fee",
        "Commercial Vehicle",
        "Real Property Tax",
      ],
    },
    {
      name: "corporate_statement",
      label: "Corporate Social Responsibility Statement",
    },
    {
      name: "incorporation_doc_upload",
      label: "Copy of Incorporation Documents",
      type: "file:image",
    },
    {
      name: "social_responsibility_evidence_upload",
      label:
        "Evidence of fulfilling corporate social responsibilities (upon renewal)",
      type: "file:image",
    },
    {
      name: "declaration",
      label:
        "I hereby declare that the above information is true and correct to the best of my knowledge, information and belief",
      type: "checkbox",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
