import { Fragment, useEffect, useState, createElement } from 'react'
import DataTable from 'react-data-table-component'
import { Switch, Route } from 'react-router'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
import QrReader from 'react-qr-reader'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import StateStore from '@helpers/state-store'
import { fullName } from '@modules/form-wizard/utils'
import ApplicationNotes from '@modules/form-wizard/notes'

import NationalSymbolPermission from './national-symbol-permission'
import SecurityLicenceRegistration from './security-licence-new'
import SecurityLicenceRenewal from './security-licence-renewal'
import SecurityLicenceAppeal from './security-licence-appeal'
import TempSecurityLicence from './security-licence-temp'
import BusinessSecurityLicence from './security-licence-business'

const MODAL = {
  MODE: 'MODE',
  VIA_LICENCE_NUMBER: 'VLN',
  VIA_QR_CODE: 'VQR',
}

const SERVICES = {
  SECURITY_LICENCES: {
    label: 'Security Guard / Inquiry Agent Licences',
    resource: '/inquiry_and_security_licenses',
    form_key: 'inquiry_and_security_license',
    form_type: 'InquiryAndSecurityLicense',

    path: record => {
      const { renewal, agent_type, temporary, appeal } = record

      switch (agent_type) {
        case 'security guard':
          if (temporary) return 'security-temp'
          if (renewal) return 'security-renewal'
          if (appeal) return 'security-appeal'
          return 'security-new'
        case 'inquiry firm':
          if (record.employee_details) return 'inquiry-new'
          return renewal
            ? 'inquiry-business-renewal'
            : 'inquiry-new-business'
        case 'security firm':
          if (record.employee_details) return 'security-new'
          return renewal
            ? 'security-business-renewal'
            : 'security-new-business'
        case 'inquiry agent':
          if (temporary) return 'inquiry-temp'
          if (renewal) return 'inquiry-renewal'
          if (appeal) return 'inquiry-appeal'
          return 'inquiry-new'
        default:
      }
    }
  },
  SYMBOL_USE_PERMISSION: {
    path: 'national-symbols',
    label: 'Permission To Use National Symbols',
    resource: '/national_symbol_permissions',
    form_key: 'national_symbol_permission',
    form_type: 'NationalSymbolPermission',
  }
}

const PendingSecurityLicences = props => {
  const [service, setService] = useState(null)
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [clear] = useState(false)
  const [data, setData] = useState([])
  const [active, setActive] = useState({})
  const [modal, setModal] = useState([])
  const [params, setParams] = StateStore({
    page: 1,
    per_page: 20,
  })

  const BASE_URL = props.match.url
  const { history } = props

  useEffect(() => {
    service && fetchRecords()
  }, [service, params.page])

  const changeService = ev => {
    const v = ev.target.value

    if (!v) {
      setService(null)
      return
    }

    setService({ key: v, ...SERVICES[v] })
  }

  const fetchRecords = async () => {
    setLoading(true)

    try {
      const { data } = await $app.axios.get(service.resource, { params })

      setData(data.records)
      setParams(data.params)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const approve = (selector, temp = false) => async () => {
    // Single Record Update
    if (selector?.user) {
      const { isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Approve Application',
        html: `
          Are you sure you want to approve this application for
          <strong>${fullName(selector.user, 'initial')}</strong>?
        `,
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve',
      })

      if (isDismissed) return
      setLoading(true)

      try {
        await $app.axios.put(service.resource + '/update_application', {
          [service.form_key]: {
            form_num: selector.form_num,
            application_decision: temp ? 'temp approved' : 'approved'
          },
        })

        toastr.success('Success', 'Application successfully updated')
        setActive({})
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }

      return
    }

    // Batch Updates
    const affectingAll = selector === data
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Approve ${affectingAll ? 'All' : 'Selected'} Applications`,
      html: affectingAll
        ? `
          Are you sure that you want to approve
          all of the applications on the current page?
        `
        : `
          Are you sure you want to approve
          all ${selected.length} applications?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Approve All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      const form_ids = selector.map(f => f.id)

      await $app.axios.put(service.resource + '/update_application_batch', {
        [service.form_key]: {
          form_ids,
          application_decision: temp ? 'temp approved': 'approved'
        }
      })

      toastr.success('Success', 'Applications successfully updated')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing the affected applications',
        'error'
      )
    }
  }

  const deny = selector => async () => {
    // Single Record Update
    if (selector?.user) {
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Deny Application',
        html: `
          Are you sure you want to approve this application for
          <strong>${fullName(selector.user, 'initial')}</strong>?
        `,
        showCancelButton: true,
        confirmButtonText: 'Yes, Deny',
      })

      if (!isConfirmed) return
      setLoading(true)

      try {
        await $app.axios.put(service.resource + '/update_application', {
          [service.form_key]: {
            form_num: selector.form_num,
            application_decision: 'denied',
          },
        })

        toastr.success('Success', 'Application successfully updated')
        setActive({})
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
          )
        }
        
      return
    }

    // Batch Updates
    const affectingAll = selector == 'A'
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Deny ${affectingAll ? 'All' : 'Selected'} Applications`,
      html: affectingAll
        ? `
          Are you sure that you want to deny all
          of the applications on the current page?
        `
        : `
          Are you sure you want to approve
          all ${selected.length} applications?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Deny All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      await $app.axios.put(service.resource + '/update_application_batch', {
        [service.form_key]: {
          form_ids: (affectingAll ? data : selected).map(row => row.id),
          application_decision: 'denied'
        }
      })

      toastr.success('Success', 'Applications successfully updated')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing the affected applications',
        'error'
      )
    }
  }

  const viewRecord = record => {
    
    const to = typeof service.path == 'function'
      ? service.path(record)
      : service.path

    history.push(sublink(to), { view: record })
  }

  const writeNote = async record => {
    if (!record.notes?.length) record.notes = []

    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Add Note',
      inputAttributes: {
        autocorrect: 'on',
      },
      showCancelButton: true,
    })

    if (!text) return

    try {
      const { data } = await $app.axios.post('/services/notes/add_note', {
        note: {
          note_type: 'note',
          internal_view_only: true,
          notable_type: service.form_type,
          notable_id: record.id,
          text,
        },
      })

      record.notes.push(data.note)

      Swal.fire({
        icon: 'success',
        toast: 'true',
        text: 'Note successfully created',
        timer: 5000,
        position: 'top-end',
        showConfirmButton: false,
      })
    } catch (err) {
      console.error(err)
      
      Swal.fire({
        icon: 'error',
        toast: 'true',
        text: 'Failed to create note.',
        timer: 5000,
        position: 'top-end',
        showConfirmButton: false,
      })
    }
  }

  const showScanErrorMessage = async err => {
    console.error(err)
    setModal([])

    await Swal.fire(
      'Error',
      'There was a problem while scanning the QR code',
      'error'
    )
  }

  const getLicenceID = async action => {
    setModal([null, action])

    const { value, isDismissed } = await Swal.fire({
      input: 'text',
      text: 'Please enter the licence number you wish to ' + action,
      showCancelButton: true,
      cancelButtonText: 'Nevermind',
      confirmButtonText: action.capitalize()
    })

    if (isDismissed) return

    // TODO: Find licence by ID
    return value
  }

  const getLicenceViaQRCode = async code => {
    if (!code) return

  }

  // const confirmLicenceRevocation = async f => {
  //   const [method, action] = modal

  //   const { isConfirmed, isDismissed } = await Swal.fire({
  //     title: `${action} licence`.capitalize(),
  //     html: `
  //       Are you sure that you want to ${action} this licence
  //       belonging to ${f.company_name || fullName(f.user, 'initial')}?
  //     `,
  //     showCancelButton: true,
  //     cancelButtonText: 'Nevermind',
  //     confirmButtonText: action.capitalize()
  //   })

  //   if (isDismissed) return

  //   try {
  //     const { data } = await $app.axios
  //       .put('/inquiry_and_security_licenses/update_application', {
  //         inquiry_and_security_license: {
  //           form_num: f.form_num,
  //           application_decision: action == 'revoke' ? 'cancelled' : 'revoked'
  //         }
  //       })

  //   } catch (err) {
  //     console.error(err)

  //     Swal.fire(
  //       'Error',
  //       `An error occurred while trying to ${action} the application`,
  //       'error'
  //     )
  //   }
  // }

  const sublink = path => (BASE_URL + '/' + path).replace('//', '/')

  const Service = ({ Component: element, path = '', service = '' }) => (
    <Route
      path={sublink(path)}
      render={r => createElement(element, {
        ...r,
        service: $app.getService(service)
      })}
    />
  )

  const columns = [
    {
      name: 'Applicant',
      selector: row => `${row.user.last_name}, ${row.user.first_name}`.capitalize(),
      sortable: true,
    },
    {
      name: 'Application',
      omit: service?.key != 'SECURITY_LICENCES',
      selector: row => row.agent_type?.capitalize()
        + ((row.renewal && ' Renewal') || (row.appeal && ' Appeal') || (row.temporary && ' Extension') || ' Registration'),
      sortable: true,
    },
    {
      name: 'Latest Date For Permission',
      omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.permission_date,
      sortable: true,
    },
    {
      name: 'Recommended By SIB?',
      omit: service?.key != 'SECURITY_LICENCES',
      sortable: true,
      maxWidth: '256px',
      selector: row => {
        switch (row.sib_recommendation_status) {
          case 'approved':
            return 'YES'
          case 'denied':
            return 'NO'
          default:
            return 'PENDING'
        }
      }
    },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={() => viewRecord(row)}>
            View
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={approve(row)}>
            Approve
          </MenuItem>
          {
            service?.key != 'SECURITY_LICENCES'
            || row.temporary
            || row.application_decision == 'temp approved'
            ? null
            : (
              <MenuItem onClick={approve(row, true)}>
                Approve (Temp)
              </MenuItem>
            )
          }
          <MenuItem onClick={deny(row)}>
            Deny
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => setActive(row)}>
            View Notes
          </MenuItem>
          <MenuItem onClick={() => writeNote(row)}>
            Create Note
          </MenuItem>
        </Menu>
      ),
    }
  ]

  const colors = [
    {
      style: { backgroundColor: '#0E33' },
      when: row => row.sib_recommendation_status == 'approved'
    },
    {
      style: { backgroundColor: '#FFBF0044' },
      when: row => row.sib_recommendation_status == 'denied'
    },
  ]

  return (
    <Fragment>
      <Switch>
        {/* Permission To Use National Symbol */}
        <Service
          path='national-symbols'
          service='national symbol permission'
          element={NationalSymbolPermission}
        />

        {/* Security Guard / Inquiry Agent Services */}

        {/* New */}
        <Service
          path='security-new'
          service='security guards license'
          element={SecurityLicenceRegistration}
        />
        <Service
          path='inquiry-new'
          service='inquiry agents license'
          element={SecurityLicenceRegistration}
        />

        {/* Renewal */}
        <Service
          path='security-renewal'
          service='security guards license renewals'
          element={SecurityLicenceRenewal}
        />
        <Service
          path='inquiry-renewal'
          service='inquiry agents license renewals'
          element={SecurityLicenceRenewal}
        />

        {/* Appeal */}
        <Service
          path='security-appeal'
          service='security guards license appeals'
          element={SecurityLicenceAppeal}
        />
        <Service
          path='inquiry-appeal'
          service='inquiry agents license appeals'
          element={SecurityLicenceAppeal}
        />

        {/* Temp */}
        <Service
          path='security-temp'
          service='security guards license temporary'
          element={TempSecurityLicence}
        />
        <Service
          path='inquiry-temp'
          service='inquiry agents license temporary'
          element={TempSecurityLicence}
        />

        {/* New - Business */}
        <Service
          path='security-new-business'
          service='security and inquiry firms license'
          element={BusinessSecurityLicence}
        />
        <Service
          path='inquiry-new-business'
          service='security and inquiry firms license'
          element={BusinessSecurityLicence}
        />

        {/* Renewal - Business */}
        <Service
          path='security-business-renewal'
          service='security and inquiry firms license'
          element={BusinessSecurityLicence}
        />
        <Service
          path='inquiry-business-renewal'
          service='security and inquiry firms license'
          element={BusinessSecurityLicence}
        />
      </Switch>
      <section className='content minister-applications-table'>
        <Loader loading={loading} />
        <article className='page-inner p-4'>
          <header className='flex justify-between items-center mb-6'>
            <h1 className='text-4xl text-gray-800'>
              Minister of National Security
            </h1>
            <nav className='flex justify-end space-x-4'>
              <span
                onClick={() => setModal([MODAL.MODE])}
                className='flex space-x-1 items-center text-blue-700 cursor-pointer'
              >
                <i className='fas fa-ban'></i>
                <span>Cancel/Revoke</span>
              </span>
              <Link
                to='/logout'
                className='flex space-x-1 items-center text-blue-700'
              >
                <i className='fas fa-sign-out-alt'></i>
                <span>Sign Out</span>
              </Link>
            </nav>
          </header>
          <div className='card'>
            <div className='card-header flex flex-col xl:flex-row justify-between items-center'>
              <label className='flex items-center space-x-2'>
                <span className='font-semibold'>Service</span>
                <select
                  name='service'
                  value={service?.key}
                  onChange={changeService}
                  className='form-control'
                >
                  <option value=''>Select A Service</option>
                  {Object.entries(SERVICES).map(([key, { label }]) => (
                    <option key={key} value={key} children={label} />
                  ))}
                </select>
              </label>
              <aside className='flex space-x-4 actions'>
                <span onClick={approve(selected?.length ? selected : data)}>
                  Approve {selected.length ? 'Selected' : 'All'}
                </span>
                <span
                  onClick={approve(selected?.length ? selected : data, true)}
                  data-hide={service?.key != 'SECURITY_LICENCES'}
                >
                  Approve {selected.length ? 'Selected' : 'All'} (Temp)
                </span>
                <span onClick={deny(selected)}>
                  Deny {selected.length ? 'Selected' : 'All'}
                </span>
              </aside>
            </div>
            <div className='card-body'>
              {service ? (
                <div className='table-responsive form-records-table'>
                  <DataTable
                    data={data}
                    columns={columns}
                    onSelectedRowsChange={s => setSelected(s.selectedRows)}
                    clearSelectedRows={clear}
                    conditionalRowStyles={colors}
                    noHeader
                    selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={params.per_page}
                    paginationTotalRows={params.no_of_records}
                    onChangeRowsPerPage={per_page => setParams({ per_page })}
                    onChangePage={page => setParams({ page })}
                  />
                </div>
              ) : (
                <div className='py-10 text-center font-semibold'>
                  Please select a service
                </div>
              )}
            </div>
          </div>
        </article>
        <ApplicationNotes
          data={active?.notes}
          onClose={() => setActive(null)}
        />
      </section>
      <Modal
        open={modal[0] === MODAL.MODE}
        onClose={() => setModal(false)}
        center
      >
        <header className='modal-header'>
          <h4>Cancel/Suspend A Licence</h4>
        </header>
        <article className='modal-body flex flex-col space-y-6'>
          <button
            className='btn btn-primary'
            onClick={() => getLicenceID('revoke')}
          >Cancel Via Licence Number</button>
          <button
            className='btn btn-primary'
            onClick={() => getLicenceID('suspend')}
          >Suspend Via Licence Number</button>
          <button
            className='btn btn-primary'
            onClick={() => setModal([MODAL.VIA_QR_CODE, 'revoke'])}
          >Cancel Via QR Code</button>
          <button
            className='btn btn-primary'
            onClick={() => setModal([MODAL.VIA_QR_CODE, 'suspend'])}
          >Suspend Via QR Code</button>
        </article>
        <footer className='modal-footer flex justify-end'>
          <button className='btn btn-muted' onClick={() => setModal([])}>
            Close
          </button>
        </footer>
      </Modal>
      <Modal
        open={modal[0] === MODAL.VIA_QR_CODE}
        onClose={() => setModal(false)}
        center
      >
        <p className='text-center my-8'>
          Please scan the QR Code of the licence you wish to {modal[1]}
        </p>
        <div className='relative rounded-xl bg-black overflow-hidden w-full sm:w-96 mx-auto'>
          <QrReader
            delay={1000}
            onScan={getLicenceViaQRCode}
            onError={showScanErrorMessage}
            style={{ width: '100%', height: '100%' }}
          />
          <FaChevronLeft
            size={28}
            color='#FFF'
            className='absolute bottom-0 left-0 mb-2 ml-2 cursor-pointer z-10'
            onClick={() => history.goBack()}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

export default PendingSecurityLicences