import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class JunkanooCarnivalExemption extends React.Component {
  resource = "/junkanoo_carnival_exemptions";
  form_key = "junkanoo_carnival_exemption";
  free = true;
  skip_location = true;

  defaults = {
    products: "Costumes",
  };

  fields = (form) => [
    {
      name: "client_type",
      label: "Name of Applicant",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "vat",
      label: "Vat Registration No.",
      is: "integer",
    },
    {
      name: "bl_number",
      label: "Business License No.",
      is: "integer",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "products",
      label: "Names of Products to be purchased",
      disabled: true,
    },
    {
      name: "document_upload",
      type: "file:image",
      label: "Application for raw materials to be imported",
    },
    {
      name: "total_value",
      label: "Total Value",
      is: "currency",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
