import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'

export default class PelLicense extends React.Component {
  resource = '/pel_licenses'
  form_key = 'pel_license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'made_for',
        label: 'Application Is Hereby Made For',
        options: ['Issuance', 'renewal', 'reissuance', 'reinstatement'],
        multi: true
    },
    {
        name: 'cont',
        label: 'Of the Following Bahamas PEL License',
        multi: true,
        options: [
            'Flight Dispatcher', 
            'Aircraft Maintenance Technican',
            'Aviation Repair Specialist',
            'Cabin Crew Member', 
            'Parachute Rigger',
            'Aeronautical Radio Operator', 
            'Air Traffic Controller',
            ],
    },
    {
        name: 'rat_req',
        label: 'Ratings(s) Requested',
        fields: [
            'powerplant',
            'airframe',
            {
                name: 'special',
                label: 'Specialized(Specify Class)',

            },
            {
                name: 'type_rate',
                label: 'Type Rating(Specify)',
                type: 'textarea'
            }
        ]
    },
    {
        name: 'name',
        label: 'Name(Last, First, Middle)'
    },
    {
        name: 'address',
        label: 'Permanent Address (Street or PO Box Number)'
    },
    {
        name: 'tele',
        label: 'Telephone and Fax'
    },
    {
        name: 'city',
    },
    {
        name: 'island',
        label: 'Island/State/Province'
    },
    {
        name: 'mail_code'
    },
    {
        name: 'country',
        options:countries
    },
    {
        name: 'dob',
        label: 'Date of Birth(Month, Day, Year)',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'birth_place',
        label: 'Place of Birth',
        options: countries
    },
    {
        name: 'nationality',
        label: 'Nationality(Citizenship)',
        option: nationalities
    },
    {
        name: 'language',
        label: 'Lauguage Proficiency at least Level 4?',
        type: 'select:bool'
    },
    {
        name: 'height',
        hint: 'In'
    },
    {
        name: 'weight',
        hint: 'Lbs'
    },
    {
        name: 'hair'
    },
    {
        name: 'eyes'
    },
    {
        name: 'sex',
        options: ['male', 'female']
    },
    {
        name: 'email_address',
        type: 'email'
    },
    "::License Information (If Applicable)",
    {
        name: 'license_num',
        label: 'License Number',
        required: false
    },
    {
        name: 'icao_issue',
        label: 'ICAO State of Issue',
        required: false
    },
    {
        name: 'date_issued',
        type: 'date',
        showYearDropdown: true,
        required: false
    },
    {
        name: 'rating',
        label: 'Rating(s)/Endorsement(s) Requested',
        type: 'textarea',
        required: false
    },
    {
        name: 'for_init',
        label: '(For Initial) License Or Rating Applied For On Basis Of Completion Of:',
        options: ['Experience', 'Written test', 'Practical test'],
        multi: true
    },
    {
        name: 'been_revoked',
        label: ' Has Your Licence Ever Been Suspended/revoked Or Have You Ever Failed A Test For This License Or Rating?',
        type: 'select:bool'
    },
    {
        name: 'if_yes',
        label: `If 'YES' attach an explanation`,
        type: 'textarea',
        hide: form.been_revoked != true
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
