import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import Loader from '@shared/loader'
import FileField from '@shared/form-fields/file'
import Wizard from './wizard'
import { companies, companiesList, certTypes, types, normalize } from './cert-types'
import { Field, InsuranceRecipient, MedicalRecipient } from './_forms'
import { issueCertificates } from '@actions/blockcerts'
import InputField from '@shared/form-fields/input'
import SelectField from '@shared/form-fields/select'

const defaultState = type => ({
  company     : companiesList.find(c => companies[c].includes(type)),
  type        : 'Vehicle Insurance',
  file        : null,
  filename    : null,
  recipient   : types[type].fields,
  recipients  : [],
  step        : 1,
})

class Issuer extends Component {
  constructor (props) {
    super(props)

    this.type = props.match.params.type
    this.form = React.createRef()
  }

  state = defaultState(companies[companiesList[0]][0])

  findNextType (company) {
    const arr = companies[company || this.state.company]
    return arr.includes(this.state.type) ? this.state.type : arr[0]
  }

  // Event Handlers
  on = {
    submit: async () => {
      const valid = await this.form.current.isFormValid(false)
      if (!valid) return

      const { company, type, description, recipient, recipients } = this.state

      const obj = {
        company: normalize(company),
        type: normalize(type),
        description,
        recipients: recipients.length ? recipients : [recipient]
      }

      const success = await this.props.issueCertificates(obj)
      success && this.setState({ step: 2 })
    },

    company: ({ company, type }) => {
      const nextType = type || this.findNextType(company)
      this.state.type != nextType && this.form.current.resetValidations()
      this.setState({
        ...defaultState(nextType),
        company: company || this.state.company
      })
    },

    type: ({ target }) => {
      this.setState(defaultState(target.value))
    },

    input: ({ target }) => {
      const recipient = { ...this.state.recipient }
      recipient[target.name] = target.value

      this.setState({ recipient })
    },

    date: (target, val) => {
      const recipient = { ...this.state.recipient }
      recipient[target] = val

      this.setState({ recipient })
    },

    clear: e => {
      this.setState({
        file: null,
        filename: null,
        recipient: types[this.state.type].fields
      })
    },

    file: ({ target }) => {
      const file = target.files[0]

      const reader = new FileReader()

      reader.onload = e => {
        this.setState({
          file,
          filename: file.name,
          recipients: this.parseCSV(reader.result)
        })
      }

      reader.readAsText(file)
    },
  }

  supports = {
    FileAPI () {
      return !['File', 'FileReader'].some(prop => !window[prop])
    }
  }

  parseCSV (txt) {
    const rows = txt.split('\n').filter(r => !!r)
    const headings = rows[0].split(',').map(h => h.trim().toLowerCase().replace(/\s/g, '_'))

    return rows.slice(1).map(row => {
      const cols = row.split(',').map(c => c.trim())
      const obj = {}

      for (let i = 0, len = cols.length; i < len; i++) {
        obj[headings[i]] = cols[i]
      }

      return obj
    })
  }

  render () {
    const {
      props,
      state,
      on,
    } = this

    const companyOptions = companiesList.map(c => ({
      value: c,
      label: c,
    }))

    if (props.loading) return <Loader {...props} />

    const form = this.type == 'batch'
    ? () => (
      <Field name='csv-file' label='Recipients CSV'>
        <FileField
          name='csv-file'
          accept='.csv,.txt'
          onChange={on.file}
          value={state.file}
          validators={['required', 'isFile']}
          errorMessages={['CSV file is required']}
        />
      </Field>
    )
    : recipient => {
      const props = {
        recipient,
        onChange: e => on.input(e),
        onDate: on.date
      }
      
      switch (state.type) {
        case certTypes.VEHICLE_INSURANCE:
          return InsuranceRecipient(props)
        case certTypes.COVID_LOW_RISK:
          return MedicalRecipient(props)
        default:
          return null
      }
    }

    const title = this?.type == 'batch' ? 'Issue Batch Certificates' : 'Issue Single Certificate'

    return (
      <Wizard
        title={title}
        tabText='Issue Certificate'
        tabIcon='certificate'
        onFormSubmit={on.submit}
        onBackPress={() => this.props.history.push('/certs')}
        step={state.step}
      >
        <ValidatorForm ref={this.form} onSubmit={() => {}} className='form-wizard__form'>
          <Field name='company'>
            <SelectField
              name="batch_company"
              value={state.company}
              className="form-control"
              options={companyOptions}
              onChange={v => on.company({ company: v.length && v[0].value })}
            />
          </Field>
          <Field name='certificate_type'>
            <InputField
              name='batch_type'
              value='Vehicle Insurance'
              className='form-control'
              disabled
            />
          </Field>

          {form(state.recipient)}
        </ValidatorForm>
      </Wizard>
    )
  }
}

export default connect(state => {
  const { loading } = state.blockcerts
  return { loading }
}, {
  issueCertificates
})(Issuer)