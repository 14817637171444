import React from 'react'
import { connect } from 'react-redux'

import Loader from '@shared/loader'
import LastStep from '@modules/form-wizard/success'

class CertificationsForm extends React.Component {
  user = sessionStorage.user && JSON.parse(sessionStorage.user)

  on = {
    submit: async ev => {
      ev.preventDefault()

      await this.props.onFormSubmit(this)
    },
  }

  render () {
    const { props, on } = this
    const { step } = props

    if (props.loading) return <Loader {...props} />

    const tab = (text, icon, index) => (
      <li className="step" style={{ 'width': "50%" }}>
        <a className={`nav-link ${step == index ? "active" : ""}`} href="#" aria-expanded="true">
          <i className={`fas fa-${icon} mr-0`}></i>
          {text}
        </a>
      </li>
    )

    let activeTab
    let nextBtn

    switch (step) {
      case 1:
        activeTab = (
          <div className="tab-pane active" id="about">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                      <div className="card-title">{props.title}</div>
                    </div>
                    { props.children }
                </div>
              </div>
            </div>
          </div>
        )

        nextBtn = (
          <button type="button" className="btn btn-next btn-danger" onClick={on.submit}>
            Next
          </button>
        )

        break

      case 2:
        activeTab = <LastStep hasFee={false} feedback={false} />
        nextBtn = <input type="button" className="btn btn-finish btn-danger" onClick={props.onBackPress} name="finish" value="Finish" />
        break

      default:
    }

    return (
      <React.Fragment>
      <div className="row" style={{ marginTop: 70 }}>
        <div className="wizard-container wizard-round col-md-9">
            <div className="wizard-body">
              <div className="row">
                <ul className="wizard-menu nav nav-pills nav-primary">
                  { tab(props.tabText, props.tabIcon, 1) }
                  { tab('Confirmation', 'check-square', 2) }
                </ul>
              </div>

              <div className="tab-content">
                { activeTab }
              </div>
            </div>

            <div className="wizard-action">
              <div className="pull-left">
              <input type="button" className="btn btn-finish" onClick={props.onBackPress} name="finish" value="Back" />
              </div>
              <div className="pull-right">{nextBtn}</div>
              <div className="clearfix"></div>
            </div>
        </div>
      </div>
      </React.Fragment>
    )
  }
}

export default connect(state => {
  return {
    loading: state.blockcerts.loading
  }
})(CertificationsForm)