import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { danceCodes } from '../clementBethelArtsFestival/book-codes'
export default class ClementBethelDance extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'island_deadline_date',
        label: 'Check your island deadline date for each island',
        options: [
            `Abaco (inclusive of Moore's islands, Man-o-War Cay, Hope Town, Green Turtle Cay) March 8th, 2024`,
            'Andros Feb 16th, 2024',
            'Bimini Feb 9th, 2024',
            'Berry Islands Feb 9th, 2024',
            'Cat Island Jan 26th, 2024',
            'Eleuthera (inclusive of Spanish Wells & Harbour Island) Apr 19th, 2024',
            'Exuma (Main Island) Jan 19th, 2024',
            'Grand Bahama Mar 22nd, 2024',
            'New Providence Apr 5th, 2024',
           // 'Rum Cay Feb 15th',
            //'Ragged Island Feb 15th',
            'Long Island Jan 26th, 2024',
            'Southern Islands (Inagua, Mayaguana, Acklins, Crooked Island, San Salvador) Feb 23rd, 2024',
            'Other'
    ],
    
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.island_deadline_date !='Other',
    },
    {
        name: 'school_or_ensemble',
        label:'Name of school or Ensemble',
    },
    //Division conditionals
    {
        name: 'division',
        options: [
            'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)',
            'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)',
            'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)',
            'Community COM (10 Dollars Single 20 Dollars Group Ensemble)',
            'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)',
            'Other'
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.division !='Other',
    },
    {
        name: 'class_title_pre',
        label: 'Class Title',
        options: danceCodes.pre_school.categories[0].titles,
        hide: form.division != 'Pre-School PS (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
        name: 'class_title_primary',
        label: 'Class Title',
        options: danceCodes.primary_school.categories[0].titles,
        hide: form.division != 'Primary PR (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
        name: 'class_title_junior',
        label: 'Class Title',
        options: danceCodes.junior_high.categories[0].titles,
        hide: form.division != 'Jr. High JH (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
        name: 'class_title_senior',
        label: 'Class Title',
        options: danceCodes.senior_high.categories[0].titles,
        hide: form.division != 'Sr. High SH (5 Dollars Single 10 Dollars Group/Ensemble)'
    },
    {
        name: 'class_title_com',
        label: 'Class Title',
        options: danceCodes.open_and_community.categories[0].titles,
        hide: form.division != 'Community COM (10 Dollars Single 20 Dollars Group Ensemble)'
    },
    // Class Number conditionals
    {
        name: 'number_of_entrants_ballet',
        label: 'Please provide the number of females and males in the group',
        options: [
            'one person',
            'two persons',
            'more than two persons'
            ], 
        hide: !form.class_title_pre === danceCodes.pre_school.categories[0].titles[0]   
    }, 
    {
        name: 'class_number_one',
        label: 'Class Number',  
        options: ['Dance PS1 A'],      
        hide: form.number_of_entrants_ballet != 'one person'
    },
    {
        name: 'class_number_two',
        label: 'Class Number',   
        options: ['Dance PS1 B'],      
        hide: form.number_of_entrants_ballet != 'two persons'
    },
    {
        name: 'class_number_more',
        label: 'Class Number',   
        options: ['Dance PS1 C'],      
        hide: form.number_of_entrants_ballet != 'more than two persons'
    },
    {
        name: 'name_of_guardian',
        label: 'Name of Director/Teacher/Parent', 
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

