import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class InstrumentsDeclaration extends React.Component {
  resource = '/instruments_declarations'
  form_key = 'instruments_declaration'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::CAR OPS 1 CHAPTER 11, 12 & 19 COMPLIANCE DECLARATION(INSTRUMENTS & EQUIPMENT)",
{
    heading:<p>Note: This document is based on CAR OPS 1, Revision 01 dated 01 July 2021 and operators must refer to this
document when completing this declaration.</p>
},
    /*{
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'First Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name of Operator',
        required: false,
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name of Operator',
        hide: form.i_am_applicant != false
    },*/
    {
        name:'name',
        label:'Name of operator:'
    },
    {
        name:'reg_mark',
        label:'Registration Mark:'
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft:`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial No.:'
    },
    {
        name: 'manufacturer',
        label: 'Aircraft Manufacturer:'
    },
    {
        name: 'manufacture_date',
        label: 'Date of Manufacture:',
        type: 'date',
        showYearDropdown:true
    },
    {
        name: 'max_weight',
        label: 'Maximum Certificated Take-off Weight (MTOW):'
    },
    {
        name: 'max_seat',
        label: 'Maximum approved passenger seating configuration (MAPSC):'
    },
/*
    "::CAR OPS 1 CHAPTER 11 REQUIREMENTS",
    {
        heading:<h5>MEANS OF COMPLIANCE</h5>,
    },
    {
        name:'Requirement satisfied through type design as specified in Type Certificate Data Sheet(TCDS)',
        type:'select:bool'
    }*/
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
