import React from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import locations from '@constants/post-office-locations'
import InputField from '@shared/form-fields/input'
import Loader from '@shared/loader'
import ENV from '@constants/env'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys } from '@helpers/objects'
import generateRange from '@helpers/range'

const today = moment()

const BASE_FEES = {
  small  : 8.25,
  medium : 13.75,
  large  : 22.00,
  late : 10,
  lateDate: `02-01-${today.year()}`
  // lateDate: `04-30-${today.year()}`
}

const years = generateRange(1900, new Date().getFullYear())
  .map(v => ({
    value: v + '-01-01',
    label: v,
  }))
  .reverse()

export default class RentalBoxRenewal extends React.Component {
  state = {
    payment_amount: '',
    loading: false,
    record: null,
  }

  title = 'Renewal of Rent of Private Letter Boxes'
  resource = '/rent_box_renewals'
  form_key = 'rent_box_renewal'

  skip_location = true

  // postpaid = () => !this.state.locked
  postpaid = false
  fee = async r => {
    if (r.payment_amount) return r.payment_amount
    if (!this.state.locked) return 0

    // console.log('r', this.state)

    const base = BASE_FEES[r.box_type]
    const late_date = new Date(BASE_FEES.lateDate)
    const late_for_curr_yr = today >= late_date ? 10 : 0
    // console.log('late for current year: ', late_for_curr_yr)
    // const diff = today.year() - this.state.locked.last_payment.slice(0, 4)
    const diff = today.year() - this.state.last_payment?.slice(0, 4)
    const late = diff > 0 ? 10 * (diff - 1) : 0
    // console.log('late fee', late)

    if (diff == 0) return base
    return base * diff + late + late_for_curr_yr
  }

  hide_columns = ['Name']

  // hide_buttons = {
  //   fee: true,
  // }

  defaults = () => ({
    last_payment_month: this.last_payment?.replace(/\d{2}-\d{2}$/, '01-01'),
  })

  validate = form => {
    const location = extractKeys(
      form,
      'location:pickup_location',
      'sub_location:pickup_sub_location'
    )

    this.setState({ last_payment: form.last_payment_month?.replace(/\d{2}-\d{2}$/, '01-01') })

    return {
      last_payment_month: this.last_payment ?? form.last_payment_month,
      ...location,
    }
  }

  color_codes = (props, bg) => [
    {
      when: r => r.payment_status && today.diff(r.updated_at, 'days') < 3,
      style: bg('#0C15'),
    },
    {
      when: r => r.payment_status && today.diff(r.updated_at, 'days') == 3,
      style: bg('#FFD5809F'),
    },
    {
      when: r => r.payment_status && today.diff(r.updated_at, 'days') > 3,
      style: bg('#C003'),
    },
  ]

  columns = ({ view }) => [
    {
      name: 'Location',
      selector: 'sub_location',
      format: r => `${r.sub_location}, ${r.location}`,
      searchable: true,
    },
    {
      name: 'Box Holder',
      selector: 'box_holder',
      searchable: true,
      cell: r => (
        <span onClick={view(r)} data-action>
          {r.box_holder}
        </span>
      ),
    },
    {
      name: 'Box #',
      selector: 'box_number',
      searchable: true,
    },
  ]

  hooks = {
    'post:read': async records => {
      if (!$app.hasRole('pilot')) return
      const lastPaid = records.find(r => r.payment_invoice)?.payment_invoice
      if (!lastPaid) return

      try {
        const url = ENV.CASHLESS_URL + '/invoices/by_invoice_number/' + lastPaid
        const { data: the } = await $app.axios.get(
          ENV.CASHLESS_URL + '/fetch_guest_token'
        )
        const { data: invoice } = await $app.axios.get(url, {
          headers: {
            Authorization: the.token,
          },
        })
  
        this.last_payment = invoice.created_at.slice(0, 10)
      } catch (err) {
        console.error(err)
        return
      }
    },
  }

  // custom_action = {
  //   text: 'Set Fee',
  //   icon: 'dollar-sign',
  //   test: r => !r.payment_status,
  //   fn: record =>
  //     this.setState({
  //       record,
  //       payment_amount: '',
  //     }),
  // }

  closeModal = ev => {
    ev.preventDefault()
    this.setState({ record: null })
  }

  updateView = update => {
    const { history, location } = this.props
    const inview = location.state?.view

    inview &&
      history.replace(location.pathname, {
        ...location.state,
        view: { ...inview, ...update },
      })
  }

  on = {
    fee: ev => {
      const val = ev.target.value
      if (val && !/^[0-9]+(\.[0-9]{0,2})?$/.test(val)) return
      this.setState({ payment_amount: val })
    },

    submit: async ev => {
      this.setState({ loading: true })

      const { state, resource, form_key } = this
      const { record, payment_amount } = state
      const { axios } = $app

      const message = payment => `
        Good day ${record.user.first_name.capitalize()},
        <br />
        Please see below your Service Fee needed to complete your Post Box Renewal.
        <br /><br />
        Service Fee : <b>$${payment}</b><br />
        <br/><br />
        <a href='${
          ENV.PILOT_URL
        }'>Click here</a> to login and complete payment on the Portal.
        <br/><br />
        Thank you for using the MyGateway Portal.
      `

      try {
        await axios.put(resource + '/update_payment_amount', {
          [form_key]: {
            form_num: record.form_num,
            fee_per_year: +payment_amount,
            expiration_date: moment().subtract(7, 'days').format(),
          },
        })

        toastr.success('Success', 'Application fee updated.')
        this.updateView({ payment_amount })

        try {
          const { data } = await axios.post('/emails', {
            email: record.user.email,
            subject: 'MyGateway Portal: Rent Box Renewal Payment',
            message: message(Number(payment_amount).toFixed(2)),
          })

          data.message && !data.error
            ? toastr.success('Success', 'Email sent to citizen')
            : toastr.error('Error', data.message || data.error)
        } catch (err) {
          console.error(err)
          toastr.error('Error', 'Unable to send email to citizen')
        }
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to set Payment Amount')
      }

      this.setState({ loading: false, record: null })
    },
  }

  getLocation = box => {
    const l = (island, location) => ({
      location: island,
      sub_location: location,
    })

    if (!box) return l('', '')

    switch (box.replace(/[^a-z]/gi, '')) {
      case 'TEST':
        return l('New Providence', 'Sesame Street')
      case 'N':
        return l('New Providence', 'General Post Office - Town Center Mall')
      case 'AP':
        return l('New Providence', 'Airport')
      case 'CB':
        return l('New Providence', 'Cable Beach')
      case 'CR':
        return l('New Providence', 'Carmichael Road')
      case 'EE':
        return l('New Providence', 'Elizabeth Estates')
      case 'FH':
        return l('New Providence', 'Fox Hill Post Office')
      case 'GT':
        return l('New Providence', 'Grants Town')
      case 'SP':
        return l('New Providence', 'SandyPort')
      case 'SS':
        return l('New Providence', 'Shirley Street Post Office')
      case 'SB':
        return l('New Providence', 'South Beach Post Office')
      case 'F':
        return l('Grand Bahama', 'Freeport')
      case 'H':
        return l('Grand Bahama', `Hunter's Post`)
      case 'WE':
        return l('Grand Bahama', 'West End')
      case 'ER':
        return l('Grand Bahama', 'Eight Mile Rock')
      // case 'SP':
      //   return l('Grand Bahama', 'Smith Point')
      case 'MT':
        return l('Grand Bahama', 'McClean\'s Town')
      case 'SC':
        return l('Grand Bahama', 'Sweeting\'s Cay')
      case 'HR':
        return l('Grand Bahama', 'High Rock')
      case 'AB':
        return l('Abaco', '')
      case 'AC':
        return l('Acklins', '')
      case 'AN':
        return l('Andros', '')
      case 'BM':
        return l('Bimini', 'Alice Town')
      case 'BI':
        return l('Bimini', '')
      case 'CC':
        return l('Bimini', 'Cat Cay')  
      case 'CA':
        return l('Cat Island', '')
      case 'CI':
        return l('Crooked Island', '')
      case 'EX':
        return l('Exuma', '')
      case 'EL':
        return l('Eluethera', '')
      case 'IN':
        return l('Inagua', '')
      case 'LI':
        return l('Long Island', '')
      case 'MA':
        return l('Mayaguana', '')
      case 'RI':
        return l('Ragged Island', '')
      case 'SA':
        return l('San Salvador', '')
      case 'RC':
        return l('Rum Cay', '')        
      default:
        return l('', '')
    }
  }

  fields = form => {
    const { locked } = this.state

    return [
      {
        name: 'box_number',
        hint: "eg. FH1000 ~ Fox Hill box number 1000",
        onBlur: async (val, obj) => {
          try {
            // console.log('Old ev', obj);
            const newVal = val.replaceAll(' ', '').replaceAll('-', '').trim().toUpperCase();
            val = newVal;
            obj.box_number = newVal;
            // console.log('New ev', obj);
            const { data: box } = await $app.axios.get(`/po_boxes/${newVal}`)
            this.setState({ locked: box?.id ? box : false })

            return {
              box_holder: box?.box_holder ?? '',
              box_type: box?.box_size ?? '',
              last_payment_month: box?.last_payment?.slice(0, 4) ?? '',
              ...this.getLocation(val),
            }
          } catch (err) {
            console.error(err)
          }
        },
      },
      {
        label: "Registered Box Holder's Name",
        name: 'box_holder',
        disabled: locked,
      },
      {
        name: 'box_type',
        type: locked ? 'text' : 'select',
        disabled: locked,
        hint: `Late fee of $10 added after ${moment(BASE_FEES.lateDate).format('MMMM Do')}`,
        options: [
          { label: `Small ($${BASE_FEES.small})`, value: 'small' },
          { label: `Medium ($${BASE_FEES.medium})`, value: 'medium' },
          { label: `Large ($${BASE_FEES.large})`, value: 'large' },
        ],
      },
      {
        name: 'location',
        type: locked ? 'text' : 'select',
        disabled: locked,
        options: Object.keys(locations),
      },
      {
        key: form.location || '***',
        name: 'sub_location',
        type: locked ? 'text' : 'select',
        disabled: locked || !form.location,
        options: locations[form.location] || [],
      },
      {
        name: 'last_payment_month',
        type: 'select',
        label: 'Year of Last Payment',
        options: years,
      },
    ]
  }

  render () {
    const { state, on, closeModal } = this

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!state.record}
          style={{ width: 640 }}
          onClose={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Set Payment Amount</h5>
          </div>
          <div className='modal-body'>
            <ValidatorForm id='fee-amount-form' onSubmit={on.submit}>
              <div className='form-group form-show-validation'>
                <label htmlFor='payment_amount'>
                  Enter the application fee
                  <span className='required-label'>*</span>
                </label>
                <InputField
                  name='paymount_amount'
                  icon='dollar-sign'
                  value={state.payment_amount}
                  onChange={on.fee}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  required
                />
              </div>
            </ValidatorForm>
          </div>
          <div className='modal-footer'>
            <button
              className='btn btn-round mr-2'
              aria-label='Close'
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className='btn custom-btn btn-round'
              type='submit'
              form='fee-amount-form'
            >
              Submit
            </button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
