import Swal from 'sweetalert2'

const redirectToCustoms = async () => {
  await Swal.fire({
    icon: 'info',
    // title: 'This Application Has Been Moved',
    title: 'Important Notice',
    html: `
      Kindly take note that effective immediately, all Individual and Business
      clients are asked to apply for this service through the Bahamas Customs
      and Excise Department. More information is available 
      <a href='https://www.bahamascustoms.gov.bs/imports-and-exports/exemptions-and-concessions-offered-by-the-ministry-of-finance/'>here</a>.
      <br />
      Thank you for using MyGateway.
    `,
  })

  return null
}

export default redirectToCustoms
