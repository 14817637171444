import {
  Fragment,
  useEffect,
  useRef,
  useReducer,
  useState,
  useMemo,
} from 'react'

import axios from 'axios'
import ENV from '@constants/env'
import { Redirect, Route, Switch } from 'react-router'
import DataTable from 'react-data-table-component'
import Modal from 'react-responsive-modal'
import { Camera } from 'react-camera-pro'
import QRReader from 'react-qr-reader'
import { FaChevronLeft } from 'react-icons/fa'
import { RiCameraLine, RiCameraSwitchLine } from 'react-icons/ri'

import Swal from '@sweetalert'
import Loader from '@shared/loader'
import useWindowDimensions from '@hooks/dimensions'
import mapServiceName from '@constants/maps.services'
import { fullName, getUploadPath } from '@modules/form-wizard/utils'

import { shareCert, getQRCode } from './actions'
import { values } from './verify'

import CoatOfArms from '@images/bs-coat-of-arms.svg'
import { Menu, MenuItem } from '@szhsin/react-menu'
import toastr from '@modules/toastr'
import '@styles/dashboard.css'


const LiveDigitalCerts = () => {
  return (
    <div className='content'>
      <div className='page-header' />
      <div className='page-inner'>
        <Switch>
          <Route path='/certs/documents' component={UploadDocuments} />
          <Route path='/certs/own' component={OwnCerts} />
          <Route path='/certs/shared' component={SharedCerts} />
          <Route path='/certs/history' component={CertNotifications} />
          <Route path='/certs/scan' component={ScanQRCode} />
        </Switch>
      </div>
    </div>
  )
}

const UploadDocuments = ({ history }) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [docUpload, setDocUpload] = useState(null)
  const [docType, setDocType] = useState('')
  const [docExpiry, setDocExpiry] = useState('')

  const docUploadRef = useRef(null)

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const { data } = await $app.axios.get('/profiles')
        setData(data.images.filter(x => x.name != 'Signature'))
      } catch (err) {
        console.error(err)

        await Swal.fire(
          'Oops',
          `
            An error occureed while fetching your documents.
            Please try again later.
          `,
          'error'
        )

        history.push('/dashboard')
      }
      setLoading(false)
    }

    getDocuments()
  }, [])

  useEffect(() => {
    if (docUploadRef.current) {
      setDocUpload(null);
      docUploadRef.current.value = '';
    }
  }, [showModal])

  if (loading) return <Loader loading='Loading Documents' />

  const upload = (docRef) => {
    setDocType(docRef)
    setShowModal(!showModal)
  }

  // Ensures that the profile image uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = (e) => {
    const elem = e.target;
    if (elem.files[0].size > 2048576) {
      elem.value = '';
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'File size too big (max size allowed: 2 megabyte)',
        showConfirmButton: false,
        timer: 2000
      })
    } else { 
      setDocUpload(elem.files[0]);
    }
  };
    
  const handlePhotoUpload = async e => {
    e.preventDefault();

    const photo = new FormData();
    const docRefExpiry = docType.replace('_upload', '_expiry')
    photo.append(`profile[${docType}]`, docUpload);
    photo.append(`profile[${docRefExpiry}]`, docExpiry)

    setLoading(true);
    try {
      console.log($app)
      const { data } = await axios.patch(`${ENV.WEB_SERVICE_URL}/${ENV.API_V}/profiles/1`, photo,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: $app.token} })

      setData(data.images.filter(x => x.name != 'Signature'))
      setShowModal(false)
      toastr.success('Success', 'Document uploaded successfully')
    } catch (err) {
      console.error(err)

      await Swal.fire(
        'Oops',
        `
          An error occureed while updating your documents.
          Please try again later.
        `,
        'error'
      )
    }

    setLoading(false)
    setDocUpload(null)
    if (docUploadRef.current) docUploadRef.current.value = '';

    return null;
  };



  const columns = [
    {
      name: 'Type',
      selector: row => row.name,
      // width: '128px',
    },
    {
      name: 'Image',
      cell: row =>
        /missing/.test(row.url) ? (
          <span className='truncate'>No document uploaded</span>
        ) : (
          <a href={getUploadPath(row.url, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>
        ),
    },
    {
      name: 'Expiry Date',
      cell: row => row.expiry,
    },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={() => upload(row.ref)}>
            Upload
          </MenuItem>
        </Menu>
      ),
    }
  ]

  return (
    <Fragment>
      {/* Upload Modal */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        classNames={{ modal: 'w-full sm:w-128' }}
        center
      >
        <form className="" onSubmit={handlePhotoUpload} encType="multipart/form-data">
          <img className='mb-3' alt='Document upload' src={docUpload ? URL.createObjectURL(docUpload) : null}></img>
          <div className='' style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="document_upload">Upload Document</label>
            <input
              ref={docUploadRef}
              id='document_upload'
              type="file"
              alt='Profile document upload'
              name="document_upload"
              accept="image/png, image/jpeg, image/jpg"
              onChange={e => handleCheckFileSize(e)}
              style={{marginBottom: '1em'}}
            />
            <label htmlFor="document_expiry">Document Expiry Date</label>
            <input
              id='document_expiry'
              type="date"
              alt='Profile document upload'
              name="document_expiry"
              onChange={e => setDocExpiry(e.target.value)}
              style={{marginBottom: '1em'}}
            />
            {docUpload ? (<button className='btn' style={{backgroundColor: '#64b5fd'}} type="submit">Upload</button>) : (<button className='btn' style={{backgroundColor: 'lightgrey'}} type="submit" disabled>Upload</button>)}
          </div>
        </form>
      </Modal>
      <section className='card'>
        <header className='card-header'>
          <h4 style={{ margin: 0 }}>My Documents</h4>
        </header>
        <div className='card-body form-records-table'>
          <DataTable data={data} columns={columns} striped />
        </div>
      </section>
    </Fragment>
  )
}

const OwnCerts = ({ history }) => {
  const [own, setOwn] = useState(null)
  const { width } = useWindowDimensions()

  const mobile = width < 640

  useEffect(() => {
    const getOwnCerts = async () => {
      try {
        const { data } = await $app.axios.get('/certs/digital')
        setOwn(data.records)
      } catch (err) {
        console.error(err)

        await Swal.fire(
          'Oops',
          `
            An error occureed while fetching your digital certificates.
            Please try again later.
          `,
          'error'
        )

        history.push('/dashboard')
      }
    }

    getOwnCerts()
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'Service',
        selector: row => mapServiceName(row.service_type),
      },
      {
        name: 'Approval Date',
        omit: mobile,
        selector: row => new Date(row.approval_date).toISOString().slice(0, 10),
      },
      {
        name: 'Expiry Date',
        omit: mobile,
        selector: row =>
          row.expiry_date
            ? new Date(row.expiry_date).toISOString().slice(0, 10)
            : 'N/A',
      },
      {
        name: 'Action',
        omit: mobile,
        cell: row => (
          <div className='flex space-x-4'>
            <span data-action onClick={() => shareCert(row)}>
              Share
            </span>
            <span data-action onClick={() => getQRCode(row)}>
              View
            </span>
          </div>
        ),
      },
    ],
    [width]
  )

  if (!own) return <Loader loading='Getting Certificates' />

  return (
    <section className='card'>
      <header className='card-header flex flex-col md:flex-row md:justify-between md:items-center'>
        <h4 className='mb-3 md:mb-0'>My Certificates</h4>
        <DocumentUpload />
      </header>
      <div className='card-body form-records-table'>
        <DataTable
          data={own}
          columns={columns}
          expandableRows={mobile}
          expandableRowsComponent={<CertDetails />}
          striped
        />
      </div>
    </section>
  )
}

const SharedCerts = ({ history }) => {
  const [shared, setShared] = useState(null)

  useEffect(() => {
    const getSharedCerts = async () => {
      try {
        const { data } = await $app.axios.get('/certs/shared')
        setShared(data.records)
      } catch (err) {
        console.error(err)

        await Swal.fire(
          'Oops',
          `
            An error occureed while fetching certificates shared with you.
            Please try again later.
          `,
          'error'
        )

        history.push('/dashboard')
      }
    }

    getSharedCerts()
  }, [])

  if (!shared) return <Loader loading='Getting Shared Certificates' />

  const columns = [
    {
      name: 'Shared By',
      selector: row => fullName(row.user, 'initial'),
    },
    {
      name: 'Service',
      selector: row => mapServiceName(row.service_type),
    },
    {
      name: 'Approval Date',
      selector: row => new Date(row.approval_date).toISOString().slice(0, 10),
    },
    {
      name: 'Expiry Date',
      selector: row =>
        row.expiry_date
          ? new Date(row.expiry_date).toISOString().slice(0, 10)
          : 'N/A',
    },
    {
      name: 'Action',
      cell: row => (
        <div className='flex space-x-4'>
          <span data-action onClick={() => getQRCode(row)}>
            View
          </span>
        </div>
      ),
    },
  ]

  return (
    <section className='card'>
      <header className='card-header'>
        <h4 style={{ margin: 0 }}>Shared Certificates</h4>
      </header>
      <div className='card-body form-records-table'>
        <DataTable data={shared} columns={columns} striped />
      </div>
    </section>
  )
}

const CertDetails = ({ data }) => (
  <aside className='p-2'>
    <div className='flex justify-between'>
      <span>Approval Date</span>
      <span>{new Date(data.approval_date).toISOString().slice(0, 10)}</span>
    </div>
    <div className='flex justify-between'>
      <span>Expiry Date</span>
      <span>{new Date(data.expiry_date).toISOString().slice(0, 10)}</span>
    </div>
    <div className='my-2'>
      <em className='font-medium'>Actions</em>
    </div>
    <div className='flex space-x-4'>
      <span data-action onClick={() => shareCert(data)}>
        Share
      </span>
      <span>|</span>
      <span data-action onClick={() => getQRCode(data)}>
        View
      </span>
    </div>
  </aside>
)

const CertNotifications = ({ history }) => {
  const [data, setData] = useState(null)
  const [params] = useState({ page: 1 })

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const { data } = await $app.axios.get('/notifications', params)
        setData(data.records)
      } catch (err) {
        console.error(err)

        await Swal.fire(
          'Oops',
          `
            An error occureed while fetching your notifications.
            Please try again later.
          `,
          'error'
        )

        history.push('/dashboard')
      }
    }

    getNotifications()
  }, [])

  if (!data) return <Loader loading='Getting Notifications' />

  const columns = [
    {
      name: 'Date',
      selector: row => new Date(row.created_at).toISOString().slice(0, 10),
      width: '128px',
    },
    {
      name: 'Message',
      cell: row =>
        row.viewed ? (
          <span className='truncate'>{row.message}</span>
        ) : (
          <strong className='truncate'>{row.message}</strong>
        ),
    },
  ]

  return (
    <section className='card'>
      <header className='card-header'>
        <h4 style={{ margin: 0 }}>My Notifications</h4>
      </header>
      <div className='card-body form-records-table'>
        <DataTable data={data} columns={columns} striped />
      </div>
    </section>
  )
}

const DocumentUpload = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [state, setState] = useReducer(
    (state, payload) => {
      if (state.doctype && payload.doctype)
        return {
          ...payload,
          docNumber: '',
          face: null,
          front: null,
          // back: null,
        }

      return { ...state, ...payload }
    },
    {
      doctype: '',
      docNumber: '',
    }
  )

  const getImageBlob = key => async dataURL => {
    const [meta, data] = dataURL.split(',')
    const [, type] = meta.match(/^data:(image\/.{3,4});/)
    const bytes = Buffer.from(data, 'base64')

    setState({
      [key]: new Blob([bytes], { type }),
    })
  }

  const submit = async () => {
    setLoading(true)
    const form = new FormData()

    form.set('id_type', state.doctype)
    form.set('id_numb', state.docNumber)
    form.set('face_scan_upload', state.face)
    form.set('document_front_upload', state.front)
    // form.set('document_back_upload', state.back)

    const url = '/digital_id/upload_document'
    let data, status

    try {
      const res = await $app.axios.post(url, form, {
        validateStatus: s => s < 401,
      })

      data = res.data
      status = res.status
    } catch (err) {
      console.error(err)
      setLoading(false)

      await Swal.fire(
        'Oops',
        `
          There was an error while processing your request.
          Please try again later.
        `,
        'error'
      )
    }

    const valid = {}

    for (let [key, obj] of Object.entries(data)) {
      if (obj?.image?.id) {
        valid[key.replace('_upload', '')] = obj.status
      }
    }

    setLoading(false)

    if (status == 200) {
      setOpen(false)

      await Swal.fire({
        icon: 'success',
        title: 'Document Uploaded',
        html: `
          <p class='text-center'>Verifications</p>
          <div class='flex flex-wrap'>
          ${Object.entries(valid)
            .map(
              v => `
            <span class='w-1/2 text-left'>${v[0].initialCaps()}</span>
            <span class='w-1/2 text-right'>${v[1].initialCaps()}</span>
          `
            )
            .join('')}
          </div>
        `,
      })
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Document',
      })
    }
  }

  return (
    <Fragment>
      <Loader loading={loading} message='Verifying Document' />
      <button className='btn custom-btn' onClick={() => setOpen(true)}>
        Upload Documents
      </button>
      <Modal
        open={open && !loading}
        onClose={() => setOpen(false)}
        classNames={{ modal: 'w-full sm:w-128 p-0' }}
        center
      >
        <header className='modal-header'>
          <h5 className='modal-title'>Upload Document</h5>
        </header>
        <article className='modal-body'>
          <div className='form-group form-show-validation'>
            <label htmlFor='document_type'>
              Document Type<span className='required-label'>*</span>
            </label>
            <select
              name='document_type'
              className='form-control'
              value={state.doctype}
              onChange={ev => setState({ doctype: ev.target.value })}
            >
              <option value=''>(Document Type)</option>
              <option value='passport'>Passport</option>
              <option value='drivers license'>Driver's Licence</option>
            </select>
          </div>
          <div className='form-group form-show-validation'>
            <label htmlFor='document_number'>
              Document Number<span className='required-label'>*</span>
            </label>
            <input
              name='document_number'
              inputMode={state.doctype == 'passport' ? 'text' : 'numeric'}
              className='form-control'
              value={state.docNumber}
              onChange={ev => setState({ docNumber: ev.target.value })}
            />
          </div>
          <h6 className='font-semibold mb-4 px-2'>Uploads</h6>
          <table className='w-full'>
            <thead>
              <tr>
                <th className='px-2 pb-3'>Picture</th>
                <th className='px-2 pb-3'>Status</th>
              </tr>
            </thead>
            <tbody>
              <CameraUploads
                label='Selfie'
                value={state.face}
                onSnap={getImageBlob('face')}
              />
              <CameraUploads
                label='Document Front'
                value={state.front}
                onSnap={getImageBlob('front')}
              />
              {/* <CameraUpload
                label='Document Back'
                value={state.back}
                onSnap={getImageBlob('back')}
              /> */}
            </tbody>
          </table>
        </article>
        <footer className='modal-footer flex justify-end items-center'>
          <button className='btn m-0 p-0 mr-4' onClick={() => setOpen(false)}>
            Close
          </button>
          <button
            className='btn custom-btn'
            onClick={submit}
            disabled={Object.values(state).some(v => !v)}
          >
            Submit
          </button>
        </footer>
      </Modal>
    </Fragment>
  )
}

const CameraUploads = ({ label, value, onSnap }) => {
  const [open, setOpen] = useState(null)
  const cam = useRef(null)

  if (!open) {
    return (
      <tr>
        <td className='px-2 py-1'>{label}</td>
        <td className='px-2 py-1'>{value ? 'Ready' : 'Pending'}</td>
        <td className='px-2 py-1 text-right'>
          <button
            className='btn p-0 m-0 xs:ml-auto text-blue-700'
            onClick={() => setOpen(true)}
          >
            {value ? 'Retake' : 'Take Picture'}
          </button>
        </td>
      </tr>
    )
  }

  return (
    <section className='fixed flex flex-col inset-0 w-full h-full justify-center items-center'>
      <div className='bg-black bg-opacity-25 mb-4 p-2 rounded-lg'>
        <p className='text-white text-center m-0'>
          Please make sure that the picture is clear
        </p>
      </div>
      <div className='relative rounded-xl bg-black overflow-hidden w-full h-full sm:w-96 sm:h-128'>
        <Camera ref={cam} />
        <div className='absolute flex justify-between bottom-0 inset-x-0 p-4'>
          <FaChevronLeft
            size={28}
            color='#FFF'
            className='cursor-pointer'
            onClick={() => setOpen(false)}
          />
          <RiCameraLine
            size={28}
            color='#FFF'
            className='cursor-pointer'
            onClick={() => {
              onSnap(cam.current?.takePhoto())
              setOpen(false)
            }}
          />
          <RiCameraSwitchLine
            size={28}
            color='#FFF'
            className='cursor-pointer'
            onClick={() => cam.current?.switchCamera()}
          />
        </div>
      </div>
    </section>
  )
}

const ScanQRCode = ({ history }) => {
  const [mode, setMode] = useState('SCANNING')

  if ($app.hasRole('pilot')) {
    return <Redirect to='/certs/own' />
  }

  if (mode == 'FETCHING') {
    return <Loader loading />
  } else if (mode === 'DONE') {
    return null
  }

  const onScan = async code => {
    if (!code) return

    try {
      setMode('FETCHING')

      const h = await $app.axios.get(`/certs/find_by_qr_hash?qr_hash=${code}`)
      const u = await $app.axios.get('/certs/uuid/' + h.data.uuid)

      setMode('DONE')

      await Swal.fire({
        icon: 'success',
        title: 'Cerificate Verified',
        html: (
          <Fragment>
            <img
              src={CoatOfArms}
              alt='Coat of Arms'
              className='absolute w-24'
              style={{
                top: '1rem',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
            {values(u.data.form_type, u.data.record)}
          </Fragment>
        ),
        customClass: 'relative swal--wide hide-icon',
      })

      setMode('SCANNING')
    } catch (err) {
      console.error(err)
      setMode('DONE')

      const message =
        err.response?.data?.error ??
        'An error occurred while attempting to verify this certificate'

      const expired = /expired/i.test(message)

      await Swal.fire(
        expired ? 'Code Expired' : 'Error',
        message,
        expired ? 'warning' : 'error'
      )

      setMode('SCANNING')
    }
  }

  const onError = async err => {
    console.error(err)
    setMode('DONE')

    await Swal.fire(
      'Error',
      'There was a problem while scanning the QR code',
      'error'
    )

    setMode('SCANNING')
  }

  return (
    <section
      className='fixed flex flex-col inset-0 w-full h-full justify-center items-center'
      style={{
        background: '#000A',
        zIndex: 1000,
      }}
    >
      <div className='bg-black bg-opacity-25 mb-4 p-2 rounded-lg'>
        <p className='text-white text-center m-0'>
          Please scan the QR Code to verify its authenticity
        </p>
      </div>
      <div className='relative rounded-xl bg-black overflow-hidden w-full sm:w-96'>
        <QRReader
          delay={1000}
          onScan={onScan}
          onError={onError}
          style={{ width: '100%', height: '100%' }}
        />
        <FaChevronLeft
          size={28}
          color='#FFF'
          className='absolute bottom-0 left-0 mb-2 ml-2 cursor-pointer z-10'
          onClick={() => history.goBack()}
        />
      </div>
    </section>
  )
}

export default LiveDigitalCerts
