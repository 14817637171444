import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import fishing_areas from '@constants/fishing-areas'
import islands from '@constants/islands'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'
import { getCurrentStage } from './_marine-utility'
import passportPhotoExample from '@helpers/passport-photo-example'

import { submitForReview, approveApplication } from './_fdc_number'

class TrapStoneCrab extends React.Component {
  state = {
    backspace: false,
  }

  long_name = 'New Trap Stone Crab Permit'
  resource = '/stone_crab_traps'
  form_key = 'stone_crab_trap'
  read_params = {
    // admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: false,
      },
    },
  }
  
  skip_location = true

  hide_buttons = {
    status: true,
    deny: true,
  }

  // filter_records = r => !r.renewal

  custom_actions = [
    submitForReview(this),
    approveApplication(this),
    {
      text: 'Deny',
      icon: 'ban',
      test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]

  foremost = {
    type: 'select:bool',
    label: `
      Are you a commercial fisherman with a port licence?
    `,
    test: v =>
      v || 'Sorry, but this application is only for commercial fishermen',
  }

  columns = props => [
    {
      name: 'Current Stage',
      selector: 'application_decision',
      width: '300px',
      cell: r => <span>{getCurrentStage(r) || 'N/A'}</span>,
      searchable: r => getCurrentStage(r),
    },
  ]

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
          license_numb: record.license_numb || 0,
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - New Trap Stone Crab Permit',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Thank you for your interest in applying for a new trap stone crab permit. 
            Unfortunately, your application was denied due to outstanding documents required. 
            Please review the requirements and resubmit. 
            Thank you for using MyGateway.gov.bs, the centralized way to request and pay for online government services. 
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  fields = form => [
    {
      name: 'license_numb',
      label: 'Licence Number',
      hide: true,
      view: true,
    },
    {
      name: 'fdc_numb',
      label: 'FDC Number',
      hide: true,
      view: true,
    },
    {
      name: 'client_type',
      label: 'Are you a company or an individual?',
      options: [
        { label: 'Individual', value: 'individual' },
        { label: 'Business', value: 'business' },
      ],
    },
    {
      name: 'company_name',
      label: "Company's registered name",
      hide: form.client_type != 'business',
      disabled: form.client_type == 'business',
    },
    {
      name: 'company_email',
      label: "Company's registered email address",
      hide: form.client_type != 'business',
      disabled: form.client_type == 'business',
      required: false,
    },
    {
      name: 'captain',
      type: 'select:bool',
      label: 'Are you the captain?',
    },
    ':: Vessel Details',
    {
      name: 'vessel_name',
      label: 'Name of Vessel',
    },
    {
      name: 'port_registration_number',
      label: 'Port Registration Number of Vessel',
      callback: v => {
        let backspace = this.state?.backspace
        let val = v.toUpperCase()

        if (v.length === 2 && !backspace) {
          val += '-'
          this.setState((prevState, props) => ({
            backspace: !prevState.backspace,
          }))
        }

        if (v.length < 2 && backspace)
          this.setState((prevState, props) => ({
            backspace: !prevState.backspace,
          }))

        if (v.length > 3) {
          console.log(v)
          if (!/^[0-9]+$/.test(v.substring(3)))
            return { port_registration_number: val.substring(0, 3) }
        }

        return { port_registration_number: val }
      },
      hint: 'Registration Number is alphanumeric, e.g. BS-3434354',
    },
    {
      name: 'overall_length',
      label: 'Overall Length of Vessel',
      is: 'integer',
      hint: 'In Feet',
    },
    {
      name: 'home_port',
      label: 'Home Port',
      options: islands,
    },
    {
      heading: "Captain's Details",
    },
    {
      name: 'captain_first_name',
      label: 'First Name',
      hide: form.captain,
      view: !form.captain,
    },
    {
      name: 'captain_last_name',
      label: 'Last Name',
      hide: form.captain,
      view: !form.captain,
    },
    // {
    //   name: 'captains_nationality',
    //   label: 'Nationality',
    //   options: nationalities,
    // },
    {
      name: 'captain_address',
      label: 'Street Address',
      hide: form.captain,
      view: !form.captain,
    },
    {
      name: 'captain_passport_upload',
      label: 'Passport Photo',
      type: 'file:image',
      hide: form.captain,
      view: !form.captain,
      key: 'image.3',
      hint: passportPhotoExample,
    },
    {
      name: 'captain_license_numb',
      label: 'Captain License Number',
      required: false,
    },
    '::SPACER',
    {
      name: 'numb_of_traps',
      label: 'Number of Traps Requested',
      is: 'integer',
    },
    {
      name: 'working_areas',
      label: 'Proposed Working Areas',
      options: fishing_areas,
      multi: true,
      hint: `<span style="background-color: #FFFF00">Select <strong>ALL</strong> of the options that apply</span>`,
    },
    {
      name: 'port_registration_upload',
      label: 'Port Registration Card',
      type: 'file:all',
      key: 'image.1',
      hint: `<span style="background-color: #FFFF00">WARNING: This card should be a reflection of your commercial status as a fisherman. If you are a leisure fisherman, your application will be <strong>denied</strong></span>`,
    },
    {
      name: 'boat_image_upload',
      label: 'Picture of Boat',
      type: 'file:image',
      key: 'image.2',
    },
    {
      name: 'declare',
      label:
        'I DECLARE THAT ANY PERMIT USED AS A RESULT OF THIS APPLICATION WILL BE USED ONLY IN ACCORDANCE WITH THE FISHERIES RESOURCES (JURISDICTION AND CONSERVATION) REGULATIONS, 1986 AND THE REGULATIONS MADE THEREUNDER.',
      type: 'checkbox',
    },
  ]

  hidden_fields = () => [
    {
      name: 'license_numb',
      label: 'Permit Number',
      is: 'integer',
    },
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Trap Stone Crab Permit',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default TrapStoneCrab
