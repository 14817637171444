import React from 'react'
import islands from '@constants/islands'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ClementBethelFilm extends React.Component {
  resource = '/clement_national_art_festivals'
  form_key = 'clement_national_art_festival'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'nursery_rhyme',
        label: 'Which Nursery Ryhme/Ring Play is your film based on?',
        hint: 'Your film must be based on one of these options',
        options: [
            'Georgie Peorgie',
            'Jack and Jill',
            'Little Miss Muffet',
            'Little Boy Blue',
            'Mary Had a Little Lamb',
            'Pat-a-Cake',
            'Diddle Diddle Dumpling',
            'Little Jack Horner',
            'Mary Mary Quite Contrary',
            'Baa Baa Black Sheep',
            'None'
            //unable to complete if none is selected
        ]
    },
    {
        name:'name_of_film',
    },
    {
        name: 'name_of_organization',
    },
    {
        name: 'category_of_film',
        options: [
            'Narrative',
            'Documentary',
            'PSA',
            'Musical or Music Video',
            'Experimental'
        ]
    },
    {
        name: 'duration_of_film',
    },
    {
        name: 'island',
        options: islands,
    },
    {
        name: 'phone_number',
        type: 'phone',
    },
    /*{
        name: 'level_first',
        label: 'Level',
        options: [
            'Primary PR ($5 Single $10 Group/Ensemble)',
            'Jr. High JH ($5 Single $10 Group/Ensemble)',
            'Sr. High SH ($5 Single $10 Group/Ensemble)',
            'Community COM ($10 Single $20 Group/Ensemble)',
            'Pre-School PS ($5 Single $10 Group/Ensemble)',
            'Other'
        ],
    }, 
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.level_first !='Other',
    }, */
    {
        name: 'division',
        options: [
            'Primary PR ($5 Single)',
            'Primary PR ($10 Groupe)',
            'Jr. High JH ($5 Single)',
            'Jr. High JH ($10 Group/Ensemble)',
            'Sr. High SH ($5 Single)',
            'Sr. High SH ($10 Group/Ensemble)',
            'Community COM ($10 Single)',
            'Community COM ($20 Group/Ensemble)',
            'Pre-School PS ($5 Single $10 Group/Ensemble)',
            'Other'
        ],
    },
    {
        name:'other',
        label: ' ',
        hint: 'Please specify',
        hide: form.division !='Other',
    },
    {
        name: 'recording_and_editing',
        label: 'Recording and editing software',
        type: 'textarea',
    },
    {
        name: 'brief_synopsis',
        label: 'Provide a brief synopsis of the Film.', 
        hint: 'Include credits',
        type: 'textarea',
    },
    "::Uploads",
    {
        name: 'film_sumbissions',
        key: 'image.0',
        type: 'file',
        hint: 'No larger than 1 GB'
        //video upload'
        //Also must be able to accept links from youtube, vimeo, dropbox, google drive and one drive
    },
    {
        name: 'decription_submission',
        heading: `<p>Film entrants are to include the following info in WRITING with their Submission Package; these should also be included in the video credits at the end of the film: <br> i. Name of Commmunity Group or Production Company producing the film <br> ii. The full names of all persons who assisted and who worked on the film <br> iii. The roles of the persons who assisted; be sure to indicate those who they wish to nominate; Eg- Writer, Director, Actor, etc. <br> iv. All actor's isNamespaceExportDeclaration, alongside the name of the character they played <br> v. Any narrator's or interviewees' names <br><br> In the case of school submissions: <br> vi. Name of school producing film <br> vii. The full names of the child/children for whom the film is being submitted</p>`
    },
    {
        name: 'written submission',
        key: 'image.1',
        type: 'file',
        hint: 'accepts .doc, .pdf., .docx'
    },
    {
        name: 'acceptance',
        type: 'checkbox',
        use_as_acceptance: true,
        label: `
          I declare that the film piece(s) submitted with this application represents my original work, by myself or someone on my production team; except in the instances where I have acknowledged the in writing the ideas, words, images, or material of other writers, filmmakers, musicians or songwriters. `
      }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
