const initial = {
  count: 0,
}
export const resetSessionTimeout = () => ({ type: 'NOOP' })

const reducer = (state = initial) => {
  // increment counter on every action
  // this will reset the session timer
  return {
    count: state.count + 1,
  }
}

export default reducer
