const error = (title = '', html = '') => [title, html, 'error']

const getUnavailibilityMessage = agency => {
  switch (agency) {
    case 'rgd':
      return error(
        'RGD Unavailable',
        'Sorry, but RGD is currently unavailable. Please try again later.'
      )
    default:
      return error(
        'Error',
        'Sorry, but this service seems to be unavailable at the moment. Please try again later.'
      )
  }
}

export default getUnavailibilityMessage
