import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import MCSidePanel from './sidebar'
import { getNote } from '@actions/notes'

class Detail extends React.Component {
  componentDidMount() {
    try {
      this.note = this.props.location.state.note
      if (!this.note) throw new Error()
    } catch (err) {
      return this.props.history.replace('/message-center/inbox')
    }
  }

  render() {
    const { props } = this
    const note = this.note || props.note
    if (!note) return null

    const { notable_type, created_at, text } = note

    return (
      <div className='email-content email-content-detail content content-full'>
        <div className='page-inner-fill'>
          <div className='page-with-aside mail-wrapper bg-white'>
            <MCSidePanel {...props} />
            <div className='email-content page-content mail-content'>
              <div className='email-head d-lg-flex d-block'>
                <h3>{notable_type && notable_type.spaceBeforeCap()}</h3>

                <div className='controls mt-3 mt-lg-0'>
                  <Link to='/message-center/inbox'>
                    <i className='fa fa-reply'></i>
                  </Link>
                </div>
              </div>
              <div className='email-sender'>
                <div className='sender'></div>
                <div className='date'>
                  {created_at && new Date(created_at).dateMonthTime()}
                </div>
              </div>
              <div
                className='email-body'
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(({ notes }) => ({ note: notes.note }), { getNote })(
  Detail
)
