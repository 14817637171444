import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import { extractKeys } from '@helpers/objects'

import Form from '@modules/form-wizard/form'
import Success from '@modules/form-wizard/success'

import {
  createAppointment,
  getBlackoutDates,
  getFormTypes,
} from '@actions/appointments'

class AddScheduleAppointment extends React.Component {
  state = {
    step: 1,

    blackoutTimes: null,
  }

  componentDidMount() {
    this.props.getFormTypes()
  }

  getUser = () => {
    const user = window.sessionStorage.getItem('user')
    return user && JSON.parse(user)
  }

  getBlackouts = type => this.props.getBlackoutDates(type)

  splitPascal = str => str.replace(/([A-Z])/g, ' $1')

  returnToTable = () => this.props.history.push('/appointments')

  createAppointment = form => {
    const { props, state } = this
    state.step == 1 &&
      props.createAppointment(
        {
          ...form,
          schedulable_id: 25,
        },
        () => {
          this.setState({ step: 2 })
        }
      )
  }

  calculateBlackoutDates = ranges => {
    const dayOf = m => new Date(m._d)

    return ranges.reduce((days, range) => {
      const start = moment(range.blackout_start_date)
      const end = moment(range.blackout_end_date)

      while (!start.isSame(end, 'days')) {
        start.add(1, 'days')
        days.push(dayOf(start))
      }

      return days.pop() && days
    }, [])
  }

  calculateBlackoutTimes = d => {
    if (!d || !this.props.blackouts.length) return null

    const currentDay = moment(d)
    const targetDays = this.props.blackouts.reduce(
      (arr, r) => [...arr, r.blackout_start_date, r.blackout_end_date],
      []
    )

    const match = targetDays.findIndex(d => currentDay.isSame(d, 'days'))
    if (match === -1) return null

    const timeOf = m => new Date(m._d)

    const times = []
    if (match % 2) {
      // is a start date: start from midnight & stop at cutoff
      const stop = moment(targetDays[match]).startOf('hour')
      const start = stop.clone().startOf('day')

      do {
        times.push(timeOf(start))
        start.add(30, 'minutes')
      } while (!start.isSame(stop, 'minutes'))
    } else {
      // is an end date: start from cuton time to EOD
      const start = moment(targetDays[match]).startOf('hour')
      const stop = start.clone().endOf('day').add(1, 'seconds')

      do {
        times.push(timeOf(start))
        start.add(30, 'minutes')
      } while (!start.isSame(stop, 'minutes'))
    }

    return times
  }

  StepOne = () => {
    const {
      state,
      props,
      splitPascal,
      calculateBlackoutDates,
      createAppointment,
    } = this

    const types = props.types
      ? props.types.map(type => ({
          value: type,
          label: splitPascal(type),
        }))
      : null

    const blackoutDates = props.blackouts.length
      ? calculateBlackoutDates(props.blackouts)
      : null
    const blackoutTimes = state.blackoutTimes

    const form = {
      title: 'Schedule Appointment',
      ministry: '',
      name: 'appointment-form',

      fields: [
        {
          name: 'schedulable_type',
          label: 'Appointment',
          options: types,
          blocking: true,
          test: v => !!v,

          callback: type => type && this.props.getBlackoutDates(type),
        },
        {
          name: 'date',
          type: 'date',
          showTimeSelect: true,
          autoComplete: 'off',
          dateFormat: 'do MMMM, yyyy @ h:mm aa',
          excludeDates: blackoutDates,
          excludeTimes: blackoutTimes,
          minDate: new Date(),

          validator: d => {
            const now = moment()
            const date = moment(d.toISOString())

            if (!date.isAfter(now, 'minutes')) {
              toastr.error('Error', 'Schedule time is invalid')
              return false
            }

            return true
          },

          callback: d =>
            this.setState({ blackoutTimes: this.calculateBlackoutTimes(d) }),
        },
      ],

      save: createAppointment,
    }

    return <Form {...form} skipLocation />
  }

  tabClass = (current, target) =>
    'nav-link ' + (current == target ? 'active' : '')

  render() {
    const { state, props, returnToTable, getUser, tabClass, StepOne } = this
    const { step } = state
    const { loading } = props

    let content = null
    let nextBtn = null
    let prevBtn = (
      <input
        type='button'
        className='btn btn-danger'
        name='previous'
        onClick={returnToTable}
        value='Go Back'
      />
    )

    switch (step) {
      case 1:
        content = (
          <div className='tab-pane active'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <StepOne />
                </div>
              </div>
            </div>
          </div>
        )

        nextBtn = (
          <input
            type='submit'
            form='appointment-form'
            className='btn btn-next btn-primary'
            name='next'
            value='Next'
          />
        )

        break
      case 2:
        content = <Success hasFee={false} user={getUser()} />
        nextBtn = null

        break
      default:
    }

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <div className='row' style={{ marginTop: 70 }}>
          <div className='wizard-container wizard-round col-md-9'>
            <div className='wizard-header text-center'>
              <h3 className='wizard-title'>
                <strong>Schedule</strong> Appointment
              </h3>
              <small>Appointment Scheduling Across Whole of Government</small>
            </div>
            <div className='wizard-body'>
              <div className='row'>
                <ul className='wizard-menu nav nav-pills nav-primary'>
                  <li className='step' style={{ width: '48%' }}>
                    <a
                      className={tabClass(step, 1)}
                      href='#'
                      aria-expanded='true'
                    >
                      <i className='fas fa-calendar-plus mr-2'></i>
                      Schedule Appointment
                    </a>
                  </li>
                  <li className='step' style={{ width: '50%' }}>
                    <a className={tabClass(step, 2)} href='#'>
                      <i className='fas fa-check-square mr-2'></i>
                      Confirmation
                    </a>
                  </li>
                </ul>
              </div>

              <div className='tab-content'>{content}</div>
            </div>

            <div className='wizard-action'>
              <div className='pull-left'>{prevBtn}</div>
              <div className='pull-right'>{nextBtn}</div>
              <div className='clearfix'></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(
  state => extractKeys(state.appointments, 'loading', 'blackouts', 'types'),
  { createAppointment, getFormTypes, getBlackoutDates }
)(AddScheduleAppointment)
