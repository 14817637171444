import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import '@styles/404.css'

const NotFound = () => (
  <article className='page-not-found'>
    <div className='wrapper not-found'>
      <h1 className='fadeIn'>404</h1>
      <div className='desc fadeIn'>
        <span>OOPS!</span>
        <br />
        Looks like you got lost.
      </div>
      <Link to='/dashboard' className='btn custom-btn mt-4 animated fadeInUp'>
        <span className='btn-label mr-2'>
          <i className='fas fa-home'></i>
        </span>
        Back To Home
      </Link>
    </div>
  </article>
)

const _404 = () => ($app?.token ? <NotFound /> : <Redirect to='/' />)

export default _404
