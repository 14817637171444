import * as T from '@action-types'

const initial = {
  loading: false,
  error: null,

  agencies: [],
  faqs: [],
  alerts: [],
  events: [],
  locations: [],
  services: [],
  feedback: null,
  deliveries: [],

  current_alert: {},
  publicEvents: [],
}

const reducer = (state = initial, { type, payload }) => {
  switch (type) {
    case T.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: payload || true,
        error: null,
      }
    case T.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.response?.data?.error,
      }
    case T.GET_AGENCIES:
      return {
        ...state,
        loading: false,
        agencies: payload,
      }
    case T.GET_AGENCY_FAQS:
      return {
        ...state,
        loading: false,
        faqs: payload,
      }
    case T.GET_AGENCY_LOCATIONS:
      return {
        ...state,
        loading: false,
        locations: payload,
      }
    case T.GET_AGENCY_ALERTS:
      return {
        ...state,
        loading: false,
        alerts: payload.reduce(
          (arr, { agency, alerts }) => [
            ...arr,
            ...alerts.map(alert => ({ ...alert, agency })),
          ],
          []
        ),
      }
    case T.SET_AGENCY_ALERT:
      return {
        ...state,
        current_alert: Object.assign(payload || {}, {
          type: payload?.alert_type,
        }),
      }
    case T.GET_AGENCY_EVENTS:
      return {
        ...state,
        loading: false,
        events: payload,
      }
    case T.GET_AGENCY_SERVICES:
      return {
        ...state,
        loading: false,
        services: payload,
      }
    case T.GET_AGENCY_FEEDBACK:
      return {
        ...state,
        loading: false,
        feedback: payload,
      }
    case T.GET_CALENDAR_EVENTS:
      return {
        ...state,
        loading: false,
        publicEvents: payload,
      }
    case T.GET_DELIVERIES:
      return {
        ...state,
        loading: false,
        deliveries: payload,
      }
    case T.CREATE_DELIVERY:
      return {
        ...state,
        loading: false,
        deliveries: [payload, ...state.deliveries],
      }
    case T.CLOSE_DELIVERY:
      return {
        ...state,
        loading: false,
        deliveries: closeDelivery(state.deliveries, payload),
      }
    case T.RECEIVE_DELIVERY:
      return {
        ...state,
        loading: false,
        deliveries: moveDelivery(state.deliveries, payload),
      }
    case T.RECEIVE_PACKAGE:
      return {
        ...state,
        loading: false,
        deliveries: receivePackage(state.deliveries, payload),
      }
    default:
      return {
        ...state,
        loading: false,
      }
  }
}

function closeDelivery(deliveries, id) {
  const next = deliveries.slice()
  const index = next.findIndex(d => d.id == id)

  next[index].submitted = true
  return next
}

function moveDelivery({ deliveries: here, deliveries_in_transit: yet }, id) {
  const [affected] = yet.splice(
    yet.findIndex(d => d.id == id),
    1
  )

  here.push({ ...affected, delivered: true })

  return {
    deliveries: here.slice(),
    deliveries_in_transit: yet.slice(),
  }
}

function receivePackage({ deliveries, ...rest }, record) {
  const { delivery_id, service_type, id } = record

  const [target] = deliveries.splice(
    deliveries.findIndex(d => d.id == delivery_id),
    1
  )

  Object.assign(
    target.service_forms
      .find(f => f.service_type == service_type)
      .forms.find(f => f.id == id),
    { delivery_status: 'ready for pickup' }
  )

  return {
    deliveries: [...deliveries, target],
    ...rest,
  }
}

export default reducer
