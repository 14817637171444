import React from 'react'
import Modal from 'react-responsive-modal'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { extractKeys, withoutKeys } from '@helpers/objects'

import {
  ExemptionsForm,
  calculateExemptionToatal,
  VehicleForm,
  ApprovalForm,
} from './_exemptions-form'
// import redirectToCustoms from './_redirect'

export default class FamilyIslandDevelopment extends React.Component {
  state = {
    record: null,
    loading: false,
  }

  long_name = `
    Family Island Development Encouragement Act Exemption
    <br/>
    (Andros & Southern Bahamas)
  `

  resource = '/family_island_dev_enc_exemptions'
  form_key = 'family_island_dev_enc_exemption'
  free = true

  // before_apply = redirectToCustoms
  skip_location = true
  hide_buttons = {
    status: true,
  }

  islands = [
    'Ragged Island',
    'San Salvador',
    'Rum Cay',
    'Cat Island',
    'Long Island',
    'Mayaguana',
    'Inagua',
    'Crooked Island',
    'Acklins Island',
    'Long Cay',
    'Andros',
  ]

  validate = form => {
    if (!form.exemption_category.size) {
      return 'No exemption categories were selected'
    }

    const exemptions = [
      'building_supplies_exemption_list',
      'equipment_exemption_list',
      'kitchen_exemption_list',
      'machinery_exemption_list',
      'furniture_exemption_list',
      'electrical_exemption_list',
      'hardware_exemption_list',
      'it_hardware_exemption_list',
      'solar_exemption_list',
      'plumbing_exemption_list',
      'vehicle_exemption_list',
      'fishing_exemption_list',
      'commercial_furniture_exemption_list',
    ]

    const transformed = exemptions.reduce((obj, prop) => {
      const ex = form[prop]
        ?.filter(row => row.item || row.make)
        ?.map(row => ({
          ...withoutKeys({ ...row }, 'tariff'),
          value: Number(row.value).toFixed(2),
          tariff_id: row.tariff?.id,
          tariff_code: row.tariff?.value,
        }))

      if (ex?.length) obj[prop] = ex
      return obj
    }, {})

    return {
      ...transformed,
      total_value_of_exemption: exemptions
        .reduce((sum, prop) => sum + calculateExemptionToatal(form[prop]), 0)
        .toFixed(2),
    }
  }

  openPicker = (data, target) => {
    const options = data.map(d => ({
      label: `<span class='text-muted'>${d.value}</span><br/>${d.label}`,
      value: extractKeys(d, 'id', 'value'),
    }))

    this.setState({
      picker: {
        target,
        options,
        unskippable: true,
        heading: 'Please Select The Correct Tariff Code',
        onSelect: () => this.setState({ picker: null }),
      },
    })
  }

  custom_action = {
    text: 'Approve Exemptions',
    icon: 'check-square',
    fn: record => this.setState({ record }),
  }

  close = () => this.setState({ record: null })

  update = status => {
    switch (status) {
      case 'SAVING':
        return this.setState({ record: null, loading: true })
      case 'DONE':
      case 'FAILED':
        return this.setState({ loading: false })
      default:
    }
  }

  fields = (form, viewMode) => {
    const { openPicker } = this

    const availableExemptions =
      form.client_type == 'individual'
        ? [
            'Building Materials',
            'Plumbing Fixtures',
            'Electrical Fixtures',
            'Furniture & Appliances',
            'Solar Panels',
            'Fishing Equipment',
            'Commercial Equipment',
          ]
        : [
            'Building Materials',
            'Commercial Equipment',
            'Plumbing Fixtures',
            'Commercial Machinery',
            'Furniture & Appliances',
            'IT Hardware and Software',
            'Electrical Fixtures',
            'Company Vehicle',
            'Fishing Equipment',
            'Hardware Supplies',
            'Commercial Furniture',
            'Solar Panels',
          ]

    const subject = form.client_type == 'individual' ? 'Home' : 'Company'

    return [
      {
        name: 'client_type',
        label: 'Application For?',
        options: [
          { label: 'Individual', value: 'individual' },
          { label: 'Business', value: 'business' },
        ],
        view: {
          label: 'Application Type',
          value: v => v.capitalize(),
        },
      },
      {
        heading: subject + ' Details',
        hide: !form.client_type,
      },
      {
        name: 'company_type',
        hide: form.client_type != 'business',
        options: [
          'Incorporated / Limited Company',
          'Sole Proprietorship',
          'Partnership',
          'Home Based',
        ],
      },
      {
        name: 'trading_name',
        hide: form.company_type != 'Sole Proprietorship',
      },
      {
        name: 'location',
        label: subject + ' Island',
        options: this.islands,
        hide: !form.client_type,
      },
      {
        name: 'company_name',
        label: 'Name of Company',
        hide: form.client_type != 'business',
      },
      {
        name: 'company_type_age',
        label: 'New or Existing ' + subject,
        options: ['New', 'Existing'],
        hide: !form.client_type,
      },
      {
        name: 'bl_number',
        label: 'Business License Number',
        hide: form.client_type != 'business',
      },
      {
        name: 'bl_expiry_date',
        type: 'date',
        label: 'Business Licence Expiry Date',
        hide: form.client_type != 'business',
      },
      {
        name: 'tax_id_number',
        label: 'TIN#',
        hide: form.client_type != 'business',
        required: false,
      },
      {
        name: 'tax_comp_cert_expiry',
        label: 'Tax Compliance Certificate Expiry Date',
        type: 'date',
        showYearDropdown: true,
        hide: form.client_type != 'business',
        required: false,
      },
      {
        name: 'business_turnover',
        label: 'Sales/Business Turnover',
        is: 'currency',
        hide: form.client_type != 'business' || form.client_type_age == 'New',
      },
      {
        name: 'years_of_business',
        label: 'Years of Business',
        is: 'integer',
        hide: form.client_type != 'business' || form.client_type_age == 'New',
        view: {
          hide: form.client_type != 'business',
          value: v => v + ' year(s)',
        },
      },
      '::Supporting Documents',
      {
        name: 'tax_comp_cert_upload',
        label: 'Tax Compliance Certificate',
        key: f => f.image[1],
        type: 'file:pdf',
        hide: form.client_type == 'individual',
      },
      {
        name: 'business_license_upload',
        label: 'Business Licence',
        type: 'file:pdf',
        key: f => f.image[6],
        hide: form.client_type == 'individual',
      },
      {
        name: 'site_plan_upload',
        label: 'Min. Of Public Works Stamped Floor Plan',
        type: 'file:pdf',
        key: f => f.image[3],
        required: false,
      },
      {
        name: 'occupancy_letter_upload',
        label: 'Occupancy Certificate',
        type: 'file:pdf',
        key: f => f.image[5],
        hide: !form.exemption_category?.has?.('Furniture & Appliances'),
      },
      {
        name: 'invoice_upload',
        label: 'Invoice',
        key: f => f.image[2],
        type: 'file:pdf',
      },
      {
        heading: 'Exemption Details',
        hide: !form.client_type,
      },
      {
        name: 'exemption_category',
        label: 'Exemption Categories',
        type: 'checklist',
        view: false,
        options: availableExemptions,
        hide: !form.client_type,
      },
      {
        name: 'building_supplies_exemption_list',
        label: 'Building Materials',
        hide: !form.exemption_category?.has?.('Building Materials'),
        ...ExemptionsForm(
          form.building_supplies_exemption_list,
          openPicker,
          viewMode
        ),
      },
      {
        name: 'equipment_exemption_list',
        label: 'Commercial Equipment',
        hide: !form.exemption_category?.has?.('Commercial Equipment'),
        ...ExemptionsForm(form.equipment_exemption_list, openPicker, viewMode),
      },
      {
        name: 'kitchen_exemption_list',
        label: 'Industrial Kitchen Appliances',
        hide: !form.exemption_category?.has?.('Industrial Kitchen Appliances'),
        ...ExemptionsForm(form.kitchen_exemption_list, openPicker, viewMode),
      },
      {
        name: 'machinery_exemption_list',
        label: 'Commercial Machinery',
        hide: !form.exemption_category?.has?.('Commercial Machinery'),
        ...ExemptionsForm(form.machinery_exemption_list, openPicker, viewMode),
      },
      {
        name: 'furniture_exemption_list',
        label: 'Furniture & Appliances',
        hide: !form.exemption_category?.has?.('Furniture & Appliances'),
        ...ExemptionsForm(form.furniture_exemption_list, openPicker, viewMode),
      },
      {
        name: 'electrical_exemption_list',
        label: 'Electrical Fixtures',
        hide: !form.exemption_category?.has?.('Electrical Fixtures'),
        ...ExemptionsForm(form.electrical_exemption_list, openPicker, viewMode),
      },
      {
        name: 'hardware_exemption_list',
        label: 'Hardware Supplies',
        hide: !form.exemption_category?.has?.('Hardware Supplies'),
        ...ExemptionsForm(form.hardware_exemption_list, openPicker, viewMode),
      },
      {
        name: 'it_hardware_exemption_list',
        label: 'IT Hardware and Software',
        hide: !form.exemption_category?.has?.('IT Hardware and Software'),
        ...ExemptionsForm(
          form.it_hardware_exemption_list,
          openPicker,
          viewMode
        ),
      },
      {
        name: 'solar_exemption_list',
        label: 'Solar Panels',
        hide: !form.exemption_category?.has?.('Solar Panels'),
        ...ExemptionsForm(form.solar_exemption_list, openPicker, viewMode),
      },
      {
        name: 'plumbing_exemption_list',
        label: 'Plumbing Fixtures',
        hide: !form.exemption_category?.has?.('Plumbing Fixtures'),
        ...ExemptionsForm(form.plumbing_exemption_list, openPicker, viewMode),
      },
      {
        name: 'vehicle_exemption_list',
        label: 'Company Vehicle',
        hide: !form.exemption_category?.has?.('Company Vehicle'),
        tooltip:
          'Please note that all vehicles must be less than 10 (ten) years old, except for heavy duty vehicles',
        ...ExemptionsForm(form.vehicle_exemption_list, openPicker, viewMode),
        columns: VehicleForm(viewMode),
      },
      {
        name: 'fishing_exemption_list',
        label: 'Fishing Equipment',
        hide: !form.exemption_category?.has?.('Fishing Equipment'),
        ...ExemptionsForm(form.fishing_exemption_list, openPicker, viewMode),
      },
      {
        name: 'commercial_furniture_exemption_list',
        label: 'Commercial Furniture',
        hide: !form.exemption_category?.has?.('Commercial Furniture'),
        ...ExemptionsForm(
          form.commercial_furniture_exemption_list,
          openPicker,
          viewMode
        ),
      },
      '::SPACER',
      {
        name: 'penalty',
        type: 'checkbox',
        label: `
            Providing false or misleading information
            &mdash; or using exemptions for purposes other than applied for
            &mdash; will lead to prosecution and related fines and criminal penalties.
        `,
      },
    ]
  }

  hidden_fields = () => [
    {
      name: 'total_value_of_exemption',
      view: {
        label: 'Total Exemption Value',
        value: v => '$' + Number(v).toLocaleString(),
      },
    },
    {
      key: f => f.image[7],
      name: 'pdf_upload',
      type: 'file:image',
      label: 'Exemption Certificate',
    },
  ]

  render = () => {
    const { state, props, close, fields, resource, form_key, update } = this
    const { record } = state

    const fprops = {
      update,
      resource,
      close,
      form_key,
      location: props.location,
      history: props.history,
    }

    if (record)
      Object.assign(fprops, {
        record,
        fields: fields(record),
      })

    return (
      <React.Fragment>
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal open={!!record} onClose={close} center>
          <ApprovalForm {...fprops} />
        </Modal>
      </React.Fragment>
    )
  }
}
