import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class TourCarFranchiseRenewal extends React.Component {
  // id       = 'TourCarFranchiseApplication'
  // service  = 'tour car franchise application'
  // title    = 'Tour Car Franchise'
  // agency   = 'rtd'
  // item     = '396'
  // admin    = 'road_traffic'
  resource = '/tour_car_franchises'
  form_key = 'tour_car_franchise'
  skip_location = true

  defaults = {
    client_type: 'individual',
    granted: false,
    renewal: true,
    variation: false,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        granted: false,
        renewal: true,
        variation: false,
      },
    },
  }
  
  fields = () => [
    {
      name: 'client_type',
      save: true,
      hide: true,
    },
    {
      name: 'granted',
      save: true,
      hide: true,
    },
    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    {
      name: 'variation',
      save: true,
      hide: true,
    },

    {
      name: 'existing_vehicles_numb',
      label: 'Number of vehicles specified on existing franchise',
      type: 'integer',
    },
    {
      name: 'applied_vehicles_numb',
      label: 'Number of vehicles now applied for',
      type: 'integer',
    },
    {
      name: 'variation_requested',
      label: 'Variation requested',
      type: 'text',
    },
    {
      name: 'vehicles_used_list',
      label: 'Vehicles to be used',
      // required: false,
      hint: 'e) Registered numbers of vehicles mentioned above that may be used on pre-arranged transfers',
      view: {
        value: v => {
          if (!v?.length) return null
          return v.map(r => JSON.parse(r))
        }
      },
      columns: [
        {
          name: 'make',
          label: 'Make',
          required: false,
        },
        {
          name: 'model',
          label: 'Model',
          required: false,
        },
        {
          name: 'year',
          label: 'Year',
          type: 'integer',
          required: false,
        },
        {
          name: 'registration_numb',
          label: 'Registration #',
          required: false,
        },
        {
          name: 'pre_arranged_transfer_use',
          label: 'e) Pre-arranged use',
          type: 'checkbox',
          required: false,
        },
      ]
    },
    // {
    //   name: 'vehicles_registered',
    //   label: 'Registered numbers of vehicles mentioned above that may be used on pre-arranged transfers',
    //   hint: 'NOTES: Only specified vehicles may be used for the carriage of passengers on pre-arranged transfers and the number of such vehicles shall not exceed one-half of the maximum number of vehicles authorised on the franchise'
    //   columns: ['vehicles_registered'],
    //   format: v => v.vehicles_registered
    // },
    {
      name: 'service_dates_list',
      label: 'Days or Occasions on which vehicles may be used',
      // required: false,
      // hint: 'Please separate each company with a newline',
      view: {
        value: v => {
          if (!v?.length) return null
          return v.map(r => JSON.parse(r))
        }
      },
      columns: [
        {
          name: 'date',
          label: 'Date',
          type: 'date',
          required: false,
        },
        {
          name: 'time_start',
          label: 'Time start',
          type: 'time',
          required: false,
        },
        {
          name: 'time_end',
          label: 'Time End',
          type: 'time',
          required: false,
        },
      ]
    },
    {
      name: 'max_numb_vehicles_used',
      label: 'Maximum number of vehicles to be used on each day or occasion',
      type: 'integer',
    },
    {
      name: 'pick_up_points_list',
      label: 'Position of pick up points',
      columns: ['pick_up_points'],
      format: v => v.pick_up_points
    },
    {
      name: 'drop_off_points_list',
      label: 'Position of setting down points',
      columns: ['drop_off_points'],
      format: v => v.drop_off_points
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

