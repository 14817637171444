import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class EltRegistration extends React.Component {
  resource = '/elt-registrations'
  form_key = 'elt-registration'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Details of aircraft",
    {
        name: 'reg_mark',
        label:'Registration Mark',
        hint: 'C6'
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'max_persons',
        label: 'Max Persons on Board'
    },
    {
        name: 'radio_sign',
        label: 'Radio Call Sign'
    },
    {
        name: 'acars',
        label: 'ACARS Fitted',
        type: 'select:bool'
    },
    {
        name: 'aircraft_address',
        label: 'Mode-S Aircraft Address',
        hint: 'Hex'
    },
    {
        name: 'aircraft_colours'
    },
    "::ELT Information",
    {
        name: 'hex_code',
        label: 'COSPAS/SARSAT 15bit Hex Code'
    },
    {
        name: 'elt',
        label: "ELT"
    },
    {
        name: 'battery_exp',
        label: 'Battery Expiry Date',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'elt_manufacturer',
        label: 'ELT Manufacturer'
    },
    {
        name: 'model_num',
        label: 'Model No.'
    },
    {
        name: 'elt_serial',
        label: 'ELT Serial No.(s)'
    },
    {
        name: 'beacon_type',
        label: 'COSPAS-SARSAT Beacon Type Approval Certificate (CSTA) number'
    },
    {
        name: 'suplier_address',
        label: 'Address of Supplier'
    },
    {
        name: 'phone_num',
        label: 'Telephone No.',
        type: 'phone'
    },
    "::Details of Aircraft Operator",
    {
        name: 'operator_name',
        label:'Name of Operator'
    },
    {
        name: 'address'
    },
    {
        name: 'operator_num',
        label: 'Telephone No.',
        type: 'phone',
    },    
    {
        name:'work_num',
        type: 'phone'
    },
    {
        name:'fax'
    },
    {
        name:'op_email',
        label: 'Email',
        type: 'email'
    },
    "::Emergency Contact Information",
    {
        name: 'primate_contact',
        label: 'Primary 24 Hour Contact Name'
    },
    {
        name: 'primary_num',
        label: 'Telephone No.',
        type: 'phone'
    },
    {
        name: 'primary_work',
        label: 'Work',
        type: 'phone'
    },
    {
        name: 'mobile',
        type: 'phone'
    },
    {
        name: 'second_contact',
        label: 'Secondary 24 Hour Contact Name'
    },
    {
        name: 'secondary_num',
        label: 'Telephone No.',
        type: 'phone'
    },
    {
        name: 'secondary_work',
        label: 'Work',
        type: 'phone'
    },
    {
        name: 'secondary_mobile',
        label: 'Mobile',
        type: 'phone'
    }
    
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
