import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class SpecialVehicleLicense extends React.Component {
  resource = '/special_vehicle_licenses'
  form_key = 'special_vehicle_license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  /*
  
  t.string :form_num
t.string :payment_amount
t.string :payment_invoice
t.string :payment_status
t.string :application_status
t.string :application_decision, default: 'pending'
t.boolean :acceptance, default: false
t.boolean :declare, default: false
t.string :pickup_location
t.string :pickup_sub_location
t.belongs_to :user, foreign_key: true
t.belongs_to :delivery, foreign_key: true, null: true
t.belongs_to :officer, foreign_key: { to_table: :users }, null: true
t.belongs_to :proxy, foreign_key: { to_table: :users }, null: true
t.string :delivery_status
t.date :app_status_change_date
t.string :ip_address
t.boolean :requested_proxy, default: false

t.string :registration_numb
t.string :chassis_numb
t.string :vehicle_make
t.string :vehicle_type
t.string :gross_weight
t.string :number_of_axles
t.string :axle_load1
t.string :axle_load2
t.string :axle_load3
t.string :axle_load4
t.string :axle_dist_1_2
t.string :axle_dist_2_3
t.string :axle_dist_3_4
t.string :axle_1_wheel_numb
t.string :axle_2_wheels_numb
t.string :axle_3_wheels_numb
t.string :axle_4_wheels_numb
t.string :track_inside_wheels
t.string :dist_inside_wheels
t.string :overall_length_vehicle
t.string :overall_length_trailer
t.string :overall_length_both
  
  */

  fields = form => [
    {
        name: 'registration_numb',
        label: 'Registration number',
     },
     {
        name: 'chassis_numb',
        label: 'Chassis number',
    },
     {
        name: 'vehicle_make',
        label: 'Make of vehicle(s)',
    },
    {
        name:'vehicle_type',
        label: 'Type of vehicle/Trailer',
    },
     {
        name: 'gross_weight',
        label: 'Total Gross Weight',
    },
     {
        name: 'number_of_axles',
        label: 'Number of Axles',
        is:'integer',
    },
     {
        name: 'axle_load1',
        label: 'Load on Axle 1',
        is:'integer',
    },
     {
        name: 'axle_2_wheels_numb',
        label: 'Load on Axle 2',
        is:'integer',
    },
     {
        name: 'axle_load3',
        label: 'Load on Axle 3',
        is:'integer',
    },
     {
        name: 'axle_load4',
        label: 'Load on Axle 4',
        is:'integer',
    },
     {
        name: 'axle_dist_1_2',
        label: 'Distance Axle 1 to Axle 2',
    },
     {
        name: 'axle_dist_2_3',
        label: 'Distance Axle 2 to Axle 3',
    },
     {
        name: 'axle_dist_3_4',
        label: 'Distance Axle 3 to Axle 4',
    },
     {
        name: 'axle_1_wheel_numb',
        label: 'Number of wheels per Axle 1',
        is:'integer',
    },
     {
        name: 'axle_2_wheel_numb',
        label: 'Number of wheels per Axle 2',
        is:'integer',
    },
     {
        name: 'axle_3_wheel_numb',
        label: 'Number of wheels per Axle 3',
        is:'integer',
    },
     {
        name: 'axle_4_wheel_numb',
        label: 'Number of wheels per Axle 4',
        is:'integer',
    },
     {
        name: 'track_inside_wheels',
    },
     {
        name: 'dist_inside_wheels',
        label: 'Distance inside wheels',
    },
     {
        name: 'overall_length_vehicle',
        label: 'Overall length of vehicle',
    },
     {
        name: 'overall_length_trailer',
        label: 'Overall length of trailer',
    },
     {
        name: 'overall_length_both',
        label: 'Overall length of both',
    },
       ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
