import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import toastr from '@modules/toastr'
import Swal from 'sweetalert2'
import { fullName } from '@modules/form-wizard/utils'

export default class JrJunkanooRegistration extends React.Component {
  resource = '/jr_junkanoo_registrations'
  form_key = 'jr_junkanoo_registration'

  free          = true
  skip_location = true
  hide_buttons  = {
    status: true,
    deny: false,
    fee: true,
  }
  custom_actions = [
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: [['senior_officer']],
      fn: record =>
        Swal.fire({
          title: 'Approve Application',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I approve this applicant",
            'denied': "I deny this applicant",
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.value) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })
              toastr.success('Success', 'Application status updated successfully')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
    {
      text: 'Recommend To Cultural Officer',
      icon: 'check-square',
      roles: [['officer']],
      fn: record =>
        Swal.fire({
          title: 'Recommend To Cultural Officer',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I recommend this applicant",
            'denied': "I do not recommend this applicant",
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.value) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })
              toastr.success('Success', 'Application status updated successfully')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
  ]

  validate = null

  foremost = null

  fields = form => {
    const list = [
      {
        name: 'organization_name',
      },
      {
        name: 'category',
        options: [
          { label: 'Competitive', value: 'competitive', },
          { label: 'For Fun', value: 'for fun' }
        ],
      },
      {
        name: 'group_number',
        label: 'Number of Participants',
        required: false,
      },
      {
        name: 'sponsor',
        required: false,
      },
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'checkbox',
        required: false,
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    ]

    if (!form.i_am_contact) {
      list.push(
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_phone_numb',
          type: 'phone',
          label: 'Primary Phone #',
        },
        {
          name: 'contact_work_numb',
          type: 'phone',
          label: 'Work Phone #',
          required: false
        },
        {
          name: 'contact_home_numb',
          type: 'phone',
          label: 'Home Phone #',
          required: false
        },
        {
          name: 'contact_po_box',
          label: 'P. O. Box',
          required: false
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
      )
    }

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
