import React from 'react'
import { connect } from 'react-redux'

import { extractKeys } from '@helpers/objects'
import { logoutUser } from '@actions/users'

class Session extends React.Component {
  static defaultProps = {
    timeout: 1000 * 60 * 60
  }

  timer  = null
  expire = () => this.props.logoutUser(true)

  reset = () => {
    clearTimeout(this.timer)
    if (!$app.token) return
    this.timer = setTimeout(this.expire, this.props.timeout)
  }

  componentDidMount () {
    $app.token && this.reset()
  }

  componentDidUpdate (next) {
    this.props.count !== next.count && this.reset()
  }

  render = () => null
}

export default connect(
  state => extractKeys(state.session, 'count'),
  { logoutUser }
)(Session)