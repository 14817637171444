import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '@images/logo/default.png'

const SelectScreen = ({ agency, title, sublink, openNewForm }) => {
  return (
    <div className='form-wizard__first-screen content'>
      <div className='page-inner'>
        <article>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          <h4>{agency?.name.capitalize()}</h4>
          {agency.department
            ? <h5>{agency.department}</h5>
            : null
          }
          <br />
          <div className='flex flex-col sm:flex-row sm:justify-center'>
            <Link to={sublink('applications')}>
              <i className='fas fa-list'></i>
              <span className='fw-bold'>View Applications</span>
            </Link>
            <a onClick={openNewForm}>
              <i className='fas fa-plus'></i>
              <span className='fw-bold'>Apply Now</span>
            </a>
          </div>
          <img
            src={Logo}
            alt='My Gateway Logo'
            className='absolute right-0 bottom-0 mb-24 md:mb-16 mr-4 md:mr-12 w-20 sm:w-32 md:w-48'
          />
        </article>
      </div>
    </div>
  )
}

export default SelectScreen
