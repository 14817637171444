import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
//import generateRange from '@helpers/range'
//import { BJCS, BGCSES, NATIONAL_EXAM_GRADES } from '@constants/national-exams'

 /* const years = generateRange(1980, new Date().getFullYear())
  .map(n => n.toFixed())
  .reverse() */

export default class __SERVICE_NAME__ extends React.Component {
  resource = '/national_youth_awards'
  form_key = 'national_youth_award'
  defaults = {}

  // Delete lines as needed
  free = true
  skip_location = true
  hide_buttons = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => {
    const list = [
      {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
      },
      {
        name: 'award_type',
        options: [
          { value: 'pacesetter', label: 'Pacesetter Award' },
          { value: 'youth organization', label: 'Youth Organization Award' },
          { value: 'youth enterprise', label: 'Youth Enterprise Award' },
          { value: 'youth leader', label: 'Youth Leader Award' },
        ]
      },
      {
        name: 'pacesetter_in',
        hide: form.award_type != 'pacesetter',
        label: 'Area Nominee Being Awarded For',
        options: [
          { value: 'business', label: 'Business' },
          { value: 'religion', label: 'Religion' },
          { value: 'creative arts', label: 'Creative Arts' },
          { value: 'education', label: 'Education' },
          { value: 'community service', label: 'Community Service' },
          { value: 'sports', label: 'Sports' },
        ]
      },
    ]
    
    if (form.test) {
      list.push(
        /**
         t.boolean :i_am_guardian, default: false
         t.boolean :i_am_youth, default: false
         t.string :youth_first_name
         t.string :youth_middle_name
         t.string :youth_last_name
         t.string :youth_date_of_birth
         t.string :youth_sex
         t.string :youth_island
         t.string :youth_settlement
         t.string :youth_address
         t.string :youth_po_box
         t.string :relationship_to_youth
         */
      )
    }

    list.push(
      {
        name: 'youth_activities_list',
        label: 'Youth Activities/Organizations Involved In',
        columns: ['activity'],
        format: v => v.activity
      },
      {
        name: 'special_awards_list',
        label: 'Special Awards',
        columns: ['award'],
        format: v => v.award
      },
      {
        name: 'self_promotion',
        type: 'textarea',
        label: 'Is there anything interesting you wish to share?',
      },
  
      '::Education',
      {
        name: 'attending_school',
        type: 'select:bool',
        label: 'Are you currently attending school?',
      },
      {
        name: 'present_grade',
        hide: form.attending_school !== true,
        label: 'Current grade Level',
      },
      {
        name: 'place_of_employment',
        hide: form.attending_school !== false,
        label: 'Place of Employment',
      },
    /*  {
        name: 'bjcs_passed_list',
        label: 'BJCs Passed',
        columns: [
          {
            name: 'subject',
            options: BJCS
          },
          {
            name: 'grade',
            options: NATIONAL_EXAM_GRADES.slice(0, 4),
          },
          {
            name: 'year',
            options: years
          }
        ]
      },
      {
        name: 'bgcses_passed_list',
        label: 'BGCSEs Passed',
        columns: [
          {
            name: 'subject',
            options: BGCSES
          },
          {
            name: 'grade',
            options: NATIONAL_EXAM_GRADES.slice(0, 4),
          },
          {
            name: 'year',
            options: years
          } 
        ] 
      }, */
      {
        name: 'academic_certificates',
        columns: ['certificate'],
        format: v => v.certificate
      },
      {
        name: 'additional_comments',
        required: false,
      },
      {
        name: 'i_am_contact',
        label: 'I am the contact person for nominee',
        type: 'select:bool'
      },
      {
        name: 'phone_number',
        type: 'phone',
        hide: !form.i_am_contact === true
      },
      {
        name: 'email',
        type: 'email',
        hide: !form.i_am_contact === true
      },
      '::Uploads',
      {
        name: 'photo_upload',
        type: 'file:image',
        key: 'image.0',
        label: 'Passport Photo of Nominee',
      },
      {
        name: 'biography_upload',
        label: 'Biography',
        key: 'image.1',
        type: 'file:pdf',
        required: false
      }
    )

      return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
