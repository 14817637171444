import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class TaxiCabGrant extends React.Component {
  id       = 'TaxiCabGrantApplication'
  service  = 'taxi cab grant application'
  title    = 'Taxi Cab Grant'
  agency   = 'rtd'
  item     = '396'
  admin    = 'road_traffic'
  resource = '/taxi_cab_grant_applications'
  form_key = 'taxi_cab_grant_application'

  fee = 15

  fields = form => [
    'passport',
    'occupation',
    {
      name: 'currently_hold',
      type: 'select:bool',
      label: 'Currently hold grant?'
    },
    'description',
    {
      name: 'previous_plate',
      type: 'select:bool',
      label: 'Previous Plate?'
    },
    {
      name:'plate_number',
      hide: form.previous_plate === false
    },
    {
      name: 'franchise',
      type: 'select:bool',
      label: 'Currently have franchise?'
    },
    'type',
    {
      name: 'current_license',
      type: 'select:bool',
      label: 'Current Licence?'
    },
    {
      name: 'license_number',
      label: 'Licence #',
      is: 'integer',
      hide: form.current_license === false
    },
    {
      name: 'disqualified',
      type: 'select:bool',
      label: 'Disqualified?'
    },
    {
      name: 'reason_disqualification',
      hide: form.disqualified === false,
    },
    {
      name: 'own',
      type: 'select:bool',
      label: 'Own Taxi?',
    },
    {
      name: 'driver',
      type: 'select:bool',
      label: 'Have a driver?',
    },
    'reason'
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}