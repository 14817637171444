import { useState, useEffect } from 'react'
import { extractKeys } from '@helpers/objects'

const getWindowDimensions = () =>
  extractKeys(window, 'innerWidth:width', 'innerHeight:height')

const useWindowDimensions = () => {
  const [values, setValues] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setValues(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return values
}

export default useWindowDimensions
