import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { MALE_OR_FEMALE } from '@modules/form-wizard/utils'

const today = moment()
const _18YearsAgo = today.clone().subtract(18, 'years')

export default class YouthCrimePrevention extends React.Component {
  long_name = `
    Urban Renewal Revolutionalized
    <br/>
    Multidisciplinary Crime Prevention Youth Program
  `

  resource = '/urban_renewal_crime_preventions'
  form_key = 'urban_renewal_crime_prevention'
  free = true
  custom_acceptance = true

  skip_location = true
  hide_buttons = false

  custom_actions = []

  fields = form => [
    `::Child's Details`,
    {
      name: 'child_first_name',
      label: 'First Name',
    },
    {
      name: 'child_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'child_last_name',
      label: 'Last Name',
    },
    {
      name: 'child_date_of_birth',
      label: 'Date of Birth',
      type: 'date',
      minDate: _18YearsAgo.date(),
      view: {
        value: v => `${moment(v).format('Do MMM, YYYY')} (${today.diff(v, 'years')} yrs.)`
      }
    },
    {
      name: 'child_sex',
      label: 'Sex',
      options: MALE_OR_FEMALE,
      view: {
        value: v => v.initialCaps()
      }
    },
    {
      name: 'child_address',
      label: 'Street Address',
    },
    {
      name: 'child_school',
      label: 'School Attending',
    },
    {
      name: 'child_grade',
      is: 'integer',
      label: 'Current Grade',
      view: {
        value: v => 'Grade ' + v
      }
    },
    {
      name: 'child_email',
      required: false,
      label: 'Email',
    },

    '::Media Presence',
    {
      view: false,
      heading: `
        <span>
        The Urban Renewal Revolutionized Multidisciplinary Crime Prevention
        Youth Program intends to have a Youth Ministry website where photos and
        videos from events are periodically published. We will also intend to
        have a bulletin board where we sometimes post photos from events.
        Initial your choice below
        </span>
      `,
    },
    {
      name: 'child_media_agreement',
      type: 'checkbox',
      required: false,
      label: `
        Yes, you can use pictures and videos of my child. I hereby grant
        the Urban Renewal Revolutionized Multidisciplinary Crime Prevention
        Youth Program Nassau, Bahamas the permission to use my child's likeness
        in photographs, video, and other media in any and all of its
        publications, including bulletin boards and website entries. I waive
        any right to royalties or other compensation arising or related to the
        use of the photographs or videos.
      `,
      view: {
        hide: false,
        label: 'Consented to Media Presence?',
        value: v => v ? 'Yes' : 'No'
      }
    },
    {
      name: 'child_media_disagreement',
      type: 'checkbox',
      required: false,
      label: `
        No, please do not print or publish photos or videos of my child online
        or in church-related publications.
      `,
    },
    {
      key: 'image.0',
      name: 'child_photo_upload',
      label: 'Passport Photo',
      type: 'file:image',
      hide: form.child_media_agreement !== true,
    },

    '::SPACER',
    {
      name: 'extracuricular_activities',
      label: 'Extracurricular Activities',
      multi: true,
      options: [
        { label: 'BAND', value: 'BAND' },
        { label: 'RAKE AND SCRAPE', value: 'RAKE AND SCRAPE' },
        { label: 'RIFLE DRILLS', value: 'RIFLE DRILLS' },
        { label: 'ORGANIZE DANCE', value: 'ORGANIZE DANCE' },
        { label: 'JUNKANOO', value: 'JUNKANOO' },
        { label: 'POP BAND', value: 'POP BAND' },
        { label: 'FLAG CADETS', value: 'FLAG CADETS' },
      ],
    },
    {
      name: 'instruments_chosen',
      multi: true,
      options: [
        { label: 'TRUMPET', value: 'TRUMPET' },
        { label: 'TROMBONE', value: 'TROMBONE' },
        { label: 'MELLOPHONE', value: 'MELLOPHONE' },
        { label: 'SOUSAPHONE', value: 'SOUSAPHONE' },
        { label: 'BARITONE', value: 'BARITONE' },
        { label: 'ALTO SAX', value: 'ALTO SAX' },
        { label: 'TENOR SAX', value: 'TENOR SAX' },
        { label: 'CLARINET', value: 'CLARINET' },
        { label: 'FLUTE', value: 'FLUTE' },
        { label: 'DRUMS', value: 'DRUMS' },
      ],
      hide: !(form.extracuricular_activities?.has?.('BAND') === true)
    },

    '::Emergency Contact',
    {
      name: 'emergency_first_name',
      label: 'First Name'
    },
    {
      name: 'emergency_last_name',
      label: 'Last Name'
    },
    {
      name: 'emergency_relationship',
      label: 'Relationship'
    },
    {
      name: 'emergency_phone_numb',
      as: 'phone',
      label: 'First Contact Number'
    },
    {
      name: 'emergency_phone_numb_2',
      as: 'phone',
      label: 'Second Contact Number',
      required: false,
    },
    {
      name: 'emergency_phone_numb_3',
      as: 'phone',
      label: 'Third Contact Number',
      required: false,
    },

    `::Child's Medical Details`,

    // t.string "child_medical_issues", default: [], array: true
    // t.string "child_allergies", default: [], array: true
    {
      name: 'child_insured',
      label: 'Is your child insured?',
      type: 'select:bool',
      view: {
        element: (noop, { healthcare_provider }) => healthcare_provider
          ? <strong className='text-green-600'>This child is insured</strong>
          : <strong className='text-orange-700'>This child is not insured</strong>
      },
    },
    {
      name: 'healthcare_provider',
      hide: form.child_insured !== true,
      view: !!form.healthcare_provider,
    },
    {
      name: 'healthcare_phone_numb',
      label: `Provider's Contact #`,
      hide: form.child_insured !== true,
      view: !!form.healthcare_provider,
    },
    {
      name: 'healthcare_insurance_company',
      label: 'Insurance Company',
      hide: form.child_insured !== true,
      view: !!form.healthcare_provider,
    },
    {
      name: 'healthcare_policy_numb',
      label: 'Policy #',
      hide: form.child_insured !== true,
      view: !!form.healthcare_provider,
    },
    {
      name: 'medical_agreement',
      type: 'checkbox',
      hide: form.child_insured !== true,
      label: `
        Informed Consent to Medical Treatment: I request that in my absence the
        above-named minor be admitted to any hospital or medical facility for
        diagnosis and treatment. I request and authorize physicians, dentists,
        and staff, duly licensed as Doctors of Medicine or Doctors of Dentistry
        or other such licensed technicians or nurses, to perform any diagnostic
        procedures, treatment procedures, operative procedures and x-ray
        treatment of the above minor. I have not been given a guarantee as to the
        results of examination or treatment. I authorize the hospital or medical
        facility to dispose of anyspecimen or tissue taken from the above-named
        minor. I assume full  responsibility for all costs of such treatment.
        Further, should it be necessary for  the  participant to return home due
        to medical, disciplinary, or other reasons, I do hereby assume
        responsibility for the participant's transportation home and any costs
        related thereto.
      `
    },

    '::SPACER',
    {
      name: 'liability_release_agreement',
      type: 'checkbox',
      label: `
        Parental Permission and Liability Release: As parent/legal guardian of
        the participant names above, I give my permission to participate fully
        in the Urban Renewal Revolutionized Multidisciplinary Crime Prevention
        Youth Program and field trips. I agree to indemnify and hereby release
        the URMTP, and designated employees, volunteers, and participating
        organizations from any and all liability, claims, demands for personal
        injury, sickness and death, as well as property damage and expenses of
        any nature whatsoever which may be incurred by the undersigned of the
        participant resulting from said participant's involvement in the above
        mentioned event (including transportation to and from the event).
        Furthermore, I on behalf of the participant hereby assume all risk of
        personal injury, sickness, death, damage, and expenses resulting from
        said participant’s involvement in the above described event.
        `
    },
    {
      name: 'acceptance',
      use_as_acceptance: true,
      type: 'checkbox',
      label: `
          Safety: As the participant, I agree to follow all procedures, safety
          precautions, and rules and regulations set by Urban Renewal
          Revolutionized Multidisciplinary Crime Prevention Youth Program and its
          associated Ministries.
        `
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
