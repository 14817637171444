import moment from 'moment'

import * as T from '@action-types'
import toastr from '@modules/toastr'
import sendMail from '@modules/send-mail'

const clearSession = () => {
  window.sessionStorage.clear()
  window.location.href = '/login'
}

const createError = err => dispatch => {
  const { status, data } = err.response

  status == 401 && clearSession()
  toastr.error('Error', data.error)

  setTimeout(() => {
    dispatch({
      type: T.CLEAR_ERROR
    })
  }, 2000)

  dispatch({
    type: T.GET_RESPONSE_ERROR,
    payload: err
  })
}

const getRequest = {
  type: T.GET_REQUEST_SUCCESS,
  payload: {}
}

export const getFormTypes = () => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get('/schedules/form_types')

    dispatch({
      type: T.GET_APPOINTMENT_TYPES_SUCCESS,
      payload: data.form_types
    })
  } catch (err) {
    console.error(err)
    createError(err)
  }
}

export const getBlackoutDates = type => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.get('/schedules/blackout_dates/' + type)

    dispatch({
      type: T.GET_APPOINTMENT_BLACKOUTS_SUCCESS,
      payload: data.blackout_dates
    })
  } catch (err) {
    console.error(err)
    createError(err)
  }
}

const fetchAppointments = async (officer, form_type = '', location = '') => {
  try {
    const params = {}

    if (form_type) params['scope[form_type]'] = form_type
    if (location) params['scope[location]'] = location

    const { data } = await $app.axios.get('/schedules' + (officer ? '' : '/get_by_user'), { params })
    return data || []
  } catch (err) {
    return err
  }
}

export const getAppointments = (officer = false, form_type = '', location = '') => async dispatch => {
  dispatch(getRequest)

  try {
   const response = await fetchAppointments(officer, form_type, location)

    dispatch({
      type: T.GET_APPOINTMENT_SUCCESS,
      payload: { response }
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const createAppointment = (form, cb, skipMail = false) => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.post('/schedules', form)
    const slot = moment(form.date).format('Do MMMM, YYYY @ HH:mm A')
    const service = form.schedulable_type.spaceBeforeCap()

    !skipMail && await sendMail({
      email: $app.current_user.email,
      subject: `MyGateway Portal Update - ${service} Appointment`,
      message: `Thank you for using our scheduling tool. Your appointment for ${service} has been scheduled for ${slot}`,
    })

    dispatch({
      type: T.CREATE_APPOINTMENT_SUCCESS,
      payload: { response: data }
    })

    cb.call && cb(data)
  } catch (err) {
    toastr.error('Error', err && err.response.data.error[0])
    dispatch({ type: T.CREATE_APPOINTMENT_FAILURE })
  }
}

export const updateAppointment = (appointment, status, skipMail = false) => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.post('/schedules/update_status', {
      reference_code: appointment.reference_code,
      status,
    })

    const slot = moment(appointment.slot).format('Do MMMM, YYYY @ HH:mm A')
    const service = appointment.schedulable_type.replace(/([A-Z])/g, ' $1')

    !skipMail && await sendMail({
      email: appointment.user_email,
      subject: `MyGateway Portal Update - ${service} Appointment`,
      message: `Your ${service} appointment request for ${slot} has been ${status.toLowerCase()}`,
    })

    toastr.success('Success', 'Appointment Status Updated')
  
    dispatch({
      type: T.UPDATE_APPOINTMENT_SUCCESS,
      payload: { response: data }
    })

    const response = await fetchAppointments(true)

    dispatch({
      type: T.GET_APPOINTMENT_SUCCESS,
      payload: { response }
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const removeAppointment = ({ id }, cb) => async dispatch => {
  dispatch(getRequest)

  try {
    const { data } = await $app.axios.post(`/schedules/${id}/soft_remove`)
    
    toastr.success("Success", "Successfully removed")
    cb.call && cb(data)

    // dispatch({
    //   type: T.REMOVE_APPOINTMENT_SUCCESS,
    //   payload: { response }
    // })
  } catch (err) {
    toastr.error('Error', err && err.response.data.error[0])
  }
}
