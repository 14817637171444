import { listKeys } from '@helpers/objects'

export const getPosition = (rejectOnFail = true) =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation) resolve(null)

    navigator.geolocation.getCurrentPosition(
      result => resolve(result.coords),
      fail => (rejectOnFail ? reject(fail) : resolve(null)),
      { enableHighAccuracy: true }
    )
  })

export const getDirections = (target, gmaps, from, to) =>
  new Promise((resolve, reject) => {
    const {
      DirectionsService,
      DirectionsStatus,
      DirectionsRenderer,
      UnitSystem,
      LatLng,
    } = gmaps

    const xy = coords =>
      new LatLng(...listKeys(coords, 'latitude', 'longitude').map(Number))

    try {
      const service = new DirectionsService()

      service.route(
        {
          origin: xy(from),
          destination: xy(to),
          unitSystem: UnitSystem.IMPERIAL,
          travelMode: 'DRIVING',
        },
        (res, status) => {
          if (status == DirectionsStatus.OK) {
            if (target) {
              const renderer = new DirectionsRenderer({
                map: target.map,
              })

              renderer.setDirections(res)
            }

            resolve(Math.floor(res.routes[0].legs[0].duration.value / 60))
          } else {
            reject(status)
          }
        }
      )
    } catch (err) {
      console.error(err)
      reject('Unable to compute ETA')
    }
  })
