/* These are import statements in JavaScript that import various modules and components needed for the
TradeUnionRegistration class to function properly. */
import React, { Fragment } from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import Loader from '@shared/loader'
import Swal from 'sweetalert2'
// import Form from '@modules/form-wizard/form'
// import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

const TermsOfAgreement = ({ terms }) => {
  const activatePopup = () => {
    Swal.fire(
      'Terms of Agreement',
      `${terms}`,
      'info'
    )
  }

  return (
    <button style={{backgroundColor: 'transparent', border: 'none', color: 'blue'}} onClick={() => activatePopup()}><u>Terms & Conditions</u></button>
  )
}

/* This code exports a React component called `TradeUnionRegistration` as the default export. The
component extends the `React.Component` class and defines various properties and methods that are
used to render a form wizard for registering a trade union. */
export default class DroneRegistration extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  // async componentDidMount() {
  //   Object.assign(this, extractKeys($app, 'current_user:user'))
  // }

  custom_acceptance = true
  resource = '/drone_registrations'
  form_key = 'drone_registration'
  isTransfer = /transfer/.test(this.props.location.pathname)

  // postpaid = true
  // pay_on_approval = true
  skip_location = true

  // hide_buttons = {
  //   status: false,
  //   deny: false,
  //   fee: false,
  // }

  state = {
    loading: false,
  }

  defaults = {
    // renewal: this.isRenewal,
    transfer: this.isTransfer
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        // renewal: this.isRenewal,
        transfer: this.isTransfer,
      },
    },
  }


 /* `defaults` is an object that contains default values for the fields in the form. These values will
 be used to pre-populate the form when it is first loaded. The default values include the type of
 union, whether a new name will be used, the name of the employer, the name of the union, the street
 address of the union, and information about the union officers, including their titles, names,
 email addresses, NIB numbers, addresses, and occupations. */
  // defaults = {
  //   "union_type": "Union of Employees",
  //   "new_name": true,
  //   "employer_name": "Woodworking",
  //   "union_name": "Totally New Union #242",
  //   "union_street_address": "TEST STREET",
  //   "union_officers": [
  //     {
  //         "title": "President",
  //         "email": "test@email.com",
  //         "first_name": "John",
  //         "middle_name": "J",
  //         "last_name": "Doe",
  //         "nib": "12345678",
  //         "address": "TEST ADDRESS",
  //         "occupation": "Business Management & Administration"
  //     },
  //     {
  //         "title": "Treasurer",
  //         "email": "test@email.com",
  //         "first_name": "Jane",
  //         "middle_name": "",
  //         "last_name": "Doe",
  //         "nib": "754732624",
  //         "address": "Test Address",
  //         "occupation": "Arts, Audio/Video Technology & Communications"
  //     },
  //     {
  //         "title": "General Secretariat",
  //         "email": "test@email.com",
  //         "first_name": "Jack",
  //         "middle_name": "T",
  //         "last_name": "Doe",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     },
  //     {
  //         "title": "Trustee",
  //         "email": "test@email.com",
  //         "first_name": "Johnathon",
  //         "middle_name": "D",
  //         "last_name": "Munnings",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     }
  //   ],
  // }

  validate = form => {

  }

  canned = {
    'TEST CANNED': `
      This is a test canne message; it can be whatever you like.
    `
  }

  foremost = null

  // closeModal = () => this.setState({ record: null })

  drone_terms = `
    SUBPART B: OPERATIONS: RECREATIONAL & HOBBY USE 27.030 APPLICABILITY (a) The requirements of this Subpart are applicable to the use of all 
    unmanned and/or remotely piloted aircraft weighing less than 15 kg when used for recreational or hobby activities. (b) Unmanned and/or 
    remotely piloted aircraft weighing between 15 kg to 25 kg may be operated under this Subpart for recreational and hobby activities 
    when specifically approved by the Authority for such operations. 27.035 NO REMUNERATION, COMPENSATION OR AERIAL WORK (a) The operator 
    or pilot of an aircraft to which this Subpart applies shall not— (1) Operate the aircraft except for recreational or hobby purposes. 
    (2) Request or receive remuneration or any form of compensation from any person or organisation for the operation of the aircraft. 
    (3) Use the aircraft for any aerial work purposes. Note: If intending any use of the aircraft for remuneration, compensation or aerial work, 
    refer to BCAR Subpart L, Schedule 11, for specific requirements which provide for such authorisation. 27.040 CARELESS OR RECKLESS OPERATIONS 
    (a) The operator or pilot of an aircraft to which this Subpart applies shall— (1) Not operate the aircraft in a careless or reckless manner so 
    as to endanger other persons or their property; (2) Take all practicable steps to minimize hazards to persons, property and other aircraft that 
    might be caused by these operations. 27.045 PRIOR CONSENT TO OVERFLY (a) The operator or pilot of an aircraft to which this Subpart applies shall— 
    (1) Stay clear of airspace above persons who have not given consent to the flight being conducted over them; 
    (2) Avoid operating above property without prior consent from— (i) Any persons occupying that property; or (ii) The property owner. 
    (b) The operator or pilot of an aircraft to which this Subpart applies shall not operate over a crowd or congested area of persons even 
    when consent has been obtained from the property owner. 27.050 PROXIMITY TO PERSONS (a) The operator or pilot of an aircraft to which this 
    Subpart applies shall maintain, from any person, at least— (1) 50 feet laterally; and (2) 100 feet vertically. (b) Paragraph (a) does not 
    apply to persons assisting in the launch or recovery of the aircraft. 27.055 VISUAL LINE OF SIGHT OPERATIONS (a) The operator or pilot of 
    an aircraft to which this Subpart applies shall, at all times, maintain an unobstructed visual line of sight view of the aircraft. 
    (b) For the purposes of this Section, a visual line of sight means a straight line along which an observer has a clear view and which may 
    be achieved with the use of— Page 27-5 of 16 (1) Normal corrective lenses (contact lenses, corrective glasses, sunglasses); or 
    (2) A first person view system comprising a trained and competent observer who maintains both direct eye contact with the aircraft and 
    communication with the person who is operating the aircraft. (c) The operator or pilot of an aircraft to which this Subpart applies shall 
    not operate the aircraft in— (1) Any area in which the person’s view of the whole operational area may be obstructed; or 
    (2) Meteorological conditions that may obstruct the person’s ability to view the aircraft. 27.060 VISIBILITY & PROXIMITY TO CLOUDS (a) 
    The operator or pilot of an aircraft to which this Subpart applies shall not operate that aircraft when— (1) The visibility is less than 
    1 statute mile; and (2) The base of the lowest clouds is less than 500 feet from the surface. (b) No person may operate an aircraft to which 
    this Subpart applies during conditions of fog. Note: Fog indicates that the base of the clouds is on the surface. 27.065 MAXIMUM ALTITUDE 
    (a) The operator or pilot of an aircraft to which this Subpart applies shall not operate that aircraft higher than 400 feet from the surface. 
    (b) Operations of unmanned or remotely pilot aircraft above 400 feet from the surface may only be conducted— (1) In airspace blocked or designated 
    as a Danger Area for such purposes by the Authority; and (2) Published by NOTAM or in The Bahamas Aeronautical Information Publication (AIP). 
    27.070 NIGHT OPERATIONS (a) The operator or pilot of an aircraft to which this Subpart applies shall not operate that aircraft at night. 
    (b) Paragraph (a) does not apply to operations that are operated— (1) Indoors; or (2) A continuous shielded operation. Note: The aircraft would 
    be considered to be “shielded” (from manned aircraft) if it is conducted below the maximum height of the surrounding structures or vegetation. 
    27.075 RIGHT OF WAY (a) The operator or pilot of an aircraft to which this Subpart applies shall— (1) Maintain a continuous awareness for the 
    presence of manned aircraft; and (2) If possible, cease operations until the manned aircraft is no longer visible. (b) The operator or pilot of 
    an aircraft to which this Subpart applies shall give way to, and remain well clear of, all manned aircraft on the ground and in flight. 
    (c) The operator or pilot of a powered aircraft to which this Subpart applies shall give way and remain well clear of any other unmanned aircraft. 27.080 
    PROXIMITY TO AERODROME (a) The operator or pilot of an aircraft to which this Subpart applies shall not operate the aircraft within 8 km (5 sm) of an aerodrome. 
    (b) Paragraph (a) does not apply in proximity to a controlled aerodrome if the operator or pilot has received clearance or authorisation from air traffic control 
    for the specific flight or series of flight. (c) Paragraph (a) does not apply in proximity to an uncontrolled aerodrome if the operator of the aircraft to which 
    this Subpart applies has— Page 27-6 of 16 (1) Received a written authorisation from the aerodrome operator specifying the terms of use; and (2) Ensured that a 
    NOTAM detailing the operational activities, location relative to the runways and maximum altitudes has been issued by the aerodrome operator. 
    27.085 DROPPING OF ARTICLES (a) The operator or pilot of an aircraft to which this Subpart applies shall not drop an article from the aircraft unless 
    specifically authorised by the Authority. 27.090 CONTROLLED AND SPECIAL USE AIRSPACE (a) The operator or pilot of an aircraft to which this Subpart 
    applies shall not operate that aircraft into the following airspace— (1) Controlled airspace (Classes A, B, C, D, E, and F); 
    (2) Danger areas (except for an area specifically designated for unmanned aircraft usage); (3) Prohibited areas; (4) Restricted areas; and 
    (5) Wildlife protection areas. Note 1: Compliance with the restrictions to maximum altitude and proximity to aerodromes will ensure that the operator 
    is in compliance with Section 27.090(a)(1). Note 2: The operator should obtain an aeronautical chart for the intended location of the flight and review 
    it for compliance with Section 27.090(a)(2)(3)(4)(5). 27.095 KNOWLEDGE OF AIRSPACE DESIGNATIONS (a) The operator or pilot of an aircraft to which this 
    Subpart applies shall not operate the aircraft unless— (1) Before each flight the operator or pilot is aware of— (i) The surrounding airspace designations; 
    and (ii) Any applicable airspace restrictions in place in the area of intended operation; or (2) The operation is conducted under the direct supervision 
    of the operator or pilot who is aware of— (i) The airspace designations; and (ii) Any applicable airspace restrictions in place in the area of intended operation.”
  `


  fields = form => [
    // ...BUSINESS_FIELDS(form),
    // {
    //   name: 'renewal',
    //   label: 'Renewal?',
    //   type: 'select:bool',
    //   hide: true,
    //   view: true,
    //   save: true,
    // },
    {
      name: 'transfer',
      label: 'Transfer?',
      type: 'select:bool',
      hide: true,
      view: true,
      save: true,
    },
    '::Drone Owner Details',
    {
      name: 'i_am_owner',
      label: 'I am the owner',
      type: 'select:bool'
    },
    {
      name:'owner_f_name',
      label:'First Name',
      hide: form.i_am_owner,
    },
    {
      name:'owner_m_name',
      label:'Middle Name',
      hide: form.i_am_owner,
      required: false,
    },
    {
      name:'owner_l_name',
      label:'Last Name',
      hide: form.i_am_owner,
    },
    {
      name:'home_mailing_address',
      label:'Home Mailing Address',
      // hide: form.i_am_owner,
    },

    '::Drone Details',
    {
      name:'processing_fee',
      label:'Select Drone Processing Fee Option',
      options: [
        { label: '$30 - 5 Business Days (Recreational)', value: 'Five(5) Business Days' },
        { label: '$50 - 2 Business Days (Recreational)', value: 'Two(2) Business Days' },
        { label: 'Commercial-Calculated Fee', value: 'Commercial' },
      ],
      // type: 'integer',
      // hide: form.frist_permit,
    },
    {
      name:'drone_make',
      // label:'',
      // type:'date',
      // hide: form.frist_permit,
    },
    {
      name:'drone_model',
      // label:'',
      // type:'date',
      // hide: form.frist_permit,
    },
    {
      name:'drone_weight',
      // label:'',
      // type:'date',
      // hide: form.frist_permit,
    },
    {
      name:'drone_serial',
      label: 'Drone Serial #',
      // type:'date',
      // hide: form.frist_permit,
    },
    {
      name:'geofencing',
      type: 'select:bool',
      label:'Geofencing Capabilities?',
      hint: 'Select Yes ONLY if your drone has geofencing capabilities, and/or is set to enabled.',
    },
    {
      name:'national_id_upload',
      label: 'National Identification',
      key: 'image.0',
      // label:'',
      type: 'file:all',
      hint: 'Upload either your passport or driver\'s license in color',
    },
    {
      // use_as_acceptance: true,
      name: 'declare',
      type: 'checkbox',
      view: false,
      label: `
        Providing false information to the Authority for the purpose of receiving approval or permission is 
        an offence (as section 50 (1)(c) of the Civil Aviation Act 2021 indicates) which is punishable by a 
        fine of up to $5,000 or to imprisonment for 6 months or both (s. 50 (2) Civil Aviation Act 2021).
      `,
    },
    {
      use_as_acceptance: true,
      heading: (<div className='form-check form-show-validation'>
        <label className="form-check-label flex flex-wrap items-center">
        <input type="checkbox" className="form-check-input" required="" value="false" />
        <span className="form-check-sign">
          By clicking submit you agree to the 
          <TermsOfAgreement terms={this.drone_terms}/>
        <span className="required-label">*</span></span>
        </label>
        </div>),
      type: 'checkbox',
      view: false,
    },
  ]

  render = () => {
    const { loading } = this.state
    // console.log($app.current_user.id)

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
      </Fragment>
    )
  }
}
