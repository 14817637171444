import Swal from 'sweetalert2'

import SERVICE_PROPS from '@helpers/extract-service-props'

export const hull_types = [
  'Wood or Wooden Strip Hull',
  'Steel',
  'Fiberglass',
  'Aluminum',
  'Polyethylene Plastic Hulls',
  'PolyLink3/ Triple Tough Hulls',
  'Thermoform or ABS Hulls',
  'Kevlar Hulls',
]

export const common = component => {
  const custom_actions = [
    ...(component.custom_actions ?? []),
    // {
    //   text: 'Submit to Fisheries Officer',
    //   icon: 'arrow-right',
    //   roles: ['moa_marine_peu'],
    //   test: r => r.application_decision == 'pending',
    //   fn: forwardToOfficer.bind(component),
    // },
    // {
    //   text: 'Submit to Fisheries Officer',
    //   icon: 'arrow-right',
      // roles: ['moa_marine_fso'],
      //   test: r => r.permit_officer_id,
      //   fn: forwardToDirector.bind(component),
      // },
    {
      text: 'Submit to Director',
      icon: 'arrow-right',
      roles: ['officer'],
      test: r => r.application_decision == 'pending',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to submit?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and the accompanying attachments, and are ready to submit it to a Director/Approved Signatories. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            const { value: license_numb } = await Swal.fire({
              title: "Please enter the Client's Permit Number",
              input: 'text',
              inputLabel: 'Permit Number',
              inputPlaceholder: 'Permit Number',
            })
            if (license_numb) {
              await submitToDirector.call(component, record, license_numb)
            }
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check',
      roles: ['director'],
      test: r => r.application_decision == 'processing',
      // test: r => r.fishery_officer_id,
      fn: approveApplication.bind(component),
    },
  ]

  const hide_buttons = {
    status: true,
  }

  return SERVICE_PROPS({
    ...component,
    custom_actions,
    hide_buttons,
    filter_records: getRecordFilter(),
  })
}

function getRecordFilter() {
  if ($app.hasRole('moa_')) {
    return r => r.application_decision == 'pending'
  }
}

// async function forwardToOfficer(record) {}

// async function forwardToDirector(record) {}

async function submitToDirector (record, license_numb) {
  const { resource, form_key, props } = this
  const { history, location } = props
  const { axios } = $app

  try {
    await axios.put(resource + '/update_application', {
      [form_key]: {
        form_num: record.form_num,
        application_decision: 'processing',
        license_numb,
      },
    })

    if (location?.state?.view) {
      const view = { ...location.state.view }
      view.application_decision = 'processing'
      view.license_numb = license_numb

      history.replace(location.pathname, {
        ...location.state,
        view,
      })
    }

    Swal.fire(
      'Submitted!',
      'The application is now ready for final approval by the Director/Approved Signatories.',
      'success'
    )
  } catch (error) {
    console.error(error)
    Swal.fire(
      'Oops..',
      'Something went wrong with trying to submit this application for final approval',
      'error'
    )
    return
  }
}

async function approveApplication(record) {
  const { isDismissed } = await Swal.fire({
    title: 'Approve Application',
    text: `
      Are you sure you want to approve this application?
    `,
    showCancelButton: true,
    confirmButtonText: 'Approve',
  })

  if (isDismissed) return
  const { resource, form_key } = this
  const { location, history } = this.props

  try {
    const update = {
      form_num: record.form_num,
      application_decision: 'approved',
      application_status: true,
      license_numb: record.license_numb,
    }

    await $app.axios.put(resource + '/update_application', {
      [form_key]: update,
    })

    if (location.state?.view) {
      history.replace(location.pathname, {
        ...location.state,
        view: {
          ...location.state.view,
          ...update,
        },
      })
    }

    Swal.fire(
      'Success',
      `Application successfully approved`,
      'success'
    )
  } catch (err) {
    console.error(err)

    Swal.fire(
      'Error',
      `An error coccurred while setting the applicant's licence number`,
      'error'
    )
  }
}
