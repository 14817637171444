import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BGCSES, BJCS } from '@constants/national-exams'
import ISLANDS from '@constants/islands'
import generateRange from '@helpers/range'

const gradeLevels = generateRange(7, 12).map(n => n.toFixed())

export default class __SERVICE_NAME__ extends React.Component {
  resource = '/national_youth_parliaments'
  form_key = 'national_youth_parliament'
  defaults = {}

  // Delete lines as needed
  free           = true
  skip_location  = true
  hide_buttons   = false
  custom_actions = []

  validate = null
  foremost = null

  fields = form => {
    const list = [
      {
        name: 'delivered_speech',
        type: 'select:bool',
        label: 'Have you ever delivered a speech?',
      },
      {
        name: 'toastmasters_training',
        type: 'select:bool',
        label: `
          Are you a Toastmasters International member or have
          ever had training by Toastmasters?
        `,
      },
      `::Youth's Details`,
      {
        name: 'i_am_youth',
        type: 'checkbox',
        required: false,
      },
    ]

    if (form.i_am_youth) {
      list.push(
        {
          name: 'youth_first_name',
          label: 'First Name',
        },
        {
          name: 'youth_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'youth_last_name',
          label: 'Last Name',
        },
        {
          name: 'youth_nib',
          label: 'NIB',
          is: 'integer'
        },
        {
          name: 'youth_date_of_birth',
          type: 'date',
          label: 'Date of Birth',
          showYearDropdown: true,
        },
        {
          name: 'youth_sex',
          label: 'Sex',
          options: ['Male', 'Female']
        },
        {
          name: 'youth_island',
          label: 'Island',
          options: ISLANDS,
        },
        {
          name: 'youth_settlement',
          label: 'Settlement',
        },
        {
          name: 'youth_address',
          label: 'Street Address',
        },
        {
          name: 'youth_po_box',
          label: 'P. O. Box',
          required: false,
        },
        {
          name: 'relationship_to_youth',
          label: 'Relationship To Applicant',
        },
      )
    }

    list.push(
      '::SPACER',
      {
        name: 'youth_activities_list',
        label: 'Youth Activities/Organizations Involved In',
        columns: ['activity'],
        format: v => v.activity,
      },
      {
        name: 'youth_awards_list',
        label: 'Special Awards Received',
        columns: ['award'],
        format: v => v.award,
      },
      {
        name: 'similar_event',
        type: 'select:bool',
        label: 'Have you participated in any similar event?',
      },
      {
        name: 'event_name',
        hide: !form.similar_event,
        label: 'Name of Event',
      },
      {
        name: 'event_where',
        hide: !form.similar_event,
        label: 'Location of Event',
      },
      {
        name: 'event_date',
        hide: !form.similar_event,
        type: 'date',
        label: 'Date of Event',
      },
      {
        name: 'additional_comments',
        label: '',
        required: false,
      },
  
      '::Academic Information',
      {
        name: 'in_school',
        type: 'select:bool',
        label: 'Are you currently in school?',
      },
      {
        name: 'present_grade',
        label: 'Current grade Level',
        options: gradeLevels
      },
      {
        name: 'bjcs_passed_list',
        label: `BJC's Passed`,
        multi: true,
        options: BJCS,
        hide: form.grade_level_completed < 7
      },
      {
        name: 'bgcses_passed_list',
        label: `BGCSE's Passed`,
        multi: true,
        options: BGCSES,
        hide: form.grade_level_completed < 10
      },
      {
        name: 'academic_certificates',
        label: 'Other Awards/Accomplishments',
        columns: ['certificate'],
        format: v => v.certificate
      },

      '::SPACER',
      {
        name: 'why_me',
        type: 'textarea',
        label: `
          Why do you think that you should be selected as
          a Nominee for Youth Parliament.
        `,
      },
  
      '::Uploads',
      {
        name: 'bjcs_upload',
        type: 'file:pdf',
        label: 'BJC Certificate',
      },
      {
        name: 'bgcses_upload',
        type: 'file:pdf',
        label: 'BGCSE Certificate',
      }
    )

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
