import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import SamplePassportImage from '@images/sample-passport-data-page.png'
import BadPassportImage from '@images/sample-passport-data-page-bad.jpg'

import {
  markAsVetted,
  recommendToPS,
  recommendToMinister,
  appealApplication,
  SECURITY_COLUMNS,
} from './_security-utils'

export default class SecurityLicenceRegistration extends React.Component {
  resource = '/inquiry_and_security_licenses'
  form_key = 'inquiry_and_security_license'

  fee = 10
  skip_location = true
  custom_acceptance = true
  columns = SECURITY_COLUMNS

  isSecurity = /security-/.test(this.props.location.pathname)

  defaults = {
    client_type: 'individual',
    renewal: false,
    temporary: false,
    agent_type: this.isSecurity ? 'security guard' : 'inquiry agent',
  }

  hide_buttons = {
    status: true,
    deny: !$app.hasRole('minister'),
  }

  custom_actions = [
    markAsVetted.call(this),
    recommendToPS.call(this),
    recommendToMinister.call(this),
    appealApplication.call(this),
  ]

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        temporary: false,
        renewal: false,
        appeal: false,
        agent_type: this.defaults.agent_type
      },
    },
  }

  updateView = update => {
    const { history, location } = this.props
    const inview = location.state?.view

    inview &&
      history.replace(location.pathname, {
        ...location.state,
        view: { ...inview, ...update },
      })
  }

  fields = form => [
    {
      name: 'client_type',
      save: true,
      hide: true,
    },
    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    {
      name: 'temporary',
      save: true,
      hide: true,
    },
    {
      name: 'agent_type',
      save: true,
      hide: true,
    },
    {
      name: 'license_numb',
      label: 'Licence #',
      hide: true,
      view: true,
    },

    '::Employment Details',
    {
      name: 'employers',
      label: 'Company Name(s)',
      required: false,
      hint: 'Please separate each company with a newline',
      view: {
        value: v => {
          if (!v?.length) return null
          return v.map(r => JSON.parse(r))
        }
      },
      columns: [
        {
          name: 'company_name',
          label: 'Name',
          required: false,
        },
        {
          name: 'phone_number',
          label: 'Phone #',
          required: false,
        },
      ]
    },
    {
      name: 'full_time',
      type: 'select:bool',
      label: 'Is this your full time occupation?',
      hide: form.agent_type == 'inquiry agent',
    },
    {
      name: 'employer_name',
      label: `Employer's Name`,
      hide: form.agent_type == 'inquiry agent' || form.full_time !== false,
    },
    {
      name: 'employer_address',
      label: `Employer's Address`,
      hide: form.agent_type == 'inquiry agent' || form.full_time !== false,
    },

    '::Personal Contact Information',
    {
      name: 'street_address',
      label: 'Street Address',
    },
    {
      name: 'po_box',
      label: 'P.O. Box',
      required: false,
    },
    {
      name: 'phone_number',
      type: 'phone',
      label: 'Contact Number',
      use_profile: 'additional_phone_number',
    },

    '::Uploads',
    {
      name: 'photo_upload',
      label: 'Passport sized Photo',
      type: 'file:image',
      key: 'image.5',
    },
    {
      name: 'identification_upload',
      label: 'Government Issued Photo ID',
      type: 'file:image',
      // hint: 'Passport, Driver\'s Licence, or Voter\'s Card.',
      key: 'image.3',
      hint: `
      The <strong>information page</strong> of your passport,
      or the front side of your Driver's Licence or Voter's Card.<br/>
      <strong>Selfies will not be accepted.</strong>
      <div class='flex flex-row items-center'>
        <div class='flex flex-row items-center mr-6'>
          <img class='w-32 md:w-40 lg:w-48 mr-2' src='${SamplePassportImage}'/>
          <strong class='text-green-500 font-extrabold text-xl'>&checkmark;</strong>
        </div>
        <div class='flex flex-row items-center mr-6'>
          <img class='w-32 mx-4' src='${BadPassportImage}'/>
        </div>
      </div>
      `
    },
    {
      name: 'police_cert_upload',
      label: 'Valid Police Character Certificate',
      type: 'file:image',
      key: 'image.11',
      hint: 'Not more than six (6) months old',
    },

    '::SPACER',
    {
      name: 'final_declartion',
      long: true,
      label: `
        By clicking this box, I confirm that I understand my obligations under
        the respective laws and regulations applicable to the application for 
        security guards and/or inquiry agents licence and agree to observe
        all applicable laws, regulations, policies and guidelines.
      `,
      type: 'checkbox',
    },
    {
      name: 'acceptance',
      type: 'checkbox',
      label: `
        By clicking here you agree that the information provided is accurate
        and lawful without false pretence
      `
    }
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      type: 'file:pdf',
      label: this.isSecurity
        ? 'Security Guard Certificate'
        : 'Inquiry Agent Certificate',
      key: 'image.0',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
