import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class MarriageCertificate extends React.Component {
  resource = '/marriage_certificates'
  form_key = 'marriage_certificate'

  fee = 20

  validate = form => ({
    wedding_date: moment(form.wedding_date).format('YYYYMMDD'),
  })

  fields = () => {
    const spouse = /^male/i.test($app.applicant.gender) ? 'wife' : 'husband'

    return [
      {
        name: 'apostille',
        label: 'Purpose',
        options: ['Apostille', 'Regular'],
      },
      {
        name: 'maiden_name',
        label: 'Maiden Name',
        hide: spouse == 'wife',
        use_profile: 'maiden_name',
        disabled: !!$app.applicant.maiden_name,
      },
      `${spouse}_first_name`,
      `${spouse}_${spouse == 'wife' ? 'maiden' : 'last'}_name`,
      {
        name: 'wedding_date',
        type: 'date',
        showYearDropdown: true,
      },
    ]
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
