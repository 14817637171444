import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'
import Swal from '@sweetalert'

const today = new Date()

export default class CulturalRegistry extends React.Component {
  resource = '/cultural_registries'
  form_key = 'cultural_registry'

  defaults = {
    development_assistance: [
      { item: '' },
      { item: '' },
      { item: '' },
    ]
  }

  free          = true
  skip_location = true
  hide_buttons = {
    status: true,
    deny: !$app.hasAnyRole(['deputy_director', 'director']),
  }
  customTitle = () => {
    if ($app.hasRole('officer')) return 'Recommend To ADOC';
    // if ($app.hasRole('senior_officer')) return 'Recommend To Director';
    if ($app.hasRole('department_officer')) return 'Recommend To ADOC';
    if ($app.hasAnyRole(['deputy_director'])) return 'Recommend To Director';
    if ($app.hasAnyRole(['director'])) return 'Approve Application';
    // if ($app.hasAnyRole(['under_secretary', 'permanent_secretary'])) return 'Recommend To Minister';
    // if ($app.hasRole('minister')) return 'Approve Application';
  }

  custom_actions = [
    {
      text: 'Assign Department Officer',
      icon: 'user-tie',
      roles: [['deputy_director']],
      test: r => ['officer viewed'].includes(r.application_decision),
      fn: async record => {
        const departmentOfficerList = {};
        const { data } = await $app.axios.get('/cultural_grants/show_department_officers')
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          departmentOfficerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Department Officer',
          html: `
            Select which department officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: departmentOfficerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },
    {
      text: this.customTitle(),
      icon: 'user-tie',
      roles: [['officer', 'deputy_director', 'department_officer', 'director']],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: async record => {
        const isDirector = $app.hasAnyRole(['director']);
        const { value, isDismissed } = await Swal.fire({
          icon: 'question',
          title: this.customTitle(),
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': isDirector ? "I approve this applicant" : "I recommend this applicant",
            'denied': isDirector ? "I will deny this applicant" : "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (isDismissed) return

        await this.submitToNewLevel(value, record)
      },
    },
  ]

  submitToNewLevel = async (value, record, officerID = null) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    const newAppDecision = () => {
      if ($app.hasRole('officer')) return 'officer viewed';
      if ($app.hasRole('department_officer')) return 'department officer viewed';
      if ($app.hasRole('deputy_director')) return 'deputy director viewed';
      if ($app.hasRole('director')) return 'director viewed';
      if ($app.hasAnyRole(['under_secretary', 'permanent_secretary'])) return 'secretary viewed';
      if ($app.hasRole('minister')) return value;
    }

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          department_officer_id: officerID,
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = newAppDecision()

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application successfully submitted.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application',
        'error'
      )
      return
    }
  }

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),

    {
      name: 'number_of_members',
      label: 'Number of Members in Group/Organizations',
      is: 'integer',
    },

    {
      name: 'artist_name',
      // label: ''
      hint: 'Professional artist/stage name'
    },
    {
      name: 'website',
      required: false
    },
    {
      name: 'social_media',
      columns: [
        {
          name: 'platform',
          datalist: [
            'Facebook',
            'Twitter',
            'Instagram',
            'TikTok',
            'YouTube',
            'LinkedIn',
            'Pinterest'
          ]
        },
        'tag'
      ],
      // format: list => {
      //   // console.log(list)
      //   return JSON.stringify({
      //     [list.site.toLowerCase()]: true,
      //     tag: list.tag
      //   })
      // },

      // view: {
      //   element: value => {
      //     // const links = value.map()

      //     return (
      //       <Fragment>
      //         <h5 className='sub'>Social Media Links</h5>
      //       </Fragment>
      //     )
      //   }
      // }
    },

    '::Work History',
    {
      name: 'years_of_engagement',
      options: [
        { value: '0 - 5 Years', label: '0 - 5 Years' },
        { value: '6 - 10 Years', label: '6 - 10 Years' },
        { value: '11 - 15 Years', label: '11 - 15 Years' },
        { value: '16 - 20 Years', label: '16 - 20 Years' },
        { value: '21+ Years', label: '21+ Years' }
      ]
    },
    {
      name: 'affiliates',
      label: 'Local & International Affiliates',
      columns: ['affiliate'],
      format: v => v.affiliate
    },
    {
      name: 'work_level',
      options: [
        'Amateur',
        'Semi Professional',
        'Professional'
      ]
    },
    {
      name: 'areas_of_focus',
      type: 'select',
      multi: true,
      options: [
        'art',
        'craft',
        'jewelry',
        'music',
        'drama',
        {label: 'Film/TV', value: 'film'},
        'dance',
        'culinary',
        'fashion',
        'literature',
        'junkanoo',
        {label: 'Festival/Pagentry', value: 'festival'},
        {label: 'Museum/Attraction', value: 'museum'},
        // 'Other'
      ]
    },
    // {
    //   name: 'area_of_focus_other',
    //   hide: !form.areas_of_focus?.includes?.('Other'),
    //   label: 'Please Specify',
    // },
    {
      name: 'target_demographic',
      type: 'textarea'
    },

    '::Education / Training',
    {
      name: 'education_history',
      columns: [
        'school_name',
        {
          name: 'school_type',
        },
        'area_of_focus',
        {
          name: 'level_of_study',
          options: [
            "Primary",
            'Secondary',
            "Undergraduate",
            "Post-Graduate",
          ],
        },
        {
          name: 'start_date',
          type: 'date',
          // max: today,
        },
        {
          name: 'completion_date',
          type: 'date',
          required: false,
        }
      ]
    },

    '::Skills and Qualifications',
    {
      name: 'special_skills',
      label: 'Special Skills, Abilities, & Honours',
      columns: ['item'],
      format: v => v.item,
    },

    '::Highlights',
    {
      name: 'highlights',
      label: 'Main Activities over the last 5 years',
      columns: ['activity'],
      format: v => v.activity,
    },
    {
      name: 'development_assistance',
      label: 'Three (3) things that can assist your development',
      columns: ['item'],
      format: v => v.item,
    },

    '::Financial Indicators',
    {
      name: 'high_season_dates',
      columns: [{
        name: 'date',
        type: 'date',
        minDate: today
      }],
      format: v => v.date,
    },
    {
      name: 'low_season_dates',
      columns: [{
        name: 'date',
        type: 'date',
        minDate: today
      }],
      format: v => v.date,
    },
    {
      name: 'annual_income_range',
      is: 'currency'
    },
    {
      name: 'support_funding',
      type: 'textarea',
      label: 'Additional Support Funding',
      required: false,
    },

    '::Uploads',
    {
      name: 'resume_upload',
      label: 'Resume',
      key: 'image.0',
      type: 'file:all',
    },
    {
      name: 'photo_upload',
      label: 'Photo',
      key: 'image.1',
      type: 'file:image',
      hint: 'Passport quality photo',
    },
    {
      name: 'photo_2_upload',
      label: 'Additional Photo',
      key: 'image.2',
      type: 'file:image',
      hint: 'Passport quality photo',
      required: false,
    },
    {
      name: 'bio_upload',
      label: 'Short Bio',
      key: 'image.3',
      type: 'file:all',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
