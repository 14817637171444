import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import islands from "@constants/islands";
import countries from "@constants/countries";

export default class MarineMammalExport extends React.Component {
  id = "MammalProductLicense";
  service = "mammal product license";
  title = "Licence to Export a Marine Mammal Product";
  agency = "moa";
  resource = "/mammal_product_licenses";
  form_key = "mammal_product_license";
  free = false;
  postpaid = false;
  defaults = {
    license_type: "export",
  };
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "license_type",
      label: "License Type",
      hide: "true",
    },
    {
      name: "product_type",
      label:
        "Type of marine mammal/marine mammal product proposed to be exported",
    },
    {
      name: "facility_name",
      label:
        "Name of facility where the marine mammal/marine mammal product will be exported",
    },
    {
      name: "facility_location",
      label:
        "Location of facility where the marine mammal/marine mammal product will be exported",
      options: islands,
    },
    {
      name: "license_purpose",
      label: "Purpose of export of the marine mammal/marine mammal product",
      type: "textarea",
    },
    {
      name: "transportation_mode",
      label:
        "Transportation mode and method by which the marine mammal/marine mammal product will be exported",
      options: ["Air", "Land", "Sea"],
    },
    {
      name: "entry_port",
      label:
        "Port of entry from/to which the marine mammal/marine mammal product is to be exported",
      options: countries,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
