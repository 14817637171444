import axios from 'axios'

import ENV from '@constants/env'
import toastr from '@modules/toastr'
import { history } from '@/App'
import { FETCH_GMAP_KEY } from '@action-types'

const initial = {
  key: '',
}

const reducer = (state = initial, { type, payload }) => {
  switch (type) {
    case FETCH_GMAP_KEY:
      return {
        ...state,
        key: payload,
      }
    default:
      return state
  }
}

export const fetchKey = () => async dispatch => {
  const user = JSON.parse(sessionStorage.getItem('user') || '{}')
  try {
    const { data } = await axios.get(
      ENV.WEB_SERVICE_URL + '/api/v1/GMAPS/key',
      {
        headers: {
          Authorization: user.token,
        },
      }
    )

    if (data) {
      return dispatch({
        type: FETCH_GMAP_KEY,
        payload: data,
      })
    } else {
      // toastr.error('Error', 'Unable to fetch API Key')
      history.goBack()
    }
  } catch (err) {
    console.error(err)
    // toastr.error(err)
  }

  toastr.error('Error', 'Unable to load Google Maps data')
}

export default reducer
