import React from 'react'
import countries from '@constants/countries'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'
import passportPhotoExample from '@helpers/passport-photo-example'
import { commonBanks } from '@constants/banking-institutions'
import convertToBase64 from '@helpers/file-to-base64'
import moment from 'moment'
import nationalities from '@constants/nationalities'

const today = moment()
const yearsAgo = (minAge) => today.clone().subtract(minAge, 'years').toDate()

export default class SummerEmploymentBase extends React.Component {
  resource = '/summer_employments'
  form_key = 'summer_employment'

  isYouth = /youth/.test(this.props.location.pathname)
  isCulture = /culture/.test(this.props.location.pathname)
  isSports = /sports/.test(this.props.location.pathname)

  // Shutdown applications
  componentDidMount() {
    (this.isYouth || this.isSports) && Swal.fire(
      'Applications Closed',
      `
        Sorry, but this application form has reached its deadline.
      `,
      'info'
    )
  }


  before_apply = () => {
    if (this.isYouth || this.isSports)
      return `
        Sorry, but this application form has reached its deadline.
      `
  }

  decideDivision = () => {
    if (this.isSports) {
      return 'sports'
    } else if (this.isCulture) {
      return 'culture'
    } else {
      return 'youth'
    }
  }

  defaults = {
    division_selected: this.decideDivision(),
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        division_selected: this.defaults.division_selected
      },
    },
  }

  // Delete lines as needed
  // fee           = 99
  free          = true
  // postpaid      = true
  skip_location = true
  hide_buttons  = {
    status: true,
    deny: true,
  }

  custom_actions = [
    {
      text: 'Verify Documents',
      icon: 'id-badge',
      roles: ['officer'],
      test: r => r.application_decision != 'verified',
      fn: record =>
        Swal.fire({
          title: 'Are you sure that you would like to verify record documents?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and verified the authenticity of the accompanying attachments. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.verifyRecord(record)
          }
        }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['officer'],
      test: r => r.application_decision == 'verified',
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.approve(record)
          }
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      // test: r => r.payment_status,
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
  ]

  verifyRecord = async (record) => {
    const { resource, form_key, props } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'verified',
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'verified'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      Swal.fire(
        'Submitted!',
        'The application is now ready for final approval.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to submit this application for final approval',
        'error'
      )
      return
    }
  }

  approve = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'approved'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application successfully approved')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to approve application')
      return
    }

    this.setState({ loading: false })
  }

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    this.setState({ loading: false })
  }

  validate = async values => {
    const update = {}

    if(values.references_list[0]) {
      update.references_list = []

      for (let row of values.references_list) {
        const {
          reference_upload,
          ...item
        } = row

        if (reference_upload) {
          item.reference_upload = await convertToBase64(reference_upload)
          update.references_list.push(item)
        }
      }
    }

    return update
  }

  foremost = null

  includesAny = (arr, vals) => { return vals?.some(v => arr?.includes(v)) };

  childProofLabel = (form) => {
    if (form.choose_upload == 'Birth Certificate') {
      return 'Registered birth certificate of minor'
    }
    else if (form.choose_upload == 'Adoption Certificate') {
      return 'Certified Adoption Certificate'
    }
    else if (form.choose_upload == 'Certified Court Order') {
      return 'Certified Court Order for proof of legal Guardianship'
    }
    else if (form.choose_upload == 'Letter from Social Services') {
      return 'Valid letter from Department of Social Services, where the minor is under state care'
    } else {
      return 'Child Identification Documents'
    }
  }

  // programLabel = form => {
  //   if (form.division_selected == 'youth') {
  //     return 'Which programme would you like to be considered for?'
  //   } else if (form.division_selected == 'sports') {
  //     return 'Program Options'
  //   } else {
  //     return ''
  //   }
  // }

  programOptions = form => {
    let options = []
    // console.log('division', this.decideDivision());

    if (form.division_selected == 'youth') {
      // options = ['camp placement', 'apprentice programme', 'corporate placement'] 
      options = ['corporate placement']
    } else if (form.division_selected == 'sports') {
      options = [
        { value: 'summer camp instructor', label: 'Summer Camp Instructor'},
        { value: 'sports internship', label: 'Summer Sports Internship'},
      ]
    } else {
      options = ['camp placement', 'apprentice programme']
    }

    return options
  }

  programInterest = form => {
    let options = []

    if (form.which_program == 'sports internship') {
      options = [
        { 
          value: 'facilities management',
          label: 'Facilities Management',
        },
        { 
          value: 'digital media',
          label: 'Digital Media',
        },
        { 
          value: 'health and wellness',
          label: 'Health and Wellness',
        },
        { 
          value: 'administration',
          label: 'Administration',
        },
      ] 
    } else if (form.which_program == 'summer camp instructor') {
      options = [
        'Basketball',
        'Volleyball',
        'Baseball',
        'Soccer',
        'Softball',
        'Boxing',
        'Archery',
        'Tennis',
        { value: 'Flag Football', label: 'Flag Football'},
        // { value: 'Beep Baseball', label: 'Beep Baseball'},
        { value: 'Swimming', label: 'Swimming(located at south beach swimming pools)'},
      ]
    } else if (form.which_program == 'apprentice programme') {
      options = [
        'Basketball',
        'Volleyball',
        'Baseball',
        'Soccer',
        'Softball',
        'Boxing',
        'Archery',
        'Tennis',
        { value: 'Flag Football', label: 'Flag Football'},
        { value: 'Beep Baseball', label: 'Beep Baseball'},
        { value: 'Swimming', label: 'Swimming(located at south beach swimming pools)'},
      ]
    }

    return options
  }

  columns = props => [
    {
      name: 'Program Type',
      selector: row => row.which_program?.capitalize() || 'N/A',
      sortable: true,
      searchable: r => r.which_program,
    },
    {
      name: 'Interest',
      selector: row => {
        if (row.which_program == 'sports internship') {
          return row.program_interest?.capitalize() || 'N/A'
        } else if (row.which_program == 'summer camp instructor') {
          return row.program_interest?.capitalize() || 'N/A'
        } else if (row.which_program == 'apprentice programme') {
          return row.program_interest?.capitalize() || 'N/A'
        } else if (row.which_program == 'corporate placement') {
          return row.program_interest?.capitalize() || 'N/A'
        } else if (row.which_program == 'camp placement') {
          return row.program_interest?.capitalize() || 'N/A'
        }
      },
      sortable: true,
      searchable: r => r.program_interest,
    },
  ]

  fields = form => [
    {
      name: 'division_selected',
      save: true,
      hide: true,
    },
    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    
    "::Applicant Information",
    {
      name: 'relation_to_applicant',
      label: 'Relation to Applicant',
      options: ['parent', 'guardian', 'relative', 'friend'],
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_first_name',
      label: 'First name',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_middle_name',
      label: 'Middle name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'applicant_last_name',
      label: 'Last name',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_date_of_birth',
      label: 'Date of birth',
      type: 'date',
      showYearDropdown: true,
      minDate: yearsAgo(25),
      maxDate: yearsAgo(15),
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_age',
      label: 'Age',
      type: 'integer',
      hide: form.i_am_applicant,
      onBlur: () => {
        if (form.applicant_date_of_birth) {
          const today = new Date();
          const dob = new Date(form.applicant_date_of_birth)
          const monthCheck = today.getMonth() >= dob.getMonth()
          const dayCheck = today.getDate() >= dob.getDate()
          const minusYear = monthCheck && dayCheck ? 0 : 1
          const result = (today.getFullYear() - dob.getFullYear()) - minusYear
          if (result < 15 || result > 25) {
            Swal.fire(
              `Summer Employment is only intended for applicants between the ages of 15 and 25 years of age`,
              '',
              'error',
            )
            return { applicant_age: '' }
          }
          return { applicant_age: parseInt(result) }
        } else {
          Swal.fire(
            `Must set Date of birth field first`,
            '',
            'error',
          )
          return { applicant_age: '' }
        }
      },
    },
    {
      name: 'applicant_gender',
      label: 'Gender',
      options: ['male','female'],
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_place_of_birth',
      label: 'Place of birth',
      options: countries,
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_nationality',
      label: 'Nationality',
      options: nationalities,
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_national_insurance_number',
      label: 'NIB Number',
      type: 'integer',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_address',
      label: 'Home Address',
    },
    {
      name: 'applicant_subdivision',
      label: 'Subdivision',
    },
    {
      name: 'applicant_home_phone_number',
      label: 'Home phone #',
      type: 'phone',
      hide: form.i_am_applicant,
    },
    {
      name: 'applicant_work_phone_number',
      label: 'Work phone #',
      type: 'phone',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'applicant_cell_phone_number',
      label: 'Cell phone #',
      type: 'phone',
      hide: form.i_am_applicant,
      // required: false,
    },
    {
      name: 'applicant_email_address',
      label: 'Email address',
      type: 'email',
      hide: form.i_am_applicant,
      // required: false,
    },
    {
      name: 'employed',
      label: 'Currently employed?',
      type: 'select:bool'
    },
    {
      name: 'place_of_employment',
      label: 'Where are you employed?',
      hide: !form.employed,
    },

    "::SPACER",
    "::Job placement preference",
    {
      name: 'which_program',
      label: 'Which programme would you like to be considered for?',
      options: this.programOptions(form),
      hint: form.division_selected == 'youth' && 'Apprentice programme has limited space. Corporate placement is for individuals age 18-25',
    },

    // Youth Division
    {
      name:'camp_select',
      label: `Please select which camp area you'd like to be placed in`,
      // hint: 'Must be able to demonstrate capability in chosen area',
      hide: form.which_program !='camp placement',
      options: form.division_selected == 'culture' ?
      [
        'dance',
        'junkanoo',
        'film',
        'theatre',
        'writing',
        'drumming',
        'pageantry',
        'arts & craft',
        'music-choir',
        'musical-instruments',
      ] :
      [
        { 
          value: 'Camp iMatter',
          label: 'Camp iMatter',
        },
        { 
          value: 'CampLit',
          label: 'CampLit',
        },
      ]
    },
    // Sports Division
    {
      name:'program_interest',
      label: `Please select which area you are interested in`,
      // hint: 'Must be able to demonstrate capability in chosen area',
      hide: !['summer camp instructor', 'sports internship'].includes(form.which_program),
      options: this.programInterest(form),
    },
    {
      name: 'instruments_list',
      label: 'Name of instrument(s)',
      columns: ['instrument'],
      format: v => v.instrument,
      hide: form.division_selected == 'culture' ? form.camp_select !='musical-instruments' : true,
    },
    {
      name: 't_shirt_size',
      options:[
          'small',
          'medium',
          'large',
          'XL',
          'XXL',
      ],
      hide: form.which_program !='camp placement',
    },
    {
      name: 'apprentice_select',
      label: `Please select which apprentice area you'd like to be placed in`,
      hide: form.which_program != 'apprentice programme',
      options: form.division_selected == 'culture' ? 
      [
        'cultural research',
        'visual arts',
        'farming',
        'fasion',
        'theatre performance',
        'animation',
        'media production',
        'song writing & production',
      ] : [
        'beauty trades',
        'face painting',
        'carpentry',
        'photography',
        'cooking',
        'baking',
        'agriculture',
        'sewing',
      ],
    },

    ":: Emergency Contact Information",
    {
      name: 'emergency_first_name',
      label: 'First name',
    },
    {
      name: 'emergency_middle_name',
      label: 'Middle name',
      required: false
    },
    {
      name: 'emergency_last_name',
      label: 'Last name',
    },
    {
      name: 'emergency_relation_to_applicant',
      label: 'Relation to applicant',
      options: ['parent', 'guardian', 'spouse', 'relative', 'friend']
    },
    {
      name: 'emergency_place_of_work',
      label: 'Place of employment',
    },
    {
      name: 'emergency_home_phone_number',
      label: 'Home phone #',
      type: 'phone',
      required: false,
    },
    {
      name: 'emergency_work_phone_number',
      label: 'Work phone #',
      type: 'phone',
      required: false,
    },
    {
      name: 'emergency_cell_phone_number',
      label: 'Cell phone #',
      type: 'phone',
    },

    "::Education",
    {
      name: 'level_of_education',
      label: 'What is your current educational status?',
      options:['not applicable', 'freshman', 'sophomore', 'junior', 'senior'],
    },
    {
      name: 'name_of_school',
      label: 'What is the name of your School/College/University?',
      hide: form.level_of_education == 'not applicable'
    },
    {
      name: 'graduation_year',
      label: 'Graduation year',
      type: 'integer'
    },
    {
      name: 'college_degree_list',
      label: 'Certificates obtained?',
      multi: true,
      options: [
        'none',
        'bjc',
        'bgcse',
        'associate',
        'bachelors',
        'masters'
      ],
      // required: false,
      // hint: 'Type N/A if not applicable',
    },
    {
      name: 'name_of_degree',
      label:'What is the name of the degree?',
      hide: !this.includesAny(['associate', 'bachelors', 'masters'], form.college_degree_list)
      // hint: 'Type N/A if not applicable',
    },

    {
      name: 'bjcs_passed_list',
      label: 'List the BJC subjects you have passed',
      columns: [
        { 
          name: 'subject',
          required: false,
        },
        {
          name: 'grade',
          options: ['D', 'C', 'B', 'A'],
          required: false,
        }
      ],
      hide: !this.includesAny(['bjc'], form.college_degree_list),
      required: false,
    },
    {
      name: 'bgcses_passed_list',
      label: 'List the BGCSE subjects you have passed',
      columns: [
        { 
          name: 'subject',
          required: false,
        },
        {
          name: 'grade',
          options: ['D', 'C', 'B', 'A'],
          required: false,
        }
      ],
      hide: !this.includesAny(['bgcse'], form.college_degree_list),
      required: false,
    },

    "::SPACER",
    {
      name: 'additional_experience',
      label: 'Provide any additional trainings or experience you think may be relevant',
      type: 'textarea',
      required: false,
    },

    ":: Medical Information",
    {
      name: 'health_condition',
      label: 'Do you have any physical conditions that may affect your ability to perform the duties associated with the position sought?',
      type: 'select:bool'
    },
    {
      name: 'health_condition_details',
      label: 'Please specify precautions or special arrangements that would need to be taken under consideration',
      type: 'textarea',
      hide: !form.health_condition,
    },

    "::References",
    {
      name:'references_list',
      label:'Give the name of (3) persons, who are not immediate relatives, to provide a reference on your behalf',
      rowLabel: 'Reference',
      min: 3,
      hide: form.which_program != 'corporate placement',
      fields: [
        'first_name',
        'last_name',
        {
          name: 'relationship_to_applicant',
          label: 'Relation to applicant',
          options: ['parent', 'guardian', 'spouse', 'relative', 'friend'],
        },
        {
          name: 'contact_information',
          type: 'phone'
        },
        {
          name: 'reference_upload',
          label: 'Reference Upload',
          type: 'file:image',
        },
      ],
    },

    ":: Banking Info",
    {
      name: 'bank_name',
      label: 'Bank Name',
      options: commonBanks,
    },
    {
      name: 'bank_branch',
      label: 'Bank Branch Transit #',
      type: 'integer',
    },
    {
      name: 'bank_acct_numb',
      label: 'Bank Account Number',
      type: 'integer',
    },

    ":: Uploads",
    {
      name: 'choose_upload',
      label: "Please upload one of the following documents to verify you are the applicant's parent or guardian",
      options: ['Birth Certificate', 'Adoption Certificate', 'Certified Court Order', 'Letter from Social Services'],
      hide: form.i_am_applicant || form.applicant_age > 18,
    },
    {
      name: 'child_proof_upload',
      label: this.childProofLabel(form),
      hint: `If the document is not uploaded, you must visit the Division of 
        <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.0',
      type: 'file:all',
      hide: form.i_am_applicant || form.applicant_age > 18,
      required: false,
    },
    {
      name: 'guardian_proof_upload',
      label: 'Identification documents of parent or legal guardian',
      hint: `If the document is not uploaded, you must visit the Division of 
      <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.1',
      type: 'file:all',
      hide: form.i_am_applicant || form.applicant_age > 18 || form.choose_upload != 'Birth Certificate',
      required: false,
    },
    {
      name:'police_record_upload',
      label: 'Police Character Certificate',
      key: 'image.2',
      type: 'file:all',
      hide: form.which_program != 'corporate placement',
    },
    {
      name: 'nib_card_upload',
      label: 'National Insurance Smart Card',
      key: 'image.3',
      type: 'file:all',
    },
    {
      name: 'passport_or_birth_upload',
      label: 'Valid Passport',
      key: 'image.4',
      type: 'file:all',
      hint: passportPhotoExample,
    },
    {
      name: 'uni_id_upload',
      label: 'College/University ID or Transcript',
      key: 'image.5',
      type: 'file:all',
      required: false,
    },
    {
      name:'photo_upload',
      label: 'Photo (Current Headshot of Applicant)',
      key: 'image.6',
      type: 'file:image',
    },
    {
      name:'qualifications_upload',
      label: 'Degree or other certificates',
      key: 'image.7',
      type: 'file:all',
      required: false,
    },
    {
      name: 'bjc_upload',
      label: 'BJCs Upload',
      key: 'image.8',
      type: 'file:image',
      hide: !this.includesAny(['bjc'], form.college_degree_list),
    },
    {
      name: 'bgcse_upload',
      label: 'BGCSEs Upload',
      key: 'image.9',
      type: 'file:image',
      hide: !this.includesAny(['bgcse'], form.college_degree_list),
    },
    {
      name: 'associates_upload',
      label: 'Associate\'s Degree',
      key: 'image.10',
      type: 'file:image',
      hide: !this.includesAny(['associate'], form.college_degree_list),
    },
    {
      name: 'bachelors_upload',
      label: 'Bachelor\'s Upload',
      key: 'image.11',
      type: 'file:image',
      hide: !this.includesAny(['bachelors'], form.college_degree_list),
    },
    {
      name: 'masters_upload',
      label: 'Master\'s Degree',
      key: 'image.12',
      type: 'file:image',
      hide: !this.includesAny(['masters'], form.college_degree_list),
    },
    {
      name: 'bank_info_upload',
      label: 'Bank Account Information and Proof of Recent Activity',
      key: 'image.13',
      type: 'file:image',
      hint: 'Card with Bank name, branch, account number and transit code or Bank statement'
    },
    {
      name: 'resume_upload',
      label: 'Current Resume',
      key: 'image.14',
      type: 'file:image',
      hint: 'Corporate Placement Programme selected',
      hide: form.which_program != 'corporate placement',
      // required: false
    },
    {
      name: 'immunization_upload',
      label: 'Immunization Card',
      key: 'image.15',
      type: 'file:image',
      hint: 'Corporate Placement Programme selected',
      hide: form.which_program != 'corporate placement' && !form.health_condition,
      required: false
    },
    {
      name: 'declare',
      label: `I confirm that the information submitted is true and that any 
        application submitted on behalf of a minor has been completed 
        by their parent/guardian`,
      type: 'checkbox',
      hide: form.i_am_applicant || form.applicant_age >= 18,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
