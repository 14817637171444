/* These are import statements in JavaScript that import various modules and components needed for the
TradeUnionRegistration class to function properly. */
import React, { Fragment } from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import Loader from '@shared/loader'
// import Form from '@modules/form-wizard/form'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
// import OpenMapLeaf from '@modules/open-street-map-field'

/* This code exports a React component called `TradeUnionRegistration` as the default export. The
component extends the `React.Component` class and defines various properties and methods that are
used to render a form wizard for registering a trade union. */


export default class PowerSawPermit extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  // async componentDidMount() {
  //   Object.assign(this, extractKeys($app, 'current_user:user'))
  // }

  resource = '/power_saw_permits'
  form_key = 'power_saw_permit'
  isRenewal = /renewal/.test(this.props.location.pathname)

  // postpaid = true
  pay_on_approval = true
  skip_location = true

  hide_buttons = {
    status: false,
    deny: false,
    fee: false,
  }

  state = {
    loading: false,
  }

  defaults = {
    renewal: this.isRenewal,
    first_permit: this.isRenewal ? !this.isRenewal : null,
    // transfer: this.isTransfer
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
        // transfer: this.isTransfer,
      },
    },
  }


 /* `defaults` is an object that contains default values for the fields in the form. These values will
 be used to pre-populate the form when it is first loaded. The default values include the type of
 union, whether a new name will be used, the name of the employer, the name of the union, the street
 address of the union, and information about the union officers, including their titles, names,
 email addresses, NIB numbers, addresses, and occupations. */
  // defaults = {
  //   "union_type": "Union of Employees",
  //   "new_name": true,
  //   "employer_name": "Woodworking",
  //   "union_name": "Totally New Union #242",
  //   "union_street_address": "TEST STREET",
  //   "union_officers": [
  //     {
  //         "title": "President",
  //         "email": "test@email.com",
  //         "first_name": "John",
  //         "middle_name": "J",
  //         "last_name": "Doe",
  //         "nib": "12345678",
  //         "address": "TEST ADDRESS",
  //         "occupation": "Business Management & Administration"
  //     },
  //     {
  //         "title": "Treasurer",
  //         "email": "test@email.com",
  //         "first_name": "Jane",
  //         "middle_name": "",
  //         "last_name": "Doe",
  //         "nib": "754732624",
  //         "address": "Test Address",
  //         "occupation": "Arts, Audio/Video Technology & Communications"
  //     },
  //     {
  //         "title": "General Secretariat",
  //         "email": "test@email.com",
  //         "first_name": "Jack",
  //         "middle_name": "T",
  //         "last_name": "Doe",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     },
  //     {
  //         "title": "Trustee",
  //         "email": "test@email.com",
  //         "first_name": "Johnathon",
  //         "middle_name": "D",
  //         "last_name": "Munnings",
  //         "nib": "9080083204",
  //         "address": "Test Stree",
  //         "occupation": "Agriculture, Food & Natural Resources"
  //     }
  //   ],
  // }

  validate = form => {

  }

  canned = {
    'TEST CANNED': `
      This is a test canne message; it can be whatever you like.
    `
  }

  foremost = null

  // closeModal = () => this.setState({ record: null })

  // t.string :forest_name
  // t.string :forest_island
  // t.string :forest_street_address
  // t.decimal :longitude, precision: 10, scale: 6
  // t.decimal :latitude, precision: 10, scale: 6
  // t.string :forest_settlement

  // t.boolean :first_permit, default: false
  // t.string :last_app_numb
  // t.date :last_app_date
  // t.boolean :permit_granted, default: false
  // t.string :last_permit_numb
  // t.date :last_permit_date

  // t.boolean :saw_owner, default: false
  // t.string :saw_owner_f_name
  // t.string :saw_owner_l_name
  // t.string :saw_owner_island
  // t.string :saw_owner_street_address
  // t.string :saw_possession_desc

  // t.boolean :to_harvest, default: false
  // t.boolean :valid_harvest_permit, default: false
  // t.string :harvest_permit_numb
  // t.string :harvest_license_numb
  // t.date :harvest_license_date

  // t.string :saw_make
  // t.string :saw_blade_length
  // t.integer :saw_age
  // t.string :saw_serial_numb
  // t.string :saw_markings

  // t.string :produce_desc

  // t.attachment :harvest_permit_upload

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      label: 'Renewal?',
      type: 'select:bool',
      hide: true,
      view: true,
      save: true,
    },

    '::Forest Estate Details',
    {
      name:'forest_name',
      label:'Name of forest estate',
    },
    // {
    //   name:'forest_acreage',
    //   label:'approximate acreage of forest estate',
    // },
    {
      name:'forest_island',
      label:'Island',
      options: islands,
    },
    // {
    //   name:'forest_city',
    //   label:'',
    // },
    {
      name:'forest_street_address',
      label:'Address',
    },
    {
      name:'forest_settlement',
      label:'Nearest Settlement',
    },

    '::Specific Information',
    {
      name:'first_permit',
      type: 'select:bool',
      label:'Is this your first application for a permit to use/transport a portable or power driven saw?',
      disabled: this.isRenewal,
      save: true,
    },
    {
      name:'last_app_numb',
      label:'Application No.',
      // type: 'integer',
      hide: form.first_permit,
    },
    {
      name:'last_app_date',
      label:'Date of application',
      type:'date',
      hide: form.first_permit,
    },
    {
      name:'permit_granted',
      type: 'select:bool',
      label: 'Was the permit granted?',
      hide: form.first_permit,
    },
    {
      name:'last_permit_numb',
      label:'Permit No.',
      // type: 'integer',
      hide: !form.permit_granted,
    },
    {
      name:'last_permit_date',
      label:'Date of issue',
      type:'date',
      hide: !form.permit_granted,
    },

    '::Saw Owner Details',
    {
      name:'saw_owner',
      label: 'Are you the owner of the portable or power driven saw?',
      type: 'select:bool',
    },
    {
      name:'saw_owner_f_name',
      label:'First Name',
      hide: form.saw_owner,
    },
    {
      name:'saw_owner_l_name',
      label:'Last Name',
      hide: form.saw_owner,
    },
    {
      name:'saw_owner_street_address',
      label:'Street Address',
      hide: form.saw_owner,
    },
    {
      name:'saw_owner_island',
      label:'Island of residence',
      options: islands,
      hide: form.saw_owner,
    },
    {
      name: 'saw_possession_desc',
      label:'How did you come to be in possession of the saw?',
      hide: !form.saw_owner,
    },
    {
      name: 'to_harvest',
      label: 'Do you intend to use the portable or power saw to harvest timber and/or non-timber forest produce?',
      type: 'select:bool',
    },
    {
      name:'valid_harvest_permit',
      type: 'select:bool',
      label:'Are you the holder of a valid permit or timber harvesting licence to harvest and remove timber and/or non-timber forest produce?',
    },
    {
      name:'harvest_permit_numb',
      label:'Permit No.',
      // type: 'integer',
      hide: !form.valid_harvest_permit,
    },
    {
      name:'harvest_license_numb',
      label:'Licence No.',
      // type: 'integer',
      hide: !form.valid_harvest_permit,
    },
    {
      name:'harvest_license_date',
      label:'Date of Issue',
      type:'date',
      hide: !form.valid_harvest_permit,
    },
    {
      name: 'permit_type',
      label: 'Type of permit',
      options: ['Timber', 'Non-timber'],
      hide: !form.valid_harvest_permit,
    },
    {
      name: 'timber_type',
      label: 'Is it salvage or non-salvage?',
      options: ['salvage', {name:'non_salvage', label: 'Non-salvage'}],
      hide: form.permit_type != 'Timber'
    },

    '::Saw Details',
    {
      name: 'saw_make',
      label: 'Name/make of the power driven saw',
    },
    {
      name: 'saw_blade_length',
      label: 'Length of the blade',
      type: 'integer',
      hint: 'In Feet',
    },
    {
      name: 'saw_age',
      label: 'Approximate age of power driven saw',
      type: 'integer',
    },
    {
      name: 'saw_serial_numb',
      label: 'Serial number of power driven saw',
    },
    {
      name: 'saw_markings',
      label: 'Any distinctive markings on the power driven saw',
    },

    '::Produce Details',
    {
      name: 'timber_specifics_list',
      label: 'Specifics of timber or non-timber produce to be removed',
      // hint: 'Applicable for instrument class only',
      columns: ['timber', {name: 'non_timber', label: 'Non-timber'}],
      format: v => v.timber,
    },
    {
      name:'harvest_permit_upload',
      key: 'image.1',
      // label:'',
      type: 'file:all',
    },
  ]

  render = () => {
    const { loading } = this.state
    // console.log($app.current_user.id)

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
      </Fragment>
    )
  }
}
