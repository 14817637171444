import React from 'react'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'

import Loader from '@shared/loader'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import normalizeField from '@helpers/normalize-form-field'

import occupations from '@constants/occupations'

export default class ProspectRidge extends React.Component {
  set = key => val => this.setState({ [key]: val })

  state = {
    co_applicant: false,
    loading: false,
  }

  long_name = 'Prospect Ridge Community for Young Professional Serviced Lots'
  resource = '/prospect_ridge_com_serviced_lots'
  form_key = 'prospect_ridge_com_serviced_lot'
  free = true

  skip_location = true
  custom_action = false
  custom_acceptance = true

  hide_buttons = {
    status: true,
    deny: true,
  }

  custom_action = {
    text: 'Confirm Gov. Employee',
    icon: 'user',
    fn: record =>
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className='custom-ui'>
            <h3>Confirm As Governemt Employee</h3>
            <p style={{ color: '#333' }}>
              Are you sure you want to confirm{' '}
              {`${record.user.first_name} ${record.user.last_name}`.capitalize()}
              as a goverment employee?
            </p>
            <button
              className='btn btn-primary'
              onClick={this.confirmGovWorker(record, onClose)}
            >
              Yes
            </button>
            <button className='btn btn-danger' onClick={onClose}>
              No
            </button>
          </div>
        ),
      }),
  }

  confirmGovWorker = (record, onClose) => async ev => {
    const { resource, form_key } = this

    try {
      this.setState({ loading: true })

      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'processing',
          gov_worker: true,
        },
      })

      onClose()
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({ loading: false })
    }
  }

  before_apply = () => {
    if ($app.applicant.age > 45) {
      return 'Only citizens 45 years of age and below are permitted to apply for this form'
    }
  }

  calculateScore = ({ land_owner, gov_worker, ...form }) => {
    let sum = 0
    const { age } = $app.applicant
    const income = form.self_employed
      ? form.business_annual_income
      : form.annual_salary

    if (!land_owner) {
      sum += 5
    }

    if (gov_worker) {
      sum += 5
    }

    if (age < 26) {
      sum += 15
    } else if (age < 36) {
      sum += 10
    } else {
      sum += 5
    }

    if (income <= 80000) {
      sum += 15
    } else if (income <= 120000) {
      sum += 10
    } else {
      sum += 5
    }

    return sum
  }

  on = {
    confirm: v => this.setState({ gov_worker: v?.[0]?.value }),
  }

  defaults = {
    verification_option: 'Mortgage Preapproval Letter',
  }

  foremost = {
    type: 'select:bool',
    label: `
      Do you have ready a Mortgage Preapproval Letter OR a Letter of Financial Suitability from a financial institutution,
      and a current Job Letter (or a Business Licence if you are self employed)?
    `,
    long: true,
    test: v =>
      v ||
      'Sorry, but you must meet the prerequisites before being allowed to apply. Please try again after obtaining the necessary documents.',
  }

  fields = (form, viewMode) => {
    const { state, set } = this

    const self = [
      'business_name',
      'business_address',
      {
        name: 'business_start_date',
        type: 'date',
        showYearMonthDropdown: true,
      },
      {
        name: 'business_annual_income',
        is: 'currency',
        label: 'Annual Income',
      },
      {
        name: 'business_net_income',
        is: 'currency',
        label: 'Annual Net Income',
      },
      {
        name: 'business_desc',
        type: 'textarea',
        label: 'Please describe the nature of the business',
        view: {
          label: 'Nature of Business',
          hide: !form.self_employed,
        },
      },
    ]
      .map(normalizeField)
      .map(f => ({
        ...f,
        hide: f.hide || !form.self_employed,
      }))

    const employed = [
      '::Employment Details',
      'place_of_employment',
      'employment_address',
      {
        name: 'employment_occupation',
        options: occupations,
        label: 'Occupation',
      },
      {
        name: 'tenure_of_employment',
        view: { value: v => v + ' year(s)', hide: form.self_employed },
        label: 'Employment Tenure (years)',
        is: 'integer',
      },
      {
        name: 'annual_salary',
        is: 'currency',
      },
    ]
      .map(normalizeField)
      .map(f => ({
        ...f,
        hide: f.hide || form.self_employed,
      }))

    const hasCoApplicant = form.co_applicant_first_name || state.co_applicant
    const coFields = [
      '::Co-Applicant Details',
      {
        name: 'co_applicant_name',
        hide: true,
        view: {
          label: 'Name',
          value: () =>
            ['prefix', 'first_name', 'middle_name', 'last_name', 'suffix']
              .map(k => form['co_applicant_' + k])
              .join(' '),
        },
      },
      {
        name: 'prefix',
        view: false,
        options: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.', 'Rev.'],
      },
      {
        name: 'first_name',
        view: false,
      },
      {
        name: 'middle_name',
        view: false,
        required: false,
      },
      {
        name: 'last_name',
        view: false,
      },
      {
        name: 'dob',
        type: 'date',
        view: {
          label: 'D.O.B',
          value: v => moment(v).format('Do MMMM, YYYY'),
        },
      },
      {
        name: 'email',
        is: 'email',
      },
      {
        name: 'relationship',
        options: ['Spouse', 'Parent', 'Son', 'Daughter', 'Relative'],
      },
      {
        name: 'nib',
        label: 'NIB',
        is: 'integer',
      },
      {
        name: 'passport',
        is: 'passport',
      },
      {
        name: 'voters_card',
        required: false,
      },
      // {
      //   name: 'annual_salary',
      //   is: 'currency',
      // },
      {
        name: 'self_employed',
        type: 'select:bool',
      },
      {
        heading: 'Co-Applicant Employment Details',
        hide: !viewMode || form.co_applicant_self_employed,
      },
      ...employed.map(f => ({ ...f, hide: form.co_applicant_self_employed })),
      {
        heading: (viewMode ? 'Co-Applicant ' : '') + 'Business Details',
        hide: !form.co_applicant_self_employed,
      },
      {
        name: 'business_name',
        hide: !form.co_applicant_self_employed,
      },
      {
        name: 'business_address',
        hide: !form.co_applicant_self_employed,
      },
      {
        name: 'start_date',
        type: 'date',
        showYearDropdown: true,
        hide: !form.co_applicant_self_employed,
      },
      {
        name: 'business_annual_net_income',
        is: 'currency',
        hide: !form.co_applicant_self_employed,
      },
      {
        name: 'banking_institution',
        hide: !form.co_applicant_self_employed,
      },
      {
        name: 'business_desc',
        type: 'textarea',
        label: 'Please describe the nature of the business',
        hide: !form.co_applicant_self_employed,
        view: {
          label: 'Nature of Business',
          hide: !form.co_applicant_self_employed,
        },
      },
      {
        name: 'additional_income',
        type: 'select:bool',
        label: 'Any Additional Income?',
      },
      {
        name: 'additional_income_source',
        hide: !form.co_applicant_additional_income,
        label: 'Income Source',
      },
      {
        name: 'additional_income_monthly_amount',
        hide: !form.co_applicant_additional_income,
        is: 'currency',
        label: 'Additional Monthly Amount',
      },
      {
        name: 'disabilities',
        type: 'select:bool',
        label: 'Are you disabled?',
      },
      {
        key: f => f.image[6],
        name: 'disability_cert_upload',
        type: 'file:image',
        label: 'Disability Certificate',
        hide: !form.co_applicant_disabilities,
      },
      {
        name: 'disabilities_desc',
        type: 'textarea',
        hide: !form.co_applicant_disabilities,
        label: 'Please describe their disability',
        view: {
          label: 'Description of Disability',
          hide: !form.co_applicant_disabilities,
        },
      },
    ]
      .map(normalizeField)
      .map(f => ({
        ...f,
        name: 'co_applicant_' + f.name,
        label: f.label || initialCaps(f.name),
        hide: f.hide || !hasCoApplicant,
      }))

    return [
      {
        admin: true,
        name: 'weighted_score',
        view: {
          value: v => this.calculateScore(form),
        },
      },
      {
        name: 'self_employed',
        type: 'select:bool',
        label: 'Are you self employed?',
        view: {
          label: 'Self Employed?',
        },
      },
      {
        name: 'land_owner',
        type: 'select:bool',
        label: 'Do you own land?',
        view: {
          label: 'Owns Land?',
        },
      },
      {
        name: 'first_time',
        label: 'Are you a first time homeowner?',
        type: 'select:bool',
        test: v =>
          v ||
          'Sorry, but only first time homeowners are eligible to apply for this application',
        hide: !form.land_owner,
        view: false,
      },
      {
        name: 'property_type',
        label: 'Planned Project Type',
        options: [
          'Single Family Home',
          'Single Storey Duplex',
          'Two Storey Duplex',
        ],
      },
      '::Supporting Documents',
      {
        key: f => f.image[0],
        name: 'job_letter_upload',
        type: 'file:pdf',
        label: 'Current Job Letter',
        hide: form.self_employed,
        tooltip: 'Job Letter must not be older than 30 days',
      },
      {
        key: f => f.image[2],
        name: 'business_license_upload',
        type: 'file:pdf',
        hide: !form.self_employed,
        label: 'Current Business Licence',
      },
      {
        key: f => f.image[3],
        name: 'mortgage_letter_upload',
        type: 'file:pdf',
        label: 'Mortgage Preapproval Letter OR Letter of Financial Suitability',
        tooltip: 'Letter must not be older than 30 days',
      },
      '::Personal Details',
      {
        name: 'marital_status',
        profile: true,
      },
      {
        name: 'dependents',
        type: 'select:bool',
        view: true,
        label: 'Any Dependents?',
      },
      {
        name: 'number_of_dependents',
        is: 'integer',
        hide: !form.dependents,
      },
      {
        name: 'disabilities',
        type: 'select:bool',
        label: 'Are you disabled?',
      },
      {
        key: f => f.image[5],
        name: 'disability_cert_upload',
        type: 'file:image',
        label: 'Disability Certificate',
        hide: !form.disabilities,
      },
      {
        name: 'disability_desc',
        type: 'textarea',
        hide: !form.disabilities,
        label: 'Please describe your disablity',
        view: {
          label: 'Description of Disability',
          hide: !form.disabilities,
        },
      },
      ...self,
      ...employed,
      {
        name: 'co_applicant',
        label: 'Is There A Co-Applicant?',
        type: 'select:bool',
        callback: set('co_applicant'),
        view: false,
      },
      ...coFields,
      {
        name: 'acceptance_1',
        type: 'checkbox',
        label:
          'I/We understand that there is a limited amount of lots and submittal of a full application does not guarantee approval to purchase a lot.',
      },
      {
        name: 'acceptance_2',
        type: 'checkbox',
        label:
          'I/We agree to build in 2 years with the understanding that an additional year can be applied for with Government tax concessions.',
      },
      {
        name: 'acceptance_3',
        type: 'checkbox',
        label:
          'I/We give consent to the Government of The Bahamas or its designee to verify information provided in this application.',
      },
      {
        name: 'acceptance_4',
        type: 'checkbox',
        label: `
          I/We agree that, if selected, I/we must attend at least one educational session,
          during which the rights and responsibilities of home-owners will be presented.
        `,
      },
      {
        use_as_acceptance: true,
        name: 'acceptance',
        type: 'checkbox',
        view: false,
        label: `
          I/We agree to provide additional information on request to verify the accuracy of all statements in this application.<br/><br/>
          *No application will be considered complete unless (signed or ticked box of acceptance) by the applicant and co-applicant (if applicable).
        `,
      },
    ]
  }

  render = () => (
    <React.Fragment>
      <Loader loading={this.state.loading} />
      <Wizard {...SERVICE_PROPS(this)} />
    </React.Fragment>
  )
}

function initialCaps(str) {
  if (!str) return ''

  return str
    .trim()
    .split(/[ _-]/)
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')
}
