import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class IssueCertificate extends React.Component {
  resource = '/issue_certificates'
  form_key = 'issue_certificate'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'reg_mark',
        label:'Registration Mark',
        hint:'C6'
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Manufacturer of Aircraft'
    },
    {
        name: 'manufacture_date',
        label: 'Date of Manufacture',
        type: 'date',
        showYearDropdown:true
    },
    {
        name: 'type_cert',
        label: 'Type Certificate Data Sheet (TCDS) to which the aircraft conforms'
    },
    {
        name: 'new_used',
        label: 'New oe Used',
        options: ['New', 'Used']
    },
    {
        name: 'airframe_hours'
    },
    {
        name: 'cycles'
    },
    {
        name: 'Engine_manufactur',
        label: 'Engine(s) Manufacturer',
        columns: ['Engine Manufacturer', 'Type Designation']
    },
    {
        name: 'pro_manufactur',
        label: 'Propeller(s) Manufacturer',
        columns: ['Propeller Manufacturer', 'Type Designation']
    },
    {
        name: 'apu_manufactur',
        label: 'APU Manufacturer',
        columns: ['APU Manufacturer', 'Type Designation']
    },
    {
        name: 'max_takeoff',
        label: 'Maximum take-off Mass',
        hint: 'kg'
    },
    {
        name: 'max_landing',
        label: 'Maximum Landing Mass',
        hint: 'kg'
    },
    {
        name: 'max_seats',
        label: 'Maximum No. of Seats installed',
        columns: [
            {
                name: 'passengers',
                type: 'integer'
            },
            {
                name: 'crew',
                type: 'integer'
            }
        ]
    },
    ":: Flight Manual / Pilot Operating Handbook",
    {
        name: 'fm_num',
        label: 'FM/POH Reference Number' 
    },
    {
        name: 'revision_num',
        label: 'Revision No.'
    },
    {
        name: 'fm_ref',
        label: 'FM Supplement Reference'
    },
    {
        name: 'fm_title',
        label: 'FM Supplement Title'
    },
    {
        name: 'fm_date',
        label: 'FM Supplement Date:',
        type: 'date',
        showYearDropdown: true
    },
    {
        name: 'avionics_system',
        label:'Avionics System Installation And Transmitting Equipment Fitted',
        columns: [
          {
            name: 'categories',
            options: [
              'VHF Communication',
              'HF Communication',
              'VHF Navigation, Marker',
              'ELT',
              'Satcom',
              'Radio Altimeter',
              'DME',
              'ATC Transponder',
              'TCAS/ACAS',
              'GPS',
              'ADF', 
              'Weather Radar'
            ]
          },
          {
            name: 'manufacturer'
          },
          {
            name: 'type',
            label: 'Type/Model'
          },
          {
            name: 'quantity'
          }
        ]
      },
      "::Recorders",
      
    {
        name: 'recorder_one',
        label: 'Flight Data Recorder(FDR)'
    },
    {
        name: 'manufacturer'
    },
    {
        name: 'type',
        label: 'Type/Model'
    },
    {
        name: 'quantity'
    },
    {
        name: 'recorder_two',
        label: 'Cockpit Voice Recorder(CVR)'

    },
    {
        name: 'manufacturer'
    },
    {
        name: 'type',
        label: 'Type/Model'
    },
    {
        name: 'quantity'
    },
    "::Noise Certification",
    {
        heading: <p>Noise levels should include the unit of measure</p>
    },
    {
        name: 'cert_standard',
        label: 'ICAO Noise Certification Standard'
    },
    {
        name: 'lateral_level',
        label: 'Lateral/Full Power Noise Level'
    },
    {
        name: 'approach_level',
        label: 'Approach Noise Level'
    },
    {
        name: 'over_level',
        label: 'Over Flight Noise Level'
    },
    {
        name: 'takeoff_level',
        label: 'Take-off Noise Level'
    },
    "::Deiiferences to type design standard",
    {
        name: 'mods_one',
        label: 'Please provide details of any major modifications (STCs) at original manufacture or during initial outfitting. Include STC or the equivalent reference here. If no differences exist, then please state NONE.',
        type: 'textarea'
    },
    {
        name: 'mods_two',
        label: 'Please provide details of modifications, STCs, etc. embodied since first entry into service. Include STC or the equivalent reference here. If no differences exist, then please state NONE.',
        type: 'textarea'
    },
    "::Records",
    {
        name: 'in_english',
        label: 'The maintenance and continuing airworthiness records are in the English language',
        type: 'select:bool'
    },
    {
        name: 'provide_comments',
        label: 'Provide comments if some, or all, of the records are not in the English language:',
        type: 'textarea',
        hide: form.in_english != false
    },
    "::Details of Aircraft Physical Inspection",
    {
        name: 'date_location',
        label: 'Date and location of physical aircraft inspection',
        columns: [
            {
                name: 'date',
                type: 'date',
                showYearDropdown:true
            },
            {
                name: 'location',
                type: 'textarea'
            }
        ]
    },
    {
        name: 'host_org',
        label: 'Host Organisation where the aircraft will be physically inspected'
    },
    {
        name: 'person_details',
        label: 'Details of person presenting the aircraft for physical inspection',
        rowLabel: 'Person',
        fields: ['Name', 'Telephone No.', 'Email']
    }
         

      
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
