import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'

export default class AviationLicense extends React.Component {
  resource = '/aviation_licenses'
  form_key = 'aviation_license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'name',
        label: 'Name(Last, First, Middle)'
    },
    {
        name: 'seeking',
        label: 'Seeking the Following License',
        multi: true,
        options: [
            'Student Pilot',
            'Commercial Pilot', 
            'Flight Dispatcher',
            'Private Pilot',  
            'Airline Transport Pilot', 
            'Air Traffic Controller',
            'Cabin Crew',
            'Maintenance Technician',
            'Other'
        ]
    },
    {
        name: 'tele_fax',
        label: 'Telehpone/Fax/Cellular',
        type: 'phone'
    },
    {
        name: 'perm_address',
        label: 'Permanent Address',
        hint: 'Street or PO Box Number'
    },
    {
        name: 'passport',
        label: 'Passport Number & State of Issue',
        type: 'textarea'
    },
    {
        name: 'city'
    },
    {
        name: 'state',
        label: 'State/Providence'
    },
    {
        name: 'mail_code'
    },
    {
        name: 'country',
        options: countries
    },
    {
        name: 'nationality',
        label: 'Nationality(Citizenship)',
        options: nationalities
    },
    {
        name: 'birth_country',
        label: 'Country of Birth',
        options: countries
    },
    {
        name: 'dob',
        label: 'Date of Birth',
        type: 'date',
        showDropdownYear: true
    },
    {
        name: 'height',
        hint: 'In'
    },
    {
        name: 'weight',
        hint: 'Lbs'
    },
    {
        name: 'eyes'
    },
    {
        name: 'sex',
        options: ['male', 'female']
    },
    {
        name: 'email',
        label: 'E-mail Address',
        type: 'email'
    },
    {
        name: 'current_holder',
        label: 'Current holder of a PEL License?',
        type: 'select:bool'
    },
    {
        name: 'license_type',
        label: 'Number & type of License'
    },
    {
        name: 'issue_state',
        label: 'State of issue'
    },
    {
        name: 'exp_date',
        label: 'Date of expiry',
        type: 'date',
        showDropdownYear: true
    },
    {
        name: 'medical_ass',
        label: 'Current Medical Assessment?',
        options: ['yes', 'no', 'N/A']
    },
    {
        name: 'ass_level',
        label: 'Assessment Level'
    },
    {
        name: 'iss_state',
        label: 'State of issue'
    },
    {
        name: 'date_exp',
        label: 'Date of expiry',
        type: 'date',
        showDropdownYear: true
    },
    {
        name:'eng_eval',
        label: 'Current English Language Evaluation?',
        options: ['yes', 'no', 'N/A']
    },
    {
        name: 'pro_level',
        label: 'Proficiency Level'
    },
    {
        name: 'is_state',
        label: 'State of issue'
    },
    {
        name: 'date_ex',
        label: 'Date of expiry',
        type: 'date',
        showDropdownYear: true
    },
    {
        name: 'notes',
        type: 'textarea'
    },
    {
        name: 'photo',
        type: 'file:all',
        key: 'image.0'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
