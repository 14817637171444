import React from 'react'
import Modal from 'react-responsive-modal'
import { connect } from 'react-redux'

import { extractKeys } from '@helpers/objects'
import { submitFeedback } from '@actions/agencies'

import VeryDisappointed from '@images/rating-emojis/very-disappointed.svg'
import Disappointed from '@images/rating-emojis/disappointed.svg'
import Neutral from '@images/rating-emojis/neutral.svg'
import Satisfied from '@images/rating-emojis/satisfied.svg'
import VerySatisfied from '@images/rating-emojis/very-satisfied.svg'

import '@styles/sweetalert.css'
import '@styles/service-feedback.css'

const styles = {
  container: {
    margin: '0 auto',
    textAlign: 'center',
  },

  lead: {
    fontWeight: 400,
    fontSize: '1.125rem',
    margin: '1rem auto',
    maxWidth: '600px',
  },
}

class Success extends React.Component {
  getMessage = () => {
    const { paid, postpaid, custom_postpaid_message } = this.props

    if (paid)
      return 'Your transaction has been successful. Please check your email for a receipt. You will be contacted by the agency for further instructions.'
    if (postpaid) {
      return (
        custom_postpaid_message ||
        'Your application has been submitted. You will receive an email when the application fee has been calculated and you are able to submit a payment.'
      )
    }

    return 'Your application has been submitted.'
  }

  state = {
    step: 1,
    modal: false,
    score: 0,
    comment: '',
    hide_feedback: false,
  }

  componentDidMount() {
    const { current_user: user } = $app
    const { feedback } = this.props

    if (feedback !== false && !user.hide_feedback) {
      this.timer = setTimeout(() => {
        this.setState({ modal: true })
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  closeModal = () => this.setState({ modal: false })
  setRating = score => () => this.setState({ score })
  setComment = ({ target }) => this.setState({ comment: target.value })
  setFeedback = ({ target }) => this.setState({ hide_feedback: target.checked })

  next = () => {
    const { step, modal, ...review } = this.state
    const { submitFeedback, service } = this.props

    if (step == 1) {
      return this.setState({ step: step + 1 })
    }

    if (step == 2) {
      submitFeedback(service.type, review, success => {
        this.setState({
          step: 3,
          title: success ? 'Thank You!' : 'Oops...',
          message: success
            ? 'We appreciate your feedback.'
            : 'We were unable to submit your review',
        })
      })
    }
  }

  Rating = {
    Prompt: () => (
      <div className='swal-modal' role='dialog' aria-modal='true'>
        <div className='swal-icon swal-icon--info'></div>
        <div className='swal-title'>We'd Love To Hear Your Feedback</div>
        <div className='swal-text'>Would you like to rate our service?</div>
        <div className='swal-footer'>
          <div className='swal-button-container'>
            <button
              className='swal-button swal-button--confirm btn btn-primary'
              onClick={this.next}
            >
              Yes
            </button>
          </div>
          <div className='swal-button-container'>
            <button
              className='swal-button swal-button--cancel btn btn-danger'
              onClick={this.closeModal}
            >
              No Thanks
            </button>
          </div>
        </div>
      </div>
    ),

    Form: () => {
      const { state, next, setRating, setComment, setFeedback, closeModal } =
        this
      const { score } = state

      const formSubmit = (ev) => {
        ev.preventDefault()
        next()
      }

      const desc = [
        'Please Select A Rating',
        'Very Disappointed',
        'Disappointed',
        'Neutral',
        'Satisfied',
        'Very Satisfied',
      ][score]

      const color = [
        '#AAA',
        '#FA4542',
        '#C9375A',
        '#575962',
        '#75D924',
        '#5AF157',
      ][score]

      const emoji = (rating, src) => (
        <img
          onClick={setRating(rating)}
          className={`rating-emoji rating-${rating} ${
            score == rating ? 'active' : ''
          }`}
          src={src}
          alt={'Rating Emoji ' + rating}
        />
      )

      return (
        <div
          className='swal-modal service-feedback-form'
          role='dialog'
          aria-modal='true'
        >
          <div className='swal-title'>How Would You Rate This Service?</div>

          <div className='rating-emojis'>
            <strong style={{ display: 'block', height: 24, color }}>
              {desc}
            </strong>
            <div className='d-flex align-items-center justify-content-between rating'>
              {emoji(5, VerySatisfied)}
              {emoji(4, Satisfied)}
              {emoji(3, Neutral)}
              {emoji(2, Disappointed)}
              {emoji(1, VeryDisappointed)}
            </div>
          </div>

          <form onSubmit={formSubmit}>
            <strong>Any comments?</strong>
            <textarea className='form-control' onChange={setComment} required={score <= 3} maxLength={400}></textarea>
            <div className='swal-footer'>
              <div className='swal-button-container'>
                <input
                  type='submit'
                  value='Submit'
                  className='swal-button swal-button--confirm btn btn-primary'
                  disabled={!state.score}
                />
              </div>
              <div className='swal-button-container'>
                <button
                  className='swal-button swal-button--cancel btn btn-danger'
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
          <div className='text-left'>
            <div className='custom-control custom-checkbox'>
              <input
                name='hide-feedback'
                id='hide-feedback'
                className='custom-control-input'
                type='checkbox'
                onChange={setFeedback}
                checked={state.hide_feedback}
              />
              <label
                className='custom-control-label m-0'
                htmlFor='hide-feedback'
              >
                <small>Don't show me this agan (Lasts 30 days)</small>
              </label>
            </div>
          </div>
        </div>
      )
    },

    Thanks: () => (
      <div className='swal-modal' role='dialog' aria-modal='true'>
        <div className='swal-title'>{this.state.title}</div>
        <div className='swal-text'>{this.state.message}</div>
        <div className='swal-footer'>
          <div className='swal-button-container'>
            <button
              className='swal-button swal-button--confirm btn btn-primary'
              onClick={this.closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    ),
  }

  Final = props => (
    <div className='tab-pane active' id='about'>
      <div className='flex flex-col items-center' style={styles.container}>
        <div className='swal-icon swal-icon--success'>
          <span className='swal-icon--success__line swal-icon--success__line--long'></span>
          <span className='swal-icon--success__line swal-icon--success__line--tip'></span>
          <div className='swal-icon--success__ring'></div>
          <div className='swal-icon--success__hide-corners'></div>
        </div>
        <h2>Successful!</h2>
        <span className='text-lg mt-4 mx-auto max-w-xl'>
          {this.getMessage()}
        </span>
        <span className='text-lg my-4 mx-auto max-w-3xl'>
          {props.success_message}
        </span>
        <br />
        <span>
          If you have not received an email in your inbox please check your junk
          or spam folder.
        </span>
      </div>
    </div>
  )

  render() {
    const { state, props, closeModal, Rating, Final } = this
    const content = {}

    if (state.modal) {
      switch (state.step) {
        case 1:
          content.modal = <Rating.Prompt />
          break
        case 2:
          content.modal = <Rating.Form />
          break
        case 3:
          content.modal = <Rating.Thanks />
          setTimeout(closeModal, 5000)
          break
        default:
      }
    }

    content.main = <Final {...props} />

    return (
      <React.Fragment>
        {content.main || null}
        <Modal open={state.modal} onClose={closeModal} center>
          {content.modal || null}
        </Modal>
      </React.Fragment>
    )
  }
}

export default connect(state => extractKeys(state.agencies, 'loading'), {
  submitFeedback,
})(Success)
