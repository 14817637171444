import React from 'react'
import moment from 'moment'
import axios from 'axios'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2'
import Select from "react-dropdown-select"

import { extractKeys } from '@helpers/objects'
import Loader from '@shared/loader'

import { getFeedback } from '@actions/agencies'
import ENV from '@constants/env'

const prettyName = service => service.replace(/_/g, ' ').capitalize()

class Stats extends React.Component {
  constructor(props) {
    super(props)

    const t = moment()

    this.labels = Array(30)
      .fill(null)
      .map(noop => {
        const d = t.format('Do MMM')
        t.subtract(1, 'days')
        return d
      })
  }

  state = {
    loading: true,
    range: '7',
    data: {},
    chart: ''
  }

  async componentDidMount() {
    const { props, get, parse } = this
    const { feedback, getFeedback } = props

    const fn = data => {
      this.setState(state => {
        state.loading = false
        state.data.feedback = parse.feedback(data || feedback)
        return state
      })
    }

    await get('show_signups')
    await get('show_service_efficacy')
    await get('show_verifications')

    if (feedback) fn()
    else getFeedback(fn)
  }

  user = JSON.parse(sessionStorage.getItem('user'))

  // Only show services in this area
  allowed = [
    'birth_certificates',
    'death_certificates',
    'marriage_certificates',
    "drivers_licenses:Driver's Licence Renewals",
    "driving_permits:New Driver's Permits",
    "drivers_learners_permit_renewals:Driver's Permit Renewals",
    'police_certificates:Police Character Certificates',
    'gun_licenses:Gun Licences',
    'gun_license_renewals:Gun Licence Renewals',
  ].map(k => {
    let [ key, label ] = k.split(':')
    if (!label) label = prettyName(key)
    return [ key, label ]
  })

  ranges = [
    { label: 'Last 7 Days', value: 7 },
    { label: 'Last 14 Days', value: 14 },
    { label: 'Last 30 Days', value: 30 },
  ]

  get = async (action) => {
    const { user, parse } = this

    try {
      const url = [ENV.WEB_SERVICE_URL, ENV.API_V, 'dashboard', action].join('/')

      const { data } = await axios.get(url, {
        headers: {
          Authorization: user.token
        }
      })

      switch (action) {
        case 'show_signups':
          this.setState(state => {
            state.data.signups = parse.signups(data)
            return state
          })
          break
        case 'show_verifications':
          this.setState(state => {
            state.data.verifications = parse.verifications(data)
            return state
          })
          break
        case 'show_service_efficacy':
          this.setState(state => {
            state.data.services = parse.services(data)
            this.services = Object.keys(state.data.services)
              .map(value => ({
                value,
                label: prettyName(value),
              }))
            state.chart = this.services[0].value
            return state
          })
          break
        default:
      }
    } catch (err) {
      console.error(err)
    }

    return
  }

  parse = {
    signups: ({ signups_each_date: dates, date_begin, date_end }) => {
      const array = []
      const start = moment(date_begin.slice(0, 10))
      const stop = moment(date_end).add(1, 'day')

      while (!start.isSame(stop, 'day')) {
        const target = dates.find(d => start.isSame(d.date, 'day')) || {}
        array.push(+(target.user_sign_ups || 0))
        start.add(1, 'day')
      }

      return array
    },

    services: ({ service_data_per_date: dates, date_begin, date_end }) => {
      const { allowed } = this

      const start = moment(date_begin.slice(0, 10))
      const stop = moment(date_end).add(1, 'day')

      const keys = Object.keys(dates[0])
        .filter(k => allowed && allowed.length ? allowed.some(a => a[0] == k) : true)

      const empty = keys.reduce((o, k) => ({ ...o, [k]: {} }), {})

      const services = keys.reduce((o, k) => ({
        ...o,
        [k]: {
          average: Array(30).fill(0),
          requests: Array(30).fill(0),
          pending: Array(30).fill(0),
          paid: Array(30).fill(0),
          unpaid: Array(30).fill(0),
          approved: Array(30).fill(0),
          denied: Array(30).fill(0),
        }
      }), {})

      let i = 0
      while (!start.isSame(stop, 'day')) {
        const target = dates.find(d => start.isSame(d.date, 'day')) || empty

        for (let key of keys) {
          services[key].average[i] = +(target[key].avg_completion_time || 0)
          services[key].requests[i] = +(target[key].requests || 0)
          services[key].pending[i] = +(target[key].pending_requests || 0)
          services[key].paid[i] = +(target[key].paid_requests || 0)
          services[key].unpaid[i] = +(target[key].unpaid_requests || 0)
          services[key].approved[i] = +(target[key].approved_requests || 0)
          services[key].denied[i] = +(target[key].denied_requests || 0)
        }

        i++
        start.add(1, 'day')
      }

      return services
    },

    feedback: ({ service_feedback_rating_per_date: dates, date_begin, date_end }) => {
      const { allowed } = this

      const start = moment(date_begin.slice(0, 10))
      const stop = moment(date_end).add(1, 'day')

      const keys = Object.keys(dates[0])
        .filter(k => allowed && allowed.length ? allowed.some(a => a[0] == k) : true)
      const subkeys = ['average', 'one', 'two', 'three', 'four', 'five']

      const empty = keys.reduce((o, k) => ({ ...o, [k]: {} }), {})

      const services = keys.reduce((o, k) => ({
        ...o,
        [k]: {
          average: [],
          one: [],
          two: [],
          three: [],
          four: [],
          five: [],
        }
      }), {})

      let i = 0
      while (!start.isSame(stop, 'day')) {
        const target = dates.find(d => start.isSame(d.date, 'day')) || empty

        for (let key of keys) {
          for (let subkey of subkeys) {
            services[key][subkey][i] = +(target[key][subkey] || 0)
          }
        }

        i++
        start.add(1, 'day')
      }

      return services
    },

    verifications: ({ verifications_per_date: dates, date_begin, date_end }) => {
      const start = moment(date_begin.slice(0, 10))
      const stop = moment(date_end).add(1, 'day')
      const keys = ['drivers_license', 'nib', 'passport', 'voters_card']
      const subkeys = ['document_verified', 'system_verified']

      const empty = keys.reduce((o, k) => ({ ...o, [k]: {} }), {})

      const services = keys.reduce((o, k) => ({
        ...o,
        [k]: {
          document_verified: [],
          system_verified: [],
        }
      }), {})

      let i = 0
      while (!start.isSame(stop, 'day')) {
        const target = dates.find(d => start.isSame(d.date, 'day')) || empty

        for (let key of keys) {
          for (let subkey of subkeys) {
            services[key][subkey][i] = +(target[key][subkey] || 0)
          }
        }

        i++
        start.add(1, 'day')
      }

      return services
    },
  }

  on = {
    range: v => v.length && this.setState({ range: v[0].value }),
    chart: v => v.length && this.setState({ chart: v[0].value }),
  }

  ServiceChart = ({ labels, options, paid, approved, denied }) => {
    const { state, on, services } = this
    const { range, chart } = state

    const config = {
      options,
      data: {
        labels,
        datasets: [{
          label: 'Requests Made',
          data: paid.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#000',
          fill: false,
        }, {
          label: 'Approved',
          data: approved.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#369',
          fill: false,
        }, {
          label: 'Denied',
          data: denied.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#C00',
          fill: false,
        }]
      }
    }

    return (
      <div className='card' key={chart}>
        <div className='card-header'>
          <Select
            searchable
            options={services}
            onChange={on.chart}
            values={[services.find(s => s.value == chart)]}
            className='form-control'
          />
        </div>
        <div className='card-body'>
          <Line {...config} />
        </div>
      </div>
    )
  }

  FeedbackTable = ({ feedback }) => {
    const { allowed } = this
  
    const calcWidth = averages => {
      const width = (averages.reduce((acc, avg) => acc + avg, 0) / averages.length * 20) + '%'
      return { width }
    }

    const rows = Object.keys(feedback)
      .map(key => (
        <tr key={key}>
          <td>{allowed.find(a => a[0] == key)[1]}</td>
          <td>
            <div className='star-ratings-sprite'>
              <span style={calcWidth(feedback[key].average)} className='star-ratings-sprite-rating'></span>
            </div>
          </td>
        </tr>
      ))

    return (
      <div className='card'>
        <div className='card-header'>
          <h5 style={{ margin: 0 }}>Ratings</h5>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='display table table-striped table-hover form-records-table'>
            <thead>
              <tr>
                <th>Service</th>
                <th>Rating</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { state, on, ranges, ServiceChart, FeedbackTable } = this
    const { loading, range, data, chart } = state
    const labels = this.labels.slice(0, range).reverse()
    
    if (loading) return (
      <Loader loading={true} />
    )
      
    const { nib, passport, drivers_license, voters_card } = data.verifications

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
            display: true,
            position: 'left',
          },
        ]
      }
    }

    const signup = {
      options,
      data: {
        labels,
        datasets: [{
          label: 'Total Signups',
          data: data.signups.slice(-range),
          backgroundColor: 'rgb(255, 255, 255)',
          borderColor: 'rgb(0, 0, 0)',
          fill: false,
        }]
      },
    }

    const verifications = {
      options,
      data: {
        labels,
        datasets: [{
          label: 'NIB',
          data: nib.document_verified.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#000',
          fill: false,
        }, {
          label: 'Passport',
          data: passport.document_verified.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#369',
          fill: false,
        }, {
          label: "Driver's Licence",
          data: drivers_license.document_verified.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#C00',
          fill: false,
        }, {
          label: "Voter's Card",
          data: voters_card.document_verified.slice(-range),
          backgroundColor: '#FFF',
          borderColor: '#A7B',
          fill: false,
        }]
      },
    }

    const service = {
      ...data.services[chart],
      options,
      labels,
    }

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <div id='stats' className='content'>
          <div className='page-header'></div>
          <div className='page-inner'>
            <div className='row' style={{ marginBottom: '1rem' }}>
              <div className='col-md-4'>
                <h4>Statistics</h4>
              </div>
              <div className='col-md-3 ml-auto'>
                <Select
                  className='form-control'
                  onChange={on.range}
                  values={[ranges.find(r => r.value == range)]}
                  options={ranges}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='card'>
                  <div className='card-header'>
                    <h5 style={{ margin: 0 }}>Signups</h5>
                  </div>
                  <div className='card-body'>
                    <Line {...signup} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='card'>
                  <div className='card-header'>
                    <h5 style={{ margin: 0 }}>Verifications</h5>
                  </div>
                  <div className='card-body'>
                    <Line {...verifications} />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <ServiceChart {...service} />
              </div>
              <div className='col-md-6'>
                <FeedbackTable feedback={data.feedback} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(
  state => ({
    ...extractKeys(state.agencies, 'services', 'feedback'),
    ...extractKeys(state.appointments, 'types'),
  }),
  { getFeedback }
)(Stats)
