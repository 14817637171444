import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class DeathCertificate extends React.Component {
  title    = 'Death Certificate'
  agency   = 'rgd'
  item     = '39'
  admin    = 'registrar_general'
  resource = '/death_certificates'
  form_key = 'death_certificate'

  fee = 10

  validate = form => ({
    date: moment(form.date).format('YYYYMMDD'),
    middle_name: form.middle_name || '###############',
  })

  fields = () => [
    {
      name: 'apostille',
      label: 'Purpose',
      options: ['Apostille', 'Regular'],
    },
    {
      name: 'first_name',
      label: "Deceased's First Name",
    },
    {
      name: 'middle_name',
      label: "Deceased's Middle Name",
      required: false,
    },
    {
      name: 'last_name',
      label: "Deceased's Last Name",
    },
    {
      name: 'date',
      type: 'date',
      label: 'Date Of Death',

      showYearDropdown: true,
      maxDate: new Date(),
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
