const convertToBase64 = file => new Promise((resolve, reject) => {
  const r = new FileReader()

  r.onload = function () {
    resolve(r.result)
  }

  r.onerror = function () {
    reject(r.error)
  }

  r.readAsDataURL(file)
})

export const multiUpload = async (title = '', desc = '', upload) => JSON.stringify({
  title,
  desc,
  file_upload: await convertToBase64(upload),
})

export default convertToBase64
