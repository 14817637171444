import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'

export default class ProductApproval extends React.Component {
  long_name = 'Product Approval'
  resource = '/moea_product_approvals'
  form_key = 'moea_product_approval'
  fee = 99
  free = false
  postpaid = false
  defaults = {}

  skip_location = true
  hide_buttons = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    ...BUSINESS_FIELDS(form),

    '::Applicant Details',
    {
      name: 'occupation',
      hide: form.client_type == 'business',
    },
    {
      name: 'street_address',
      hide: form.client_type == 'business',
    },

    '::Contact Details',
    {
      name: 'po_box',
      label: 'P. O. Box',
    },
    {
      name: 'phone_number',
      type: 'phone',
    },

    '::Product Details',
    'product_name',
    {
      name: 'raw_materials',
      columns: ['name', 'source'],
    },
    'production_method',
    {
      name: 'capital_labour_ratio',
      is: 'float',
    },
    '::SPACER',
    'feasibility',
    // {
    //   name: 'declare',
    //   type: 'checkbox',
    //   label: 'Lorem Ipsum',
    // },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
