const reduceValues = (
  initial = new FormData(),
  obj = {},
  wrapperKey = ''
) => {
  if (typeof obj != 'object') return initial

  console.log(wrapperKey)
  console.log(initial)
  const getKey = wrapperKey
    ? k => `${wrapperKey}[${k}]`
    : k => k

  return Object.entries(obj).reduce((form, [key, value]) => {
    form.set(getKey(key), Array.isArray(value)
      ? value.map(item => JSON.stringify(item))
      : value
    )

    return form
  }, initial)
}

export const generateFormData = (obj, wrapperKey) => {
  if (typeof obj != 'object') return null
  return reduceValues(new FormData(), obj, wrapperKey)
}

export const generateSegmentedFormData = (...data) => {
  try {
    return data.reduce((form, [wrapperKey, obj]) =>
      reduceValues(form, obj, wrapperKey), new FormData())
  } catch (err) {
    console.error('There was an error generating your FormData')
    return null
  }
}

export default generateFormData