import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

export default class BirthRegistration extends React.Component {
  resource = '/birth_registrations'
  form_key = 'birth_registration'

  fee = 10

  fields = () => [
    'first_name',
    {
      name: 'middle_name',
      required: false,
    },
    'last_name',
    {
      name: 'born_at',
      label: 'Time of Birth',
      type: 'time',
    },
    {
      name    : 'island',
      options : islands
    },
    {
      type    : 'date',
      name    : 'date_of_birth',
      maxDate : new Date(),
      showYearDropdown: true,
    },
    'father_first_name',
    {
      name: 'father_middle_name',
      required: false,
    },
    'father_last_name',
    'mother_first_name',
    {
      name: 'mother_middle_name',
      required: false,
    },
    'mother_last_name',
    {
      name: 'mother_maiden_name',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}