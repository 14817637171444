import React from 'react'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import Cookie from 'react-cookies'
import SimpleCrypto from 'simple-crypto-js'
import { withRouter, Link } from 'react-router-dom'

import { extractKeys } from '@helpers/objects'
import LandingPage from '@wrappers/landing-page'
import { loginUser } from '@actions/users'
import STRINGS from '@constants/strings'

const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_SECRET_KEY)

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.loadFromCookie({
      form: {
        email: '',
        password: '',
      },

      resent: null,
    })
  }

  loadFromCookie(state) {
    const cookie = Cookie.load('rememberPilotObj')

    cookie &&
      Object.assign(state.form, extractKeys(cookie, 'email', 'remember_me'), {
        password: cookie.password && simpleCrypto.decrypt(cookie.password),
      })

    return state
  }

  componentDidMount() {
    const { history, location } = this.props
    const timer = location.search.match(/(\w*)=expired/)?.pop()

    if (sessionStorage.user) return history.push('/dashboard')

    if (timer == 'session' || timer == 'otp') {
      const [title, html] =
        timer == 'session'
          ? [
              'Session Expired',
              STRINGS.INFO.SESSION_TIMEOUT.replace('<br/>', ' '),
            ]
          : [
              'OTP Token Expired',
              STRINGS.INFO.OTP_TOKEN_TIMEOUT.replace('<br/>', ' '),
            ]

      Swal.fire({
        icon: 'info',
        title,
        html,
        confirmButtonText: 'Close',
      }).then(() => history.replace(location.pathname))
    }
  }

  async componentDidUpdate() {
    const { history, unconfirmed } = this.props
    const { resent } = this.state

    if ($app.token) return history.push('/dashboard')

    if (unconfirmed && !resent) {
      const resend = await Swal.fire({
        icon: 'warning',
        title: 'Please Verify Your Email Address',
        text: `
          You must verify your email address before logging in.
          Please click the button below to resend your confirmation link
        `,
        confirmButtonColor: '#00AAEF',
        confirmButtonText: 'Resend Confirmation Email',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(this.resendConfirmation)

      if (resend === true) {
        await Swal.fire({
          icon: 'success',
          title: 'Confirmation Email Resent',
          text: 'If you have not received an email in your inbox, please check your junk or spam folder.',
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: resend,
        })
      }

      this.setState({ resent: true })
    }
  }

  on = {
    input: ({ target }) =>
      this.setState(state => {
        state.form[target.name] = target.value
        return state
      }),

    check:
      ({ target }) =>
      state => {
        state.form[target.name] = target.checked
        return state
      },

    submit: ev => {
      if (this.props.loading) return
      ev.preventDefault()
      this.props.loginUser(this.state.form, this.props.history)
    },
  }

  toggleVisibility = id => () => {
    const el = document.getElementById(id)

    if (el) {
      const prop = el.getAttribute('type') == 'text' ? 'password' : 'text'
      el.setAttribute('type', prop)
    }
  }

  resendConfirmation = async () => {
    try {
      const { data, status } = await $app.axios.patch(
        '/confirmations/resend_confirm_email',
        { user: { email: this.state.form.email } },
        { validateStatus: code => code <= 400 }
      )

      if (status == 400) {
        return data.message
      }

      return true
    } catch (err) {
      return 'Unable to resend confirmation email'
    }
  }

  render() {
    const { state, props, on, toggleVisibility } = this
    const { form } = state

    return (
      <LandingPage loading={props.loading}>
        <div className='login-form col-md-12'>
          <form onSubmit={on.submit}>
            <div className='form-group'>
              <label htmlFor='email' className='placeholder'>
                <b>Email</b>
              </label>
              <input
                id='email'
                name='email'
                value={form.email}
                inputMode='email'
                onChange={on.input}
                type='text'
                className='form-control'
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='password' className='placeholder'>
                <b>Password</b>
              </label>
              <Link
                to='/forget-password'
                tabIndex='-1'
                className='link float-right'
              >
                Forgot Password?
              </Link>
              <div className='position-relative'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  value={form.password}
                  onChange={on.input}
                  className='form-control'
                  required
                />
                <div className='show-password'>
                  <a
                    href='#'
                    tabIndex='-1'
                    onClick={toggleVisibility('password')}
                  >
                    <i className='fas fa-eye'></i>
                  </a>
                </div>
              </div>
            </div>
            <div className='form-group form-action-d-flex mb-3'>
              <div className='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  checked={form.remember_me}
                  onChange={on.check}
                  className='custom-control-input'
                  name='remember_me'
                  id='rememberme'
                />
                <label
                  className='custom-control-label m-0'
                  htmlFor='rememberme'
                >
                  Remember Me
                </label>
              </div>
              <input
                type='submit'
                disabled={this.props.loading}
                className='btn custom-btn col-md-5 float-right mt-3 mt-sm-0 fw-bold'
                value='Sign In'
              />
            </div>
          </form>
          <div className='login-account'>
            <span className='msg'>Don't have an account yet? </span>
            <Link to='/signup' className='link'>
              Sign Up
            </Link>
          </div>
          <div className='termndpolicy'>
            <Link to='/legal/terms-of-use'>Terms and Conditions</Link>
            &nbsp;|&nbsp;
            <Link to='/legal/privacy-policy'>Privacy Policy</Link>
          </div>
        </div>
      </LandingPage>
    )
  }
}

export default withRouter(
  connect(
    state =>
      extractKeys(
        state.users,
        'loading',
        'error',
        'unconfirmed',
        'success_message',
        'currentUser'
      ),
    { loginUser }
  )(Login)
)
