import React from 'react'
import { connect } from 'react-redux'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import fishing_areas from '@constants/fishing-areas'
import { getLists } from '@actions/users'
import islands from '@constants/islands'

class SportFishing extends React.Component {
  componentDidMount() {
    !this.props.islands.length && this.props.getLists()
  }
  id = 'SportfishingEvent'
  service = 'sportfishing event'
  title = 'Application to Conduct a SportFishing Event or Tournament '
  agency = 'moa'
  resource = '/sportfishing_events'
  form_key = 'sportfishing_event'
  free = false
  postpaid = false
  defaults = {}
  
  skip_location = false
  hide_buttons = false
  custom_action = false
  custom_acceptance = false

  fee = 560

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'event_name',
      label: 'Name of Event or Tournament',
    },
    {
      name: 'numb_of_vessels',
      label: 'Number of Vessels to engage in event or tournament',
      is: 'integer',
    },
    {
      name: 'starting_date',
      type: 'date',
    },
    {
      name: 'ending_date',
      type: 'date',
    },
    {
      name: 'event_location',
      label: 'Location of Event or Tournament',
      options: islands,
    },
    {
      name: 'fishing_areas',
      label: 'Proposed Fishing Areas',
      multi: true,
      hint: 'Select ALL of the options that apply',
      options: fishing_areas,
    },
    {
      name: 'targeted_fish_species',
      label: 'Target Fish Species',
      multi: true,
      hint: 'Select ALL of the options that apply',
      options: [
        'Snapper',
        'Wahoo',
        'Grouper',
        'Blackfin Tuna',
        'Yellowfin Tuna',
        'Barracuda',
        'Marlin',
        'Kingfish',
        'Dolphin',
      ],
    },
    {
      name: 'catch_and_release',
      label: 'Catch and Release?',
      type: 'select:bool',
    },
    {
      name: 'release_species',
      label: 'State type of fish species',
      hide: !form.catch_and_release,
      multi: true,
      hint: 'Select ALL of the options that apply',
      options: [
        'Snapper',
        'Wahoo',
        'Grouper',
        'Blackfin Tuna',
        'Yellowfin Tuna',
        'Barracuda',
        'Marlin',
        'Kingfish',
        'Dolphin',
      ],
    },
    {
      name: 'event_rules_attached',
      label: 'Proposed Event or Tournament Rules attached?',
      type: 'select:bool',
    },
    {
      name: 'rules_upload',
      label: 'Proposed Event Rules',
      type: 'file:pdf',
      hide: !form.event_rules_attached,
      key: f => f.image,
    },
    {
      name: 'additional_info',
      type: 'textarea',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

export default connect(
  ({ users }) => ({
    islands: users.lists?.islands || [],
  }),
  { getLists }
)(SportFishing)
