import { extractKeys as e } from './objects'

export default function SERVICE_PROPS(config) {
  const list = [
    // From react-router
    'history',
    'location',
    'match',

    // From the service config
    'long_name',
    'canned',
    'statuses',
    'pay_on_approval',
    'fee',
    'free',
    'postpaid',
    'read_params',
    'pausable',
    'postpay_key',
    'fee_adjustment',
    'columns',
    'color_codes',
    'hide_columns',
    'foremost',
    'defaults',
    'fields',
    'picker',
    'hidden_fields',
    'hooks',
    'resource',
    'form_key',
    'c_key',
    'c_fail',
    'r_key',
    'u_key',
    'multiple_uploads',
    'generate_form_data',
    'enable_batch_updates',
    'metadata',
    'validate',
    'search',
    'before_apply',
    'show_approved',
    'skip_location',
    'legal_disclaimer',
    'skip_update_payment',
    'filter_records',
    'table_row_id',
    'refresh_every',
    'overrides',
    'hide_buttons',
    'custom_acceptance',
    'actions',
    'has_appointment',
    'no_dispatch',
    'disable_proxy',
    'disable_pending_check',
    'use_profile',
    'admin_form',
    'pass_fee',
    'custom_postpaid_message',
    'show_spouse',
    'custom_notice',
    'business_only',
    'business_fields',
    'bl_field',
    'service_type',
    'disable_fpo',
  ]

  const via_props = ['history', 'location', 'match', 'service', 'loading']

  const custom_actions = [
    ...(config.props.custom_actions || []),
    ...(config.custom_actions || []),
    config.custom_action,
  ].filter(o => typeof o?.fn == 'function')

  const admin_actions = [
    ...(config.props.admin_actions || []),
    ...(config.admin_actions || []),
    config.admin_action,
  ].filter(o => typeof o?.fn == 'function')

  const via_state = ['picker', 'loading']

  return {
    ...e(config, ...list),
    ...e(config.props, ...via_props),
    ...e(config.state || {}, ...via_state),
    custom_actions,
    admin_actions,
    fee: config.fee ?? config.props.service.base_fee,
  }
}
