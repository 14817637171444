import { Fragment, useEffect, useState, createElement } from 'react'
import DataTable from 'react-data-table-component'
import { Switch, Route } from 'react-router'
import { Link } from 'react-router-dom'
// import { FaChevronLeft } from 'react-icons/fa'
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
// import QrReader from 'react-qr-reader'
// import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'

import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import StateStore from '@helpers/state-store'
import { fullName } from '@modules/form-wizard/utils'
import ApplicationNotes from '@modules/form-wizard/notes'
// import { getUploadPath } from '@modules/form-wizard/utils'

import BahamahostRegistration from '../bahamahost-registration'
import FormView from './formSelectedView'

const SERVICES = {
  BAHAMAHOST_CSP: {
    path: 'bahamahost-host-csp',
    label: 'BahamaHost CSP',
    resource:'/bahama_host_csps',
    form_key: 'bahama_host_csp',
    form_type: 'BahamaHostCsp',
    serviceType: () => {
      return 'BahamaHost Certified Service Professionals'
    }
  }
}

const MotaDirectorView = props => {
  const endDateEvent = new Date();
  endDateEvent.setDate(endDateEvent.getDate() + 1)

  const [formSelected, setFormSelected] = useState(null)
  const [service, setService] = useState(null)
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [clear] = useState(false)
  const [data, setData] = useState([])
  const [active, setActive] = useState({})
  const [params, setParams] = StateStore({
    page: 1,
    per_page: 20,
  })

  const BASE_URL = props.match.url

  useEffect(() => {
    service && fetchRecords()
  }, [service, params.page])

  const changeService = ev => {
    const v = ev.target.value

    if (!v) {
      setService(null)
      return
    }

    setFormSelected(null);
    setService({ key: v, ...SERVICES[v] })
  }

  const fetchRecords = async () => {
    setLoading(true)

    try {
      const { data } = await $app.axios.get(service.resource, { params })

      setData(data.records)
      setParams(data.params)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const viewRecord = record => {
    // console.log(record);
    // console.log(service);
    // service?.key == 'SECURITY_LICENCES' && console.log(service.path(record));
    setFormSelected(record);

    // const to = typeof service.path == 'function'
    //   ? service.path(record)
    //   : service.path

    // history.push(sublink(to), { view: record })
  }

  const filterBySession = () => async () => {
    const { value: session_numb } = await Swal.fire({
      title: 'Filter by Session #',
      input: 'text',

      showCancelButton: true,
      confirmButtonText: 'Filter',
    })
    
    if (session_numb) {
      const currParams = params;
      currParams['session_numb'] = session_numb;
      if (session_numb === '') delete currParams.session_numb
      setParams(currParams);
      fetchRecords();
    }

      return
  }

  const approve = (selector) => async () => {
    // Single Record Update
    if (selector?.user) {
      const { isDismissed } = await Swal.fire({
        icon: 'question',
        title: 'Approve Application',
        html: `
          Are you sure you want to approve this application for
          <strong>${fullName(selector.user, 'initial')}</strong>?
        `,
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve',
      })

      if (isDismissed) return
      setLoading(true)

      try {
        await $app.axios.put(service.resource + '/update_application', {
          [service.form_key]: {
            form_num: selector.form_num,
            application_decision: 'approved'
          },
        })

        toastr.success('Success', 'Application successfully updated')
        setActive({})
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
        )
      }

      return
    }

    // Batch Updates
    const affectingAll = selector === data
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Approve ${affectingAll ? 'All' : 'Selected'} Applications`,
      html: affectingAll
        ? `
          Are you sure that you want to approve
          all of the applications on the current page?
        `
        : `
          Are you sure you want to approve
          the ${selected.length} selected application(s)?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Approve All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      const form_ids = selector.map(f => f.id)

      await $app.axios.put(service.resource + '/update_application_batch', {
        [service.form_key]: {
          form_ids,
          application_decision: 'approved'
        }
      })

      toastr.success('Success', 'Applications successfully updated')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing the affected applications',
        'error'
      )
    }
  }

  const deny = selector => async () => {
    // Single Record Update
    if (selector?.user) {
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Deny Application',
        html: `
          Are you sure you want to deny this application for
          <strong>${fullName(selector.user, 'initial')}</strong>?
        `,
        showCancelButton: true,
        confirmButtonText: 'Yes, Deny',
      })

      if (!isConfirmed) return
      setLoading(true)

      try {
        await $app.axios.put(service.resource + '/update_application', {
          [service.form_key]: {
            form_num: selector.form_num,
            application_decision: 'denied',
          },
        })

        toastr.success('Success', 'Application successfully updated')
        setActive({})
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
          )
        }
        
      return
    }

    // Batch Updates
    const affectingAll = selector === data
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Deny ${affectingAll ? 'All' : 'Selected'} Applications`,
      html: affectingAll
        ? `
          Are you sure that you want to deny all
          of the applications on the current page?
        `
        : `
          Are you sure you want to deny
          the ${selected.length} selected application(s)?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Deny All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      const form_ids = selector.map(f => f.id)

      await $app.axios.put(service.resource + '/update_application_batch', {
        [service.form_key]: {
          form_ids,
          application_decision: 'denied'
        }
      })

      toastr.success('Success', 'Applications successfully updated')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing the affected applications',
        'error'
      )
    }
  }

  const defer = selector => async () => {
    // Single Record Update
    if (selector?.user) {
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Defer Application',
        html: `
          Are you sure you want to defer this application for
          <strong>${fullName(selector.user, 'initial')}</strong>?
        `,
        showCancelButton: true,
        confirmButtonText: 'Yes, Defer',
      })

      if (!isConfirmed) return
      setLoading(true)

      try {
        await $app.axios.put(service.resource + '/update_application', {
          [service.form_key]: {
            form_num: selector.form_num,
            application_decision: 'defer',
          },
        })

        toastr.success('Success', 'Application successfully updated')
        setActive({})
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your application',
          'error'
          )
        }
        
      return
    }

    // Batch Updates
    const affectingAll = selector === data
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Defer ${affectingAll ? 'All' : 'Selected'} Applications`,
      html: affectingAll
        ? `
          Are you sure that you want to defer all
          of the applications on the current page?
        `
        : `
          Are you sure you want to defer
          the ${selected.length} selected application(s)?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Defer All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      const form_ids = selector.map(f => f.id)

      await $app.axios.put(service.resource + '/update_application_batch', {
        [service.form_key]: {
          form_ids,
          application_decision: 'defer'
        }
      })

      toastr.success('Success', 'Applications successfully updated')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing the affected applications',
        'error'
      )
    }
  }

  const change_programme = selector => async () => {
    // Single Record Update
    if (selector?.user) {
      Swal.fire({
        icon: 'info',
        title: 'Change Programme Type',
        input: "select",
        inputOptions: {
          'Public Service Driver Training': 'Public Service Driver Training',
          'Tour Guide Training': 'Tour Guide Training',
          'Water Sports Training': 'Water Sports Training',
          'BahamaHost General': 'BahamaHost General',
          'BahamaHost Recertification': 'BahamaHost Recertification',
          'Certificate Reprint': 'Certificate Reprint',
        },
        inputPlaceholder: "Select a programme type",
        showCancelButton: true,
      }).then(async result => {
        if (result.isConfirmed) {
          setLoading(true)

          try {
            await $app.axios.put(service.resource + '/update_industry_type', {
              [service.form_key]: {
                form_num: selector.form_num,
                industry_type: result.value,
              },
            })

            toastr.success('Success', 'Application successfully updated')
            setActive({})
            fetchRecords()
          } catch (error) {
            setLoading(false)
            console.error(error)
            Swal.fire(
              'Oops...',
              'There has been an error with the programme change of the application',
              'error'
            )
          }
        }
      })
    }
  }

  const writeNote = async record => {
    if (!record.notes?.length) record.notes = []

    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Add Note',
      inputAttributes: {
        autocorrect: 'on',
      },
      showCancelButton: true,
    })

    if (!text) return

    try {
      const { data } = await $app.axios.post('/services/notes/add_note', {
        note: {
          note_type: 'note',
          internal_view_only: true,
          notable_type: service.form_type,
          notable_id: record.id,
          text,
        },
      })

      record.notes.push(data.note)

      Swal.fire({
        icon: 'success',
        toast: 'true',
        text: 'Note successfully created',
        timer: 5000,
        position: 'top-end',
        showConfirmButton: false,
      })
    } catch (err) {
      console.error(err)
      
      Swal.fire({
        icon: 'error',
        toast: 'true',
        text: 'Failed to create note.',
        timer: 5000,
        position: 'top-end',
        showConfirmButton: false,
      })
    }
  }

  const sublink = path => (BASE_URL + '/' + path).replace('//', '/')

  const Service = ({ Component: element, path = '', service = '' }) => (
    <Route
      path={sublink(path)}
      render={r => createElement(element, {
        ...r,
        service: $app.getService(service)
      })}
    />
  )

  const columns = [
    {
      name: 'Applicant',
      selector: 'user.name',
      sortable: true,
      cell: r => {
        let fName
        let lName
        if (r.company_form) {
          const details = JSON.parse(r.employee_details);
          fName = details.first_name
          lName = details.last_name
        } else {
          fName = r.user?.first_name
          lName = r.user?.last_name
        }

        return (
          <span>
            {`${lName}, ${fName}`.capitalize()}
          </span>
        )
      },
    },
    {
      name: 'Company Name',
      selector: row => row.company_form?.company_name || 'N/A',
      sortable: true,
      searchable: r => r.company_form?.company.name,
    },
    {
      name: 'Program Type',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.industry_type,
      sortable: true,
    },
    {
      name: 'Session Numb',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.session_numb,
      sortable: true,
    },
    {
      name: 'Registered Date',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.created_at.slice(0, row.created_at.indexOf("T")),
      sortable: true,
    },
    {
      name: 'Status',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => {
        let status = '';
        if (row.application_decision == 'senior officer viewed'){
          status = 'pending'
        } else {
          status = row.application_decision
        }
        return (<span style={{textTransform: 'capitalize'}}>{status}</span>)
      },
      sortable: true,
    },
    // {
    //   name: 'Illustration Upload',
    //   omit: service?.key != 'SYMBOL_USE_PERMISSION',
    //   selector: row => {
    //     const data = row.image[1];
    //     const dataSplit = data.split('/');
    //     const isMissing = dataSplit[1] != 'system';
    //     let aRefLink;
    //     isMissing ? aRefLink = <span>N/A</span> : aRefLink = <a href={getUploadPath(data, true)} target="_blank" rel="noopener noreferrer">(Click To View)</a>;
        
    //     return aRefLink
    //   },
    //   sortable: true,
    // },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={() => viewRecord(row)}>
            View
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={approve(row)}>
            Approve
          </MenuItem>
          <MenuItem onClick={deny(row)}>
            Deny
          </MenuItem>
          <MenuItem onClick={defer(row)}>
            Defer
          </MenuItem>
          <MenuItem onClick={change_programme(row)}>
            Change Programme Type
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => setActive(row)}>
            View Notes
          </MenuItem>
          <MenuItem onClick={() => writeNote(row)}>
            Create Note
          </MenuItem>
        </Menu>
      ),
    }
  ]

  const colors = [
    {
      style: { backgroundColor: '#0E33' },
      when: row => row.application_decision == 'approved'
    },
    {
      style: { backgroundColor: '#FFBF0044' },
      when: row => row.application_decision == 'pending'
    },
  ]

  return (
    <Fragment>
      <Switch>
        <Service
          path='bahamahost-host-csp'
          service='bahama host csp'
          element={BahamahostRegistration}
        />
      </Switch>
      <section className='content minister-applications-table'>
        <Loader loading={loading} />
        <article className='page-inner p-4'>
          <header className='flex justify-between items-center mb-6'>
            <h1 className='text-4xl text-gray-800'>
              Ministry of Tourism, Investments & Aviation
            </h1>
            <nav className='flex justify-end space-x-4'>
              {/* <span
                onClick={() => setModal([MODAL.MODE])}
                className='flex space-x-1 items-center text-blue-700 cursor-pointer'
              >
                <i className='fas fa-ban'></i>
                <span>Cancel/Revoke</span>
              </span> */}
              <Link
                to='/logout'
                className='flex space-x-1 items-center text-blue-700'
              >
                <i className='fas fa-sign-out-alt'></i>
                <span>Sign Out</span>
              </Link>
            </nav>
          </header>
          <div className='card'>
            <div className='card-header flex flex-col xl:flex-row justify-between items-center'>
              <label className='flex items-center space-x-2'>
                <span className='font-semibold'>Service</span>
                <select
                  name='service'
                  value={service?.key}
                  onChange={changeService}
                  className='form-control'
                >
                  <option value=''>Select A Service</option>
                  {Object.entries(SERVICES).map(([key, { label }]) => (
                    <option key={key} value={key} children={label} />
                  ))}
                </select>
              </label>
              {service?.key == 'BAHAMAHOST_CSP' && (
                <aside className='flex space-x-4 actions'>
                  <span onClick={filterBySession()}>
                    Filter by Session
                  </span>
                </aside>
              )}
              <aside className='flex space-x-4 actions'>
                <span onClick={approve(selected?.length ? selected : data)}>
                  Approve {selected.length ? 'Selected' : 'All'}
                </span>
                <span onClick={deny(selected)}>
                  Deny {selected.length ? 'Selected' : 'All'}
                </span>
                <span onClick={defer(selected)}>
                  Defer {selected.length ? 'Selected' : 'All'}
                </span>
              </aside>
            </div>
            <div className='card-body'>
              {(!formSelected && service) && (
                <div className='table-responsive form-records-table'>
                  <DataTable
                    data={data}
                    columns={columns}
                    onSelectedRowsChange={s => setSelected(s.selectedRows)}
                    clearSelectedRows={clear}
                    conditionalRowStyles={colors}
                    noHeader
                    selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={params.per_page}
                    paginationTotalRows={params.no_of_records}
                    onChangeRowsPerPage={per_page => setParams({ per_page })}
                    onChangePage={page => setParams({ page })}
                  />
                </div>
              )}
              {(!formSelected && !service) && (
                <div className='py-10 text-center font-semibold'>
                  Please select a service
                </div>
              )}
              {formSelected && (
                <FormView
                  passedProps={{service, formSelected}}
                  methods={{setFormSelected, approve, deny, defer, change_programme, setActive, writeNote}}
                  // methods={{setFormSelected, message, setActive, writeNote}}
                />
              )}
            </div>
          </div>
        </article>
        <ApplicationNotes
          data={active?.notes}
          onClose={() => setActive(null)}
        />
      </section>
    </Fragment>
  )
}

export default MotaDirectorView
