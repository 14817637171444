import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
// import countries from '@constants/countries'
import passportPhotoExample from '@helpers/passport-photo-example'
import { commonBanks } from '@constants/banking-institutions'

export default class BeInspiredForm extends React.Component {
  resource = '/youth_be_inspireds'
  form_key = 'youth_be_inspired'
  defaults = {}

  // Delete lines as needed
  // fee           = 99
  free          = true
  // postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'family_islander',
      label: 'Are you applying from a family island?',
      type: 'select:bool',
    },
    {
      name: 'marital_status',
      options: [
        'single',
        'married',
        'divorced',
        'widowed'
      ]
    },
    {
      name: 'number_of_children',
      label: 'No. of Children/Dependent(s)',
      type: 'integer',
    },
    {
      name: 'po_box',
      label: 'Postal Address',
      required: false,
    },
    {
      name: 'street_address',
    },

    "::Telephone contact(s)",
    // {
    //   name: 'cell_phone_number',
    //   type: 'phone',
    // },
    // {
    //   name: 'work_phone_number',
    //   type: 'phone',
    //   required: false
    // },
    {
      name: 'website',
    },
    // {
    //   name: 'date_of_birth',
    //   type: 'date',
    // },
    // {
    //   name: 'country_of_birth',
    //   options: countries,
    // },
    {
      name: 'emergency_contact_list',
      rowLabel: 'Emergency Contact',
      fields: [
        {
          name: 'contact_first_name',
        },
        {
          name: 'contact_last_name'
        },
        {
          name: 'contact_number',
          type: 'phone'
        }
      ],
      // format: v => [v.contact_first_name, v.contact_last_name, v.contact_number]
    },
  
    '::List of services offered through this business/project',

    {
      name: 'service_desc',
      label: 'Description of specific service for which you are requesting funding',
      type: 'textarea'
    },
    {
      name: 'impact',
      label:'Impact on community',
      type: 'textarea',
      hint: 'target market and number of people to be served'
    },
    {
      name: 'project_budget',
      label: 'Breakdown of project/business venture',
      type: 'textarea'
    },
    {
      name: 'business_differ',
      label: 'How does your business differ from existing similar clients?',
      type: 'textarea'
    },
    {
      name: 'promotion_plans',
      label:'What are your plans for promoting this business to your clients?',
      type: 'textarea'
    },
    {
      name: 'sustain',
      label: 'How will you sustain the business after the conclusion of the Grant?',
      type: 'textarea',
    },
    {
      name: 'requested_amount',
      label: 'What is the grant amount you are requesting from the Be Inspired fund?',
      type: 'currency'
    },
    {
      name: 'online_based',
      label: 'Will your business be online based or require a physical store/mobile?',
      options: ['Online-based', 'Physical store/Mobile']
    },
    {
      name: 'funds_amount',
      label: 'What is the amount of funds required?',
      type: 'currency'
    },
    {
      name: 'required_materials_list',
      label: 'What services, tools or machines are needed for your business?',
      columns: ['service', 'tools', 'machines'],
      // format: v => [v.services, v.tools, v.machines]
    },
    {
      name: 'existing_capital',
      label: 'Do you have exisiting capital',
      type: 'select:bool'
    },
    {
      name: 'capital_amount',
      label: 'What is the amount?',
      type: 'currency',
      hide:!form.existing_capital
    },
    {
      name: 'current_website',
      label: 'Do you have a current website/social media pages?',
      type: 'select:bool'
    },
    {
      name: 'social_media_list',
      label: 'Please provide them',
      columns: ['social_media', 'account_name'],
      hide: !form.current_website
    },
    {
      name: 'provided_before',
      label: 'Have you provided this service/product before?',
      type: 'select:bool'
    },
    {
      name: 'prototype',
      label: 'Do you have a prototype of the product?',
      type: 'select:bool'
    },
    {
      name: 'internet_access',
      label: 'Do you have internet access/computer access?',
      type: 'select:bool'
    },
    {
      name: 'training_needed',
      label: 'Do you have the training/certification needed?',
      type: 'select:bool'
    },
    {
      name:'been_convicted',
      label:'Have you ever been convicted of a crime/pending trial?',
      type: 'select:bool'
    },
    {
      name: 'gang_affiliation',
      label: 'Are you affiliated with any gang, or terrorist group?',
      type: 'select:bool'
    },
    {
      name: 'criminal_proceeds_use',
      label: 'Do you plan to use the proceeds to fund any criminal activity?',
      type: 'select:bool'
    },
    {
      name: 'secondary_education_list',
      columns: [
        {
          name: 'name_of_school',
          label: 'Name of school'
        },
        {
          name: 'from_years',
          label: 'From years',
          type: 'integer'
        },
        {
          name: 'to_years',
          label: 'To years',
          type: 'integer'
        }
      ],
      // format: v => [v.name_of_school, v.from_years, v.to_years]
    },
    {
      name: 'academic_achievements_list',
      label: 'List of Academic achievements',
      columns: ['achievement'],
      format: v => v.achievement,
      // view: {
      //   label: 'Achievements',
      //   value: v => v.map(x => ({achievement: x}))
      // }
    },
    {
      name: 'post_secondary_education_list',
      columns: [
        {
          name: 'name_of_school_second',
          label: 'Name of school'
        },
        {
          name: 'from_years_second',
          label: 'From years',
          type: 'integer'
        },
        {
          name: 'to_years_second',
          label: 'To years',
          type: 'integer'
        }
      ],
      // format: v => [v.name_of_school_second, v.from_years_second, v.to_years_second]
    },
    {
      name: 'specialization',
      type: 'textarea'
    },
    ":: Interests",
    {
      name: 'interests_list',
      label: 'State other interests',
      hint: 'sports, drama, community involvement, etc.',
      columns: ['interest'],
      format: v => v.interest,
      view: {
        label: 'Interests',
        value: v => v.join(', ')
      }
    },
    '::SPACER',
    "::Business Information",
    {
      name: 'bank_name',
      options: commonBanks,
    },
    {
      name: 'bank_address',
    },
    {
      name: 'outstanding_loands',
      label: 'Do you have any outstanding loan(s)?',
      type: 'select:bool',
    },
    {
      name: 'monthly_payment',
      type: 'currency',
      hide: !form.outstanding_loans
    },
    {
      name: 'received_funding',
      label: 'Have you ever received or are receiving funding for a business?',
      type: 'select:bool',
    },
    {
      name: 'received_funding_desc',
      label: 'Provide details',
      type: 'textarea',
      hide: !form.received_funding
    },
    {
      name: 'funds_solicited_list',
      label: 'What other source of funds is supporting the business or being solicited?',
      columns: [
        {
          name: 'source_of_funds'
        },
        {
          name: 'type_of_support'
        },
        {
          name: 'committed_or_solicited_support',
          options: ['committed', 'solicited']
        },
        {
          name: 'amount',
          type: 'currency'
        }
      ],
      // format: v => [v.source_of_funds, v.type_of_support, v.committed_or_solicited_support, v.amount]
    },
    '::SPACER',
    "::References",
    {
      name: 'references_list',
      label: 'Name two persons who are not relatives and have known you for at least 2 years',
      rowLabel: 'Reference',
      fields: [
        'first_name',
        'last_name',
        {
          name: 'relationship'
        },
        {
          name: 'occupation'
        },
        {
          name: 'phone_number',
          type: 'phone'
        },
        {
          name: 'business_phone_number',
          type: 'phone'
        }
      ],
      // format: v => [v.relationship, v.occupation, v.phone_number, v.business_phone_number]
    },
    '::SPACER',
    "::Uploads",
    {
      name: 'photo_upload',
      label: 'Personal photo',
      key: 'image.0',
      type: 'file:all'
    },
    {
      name: 'reference_letter_upload',
      label: 'Reference letters(1)',
      key: 'image.1',
      type: 'file:all'
    },
    {
      name: 'reference_letter_2_upload',
      label: 'Reference letter(2)',
      key: 'image.2',
      type: 'file:all'
    },
    {
      name: 'passport_upload',
      label: 'Passport',
      key: 'image.3',
      type: 'file:all',
      hint: passportPhotoExample
    },
    {
      name: 'business_license_upload',
      label: 'Business Licence',
      key: 'image.4',
      type: 'file:all',
      required: false
    },
    {
      name: 'nib_upload',
      label: 'NIB Card',
      key: 'image.5',
      type: 'file:all',
      required: false
    }, 
    {
      name: 'health_cert_upload',
      label: 'Health Certificate',
      type: 'file:all',
      hint: 'For businesses involving food',
      key: 'image.6',
      required: false
    },
    {
      name: 'family_island_admin_upload',
      label: 'Family Island Administrators Verification Form',
      type: 'file:all',
      hide: !form.family_islander,
      key: 'image.7',
      // required: false
    },
        
    '::SPACER',
    {
      name: 'acceptance',
      type: 'checkbox',
      use_as_acceptance: true,
      label: `
        I certify that my answers are true and complete
        to the best of my knowledge
        <br/>
        If this application leads to my employment, I understand that
        false or misleading information in my application or interview
        may result in my release
      `
    }

    
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
