import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import nationalities from '@constants/nationalities'

export default class RadioLicense extends React.Component {
  resource = '/radio_licenses'
  form_key = 'radio_license'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am the owner of the aircraft',
        type: 'select:bool'
    },
    {
      name: 'aircaft_owner',
      label: 'Details of the Aircraft Owners',
      rowLabel: 'Owner',
      fields: [
   
        {
          name: 'f_name',
          label: 'First Name of Owner',
          hide: form.i_am_applicant != false
        },
        {
            name: 'm_name',
            label: 'Middle Name of Owner',
            required: false,
            hide: form.i_am_applicant != false
        },
        {
            name: 'l_name',
            label: 'Last Name of Owner',
            hide: form.i_am_applicant != false
        },
        {
          name: 'owner_address',
          label: 'Permanent address of owner',
          hide: form.i_am_applicant != false
        },
        {
          name: 'telephone_number',
          type: 'phone',
          hide: form.i_am_applicant != false
        },
        {
          name: 'email',
          type: 'email',
          hide: form.i_am_applicant != false
        },
        {
          name: 'nationality',
          options: nationalities,
        },
        
    ]
  },
  {
    name: 'aircaft_owner',
    label: 'Details of the Aircraft Operator',
    rowLabel: 'Operator',
    fields: [
 
      {
        name: 'f_name',
        label: 'First Name of Owner',
        //hide: form.i_am_applicant != false
      },
      {
          name: 'm_name',
          label: 'Middle Name of Owner',
          required: false,
         // hide: form.i_am_applicant != false
      },
      {
          name: 'l_name',
          label: 'Last Name of Owner',
         // hide: form.i_am_applicant != false
      },
      {
        name: 'owner_address',
        label: 'Permanent address of owner',
       // hide: form.i_am_applicant != false
      },
      {
        name: 'telephone_number',
        type: 'phone',
       // hide: form.i_am_applicant != false
      },
      {
        name: 'email',
        type: 'email',
        //hide: form.i_am_applicant != false
      },
      {
        name: 'nationality',
        options: nationalities,
      },
      
  ]
},
    {
      name: 'reg_mark',
      label:'Registraion mark',
    },
    {
      name: 'aircraft_serial_number',

    },
    {
      name: 'manufacturer_designation',
      label: `Manufacturer and Designation of Aircraft`
    },
    {
      name: 'mtom',
      label: 'MTOM',
      hint: 'kg'
    },
    {
      name: 'avionics_system',
      label:'Avionics System Installation And Transmitting Equipment Fitted',
      columns: [
        {
          name: 'categories',
          options: [
            'VHF Communication',
            'HF Communication',
            'VHF Navigation, Marker',
            'ELT',
            'Satcom',
            'Radio Altimeter',
            'DME',
            'ATC Transponder',
            'TCAS/ACAS',
            'GPS',
            'ADF', 
            'Weather Radar'
          ]
        },
        {
          name: 'manufacturer'
        },
        {
          name: 'type',
          label: 'Type/Model'
        },
        {
          name: 'quantity'
        }
      ]
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
