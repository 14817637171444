import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class BirthCertificate extends React.Component {
  resource = '/birth_certificates'
  form_key = 'birth_certificate'
  fee      = 10

  fields = () => [
    {
      name: 'apostille',
      label: 'Purpose',
      options: ['Apostille', 'Regular'],
    },
    {
      name: 'mothers_first_name',
      label: "Mother's First Name"
    },
    {
      name: 'mothers_last_name',
      label: "Mother's Last Name",
      required: false,
    },
    {
      name: 'mothers_maiden_name',
      label: "Mother's Maiden Name"
    },
    {
      name: 'fathers_first_name',
      label: "Father's First Name",
      required: false,
    }, {
      name: 'fathers_last_name',
      label: "Father's Last Name",
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
