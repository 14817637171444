import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class OmnibusFranchisePrivateCharter extends React.Component {
  // id       = 'OmibusFranchiseApplicationRenewal'
  // service  = 'omibus franchise application renewal'
  // title    = 'Omnibus Franchise Renewal'
  // agency   = 'rtd'
  // item     = '396'
  // admin    = 'road_traffic'
  resource = '/omnibus_franchises'
  form_key = 'omnibus_franchise'
  skip_location = true

 // fee = 15

  defaults = {
    client_type: 'individual',
    granted: false,
    renewal: false,
    variation: false,
    public_schedule: false,
    private_schedule: false,
    private_charter: true,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        granted: false,
        renewal: false,
        variation: false,
        public_schedule: false,
        private_schedule: false,
        private_charter: true,
      },
    },
  }

fields = () => [
  {
    name: 'client_type',
    save: true,
    hide: true,
  },
  {
    name: 'granted',
    save: true,
    hide: true,
  },
  {
    name: 'renewal',
    save: true,
    hide: true,
  },
  {
    name: 'variation',
    save: true,
    hide: true,
  },
  {
    name: 'public_schedule',
    save: true,
    hide: true,
  },
  {
    name: 'private_schedule',
    save: true,
    hide: true,
  },
  {
    name: 'private_charter',
    save: true,
    hide: true,
  },

  {
    name: 'passenger_desc',
    label: 'Class or description of passengers to be carried',
    type: 'textarea',
  },
  {
    name: 'vehicles_used_list',
    label: 'Vehicles to be used',
    // required: false,
    // hint: 'Please separate each company with a newline',
    view: {
      value: v => {
        if (!v?.length) return null
        return v.map(r => JSON.parse(r))
      }
    },
    columns: [
      {
        name: 'make',
        label: 'Make',
        required: false,
      },
      {
        name: 'model',
        label: 'Model',
        required: false,
      },
      {
        name: 'year',
        label: 'Year',
        type: 'integer',
        required: false,
      },
      {
        name: 'registration_numb',
        label: 'Registration #',
        required: false,
      },
    ]
  },
  {
    name: 'service_dates_list',
    label: 'Dates on which service shall operate',
    // required: false,
    // hint: 'Please separate each company with a newline',
    view: {
      value: v => {
        if (!v?.length) return null
        return v.map(r => JSON.parse(r))
      }
    },
    columns: [
      {
        name: 'date',
        label: 'Date',
        type: 'date',
        required: false,
      },
      {
        name: 'time_start',
        label: 'Time start',
        type: 'time',
        required: false,
      },
      {
        name: 'time_end',
        label: 'Time End',
        type: 'time',
        required: false,
      },
    ]
  },
  {
    name: 'routes_list',
    label: 'Routes',
    columns: ['routes'],
    format: v => v.routes
  },
  {
    name: 'pick_up_points_list',
    label: 'Position of pick up points',
    columns: ['pick_up_points'],
    format: v => v.pick_up_points
  },
  {
    name: 'drop_off_points_list',
    label: 'Position of setting down points',
    columns: ['drop_off_points'],
    format: v => v.drop_off_points
  },
  {
    name: 'fare_stages_list',
    label: 'The Proposed fare stages',
    columns: ['fare_stages'],
    format: v => v.fare_stages
  },
  {
    name: 'maximum_fare_price',
    label: 'Maximum fares for individual passengers',
    type: 'curency',
  },
  {
    name: 'actual_fare_price',
    label: 'Actual fare for individual passengers',
    type: 'curency',
  },
  {
    name: 'min_numb_vehicles_used',
    label: 'Minumum number of to be vehicles used on each scheduled journey',
    type: 'integer',
  },
  {
    name: 'max_numb_vehicles_used',
    label: 'Maximum number of to be vehicles used on each scheduled journey',
    type: 'integer',
  },
]

render = () => <Wizard {...SERVICE_PROPS(this)} />
}
