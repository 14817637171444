import React from 'react'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'
import { confirmAlert } from 'react-confirm-alert'
import { Carousel } from 'react-responsive-carousel'
import CSwitch from 'react-switch'

import AppointmentsCalendar from '@modules/appointments'
import AppointmentDashboard from '@modules/appointments/AppointmentDashboard'
import AppointmentsList from '@modules/appointments/list'

import Swal from '@sweetalert'
import toastr from '@modules/toastr'
import Loader from '@shared/loader'
import FileField from '@shared/form-fields/file'
import ENV from '@constants/env'
import { listKeys } from '@helpers/objects'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '@styles/inspection.css'

const checks = (...props) => props.reduce((o, k) => ({ ...o, [k]: true }), {})

const generate = {
  checklist: () => ({
    ...checks(
      'head_light',
      'brake_light',
      'signal_light',
      'parking_light',
      'coloured_light',
      'lens',
      'horn',
      'wipers',
      'wind_shield',
      'wing_mirror',
      'rv_mirror',
      'tints',
      'bumper',
      'fender',
      'hood',
      'trunk',
      'door',
      'spare',
      'nuts',
      'bolts',
      'emmissions',
      'tailpipe',
      'chassis',
      'disk_or_title'
    ),
    plate_discrepency: false,
    disk_discrepency: false,
    plate_not_visible: false,
    expired_insurance: false,
    loud_muffler: false,
    balled: false,
    shredded: false,
    verdict: true,
  }),

  shots: () => ({
    front_side: '',
    rear_side: '',
    left_side: '',
    right_side: '',
  }),
}

const initial = () => ({
  step: 1,
  loading: false,
  current: 0,
  image: null,

  vehicles: [],
  appts: [],
  checklists: [],
  shots: [],
})

const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation'>
    <label htmlFor={name} className='placeholder'>
      {label}
      {required !== false ? <span className='required-label'>*</span> : null}
    </label>
    {children}
  </div>
)

const Row = ({ className, children, ...props }) => (
  <div
    {...props}
    className={
      'd-flex align-items-center justify-content-between ' + (className || '')
    }
  >
    {children}
  </div>
)

class VehicleInspectionForm extends React.Component {
  state = initial()
  shots = React.createRef()

  on = {
    current: ev => this.setState({ current: +ev.target.value }),

    thumb: image => () => this.setState({ image }),

    file:
      (name, index) =>
      ({ target }) =>
        this.setState(state => {
          state.shots[index][name] = target.files?.[0]

          return state
        }),

    check: (name, index) => checked =>
      this.setState(state => {
        if (index === undefined) {
          state.checklists = state.checklists.map(c => ({
            ...c,
            [name]: checked,
          }))
        } else {
          state.checklists[index][name] = checked
        }

        return state
      }),

    submit: async () => {
      this.setState({ loading: true })
      const { state, steps, handleError, generateFailureMessage } = this
      const { checklists, vehicles, shots } = state

      for (let i = 0, len = shots.length; i < len; i++) {
        const { id, form_num, user } = vehicles[i]
        const values = { ...checklists[i], ...shots[i] }

        const form = Object.keys(values).reduce((f, key) => {
          f.append(`individual[${key}]`, values[key])
          return f
        }, new FormData())

        try {
          const { data } = await $app.axios.put(
            `/individuals/${id}/inspection_day`,
            form
          )

          await $app.axios.put('/individuals/update_application', {
            individual: {
              form_num,
              application_decision: 'processing',
            },
          })

          if (data && !data.error) {
            if (checklists[i].verdict === false) {
              await $app.axios.post('/emails', {
                email: user.email,
                subject:
                  'MyGateway Citizen Portal Update - Failed Vehicle Inspection',
                message: generateFailureMessage(user.first_name, checklists[i]),
              })
            }

            this.setState({ loading: false, step: steps.length })
          } else
            return handleError(
              'Unable to update vehicle inspection status',
              true
            )
        } catch (err) {
          console.error(err)
          return handleError('Unable to update vehicle inspection status', true)
        }
      }
    },
  }

  nextStep = async ev => {
    ev.preventDefault()

    const { state, reset } = this
    const { step, loading } = state

    switch (step) {
      case 1:
        if (loading || !state.appts?.length) return

        const conflist = state.appts.map(appt => [
          appt.username,
          appt.application.plate_number,
        ])

        this.setState(state => {
          const len = state.appts.length
          const vehicles = []
          const shots = Array(len).fill(generate.shots())
          const checklists = Array(len).fill(generate.checklist())

          for (let appt of state.appts) {
            vehicles.push(appt.application)
          }

          return { vehicles, shots, checklists }
        })

        return confirmAlert({
          customUI: ({ onClose }) => (
            <div className='custom-ui vehicle-inspection-confirm-plates'>
              <h3>Are you sure these are vehicles you are about to inspect?</h3>
              {conflist.map(([name, plate], i) => (
                <div key={i}>
                  <span>{name}</span>
                  <strong>{plate}</strong>
                </div>
              ))}
              <button
                className='btn btn-primary'
                onClick={() => {
                  onClose()
                  this.setState({ step: step + 1 })
                }}
              >
                Yes
              </button>
              <button className='btn btn-danger' onClick={onClose}>
                No
              </button>
            </div>
          ),
        })
      case 3:
        for (let i = state.shots.length; i--; ) {
          if (Object.values(state.shots[i]).some(v => !v)) {
            this.setState({ current: i })
            this.shots.current.isFormValid(false)
            return
          }
        }
        break
      case 4:
        if (loading) return

        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Confirm Inspection',
          showCancelButton: true,
          text: `
            Are you sure you want to submit the entered values
            for all inspections?
          `,
        })

        if (isConfirmed) {
          typeof this.done == 'function' && this.done()
          this.on.submit()
        }

        break
      case 5:
        return reset()
      default:
        break
    }

    this.setState({ step: step + 1 })
  }

  enterPlate = async () => {
    const { value: plate } = await Swal.fire({
      input: 'text',
      text: 'Please enter the plate number of the vehicle to be inspected',
      inputPlaceHolder: 'Plate #',
      showCancelButton: true,
      confirmButtonText: 'Search',
    })

    if (!plate) return

    try {
      const url = '/individuals/by_plate_number/' + plate.toUpperCase()
      const { data } = await $app.axios.get(url)

      if (typeof data.verdict == 'boolean') {
        const { isConfirmed } = await Swal.fire({
          icon: 'warning',
          title: 'Are You Sure?',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          text: `
            This vehicle appears to have already ${
              data.verdict ? 'passed' : 'failed'
            }
            an inspection. Are you sure you want to continue?
          `,
        })

        if (!isConfirmed) return
      }

      Object.assign(data, {
        image: [data.insurance, null, data.vehicle_lic_disc],
      })

      this.setState(state => {
        const vehicles = [data]
        const shots = [generate.shots()]
        const checklists = [generate.checklist()]

        return {
          vehicles,
          shots,
          checklists,
          step: state.step + 1,
          appts: 'MANUAL',
        }
      })
    } catch (err) {
      console.error(err)

      Swal.fire({
        icon: 'error',
        title: 'Vehicle Not Found',
        text: 'We were unable to find a listed vehicle licence renewal application with the entered plate number',
      })
    }
  }

  reset = () => this.setState(initial)

  goBack = () => this.setState({ step: this.state.step - 1 || 1 })

  generateFailureMessage = (name, form) => {
    const checklist = Object.entries(generate.checklist()).reduce(
      (obj, [key, target]) => {
        if (key == 'verdict') return obj

        const [prop, color] =
          form[key] == target ? ['passed', 'black'] : ['failed', 'red']
        obj[prop].push(
          `<span style='color:${color}'>${checkItemLabel(key, prop)}</span>`
        )

        return obj
      },
      {
        failed: [],
        passed: [],
      }
    )

    return `
      Greetings ${name.capitalize()},
      <br/>
      <p>
      Thank you for applying for a Vehicle Licence renewal on MyGateway.
      Your vehicle has not passed the inspection requirements.
      </p>
      <br/>
      <p>
      For your reference, please see the inspection results indicating the
      checklist items that received a pass rating and those that did not. 
      </p>
      <br/>
      <p>
      You may schedule a new appointment at your convenience
      <a href='${
        ENV.PILOT_URL
      }/login?callback=/appointments' target='_blank'>here</a>.
      </p>
      <br/>
      <br/>
      <b style='color:red'>Failed Checklist Items</b>
      <br/>
      ${checklist.failed.join('<br/>')}
      <br/>
      <br/>
      <b style='color:black'>Passed Checklist Items</b>
      <br/>
      ${checklist.passed.join('<br/>')}
      <br/>
      <br/>
      Thank you for using MyGateway Citizen Portal
    `
  }

  handleError = (err = '', toast = false, stop = true) => {
    if (toast) {
      toast && toastr.error('Error', err)
    } else {
      err && console.error(err)
    }

    stop && this.setState({ loading: false })
  }

  vehicleName = (v, short = false) =>
    short
      ? `${v.year_of_vehicle.slice(2)}' ${v.model_of_vehicle}`
      : `${v.year_of_vehicle} ${v.make_of_vehicle} ${v.model_of_vehicle}`

  masterCheckValue = name => this.state.checklists.some(c => c[name])

  steps = [
    () => {
      // 1
      const { nextStep, enterPlate } = this

      const filter = appt =>
        moment().isSame(appt.slot, 'day') && !appt.application?.verdict

      return (
        <React.Fragment>
          <AppointmentsList
            service_type='IndividualLicense'
            filter={filter}
            action={{ text: 'Next', fn: nextStep }}
            onSelect={(appts, done) => {
              this.setState({ appts })
              this.done = done
            }}
            metadata={[
              id => '/individuals/' + id,
              [
                'plate_number:Plate #',
                'verdict:Passed?',
                [
                  'Vehicle',
                  data =>
                    listKeys(
                      data,
                      ...['year', 'make', 'model'].map(p => p + '_of_vehicle')
                    ).join(' '),
                ],
              ],
            ]}
            prompt='Please select the appointment(s) for which you are about to do an inspection'
            searchText='Name/Time/Plate ...'
            multiple
          />
          <div className='text-center text-blue-500'>
            <hr />
            <span className='cursor-pointer' onClick={enterPlate}>
              Enter Plate# Manually
            </span>
          </div>
        </React.Fragment>
      )
    },
    () => {
      // 2
      const { state, on, vehicleName, VehicleSelect } = this
      const { vehicles, current } = state

      const vehicle = vehicles[current]

      const thumbs = [
        ['Insurance', 0],
        ['Vehicle Disc', 2],
      ].map(([label, key]) => {
        const src = ENV.WEB_SERVICE_URL + vehicle.image[key]

        return (
          <figure className='item' key={key} onClick={on.thumb(src)}>
            <img src={src} alt={`Vehicle ${label} Thumbnail`} />
            <figcaption className='legend'>{label}</figcaption>
          </figure>
        )
      })

      return (
        <div className='text-center'>
          <h4>Vehicle</h4>
          <VehicleSelect />
          <strong>{vehicleName(vehicle)}</strong>
          <br />
          <strong>
            VIN#: {vehicle.vin_of_vehicle || '####################'}
          </strong>
          <br />
          <br />
          <h4>Uploads</h4>
          <Carousel>{thumbs}</Carousel>
          <div style={{ margin: '1rem 0 1.5rem' }}>
            Does the description & images match the vehicle(s) you are
            inspecting?
          </div>
        </div>
      )
    },
    () => {
      // 3
      const { state, on, VehicleSelect } = this
      const { shots, current } = state

      const inputs = shots.map((vehicle, i) => {
        const fields = Object.keys(vehicle).map(side => (
          <Field
            name={side}
            label={side.replace('_side', '').capitalize()}
            key={side + i}
          >
            <FileField
              name={`${side}-${i}`}
              accept='image/*'
              value={vehicle[side]}
              validators={['required', 'isFile']}
              errorMessages={['Required', 'An image is required']}
              onChange={on.file(side, i)}
              required
            />
          </Field>
        ))

        return (
          <div key={i} style={{ display: current == i ? 'block' : 'none' }}>
            {fields}
          </div>
        )
      })

      return (
        <div>
          <p className='text-center mb-3'>
            Please upload images of all sides of the vehicle
            <br />
            <span className='text-muted'>(Max Upload Size: 5MB)</span>
          </p>
          <VehicleSelect />
          <br />
          <ValidatorForm id='vehicle-form' ref={this.shots} onSubmit={() => {}}>
            {inputs}
          </ValidatorForm>
        </div>
      )
    },
    () => {
      // 4
      const { state, vehicleName, Heading, Check } = this
      const cols = state.vehicles.length
      const colspan = cols + 2
      const headings = state.vehicles.map((v, i) => (
        <th
          key={i}
          dangerouslySetInnerHTML={{
            __html: vehicleName(v, true).replace(' ', '<br/>'),
          }}
        ></th>
      ))

      return (
        <table>
          <thead>
            <tr>
              <th>Check</th>
              {cols == 1 ? null : <th>All</th>}
              {headings}
            </tr>
          </thead>
          <tbody>
            <Heading span={colspan}>Lights</Heading>
            <Check cols={cols} label='Lens' name='lens' />
            <Check cols={cols} label='Head Lights' name='head_light' />
            <Check cols={cols} label='Brake Lights' name='brake_light' />
            <Check cols={cols} label='Signal Lights' name='signal_light' />
            <Check cols={cols} label='Parking Lights' name='parking_light' />
            <Check cols={cols} label='Colored' name='coloured_light' />
          </tbody>
          <tbody>
            <Heading span={colspan}>Tyres</Heading>
            <Check cols={cols} bad label='Balded' name='balled' />
            <Check cols={cols} bad label='Shredded' name='shredded' />
            <Check cols={cols} label='All Nuts' name='nuts' />
            <Check cols={cols} label='All Bolts' name='bolts' />
            <Check cols={cols} label='Spare Tire' name='spare' />
          </tbody>
          <tbody>
            <Heading span={colspan}>Body</Heading>
            <Check cols={cols} label='Horn Works' name='horn' />
            <Check cols={cols} label='Wipers Work' name='wipers' />
            <Check
              cols={cols}
              label='Windshield Undamaged'
              name='wind_shield'
            />
            <Check
              cols={cols}
              label='Wing Mirrors Undamaged'
              name='wing_mirror'
            />
            <Check
              cols={cols}
              label='Rear View Mirror In Place'
              name='rv_mirror'
            />
            <Check cols={cols} label='Tints not too dark' name='tints' />
            <Check cols={cols} label='Hood In Good Condition' name='hood' />
            <Check cols={cols} label='Trunk In Good Condition' name='trunk' />
            <Check cols={cols} label='Bumper In Good Condition' name='bumper' />
            <Check
              cols={cols}
              label='Fenders In Good Condition'
              name='fender'
            />
            <Check cols={cols} label='Doors In Good Condition' name='door' />
            <Check cols={cols} label='Muffler Too Loud' name='loud_muffler' />
            <Check
              cols={cols}
              label='Emissions At Acceptable Level'
              name='emmissions'
            />
            <Check
              cols={cols}
              label='Tailpipe In Good Condition'
              name='tailpipe'
            />
            <Check cols={cols} label='Chassis' name='chassis' />
          </tbody>
          <tbody>
            <Heading span={colspan}>Licence & Insurance</Heading>
            <Check
              cols={cols}
              bad
              label='Plate Obscured'
              name='plate_not_visible'
            />
            <Check
              cols={cols}
              bad
              label='Any Plate Discrepancy'
              name='plate_discrepency'
            />
            <Check
              cols={cols}
              bad
              label='Any Disk Discrepancy'
              name='disk_discrepency'
            />
            <Check
              cols={cols}
              bad
              label='Insurance Expired'
              name='expired_insurance'
            />
            <Check
              cols={cols}
              bad
              label='Disk or Title Given'
              name='disk_or_title'
            />
            <Check cols={cols} label='Final Verdict?' name='verdict' bold />
          </tbody>
        </table>
      )
    },
    () => {
      // 5
      const text =
        this.state.vehicles.length > 1
          ? "The vehicles' inspection statuses have been successfully updated."
          : "The vehicle's inspection status has been successfully updated."
      return (
        <div className='text-center'>
          <h4 className='mb-3'>Thank You</h4>
          <p className='mt-3 mb-3'>{text}</p>
          <p className='mb-3'>
            If you have more vehicles to inspect, you can quickly click the
            'Reset' button below.
          </p>
        </div>
      )
    },
  ]

  VehicleSelect = () => {
    const { on, state, vehicleName } = this

    if (state.vehicles.length == 1) return null

    const options = state.vehicles.map((v, i) => (
      <option key={i} value={i}>
        Vehicle {i + 1} ({vehicleName(v, true)})
      </option>
    ))

    return (
      <select
        className='form-control'
        onChange={on.current}
        value={state.current}
      >
        {options}
      </select>
    )
  }

  Heading = ({ span, children }) => (
    <tr>
      <td colSpan={span}>
        <strong>{children}</strong>
      </td>
    </tr>
  )

  Check = ({ cols, label, name, bold }) => {
    const { state, on, masterCheckValue } = this
    const { checklists } = state

    const switches = Array(cols)
      .fill(0)
      .map((n, i) => (
        <td key={`${name}-${i}`}>
          <CSwitch
            checked={checklists[i][name]}
            onChange={on.check(name, i)}
            // onColor={bad ? '#F25961': '#49ABF7'}
            onColor='#49ABF7'
          />
        </td>
      ))

    const master =
      cols == 1 ? null : (
        <td>
          <CSwitch
            checked={masterCheckValue(name)}
            onChange={on.check(name)}
            // onColor={bad ? '#F25961': '#49ABF7'}
            onColor='#49ABF7'
          />
        </td>
      )

    const text = bold ? <strong>{label}</strong> : <span>{label}</span>

    return (
      <tr>
        <td>{text}</td>
        {master}
        {switches}
      </tr>
    )
  }

  render() {
    const { state, steps, nextStep, goBack } = this
    const { step } = state
    const numSteps = steps.length

    const styles = {
      logout: {
        position: 'absolute',
        top: '1rem',
        right: '1.5rem',
        zIndex: 1,
      },
      appointment: {
        position: 'absolute',
        top: '1rem',
        right: '7rem',
        zIndex: 1,
      },
      bottomButtons: {
        marginTop: '1.5rem',
      },
    }

    const nextBtn = (
      <button
        className='btn custom-btn col-md-5 fw-bold ml-auto'
        onClick={nextStep}
        disabled={!state.appts?.length}
      >
        {(step == 2 && 'Yes') ||
          (step == numSteps - 1 && 'Finish') ||
          (step == numSteps && 'Reset') ||
          'Next'}
      </button>
    )

    const prevBtn =
      step == 1 || step == 5 ? null : (
        <button className='btn col-md-5 fw-bold' onClick={goBack}>
          {step == 2 ? 'No' : 'Back'}
        </button>
      )

    const progress = Math.floor(((step - 1) / (numSteps - 1)) * 100)

    return (
      <React.Fragment>
        <Loader loading={state.loading} />
        <div
          id='vehicle-inspection-form'
          className={
            'wrapper wrapper-login wrapper-login-full p-0' +
            (step == 4 ? ' checklist' : '')
          }
        >
          <Link
            to='/appointments'
            className='logout-button'
            style={styles.appointment}
          >
            <i className='fas fa fa-calendar-alt' aria-hidden='true'></i>
          </Link>
          <Link to='/logout' className='logout-button' style={styles.logout}>
            <i className='fas fa fa-sign-out-alt' aria-hidden='true'></i>
          </Link>
          <div className='login-aside d-flex flex-column align-items-center justify-content-center text-center bg-secondary-gradient'>
            <h1 className='title fw-bold text-white mb-3'>
              Vehicle Inspection
            </h1>
            <p className='subtitle text-white op-7'>Road Traffic Department</p>
          </div>
          <div className='login-aside d-flex align-items-center justify-content-center bg-white'>
            <div className='container container-login container-transparent animated fadeIn'>
              <div className='login-form col-md-12'>
                <div className='progress-card'>
                  <div className='progress-status'>
                    <span className='text-muted'>Progress</span>
                    <span className='text-muted'>{progress}%</span>
                  </div>
                  <div className='progress' style={{ height: '2px' }}>
                    <div
                      className='progress-bar bg-info'
                      role='progressbar'
                      style={{ width: progress + '%' }}
                      aria-valuenow={progress}
                      aria-valuemin='0'
                      aria-valuemax='100'
                      data-toggle='tooltip'
                      data-placement='top'
                      title=''
                      data-original-title='0%'
                    ></div>
                  </div>
                </div>
                <div
                  className='content'
                  style={{
                    maxWidth: state.step == numSteps - 1 ? 'none' : '640px',
                  }}
                >
                  {steps[step - 1]()}
                  <Row style={styles.bottomButtons}>
                    {prevBtn}
                    {nextBtn}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={state.image}
          onClose={() => this.setState({ image: null })}
          center
        >
          <img
            src={state.image}
            className='user-upload-blowup'
            alt='Magnified User Upload'
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default function Routes({ location }) {
  const backlink = /^\/appointments/.test(location.pathname) ? (
    <div id='vehicle-inspection-form' className='top-buttons'>
      <Link to='/inspections' className='logout-button'>
        <i className='fas fa fa-car' aria-hidden='true'></i>
      </Link>
      <Link to='/logout' className='logout-button'>
        <i className='fas fa fa-sign-out-alt' aria-hidden='true'></i>
      </Link>
    </div>
  ) : null

  return (
    <React.Fragment>
      {backlink}
      <Switch>
        <Route path='/inspections' exact component={VehicleInspectionForm} />
        <Route path='/appointments' exact component={AppointmentsCalendar} />
        <Route
          path='/appointments/dashboard'
          exact
          component={AppointmentDashboard}
        />
        <Redirect to='/inspections' />
      </Switch>
    </React.Fragment>
  )
}

function checkItemLabel(key, use) {
  if (labels[use][key]) return labels[use][key]

  const name = key.replaceAll('_', ' ').capitalize()
  return (
    name + (use == 'passed' ? ' In Good Condition' : ' Not In Good Condition')
  )
}

const labels = {
  failed: {
    coloured_light: 'Head Lights Or Brake Lights Have Incorrect Color',
    balled: 'Tyres Balded',
    shredded: 'Tyres Shredded',
    tints: 'Tints Too Dark',
    rv_mirror: 'Rearview Mirror Not In Good Condition',
    door: 'Door(s) Not In Good Condition',
    spare: 'Spare Tire Missing Or Not In Good Condition',
    nuts: 'Nuts Not Properly Installed Into Tyres',
    bolts: 'Bolts Not Properly Installed Into Tyres',
    emmissions: 'Emissions Above Acceptable Levels',
    disk_or_title: 'Disk Or Title Not Presented Or Missing',
    plate_discrepency: 'Discrepancy With Licence Plate',
    disk_discrepency: 'Discrepancy With Disc',
    plate_not_visible: 'Licence Plate Number Obscured Or Blocked',
    expired_insurance: 'Insurance Policy Expired',
    loud_muffler: 'Muffler Too Loud',
  },

  passed: {
    coloured_light: 'Lights Are Correct Color',
    tints: 'Tints Are Not Too Dark',
    emmissions: 'Emissions At An Acceptable Level',
    rv_mirror: 'Rearview Mirror In Good Condition',
    door: 'Door(s) In Good Condition',
    disk_or_title: 'Disk And Title Are Satifactory',
    plate_discrepency: 'No Discrepancies With Licence Plate',
    disk_discrepency: 'No Discrepancies With Disc',
    plate_not_visible: 'Licence Plate Number Clearly Visible',
    expired_insurance: 'Insurance Policy Is Current',
    loud_muffler: 'Muffler Is Not Too Loud',
    balled: 'Tyres Have Satisfactory Tread Level',
    shredded: 'Tyres Are Not Shredded',
  },
}
