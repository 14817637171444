import React from "react";

import Wizard from "@modules/form-wizard";

import SERVICE_PROPS from "@helpers/extract-service-props";

export default class CommercialPrinter extends React.Component {
  title = "Commercial Printer";
  agency = "mofrd";
  item = "475";
  resource = "/commercial_printers";
  form_key = "commercial_printer";
  free = true;
  skip_location = true;

  fields = (form) => [
    {
      name: "client_type",
      label: "Name of Applicant",
      options: [
        {
          label: "Business",
          value: "business",
        },
        {
          label: "Individual",
          value: "individual",
        },
      ],
    },
    {
      name: "shareholders",
      label: "Please give the names of beneficial shareholders",
      placeholder: "Separate names using commas (e.g., John, David, Mark, etc)",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
      type: "textarea",
      list: true,
    },
    {
      name: "bl_number",
      label: "Business License No.",
      is: "integer",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "tin_number",
      label: "Tax Identification No.",
      is: "integer",
    },
    {
      name: "tin_issue_date",
      label: "Tax Compliance Certificate Issue Date",
      type: "date",
      maxDate: new Date(),
      showYearDropdown: true,
    },
    {
      name: "tin_expiration_date",
      label: "Tax Compliance Certificate Expiry Date",
      type: "date",
      showYearDropdown: true,
    },
    {
      name: "address",
      label: "Street Address of Business",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
    },
    {
      name: "years_of_business",
      label: "Years of Business Existence",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
      is: "integer",
    },
    {
      name: "number_of_employees",
      label: "Number of Employees",
      hide: form.client_type != "business",
      view: { hide: form.client_type != "business" },
      is: "integer",
    },
    {
      name: "sales_amount",
      label: "State amount of Sales or Turnover during the past twelve months",
      is: "currency",
    },
    {
      name: "document_upload",
      type: "file:image",
      label: "Goods required for importation by Commercial Printers",
    },
    {
      name: "total_value",
      label: "Total Value",
      is: "currency",
    },
    {
      name: "set_out",
      type: "checkbox",
      view: false,
      label:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
