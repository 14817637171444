export const serviceRecord = {
  "Disability Form": "disability_form",
  "Driving Test Application Practical": "driving_test_application_practical",
  "New Driver License": "new_driver_licenses",
  "Omibus Franchise Application": "omibus_franchise_applications",
  "Self Drive Franchise Application": "self_drive_franchise_applications",
  "Franchise License": "franchise_license",
  "Taxi Cab Grant Application": "taxi_cab_grant_applications",
  "Tour Car Franchise Application": "tour_car_franchise_applications",
  "Driving Permit": "driving_permit",
  "Drivers License Replacement": "driver_license_replacements",
  "Individual License": "individuals",
  "Drivers License": "drivers_licenses",
  "Drivers Learners Permit Renewal": "drivers_learners_permit_renewals",
  "Public Service Drivers License": "public_service_drivers_licenses",
  "Finger Printing": "finger_printings",
  "Gun License": "gun_licenses",
  "Chassis Check": "chassis_checks"
};

export const serviceAlias = {
  NewDriverLicense: "New Driver's Licence",
  IndividualLicense: "Vehicle Licence Renewal",
  DriversLicense: "Licence Renewal",
};
