import React, { Fragment } from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
import Modal from 'react-responsive-modal'
import Form from '@modules/form-wizard/form'
import Swal from 'sweetalert2'

export default class TheatreLicence extends React.Component {
  resource = '/operate_theatre_licenses'
  form_key = 'operate_theatre_license'

  isRenewal = this.props.location.pathname.includes('-renewal')
  isTransfer = this.props.location.pathname.includes('-transfer')

  state = {
    record: null
  }

  defaults = {
    renewal: this.isRenewal,
    transfer: false
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
        transfer: this.isTransfer,
      },
    },
  }

  // filter_records = this.isRenewal
  //   ? r => r.renewal
  //   : r => !r.renewal

  fee = form => {
    switch (form.seating_capacity) {
      case '1 - 99':
        return 50
      case '100 - 199':
        return 200
      case '200 - 299':
        return 400
      case '300 - 399':
        return 600
      case '400 - 499':
        return 800
      case '500 or More':
        return 1000
      default:
    }
  }

  skip_location = true
  hide_buttons  = false

  custom_actions = [
    {
      text: 'Transfer Licence',
      icon: 'exchange-alt',
      user: true,
      fn: async record => this.setState({ record })
    }
  ]

  validate = null

  foremost = null

  closeModal = () => this.setState({ record: null })

  initiateTransfer = async values => {
    const url = '/operate_theatre_licenses/transfer_license'
    
    await $app.axios.put(url, {
      [this.form_key]: {
        ...values,
        form_num: this.state.record.form_num
      }
    })

    this.setState({ record: null })

    await Swal.fire(
      'Licence Transfer Started',
      'Your transfer licence has been sent to the requested recipient',
      'success'
    )
  }

  fields = form => [
    ...BUSINESS_FIELDS(form),

    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    {
      name: 'transfer',
      save: true,
      hide: true,
    },
    {
      name: 'premise_street_address',
      label: 'Address of Premises'
    },
    {
      name: 'premise_island',
      label: 'Island of Premises',
      options: islands,
    },
    {
      name: 'exhibitions_staged',
    },
    {
      name: 'seating_capacity',
      label: 'Seating Capacity',
      options: [
        { label: '1 - 99 people', value: '1 - 99'},
        { label: '100 - 199 people', value: '100 - 199'},
        { label: '200 - 299 people', value: '200 - 299'},
        { label: '300 - 399 people', value: '300 - 399'},
        { label: '400 - 499 people', value: '400 - 499'},
        { label: '500 or more people', value: '500 or more'},
      ]
    },
    {
      name: 'shareholders',
      columns: ['first_name', 'last_name'],
      // format: v => v.shareholder
    },

    '::Uploads',
    {
      name: 'site_plans_upload',
      label: 'Site Plans',
      type: 'file:all',
    },
    {
      name: 'building_plans_upload',
      label: 'Building Plans',
      type: 'file:all',
    },
  ]

  render = () => {
    const { record } = this.state

    const transferFields = () => [
      {
        name: 'nib',
        label: 'NIB #',
        is: 'integer',
      },
      {
        name: 'first_name',
        label: 'First Name',
      },
      {
        name: 'last_name',
        label: 'Last Name'
      }
    ]

    return (
      <Fragment>
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!record}
          onClose={this.closeModal}
          classNames={{ modal: 'w-full max-w-xl' }}
          center
        >
          <header className='modal-header'>
            <h5 className='mb-0'>Transfer Theater Licence</h5>
          </header>
          <p className='my-2'>
            Enter the details for whom you want to transfer this licence to
          </p>
          <Form
            key={record?.id}
            fields={transferFields}
            save={this.initiateTransfer}
            hideButton={false}
          />
        </Modal>
      </Fragment>
    )
  }
}
