import React from 'react'
import { connect } from 'react-redux'

import Loader from '@shared/loader'
import { getAgencies, getAgencyFaqs, setAgencyAlert } from '@actions/agencies'
import { extractKeys } from '@helpers/objects'

import '@styles/faqs.css'
import '@styles/agency-details.css'

class AgencyPage extends React.Component {
  state = {
    ready: false,
  }

  componentDidMount() {
    const { code } = this.props.match.params

    this.setState(
      {
        agency: $app.getAgency(code),
        services: $app.getServices(code),
      },
      () => {
        if (!this.state.agency) {
          return this.props.history.replace('/dashboard')
        }

        if (!this.props.faqs?.length) {
          this.props.getAgencyFaqs()
        } else {
          this.findFAQs()
        }
      }
    )
  }

  componentDidUpdate() {
    const { faqs } = this.props

    if (this.state.faqs === undefined && faqs?.length) {
      this.findFAQs()
    }
  }

  findFAQs = () => {
    const { id } = this.state.agency
    const target = this.props.faqs
      .find(f => f.name == id)
      ?.services.reduce(
        (list, { name, faqs }) => [
          ...list,
          { heading: $app.getServiceProp(name, 'title') },
          ...faqs,
        ],
        []
      )

    this.setState({ faqs: target?.length ? target : null })
  }

  render() {
    const { agency, services, faqs } = this.state
    if (faqs === undefined) return <Loader loading />

    const aID = agency.code.dashCase()
    const links = services.map(s => (
      <div
        key={s.type}
        className='w-full sm:w-1/2 xl:w-1/3 4xl:w-1/4 mb-4 px-2'
      >
        <a href={s.path}>{s.title}</a>
      </div>
    ))

    const list = faqs?.map(({ heading, question, answer }, i) =>
      heading ? (
        <strong key={i} className='block mb-4 ml-1'>
          {heading}
        </strong>
      ) : (
        <div className='card p-0' key={i}>
          <div
            id={'heading-' + i}
            className='card-header collapsed'
            data-toggle='collapse'
            data-target={'#collapse-' + i}
            aria-controls={'collapse-' + i}
            aria-expanded='false'
            role='button'
          >
            {/* <div className="span-icon">
            <div className="flaticon-box-1"></div>
          </div> */}
            <div className='span-title'>{question}</div>
            <div className='span-mode'>
              <i className='fas fa-plus'></i>
              <i className='fas fa-minus'></i>
            </div>
          </div>

          <div
            id={'collapse-' + i}
            className='collapse'
            aria-labelledby={'heading-' + i}
            data-parent={'#accordion-' + agency.code.dashCase()}
          >
            <div
              className='card-body'
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </div>
        </div>
      )
    )

    const FAQs = list ? (
      <div id='faqs-page' className='w-full 2xl:w-11/12 3xl:w-5/6'>
        <div className='card'>
          <h5 className='mb-1'>FAQs</h5>
          <hr className='mt-0' />
          <div
            className='tab-pane fade active show'
            id={'v-pills-faqs-content-' + aID}
            role='tabpanel'
            aria-labelledby={'v-pills-faqs-tab-' + aID}
          >
            <div
              id={'accordion-' + aID}
              className='accordion accordion-primary'
            >
              {list}
            </div>
          </div>
        </div>
      </div>
    ) : null

    const details = [
      ['Minister', agency.minister],
      ['Permanent Secretary', agency.permanent_secretary],
      ['Phone Number', agency.phone_number],
      ['Email', agency.email],
    ].map(([heading, val]) => (
      <div className='md:w-1/2 mb-2' key={heading}>
        <strong className='pr-2'>{heading}:</strong>
        <span>{val}</span>
      </div>
    ))

    return (
      <section id='agency-details-page'>
        <div className='w-full'>
          <article className='relative card flex md:flex-row p-0'>
            <div className='w-full md:hidden xl:block xl:w-2/5 3xl:w-1/4'>
              <img src={agency.thumbnail} alt='Agency Thumbnail' />
            </div>
            <div className='p-4 md:w-full xl:w-3/5 3xl:w-3/4'>
              <h4 className='mb-1'>
                {agency.name}
                <span className='hidden md:block float-right'>
                  <i className={agency.icon} />
                </span>
              </h4>
              <hr className='mt-0' />
              <div className='flex md:flex-row md:flex-wrap'>{details}</div>
              <hr />
              <p>{agency.desc}</p>
            </div>
          </article>
        </div>
        <div className='w-full 2xl:w-5/6 3xl:w-4/5'>
          <h5 className='mb-1'>Services</h5>
          <hr className='mt-0' />
          <div id='agency-services-links' className='flex flex-row flex-wrap'>
            {links}
          </div>
        </div>
        {FAQs}
      </section>
    )
  }
}

export default connect(
  state => extractKeys(state.agencies, 'agencies', 'alerts', 'faqs'),
  { getAgencies, getAgencyFaqs, setAgencyAlert }
)(AgencyPage)
