import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";

export default class PottersCayVendor extends React.Component {
  resource = "/potters_cay_vendors";
  form_key = "potters_cay_vendor";
  fee = 99;
  free = false;
  postpaid = false;
  defaults = {};
  long_name = "Potter's Cay Vendor Application";

  skip_location = true;
  hide_buttons = false;

  custom_actions = [];

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "company_name",
      label: "Name of Company",
    },
    {
      name: "stall_type",
      label: "Type of Stall",
      options: ["Food", "Produce", "Fish & Conch", "Mobile"],
    },
    {
      name: "items_sold",
      label: "Provide a list of Items to be sold",
      columns: [{ name: "items", label: "Items" }],
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
