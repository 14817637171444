import React from 'react'
import moment from 'moment'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import nationalities from '@constants/nationalities'
import { MALE_OR_FEMALE } from '@modules/form-wizard/utils'

const today = moment()
const _18YearsAgo = today.clone().subtract(18, 'years')

export default class SportsLeague extends React.Component {
  resource = '/urban_renewal_sporting_leagues'
  form_key = 'urban_renewal_sporting_league'
  free     = true

  skip_location = true
  hide_buttons  = false

  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'sports_selected',
      multi: true,
      label: 'Which sports do you want to sign your child up for?',
      options: [
        'Basketball',
        'Swimming',
        'Golf',
        'Tennis',
        'Baseball',
        'Track',
        'Soccer'
      ],
      format: v => [...v],
      view: {
        label: 'Sports Selected',
        value: v => v.join(', ')
      }
    },

    `::Child's Details`,
    {
      name: 'child_first_name',
      label: 'First Name',
    },
    {
      name: 'child_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'child_last_name',
      label: 'Last Name',
    },
    {
      name: 'child_nib',
      label: 'NIB',
    },
    {
      name: 'child_date_of_birth',
      type: 'date',
      minDate: _18YearsAgo.date(),
      view: {
        value: v => `${moment(v).format('Do MMM, YYYY')} (${today.diff(v, 'years')} yrs.)`
      }
    },
    {
      name: 'child_place_of_birth',
      label: 'Place of Birth',
    },
    {
      name: 'child_nationality',
      label: 'Nationality',
      options: nationalities
    },
    {
      name: 'child_sex',
      label: 'Sex',
      options: MALE_OR_FEMALE,
    },
    {
      name: 'child_address',
      label: 'Street Address',
    },

    '::Emergency Contact',
    {
      name: 'emergency_first_name',
      label: 'First Name'
    },
    {
      name: 'emergency_last_name',
      label: 'Last Name'
    },
    {
      name: 'emergency_relationship',
      label: 'Relationship'
    },
    {
      name: 'emergency_phone_numb',
      as: 'phone',
      label: 'First Contact Number'
    },
    {
      name: 'emergency_phone_numb_2',
      as: 'phone',
      label: 'Second Contact Number',
      required: false,
    },
    {
      name: 'emergency_phone_numb_3',
      as: 'phone',
      label: 'Third Contact Number',
      required: false,
    },

    `::Child's Medical Health`,
    {
      name: 'child_medical_issues',
      type: 'select:bool',
      label: 'Does your child have any pre-existing medical issues?',
      view: {
        label: 'Medical Issues?',
      }
    },
    {
      name: 'child_asthmatic',
      type: 'select:bool',
      label: 'Does your child use an inhaler?',
      view: {
        label: 'Asthmatic?',
      }
    },
    {
      name: 'child_prescribed_medication',
      type: 'select:bool',
      label: 'Is your child on any prescribed medication?',
      view: {
        label: 'On Prescriptions?',
      }
    },
    {
      name: 'child_allergies_bool',
      save: false,
      type: 'select:bool',
      label: `Does your child have any allergies`
    },
    {
      name: 'child_allergies',
      columns: ['allergy'],
      format: v => v.allergy,
      label: `Child's Allergies`,
      // format: row => row.map(the => the.allergy),
      hide: form.child_allergies_bool !== true,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
