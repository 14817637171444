const requiredFields = {
  name: '',
  identity: '',
  nib: '',
}

// CERT TYPES
const VEHICLE_INSURANCE = 'Vehicle Insurance'
const COVID_LOW_RISK = 'COVID-19 Low Risk'

export const normalize = str => str.toLowerCase()
  .trim()
  .replace(/ +/g, '-')
  .replace(/[^a-z0-9-]/g, '')

export const certTypes = {
  VEHICLE_INSURANCE,
  COVID_LOW_RISK
}

export const companies = {
  // "Colina": [
  //   VEHICLE_INSURANCE
  // ],
  "Mega Insurance": [
    VEHICLE_INSURANCE
  ],
  // "Doctor's Hospital": [
  //   COVID_LOW_RISK
  // ]
}

export const companiesList = Object.keys(companies)

export const types = {
  ...Type(
    VEHICLE_INSURANCE,
    '{company} verifies that {name} holds an insurance policy with us for the period starting at {start_date}, and ending at {end_date}.\nThe insured vehicle is a {vehicle_year} {vehicle_make} {vehicle_model}, with the licence plate {licence_plate}, and the VIN# {vehicle_vin}',
    'policy_number insurance_type licence_plate start_date end_date vehicle_make vehicle_model vehicle_vin vehicle_year'
  ),

  ...Type(
    COVID_LOW_RISK,
    '{company} has tested {name} and has found that they are confirmed to  not currently carry the COVID-19 virus.\nTest Date: {test_date}',
    'test_date dob nib'
  )
}

function Type (name, description, fields) {
  return {
    [name]: {
      description,
      fields: {
        ...requiredFields,
        ...fields.split(' ').reduce((o, c) => ({ ...o, [c]: '' }), {})
      }
    }
  }
}