export const NATIONAL_EXAM_GRADES = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'U',
  'I',
]

export const BJCS = [
  'Mathematics',
  'English Language',
  'English Literature',
  'General Science',
  'Health Science',
  'Religious Studies',
  'Social Studies',
  'Technical Drawing',
  'Home Economics',
  'Art',
  'Craft',
]

export const BGCSES = [
  'Mathematics',
  'English Language',
  'English Literature',
  'Keyboarding',
  'Bookkeeping & Accounts',
  'Commerce',
  'Economics',
  'Office Procedures',
  'Clothing Construction',
  'Food and Nutrition',
  'Spanish',
  'French',
  'Biology',
  'Chemistry',
  'Combined Science',
  'Physics',
  'History',
  'Geography',
  'Religious Studies',
  'Auto Mechanics',
  'Carpentry & Joinery',
  'Electrical Installation',
  'Graphical Communications',
  'Visual Art & Design',
  'Art & Craft',
  'Music'
]