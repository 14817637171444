import React, { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'

import { verifyOTP } from '@actions/users'
import ENV from '@constants/env'

const OTPScreen = ({ history }) => {
  const dispatch = useDispatch()
  const [code, setCode] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace('/login?otp=expired')
    }, 1000 * 60 * 15) // 15 MINUTES

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (code.length < 6) return
    submit()
  }, [code])

  const submit = () => {
    dispatch(
      verifyOTP(code, verified => {
        if (verified) return

        Swal.fire({
          icon: 'error',
          title: 'Oops',
          text: `You've failed to authenticate.`,
          confirmButtonText: 'Try Again',
        }).then(() => {
          history.goBack()
        })
      })
    )
  }

  return (
    <div id='otp-screen'>
      <Modal classNames={{ modal: 'w-full md:w-128' }} open center>
        <form onSubmit={submit} className='flex flex-col items-stretch'>
          <span className='mb-3'>
            Please check your email/phone(SMS) for your One Time Password.
          </span>
          <strong>Enter One Time Password</strong>
          <input
            type='text'
            name='otp'
            onChange={ev =>
              setCode(
                ev.target.value
                  .trim()
                  .replace(/[^0-9]/g, '')
                  .slice(0, 6)
              )
            }
            value={code}
            className='form-control'
            autoComplete='off'
            inputMode='numeric'
          />
          <div className='mt-3 mb-3 text-black text-sm'>
            The security of your data is important to us and we need to
            authenticate your request. This password is unique to you and can
            only be used once. You have 15 minutes to click the link below and
            enter the code.
            <br />
            Your OTP (One Time Password) will be sent to your email provided at
            registration on MyGateway. As a reminder, please check your junk
            mailbox for the confirmation email. If you have enabled OTP via SMS,
            you may also check your SMS messages for your code
            <br />
            <br />
            Need help? contact our HelpDesk at{' '}
            <a href='tel:2426044357'>(242) 604-4357</a>
            <br />
            Toll free:{' '}
            <a href={ENV.HELPDESK.LINK.PHONE}>{ENV.HELPDESK.TEXT.PHONE}</a>.
          </div>
          <div className='clearfix'></div>
          <footer className='flex justify-end space-x-4 mt-3'>
            <input type='submit' className='btn btn-primary' value='Enter' />
            <button
              type='button'
              className='btn btn-danger'
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </footer>
        </form>
      </Modal>
    </div>
  )
}

export default OTPScreen
