import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

export default class ProtectedTreePermitMultiple extends React.Component {
  resource = '/protected_tree_permits'
  form_key = 'protected_tree_permit'
  
  isMultiple = /multiple/.test(this.props.location.pathname)

  defaults = {}

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        multiple_trees: this.isMultiple
      },
    },
  }

  // Delete lines as needed
  // fee           = 99
  free          = false
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    {
      name: 'f_name',
      label: 'Applicant first name',
      hide: form.i_am_applicant,
    },
    {
      name: 'm_name',
      label: 'Applicant middle name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'l_name',
      label: 'Applicant last name',
      hide: form.i_am_applicant,
    },
    {
      name: 'address',
    },
    {
      name: 'po_box',
      label: 'P.O. Box'
    },
    {
      name: 'island',
      options: islands
    },
    {
      name: 'telephone',
      type: 'phone'
    },
    {
      name: 'fax',
      type: 'phone',
    },
    {
      name: 'location',
      label: 'Location or site of the tree'
    },
    {
      name: 'valid_permit',
      label: 'Is the site covered by a valid permit for conduct of excavation or landfil?',
      type: 'select:bool'
    },
    {
      name: 'is_covered',
      label: 'Please provide the permit number',
      type: 'integer',
      hide: form.valid_permit != true 
    },
    {
      name: 'not_covered',
      label: 'What is the reason for the removal of the trees?',
      type: 'textarea',
      hint: 'Example: Description of Operation: Land clearing, construction, removal/transplant/destruction of a tree for construction purposes.',
      hide: form.valid_permit != false
    },
    {
      name: 'land_clearing',
      label: 'Please provide details on the land clearing',
      type: 'textarea'
    },
    {
      name: 'size',
      label: 'Provide the size of the area'
    },
    {
      name: 'tree_names',
      label: 'Common and Scientific name of protected tree(s)',
      rowLabel: 'Tree',
      fields: [ {
                  name: 'common_name'
                },
                {
                  name: 'scientific_name'
                },
              ]
    },
    {
      name: 'trees_num',
      label: 'Number of protected trees under the Act',
      type: 'integer'
    },
    {
      name: 'tree_measurments',
      label: 'Age/size/height of the tree(s)',
      rowLabel: 'Age/size/height of Tree',
      fields: [
        {
          name: 'tree_name'
        },
        {
          name: 'age',
          is: 'integer',
          required: false
        },
        {
          name: 'size',
          required: false
        },
        {
          name: 'height',
          required: false
        }
      ]
    },
    {
      name: 'equipment_type_list',
      label: 'Type of equipment used for harvesting protecting tree(s)',
      columns: ['equipment'],
      format: v => v.equipment
    },
    {
      name: 'proposed_method',
      label: 'Proposed method of harvesting protected tree(s)',
      type: 'textarea'
    },
    {
      name: 'after_use',
      label: 'Planned restorative and reinstatement plans and future land use plans for the site',
      type: 'textarea'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
