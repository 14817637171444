const orchids = [
  {
    common_name: "Angraecum Orchids",
    scientific_name: "Angraecum florurentum"
  },
  {
    common_name: "Bamboo Orchid",
    scientific_name: "Arundina graminifolia"
  },
  {
    common_name: "Bamboo Orchid",
    scientific_name: "Arundina"
  },
  {
    common_name: "Black Fiddle Orchid",
    scientific_name: "Coelogyne pandurata"
  },
  {
    common_name: "Bletilla Orchids",
    scientific_name: "Bletilla formosana"
  },
  {
    common_name: "Blue Vanda Orchid",
    scientific_name: "Vanda coerulea"
  },
  {
    common_name: "Boat Orchids",
    scientific_name: "Cymbidium iridioides"
  },
  {
    common_name: "Brassavola Orchids",
    scientific_name: "Brassavola subulifolia"
  },
  {
    common_name: "Bulbophyllum Orchids",
    scientific_name: "Bulbophyllum echinolabium"
  },
  {
    common_name: "Cambria Orchids (Vuylstekeara)",
    scientific_name: "Vuylstekeara Cambria Plush"
  },
  {
    common_name: "Cattleya Orchids",
    scientific_name: "Cattleya spp."
  },
  {
    common_name: "Christmas Orchid",
    scientific_name: "Cattleya trianae"
  },
  {
    common_name: "Cinnamon Orchid",
    scientific_name: "Lycaste aromatica"
  },
  {
    common_name: "Cockleshell Orchid",
    scientific_name: "Prosthechea cochleata"
  },
  {
    common_name: "Common Jewel Orchid",
    scientific_name: "Ludisia discolor"
  },
  {
    common_name: "Crested Snow Orchid",
    scientific_name: "Coelogyne cristata"
  },
  {
    common_name: "Cymbidium Orchids",
    scientific_name: "Cymbidium spp."
  },
  {
    common_name: "Dancing Lady Orchid",
    scientific_name: "Oncidium spp."
  },
  {
    common_name: "Easter Orchid",
    scientific_name: "Earina autumnalis"
  },
  {
    common_name: "Egret Flower Orchid",
    scientific_name: "Pecteilis radiata (syn. Habenaria radiata)"
  },
  {
    common_name: "Encyclia – Cockleshell Orchid",
    scientific_name: "Prosthechea cochleata"
  },
  {
    common_name: "Foul Giant Orchid",
    scientific_name: "Bulbophyllum grandiflorum"
  },
  {
    common_name: "Foxtail Orchid",
    scientific_name: "Rhynchostylis gigantea"
  },
  {
    common_name: "Fried-Egg Orchid",
    scientific_name: "Dendrobium chrysotoxum"
  },
  {
    common_name: "Ghost Orchid",
    scientific_name: "Dendrophylax lindenii"
  },
  {
    common_name: "Grass Pink Orchid",
    scientific_name: "Calopogon tuberosus"
  },
  {
    common_name: "Halloween Orchid",
    scientific_name: "Laelia gouldiana"
  },
  {
    common_name: "Holy Ghost Orchid",
    scientific_name: "Peristeria elata"
  },
  {
    common_name: "Kite Orchid",
    scientific_name: "Masdevallia pachyura"
  },
  {
    common_name: "Lady of the Night Orchid",
    scientific_name: "Brassavola nodosa"
  },
  {
    common_name: "Laelia – Corsage Orchid Or Star Orchid",
    scientific_name: "Angraecum sesquipedale"
  },
  {
    common_name: "Marsh Orchids",
    scientific_name: "Dactylorhiza spp."
  },
  {
    common_name: "Masdevallia Orchids",
    scientific_name: "Masdevallia spp."
  },
  {
    common_name: "Mayflower Orchid",
    scientific_name: "Laelia speciosa"
  },
  {
    common_name: "Miltoniopsis – Pansy Orchid",
    scientific_name: "Miltonia spectabilis"
  },
  {
    common_name: "Monkey-Face Orchid",
    scientific_name: "Dracula simia"
  },
  {
    common_name: "Moth Orchids",
    scientific_name: "Phalaenopsis spp."
  },
  {
    common_name: "Noble Rock Orchid",
    scientific_name: "Dendrobium nobile"
  },
  {
    common_name: "Northeastern Butterfly Orchid",
    scientific_name: "Psychopsis papilio"
  },
  {
    common_name: "Nun Orchid",
    scientific_name: "Lycaste virginalis"
  },
  {
    common_name: "Phaius Orchids",
    scientific_name: "Phaius spp."
  },
  {
    common_name: "Pink Foxtail Orchid",
    scientific_name: "Aerides multiflora"
  },
  {
    common_name: "Pink Lady’s Slipper Orchid",
    scientific_name: "Cypripedium acaule"
  },
  {
    common_name: "Pleione – Indian Crocus",
    scientific_name: "Pleione bulbocodioides"
  },
  {
    common_name: "Putty Root Orchid",
    scientific_name: "Aplectrum hyemale"
  },
  {
    common_name: "Queen Victoria Blue Orchid",
    scientific_name: "Dendrobium victoriae-reginae"
  },
  {
    common_name: "Rag Orchid",
    scientific_name: "Ophrys Apifera"
  },
  {
    common_name: "Ruffled Dancing Lady Orchid",
    scientific_name: "Otoglossum brevifolium"
  },
  {
    common_name: "Scarlet Cattleya Orchid",
    scientific_name: "Cattleya coccinea (Formerly Sophronitis coccinea)"
  },
  {
    common_name: "Showy Orchid",
    scientific_name: "Galearis spectabilis"
  },
  {
    common_name: "Showy Tiger Orchid",
    scientific_name: "Grammatophyllum speciosum"
  },
  {
    common_name: "Slipper Orchid",
    scientific_name: "Paphiopedilum spp."
  },
  {
    common_name: "Sparkling Jewel Orchid",
    scientific_name: "Macodes petola"
  },
  {
    common_name: "Spectacular Big Lip Orchid",
    scientific_name: "Miltonia spectabilis"
  },
  {
    common_name: "Spider Orchid",
    scientific_name: "Brassia spp."
  },
  {
    common_name: "Spotted Bull Orchid",
    scientific_name: "Stanhopea tigrina"
  },
  {
    common_name: "Spotted Cat of the Mountain Orchid",
    scientific_name: "Zygopetalum maculatum"
  },
  {
    common_name: "Stanhopea Orchids",
    scientific_name: "Stanhopea insignis"
  },
  {
    common_name: "Star Orchid",
    scientific_name: "Epidendrum Orchids"
  },
  {
    common_name: "Tiger’s Mouth Orchid",
    scientific_name: "Rossioglossum grande (Formerly Odontoglossum grande)"
  },
  {
    common_name: "Tiger-Stripe Orchid",
    scientific_name: "Cymbidium iridioides"
  },
  {
    common_name: "Vanda Orchids",
    scientific_name: "Vanda coerulea"
  },
  {
    common_name: "Vanilla Orchid",
    scientific_name: "Vanilla planifolia"
  },
  {
    common_name: "Zygopetalum Orchids",
    scientific_name: "Zygopetalum mackayi"
  }
].reduce((obj, curr) => {
  const { common_name, scientific_name } = curr ?? {}
  if (!common_name) return obj

  obj[common_name] = scientific_name
  return obj
}, {})

export default orchids
