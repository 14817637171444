import SamplePassportImage from '@images/sample-passport-data-page.png'
import BadPassportImage from '@images/sample-passport-data-page-bad.jpg'

const passportPhotoExample = `
The <strong>information page</strong> of your passport.<br/>
<strong>Selfies or Passport Photos will not be accepted.</strong>
<div class='flex flex-row items-center'>
  <div class='flex flex-row items-center mr-6'>
    <img class='w-32 md:w-40 lg:w-48 mr-2' src='${SamplePassportImage}'/>
    <strong class='text-green-500 font-extrabold text-xl'>&checkmark;</strong>
  </div>
  <div class='flex flex-row items-center mr-6'>
    <img class='w-32 mx-4' src='${BadPassportImage}'/>
  </div>
</div>
`

export default passportPhotoExample
