import React from "react";

import Wizard from "@modules/form-wizard";
import SERVICE_PROPS from "@helpers/extract-service-props";
import countries from "@constants/countries";
import islands from "@constants/islands";
import animals from "@constants/animals";
import { AnimalRequestDetails } from "./_item-imports";

export default class DomesticLivestock extends React.Component {
  id = "DomesticLivestockImportPermit";
  service = "domestic livestock import permit";
  title = "Application for Permit to import Domestic Livestock Animals";
  agency = "moa";
  resource = "/domestic_livestock_import_permits";
  form_key = "domestic_livestock_import_permit";
  free = false;
  postpaid = false;
  defaults = {};
  canned = [];

  skip_location = false;
  hide_buttons = false;
  custom_action = false;
  custom_acceptance = false;

  fee = 99;

  validate = null;

  foremost = null;

  fields = (form) => [
    {
      name: "animal_type",
      label: "Type of Animal",
      options: animals,
    },
    {
      name: "number_of_animals",
      label: "Number of Animals",
      is: "integer",
    },
    {
      name: "animal_breed",
      label: "Breed/Mix",
    },
    ":: Age",
    {
      name: "animal_age_years",
      label: "Years",
    },
    {
      name: "animal_age_months",
      label: "Months",
    },
    "::SPACER",
    {
      name: "animal_sex",
      options: ["Male", "Female"],
    },
    {
      name: "neutered",
      label: "Neutered?",
      type: "select:bool",
    },
    {
      name: "markings",
    },
    {
      name: "initial_country_of_export",
      label: "Country of Export",
      options: countries,
    },
    {
      name: "country_of_birth",
      label: "Country of birth of animal",
      options: countries,
    },
    {
      name: "facility_name",
      label:
        "Facility/Source name and complete physical address where animals are currently",
    },
    {
      name: "facility_address",
      label: "Complete physical address where animals are currently",
    },
    {
      name: "date_of_arrival",
      label: "Anticipated Date of Arrival",
      type: "date",
      maxDate: new Date(),
    },
    {
      name: "purpose",
      label: "Purpose of importing animals",
      options: ["Pet", "Vacation", "Service Animal", "ReSale"],
    },
    {
      name: "island_destinations",
      label: "Island Destinations in the Bahamas",
      options: islands,
    },
    {
      name: "animal_request_details",
      columns: AnimalRequestDetails,
    },
  ];

  render = () => <Wizard {...SERVICE_PROPS(this)} />;
}
